import {
	mdiCash100,
	mdiCreditCard,
	mdiGiftOpen,
	mdiCellphone,
	mdiSetRight,
	mdiVectorCombine,
	mdiContentSave,
	mdiNewspaperVariantOutline,
} from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, IButtonProps, Icon } from 'components/atoms';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { BillGeneral } from 'pages/CheckOut/components/BillGeneral';
import { CashModal } from 'pages/CheckOut/components/Modals/CashModal';
import { GiftCardModal } from 'pages/CheckOut/components/Modals/GiftCardModal';
import { PaymentMethods } from 'pages/CheckOut/components/PaymentMethods';
import { useCheckOutContext } from 'pages/CheckOut/context';
import React, { useState } from 'react';
import { PaymentType } from 'utils/Consts';
import { CustomerInfo } from './components/CustomerInfo';
import { mdiCheckCircle } from '@mdi/js';
import I18n, { t } from 'i18n-js';
import { translations, _t } from 'utils';
import { CardModal } from 'pages/CheckOut/components/Modals/CardModal';
import { CombineModal } from './components/Modals/CombineModal';
import { AppModal } from './components/Modals/AppModal';
import { OtherModal } from './components/Modals/OtherModal';
import { CheckModal } from './components/Modals/CheckModal';
interface IPaymentButtonProps extends IButtonProps {
	paymentType: PaymentType;
}

const PaymentButton = (props: IPaymentButtonProps) => {
	const { payments, calculateAmountNeedToPay } = useCheckOutContext();
	const paymentTypes = useAppSelector(
		(state) => state.PaymentMethodReducer.paymentMethods
	);
	const checkHasPayment = (): boolean => {
		return !_.isEmpty(
			_.find(payments, (x) => x.paymentType === props.paymentType)
		);
	};
	const getTitleByType = () => {
		switch (props.paymentType) {
			case PaymentType.CASH:
				return {
					name: t(translations.checkout.cash),
					icon: mdiCash100,
				};
			case PaymentType.GIFT_CARD:
				return {
					name: t(translations.checkout.giftCard),
					icon: mdiGiftOpen,
				};
			case PaymentType.CREDIT_CARD:
				return {
					name: t(translations.checkout.creditCard),
					icon: mdiCreditCard,
				};
			case PaymentType.SEC:
				return {
					name: t(translations.checkout.check),
					icon: mdiNewspaperVariantOutline,
				};
			case PaymentType.APP:
				return {
					name: t(translations.checkout.payWithApp),
					icon: mdiCellphone,
				};
			case PaymentType.OTHER:
				return {
					name: t(translations.checkout.otherPaymentMethods),
					icon: mdiSetRight,
				};
			default:
				return {
					name: t(translations.checkout.otherPaymentMethods),
					icon: mdiSetRight,
				};
		}
	};
	return (
		<Button
			{...props}
			className={clsxm(
				'flex-1 btn-outline btn-secondary bg-white',
				checkHasPayment() && 'main-gradient-background',
				props.className,
				window.innerWidth <= 1440 &&
					paymentTypes.length > 5 &&
					'gap-0 text-sx text-center items-center flex-none',
			)}
			col
			disabled={calculateAmountNeedToPay() <= 0}
			iconBefore={<Icon path={getTitleByType()?.icon} />}
			title={getTitleByType()?.name}
			small={window.innerWidth <= 1440 && paymentTypes.length > 5} // lg
		/>
	);
};

export const PaymentTypes = () => {
	const [modal, setModal] = useState<PaymentType>();
	const paymentTypes = useAppSelector(
		(state) => state.PaymentMethodReducer.paymentMethods
	);
	const onClose = () => {
		setModal(undefined);
	};

	return (
		<>
			<div
				id="list-btn-payments"
				className={clsxm(
					'w-full flex gap-2 mt-4 mr-2',
					window.innerWidth <= 1420 && 'gap-1.5'
				)}
			>
				<PaymentButton
					id="cash-btn"
					paymentType={PaymentType.CASH}
					onClick={() => setModal(PaymentType.CASH)}
				/>
				<PaymentButton
					id="credit-card-btn"
					paymentType={PaymentType.CREDIT_CARD}
					onClick={() => setModal(PaymentType.CREDIT_CARD)}
					className='flex-none'
				/>
				<PaymentButton
					id="credit-card-btn"
					paymentType={PaymentType.SEC}
					onClick={() => setModal(PaymentType.SEC)}
				/>
				<PaymentButton
					id="gift-card-btn"
					paymentType={PaymentType.GIFT_CARD}
					onClick={() => setModal(PaymentType.GIFT_CARD)}
				/>
				<PaymentButton
					id="app-btn"
					paymentType={PaymentType.APP}
					onClick={() => setModal(PaymentType.APP)}
				/>
				{!_.isEmpty(paymentTypes) && (
					<PaymentButton
						id="others-btn"
						paymentType={PaymentType.OTHER}
						onClick={() => setModal(PaymentType.OTHER)}
					/>
				)}
			</div>
			{modal === PaymentType.CASH && <CashModal onCancel={onClose} />}
			{modal === PaymentType.CREDIT_CARD && <CardModal onCancel={onClose} />}
			{modal === PaymentType.SEC && <CheckModal onCancel={onClose} />}
			{modal === PaymentType.GIFT_CARD && <GiftCardModal onCancel={onClose} />}
			{modal === PaymentType.APP && <AppModal onCancel={onClose} />}
			{modal === PaymentType.OTHER && <OtherModal onCancel={onClose} />}
		</>
	);
};

export const BillPayment = () => {
	const { done, saveBill, bill, childrenBills } = useCheckOutContext();
	const [showCombine, setShowCombine] = useState(false);

	return (
		<div className="col-span-1 flex flex-col overflow-hidden pb-16 pl-4 px-4 ">
			<div className="grid grid-cols-4 gap-2">
				<div
					className={clsxm(
						'col-span-2 grid grid-cols-2 gap-2 ',
						_.isEmpty(bill) && 'invisible'
					)}
				>
					<Button
						id="combine-btn"
						iconBefore={<Icon path={mdiVectorCombine} />}
						small
						className={clsxm(
							'  border-none shadow-md',
							done ? 'btn-disabled' : 'bg-warning text-white'
						)}
						title={t(translations.checkout.combineBill)}
						disabled={done}
						onClick={() => setShowCombine(true)}
					/>
					<Button
						id="save-bill-btn"
						onClick={() => saveBill()}
						iconBefore={<Icon path={mdiContentSave} />}
						small
						className={clsxm(
							' border-none shadow-md',
							!_.isEmpty(childrenBills) || done
								? 'btn-disabled'
								: 'bg-accent text-white'
						)}
						disabled={!_.isEmpty(childrenBills) || done}
						title={t(translations.checkout.saveBill)}
					/>
				</div>

				<CustomerInfo />
			</div>

			<PaymentTypes />
			<div className="grid grid-cols-2 gap-2 mt-4 flex-1 ">
				{done ? (
					<div className="flex-1 flex flex-col items-center justify-center gap-2">
						<Icon
							className="w-20 h-20 mx-auto"
							path={mdiCheckCircle}
							color="#52c41a"
						/>
						<h3 className="mt-0">
							{I18n.t(_t(translations.checkout.checkoutSuccess))}
						</h3>
						<p className="text-base-300">
							{I18n.t(_t(translations.checkout.checkoutSuccessSub))}
						</p>
					</div>
				) : (
					<PaymentMethods />
				)}
				<BillGeneral />
			</div>
			{showCombine && <CombineModal onCancel={() => setShowCombine(false)} />}
		</div>
	);
};
