import { TimeHelper } from 'helpers';
import { EFrequencyType } from 'models';
import moment from 'moment';
import { t, translations } from 'utils';

export const convertStartEndTime = (
	time: moment.Moment,
	date: moment.Moment
) => {
	return TimeHelper.fromTimeZoneStringToUtc(
		`${date.format('YYYY-MM-DD')}T${time.format('HH:mm')}`
	);
};

export const FREQUENCY_TYPES = [
	{ id: EFrequencyType.ONCE, title: t(translations.holiday.once) },
	{ id: EFrequencyType.WEEKLY, title: t(translations.holiday.weekly) },
	{ id: EFrequencyType.MONTHLY, title: t(translations.holiday.monthly) },
];
