import { ButtonGroup, Input } from 'components/atoms';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { translations, I18n, _t } from 'utils';

const COMMISSION_RATE = [
	{
		title: '100-0',
		value: 100,
	},
	{
		title: '70-30',
		value: 70,
	},
	{
		title: '60-40',
		value: 60,
	},
	{
		title: '50-50',
		value: 50,
	},
	{
		title: '40-60',
		value: 40,
	},
	{
		title: '30-70',
		value: 30,
	},
	{
		title: '0-100',
		value: 0,
	},
	{
		title: 'Other',
		value: -1,
	},
];

const CommissionRate = (props: {
	title: string;
	onSelect: (value?: number) => void;
	defaultValue?: number;
	canChoose100?: boolean;
}) => {
	const { title, onSelect, defaultValue, canChoose100 } = props;

	const ARRAY = canChoose100 ? COMMISSION_RATE : COMMISSION_RATE.slice(1);

	const [value, setValue] = useState<number | undefined>(defaultValue);
	const [showInput, setShowInput] = useState(false);

	useEffect(() => {
		if (
			defaultValue &&
			_.isEmpty(ARRAY.find((x) => x.value === defaultValue))
		) {
			setValue(defaultValue);
			setShowInput(true);
		}
	}, [defaultValue]);

	const onChangeValue = (value: number) => {
		let commissionPercent: number | undefined = value;
		if (value > 100) {
			commissionPercent = 100;
		}
		if (value <= 0) {
			commissionPercent = undefined;
		}
		setValue(commissionPercent);
		if (commissionPercent === 0) {
			onSelect(undefined);
		} else {
			onSelect(commissionPercent);
		}
	};

	return (
		<div>
			<div className="flex gap-4">
				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">{title}</span>
					</label>
					<div>
						<ButtonGroup<{ title: string; value: number }>
							buttons={ARRAY}
							value={
								showInput
									? ARRAY.find((x) => x.title === 'Other')
									: ARRAY.find((x) => x.value === value)
							}
							selectedId={
								showInput ? value : ARRAY.find((x) => x.value === value)?.value
							}
							valueField="value"
							titleField="title"
							onChange={(e) => {
								if (e.title === 'Other') {
									setValue(defaultValue);
									setShowInput(true);
									onSelect(e.value !== -1 ? e.value : defaultValue);
								} else {
									setShowInput(false);
									setValue(e.value);
									onSelect(e.value);
								}
							}}
						/>
					</div>
				</div>
				{showInput && (
					<div className="w-1/4 ">
						<Input
							type={'number'}
							onChange={(e) => onChangeValue(_.toNumber(e.target.value))}
							value={value}
							label={I18n.t(_t(translations.staffDetail.inputCommissionRate))}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default CommissionRate;
