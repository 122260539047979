import { IBaseModel } from './Base.model';

export interface IHolidayDetail extends IBaseModel {
	holidayId?: string;
	employeeId?: string;
	fromDate?: Date | string;
	toDate?: Date | string;
	startTime?: Date | string;
	endTime?: Date | string;
	status?: number;
	shopId?: string;
	branchId?: string;
}

export interface IHolidayModel extends IBaseModel {
	holidayName?: string;
	frequencyType?: EFrequencyType;
	fromDate?: Date | string;
	toDate?: Date | string;
	daysOfWeek?: number[] | string;
	daysOfMonth?: number[] | string;
	startTime?: Date | string;
	endTime?: Date | string;
	separationCount?: number;
	weekOfMonth?: EWeekOfMonth;
	maxNumberOfOccurrences?: number;
	employeeId?: string;
	shopId?: string;
	branchId?: string;
	holidayDetails: IHolidayDetail[];
	description?: any;
	approvalComment?: any;
	approvalDate?: any;
}

export enum EFrequencyType {
	ONCE,
	WEEKLY,
	MONTHLY
}

export enum EWeekOfMonth {
	LAST = -1,
	FIRST,
	SECOND,
	THIRD,
	FOURTH,
}