import { t, translations } from 'utils';
import * as yup from 'yup';
export const validationSchema = yup.object().shape({
	name: yup.string().required(t(translations.branchSetting.branchNameMessage)),
	address: yup.object().shape({
		// state: yup.string(),
		// 	.required(t(translations.branchSetting.stateMessage)),
		// street: yup.string(),
		// city: yup.string(),
	}),
	// fax: yup.string(),
	// .required(t(translations.branchSetting.phoneMessage)),
	phone: yup.string().required(t(translations.branchSetting.faxMessage)),
	// taxCode: yup.string(),
	// .required(
	// 	t(translations.branchSetting.taxCodeMessage)
	// ),
	taxPercent: yup
		.number()
		.required(t(translations.branchSetting.taxRateMessage))
		.typeError(t(translations.branchSetting.taxRateMessage))
		.max(100, t(translations.validate.greaterThanHundredPercent))
		.min(0, t(translations.validate.lessThanZero)),
	timeBlockInterval: yup
		.number()
		.required(t(translations.branchSetting.taxRateMessage))
		.typeError(t(translations.branchSetting.timeBlockIntervalMessage))
		.min(0, t(translations.validate.lessThanZero)),

	defaultEmployeeCommission: yup
		.number()
		.required(t(translations.branchSetting.defaultCommissionMessage))
		.typeError(t(translations.branchSetting.defaultCommissionMessage))
		.max(100, t(translations.validate.greaterThanHundredPercent))
		.min(0, t(translations.validate.lessThanZero)),
	// location: yup.object().shape({
	//   latitude: yup
	//     .string()
	//     .max(90, t(translations.branchSetting.latitudeLimit))
	//     .min(-90, t(translations.branchSetting.latitudeLimit))
	//     .test(
	//       "is-longitude-avaiable",
	//       `${t(translations.branchSetting.locationMessage)} ${t(
	//         translations.branchSetting.latitude
	//       )}`,
	//       (value, context) => {
	//         const { parent } = context;
	//         if ((parent.longitude || isNaN(parent.longitude)) && !value) {
	//           return false;
	//         }
	//         return true;
	//       }
	//     )
	//     .test(
	//       "is-latiude-limit",
	//       t(translations.branchSetting.latitudeLimit),
	//       (value) => {
	//         const num = Number.parseFloat(value || "");
	//         if (isNaN(num) || num > 90 || num < -90) {
	//           return false;
	//         }
	//         return true;
	//       }
	//     ),
	//   longitude: yup
	//     .string()
	//     .max(180, t(translations.branchSetting.longitudeLimit))
	//     .min(-180, t(translations.branchSetting.longitudeLimit))
	//     .test(
	//       "is-latitude-avaiable",
	//       `${t(translations.branchSetting.locationMessage)} ${t(
	//         translations.branchSetting.longitude
	//       )}`,
	//       (value, context) => {
	//         const { parent } = context;
	//         if ((parent.latitude || isNaN(parent.latitude)) && !value) {
	//           return false;
	//         }
	//         return true;
	//       }
	//     )
	//     .test(
	//       "is-longitude-limit",
	//       t(translations.branchSetting.longitudeLimit),
	//       (value) => {
	//         const num = Number.parseFloat(value || "");
	//         if (isNaN(num) || num > 180 || num < -180) {
	//           return false;
	//         }
	//         return true;
	//       }
	//     ),
	// }),
	lateArrivalTimeAllowed: yup
		.number()
		.typeError(t(translations.branchSetting.lateArrivalAllowedMessage))
		.min(0, t(translations.validate.lessThanZero)),

	cardChargePercent: yup
		.string()
		.required(t(translations.branchSetting.cardChargeByPercentMessage))
		// .typeError(t(translations.branchSetting.cardChargeByPercentMessage))
		// .max(100, t(translations.validate.greaterThanHundredPercent))
		// .min(0, t(translations.validate.lessThanZero))
		.test(
			'cardcharge-flatrate-available',
			t(translations.branchSetting.cardChargeByPercentNotPermit),
			(value, context) => {
				const { parent } = context;
				const flatRate = Number.parseFloat(parent.flatRate);
				const percent = Number.parseFloat(value || '');
				if (!isNaN(flatRate) && !isNaN(percent) && flatRate > 0) {
					return false;
				}
				return true;
			}
		)
		.test(
			'is-percent-limit',
			t(translations.validate.percentLimit),
			(value) => {
				const num = Number.parseFloat(value || '');
				if (isNaN(num) || num > 100 || num < 0) {
					return false;
				}
				return true;
			}
		),
	cardChargeFlatRate: yup
		.string()
		.required(t(translations.branchSetting.cardChargeByValueMessage))
		// .typeError(t(translations.branchSetting.cardChargeByValueMessage))
		// .min(0, t(translations.validate.lessThanZero))
		.test(
			'cardcharge-percent-available',
			t(translations.branchSetting.cardChargeByValueNotPermit),
			(value, context) => {
				const { parent } = context;
				const percent = Number.parseFloat(parent.cardChargePercent);
				const flatRate = Number.parseFloat(value || '');
				if (percent && flatRate && percent > 0) {
					return false;
				}
				return true;
			}
		)
		.test(
			'is-flatRate-limit',
			t(translations.validate.lessThanZero),
			(value) => {
				const num = Number.parseFloat(value || '');
				if (isNaN(num) || num < 0) {
					return false;
				}
				return true;
			}
		),
	// googleReviewUrl: yup.string(),
	// yelpReviewUrl: yup.string(),
	allowTimeBeforeBookingOnline: yup
		.number()
		.required(t(translations.branchSetting.lateArrivalAllowedMessage))
		.typeError(t(translations.branchSetting.lateArrivalAllowedMessage))
		.min(0, t(translations.validate.lessThanZero)),
	valuePerTurn: yup
		.number()
		.typeError(t(translations.validate.lessThanZero))
		.nullable()
		.notRequired()
		.min(0, t(translations.validate.lessThanZero)),
});
