import { CurrencyHelper } from 'helpers';
import _ from 'lodash';
import { IPayrollDetailDetail, IPayrollOverviewDetail } from 'models';
import moment from 'moment';
import { getDateString } from 'pages/FinancialReport';
import { Divider } from 'pages/FinancialReport/components/Divider';
import { ReportFieldValue } from 'pages/FinancialReport/components/ReportFieldValue';
import React from 'react';
import { t, translations } from 'utils';
import { usePayrollDetailContext } from './payrollDetailContext';

export const ReportRollView = () => {
	const { report } = usePayrollDetailContext();
	const detailDetails = report![1].payrollDetailDetails
		?.filter((e) => e.income! > 0)
		.sort((a, b) =>
			moment(a.date).startOf('date').isBefore(moment(b.date).startOf('date'))
				? -1
				: moment(a.date).startOf('date').isSame(moment(b.date).startOf('date'))
				? (a.turn || 0) < (b.turn || 0)
					? -1
					: 1
				: 1
		);
	const overviewDetail = report![0].payrollOverviewDetails?.filter(
		(e) => e.income! > 0
	);

	return (
		<>
			<h2 className={'text-3xl m-0 text-ERROR'}>{report![0].employeeName}</h2>
			<h4 className="my-4">
				{getDateString(
					moment(report && report[0].fromDate),
					moment(report && report[0].toDate)
				)}
			</h4>
			<PayrollDetailListItems
				detailDetails={detailDetails || []}
				overviewDetails={overviewDetail || []}
			/>
			<Divider />
			<Payments />
			<Divider />
			<Salary />
		</>
	);
};

interface IPayrollDetailItemsProps {
	detailDetails: IPayrollDetailDetail[];
	overviewDetails: IPayrollOverviewDetail[];
}
const PayrollDetailListItems = ({
	detailDetails,
	overviewDetails,
}: IPayrollDetailItemsProps) => {
	const { isDetail, report } = usePayrollDetailContext();
	return (
		<>
			{!isDetail ? (
				<div className="grid grid-cols-7 bg-base-200 px-4 rounded-t-lg">
					<label className="label">
						<p className="label-text font-bold m-0">
							{t(translations.payrollDetailReport.date)}
						</p>
					</label>
					<label className="label">
						<p className="label-text font-bold m-0">
							{t(translations.payrollDetailReport.weekday)}
						</p>
					</label>
					<label className="label">
						<p className="label-text font-bold m-0 text-right w-full">
							{t(translations.payrollDetailReport.income)}
						</p>
					</label>
					<label className="label">
						<p className="label-text font-bold m-0 text-right w-full">
							{t(translations.payrollDetailReport.supplyFee)}
						</p>
					</label>
					<label className="label">
						<p className="label-text font-bold m-0 text-right w-full">
							{t(translations.payrollReport.realIncome)}
						</p>
					</label>
					<label className="label">
						<p className="label-text font-bold m-0 text-right w-full">
							{t(translations.payrollDetailReport.commSalary)}
						</p>
					</label>
					<label className="label">
						<p className="label-text font-bold m-0 text-right w-full">
							{t(translations.payrollDetailReport.tips)}
						</p>
					</label>
				</div>
			) : (
				<div className="grid grid-cols-5 bg-base-200 px-4 rounded-t-lg">
					<label className="col-span-1 label">
						<p className="label-text font-bold m-0">
							{t(translations.payrollDetailReport.turn)}
						</p>
					</label>
					<label className="col-span-2 label">
						<p className="label-text font-bold m-0">
							{t(translations.payrollDetailReport.date)}
						</p>
					</label>
					<label className="col-span-2 label text-right">
						<p className="label-text font-bold m-0 text-right w-full">
							{t(translations.payrollDetailReport.commSalary)}
						</p>
					</label>
				</div>
			)}
			{!isDetail
				? _.orderBy(
						overviewDetails!,
						[(x) => moment(x.date).unix(), (x) => x.turn],
						['asc', 'asc']
				  ).map((e, index) => (
						<div key={index} className="grid grid-cols-7 px-4">
							<label className="label">
								<p className="label-text m-0">
									{moment(e.date).format('MMM Do')}
								</p>
							</label>
							<label className="label">
								<p className="label-text m-0">
									{moment(e.date).format('dddd')}
								</p>
							</label>
							<label className="label">
								<p className="label-text m-0 text-right w-full">
									{CurrencyHelper.formatBalance(e.income)}
								</p>
							</label>
							<label className="label">
								<p className="label-text m-0 text-right w-full">
									{CurrencyHelper.formatBalance(e.supplyFee)}
								</p>
							</label>
							<label className="label">
								<p className="label-text m-0 text-right w-full font-bold">
									{CurrencyHelper.formatBalance(
										(e.income || 0) - (e.supplyFee || 0)
									)}
								</p>
							</label>
							<label className="label">
								<p className="label-text m-0 text-right w-full">
									{CurrencyHelper.formatBalance(e.commission)}
								</p>
							</label>
							<label className="label">
								<p className="label-text m-0 text-right w-full">
									{CurrencyHelper.formatBalance(e.tip)}
								</p>
							</label>
						</div>
				  ))
				: detailDetails!.map((payroll, index) => {
						return (
							<>
								<div key={index} className="grid grid-cols-5 px-4">
									<label className="col-span-1 label">
										<p className="label-text font-bold m-0">{payroll.turn}</p>
									</label>
									<label className="col-span-2 label">
										<p className="label-text font-bold m-0">
											{`${moment(payroll.date).format('MMM Do, YYYY')} ${
												payroll.customerName ? '- ' + payroll.customerName : ''
											}`}
										</p>
									</label>
									<label className="col-span-2 label">
										<p className="label-text font-bold m-0 text-right w-full">
											{CurrencyHelper.formatBalance(
												(payroll.salaryPay || 0) - (payroll.tip || 0)
											)}
										</p>
									</label>
								</div>
								{payroll.billDetails?.map((bill) => (
									<div key={index} className="grid grid-cols-5 px-4">
										<label className="col-span-1 label"></label>
										<label className="col-span-2 label">
											<p className="label-text text-[grey] m-0">
												{bill.serviceName}
											</p>
										</label>
										<label className="col-span-2 label">
											<p className="label-text text-[grey] m-0 text-right w-full">
												{`${
													bill.supplyFee && bill.supplyFee! > 0
														? `(-${CurrencyHelper.formatPrice(
																bill.supplyFee!
														  )} ${t(
																translations.payrollDetailReport.supplyFee
														  )})`
														: ''
												} ${CurrencyHelper.formatPrice(bill.amount)}`}
											</p>
										</label>
									</div>
								))}
							</>
						);
				  })}
			{
				!isDetail ? (
					<div className="grid grid-cols-7 px-4">
						<label className="label">
							<p className="label-text font-bold m-0">
								{t(translations.payrollDetailReport.total)}
							</p>
						</label>
						<label className="label">
							<p className="label-text font-bold m-0" />
						</label>
						<label className="label">
							<p className="label-text font-bold m-0 text-right w-full">
								{CurrencyHelper.formatBalance(report![0].totalIncome)}
							</p>
						</label>
						<label className="label">
							<p className="label-text font-bold m-0 text-right w-full">
								{CurrencyHelper.formatBalance(report![0].totalSupplyFee)}
							</p>
						</label>
						<label className="label">
							<p className="label-text font-bold m-0 text-right w-full">
								{CurrencyHelper.formatBalance(
									(report![0].totalIncome || 0) -
										(report![0].totalSupplyFee || 0)
								)}
							</p>
						</label>
						<label className="label">
							<p className="label-text font-bold m-0 text-right w-full">
								{CurrencyHelper.formatBalance(report![0].totalCommission)}
							</p>
						</label>
						<label className="label">
							<p className="label-text font-bold m-0 text-right w-full">
								{CurrencyHelper.formatBalance(report![0].totalTip)}
							</p>
						</label>
					</div>
				) : null
				// <div className="grid grid-cols-5 px-4">
				// 	<label className="col-span-1 label">
				// 		<p className="label-text font-bold m-0">
				// 			{t(translations.payrollDetailReport.total)}
				// 		</p>
				// 	</label>
				// 	<label className="col-span-2 label">
				// 		<p className="label-text font-bold m-0" />
				// 	</label>
				// 	<label className="col-span-2 label">
				// 		<p className="label-text font-bold m-0 text-right w-full">
				// 			{CurrencyHelper.formatBalance(report![0].totalSalaryPay)}
				// 		</p>
				// 	</label>
				// </div>
			}
		</>
	);
};

const Payments = () => {
	const { report } = usePayrollDetailContext();
	return (
		<div>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.paymentTypes)}
				bold
				big
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalCash)}
				value={CurrencyHelper.formatBalance(report![0].billPaymentTypes?.cash)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalCreditCard)}
				value={CurrencyHelper.formatBalance(report![0].billPaymentTypes?.card)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalByApp)}
				value={CurrencyHelper.formatBalance(
					report![0].billPaymentTypes?.payByApps
				)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalGiftcard)}
				value={CurrencyHelper.formatBalance(
					report![0].billPaymentTypes?.giftCard
				)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.total)}
				value={CurrencyHelper.formatBalance(report![0].billPaymentTypes?.total)}
				bold
				big
			/>
		</div>
	);
};

const Salary = () => {
	const { report } = usePayrollDetailContext();
	return (
		<div>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.supplyFee)}
				value={'-' + CurrencyHelper.formatBalance(report![0].totalSupplyFee)}
				bold
				big
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.tax)}
				value={'-' + CurrencyHelper.formatBalance(report![0].totalTax)}
				bold
				big
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.refund)}
				value={'-' + CurrencyHelper.formatBalance(report![0].totalRefund)}
				bold
				big
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.tipCollected)}
				value={CurrencyHelper.formatBalance(report![0].totalTip)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.tipTimes)}
				value={`${report![0].totalTipCount}`}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.tipCardCharge)}
				value={
					'-' +
					CurrencyHelper.formatBalance(report![0].totalTipCardCharge || 0 * -1)
				}
			/>

			<ReportFieldValue
				title={t(translations.payrollDetailReport.payroll)}
				big
				bold
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.check)}
				value={CurrencyHelper.formatBalance(report![0].subtotalPayByCheck)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.cash)}
				value={CurrencyHelper.formatBalance(report![0].subtotalPayByCash)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalTips)}
				value={CurrencyHelper.formatBalance(report![0].totalTipPay)}
			/>

			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalCheckAmount)}
				value={CurrencyHelper.formatBalance(report![0].totalSalaryPayByCheck)}
				bold
				big
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalCashAmount)}
				value={CurrencyHelper.formatBalance(report![0].totalSalaryPayByCash)}
				bold
				big
			/>
		</div>
	);
};
