import clsxm from 'clsxs/clsxm';
import { translations, t } from 'utils';
import React from 'react';
import { Button, Icon, Input } from 'components/atoms';
import { mdiKeyboardBackspace, mdiStore } from '@mdi/js';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { ShopApiService } from 'services';
import { IApiResponse } from 'models';
import { IShop } from 'models/IShop';

interface IPartnerForm {
	id: string;
}
export const Partner = () => {
	const history = useHistory();

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<IPartnerForm>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	const onSubmit = async (formData: IPartnerForm) => {
		const res = (await ShopApiService.getShopByAdminUrl(
			formData.id
		)) as IApiResponse<IShop>;
		if (res.succeeded && !isEmpty(res.data)) {
			window.location.href = `http://${formData.id}.${process.env
				.REACT_APP_PRODUCTION_URL!}/`;
		} else {
			alert('Shop not exist');
		}
	};

	return (
		<>
			<div className={clsxm('flex flex-col items-center w-full ')}>
				<img
					alt=""
					className={clsxm('w-48 h-48')}
					src="./logo-square.png"
				></img>
				<h4 className={clsxm('mt-[-30px]')}>{t(translations.login.slogan)}</h4>
				<div className="rounded-lg bg-base-100 shadow-lg w-1/4 min-w-96 gap-3 mt-6">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="card-body">
							<h2 className="mt-0">{t(translations.partner.title)}</h2>
							<Input
								placeholder={t(translations.partner.placeHolder)}
								{...register('id', {
									required: t(translations.register.shopName),
								})}
								error={errors.id && errors.id.message}
								renderBefore={<Icon size={0.8} path={mdiStore} />}
							/>
							<div className="card-actions justify-end mt-4">
								<Button
									type="submit"
									primary
									small
									title={t(translations.partner.goToShop)}
									disabled={isEmpty(watch('id'))}
								/>
							</div>
						</div>
					</form>
				</div>
				<div className="flex justify-between">
					<div className="col">
						<Button
							onClick={() => {
								history.goBack();
							}}
							small
							title="Go Back"
							className="text-black mt-2"
							ghost
							iconBefore={<Icon path={mdiKeyboardBackspace} />}
						/>
					</div>
					<div className="col">
						<Button
							// white
							small
							// className="border-dashed border-1 shadow-none"
							className="text-primary mt-2"
							ghost
							title="Register new shop"
							onClick={() => history.push('/register')}
						/>
					</div>
				</div>
			</div>
			<footer className="footer footer-center p-4  text-base-content">
				<div>
					<p>Copyright © 2022 - SCSSolutions.io</p>
				</div>
			</footer>
		</>
	);
};
