/* eslint-disable react-hooks/exhaustive-deps */
import { Description, Button } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { useCheckOutContext } from 'pages/CheckOut/context';
import { useRef, useState } from 'react';
import { t, translations } from 'utils';
import { PaymentType } from 'utils/Consts';

export const CheckModal = (props: { onCancel: () => void }) => {
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const { calculateAmountNeedToPay, addPayment } = useCheckOutContext();
	const numpadRef = useRef<INumPadRef>();
	const [referenceCode, setReferenceCode] = useState('');

	const onUpdateText = (text: string) => {
		if (numpadRef) {
			numpadRef.current?.setText(text);
		}
	};

	const onClear = () => {
		onUpdateText('');
	};

	const onAddPayment = (transactionReferenceNumber?: string) => {
		if (!_.isEmpty(transactionReferenceNumber)) {
			addPayment({
				paymentType: PaymentType.SEC,
				amount: calculateAmountNeedToPay(),
				transactionReferenceNumber: transactionReferenceNumber,
			});
			props.onCancel();
		}
	};

	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			showNumpad={branch?.paymentReferenceCodeRequiring}
			title={t(translations.checkout.check)}
			onConfirm={(x) => {
				onAddPayment(x);
			}}
			onChange={(x) => setReferenceCode(x)}
		>
			<div className="flex flex-col h-full justify-between">
				<div className="flex gap-8 items-center justify-center mt-10 "></div>
				{branch?.paymentReferenceCodeRequiring && (
					<div className="flex h-fit flex-1 items-end p-4">
						<div className="flex w-full items-center justify-between ">
							<div>
								<Description title="Last 4 digits" value={referenceCode} />
							</div>

							<div className="flex gap-2">
								<Button
									onClick={() => onClear()}
									small
									title={t(translations.clear)}
									className="btn-outline btn-error"
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</ModalNumPad>
	);
};
