import { IExpenseType } from 'models';
import { IPaginateRequest } from 'models/RequestModels';
import { BaseApiService } from './BaseApiService';

const prefix = '/ExpenseType';

class ExpenseTypeApiService extends BaseApiService {
	public getAllExpenseTypes = (request: IPaginateRequest) =>
		this.get<IExpenseType[]>(`${prefix}/get-all-expense-types`, {
			params: request,
		});
}

export default new ExpenseTypeApiService(true);
