export const CONTAINER_BACKGROUND = '#E8E8E8';
export const PRIMARY_COLOR = '#323dad';
export const GREY = '#969696';
export const RED_BERRY = '#911439';
export const WHITE_SMOKE = '#FFFAFA';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const BLACK_60 = '#00000060';
export const BLACK_40 = '#00000040';
export const BLACK_OPACITY_80 = 'rgba(000,0,0,.8)';
export const BLACK_OPACITY_60 = 'rgba(000,0,0,.6)';
export const GREEN = '#009533';

export const CATEGORY_COLORS = [
	'hsl(211, 100%, 80%)',
	'hsl(68, 100%, 80%)',
	'hsl(251, 100%, 80%)',
	'hsl(56, 100%, 80%)',
	'hsl(30, 100%, 80%)',
	'hsl(160, 100%, 80%)',
	'hsl(358, 100%, 80%)',
	'hsl(256, 100%, 80%)',
	'hsl(295, 100%, 80%)',
	'hsl(101, 100%, 80%)',
	'hsl(18, 100%, 80%)',
	'hsl(138, 100%, 80%)',
	'hsl(134, 100%, 80%)',
	'hsl(55, 100%, 80%)',
	'hsl(266, 100%, 80%)',
	'hsl(359, 100%, 80%)',
	'hsl(109, 100%, 80%)',
	'hsl(57, 100%, 80%)',
	'hsl(184, 100%, 80%)',
	'hsl(42, 100%, 80%)',
	'hsl(336, 100%, 80%)',
	'hsl(295, 100%, 80%)',
	'hsl(17, 100%, 80%)',
	'hsl(286, 100%, 80%)',
	'hsl(28, 100%, 80%)',
	'hsl(202, 100%, 80%)',
	'hsl(227, 100%, 80%)',
	'hsl(121, 100%, 80%)',
	'hsl(324, 100%, 80%)',
];
