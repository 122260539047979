import { Container } from 'components/atoms';
import { Header } from './components/Header';
import { Reviews } from 'pages/Dashboard/components/Reviews';
import { Summary } from 'pages/Dashboard/components/Summary';
import { TotalAppointment } from 'pages/Dashboard/components/TotalBookings';
import { DashboardProvider } from 'pages/Dashboard/context';
import React from 'react';
import { TopStaffs } from 'pages/Dashboard/components/TopStaffs';
import { BookingByHour } from 'pages/Dashboard/components/BookingByHour';
import { UpcomingBookings } from './components/UpcomingBooking';
import { Chart, PointElement, LineElement, TimeScale, Filler } from 'chart.js';
import 'chartjs-adapter-moment';
import { TotalSales } from 'pages/Dashboard/components/TotalSales';

Chart.register(PointElement, LineElement, TimeScale, Filler);
export const DashBoard = () => {
	return (
		<DashboardProvider>
			<Container>
				<Header />
				<div className="grid grid-cols-5 gap-4 pb-16 ">
					<div className="flex flex-col gap-4 col-span-4">
						<Summary />
						<div className="grid grid-cols-4 gap-2">
							<TotalAppointment />
							<Reviews />
							<TopStaffs />
						</div>
						<div className="grid grid-cols-4 gap-2">
							<BookingByHour />
							<TotalSales />
						</div>
					</div>
					<UpcomingBookings />
				</div>
			</Container>
		</DashboardProvider>
	);
};
