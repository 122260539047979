import clsxm from 'clsxs/clsxm';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Player } from '@lottiefiles/react-lottie-player';

const loadingFile = require('./newLoading.json');
export const LoadingProvider: React.FC<{
	loading?: boolean;
	children: React.ReactNode;
	className?: string;
}> = ({ loading, children, className }) => {
	return (
		<LoadingOverlay
			className={clsxm('rounded-lg relative ', className)}
			styles={{
				overlay: (base) => ({
					...base,
					background: 'rgba(0, 0, 0, 0.3)',
				}),
				spinner: (base) => ({
					...base,
				}),
				wrapper: (base) => ({
					...base,
				}),
				content: (base) => ({
					...base,
				}),
			}}
			// active={loading}
			// spinner
			// text="Loading"
		>
			{children}
			{loading && (
				<div
					style={{ backgroundColor: `rgba(0, 0, 0, 0.3)` }}
					className="absolute top-0 left-0 right-0 bottom-0 z-[99999] justify-center items-center flex"
				>
					<Player
						autoplay={true}
						loop={true}
						controls={true}
						src={loadingFile}
						style={{ height: '450px', width: '450px' }}
					></Player>
				</div>
			)}
		</LoadingOverlay>
	);
};
