import { LoadingProvider } from 'contexts/LoadingContext';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const FacebookIntegration = () => {
	const { shopId } = useParams<{ shopId: string }>();

	useEffect(() => {
		window.FB.login(
			function (response: any) {
				let token = '';
				if (response.status === 'connected') {
					token = response.authResponse.accessToken;
				} else {
					token = 'error';
				}
				setTimeout(() => {
					window.location.replace(
						`https://${shopId}.${process.env.REACT_APP_PRODUCTION_URL}/settings/shop/${token}`
					);
				});
			},
			{
				scope:
					'email,pages_show_list,pages_messaging,instagram_basic,instagram_manage_messages,page_events,' +
					'pages_read_engagement,pages_manage_metadata,public_profile,pages_messaging_subscriptions,pages_read_user_content,' +
					'pages_messaging_phone_number,pages_manage_posts,instagram_content_publish,pages_manage_instant_articles,instagram_manage_comments',
			}
		);
	}, []);
	return (
		<LoadingProvider loading={true}>
			<div className="w-screen h-screen bg-primary" />
		</LoadingProvider>
	);
};
