import { PayloadAction } from "@reduxjs/toolkit";
import { AlertHelper } from "helpers";
import { showError } from "helpers/alertHelper";
import { toTimeZone } from "helpers/timeHelper";
import { EBookingInputFrom, IApiResponse, IBooking, ICustomer, IErrorResponse } from "models";
import { IBranch } from "models/IBranch";
import { IStylistAndBlockTimeRequest, IGetBookingsRequest, IUpdateBookingStatusRequest } from "models/RequestModels";
import { IStylistAndTimeBlockResponse } from "models/ResponseModels";
import moment from "moment";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { BookingActions, CustomerActions } from "redux/actions";
import { RootState } from "redux/configuration/rootReducer";
import BookingApiService from "services/BookingApiService";
import CustomerApiService from "services/CustomerApiService";
import { BookingStatus } from "utils/Consts";

const getBranch = (state: RootState) => state.BranchReducer.currentBranch;

function* createBooking(action: PayloadAction<Partial<IBooking>>) {
  try {
    const result: IApiResponse<IBooking> = yield call(BookingApiService.createBooking, action.payload);
    if (result.succeeded) {
      AlertHelper.showAlert("Save Successfully", "success");
      yield put(BookingActions.createBooking.success(result.data!));
    } else {
      const errorResult = result as IErrorResponse;
      AlertHelper.showError(errorResult);
      yield put(BookingActions.createBooking.failed(errorResult));
    }
  } catch (error) {
    yield put(BookingActions.createBooking.failed(error));
  }
}

function* editBooking(action: PayloadAction<Partial<IBooking>>) {
  try {
    const result: IApiResponse<IBooking> = yield call(BookingApiService.editBooking, action.payload);
    if (result.succeeded) {
      AlertHelper.showAlert("Save Successfully", "success");
      yield put(BookingActions.editBooking.success(result.data!));
    } else {
      const errorResult = result as IErrorResponse;
      AlertHelper.showError(errorResult);
      yield put(BookingActions.editBooking.failed(errorResult));
    }
  } catch (error) {
    yield put(BookingActions.editBooking.failed(error));
  }
}
function* updateBookingStatus(action: PayloadAction<IUpdateBookingStatusRequest>) {
  try {
    const result: IApiResponse<IBooking> = yield call(BookingApiService.updateBookingStatus, action.payload);
    if (result.succeeded) {
      AlertHelper.showAlert("Update Successfully", "success");
      yield put(BookingActions.updateStatus.success(result.data!));
    } else {
      const errorResult = result as IErrorResponse;
      AlertHelper.showError(errorResult);
      yield put(BookingActions.updateStatus.failed(errorResult));
    }
  } catch (error) {
    yield put(BookingActions.updateStatus.failed(error));
  }
}
function* getBookingsByBranch(action: PayloadAction<IGetBookingsRequest>) {
  try {
    const result: IApiResponse<IBooking[]> = yield call(BookingApiService.getBookingByBranch, action.payload);
    if (result.succeeded) {
      yield put(BookingActions.getBookingsByBranch.success(result.data!));
    } else {
      const errorResult = result as IErrorResponse;
      yield put(BookingActions.getBookingsByBranch.failed(errorResult));
    }
  } catch (error) {
    yield put(BookingActions.getBookingsByBranch.failed(error));
  }
}

function* getTodayBookings() {
  try {
    const params: IGetBookingsRequest = {
      fromDate: toTimeZone(moment().toDate()).format("YYYY-MM-DD"),
      toDate: toTimeZone(moment().toDate()).format("YYYY-MM-DD"),
      pageSize: 1000,
      bookingStatus: `${BookingStatus.PENDING}, ${BookingStatus.CHECKED_IN}, ${BookingStatus.PROCESSING}, ${BookingStatus.DONE}, ${BookingStatus.FINISHED},${BookingStatus.SUSPENDED}`,
    };
    const result: IApiResponse<IBooking[]> = yield call(BookingApiService.getBookingByBranch, params);
    if (result.succeeded) {
      yield put(BookingActions.getTodayBookings.success(result.data!));
      // SocketService.setUrl();
      // SocketService.runService();
      // SocketService.onListen();
    } else {
      const errorResult = result as IErrorResponse;
      yield put(BookingActions.getTodayBookings.failed(errorResult));
    }
  } catch (error) {
    yield put(BookingActions.getTodayBookings.failed(error));
  }
}

function* getBookingById(action: PayloadAction<string>) {
  try {
    const result: IApiResponse<IBooking> = yield call(BookingApiService.getBookingById, action.payload);
    if (result.succeeded) {
      yield put(BookingActions.getBookingById.success(result.data!));
    } else {
      const errorResult = result as IErrorResponse;
      yield put(BookingActions.getBookingById.failed(errorResult));
    }
  } catch (error) {
    yield put(BookingActions.getBookingById.failed(error));
  }
}

function* getAvailableStylistAndTimeBlocks(
  action: PayloadAction<{
    index: number;
    bookingDetail: IStylistAndBlockTimeRequest;
  }>
) {
  try {
    const result: IApiResponse<IStylistAndTimeBlockResponse> = yield call(
      BookingApiService.getAvailableStylistAndTimeBlocks,
      { ...action.payload.bookingDetail, inputFrom: EBookingInputFrom.POS }
    );
    if (result.succeeded) {
      yield put(
        BookingActions.getAvailableStylistAndTimeBlocks.success({
          index: action.payload.index,
          bookingDetail: result.data!,
        })
      );
    } else {
      const errorResult = result as IErrorResponse;
      yield put(BookingActions.getAvailableStylistAndTimeBlocks.failed(errorResult));
    }
  } catch (error) {
    yield put(BookingActions.getAvailableStylistAndTimeBlocks.failed(error));
  }
}

// function* selectCustomer(action: PayloadAction<Partial<ICustomer>>) {
//   yield put (BookingActions.getCustomerHistory)
// }

function* editCustomer(action: PayloadAction<Partial<ICustomer>>) {
  try {
    const result: IApiResponse<Partial<ICustomer>> = yield call(CustomerApiService.editCustomer, action.payload);
    if (result.succeeded) {
      yield put(BookingActions.editCustomer.success());
      yield put(BookingActions.selectCustomer.request(result.data!));
    } else {
      const error = result as IErrorResponse;
      yield put(BookingActions.editCustomer.failed(error));
      showError(error);
    }
  } catch (error) {
    yield put(BookingActions.editCustomer.failed(error));
  }
}

function* addCustomer(action: PayloadAction<Partial<ICustomer>>) {
  try {
    const currentBranch: IBranch = yield select(getBranch);
    const customer = {
      ...action.payload,
      address: {
        ...action.payload.address,
        countryCode: currentBranch.address?.countryCode,
      },
    };
    const result: IApiResponse<ICustomer> = yield call(CustomerApiService.addCustomer, customer);
    if (result.succeeded) {
      yield put(BookingActions.addCustomer.success(result.data!));
      yield put(BookingActions.selectCustomer.request(result.data!));
    } else {
      const error = result as IErrorResponse;
      yield put(CustomerActions.addCustomer.failed(error));
      AlertHelper.showError(error);
    }
  } catch (error) {
    yield put(CustomerActions.addCustomer.failed(error));
  }
}

function* getCustomerByPhone(action: PayloadAction<string>) {
  try {
    const result: IApiResponse<ICustomer> = yield call(CustomerApiService.getCustomerByPhone, action.payload);
    if (result.succeeded) {
      yield put(BookingActions.getCustomerByPhone.success(result.data!));
    } else {
      const errorResult = result as IErrorResponse;
      yield put(BookingActions.getCustomerByPhone.failed(errorResult));
      AlertHelper.showError(errorResult);
    }
  } catch (error) {
    yield put(BookingActions.getCustomerByPhone.failed(error));
  }
}

function* createNewBookingWithCustomer(
  action: PayloadAction<{
    customer: Partial<ICustomer>;
    booking: Partial<IBooking>;
  }>
) {
  try {
    //Create customer
    const createCustomerResult: IApiResponse<ICustomer> = yield call(
      CustomerApiService.addCustomer,
      action.payload.customer
    );
    if (createCustomerResult.succeeded) {
      yield put(BookingActions.addCustomer.success(createCustomerResult.data!));
      yield put(BookingActions.selectCustomer.request(createCustomerResult.data!));
      yield put(BookingActions.setIsNewCustomer.request(false));
      //Create Booking
      const newBooking: Partial<IBooking> = {
        ...action.payload.booking,
        customerId: createCustomerResult.data?.id,
      };
      yield put(BookingActions.createBooking.request(newBooking));
    } else {
      const error = createCustomerResult as IErrorResponse;
      yield put(CustomerActions.addCustomer.failed(error));
      AlertHelper.showError(error);
    }
  } catch (error) {
    yield put(BookingActions.createBookingWithNewCustomer.failed(error));
  }
}

export function* BookingWatcher() {
  yield takeEvery(BookingActions.getBookingsByBranch.requestName, getBookingsByBranch);
  yield takeEvery(BookingActions.getAvailableStylistAndTimeBlocks.requestName, getAvailableStylistAndTimeBlocks);
  yield takeEvery(BookingActions.getBookingById.requestName, getBookingById);
  yield takeEvery(BookingActions.createBooking.requestName, createBooking);
  yield takeEvery(BookingActions.getCustomerByPhone.requestName, getCustomerByPhone);
  yield takeEvery(BookingActions.editCustomer.requestName, editCustomer);
  yield takeEvery(BookingActions.addCustomer.requestName, addCustomer);
  yield takeEvery(BookingActions.editBooking.requestName, editBooking);
  yield takeEvery(BookingActions.updateStatus.requestName, updateBookingStatus);
  yield takeEvery(BookingActions.getTodayBookings.requestName, getTodayBookings);
  yield takeEvery(BookingActions.createBookingWithNewCustomer.requestName, createNewBookingWithCustomer);
}
