import { Card } from 'components/atoms';
import { CurrencyHelper } from 'helpers';
import { t, translations } from 'utils';
import React from 'react';
import { useReportContext } from './context';
import { ReportFieldValue } from './ReportFieldValue';
import { Divider } from './Divider';
import { SkeletonLoading } from './SkeletonLoading';
import clsxm from 'clsxs/clsxm';

export const GiftCardAndPromotionsReport = () => {
	const { loading, report } = useReportContext();
	return (
		<div className={clsxm('h-fit')}>
			<Card title={t(translations.ownerFinancialSummaryReport.otherFinance)}>
				{loading ? (
					<SkeletonLoading />
				) : (
					<>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.giftCard)}
							middleValue={t(translations.ownerFinancialSummaryReport.quantity)}
							bold
							big
						/>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.giftCardSold)}
							value={CurrencyHelper.formatPrice(report?.giftCardSold)}
							middleValue={report?.giftCardSoldQuantity.toString()}
						/>
						<ReportFieldValue
							title={t(
								translations.ownerFinancialSummaryReport.promotionGiftCard
							)}
							value={CurrencyHelper.formatPrice(report?.giftCardPromote)}
							middleValue={report?.giftCardPromoteQuantity.toString()}
						/>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.giftCardUsed)}
							value={CurrencyHelper.formatPrice(report?.giftCardUsed)}
							middleValue={report?.giftCardUsedQuantity.toString()}
						/>
						<Divider />
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.totalBalance)}
							value={CurrencyHelper.formatPrice(report?.giftCardBalance)}
							bold
							big
						/>
						<Divider />
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.totalTip)}
							value={CurrencyHelper.formatPrice(report?.tip)}
							bold
							big
						/>
						<Divider />
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.totalDiscount)}
							value={CurrencyHelper.formatPrice(report?.discount)}
							bold
							big
						/>
					</>
				)}
			</Card>
		</div>
	);
};
