import { Button, ButtonGroup, Description } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { BookingHelper, CurrencyHelper } from 'helpers';
import { calculateTotalAmountBillDetails } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import I18n from 'i18n-js';
import _, { isEmpty, sumBy, uniqBy } from 'lodash';
import React, { useRef, useState } from 'react';
import { t, translations, _t } from 'utils';
import { DiscounterType } from 'utils/Consts';
import { useBillDetailContext } from '../context';

interface ISharingMethod {
	id: DiscounterType;
	label: string;
}

const RefundSharingTypes: ISharingMethod[] = [
	{
		id: DiscounterType.BOTH,
		label: I18n.t(_t(translations.checkout.both)),
	},
	{
		id: DiscounterType.OWNER_ONLY,
		label: I18n.t(_t(translations.checkout.ownerOnly)),
	},
	{
		id: DiscounterType.EMPLOYEE_ONLY,
		label: I18n.t(_t(translations.checkout.employeeOnly)),
	},
];

export const RefundModal = (props: { onCancel: () => void }) => {
	const { bill, billDetails, setRefund, setRefundDetails, setRefunding } =
		useBillDetailContext();
	const numpadRef = useRef<INumPadRef>();
	const [sharingType, setSharingType] = useState(RefundSharingTypes[0]);
	const [refundValue, setRefundValue] = useState(0);
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const taxPercent = useAppSelector(
		(state) => state.BranchReducer.currentBranch?.taxPercent
	);
	const onUpdateText = (text: string) => {
		if (numpadRef) {
			numpadRef.current?.setText(text);
		}
	};
	const onExact = () => {
		onUpdateText(`${getMaxAmount()}`);
	};
	const uniqStylists = uniqBy(
		bill?.billDetails
			.filter((x) => isEmpty(x.giftCardId))
			.map((x) => x.stylist),
		(s) => s?.id
	);
	const getRefundByStaff = (stylistId: string, isLast: boolean): number => {
		const billSameStaff = bill?.billDetails.filter(
			(x) => x.stylistId === stylistId
		);
		const totalServiceSameStylist = sumBy(
			billSameStaff,
			(x) => (x?.price || 0) + (x?.extraAmount || 0)
		);
		const refundPercent =
			(totalServiceSameStylist /
				calculateTotalAmountBillDetails(bill?.billDetails || [])) *
			100;
		let employeeSharingAmount = 0;
		switch (sharingType.id) {
			case DiscounterType.BOTH:
				employeeSharingAmount = refundValue / 2;
				break;
			case DiscounterType.EMPLOYEE_ONLY:
				employeeSharingAmount = refundValue;
				break;
			case DiscounterType.OWNER_ONLY:
				employeeSharingAmount = 0;
				break;
			default:
				employeeSharingAmount = 0;
				break;
		}
		const refundAuto = CurrencyHelper.priceFixed(
			(refundPercent * employeeSharingAmount) / 100
		);

		return refundAuto;
	};
	const getOwnerRefund = () => {
		let ownerSharingAmount;
		switch (sharingType.id) {
			case DiscounterType.BOTH:
				ownerSharingAmount = refundValue / 2;
				break;
			case DiscounterType.EMPLOYEE_ONLY:
				ownerSharingAmount = 0;
				break;
			case DiscounterType.OWNER_ONLY:
				ownerSharingAmount = refundValue;
				break;
			default:
				ownerSharingAmount = 0;
				break;
		}
		return ownerSharingAmount;
	};
	const getMaxAmount = () => {
		return _.sumBy(billDetails, (billDetail) => {
			const maxAmount =
				(branch?.discountBeforeTax
					? BookingHelper.calculateBookingDetailAmount({
							item: billDetail.item,
							discount: billDetail.discount || 0,
							promotionDiscount: billDetail.promotionDiscount || 0,
					  }) < 0
						? 0
						: BookingHelper.calculateBookingDetailAmount({
								item: billDetail.item,
								discount: billDetail.discount || 0,
								promotionDiscount: billDetail.promotionDiscount || 0,
						  })
					: billDetail.amount || 0) + (billDetail.extraAmount || 0);
			return maxAmount + (maxAmount * (branch?.taxPercent || 0)) / 100;
		});
	};
	const onConfirm = () => {
		let ownerSharingAmount, employeeSharingAmount;
		switch (sharingType.id) {
			case DiscounterType.BOTH:
				ownerSharingAmount = refundValue / 2;
				employeeSharingAmount = refundValue / 2;
				break;
			case DiscounterType.EMPLOYEE_ONLY:
				ownerSharingAmount = 0;
				employeeSharingAmount = refundValue;
				break;
			case DiscounterType.OWNER_ONLY:
				ownerSharingAmount = refundValue;
				employeeSharingAmount = 0;
				break;
			default:
				break;
		}
		setRefund({
			ownerSharingAmount,
			employeeSharingAmount,
			totalAmount: refundValue,
			refundSharingType: sharingType.id,
		});
		const newRefundDetails = (billDetails || []).map((detail) => {
			const refundForDetail =
				(refundValue * ((detail.amount || 0) + (detail.extraAmount || 0))) /
				calculateTotalAmountBillDetails(billDetails || []);
			const originalAmount = CurrencyHelper.priceFixed(
				(refundForDetail / ((taxPercent || 0) + 100)) * 100
			);
			const tax = CurrencyHelper.priceFixed(refundForDetail - originalAmount);
			return {
				...detail,
				id: undefined,
				billDetailId: detail.id,
				amount: -originalAmount,
				tax: -tax,
			};
		});
		setRefundDetails(newRefundDetails || []);
		setRefunding(true);
	};
	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			isMoney
			onChange={(x) => {
				const maxValue = getMaxAmount() || 0;
				if (parseFloat(x) > maxValue) {
					setRefundValue(maxValue);
					onUpdateText(`${maxValue}`);
				} else {
					setRefundValue(parseFloat(x) || 0);
				}
			}}
			showNumpad
			unit={'Usd'}
			title={'Refund Bill'}
			onConfirm={(x) => {
				onConfirm();
				props.onCancel();
			}}
		>
			<div className="flex flex-col gap-2 h-full flex-1 p-4">
				<h5 className="font-bold">Sharing Method</h5>
				<ButtonGroup<ISharingMethod>
					buttons={RefundSharingTypes}
					buttonClassNames={['flex-1', 'flex-1', 'flex-1']}
					activeClassName={'flex-1'}
					small
					value={sharingType}
					onChange={(t) => {
						setSharingType(t);
					}}
					valueField={'id'}
					titleField="label"
				/>
				<Description
					title={`Refund by Owner`}
					value={CurrencyHelper.formatPrice(getOwnerRefund())}
				/>
				{uniqStylists.map((stylist, index) => {
					return (
						<Description
							title={`Refund by ${stylist?.firstName || ''} ${
								stylist?.lastName || ''
							}`}
							value={CurrencyHelper.formatPrice(
								getRefundByStaff(
									`${stylist?.id}`,
									index === uniqStylists.length - 1
								)
							)}
						/>
					);
				})}
				<div className="flex flex-1 gap-2 items-end">
					<div className="flex w-full items-center justify-between">
						<div>
							<Description
								title={t(translations.billing.maximumRefundAmount)}
								value={`${CurrencyHelper.formatPrice(getMaxAmount() || 0)}`}
							/>
						</div>
						<div className="flex gap-2">
							<Button
								onClick={() => onExact()}
								small
								title={t(translations.checkout.exact)}
								className="btn-outline btn-primary"
							/>
							<Button
								onClick={() => {
									if (numpadRef) {
										numpadRef.current?.setText('');
									}
								}}
								small
								title={t(translations.clear)}
								className="btn-outline btn-error"
							/>
						</div>
					</div>
				</div>
			</div>
		</ModalNumPad>
	);
};
