import React, { useEffect, useMemo, useState } from 'react';
import { mdiEmailFast } from '@mdi/js';
import { I18n, _t, translations } from 'utils';
import { useHistory } from 'react-router-dom';
import { ITextMessage, ITextMessageSendRequest } from 'models/ITextMessage';
import TextMessageApiService from 'services/TextMessageApiService';
import { IApiResponse, IErrorResponse } from 'models';
import { AlertHelper } from 'helpers';
import moment from 'moment';
import Table, { EColumnType, IColumnProps } from 'components/atoms/Table';
import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import {
	Button,
	ButtonGroup,
	ConfirmModal,
	Modal,
	Tag,
} from 'components/atoms';
import clsxm from 'clsxs/clsxm';
import _ from 'lodash';
import { getNameColorFromStatus } from 'helpers/colorHelper';

export const ListButton = (props: {
	onEdit: () => void;
	onDelete: () => void;
	onSend?: () => void;
	isPending?: boolean;
	isSelected?: boolean;
}) => {
	return (
		<div className="flex gap-4 items-center justify-end">
			<div
				className={clsxm(
					props.isPending
						? `cursor-pointer rounded border border-[#0a5984] p-2 bg-white`
						: `hidden`
				)}
				onClick={() => {
					props.onSend && props.onSend();
				}}
			>
				<Icon path={mdiEmailFast} className="w-6 h-6" />
			</div>
			<div
				className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
				onClick={() => props.onEdit()}
			>
				<Icon path={mdiPencilOutline} className="w-6 h-6" />
			</div>
			<div
				className="cursor-pointer rounded border border-DELETED p-2 bg-white"
				onClick={() => props.onDelete()}
			>
				<Icon path={mdiDeleteOutline} className="text-DELETED w-6 h-6" />
			</div>
		</div>
	);
};

const ListTextMessage = () => {
	const history = useHistory();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState(false);
	const [textMessageId, setTextMessageId] = useState('');
	const listMessageStatus = useMemo(() => [-1, 0, 1], []);
	const [showModalSend, setShowModalSend] = useState(false);

	const MessageStatus = _.map(listMessageStatus, (x) => ({
		id: x,
		title: ` ${
			x === -1 ? 'All' : I18n.t(_t(translations.messageStatus[`status_${x}`]))
		}`,
	}));
	const [status, setStatus] = useState(MessageStatus[0]);

	const handleSend = async () => {
		const data: Partial<ITextMessageSendRequest> = {
			marketingTextMessageIds: [textMessageId],
		};
		setLoading(true);
		const result = await TextMessageApiService.sendTextMessage(data);
		setLoading(false);
		setShowModalSend(false);
		if (result.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.textMessageDetail.messageSendSuccess))
			);
			//history.goBack();
		} else {
			setLoading(false);
			AlertHelper.showError(result);
		}
		setLoading(false);
	};

	const columns = useMemo<IColumnProps<ITextMessage>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.textMessage.title)),
				key: 'title',
				type: EColumnType.TEXT,
				renderCell: (data) => {
					return (
						<div className="flex align-middle justify-between">
							{data.title}
						</div>
					);
				},
			},
			{
				header: I18n.t(_t(translations.textMessage.content)),
				key: 'content',
				type: EColumnType.TEXT,
				renderCell: (data) => {
					return (
						<p className={clsxm('text-sm m-0 whitespace-normal')}>
							{data.content}
						</p>
					);
				},
				cellClassName: 'w-1/3',
			},
			{
				header: I18n.t(_t(translations.textMessage.customerReach)),
				key: 'customerReach',
				type: EColumnType.TEXT,
				renderCell: (data) => {
					return (
						<div className="flex text-right justify-end">
							{data.customerReach}
						</div>
					);
				},
			},
			{
				header: I18n.t(_t(translations.textMessage.sentTime)),
				key: 'sentTime',
				renderCell: (data) => {
					return (
						<div className="align-middle flex">
							<div>
								{data.sentTime ? moment(data.sentTime).format('LLL') : ''}
							</div>
						</div>
					);
				},
			},
			{
				header: I18n.t(_t(translations.status)),
				key: 'status',
				renderCell: (data) => {
					return (
						<div className="">
							<Tag
								label={I18n.t(
									_t(translations.messageStatus[`status_${data.status}`])
								)}
								className={clsxm(`bg-${getNameColorFromStatus(data.status)} `)}
							/>
						</div>
					);
				},
			},

			{
				header: 'Actions',
				key: 'id',
				renderCell: (data: ITextMessage) => {
					return (
						<ListButton
							onEdit={() => history.push(`text-message/edit/${data.id}`)}
							onDelete={() => {
								setTextMessageId(data.id);
								setShowModal(true);
							}}
							onSend={() => {
								setTextMessageId(data.id);
								setShowModalSend(true);
							}}
							isPending={data.status === 0 ? true : false}
						/>
					);
				},
			},
		];
	}, []);

	const onDeleteTextMessage = async () => {
		setLoading(true);
		try {
			const result = await TextMessageApiService.deleteTextMessage(
				textMessageId
			);
			setShowModal(false);
			setLoading(false);

			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.textMessageDetail.messageDeleteSuccess))
				);
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {}
	};

	return (
		<>
			<Table<ITextMessage>
				queryUrl={'/MarketingTextMessage/get-all-marketing-text-messages'}
				renderHeader={
					<div className="flex align-middle justify-between">
						<ButtonGroup<{ id: number; title: string }>
							buttons={MessageStatus}
							valueField={`id`}
							titleField="title"
							value={status}
							onChange={(x) => setStatus(x)}
							buttonClassNames={listMessageStatus.map((x) => {
								if (x === -1) {
									return '';
								}
								return clsxm(
									`text-${getNameColorFromStatus(
										x
									)} border-${getNameColorFromStatus(x)}`
								);
							})}
							className="mx-4"
						/>
						<Button
							onClick={() => history.push(`text-message/add-new`)}
							small
							title={I18n.t(_t(translations.addNew))}
							iconBefore={<Icon path={mdiPlus} className="w-6 h-6" />}
							primary
						/>
					</div>
				}
				columns={columns as any}
				showSearch
				additionalParams={{
					status: `${status.id === -1 ? '' : status.id}`,
				}}
				// className="max-h-[calc(100vh-27.3rem)] w-full"
				searchPlaceHolder={I18n.t(_t(translations.textMessage.search))}
			/>
			<ConfirmModal
				visible={showModal}
				title={I18n.t(_t(translations.delete))}
				onNegativeClick={() => setShowModal(false)}
				onPositiveClick={() => onDeleteTextMessage()}
				loading={isLoading}
				subTitle={I18n.t(
					_t(translations.textMessageDetail.deleteConfirmContent)
				)}
			/>
			<ConfirmModal
				visible={showModalSend}
				title={I18n.t(_t(translations.textMessage.send))}
				onNegativeClick={() => {
					setShowModalSend(false);
				}}
				onPositiveClick={() => handleSend()}
				loading={isLoading}
				subTitle={I18n.t(_t(translations.textMessage.sendConfirm))}
			/>
		</>
	);
};

export default ListTextMessage;
function setLoading(arg0: boolean) {
	throw new Error('Function not implemented.');
}
