import { Avatar, Button, IColumnProps, Icon, Switch } from 'components/atoms';
import { IApiResponse, IEmployee, IErrorResponse } from 'models';
import React, { useMemo, useRef } from 'react';
import { I18n, _t, translations } from 'utils';
import { useHistory } from 'react-router-dom';
import { getShortName } from 'helpers/StringHelper';
import Table, { EColumnType, ITableRef } from 'components/atoms/Table';
import { mdiPencilOutline, mdiPlus } from '@mdi/js';
import { join } from 'lodash';
import EmployeeApiService from 'services/EmployeeApiService';
import { AlertHelper } from 'helpers';

export const Staffs = () => {
	const history = useHistory();

	const tableRef = useRef<ITableRef>();

	const columns = useMemo<IColumnProps<IEmployee>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.customerList.name)),
				key: 'id',
				renderCell: (data) => {
					const staff = data;
					const staffName = `${staff.firstName || ''} ${staff.lastName || ''}`;
					return (
						<div className="align-middle flex">
							<div>
								<Avatar small name={getShortName(staffName)} />
							</div>
							<span className="m-auto ml-4">{staffName}</span>
						</div>
					);
				},
			},
			{
				header: I18n.t(_t(translations.customerList.role)),
				key: 'employeeTypeCode',
				renderCell: (data) => {
					return (
						<p>
							{join(
								data!.employeeTypes?.map((x) => x.name),
								', '
							)}
						</p>
					);
				},
			},
			{
				header: I18n.t(_t(translations.customerList.startDate)),
				key: 'hireDate',
				type: EColumnType.DATE,
			},
			{
				header: I18n.t(_t(translations.customerList.mobile)),
				key: 'phone',
				type: EColumnType.PHONE_NUMBER,
			},
			{
				header: I18n.t(_t(translations.email)),
				key: 'email',
			},
			// {
			// 	header: I18n.t(_t(translations.customerList.dateOfBirth)),
			// 	key: 'dateOfBirth',
			// 	type: EColumnType.DATE,
			// },
			{
				header: 'Commission (%)',
				key: 'servicesCommissionPercent',
				// type: EColumnType.NUMBER,
				renderCell: (data) => {
					return (
						<p className="flex justify-center">
							{data.servicesCommissionPercent}
						</p>
					);
				},
			},
			{
				header: I18n.t(_t(translations.status)),
				key: 'status',
				renderCell: (data) => {
					let isChecked = data?.status! === 0 ? false : true;
					return (
						<Switch
							value={isChecked}
							onChange={() => {
								onChangeStatus(data!);
							}}
							primary
						></Switch>
					);
				},
			},
			{
				header: I18n.t(_t(translations.staff.bookedOnlineStatus)),
				key: 'canBookedOnline',
				renderCell: (data) => {
					return (
						<Switch
							value={data?.canBookedOnline}
							onChange={() => {
								onChangeBookedOnlineStatus(data!);
							}}
							primary
						></Switch>
					);
				},
			},
			{
				header: I18n.t(_t(translations.action)),
				key: 'id',
				renderCell: (data) => {
					return (
						<div className="flex gap-4 items-center">
							<div
								className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
								onClick={(e) => {
									e.stopPropagation();
									history.push(`staffs/edit/${data!.id}`);
								}}
							>
								<Icon path={mdiPencilOutline} className="w-6 h-6" />
							</div>
						</div>
					);
				},
			},
		];
	}, []);

	const onChangeStatus = async (data: IEmployee) => {
		const updateRequest: Partial<IEmployee> = {
			id: data.id,
			status: data.status === 0 ? 1 : 0,
		};
		const updateCanBookedOnlineRequest: Partial<IEmployee> = {
			id: data.id,
			canBookedOnline: updateRequest.status === 0 ? false : true,
		};
		await EmployeeApiService.updateBookedOnlineStatus(
			updateCanBookedOnlineRequest
		);
		let response = await EmployeeApiService.updateEmployeeStatus(updateRequest);
		if (response?.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.staffDetail.messageEditSuccess))
			);
			tableRef.current?.refresh();
		} else {
			const error = response as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	const onChangeBookedOnlineStatus = async (data: IEmployee) => {
		const updateRequest: Partial<IEmployee> = {
			id: data.id,
			canBookedOnline: !data.canBookedOnline,
		};
		let response = await EmployeeApiService.updateBookedOnlineStatus(
			updateRequest
		);
		if (response?.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.staffDetail.messageEditSuccess))
			);
			tableRef.current?.refresh();
		} else {
			const error = response as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	return (
		<div className="px-2">
			<Table<IEmployee>
				queryUrl={'/Employee/get-all-employees'}
				columns={columns}
				showSearch
				renderHeader={
					<Button
						small
						primary
						title={I18n.t(_t(translations.addNew))}
						iconBefore={<Icon path={mdiPlus} />}
						onClick={() => history.push('/staffs/add-new')}
					/>
				}
				additionalParams={{ orderBy: 'firstName,lastName' }}
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				// className="max-h-[calc(100vh-21.5rem)]"
			/>
		</div>
	);
};
