import { DetailPageLayout } from 'components/molecules';
import _ from 'lodash';
import { IApiResponse, IService } from 'models';
import { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import ServiceApi from 'services/ServiceApi';
import { I18n, _t, translations } from 'utils';
import { useForm } from 'react-hook-form';
import { IServiceRequest } from 'models/RequestModels';
import { showError, showSuccess } from 'helpers/alertHelper';
import BasicInfo from './components/BasicInfo';
import PricingAndDuration from './components/PricingAndDuration';
import { useAppSelector } from 'helpers/hookHelpers';
import { ServiceActions } from 'redux/actions';
import { useDispatch } from 'react-redux';
import UploadBaseService from 'services/UploadBaseService';
import { IFileUploadResponse } from 'models/ResponseModels';

export interface UpdateServiceField extends Partial<IService> {}

export const ServiceDetail = () => {
	const { id } = useParams() as { id: string };
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const form = useForm<UpdateServiceField>({
		reValidateMode: 'onChange',
		mode: 'onChange',
		defaultValues: { supplyPrice: 0 },
	});
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	const selectedCategory = useAppSelector(
		(state) => state.CategoryReducer.selectedCategory
	);
	const shopId = useAppSelector((state) => state.ShopReducer.shopId);
	const branchId = useAppSelector(
		(state) => state.BranchReducer.currentBranch?.id
	);

	const [service, setService] = useState<IService>();
	const [img, setImg] = useState<File | undefined>();
	const [loading, setLoading] = useState<boolean>(false);

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};

	const addServiceToState = (service: IService) =>
		dispatch(ServiceActions.addServiceToState.request(service));

	const editServiceFromState = (service: IService) =>
		dispatch(ServiceActions.editServiceFromState.request(service));

	useEffect(() => {
		if (isAdd()) {
			form.reset({
				itemCategoryId: selectedCategory?.id || categories[0].id,
			});
		}
	}, []);

	useEffect(() => {
		if (!isAdd()) {
			getServiceById();
		}
	}, []);

	const getServiceById = async () => {
		setLoading(true);
		try {
			const res = (await ServiceApi.getServiceById(
				id
			)) as IApiResponse<IService>;
			if (res.succeeded) {
				setService(res.data);
				form.reset({
					name: res.data?.name,
					price: res.data?.price,
					supplyPrice: res.data?.supplyPrice,
					duration: res.data?.duration,
					description: res.data?.description,
					itemCategoryId: res.data?.itemCategoryId,
					imageUrl: res.data?.imageUrl,
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const submitForm = async (formData: UpdateServiceField) => {
		setLoading(true);
		let imgUrl = service?.imageUrl;
		if (img) {
			const uploadResult = (await UploadBaseService.uploadImage([
				img,
			])) as IApiResponse<IFileUploadResponse[]>;
			if (uploadResult.succeeded) {
				imgUrl = uploadResult.data![0].imageUrl;
				// showSuccess('Upload Image Successfully');
			} else {
				showError(uploadResult);
			}
		}

		const data: IServiceRequest = {
			...service,
			name: formData.name!,
			price: formData.price!,
			description: formData.description,
			itemCategoryId: formData.itemCategoryId!,
			duration: formData.duration!,
			status: 1,
			shopId: shopId || '',
			branchIds: [branchId || ''],
			supplyPrice: formData.supplyPrice,
			imageUrl: imgUrl,
		};
		const res = (
			isAdd()
				? await ServiceApi.addNewService(data)
				: await ServiceApi.editService(data)
		) as IApiResponse;
		setLoading(false);

		if (res.succeeded) {
			showSuccess(
				isAdd()
					? I18n.t(_t(translations.customerDetail.addNewSuccess))
					: I18n.t(_t(translations.customerDetail.editSuccess))
			);
			history.push('/services');
			isAdd()
				? addServiceToState(res.data as unknown as IService)
				: editServiceFromState(res.data as unknown as IService);
		} else {
			showError(res);
		}
	};

	return (
		<DetailPageLayout<UpdateServiceField>
			title={I18n.t(
				_t(
					isAdd()
						? translations.servicesDetail.addNew
						: translations.servicesDetail.editService
				)
			)}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			loading={loading}
		>
			{(isAdd() || !_.isEmpty(service)) && (
				<>
					<BasicInfo img={img} setImg={setImg} />
					<PricingAndDuration service={service} />
				</>
			)}
		</DetailPageLayout>
	);
};
