import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IPaymentMethod } from 'models';
import { PaymentMethodActions } from 'redux/actions';

interface PaymentMethodState {
	paymentMethods: IPaymentMethod[];
}

const initialState: PaymentMethodState = {
	paymentMethods: [],
};

const getAllPaymentMethodsSuccess = (
	state: PaymentMethodState,
	action: PayloadAction<IPaymentMethod[]>
) => {
	state.paymentMethods = action.payload;
};

const addPaymentMethodSuccess = (
	state: PaymentMethodState,
	action: PayloadAction<IPaymentMethod>
) => {
	state.paymentMethods.push(action.payload);
};

const updatePaymentMethodSuccess = (
	state: PaymentMethodState,
	action: PayloadAction<IPaymentMethod>
) => {
	state.paymentMethods = state.paymentMethods.map((element) =>
		element.id === action.payload.id ? action.payload : element
	);
};

const deletePaymentMethodSuccess = (
	state: PaymentMethodState,
	action: PayloadAction<string>
) => {
	state.paymentMethods = state.paymentMethods.filter(
		(element) => element.id !== action.payload
	);
};

const paymentMethodReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(
			PaymentMethodActions.getAllPaymentMethods.success,
			getAllPaymentMethodsSuccess
		)
		.addCase(
			PaymentMethodActions.createPaymentMethod.success,
			addPaymentMethodSuccess
		)
		.addCase(
			PaymentMethodActions.updatePaymentMethod.success,
			updatePaymentMethodSuccess
		)
		.addCase(
			PaymentMethodActions.deletePaymentMethod.success,
			deletePaymentMethodSuccess
		);
});

export default paymentMethodReducer;
