import { Avatar, Card, Input, Select } from "components/atoms";
import { I18n, _t, translations } from "utils";
import React, { useEffect } from "react";
import clsxm from "clsxs/clsxm";
import { DatePicker } from "components/molecules/DatePicker";
import { DateFormInput } from "components/molecules/DateFormInput";
import { Controller, useFormContext } from "react-hook-form";
import { ICustomer } from "models";
import { UpdateCustomerField } from "..";
import { EGender } from "models/EGender";
import { MaskInput } from "components/atoms/MaskInput";
import { getShortName } from "helpers/StringHelper";
import { useAppSelector } from "helpers/hookHelpers";
import moment from "moment";

const FormBasicInfo = (props: { customer?: ICustomer }) => {
  const {
    formState: { errors },
    watch,
    setValue,
    register,
    getValues,
    control,
  } = useFormContext<UpdateCustomerField>();
  const gender = watch("gender");

  useEffect(() => {
    register("gender");
  }, [register]);
  return (
    <Card
      title={I18n.t(_t(translations.customerDetail.basicInfo))}
      description={I18n.t(_t(translations.customerDetail.description))}
    >
      <div className="grid gap-y-8">
        <div className="grid grid-cols-6 pt-8 gap-x-8">
          <div>
            <Avatar
              name={
                !props.customer
                  ? "A"
                  : getShortName(
                      `${props.customer.firstName || ""} ${
                        props.customer.lastName || ""
                      }`
                    )
              }
            />
          </div>
          <Input
            label={I18n.t(_t(translations.customerDetail.firstName))}
            placeholder={I18n.t(_t(translations.placeHolder.firstName))}
            maxLength={256}
            error={errors.firstName?.message}
            {...register("firstName", {
              required: I18n.t(
                _t(translations.customerDetail.messageFirstName)
              ),
            })}
            // defaultValue={props.isAdd ? "" : selectedCustomer?.firstName}
          />
          <Input
            label={I18n.t(_t(translations.customerDetail.lastName))}
            placeholder={I18n.t(_t(translations.placeHolder.lastName))}
            maxLength={256}
            error={errors.lastName?.message}
            {...register("lastName")}
            // defaultValue={props.isAdd ? "" : selectedCustomer?.lastName}
          />
        </div>
        <div className={clsxm("border-b border-b-base-200 my-2")} />
        <div className="grid grid-cols-2 gap-x-8">
          <div>
            <p className={clsxm("card-title m-0")}>
              {I18n.t(_t(translations.customerDetail.contact))}
            </p>
            <p className={clsxm("m-0 text-sm")}>
              {I18n.t(_t(translations.customerDetail.contactDescription))}
            </p>
            <div className="grid grid-cols-2 gap-x-8">
              <Input
                label={I18n.t(_t(translations.email))}
                placeholder={I18n.t(_t(translations.placeHolder.email))}
                error={errors.email?.message}
                {...register("email", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                // defaultValue={props.isAdd ? "" : selectedCustomer?.email}
              />
              <Controller
                render={(inputProps) => (
                  <MaskInput
                    error={errors.phone?.message}
                    label={I18n.t(_t(translations.phone))}
                    placeholder={I18n.t(_t(translations.placeHolder.phone))}
                    {...inputProps.field}
                    mask="999-999-9999"
                  />
                )}
                defaultValue={props.customer ? props.customer.phone : ""}
                rules={{
                  required: "Please enter customer's phone number",
                }}
                control={control}
                name={"phone"}
              />
            </div>
          </div>
          <div>
            <p className={clsxm("card-title m-0")}>
              {I18n.t(_t(translations.customerDetail.personalInfo))}
            </p>
            <p className={clsxm("m-0 text-sm")}>
              {I18n.t(_t(translations.customerDetail.personalInfoDescription))}
            </p>
            <div className="grid grid-cols-2 gap-x-8">
              <DateFormInput
                error={errors.dateOfBirth?.message}
                label={I18n.t(_t(translations.customerDetail.birthDate))}
                rules={{ required: false }}
                placeholder="Date of birth"
                control={control as any}
                name={"dateOfBirth"}
                defaultValue={
                  props.customer
                    ? props.customer.dateOfBirth
                      ? moment(props.customer.dateOfBirth).format("MM/DD/YYYY")
                      : ""
                    : ""
                }
              />
              <Select<{ id: EGender; label: string }>
                options={[
                  {
                    id: EGender.Male,
                    label: "Male",
                  },
                  {
                    id: EGender.Female,
                    label: "Female",
                  },
                  {
                    id: EGender.NoInformation,
                    label: "No Information",
                  },
                ]}
                titleField={"label"}
                keyField={"id"}
                label={I18n.t(_t(translations.bookingCustomerInfo.gender))}
                title={"Select Gender"}
                fullWidth
                valueId={gender}
                onChange={(value) => setValue("gender", value.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default FormBasicInfo;
