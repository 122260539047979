import React, { Component, useEffect, useState } from 'react';

import { I18n, _t, translations } from 'utils';
import * as images from './images';
import { convertStringToUpperCaseFirstChar } from 'helpers/StringHelper';
import RatingApiService from 'services/RatingApiService';
import BranchApiService from 'services/BranchApiService';
import { IBranch } from 'models/IBranch';
import { showError } from 'helpers/alertHelper';
import {
	Button,
	Card,
	Container,
	Icon,
	Input,
	TextArea,
} from 'components/atoms';
import { StringHelper } from 'helpers';
import _, { findIndex, findLastIndex } from 'lodash';
import { mdiCheckCircle } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { ICreateRatingRequest } from 'models/RequestModels';

interface IRatingProps {}

const getUrl = () => {
	let path = window.location.pathname;
	let str = path.split('/');
	let branchId = str[2];
	let bookingId = str[3];
	return {
		branchId,
		bookingId,
	};
};
const getBranchById = async () => {
	const { branchId } = getUrl();
	const response = await BranchApiService.getBranchById(branchId);
	if (response.succeeded && response.data) {
		return response.data;
	} else {
		showError(response.errors);
		return undefined;
	}
};
const Header = () => {
	return (
		<div className="flex items-center justify-center main-gradient-background w-screen fixed z-50 h-16">
			<h4 className="text-white uppercase m-0 text-2xl inline font-bold subpixel-antialiased">
				Rating
			</h4>
		</div>
	);
};
const defaultRating = [
	{ id: '1', value: '1', isChecked: false },
	{ id: '2', value: '2', isChecked: false },
	{ id: '3', value: '3', isChecked: false },
	{ id: '4', value: '4', isChecked: false },
	{ id: '5', value: '5', isChecked: false },
];
const Rating = (props: {
	branchName?: string;
	address?: string;
	phone?: string;
	email?: string;
	onRating: (rateStar: number, comment: string) => void;
}) => {
	const {
		branchName = '',
		address = '',
		phone = '',
		email = '',
		onRating,
	} = props;

	const [rating, setRating] = useState(defaultRating);
	const [comment, setComment] = useState('');

	let starRating = findLastIndex(rating, (x) => x.isChecked) + 1;

	useEffect(() => {
		if (starRating >= 4) {
			onRating(starRating, comment);
		}
	}, [starRating]);

	return (
		<div className="flex flex-col items-center justify-center w-screen text-center">
			<div className="h-24" />
			<div className="mt-0">
				<h1 className="font-normal mb-2">{branchName}</h1>
				<h3 className="font-normal mt-0">
					{convertStringToUpperCaseFirstChar(address)}
				</h3>
				<h4 className="cursor-pointer" style={{ color: 'rgb(3, 122, 255)' }}>
					{StringHelper.formatPhoneNumber(phone!)}
				</h4>
				<h3 className="font-normal italic text-lg	 ">{email}</h3>
			</div>
			<div className="h-4" />
			<div className="shadow-md rounded-lg w-fit m-4 p-4 lg:p-8">
				<div>
					<h3 className="font-semibold mt-2">
						{' '}
						{I18n.t(_t(translations.rating.tellUs))}
					</h3>
					<div className="rating rating-lg">
						{rating.map((x, i) => {
							return (
								<input
									id={x.id}
									key={x.id}
									type="radio"
									name="rating-2"
									className={clsxm(
										`mask mask-star-2 h-14 w-14`,
										x.isChecked ? 'bg-PENDING' : 'bg-base-300'
									)}
									value={x.value}
									onClick={(e) => {
										let newRating = Array.from(defaultRating);
										newRating.forEach((x) => {
											if (Number(x.id) <= Number(e.currentTarget.id)) {
												x.isChecked = true;
											} else {
												x.isChecked = false;
											}
										});
										setRating(newRating);
									}}
								/>
							);
						})}
					</div>
					{starRating <= 3 && starRating ? (
						<h4 className="font-normal mt-2 italic">
							{I18n.t(_t(translations.rating.leaveUsaComment))}
						</h4>
					) : null}
					{starRating <= 3 && starRating ? (
						<TextArea
							// className="textarea textarea-primary w-full mt-2"
							onChange={(e) => setComment(e.target.value)}
							value={comment}
							rows={4}
							placeholder="Input your reviews..."
						/>
					) : null}
				</div>
				{starRating <= 3 && starRating !== 0 && (
					<div className="text-right mt-4">
						<Button
							small
							title={I18n.t(_t(translations.checkout.confirm))}
							onClick={() => onRating(starRating, comment)}
							disabled={_.isEmpty(comment)}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

const Success = (props: { branch?: IBranch }) => {
	const { branch } = props;
	const REVIEW_PAGE = [
		{
			key: 'google',
			imgUrl: images.google,
			callBack: () => window.open(branch?.googleReviewUrl),
		},
		{
			key: 'yelp',
			imgUrl: images.yelp,
			callBack: () => window.open(branch?.yelpReviewlUrl),
		},
	];
	return (
		<div className="items-center justify-center w-screen text-center">
			<div className="h-24" />
			<div className="mx-auto">
				<Icon
					className="w-20 h-20 mx-auto"
					path={mdiCheckCircle}
					color="#52c41a"
				/>
				<h3 className="font-bold">
					{I18n.t(_t(translations.rating.ratingSuccess))}
				</h3>
				<h3 className="font-normal">
					{I18n.t(_t(translations.rating.haveNiceDay))}
				</h3>
				<div className="mt-9 mx-8 justify-center flex flex-col md:flex-row gap-4  ">
					{REVIEW_PAGE.map((x) => {
						return (
							<Button
								key={x.key}
								title={I18n.t(_t(translations.rating.vote))}
								onClick={x.callBack}
								iconAfter={
									<img src={x.imgUrl} className=" h-9 m-0" alt={x.key} />
								}
								small
								className="px-6 normal-case bg-white border-x-secondary text-black"
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};
const RatingPage = (props: IRatingProps) => {
	const [branch, setBranch] = useState<IBranch>();
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		const getBranch = async () => {
			setBranch(await getBranchById());
		};
		getBranch();
	}, []);

	useEffect(() => {
		return () => {
			setSuccess(false);
		};
	}, []);

	const onRating = (rateStar: number, comment: string) => {
		const params: ICreateRatingRequest = {
			bookingId: getUrl().bookingId,
			comment: comment,
			rate: rateStar,
			status: 1,
		};
		RatingApiService.createRating(params);
		if (rateStar > 3 ){
			if (!_.isEmpty(branch?.googleReviewUrl)){
				window.open(branch?.googleReviewUrl)
			} else {
				if (!_.isEmpty(branch?.yelpReviewlUrl)){
					window.open(branch?.yelpReviewlUrl)
				}
			}
		}
		setSuccess(true);
	};

	let address = '';
	!_.isEmpty(branch?.address)
		? !_.isEmpty(branch?.address!.street) ||
		  !_.isEmpty(branch?.address!.city) ||
		  !_.isEmpty(branch?.address!.state)
			? (address =
					`${
						!_.isEmpty(branch?.address?.street)
							? `${branch?.address!.street} -`
							: ''
					}` + `${branch?.address!.city}`)
			: (address = '')
		: (address = '');
	return (
		<div className="bg-white h-screen">
			<Header />
			{success ? (
				<Success branch={branch} />
			) : (
				<Rating
					address={address}
					branchName={branch?.name}
					email={branch?.email}
					phone={branch?.phone}
					onRating={onRating}
				/>
			)}
		</div>
	);
};

export default RatingPage;
