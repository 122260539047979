import { Button } from 'components/atoms/Button';
import moment, { Moment, MomentFormatSpecification } from 'moment';
import { useEffect, useState } from 'react';
import { CalendarModal } from './ModalCalendar';
import { mdiCalendar } from '@mdi/js';
import { Icon } from 'components/atoms/Icon';
import clsxm from 'clsxs/clsxm';
interface IDatePickerProps {
	selectedDate?: Moment;
	onSelectDate?: (date: Moment) => void;
	format?: MomentFormatSpecification;
	containerStyle?: any;
	disabled?: boolean;
	disabledBefore?: boolean;
	disabledAfter?: boolean;
	className?: string;
	title?: string;
	onShowModal?: () => void;
}

export const DatePicker = (props: IDatePickerProps) => {
	const { format = 'dddd DD MMM,YYYY' } = props;
	const [showModal, setShowModal] = useState(false);
	const [selectedDate, setSelectedDate] = useState(
		props.selectedDate || moment()
	);
	const onSelectDate = (date: Moment) => {
		setSelectedDate(date);
		props.onSelectDate && props.onSelectDate(date);
		setTimeout(() => {
			setShowModal(false);
		}, 100);
	};
	useEffect(() => {
		setSelectedDate(props.selectedDate || moment());
	}, [props.selectedDate]);
	return (
		<>
			<Button
				className={clsxm(
					'bg-white rounded-full btn-outline btn-primary capitalize font-medium min-w-[200px]',
					props.className,
					props.disabled && 'btn-outline border-base-300 text-base-300'
				)}
				small
				disabled={props.disabled}
				title={props.title || `${selectedDate!.format(`${format}`)}`}
				iconAfter={<Icon path={mdiCalendar} />}
				onClick={() => {
					setShowModal(true);
					props.onShowModal && props.onShowModal();
				}}
			/>
			{showModal && (
				<CalendarModal
					disabledBefore={props.disabledBefore}
					selectedDate={selectedDate}
					onSelectDate={(date) => onSelectDate(date)}
					onCancel={() => setShowModal(false)}
					disabledAfter={props.disabledAfter}
				/>
			)}
		</>
	);
};
