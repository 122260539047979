import { Icon } from 'components/atoms/Icon';
import { mdiChevronRight } from '@mdi/js';
import { getCoordsDistance } from 'helpers/mapHelpers';
import _ from 'lodash';
import { IBranch, ILocation } from 'models/IBranch';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BranchActions, CategoryActions, ServiceActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';

const getLocation = () => {
	return new Promise((res, rej) => {
		navigator.geolocation.getCurrentPosition(res, rej);
	});
};

const orderByLocation = async (branches: IBranch[]) => {
	try {
		const currentLocation = (await getLocation()) as any;
		if (!currentLocation.coords) {
			return branches;
		}
		const location: ILocation = {
			latitude: currentLocation.coords.latitude,
			longitude: currentLocation.coords.longitude,
		};
		return _.orderBy(
			_.map(branches, (branch) => {
				const distance = getCoordsDistance(location, branch.location!);
				return {
					...branch,
					distance,
				};
			}),
			(x) => x.distance
		);
	} catch (error) {
		return branches;
	}
};

export const SelectBranch = () => {
	const branches = useSelector(
		(state: RootState) => state.BranchReducer.branches
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const [orderedBranches, setOrderedBranches] = useState(branches);
	const updateOrderedBranches = async () => {
		const list = await orderByLocation(branches);
		setOrderedBranches(list);
	};
	useEffect(() => {
		if (!_.isEmpty(branches)) {
			if (branches.length > 1) {
				updateOrderedBranches();
			} else {
				onSelectBranch(branches[0]);
			}
		}
	}, [branches]);
	const onSelectBranch = (branch: IBranch) => {
		dispatch(BranchActions.selectBranch.request(branch));
		setTimeout(() => {
			dispatch(CategoryActions.getAllCategory.request());
			dispatch(ServiceActions.getAllServices.request());
		});
		history.push({
			pathname: `/customer-booking`,
			search: `?step=${2}`,
		});
	};
	return (
		<div className="bg-white rounded-md">
			<div className="py-[35px] pl-11 pr-6">
				<div>
					<InfiniteScroll
						initialLoad={true}
						pageStart={0}
						loadMore={() => {}}
						useWindow={false}
					>
						<div className="flex flex-col divide-y divide-background">
							{orderedBranches.map((branch) => {
								return (
									<div
										className="flex flex-row gap-2 py-3 cursor-pointer"
										key={branch.id}
										onClick={() => {
											onSelectBranch(branch);
										}}
									>
										<div className="basis-2/3">
											<h5 className="font-bold text-black -mb-[3px]">
												{branch.name}
											</h5>
											<span className="text-black/40 text-sm">{`${branch?.address?.street}, ${branch?.address?.city}, ${branch?.address?.state}`}</span>
										</div>
										<div className="basis-1/4 flex justify-end">
											<span className="text-black/40 text-sm">{`${
												(branch.distance || 0) / 1000
											} km`}</span>
										</div>
										<div className="basis-1/12">
											<Icon path={mdiChevronRight} />
										</div>
									</div>
								);
							})}
						</div>
					</InfiniteScroll>
				</div>
			</div>
		</div>
	);
};
