import { ReduxHelper } from 'helpers';
import { IShop } from 'models/IShop';

export const prefix = 'SHOP';

const getShop = ReduxHelper.generateActions<undefined, IShop>(
	`${prefix}/GET_SHOP`
);
const updateShop = ReduxHelper.generateActions<IShop, IShop>(
	`${prefix}/UPDATE_SHOP`
);

export { getShop, updateShop };
