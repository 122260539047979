import { Card } from 'components/atoms';
import { I18n, _t, translations } from 'utils';
import { useState } from 'react';
import clsxm from 'clsxs/clsxm';
import { useFormContext } from 'react-hook-form';

import { random } from 'lodash';
import { DatePicker } from 'components/molecules/DatePicker';
import { staffColors } from 'helpers/colorHelper';
import './styles.css';
import { IStaffDetailForm } from '../context';
import moment from 'moment';

const SelectColor = (props: { defaultColor?: string }) => {
	const [colorValue, setColorValue] = useState(props.defaultColor);
	const { setValue } = useFormContext<IStaffDetailForm>();
	return (
		<div>
			<div>
				<label className="label font-medium ">
					<span className="label-text text-md">
						{I18n.t(_t(translations.staffDetail.selectColour))}
					</span>
				</label>
				<div className="flex gap-2 flex-wrap">
					{staffColors.map((x) => {
						return (
							<div
								className={clsxm(
									'w-10 h-10 flex justify-center items-center align-middle border rounded-lg',
									colorValue === x ? 'bg-primary' : ''
								)}
								key={x}
								onClick={() => {
									setColorValue(x);
									setValue('color', x);
								}}
							>
								<input
									type="radio"
									name={`radio-${x}`}
									className={clsxm('radio w-8 h-8 rounded-lg border-0')}
									style={{ backgroundColor: x }}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const Employment = (props: {}) => {
	const { watch, setValue } = useFormContext<IStaffDetailForm>();
	const {} = props;

	return (
		<Card
			title={I18n.t(_t(translations.staffDetail.employment))}
			description={I18n.t(_t(translations.staffDetail.contactDescription))}
			id="employment"
		>
			<div className={clsxm('grid grid-cols-5 gap-x-4 gap-y-2')}>
				<div className="col-span-1" id="hire-date">
					<label className="label font-medium ">
						<span className="label-text text-md">
							{I18n.t(_t(translations.staffDetail.startDate))}
						</span>
					</label>
					<DatePicker
						className="w-full"
						format={'MM/DD/YYYY'}
						selectedDate={moment(watch('hireDate'))}
						onSelectDate={(date) => setValue('hireDate', date.toString())}
					/>
				</div>
				<div className="col-span-4" id="color">
					<SelectColor
						defaultColor={
							staffColors.includes(watch('color') || '')
								? watch('color')
								: staffColors[random(0, staffColors.length - 1)]
						}
					/>
				</div>
			</div>
		</Card>
	);
};

export default Employment;
