import { RootState } from 'redux/configuration/rootReducer';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { CalendarHelper, ColorHelper } from 'helpers';
import {
	EEmployeeTypeCode,
	EStaffStatus,
	IBooking,
	IEmployee,
	IEmployeeType,
} from 'models';
import { translations, _t, I18n } from 'utils';
import { BookingStatus } from 'utils/Consts';
import configureStore from 'redux/configuration/configureStore';
import moment from 'moment';
import { ChartData, ChartDataset } from 'chart.js';
import { IEventCalendar } from 'helpers/calendarHelper';
interface IDataTurnEmployee {
	type: string;
	id: string;
	turn: number;
	amount: number;
	label: string;
}
// const getAllEmployees = (state: RootState) => state.EmployeeReducer.employees;
const getEmployeesByBranch = (state: RootState) =>
	state.EmployeeReducer.employees || [];
const getEmployeesPool = (state: RootState) =>
	state.TodayReducer.poolStaffs
		.filter((x) => x.availableStylistStatus === EStaffStatus.AVAILABLE)
		.map((x) => x.employee) || [];
const getEmployeeTypes = (state: RootState) =>
	state.EmployeeTypeReducer.employeeTypes || [];
const getBookings = (state: RootState) => state.BookingReducer.bookings;

const getTodayBookings = (state: RootState) => {
	const bookings = Object.keys(state.BookingReducer.todayBookings).map((id) => {
		return state.BookingReducer.todayBookings[id];
	});
	return _.filter(bookings, (booking) => {
		if (booking.status === BookingStatus.PENDING) {
			return !isLateBooking(booking.startTimeExpected);
		} else {
			return true;
		}
	});
};

const getAvailableEmployeeForBooking = (state: RootState) =>
	state.BookingReducer.availableStylist;

const getListSortedEmployee = (
	employees: IEmployee[],
	bookings: IBooking[]
) => {
	const listEmployees = _.sortBy(
		employees,
		[
			(employee) => {
				const store = configureStore().store;
				const currentBranch = store.getState().BranchReducer.currentBranch;
				const staffBookings: IEventCalendar[] =
					_.filter(CalendarHelper.convertBookingToEvent(bookings), (event) => {
						return (
							event.resourceId === employee.id &&
							(event.status === BookingStatus.FINISHED ||
								event.status === BookingStatus.DONE)
						);
					}) || [];
				let turn = staffBookings.length;
				if (currentBranch?.calculateTurnMethod === 1) {
					const totalAmount = _.sumBy(
						staffBookings,
						(x) => (x.amount || 0) + (x.extraAmount || 0)
					);
					turn = Math.floor(totalAmount / (currentBranch.valuePerTurn || 1));
				}
				return turn;
			},
			// (employee) => {
			// 	const listBookingOfEmployee = _.filter(
			// 		CalendarHelper.convertBookingToEvent(bookings),
			// 		(event) => {
			// 			return event.resourceId === employee.id;
			// 		}
			// 	);
			// 	const totalPrice = _.sumBy(listBookingOfEmployee, (booking) => {
			// 		const amount = _.sumBy(booking.services, (service) => service.price);
			// 		return amount;
			// 	});
			// 	return totalPrice;
			// },
		],
		['asc']
	);
	return listEmployees;
};

export const searchEmployee = createSelector(
	[
		getEmployeesByBranch,
		(state: RootState, searchInput: string) => searchInput,
	],
	(employees, searchInput) => {
		return _.filter(
			employees,
			(employee) =>
				`${employee!.firstName}`
					.toLowerCase()
					.includes(searchInput.toLowerCase()) ||
				`${employee!.lastName!}`
					.toLowerCase()
					.includes(searchInput.toLowerCase())
		);
	}
);

export const getAllStylist = createSelector(
	[
		(state: RootState) => state.EmployeeReducer.employees || [],
		getEmployeeTypes,
	],
	(employees, types) => {
		const stylistTypes = types.filter(
			(type) => type.roleName === 'Technician' || type.roleName === 'Employee'
		);
		if (_.isEmpty(stylistTypes)) {
			return employees;
		}
		return _.filter(employees, (employee) =>
			checkIsStylist(
				stylistTypes.map((x) => x.code!),
				employee
			)
		);
	}
);

const checkIsStylist = (
	stylistCodes: string[],
	employee: IEmployee
): boolean => {
	if (!employee.employeeTypes) {
		return false;
	}
	let isStylist = false;
	// console.log('=>>>>>', employee.lastName, employee.firstName);
	for (let i = 0; i < employee.employeeTypes?.length!; i++) {
		if (stylistCodes.includes(employee.employeeTypes[i].code!)) {
			isStylist = true;
			break;
		}
	}
	return isStylist;
};

export const getEmployeesSortByTurn = createSelector(
	[
		(bookings: IBooking[]) => bookings,
		(bookings: IBooking[], employees: IEmployee[]) => employees,
	],
	(bookings, employees) => {
		return getListSortedEmployee(
			employees,
			_.filter(
				bookings,
				(x) =>
					x.status !== BookingStatus.CANCELED &&
					x.status !== BookingStatus.SUSPENDED
			)
		);
	}
);

export const getAvailableEmployeeSortByTurn = createSelector(
	[
		getAvailableEmployeeForBooking,
		(state: RootState) => state.BookingReducer.bookings,
		(state: RootState) => state.BookingReducer.currentIndex,
		(state: RootState) => state.EmployeeReducer.employees,
	],
	(employees, bookings, index, allEmployees) => {
		const listEmployees =
			index !== undefined ? employees[index] : allEmployees || [];

		return getListSortedEmployee(
			listEmployees,
			_.filter(
				bookings,
				(x) =>
					x.status !== BookingStatus.CANCELED &&
					x.status !== BookingStatus.SUSPENDED
			)
		);
	}
);

export const numberOfBookingOfEmployee = createSelector(
	[
		(bookings: IBooking[], employeeId: string) => bookings,
		(bookings: IBooking[], employeeId: string) => employeeId,
	],
	(bookings, employeeId) => {
		const store = configureStore().store;
		const currentBranch = store.getState().BranchReducer.currentBranch;
		const staffBookings: IEventCalendar[] =
			_.filter(CalendarHelper.convertBookingToEvent(bookings), (event) => {
				return (
					event.resourceId === employeeId &&
					event.status !== BookingStatus.SUSPENDED &&
					(event.status === BookingStatus.DONE ||
						event.status === BookingStatus.FINISHED)
				);
			}) || [];
		let turn = staffBookings.length;
		if (currentBranch?.calculateTurnMethod === 1) {
			const totalAmount = _.sumBy(
				staffBookings,
				(x) => (x.amount || 0) + (x.extraAmount || 0)
			);
			turn = Math.floor(totalAmount / (currentBranch.valuePerTurn || 1));
		}
		return turn;
	}
);

export const getMoneyOfEmployee = createSelector(
	[
		(bookings: IBooking[], employeeId: string) => bookings,
		(bookings: IBooking[], employeeId: string) => employeeId,
	],
	(bookings, employeeId) => {
		const staffBookings: IEventCalendar[] =
			_.filter(CalendarHelper.convertBookingToEvent(bookings), (event) => {
				return (
					event.resourceId === employeeId &&
					event.status !== BookingStatus.SUSPENDED &&
					(event.status === BookingStatus.DONE ||
						event.status === BookingStatus.FINISHED)
				);
			}) || [];
		let turn = staffBookings.length;

		return _.sumBy(
			staffBookings,
			(x) => (x.amount || 0) + (x.extraAmount || 0)
		);
	}
);

const convertStatusToChartType = (status: number) => {
	switch (status) {
		case BookingStatus.PROCESSING:
		case BookingStatus.FINISHED:
		case BookingStatus.DONE:
			return I18n.t(
				_t(translations.bookingStatus[`status_${BookingStatus.DONE}`])
			);
		default:
			return I18n.t(
				_t(translations.bookingStatus[`status_${BookingStatus.PENDING}`])
			);
	}
};

export const getTodayBookingByStatus = createSelector(
	[getTodayBookings, (state: RootState, status: number) => status],
	(bookings, status) => {
		if (status === -1) {
			return _.sortBy(bookings, (booking) => booking.status);
		}
		if (status === BookingStatus.PENDING) {
			return _.filter(
				bookings,
				(booking) =>
					booking.status === BookingStatus.PENDING ||
					booking.status === BookingStatus.CONFIRMED
			);
		}
		return _.filter(bookings, (booking) => booking.status === status);
	}
);
const isLateBooking = (startTime: Date): boolean => {
	const store = configureStore().store;
	const currentBranch = store.getState().BranchReducer.currentBranch;
	return moment
		.utc(startTime)
		.add(currentBranch?.lateArrivalTimeAllowed || 60, 'minute')
		.isBefore(moment.utc());
};

export const searchTodayBooking = createSelector(
	[
		(bookings: IBooking[], searchText: string) => bookings,
		(bookings: IBooking[], searchText: string) => searchText,
	],
	(bookings, searchText) => {
		return bookings.filter((booking) => {
			const customerName = `${booking.customer.firstName} ${
				booking.customer.lastName || ''
			}`;
			const customerPhone = `${booking.customer.phone}`;
			return (
				customerName.toLowerCase().includes(searchText.toLowerCase()) ||
				customerPhone.toLowerCase().includes(searchText.toLowerCase())
			);
		});
	}
);
const calculateEmployeeType = (
	countedBooking: IBooking[],
	employees: IEmployee[],
	type: string
) => {
	return employees.map((x) => {
		const lists = _.filter(
			CalendarHelper.convertBookingToEvent(countedBooking),
			(event) => {
				return (
					event.resourceId === x.id &&
					convertStatusToChartType(event.status!) === type
				);
			}
		);

		const totalAmount = _.sumBy(lists, (booking) => {
			const discount = booking.discount
				? booking.discounterType === 2
					? booking.discount
					: booking.discounterType === 0
					? booking.discount / 2
					: 0
				: 0;

			return (
				(booking.amount || 0) -
				(booking.supplyPrice || 0) +
				(booking.extraAmount || 0) -
				discount
			);
		});

		return {
			count: lists ? lists.length : 0,
			totalAmount,
		};
	});
};

export const getTurnChartData = createSelector(
	[getTodayBookings, (state: RootState) => state.TodayReducer.poolStaffs || []],
	(bookings, es) => {
		const countedBooking = _.filter(
			bookings,
			(x) => x.status !== BookingStatus.SUSPENDED
		);
		const upcomingType = I18n.t(
			_t(translations.bookingStatus[`status_${BookingStatus.PENDING}`])
		);
		const doneType = I18n.t(
			_t(translations.bookingStatus[`status_${BookingStatus.DONE}`])
		);
		const employees = _.reverse(
			getListSortedEmployee(
				es.map((x) => x.employee),
				bookings
			)
		);
		const labels = employees.map((x) => `${x.firstName || ''}`);

		const done = calculateEmployeeType(countedBooking, employees, doneType);
		const upcoming = calculateEmployeeType(
			countedBooking,
			employees,
			upcomingType
		);
		const turnData: ChartDataset<'bar'>[] = [
			{
				label: doneType,
				data: done.map((x) => x.count),
				backgroundColor: ColorHelper.getStatusColor(BookingStatus.CONFIRMED)!,
			},
			{
				label: upcomingType,
				data: upcoming.map((x) => x.count),
				backgroundColor: ColorHelper.getStatusColor(BookingStatus.PENDING)!,
			},
		];
		const amountData: ChartDataset<'bar'>[] = [
			{
				label: doneType,
				data: done.map((x) => x.totalAmount),
				backgroundColor: ColorHelper.getStatusColor(BookingStatus.CONFIRMED)!,
			},
			{
				label: upcomingType,
				data: upcoming.map((x) => x.totalAmount),
				backgroundColor: ColorHelper.getStatusColor(BookingStatus.PENDING)!,
			},
		];
		return {
			labels,
			turnData,
			amountData,
		};
	}
);

export const visualizeTurn = createSelector(
	[getTodayBookings, getAllStylist],
	(bookings, employees) => {
		const countedBooking = _.filter(
			bookings,
			(x) => x.status !== BookingStatus.SUSPENDED
		);
		const upcomingType = I18n.t(
			_t(translations.bookingStatus[`status_${BookingStatus.PENDING}`])
		);
		const doneType = I18n.t(
			_t(translations.bookingStatus[`status_${BookingStatus.DONE}`])
		);
		const data: IDataTurnEmployee[] = [];

		for (const employee of employees) {
			const stylistName = `${employee.firstName} ${employee.lastName}`;
			const updateTurn = {
				id: employee.id,
				label: stylistName,
				turn: 0,
				type: upcomingType,
				amount: 0,
			};
			const doneTurn = {
				id: employee.id,
				label: stylistName,
				turn: 0,
				type: doneType,
				amount: 0,
			};
			data.push(updateTurn);
			data.push(doneTurn);
		}
		const newData = data.map((turnData) => {
			const listUpcoming = _.filter(
				CalendarHelper.convertBookingToEvent(countedBooking),
				(event) => {
					return (
						event.resourceId === turnData.id &&
						convertStatusToChartType(event.status!) === turnData.type
					);
				}
			);
			const totalAmount = _.sumBy(listUpcoming, (booking) => {
				const amount = _.sumBy(booking.services, (service) => service.price);
				return amount;
			});
			return {
				...turnData,
				amount: totalAmount,
				turn: listUpcoming.length > 0 ? listUpcoming.length : undefined,
			};
		});
		return _.orderBy(newData, [(x) => x.amount, (x) => x.turn], 'desc');
	}
);
