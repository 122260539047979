import { Modal, Select, Switch } from 'components/atoms';
import { useAppSelector } from 'helpers/hookHelpers';
import { translations, t } from 'utils';
import React, { useEffect, useRef, useState } from 'react';
import { CurrencyHelper, TimeHelper } from 'helpers';
import { IItemCategory, IService } from 'models';
import { useForm } from 'react-hook-form';
import { SearchInput } from './SearchInput';
import ServiceApi from 'services/ServiceApi';
import { debounce, isEmpty } from 'lodash';
import { useCallback } from 'react';
import { LoadingProvider } from 'contexts/LoadingContext';

interface IPickServiceModalProps {
	visible: boolean;
	title?: string;
	selected: IService[];
	onSave: (newSelected: IService[]) => void;
	onCancel: () => void;
}

export const PickService = (props: IPickServiceModalProps) => {
	const [selected, setSelected] = useState<IService[]>([]);
	const { register, setValue, watch } = useForm();
	const [categories, setCategories] = useState<IItemCategory[]>([]);
	const [services, setServices] = useState<IService[]>([]);
	const [isSelectAllServices, setIsSelectAllServices] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const categoryId = watch('categoryId');
	const didMountRef = useRef(false);

	useEffect(() => {
		register('categoryId');
	}, [register]);
	useEffect(() => {
		setValue('categoryId', '');
	}, []);

	useEffect(() => {
		if (selected.length === services.length) {
			setIsSelectAllServices(true);
		} else {
			setIsSelectAllServices(false);
		}
	}, [selected, services]);

	const allCategories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);

	const fetchServices = async (text?: string) => {
		setIsLoading(true);
		try {
			const res = await ServiceApi.getAllService(
				!isEmpty(text) ? text : undefined
			);
			if (res.succeeded) {
				setServices(res.data || []);
			}
		} catch (error) {
			setIsLoading(false);
		}
		setIsLoading(false);
	};
	const debounceSearch = useCallback(
		debounce((text: string) => {
			fetchServices(text);
		}, 1000),
		[]
	);
	const onSearch = (text: string) => {
		debounceSearch(text);
	};

	const checkIsSelect = (data: IService) => {
		let index = selected.findIndex((x) => x.id === data.id);

		if (index !== -1) {
			return true;
		} else {
			return false;
		}
	};

	const handleClickRow = (data: IService) => {
		if (!checkIsSelect(data)) {
			setSelected([...selected, data!]);
		} else {
			const newData = selected.filter((e) => e.id !== data!.id);
			setSelected(newData);
		}
	};

	const checkCategory = (categoryId: string) => {
		let index = selected.findIndex(
			(service) => service.itemCategoryId === categoryId
		);
		if (index === -1) return CHECKBOX_STATES.Empty;
		const servicesByCategory = services.filter(
			(x) => x.itemCategoryId === categoryId
		);
		let flag = servicesByCategory.every(
			(service) =>
				selected.findIndex(
					(serviceSelect) => serviceSelect.id === service.id
				) !== -1
		);
		return flag ? CHECKBOX_STATES.Checked : CHECKBOX_STATES.Indeterminate;
	};

	const isEmptyCategory = (categoryId: string) => {
		let index = services.findIndex(
			(service) => service.itemCategoryId === categoryId
		);
		return index === -1 ? true : false;
	};

	const handleChangeCategory = (categoryId: string, check: boolean) => {
		let newData = selected.filter((x) => x.itemCategoryId !== categoryId);
		if (check) {
			newData = [
				...selected,
				...services.filter((x) => x.itemCategoryId === categoryId),
			];
		}
		setSelected(newData);
	};

	useEffect(() => {
		if (didMountRef.current) {
			fetchServices();
		}
		setSelected(props.selected);
		didMountRef.current = true;
	}, [props.selected, props.visible]);
	useEffect(() => {
		if (categoryId === '') {
			setCategories(allCategories);
		} else {
			setCategories(
				allCategories.filter((category) => category.id === categoryId)
			);
		}
	}, [categoryId, allCategories]);

	return (
		<Modal
			isOpen={props.visible || false}
			title={props.title || t(translations.promotionDetail.modalTitle)}
			className="w-1/2"
			canConfirm
			onConfirm={() => {
				props.onSave(selected);
			}}
			onCancel={() => {
				props.onCancel();
			}}
			content={
				<div className="w-full h-full p-4">
					<div className="flex space-x-4">
						<div className="w-1/2">
							<SearchInput onChange={(e) => onSearch(e.target.value)} />
						</div>

						<div className="w-1/2">
							<Select<IItemCategory>
								options={[
									{ id: '', name: 'All', code: '', description: '', status: 1 },
									...allCategories,
								]}
								titleField="name"
								keyField="id"
								fullWidth
								valueId={categoryId}
								onChange={(value) => {
									setValue('categoryId', value.id);
								}}
							/>
						</div>
						<Switch
							label={'Select All'}
							value={isSelectAllServices}
							onChange={(e) => {
								setIsSelectAllServices(e);
								if (e) {
									setSelected(services);
								} else {
									setSelected([]);
								}
							}}
							fullWidth
							primary
						/>
					</div>
					<LoadingProvider loading={isLoading}>
						<ul>
							{categories.map((category) => {
								return isEmptyCategory(category.id) ? null : (
									<li className="border-1 shadow-md my-4 w-full rounded-md">
										<div className="bg-base-200 h-12 text-lg font-semibold p-2 rounded-t-md flex">
											<Checkbox
												value={checkCategory(category.id)}
												onChange={(check) => {
													handleChangeCategory(category.id, check);
												}}
											/>
											<div className="ml-2">{category.name}</div>
										</div>
										<ul>
											{services
												.filter(
													(service) => service.itemCategoryId === category.id
												)
												.map((x, index) => {
													return (
														<>
															{index !== 0 && <div className="divider my-0" />}
															<li
																className="px-12 flex py-2"
																onClick={() => handleClickRow(x)}
															>
																<div className="basis-1/12">
																	<input
																		type="checkbox"
																		className="checkbox checkbox-primary"
																		checked={checkIsSelect(x)}
																		onChange={(e) => {
																			if (e.target.checked) {
																				setSelected([...selected, x]);
																			} else {
																				const newData = selected.filter(
																					(e) => e.id !== x.id
																				);
																				setSelected(newData);
																			}
																		}}
																	/>
																</div>
																<div className="ml-2 flex basis-11/12">
																	<span className="basis-1/2">{x.name}</span>
																	<span className="basis-1/4">
																		{TimeHelper.convertDurationToStringDetail(
																			x.duration
																		)}
																	</span>
																	<span className="basis-1/4">
																		{CurrencyHelper.formatPrice(x.price)}
																	</span>
																</div>
															</li>
														</>
													);
												})}
										</ul>
									</li>
								);
							})}
						</ul>
					</LoadingProvider>
				</div>
			}
		/>
	);
};
const CHECKBOX_STATES = {
	Checked: 'Checked',
	Indeterminate: 'Indeterminate',
	Empty: 'Empty',
};
interface ICheckboxProps {
	label?: string;
	value: string;
	onChange: (check: boolean) => void;
}
const Checkbox = ({ label, value, onChange }: ICheckboxProps) => {
	const checkboxRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (value === CHECKBOX_STATES.Checked) {
			checkboxRef.current!.checked = true;
			checkboxRef.current!.indeterminate = false;
		} else if (value === CHECKBOX_STATES.Empty) {
			checkboxRef.current!.checked = false;
			checkboxRef.current!.indeterminate = false;
		} else if (value === CHECKBOX_STATES.Indeterminate) {
			checkboxRef.current!.checked = false;
			checkboxRef.current!.indeterminate = true;
		}
	}, [value]);

	return (
		<label>
			<input
				ref={checkboxRef}
				type="checkbox"
				onChange={(e) => onChange(e.target.checked)}
				className="checkbox checkbox-primary"
			/>
			{label}
		</label>
	);
};
