import axios, { Axios, AxiosRequestConfig, AxiosResponse } from 'axios';
import { StringHelper } from 'helpers';
import _ from 'lodash';
import {
	ICloverDeviceResponse,
	ICloverPaymentResponse,
} from 'models/CloverResponse';
import qs from 'qs';
import configureStore from 'redux/configuration/configureStore';
import { BaseApiService } from 'services/BaseApiService';

class CloverAPI extends BaseApiService {}
const cloverAPI = new CloverAPI(true);

const API_CONFIG: AxiosRequestConfig = {
	// returnRejectedPromiseOnError: true,
	// withCredentials: true,
	// timeout: 30000,
	baseURL: process.env.REACT_APP_CLOVER_CONNECT_API,
	headers: {
		'Content-type': 'application/json',
		'accept-language': 'en-Us',
		// timeOffset: Math.round(moment().utcOffset() / 60),
		accept: '*/*',
	},
	paramsSerializer: (params: any) => qs.stringify(params, { indices: false }),
};
const CloverApiService = axios.create(API_CONFIG);
// CloverApiService.interceptors.response.use((response) => response, error => {
// 	if ()
// })
CloverApiService.interceptors.request.use((config: AxiosRequestConfig) => {
	const store = configureStore().store;
	const cloverAccessToken = store.getState().CloverReducer.accessToken;
	const branch = store.getState().BranchReducer.currentBranch;
	const deviceId = store.getState().CloverReducer.deviceId;
	config.headers!['X-Clover-Device-Id'] = `${deviceId}`;
	config.headers!['x-pos-id'] = `${branch?.name}`;

	if (!_.isEmpty(cloverAccessToken)) {
		config.headers!.Authorization = `Bearer ${cloverAccessToken}`;
	}
	// if (_.isEmpty(config.headers!['Idempotency-Key'])) {
	// 	config.headers!['Idempotency-Key'] = StringHelper.uuid();
	// }
	return config;
});

export const readingTip = (baseAmount: number) => {
	return CloverApiService.post('/connect/v1/device/read-tip', {
		baseAmount,
	});
};

export const adjustTip = (paymentId: string, tipAmount: number) => {
	return CloverApiService.post(
		`/connect/v1/payments/${paymentId}/tip-adjust`,
		{
			tipAmount,
		},
		{
			headers: {
				'Idempotency-Key': StringHelper.uuid(),
			},
		}
	);
};

export const showReceiptOptions = (message?: string) => {
	return CloverApiService.post(
		'/connect/v1/device/receipt-options',
		{
			deliveryOptions: [],
			message: message,
		},
		{
			headers: {
				'Idempotency-Key': StringHelper.uuid(),
			},
		}
	);
};
export const payment = (amount: number, final = false, tip = 0) => {
	return CloverApiService.post<any, AxiosResponse<ICloverPaymentResponse>>(
		'/connect/v1/payments',
		{
			amount,
			final: final,
			capture: true,
			receipt_email: 'hai.nlt@scssolutions.io',
			externalPaymentId: _.join(_.take(StringHelper.uuid(), 32), ''),
			tipAmount: final ? tip : undefined,
			deviceOptions: {
				cardEntryMethods: ['MANUAL', 'EMV', 'NFC'],
				cardNotPresent: false,
			},
		},
		{
			headers: {
				'Idempotency-Key': StringHelper.uuid(),
			},
		}
	);
};
export const reset = () => {
	return CloverApiService.post('/connect/v1/device/reset', {});
};
export const thankYou = () => {
	return CloverApiService.post('/connect/v1/device/thank-you', {});
};

export const welcome = () => {
	return CloverApiService.post('/connect/v1/device/welcome', {});
};
export const getPayment = (id: string) => {
	return CloverApiService.get(`/connect/v1/payments/${id}`);
};

export const cloverRefund = async (
	id: string,
	amount: number,
	fullRefund = false
) => {
	const payment = await getPayment(id);
	if (payment.status === 200) {
		if (!_.isEmpty(payment.data)) {
			return refund(id, amount, fullRefund);
		}
	}
};
export const refund = (
	paymentId: string,
	amount: number,
	fullRefund?: boolean
) => {
	return CloverApiService.post(
		`/connect/v1/payments/${paymentId}/refunds`,
		{
			amount,
			fullRefund,
		},
		{
			headers: {
				'Idempotency-Key': StringHelper.uuid(),
			},
		}
	);
};
export const voidPayment = (paymentId: string) => {
	return CloverApiService.post(
		`/connect/v1/payments/${paymentId}/void`,
		{
			voidReason: 'USER_CUSTOMER_CANCEL',
		},
		{
			headers: {
				'Idempotency-Key': StringHelper.uuid(),
			},
		}
	);
};
export const printReceipt = (
	id: string,
	type: 'payments' | 'refunds' = 'payments'
) => {
	return CloverApiService.post(`/connect/v1/${type}/${id}/receipt`, {
		'deliveryOption': {
			'method': 'PRINT',
		},
	});
};
export const getListDevices = (merchantId: string) => {
	const store = configureStore().store;
	const cloverAccessToken = store.getState().CloverReducer.accessToken;
	return axios.get<any, AxiosResponse<ICloverDeviceResponse>>(
		`${process.env.REACT_APP_CLOVER_API}/v3/merchants/${merchantId}/devices`,
		{
			headers: {
				Authorization: `Bearer ${cloverAccessToken}`,
			},
		}
	);
};

export const getToken = (code: string) => {
	return cloverAPI.get<{ accessToken: string }>(`/CloverPayment/token/${code}`);
};
