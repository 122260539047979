import { mdiClose } from '@mdi/js';
import { ConfirmModal, Description, Icon } from 'components/atoms';
import { CurrencyHelper } from 'helpers';
import { getPaymentName } from 'helpers/bookingHelper';
import { t } from 'i18n-js';
import _ from 'lodash';
import { IPaymentType } from 'models/ITransaction';
import React from 'react';
import { translations } from 'utils';

interface IPaymentTypesProps {
	payments: Partial<IPaymentType>[];
	onRemove: (payment: Partial<IPaymentType>, index: number) => void;
	hideRemove?: boolean;
}

export const PaymentTypes = (props: IPaymentTypesProps) => {
	const { payments } = props;
	return (
		<div className="h-fit p-4 rounded-lg bg-white">
			<h4 className="m-0 font-bold">Payment Methods</h4>
			{payments?.map((x, index) => {
				return (
					<div className="w-full rounded-lg border border-base-300 mt-2">
						<div className="p-0 px-4 pt-1 font-semibold flex justify-between items-center">
							{getPaymentName(x.paymentType!, x.appName)}
							{!props.hideRemove && (
								<div
									className="cursor-pointer"
									onClick={() => {
										props.onRemove(x, index);
									}}
								>
									<Icon className="text-error font-bold" path={mdiClose} />
								</div>
							)}
						</div>
						<div className="divider m-0" />
						<div className="p-4 py-0">
							{!_.isEmpty(x.giftCode) && (
								<Description
									className="m-0"
									title={t(translations.checkout.giftCardCode)}
									value={`${x.giftCode}`}
								/>
							)}
							<Description
								className="m-0"
								title={t(translations.checkout.amountReceived)}
								value={CurrencyHelper.formatPrice(x.amount)}
							/>
							{x.cardCharge && x.cardCharge !== 0 && (
								<Description
									className="m-0"
									title={t(translations.checkout.cardCharge)}
									value={CurrencyHelper.formatPrice(x.cardCharge)}
								/>
							)}
							{x.transactionReferenceNumber && (
								<Description
									className="m-0"
									title={t(translations.checkout.reference)}
									value={x.transactionReferenceNumber}
								/>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};
