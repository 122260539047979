/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select } from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { AlertHelper, StringHelper } from 'helpers';
import {
	getAllCity,
	getAllStates,
	getLocationFromZip,
	getTimeZoneIdByCity,
	getTimeZoneIdByState,
	ICity,
	IState,
} from 'helpers/geolocation';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { IApiResponse } from 'models';
import { IBranch } from 'models/IBranch';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { BranchActions } from 'redux/actions';
import BranchApiService from 'services/BranchApiService';
import { t, translations } from 'utils';
import { history } from 'routers';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const BranchesManagementDetail = () => {
	const { params, path } = useRouteMatch<{ id?: string }>();
	const isAdd = useMemo(() => {
		if (path.includes('edit')) {
			return false;
		} else {
			return true;
		}
	}, [params]);
	const { selectedBranch, currentBranch } = useAppSelector(
		(state) => state.BranchReducer
	);
	const [isGetByIdLoading, setIsGetByIdLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [states, setStates] = useState<IState[]>([]);
	const [cities, setCities] = useState<ICity[]>([]);
	const [isInitial, setIsInitial] = useState<boolean>(true);
	const dispatch = useDispatch();
	const branchAction = useAppSelector(
		(state) => state.ReduxActionReducer['BRANCH']
	);

	const form = useForm<BranchesManagementFormData>({
		defaultValues: getDefaultValue(selectedBranch),
		resolver: yupResolver(validationSchema),
		mode: 'onChange',
	});
	const { setValue, register, watch, formState } = form;
	const { errors } = formState;
	const selectedState = watch('address.state');
	const selectedCity = watch('address.city');
	const street = watch('address.street')
	const zipCode = watch('address.zipCode')
	useEffect(() => {
		if (!isAdd) {
			if (!params.id) {
				history.push('/branches-management');
			} else if (_.isEmpty(selectedBranch)) {
				getBranchById(params.id);
			}
		}
		return () => {
			dispatch(BranchActions.selectBranchManagement.request());
		};
	}, []);
	const getStates = async () => {
		const _states = await getAllStates();
		setStates(_states);
	};
	useEffect(() => {
		getStates();
	}, []);

	useEffect(() => {
		if (
			branchAction === BranchActions.updateBranchManagement.requestName ||
			branchAction === BranchActions.createNewBranch.requestName
		) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
		}
		if (isInitial) {
			setIsInitial(false);
		} else {
			if (!branchAction?.includes('FAILED')) {
				if (branchAction === BranchActions.updateBranchManagement.successName) {
					AlertHelper.showSuccess(t(translations.branches.updateBranchSuccess));
				} else if (branchAction === BranchActions.createNewBranch.successName) {
					AlertHelper.showSuccess(t(translations.branches.addBranchSuccess));
				}
				history.goBack();
			}
		}
	}, [branchAction]);

	useEffect(() => {
		if (selectedState) {
			const selectedStateCode = states.find(
				(state) => state.name === selectedState
			)?.isoCode;
			selectedStateCode && onSelectState(selectedStateCode);
		}
	}, [states]);

	useEffect(() => {
		if (selectedState) {
			const selectedStateCode = states.find(
				(state) => state.name === selectedState
			)?.isoCode;
			selectedStateCode && onSelectState(selectedStateCode);
		}
	}, [selectedState]);

	useEffect(() => {
		onSelectCity(cities.find((e) => e.name === selectedCity));
	}, [selectedCity]);

	const onChangeZipCode = useCallback<(zipCode: string) => void>(
		_.debounce<(zipCode: string) => void>((zipCode: string) => {
			if (!_.isEmpty(zipCode)) {
				const location = getLocationFromZip(zipCode);
				if (!_.isEmpty(location)) {
					setValue('address.city', location?.city || '');
					setValue(
						'address.state',
						states.find((state) => state.isoCode === location?.state)?.name ||
							''
					);
					onSelectState(location?.state || '');
				}
			}
		}, 200),
		[states]
	);

	const onSelectState = async (code: string) => {
		const cities = await getAllCity(code);
		const currentState = states?.find((x) => x.isoCode === code);
		if (currentState) {
			setValue('address.state', currentState!.name);
			setValue('localTimeZone', getTimeZoneIdByState(currentState));
		}
		if (cities) {
			setCities(cities);
			const currentCity = cities.find((x) => x.name === selectedCity);
			if (currentCity) {
				setValue('address.city', currentCity!.name);
			}
		}
	};

	const onSelectCity = (city?: ICity) => {
		if (city) {
			setValue('localTimeZone', getTimeZoneIdByCity(city));
		}
	};

	const onFormSubmit = (value: BranchesManagementFormData) => {
		if (isAdd) {
			const { id, isPrimary, ..._rest } = currentBranch!;
			dispatch(
				BranchActions.createNewBranch.request({
					..._rest,
					...value,
				})
			);
		} else {
			// const newBranchSetting = {
			// 	...selectedBranch,
			// 	...value,
			// } as IBranch;
			// updateBranch(newBranchSetting);
			console.log({value})
			dispatch(
				BranchActions.updateBranchManagement.request({
					...selectedBranch,
					...value,
				} as IBranch)
			);
			// console.log('selected branchj', selectedBranch)
		}
	};

	const getBranchById = async (id: string) => {
		setIsGetByIdLoading(true);
		const result = (await BranchApiService.getBranchById(
			id
		)) as IApiResponse<IBranch>;
		if (result.succeeded && !_.isEmpty(result.data)) {
			setIsGetByIdLoading(false);
			form.reset(getDefaultValue(result.data));
			dispatch(BranchActions.selectBranchManagement.request(result.data!));
		} else {
			setIsGetByIdLoading(false);
			AlertHelper.showError(result);
			history.push('/branches-management');
		}
	};

	// const addNewbranch = async (branch: Partial<IBranch>) => {
	// 	setIsLoading(true);
	// 	try {
	// 		const result = (await BranchApiService.createBranch(
	// 			branch
	// 		)) as IApiResponse<IBranch>;
	// 		if (result.succeeded) {
	// 			setIsLoading(false);
	// 			AlertHelper.showSuccess(t(translations.branches.addBranchSuccess));
	// 			dispatch(
	// 				BranchActions.getBranchesByShopId.request(currentBranch!.shopId!)
	// 			);
	// 			history.goBack();
	// 		} else {
	// 			setIsLoading(false);
	// 			AlertHelper.showError(result);
	// 		}
	// 	} catch (error) {
	// 		console.log('network error', error);
	// 	}
	// };

	// const updateBranch = async (branch: IBranch) => {
	// 	setIsLoading(true);
	// 	const result = (await BranchApiService.updateBranch(
	// 		branch
	// 	)) as IApiResponse<IBranch>;
	// 	if (result.succeeded) {
	// 		setIsLoading(false);
	// 		AlertHelper.showSuccess(t(translations.branches.updateBranchSuccess));
	// 		history.goBack();
	// 	} else {
	// 		setIsLoading(false);
	// 		AlertHelper.showError(result);
	// 	}
	// };

	return (
		<DetailPageLayout
			loading={isLoading || isGetByIdLoading}
			form={form}
			title={
				isAdd
					? t(translations.branches.addNew)
					: t(translations.branches.updateBranch)
			}
			onSave={form.handleSubmit(onFormSubmit)}
		>
			{!isGetByIdLoading && (
				<div className="grid grid-cols-6">
					<div className="col-span-2" />
					<div className="col-span-2 grid gap-4">
						<Input
							label={t(translations.branchSetting.branchName)}
							error={errors.name?.message}
							{...register('name', { disabled: isLoading })}
						/>
						<Input
							label={t(translations.branchSetting.phone)}
							error={errors.phone?.message}
							{...register('phone', { disabled: isLoading })}
						/>
						<Input
							label={t(translations.branchSetting.street)}
							value={street}
							onChange={(e) => {
								setValue('address.street',e.target.value)
							}}
							
						
						/>
						<div className="grid grid-cols-3 gap-4">
							<Select<ICity>
								label={t(translations.branchSetting.city)}
								titleField={'name'}
								keyField={'name'}
								options={cities}
								value={
									cities.find((city) => city.name === selectedCity) || {
										name: 'Select City',
										stateCode: '',
									}
								}
								onClick={(value) => setValue('address.city', value.name)}
								fullWidth
								disabled={isLoading}
							/>
							<Select<IState>
								label={t(translations.branchSetting.state)}
								titleField={'name'}
								keyField={'isoCode'}
								options={states}
								value={
									states.find((state) => state.name === selectedState) || {
										name: 'Select State',
										isoCode: '',
									}
								}
								onClick={(value) => {
									setValue('address.state', value.name);
									onSelectState(value.isoCode!);
								}}
								fullWidth
								disabled={isLoading}
								error={errors.address?.state?.message}
							/>
							<Input
								label={t(translations.partner.zipCode)}
								placeholder={t(translations.partner.zipCode)}
								error={errors.address?.zipCode?.message}
								value={zipCode}
								onChange={(e) => {
								onChangeZipCode(e.target.value);
								setValue('address.zipCode',e.target.value)
								}}
								
							/>
						</div>
					</div>
					<div className="col-span-2" />
				</div>
			)}
		</DetailPageLayout>
	);
};

const getDefaultValue = (value?: IBranch): BranchesManagementFormData => {
	if (value) {
		return {
			name: value.name,
			address: value.address,
			phone: value.phone,
			localTimeZone: value.localTimeZone,
		};
	} else {
		return {};
	}
};

const validationSchema = yup.object().shape({
	name: yup.string().required(t(translations.branchSetting.branchNameMessage)),
	phone: yup
		.string()
		.required(t(translations.branchSetting.phoneMessage))
		.test(
			'check-phone-format',
			t(translations.staffDetail.messagePhone),
			(value) => {
				if (StringHelper.checkPhoneNumber(value || '')) {
					return true;
				}
				return false;
			}
		),
	address: yup.object().shape({
		state: yup.string().required(t(translations.branchSetting.stateMessage)),
	}),
});

type BranchesManagementFormData = Pick<
	IBranch,
	'name' | 'address' | 'phone' | 'localTimeZone'
>;
