import { Card } from 'components/atoms';
import {
	CountBookingPerHourResponseDetail,
	IBookingHourSummary,
	IRecentSalesChartData,
	IRecentSalesChartDataSource,
} from 'models';
import { useDashboardContext } from 'pages/Dashboard/context';
import React, { useEffect, useMemo, useState } from 'react';
import branch from 'redux/reducers/branch';
import { AdminDashBoardApiService } from 'services';
import { Bar, Line } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';
import { CurrencyHelper } from 'helpers';

export const TotalSales = () => {
	const { timeRange, branch } = useDashboardContext();
	const [data, setData] = useState<IRecentSalesChartDataSource>();
	const fetchData = async () => {
		const response = await AdminDashBoardApiService.getRecentSalesChart({
			fromDate: timeRange.fromDate.format('YYYY-MM-DD'),
			toDate: timeRange.toDate.format('YYYY-MM-DD'),
			branchId: branch,
		});
		if (response.succeeded && response.data) {
			setData(response.data);
		}
	};
	useEffect(() => {
		fetchData();
	}, [timeRange, branch]);
	const totalDays = Math.abs(timeRange.toDate.diff(timeRange.fromDate, 'day'));
	const dataChart = useMemo(() => {
		if (!data) {
			return [];
		}
		if (totalDays < 20) {
			return data.data;
		}
		let result: IRecentSalesChartData[] = [];
		for (let i = 0; i < data.data.length; i++) {
			const value = data.data[i];
			const existIndex = _.findIndex(
				result,
				(x) =>
					moment(x.date).unix() === moment(value.date).startOf('week').unix()
			);
			if (existIndex >= 0) {
				result[existIndex] = {
					...result[existIndex],
					salesValue:
						(result[existIndex].salesValue || 0) + (value.salesValue || 0),
					appointmentCount:
						(result[existIndex].appointmentCount || 0) +
						(value.appointmentCount || 0),
					appointmentValue:
						(result[existIndex].appointmentValue || 0) +
						(value.appointmentValue || 0),
				};
			} else {
				result.push({
					date: moment(value.date).startOf('week').toDate(),
					salesValue: value.salesValue || 0,
					appointmentCount: value.appointmentCount || 0,
					appointmentValue: value.appointmentValue || 0,
				});
			}
		}
		return result;
	}, [data, totalDays]);
	return (
		<Card
			title="Total Sales"
			headerRight={
				<strong className="text-right text-PROCESSING">
					{CurrencyHelper.formatBalance(data?.totalAppointmentValue || 0)}
				</strong>
			}
			className="col-span-2"
		>
			<Line
				options={{
					plugins: {
						datalabels: {
							display: false,
						},
						legend: {
							display: false,
						},
					},
					scales: {
						x: {
							type: 'time',
							time: {
								unit: totalDays > 20 ? 'week' : 'day',
							},
							ticks: {
								autoSkip: true,
								maxTicksLimit: 10,
							},
							grid: {
								display: false,
							},
						},
						y: {
							ticks: {
								precision: 0,
							},
						},
					},
				}}
				data={{
					labels: _.sortBy(dataChart, (x) => moment(x.date).unix()).map((x) =>
						moment(x.date)
					),
					datasets: [
						{
							data: _.sortBy(dataChart, (x) => moment(x.date).unix()).map(
								(x) => x.appointmentValue
							),
							backgroundColor: '#64c2a6',
							borderColor: '#64c2a6',
							tension: 0.5,
							fill: {
								target: 'origin',
								above: '#64c2a680',
							},
							// pointRadius: (data?.data.length || 0) > 1 ? 0 : undefined,
						},
					],
				}}
			/>
		</Card>
	);
};
