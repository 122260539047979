import React, { useState } from 'react';
import Table, { IColumnProps } from 'components/atoms/Table';
import { IHolidayModel } from 'models';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { HolidayActions } from 'redux/actions';
import { HolidayApiService } from 'services';
import { t, translations } from 'utils';
import { TDateRange } from 'components/molecules/ModalCalendar';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { history } from 'routers';
import { Button, Icon } from 'components/atoms';
import { mdiPlus } from '@mdi/js';

export const ShopHoliday = () => {
	const dispatch = useDispatch();
	const [dateFilter, setDateFilter] = useState<Required<TDateRange>>({
		fromDate: moment().startOf('date'),
		toDate: moment().startOf('date').add(3, 'month'),
	});

	const dateCol: IColumnProps<IHolidayModel> = {
		header: t(translations.holiday.date),
		key: 'fromDate',
		renderCell: (item) =>
			`${moment(item.fromDate).format('dddd, MMM Do, YYYY')} - ${moment(
				item.toDate
			).format('dddd, MMM Do, YYYY')}`,
	};
	const noOfDayCol: IColumnProps<IHolidayModel> = {
		header: t(translations.staffDetail.closedDateCountCol),
		key: 'id',
		renderCell: (item) =>
			moment
				.duration(moment(item.toDate).diff(moment(item.fromDate)))
				.asDays() + 1,
	};
	const descriptionCol: IColumnProps<IHolidayModel> = {
		header: t(translations.description),
		key: 'description',
	};

	return (
		<div className="flex flex-col h-full overflow-hidden">
			<div className="flex flex-row justify-between px-4">
				<DateRangePicker
					onSelectDateRange={(value) =>
						setDateFilter(value as Required<TDateRange>)
					}
					selectedDateRange={dateFilter}
				/>
				<Button
					title={t(translations.addNew)}
					small
					onClick={() => history.push('/holiday/new/shop')}
					iconBefore={<Icon path={mdiPlus} />}
				/>
			</div>
			<Table<IHolidayModel>
				columns={[dateCol, noOfDayCol, descriptionCol]}
				onClickRow={(holiday) => {
					dispatch(HolidayActions.selectHoliday.request(holiday));
					history.push('/holiday/edit/' + holiday.id);
				}}
				queryUrl={HolidayApiService.url + '/get-all-holidays'}
				additionalParams={{
					employeeHoliday: false,
					fromDate: dateFilter.fromDate.format('YYYY-MM-DD'),
					toDate: dateFilter.toDate.format('YYYY-MM-DD'),
				}}
				containerClassName={'h-full flex flex-col overflow-hidden'}
				className={'overflow-y-auto'}
			/>
		</div>
	);
};
