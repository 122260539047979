import { createReducer, PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { IBooking, ICustomer } from "models";
import { ISearchByTextResponse } from "models/ResponseModels";
import { SearchActions } from "redux/actions";

export interface ISearchReducer {
  bookings?: IBooking[];
  customers?: ICustomer[];
}

const initialState: ISearchReducer = {};

const SearchReducer = createReducer(initialState, (builder) =>
  builder.addCase(
    SearchActions.searchByText.success,
    (state: ISearchReducer, action: PayloadAction<ISearchByTextResponse>) => {
      state.bookings = action.payload.bookings;
      state.customers = action.payload.customers;
    }
  )
);

export default SearchReducer;
