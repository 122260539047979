import { ButtonGroup } from 'components/atoms';
import React, { useState } from 'react';
import { t, translations } from 'utils';
import { ShopHoliday, StaffHoliday } from './components';

const HOLIDAY_TYPES = [
	{ id: 0, title: t(translations.holiday.staffHeader) },
	{ id: 1, title: t(translations.holiday.shopHeader) },
];

export const Holiday = () => {
	const [selectedHolidayType, setSelectedHolidayType] = useState<
		typeof HOLIDAY_TYPES[0]
	>(HOLIDAY_TYPES[0]);
	return (
		<div className="overflow-hidden h-full flex flex-col">
			<div className='p-4'>
				<ButtonGroup<typeof HOLIDAY_TYPES[0]>
					buttons={HOLIDAY_TYPES}
					valueField={'id'}
					onChange={setSelectedHolidayType}
					titleField="title"
					value={selectedHolidayType}
				/>
			</div>
			<div className="mt-4 flex-1 flex flex-col overflow-hidden">
				{selectedHolidayType.id === HOLIDAY_TYPES[0].id ? (
					<StaffHoliday />
				) : (
					<ShopHoliday />
				)}
			</div>
		</div>
	);
};

export * from './HolidayDetail';
