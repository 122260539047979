import { mdiClose, mdiWalletGiftcard } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Icon, Input, Modal } from 'components/atoms';
import { ConfirmModal } from 'components/atoms/ConfirmModal';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { LoadingProvider } from 'contexts/LoadingContext';
import { AlertHelper } from 'helpers';
import { showAlert, showError } from 'helpers/alertHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import I18n from 'i18n-js';
import _ from 'lodash';
import { IApiResponse, ICustomer, IGiftCard } from 'models';
import moment from 'moment';
import { useCheckOutContext } from 'pages/CheckOut/context';
import { useRef, useState } from 'react';
import GiftCardApiService from 'services/GiftCardApiService';
import { t, translations, _t } from 'utils';

interface ISaleGiftCardModal {
	onCancel: () => void;
	isVoucher?: boolean;
	chooseType?: boolean;
	onConfirm?: (giftCard?: Partial<IGiftCard>) => void;
	customer?: ICustomer;
}

const AMOUNT_GIFT_CARD = [
	{
		key: '20',
		title: '$20.00',
		value: 20,
	},
	{
		key: '30',
		title: '$30.00',
		value: 30,
	},
	{
		key: '40',
		title: '$50.00',
		value: 50,
	},
	{
		key: '50',
		title: '$100.00',
		value: 100,
	},
	{
		key: 'other',
		title: 'Other',
	},
];

export const SaleGiftCardModal = (props: ISaleGiftCardModal) => {
	const [code, setCode] = useState<string>();
	const [amount, setAmount] = useState<number>(0);
	const [selectedAmount, setSelectedAmount] = useState<string>();
	const [isFocusGiftCode, setsFocusGiftCode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [confirmOverrideModal, setConfirmOverrideModal] = useState(false);
	const [type, setType] = useState<number | undefined>(
		props.chooseType ? undefined : props.isVoucher ? 1 : undefined
	);
	// const [giftCard, setGiftCard] = useState<IGiftCard>();
	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const numpadRef = useRef<INumPadRef>();

	const onConfirm = async (otherAmount?: number) => {
		let newAmount = otherAmount && otherAmount !== 0 ? otherAmount : amount;

		const request = {
			giftCode: code,
			title: 'No title',
			description: 'No description',
			fromDate: moment
				.utc(moment(), 'YYYY-MM-DDTHH:mm')
				.format('YYYY-MM-DDTHH:mm'),
			toDate: moment
				.utc(moment().add(6, 'month'), 'YYYY-MM-DDTHH:mm')
				.format('YYYY-MM-DDTHH:mm'),
			// amount: props.isVoucher ? 0 : amount,
			amount: newAmount,
			balance: newAmount,
			branchId: currentBranch?.id,
			shopId: currentBranch?.shopId,
			status: 1,
			name: `${props.customer?.firstName || ''} ${
				props.customer?.lastName || ''
			}`,
			type: props.chooseType ? type : props.isVoucher ? 1 : undefined, // 0: GIFT CARD, 1: VOUCHER, undefined: default (0)
		};
		if (props.chooseType && type === 1) {
			props.onConfirm?.(request);
		} else {
			setLoading(true);
			const response = await GiftCardApiService.addGiftCard(request);
			setLoading(false);
			if (response.succeeded && response.data) {
				props.onConfirm && props.onConfirm(response.data);
				props.isVoucher && props.onCancel();
			} else {
				if (props.isVoucher) {
					showError(response);
				} else {
					setConfirmOverrideModal(true);
				}
			}
		}
	};

	const updateGiftCard = async () => {
		const giftCard = await getGiftCard();
		let params: Partial<IGiftCard> = {
			...giftCard,
			amount: amount,
			balance: amount,
			title: 'No title',
			description: 'No description',
			fromDate: moment
				.utc(moment(), 'YYYY-MM-DDTHH:mm')
				.format('YYYY-MM-DDTHH:mm'),
			type: 0,
		};
		setLoading(true);
		const res = await GiftCardApiService.updateGiftCard(params);
		setLoading(false);
		setConfirmOverrideModal(false);
		if (res.succeeded && res.data) {
			props.onConfirm && props.onConfirm(res.data!);
			props.onCancel();
		} else {
			showError(res);
		}
	};

	const getGiftCard = async () => {
		setLoading(true);
		const res = await GiftCardApiService.getGiftCardByCode(code || '');
		setLoading(false);
		if (res.succeeded && res.data) {
			return res.data;
		} else {
			showError(res);
		}
	};

	return (
		<>
			<ModalNumPad
				ref={(x) => {
					if (x) {
						numpadRef.current = x;
					}
				}}
				{...props}
				// isMoney={type.id === 'money'}
				showNumpad={
					selectedAmount === 'other' && !isFocusGiftCode && !_.isEmpty(code)
				}
				unit={'Usd'}
				title="Sale Gift Card"
				onConfirm={(x) => {
					setAmount(_.toNumber(x));
					onConfirm(_.toNumber(x));
					// props.onCancel();
				}}
			>
				{props.chooseType && type === undefined ? (
					<div className="flex flex-col gap-4 h-full p-4">
						<Button
							title={t(translations.checkout.saleGiftCard)}
							iconBefore={<Icon path={mdiWalletGiftcard} />}
							className={'btn-outline'}
							fullWidth
							primary
							onClick={() => setType(0)}
						/>
						<Button
							title={t(translations.addVoucher)}
							iconBefore={<Icon path={mdiWalletGiftcard} />}
							className={'btn-outline'}
							fullWidth
							onClick={() => setType(1)}
						/>
					</div>
				) : (
					<LoadingProvider loading={loading}>
						<div className="flex flex-col gap-2 h-full flex-1 p-4">
							<Input
								id="input-gift-code"
								label="Gift Card Code"
								value={code}
								onChange={(e) => setCode(e.target.value)}
								placeholder="Scan QRCode or swipe card"
								autoFocus
								onFocus={() => setsFocusGiftCode(true)}
								onBlur={() => setsFocusGiftCode(false)}
								renderAfter={
									<Button
										id="clear"
										onClick={() => setCode('')}
										title={'Clear'}
										className="text-error h-[45px] min-h-[45px]"
										ghost
										small
										iconBefore={<Icon path={mdiClose} />}
									/>
								}
							/>
							<div id="list-amount">
								<label className="label font-medium ">
									<span className="label-text text-md">Select Amount</span>
								</label>
								<div className="flex gap-2">
									{AMOUNT_GIFT_CARD.map((x) => {
										return (
											<Button
												onClick={() => {
													setSelectedAmount(x.key);
													if (x.value) {
														setAmount(x.value);
													}
												}}
												title={x.title}
												className={clsxm(
													'btn-outline btn-primary flex-1',
													selectedAmount === x.key && 'main-gradient-background'
												)}
											/>
										);
									})}
								</div>
							</div>

							{selectedAmount !== 'other' && (
								<div className="flex flex-1 items-end justify-end">
									<Button
										id="save"
										onClick={() => onConfirm()}
										disabled={_.isEmpty(code) || amount === 0}
										className={'text-white'}
										title="Save"
									/>
								</div>
							)}
						</div>
					</LoadingProvider>
				)}
			</ModalNumPad>
			<ConfirmModal
				title="Warning"
				type="warning"
				// className="bg-PENDING"
				visible={confirmOverrideModal}
				subTitle={I18n.t(_t(translations.checkout.overwrite))}
				onPositiveClick={updateGiftCard}
				onNegativeClick={() => setConfirmOverrideModal(false)}
			/>
		</>
	);
};
