import { Card } from 'components/atoms';
import { getDayOfWeek } from 'helpers/StringHelper';
import { I18n, _t, translations } from 'utils';
import { WeekDays } from 'utils/Consts';
import { WorkingTimeOfDay } from './WorkingTimeOfDay';

export const WorkingTime = (props: {
	isLoading?: boolean;
	online?: boolean;
}) => {
	const { isLoading } = props;
	return (
		<Card
			title={I18n.t(
				_t(
					props.online
						? translations.staffDetail.onlineWorkingTime
						: translations.staffDetail.workingTime
				)
			)}
			description={I18n.t(
				_t(
					props.online
						? translations.staffDetail.subOnlineWorkingTime
						: translations.staffDetail.subWorkingTime
				)
			)}
			id="working-time"
		>
			<div className="overflow-y-auto">
				<table className="table table-fixed table-zebra border-collapse w-full">
					<thead>
						<tr>
							<th className="text-center">
								{getDayOfWeek(WeekDays.WHOLEWEEK)}
							</th>
							<th className="text-center">{getDayOfWeek(WeekDays.SUNDAY)}</th>
							<th className="text-center">{getDayOfWeek(WeekDays.MONDAY)}</th>
							<th className="text-center">{getDayOfWeek(WeekDays.TUESDAY)}</th>
							<th className="text-center">
								{getDayOfWeek(WeekDays.WEDNESDAY)}
							</th>
							<th className="text-center">{getDayOfWeek(WeekDays.THURSDAY)}</th>
							<th className="text-center">{getDayOfWeek(WeekDays.FRIDAY)}</th>
							<th className="text-center">{getDayOfWeek(WeekDays.SATURDAY)}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="border border-base-200">
								<WorkingTimeOfDay
									day={WeekDays.WHOLEWEEK}
									isLoading={isLoading}
									online={props.online}
								/>
							</td>
							<td className="border border-base-200">
								<WorkingTimeOfDay
									day={WeekDays.SUNDAY}
									isLoading={isLoading}
									online={props.online}
								/>
							</td>
							<td className="border border-base-200">
								<WorkingTimeOfDay
									day={WeekDays.MONDAY}
									isLoading={isLoading}
									online={props.online}
								/>
							</td>
							<td className="border border-base-200">
								<WorkingTimeOfDay
									day={WeekDays.TUESDAY}
									isLoading={isLoading}
									online={props.online}
								/>
							</td>
							<td className="border border-base-200">
								<WorkingTimeOfDay
									day={WeekDays.WEDNESDAY}
									isLoading={isLoading}
									online={props.online}
								/>
							</td>
							<td className="border border-base-200">
								<WorkingTimeOfDay
									day={WeekDays.THURSDAY}
									isLoading={isLoading}
									online={props.online}
								/>
							</td>
							<td className="border border-base-200">
								<WorkingTimeOfDay
									day={WeekDays.FRIDAY}
									isLoading={isLoading}
									online={props.online}
								/>
							</td>
							<td className="border border-base-200">
								<WorkingTimeOfDay
									day={WeekDays.SATURDAY}
									isLoading={isLoading}
									online={props.online}
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Card>
	);
};
