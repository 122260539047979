import { IAddRefund, IRefund } from 'models';
import { BaseApiService } from './BaseApiService';

const url = '/Refund';

class RefundApiService extends BaseApiService {
	public createRefund = (request: Partial<IRefund>) =>
		this.post<Partial<IAddRefund>, IRefund>(`${url}/create-refund`, request);
	public getRefundById = (id: string) =>
		this.get<IRefund>(`${url}/get-refund/${id}`);
}

export default new RefundApiService(true);
