import clsxm from 'clsxs/clsxm';
import { useBookingHook } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import { convertDurationToStringDetail } from 'helpers/timeHelper';
import _ from 'lodash';
import { IService } from 'models';
import { Status } from 'models/Base.model';
import { t, translations } from 'utils';
import { CATEGORY_COLORS } from 'utils/Colors';

interface IServiceProps {
	service: IService;
	onSelect: () => void;
}

export const Service = (props: IServiceProps) => {
	const { service } = props;
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	const categoryIndex = _.findIndex(
		categories.filter((x) => x.status === Status.ACTIVE),
		(x) => x.id === service.itemCategoryId
	);
	const { currentIndex, bookingDetails, disabledEdit } = useBookingHook();
	const currentService =
		currentIndex !== undefined
			? bookingDetails[currentIndex]?.item || undefined
			: undefined;
	const color = CATEGORY_COLORS[categoryIndex] || _.last(CATEGORY_COLORS);
	return (
		<div
			onClick={() => {
				if (!disabledEdit) {
					props.onSelect();
				}
			}}
			key={`${service.id}`}
			className={clsxm(
				'service-item',
				'col-span-1 cursor-pointer aspect-[4/2.5] font-bold shadow-md rounded-lg overflow-hidden border-2 ',
				!disabledEdit && 'active:animate-pop duration-500 ease-in',
				disabledEdit && 'text-base-300',
				currentService && currentService.id === service.id
					? 'border-[3px] border-primary text-black'
					: 'border-none'
			)}
			style={{ backgroundColor: color }}
		>
			<div
				className={clsxm(
					'w-full h-full p-2 flex flex-col justify-between  rounded-t-lg rounded-br-full bg-base-200'
					// currentService && currentService.id === service.id && "border-4 border-primary"
				)}
			>
				<div className="uppercase text-xs text-center break-words leading-4 line-clamp-2">
					{service.name}
				</div>
				<div className="text-center text-xs font-medium">{`(${t(
					translations.currency
				)}${service?.price} - ${convertDurationToStringDetail(
					service?.duration!
				)})`}</div>
			</div>
		</div>
	);
};
