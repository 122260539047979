/* eslint-disable react-hooks/exhaustive-deps */
import { mdiClock, mdiPlay } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { DatePicker } from 'components/molecules/DatePicker';
import { Header } from 'components/molecules/Header';
import { TimeHelper } from 'helpers';
import { useBookingHook } from 'helpers/bookingHelper';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { TimeModal } from 'pages/Booking/components/TimeMoldal';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { t, translations } from 'utils';
import { BookingStatus } from 'utils/Consts';
import { useAppSelector } from 'helpers/hookHelpers';
import { AppConfigActions } from 'redux/actions';
import { useDispatch } from 'react-redux';

import qs from 'qs';
import { IBookingSearchQuery } from 'models/RequestModels';
export const BookingHeader = () => {
	const [showTimeModal, setShowTimeModal] = useState(false);
	const tourGuide = useAppSelector((state) => state.AppConfigReducer.tourGuide);
	const dateBookingTour = _.isEmpty(tourGuide)
		? false
		: tourGuide.dateBookingTour;
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const quickSale = queries.quickSale;

	const dispatch = useDispatch();
	const {
		getBookings,
		selectStartTime,
		changeBookingDetail,
		bookingDetails,
		timeActiveId,
		timeBlocks,
		currentBooking,
		reset,
		date,
		changeDate,
		getAvailableStylistAndTimeBlocks,
	} = useBookingHook();
	const onOpenModal = () => {
		if (showTimeModal) {
			setShowTimeModal(false);
			setTimeout(() => {
				setShowTimeModal(true);
			}, 2000);
		} else {
			setShowTimeModal(true);
		}
	};

	const onCloseModal = () => {
		setShowTimeModal(false);
	};

	const onSelectTime = (id: string, label: string) => {
		setShowTimeModal(false);
		selectStartTime(`${TimeHelper.getTimesFromBlock(id)}`);
		getAvailableStylistAndTimeBlocks({
			time: label,
		});
	};
	const history = useHistory();
	const onChangeDate = (date: Moment) => {
		getBookings({
			fromDate: date.format('MM-DD-YYYY')!,
			toDate: date.format('MM-DD-YYYY')!,
		});
		changeDate(date);
		setTimeout(() => {
			selectStartTime('');
			changeBookingDetail({
				startAtExpected: date.toDate(),
			});
			if (
				bookingDetails[0] &&
				!_.isEmpty(bookingDetails[0].itemId) &&
				!timeActiveId
			) {
				setTimeout(() => {
					setShowTimeModal(true);
				}, 400);
			}
		}, 500);
	};
	const checkShowTimeModal = () => {
		if (
			(_.isEmpty(timeActiveId) && _.isEmpty(bookingDetails[0].itemId)) ||
			currentBooking
		) {
			return false;
		}
		return (
			(!_.isEmpty(timeBlocks) || !_.isEmpty(bookingDetails[0].itemId)) &&
			_.isEmpty(
				TimeHelper.generateTimeBlocks(timeBlocks).filter(
					(x) => x.label === timeActiveId
				)
			)
		);
	};
	useEffect(() => {
		if (!showTimeModal) {
			setShowTimeModal(!timeActiveId && checkShowTimeModal());
		}
	}, [timeBlocks, timeActiveId, bookingDetails]);

	const onOpenDatePickerModal = () => {
		if (!dateBookingTour) {
			dispatch(
				AppConfigActions.usedTourGuide.request({
					key: 'dateBookingTour',
					used: true,
				})
			);
		}
	};
	const disabled =
		!!quickSale ||
		(currentBooking && currentBooking.status !== BookingStatus.PENDING);

	useEffect(() => {
		const date =
			bookingDetails[0] && bookingDetails[0].startAtExpected
				? moment(bookingDetails[0].startAtExpected).format('MM-DD-YYYY')
				: moment().format('MM-DD-YYYY');
		getBookings({
			fromDate: date,
			toDate: date,
		});
	}, []);
	return (
		<>
			<Header
				onClose={() => {
					history.goBack();
					setTimeout(() => {
						reset();
					}, 500);
				}}
				extraLeft={
					<div className="flex align-middle justify-between w-full">
						<div className="flex align-middle my-auto gap-4 ">
							<DatePicker
								className="btn-choose-booking-date"
								disabledBefore
								disabled={disabled}
								onSelectDate={onChangeDate}
								selectedDate={date}
								onShowModal={onOpenDatePickerModal}
							/>
							{!quickSale && (
								<Button
									className={clsxm(
										'btn-choose-booking-time bg-white rounded-full btn-outline btn-primary capitalize font-medium',
										disabled && 'btn-disabled'
									)}
									small
									disabled={disabled}
									title={
										timeActiveId
											? TimeHelper.toTimeZone(timeActiveId).format('hh:mmA')
											: t(translations.mainBooking.selectTime)
									}
									onClick={onOpenModal}
									iconAfter={<Icon path={mdiClock} />}
								/>
							)}
						</div>
					</div>
				}
				title={t(translations.bookingPage.booking)}
				showClose
			/>
			{!quickSale && showTimeModal ? (
				<TimeModal
					disableCancel={
						checkShowTimeModal() && !timeActiveId && !_.isEmpty(timeBlocks)
					}
					onSelect={onSelectTime}
					onCancel={onCloseModal}
				/>
			) : undefined}
		</>
	);
};
