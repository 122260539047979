import { IEmployeeType } from 'models';
import { BaseApiService } from 'services/BaseApiService';
import {
	IEditEmployeeTypeRequest,
	IQueryEmployeeTypeRequest,
} from 'models/RequestModels';
class EmployeeTypeApiService extends BaseApiService {
	public getAllEmployeeTypes = (query?: IQueryEmployeeTypeRequest) =>
		this.get<IEmployeeType[]>(`/EmployeeType/get-all-employee-types`, {
			params: query,
		});

	public getEmployeeTypeById = (id: string) =>
		this.get<IEmployeeType>(`/EmployeeType/get-employee-type/${id}`);

	public addNewEmployeeType = (
		addEmployeeTypeRequest: IEditEmployeeTypeRequest
	) =>
		this.post<IEditEmployeeTypeRequest, IEmployeeType>(
			`/EmployeeType/create-employee-type`,
			addEmployeeTypeRequest
		);

	public editEmployeeType = (
		editEmployeeTypeRequest: IEditEmployeeTypeRequest
	) =>
		this.put<IEditEmployeeTypeRequest, string>(
			`/EmployeeType/update-employee-type`,
			editEmployeeTypeRequest
		);

	public deleteEmployeeType = (id: string) =>
		this.delete<string>(`/EmployeeType/delete-employee-type/${id}`);
}

export default new EmployeeTypeApiService();
