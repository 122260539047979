import clsxm from 'clsxs/clsxm';
import { Button } from 'components/atoms/Button';
import moment, {
	Moment,
	MomentFormatSpecification,
	MomentBuiltinFormat,
} from 'moment';
import { useEffect, useState } from 'react';
import { TDateRange, CalendarModal } from './ModalCalendar';
import { mdiChevronRight } from '@mdi/js';
import { Icon } from 'components/atoms/Icon';
interface IDateRagePickerProps {
	selectedDateRange?: TDateRange;
	onSelectDateRange?: (dateRange: TDateRange) => void;
	format?: MomentFormatSpecification;
	containerStyle?: StyleSheet;
	disabled?: boolean;
	className?: string;
	disableBefore?: boolean;
}

export const DateRangePicker = (props: IDateRagePickerProps) => {
	const { format = 'MM/DD/YYYY' } = props;
	const [showModal, setShowModal] = useState(false);
	const [dateRage, setDateRage] = useState(
		props.selectedDateRange || { fromDate: moment() }
	);

	const onSelectDateRange = (dateRage: TDateRange) => {
		setDateRage(dateRage);
		props.onSelectDateRange && props.onSelectDateRange(dateRage);
		setShowModal(false);
	};
	useEffect(() => {
		if (props.selectedDateRange) {
			setDateRage(props.selectedDateRange);
		}
	}, [props.selectedDateRange]);
	return (
		<>
			<Button
				className={clsxm(
					'bg-white rounded-full btn-outline btn-primary font-medium min-w-[280px]',
					props.className
				)}
				small
				onClick={() => setShowModal(true)}
				disabled={props.disabled}
			>
				{/* <CalendarOutlined style={{ marginRight: 30 }} /> */}

				<div
					className={clsxm(
						'btn btn-ghost bg-transparent hover:bg-transparent hover:text-primary inline-flex justify-center align-middle'
					)}
				>
					<span>
						{dateRage.fromDate
							? dateRage.fromDate.format(`${format}`)
							: moment().format(`${format}`)}
					</span>

					<Icon path={mdiChevronRight} className="m-2" />
					{dateRage.toDate && <div>{dateRage.toDate.format(`${format}`)}</div>}
				</div>
			</Button>
			{showModal && (
				<CalendarModal
					rangeSelect
					selectedDateRange={dateRage}
					onSelectDateRange={(dateRange) => onSelectDateRange(dateRange)}
					disabledBefore={props.disableBefore}
					onCancel={() => setShowModal(false)}
				/>
			)}
		</>
	);
};
