/* eslint-disable react-hooks/exhaustive-deps */
import {
	mdiBorderColor,
	mdiChevronDown,
	mdiChevronRight,
	mdiDelete,
	mdiNoteEdit,
	mdiPencilOutline,
	mdiPlus,
} from '@mdi/js';
import {
	Button,
	Card,
	ConfirmModal,
	EColumnType,
	IColumnProps,
	Icon,
	Modal,
	TimePicker,
} from 'components/atoms';
import Table from 'components/atoms/Table';
import { PickService } from 'components/molecules/PickService';
import { TimeHelper, CurrencyHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { IService } from 'models';
import moment from 'moment';
import { ServicesModal } from 'pages/Promotion/PromotionDetailPage/components/AppliedToServices';
import { useMemo, useState } from 'react';
import { I18n, _t, translations, t } from 'utils';
import { useStaffDetailContext } from '../context';

export const SelectServices = (props: {}) => {
	const [showModalEdit, setShowModalEdit] = useState(false);

	const { servicesOnTable, setServicesOnTable } = useStaffDetailContext();

	const [serviceIdNewDuration, setServiceIdNewDuration] = useState('');
	const [showAll, setShowAll] = useState(false);
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	const services = useAppSelector((state) => state.ServiceReducer.services);

	const onSave = (selectedRows: Partial<IService>[]) => {
		setServicesOnTable([...selectedRows]);
		setShowModalEdit(false);
	};

	const isEmptyCategory = (categoryId: string) => {
		let index = servicesOnTable.findIndex(
			(service) => service.itemCategoryId === categoryId
		);
		return index === -1 ? true : false;
	};

	const handleSetNewDuration = (newDuration: number, id: string) => {
		let newServices = Array.from(servicesOnTable);
		newServices = newServices.map((x) => {
			if (x.id === id) {
				return { ...x, duration: newDuration };
			}
			return { ...x };
		});
		setServicesOnTable(newServices);
	};

	return (
		<Card
			title={I18n.t(_t(translations.staffDetail.services))}
			description={showAll ? 'Click to minimize' : 'Click to expand'}
			id="select-services"
			onHeaderClick={() => setShowAll(!showAll)}
			headerRight={
				<div className="flex-1 flex justify-end ">
					<span>
						<strong>{`${servicesOnTable.length}/${services.length}`}</strong>{' '}
						services
					</span>
					<Icon size={1.2} path={showAll ? mdiChevronDown : mdiChevronRight} />
				</div>
			}
		>
			{showAll && (
				<>
					<div className="flex w-full justify-end">
						<Button
							small
							title={t(translations.edit)}
							iconBefore={<Icon path={mdiPencilOutline} />}
							onClick={() => {
								setShowModalEdit(true);
							}}
						/>
					</div>
					<ul className="list-none p-0">
						{categories.map((category) => {
							return isEmptyCategory(category.id) ? null : (
								<li className="border-1 shadow-md my-4 w-full rounded-md px-0">
									<div className="bg-base-200 h-12 text-lg font-semibold p-2 rounded-t-md flex">
										{category.name}
									</div>
									<ul className="p-0 pb-2">
										{servicesOnTable
											.filter(
												(service) => service.itemCategoryId === category.id
											)
											.map((x, index) => {
												return (
													<div key={index}>
														{index !== 0 && <div className="divider my-0" />}
														<li className="px-12 flex items-center ">
															<div className="ml-2 flex basis-11/12 items-center gap-3">
																<span className="basis-1/2">{x.name}</span>
																<span className="basis-1/4 flex items-center">
																	{serviceIdNewDuration === x.id ? (
																		<TimePicker
																			minuteStep={5}
																			use12Hours={false}
																			format={'HH:mm'}
																			defaultValue={moment(
																				TimeHelper.convertDurationToString(
																					x?.duration || 0
																				),
																				'HH:mm'
																			)}
																			hideDisabledOptions
																			onChange={(e) =>
																				handleSetNewDuration(
																					TimeHelper.convertDurationToNumber(
																						moment(e.toString()).format('HH:mm')
																					),
																					x.id || ''
																				)
																			}
																			onClose={() =>
																				setServiceIdNewDuration('')
																			}
																			hideAmPm
																			className="w-1/3"
																			// leftPopUp
																		/>
																	) : !x.duration && x.duration === 0 ? (
																		<div className="text-base">
																			{' '}
																			Edit Duration
																		</div>
																	) : (
																		TimeHelper.convertDurationToStringDetail(
																			x.duration || 0
																		)
																	)}
																	<div className="inline-flex items-end">
																		<Button
																			ghost
																			small
																			iconBefore={
																				<Icon
																					className="text-[#696969]"
																					path={mdiPencilOutline}
																				/>
																			}
																			onClick={() =>
																				setServiceIdNewDuration(x.id || '')
																			}
																		/>
																	</div>
																</span>

																<span className="basis-1/8">
																	{CurrencyHelper.formatPrice(x.price)}
																</span>
															</div>
														</li>
													</div>
												);
											})}
									</ul>
								</li>
							);
						})}
					</ul>
				</>
			)}

			<PickService
				title={t(translations.promotionDetail.modalTitle)}
				visible={showModalEdit}
				selected={servicesOnTable as IService[]}
				onSave={(data) => onSave(data)}
				onCancel={() => setShowModalEdit(false)}
			/>
		</Card>
	);
};
