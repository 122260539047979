import Table, {
	EColumnType,
	IColumnProps,
	ITableRef,
} from 'components/atoms/Table';
import { IMarketingEmail } from 'models/IMarketingEmail';
import { translations, t } from 'utils';
import React, { useMemo, useRef, useState } from 'react';
import { toTimeZone } from 'helpers/timeHelper';
import { Button, ConfirmModal, Icon } from 'components/atoms';
import { useHistory } from 'react-router-dom';
import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js';
import EmailBlastApiService from 'services/EmailBlastApiService';
import { AlertHelper } from 'helpers';
import { IErrorResponse } from 'models';
import clsxm from 'clsxs/clsxm';

export const ButtonsAction = (props: {
	onEdit: () => void;
	onDelete: () => void;
	isSelected?: boolean;
}) => {
	return (
		<div className="flex gap-4 items-center">
			<div
				className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
				onClick={() => props.onEdit()}
			>
				<Icon path={mdiPencilOutline} className="w-6 h-6" />
			</div>
			<div
				className={clsxm(props.isSelected ? 'text-white' : 'text-[#f0f0f0]')}
			>
				|
			</div>
			<div
				className="cursor-pointer rounded border border-DELETED p-2 bg-white"
				onClick={() => props.onDelete()}
			>
				<Icon path={mdiDeleteOutline} className="text-DELETED w-6 h-6" />
			</div>
		</div>
	);
};
export const ListEmail = () => {
	const [showModal, setShowModal] = useState(false);
	const [emailId, setEmailId] = useState('');
	const history = useHistory();
	const tableRef = useRef<ITableRef>();
	const columns = useMemo<IColumnProps<IMarketingEmail>[]>(() => {
		return [
			{
				header: t(translations.emailBlast.emailSubject),
				key: 'subject',
			},
			{
				header: t(translations.emailBlast.customerReach),
				key: 'customerReach',
				type: EColumnType.NUMBER,
			},
			{
				header: t(translations.status),
				key: 'status',
				renderCell: (data) => {
					return data.status === 1
						? t(translations.textMessageDetail.sent)
						: t(translations.textMessageDetail.pending);
				},
			},
			{
				header: t(translations.emailBlast.sentTime),
				key: 'sentTime',
				renderCell: (data) => {
					return data.sentTime
						? toTimeZone(data.sentTime).format('MM/DD/YYYY HH:mm A')
						: '';
				},
			},
			{
				header: t(translations.action),
				key: 'id',
				headerClassName: 'w-44',
				cellClassName: 'w-44',
				renderCell: (data) => {
					return (
						<ButtonsAction
							onEdit={() => history.push(`email-blast/edit/${data.id}`)}
							onDelete={() => {
								setEmailId(data.id);
								setShowModal(true);
							}}
						/>
					);
				},
			},
		];
	}, []);

	const onDeleteEmail = async () => {
		try {
			const result = await EmailBlastApiService.deleteMarketingEmail(emailId);
			if (result.succeeded) {
				AlertHelper.showSuccess(
					t(translations.emailBlast.messageDeleteSuccess)
				);
				tableRef.current?.refresh();
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {}
	};

	return (
		<>
			<Table<IMarketingEmail>
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				queryUrl="/MarketingEmail/get-all-marketing-emails"
				showSearch
				columns={columns}
				renderHeader={
					<Button
						small
						primary
						title={t(translations.addNew)}
						iconBefore={<Icon path={mdiPlus} />}
						onClick={() => history.push('/email-blast/add-new')}
					/>
				}
				// className="max-h-[calc(100vh-27.3rem)]"
			/>
			<ConfirmModal
				visible={showModal}
				title={t(translations.delete)}
				subTitle={t(translations.emailBlast.deleteConfirmContent)}
				onPositiveClick={() => {
					onDeleteEmail();
					setShowModal(false);
				}}
				onNegativeClick={() => setShowModal(false)}
				type="error"
			/>
		</>
	);
};
