import { Select } from 'components/atoms';
import { useAppSelector } from 'helpers/hookHelpers';
import { IBranch } from 'models/IBranch';
import React from 'react';
import { t, translations } from 'utils';

interface IBranchSelectProps {
	onSelect: (branchId: string) => void;
	value?: string;
}

export const BranchSelect = (props: IBranchSelectProps) => {
	const branches = useAppSelector(
		(state) => state.BranchReducer.branches
	) as Partial<IBranch>[];

	const user = useAppSelector((state) => state.UserReducer.user);
	if (!user?.userRoles?.includes('Admin') || branches.length < 2) {
		return null;
	}

	return (
		<div className="flex items-center gap-2 ">
			<strong className="bold">
				{t(translations.ownerFinancialSummaryReport.branchFilter)}
			</strong>
			<Select<Partial<IBranch>>
				options={[
					{
						id: 'all',
						name: t(translations.ownerFinancialSummaryReport.allBranch),
					} as Partial<IBranch>,
				].concat(branches || [])}
				size="small"
				titleField={'name'}
				keyField={'id'}
				valueId={props.value}
				className={'min-w-[250px]'}
				left
				onChange={(branch) => props.onSelect(`${branch.id}`)}
			/>
		</div>
	);
};
