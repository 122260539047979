import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IExpenseType } from 'models';
import { ExpenseTypeActions } from 'redux/actions';

export interface IExpenseTypeReducer {
	types: IExpenseType[];
}

const initialState: IExpenseTypeReducer = {
	types: [],
};

const getAllExpenseTypeSuccess = (
	state: IExpenseTypeReducer,
	action: PayloadAction<IExpenseType[]>
) => {
	state.types = action.payload;
};

const ExpenseTypeReducer = createReducer(initialState, (builder) =>
	builder.addCase(
		ExpenseTypeActions.getAllExpenseTypes.success,
		getAllExpenseTypeSuccess
	)
);

export default ExpenseTypeReducer;
