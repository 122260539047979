import { ReduxHelper } from "helpers";
import {
  IBookingActivity,
  IRecentSalesChartDataSource,
  ITopServices,
  IUpComingBookingsChartDataSource,
} from "models";
import {
  IGetRequest,
  IPaginateRequest,
  ITodayNextBookingActivityRequest,
  ITopServicesRequest,
} from "models/RequestModels";
import { IPaginateResponse } from "models/ResponseModels";

export const prefix = "ADMIN_DASHBOARD";

const getRecentSalesChart = ReduxHelper.generateActions<
  IGetRequest,
  IRecentSalesChartDataSource
>(`${prefix}/GET_RECENT_SALE_CHART`);

const getUpComingBookingsChart = ReduxHelper.generateActions<
  IGetRequest,
  IUpComingBookingsChartDataSource
>(`${prefix}/GET_UP_COMING_CHART`);

const getBookingActivity = ReduxHelper.generateActions<
  IPaginateRequest,
  IPaginateResponse<IBookingActivity[]>
>(`${prefix}/GET_BOOKING_ACTIVITY`);

const getTodayNextBookingActivity = ReduxHelper.generateActions<
  ITodayNextBookingActivityRequest,
  IPaginateResponse<IBookingActivity[]>
>(`${prefix}/GET_TODAY_BOOKING_ACTIVITY`);

const getTopServices = ReduxHelper.generateActions<
  ITopServicesRequest,
  ITopServices[]
>(`${prefix}/GET_TOP_SERVICES`);

const getTopStaffs = ReduxHelper.generateActions<
  ITopServicesRequest,
  ITopServices[]
>(`${prefix}/GET_TOP_STAFF`);

const clearData = ReduxHelper.generateLocalAction(`${prefix}/CLEAR_DATA`);

export {
  getRecentSalesChart,
  getUpComingBookingsChart,
  getBookingActivity,
  getTodayNextBookingActivity,
  getTopServices,
  getTopStaffs,
  clearData,
};
