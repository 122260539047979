import { DetailPageLayout } from "components/molecules";
import React, { useEffect, useState } from "react";
import { I18n, _t, translations } from "utils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm, useFormContext } from "react-hook-form";
import { IApiResponse, IEmployeeType, IErrorResponse, IRole } from "models";
import _ from "lodash";
import { AlertHelper } from "helpers";
import { Card, Input, Select, Switch } from "components/atoms";
import { IEditEmployeeTypeRequest } from "models/RequestModels";
import EmployeeTypeApiService from "services/EmployeeTypeApiService";
import RoleApiService from "services/RoleApiService";
import { getValue } from "@testing-library/user-event/dist/utils";

interface FormProps {
  employeeType?: IEmployeeType;
  roles: IRole[];
}
interface formData {
  name: string;
  description: string;
  roleId: string;
  status: boolean;
}
const FormBasicInfo = (props: FormProps) => {
  const {
    formState: { errors },
    watch,
    setValue,
    getValues,
    register,
  } = useFormContext<formData>();
  const roleId = watch("roleId");
  const status = watch("status");

  useEffect(() => {
    register("roleId");
    register("status");
  }, [register]);
  return (
    <Card title={I18n.t(_t(translations.employeeType.staffTypeInfo))}>
      <div className="grid grid-cols-4 gap-x-8 gap-y-8 pt-8">
        <Input
          label={I18n.t(_t(translations.employeeType.employeeType))}
          placeholder={I18n.t(_t(translations.employeeType.inputEmployeeType))}
          {...register("name", {
            required: I18n.t(
              _t(translations.employeeType.employeeTypeRequired)
            ),
          })}
          autoFocus={true}
          error={errors.name?.message}
        />
        <Input
          label={I18n.t(_t(translations.employeeType.description))}
          placeholder={I18n.t(_t(translations.employeeType.inputDescription))}
          {...register("description")}
          error={errors.description?.message}
        />
        <Select<IRole>
          options={props.roles}
          titleField={"name"}
          keyField={"id"}
          label={I18n.t(_t(translations.employeeType.rolePermission))}
          title={"Select Role"}
          fullWidth
          valueId={roleId}
          onChange={(value) => setValue("roleId", value.id)}
          error={errors.roleId?.message}
        />
        <div>
          <label className="label font-medium ">
            <span className="label-text text-md">
              {I18n.t(_t(translations.employeeType.status))}
            </span>
          </label>
          <Switch
            defaultChecked={getValues().status}
            onChange={(value) => {
              setValue("status", value);
            }}
            value={status}
          />
        </div>
      </div>
    </Card>
  );
};

export const StaffTypeDetailPage = () => {
  const location = useLocation();
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const [employeeType, setEmployeeType] = useState<IEmployeeType>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isAdd = () => {
    return location.pathname.includes("add-new");
  };
  const goBack = () => {
    history.push("/staff-type");
  };

  const form = useForm<formData>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: { status: true },
  });

  const fetchStaffTypeById = async () => {
    setIsLoading(true);
    try {
      const res = (await EmployeeTypeApiService.getEmployeeTypeById(
        id
      )) as IApiResponse<IEmployeeType>;
      if (res.succeeded) {
        setEmployeeType(res.data);
        form.reset({
          description: res.data?.description || "",
          name: res.data?.name || "",
          roleId: res.data?.roleId,
          status: res.data?.status === 0 ? false : true,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchRoles = async () => {
    setIsLoading(true);
    try {
      const res = (await RoleApiService.getAllRoles()) as IApiResponse<IRole[]>;
      if (res.succeeded) {
        setRoles(res.data || []);
        if (!_.isEmpty(res.data)) {
          form.setValue("roleId", res.data ? res.data[0].id : "");
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRoles();
    if (!isAdd()) {
      fetchStaffTypeById();
    }
  }, []);

  const submitForm = async (formData: formData) => {
    setIsLoading(true);
    const data: IEditEmployeeTypeRequest = {
      id: isAdd() ? "" : employeeType?.id!,
      name: formData.name,
      description: formData.description,
      roleId: formData.roleId,
      status: formData.status ? 1 : 0,
    };
    const res = (
      isAdd()
        ? await EmployeeTypeApiService.addNewEmployeeType(data)
        : await EmployeeTypeApiService.editEmployeeType(data)
    ) as IApiResponse;
    if (res.succeeded) {
      setIsLoading(false);
      AlertHelper.showSuccess(
        isAdd()
          ? I18n.t(_t(translations.employeeType.addNewSuccess))
          : I18n.t(_t(translations.employeeType.editSuccess))
      );
      goBack();
    } else {
      setIsLoading(false);
      const error = res as IErrorResponse;
      AlertHelper.showError(error);
    }
  };

  return (
    <DetailPageLayout<formData>
      title={I18n.t(
        _t(
          isAdd()
            ? translations.employeeType.addNewEmployeeType
            : translations.employeeType.editEmployeeType
        )
      )}
      form={form}
      onSave={form.handleSubmit(submitForm)}
      loading={isLoading}
    >
      {(isAdd() || !_.isEmpty(employeeType)) && (
        <>
          <FormBasicInfo employeeType={employeeType} roles={roles} />
        </>
      )}
    </DetailPageLayout>
  );
};
