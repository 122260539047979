import { Card } from 'components/atoms';
import { CurrencyHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import { t, translations } from 'utils';
import React from 'react';
import { useReportContext } from './context';
import { ReportFieldValue } from './ReportFieldValue';
import { Divider } from './Divider';
import { SkeletonLoading } from './SkeletonLoading';
import clsxm from 'clsxs/clsxm';

export const GeneralFinanceReport = () => {
	const { discountBeforeTax } = useAppSelector(
		(state) => state.BranchReducer.currentBranch!
	);
	const { loading, report } = useReportContext();
	return (
		<div className={clsxm('h-fit')}>
			<Card title={t(translations.ownerFinancialSummaryReport.generalFinance)}>
				{loading ? (
					<SkeletonLoading />
				) : (
					<>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.serviceSold)}
							value={CurrencyHelper.formatPrice(report?.serviceSold || 0)}
						/>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.supplyCharge)}
							value={CurrencyHelper.formatPrice(report?.supplyFee)}
						/>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.productSold)}
							value={CurrencyHelper.formatPrice(report?.productSold)}
						/>
						{!discountBeforeTax && (
							<>
								<Divider />
								<ReportFieldValue
									title={t(
										translations.ownerFinancialSummaryReport.taxableRevenue
									)}
									value={CurrencyHelper.formatPrice(report?.taxableRevenue)}
								/>
								<ReportFieldValue
									title={t(
										translations.ownerFinancialSummaryReport.totalSaleTax
									)}
									value={CurrencyHelper.formatPrice(report?.tax)}
									sub
								/>
							</>
						)}
						<ReportFieldValue
							title={`${t(
								translations.ownerFinancialSummaryReport.discount
							)} (${
								discountBeforeTax
									? t(translations.ownerFinancialSummaryReport.beforeTax)
									: t(translations.ownerFinancialSummaryReport.afterTax)
							})`}
							value={CurrencyHelper.formatPrice(report?.discount)}
						/>
						<ReportFieldValue
							title={`${t(translations.ownerFinancialSummaryReport.promotion)} (
						${
							discountBeforeTax
								? t(translations.ownerFinancialSummaryReport.beforeTax)
								: t(translations.ownerFinancialSummaryReport.afterTax)
						}
						)`}
							value={CurrencyHelper.formatPrice(report?.promotionDiscount)}
						/>
						{discountBeforeTax && (
							<>
								<Divider />
								<ReportFieldValue
									title={t(
										translations.ownerFinancialSummaryReport.taxableRevenue
									)}
									value={CurrencyHelper.formatPrice(report?.taxableRevenue)}
								/>
								<ReportFieldValue
									title={t(
										translations.ownerFinancialSummaryReport.totalSaleTax
									)}
									value={CurrencyHelper.formatPrice(report?.tax)}
									sub
								/>
							</>
						)}
						<ReportFieldValue
							title={t(
								translations.ownerFinancialSummaryReport.totalCardCharge
							)}
							value={CurrencyHelper.formatPrice(report?.cardCharge)}
						/>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.giftCardSold)}
							value={CurrencyHelper.formatPrice(report?.giftCardSold)}
						/>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.serviceRefund)}
							value={CurrencyHelper.formatPrice(report?.serviceRefund)}
						/>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.productRefund)}
							value={CurrencyHelper.formatPrice(report?.productRefund)}
						/>
						<Divider />
						{/* <Description
					title={I18n.t(
						_t(translations.ownerFinancialSummaryReport.productRefund)
					)}
					value="100"
				/> */}
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.gross)}
							value={CurrencyHelper.formatPrice(report?.revenue)}
							bold
							big
						/>

						{report?.expensesBreakdown.map((element, index) => (
							<ReportFieldValue
								title={EXPENSE_BREAKDOWN[index]}
								value={CurrencyHelper.formatPrice(element?.amount)}
								bold
								big
							/>
						))}

						<Divider />
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.profit)}
							value={CurrencyHelper.formatPrice(report?.profit)}
							bold
							big
						/>
					</>
				)}
			</Card>
		</div>
	);
};

const EXPENSE_BREAKDOWN = [
	t(translations.ownerFinancialSummaryReport.payrollExpenses),
	t(translations.ownerFinancialSummaryReport.otherExpenses),
];
