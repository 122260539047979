import { mdiCheckAll, mdiClose, mdiPrinter } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Description, Icon } from 'components/atoms';
import { AlertHelper, CurrencyHelper } from 'helpers';
import { showError, showSuccess } from 'helpers/alertHelper';
import { priceFixed } from 'helpers/currencyHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import { createESCCheckOut, print } from 'helpers/printHelper';
import _ from 'lodash';
import { EmployeeCodeModal } from 'pages/CheckOut/components/Modals/EmployeeCodeModal';
import { useCheckOutContext } from 'pages/CheckOut/context';
import React, { useMemo, useState } from 'react';
import GiftCardApiService from 'services/GiftCardApiService';
import TipApiService from 'services/TipApiService';
import { t, translations } from 'utils';
import { PaymentType, RECEIPT_PRINT_TYPE } from 'utils/Consts';
import { calculateCardCharge } from './Modals/CardModal';
import { history } from 'routers';

const Discount = () => {
	const { discount, setDiscount, setDiscounterType, totalAmountBillDetails } =
		useCheckOutContext();
	if (!discount || discount === 0) {
		return null;
	}
	const discountPercent = CurrencyHelper.priceFixed(
		(discount * 100) / totalAmountBillDetails()
	);
	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8">
			<Description
				title={`${t(translations.checkout.discount)} (${discountPercent}%)`}
				value={`-${CurrencyHelper.formatPrice(discount)}`}
				valueClassName={'text-error'}
			/>
			<div
				onClick={() => {
					setDiscount(0);
					setDiscounterType(undefined);
				}}
				className="absolute cursor-pointer flex justify-center text-error items-center right-1 top-0 bottom-0"
			>
				<Icon path={mdiClose} />
			</div>
		</div>
	);
};
const PromotionDiscount = () => {
	const { promotion, billDetails, childrenBills, deletePromotion } =
		useCheckOutContext();
	const childBillDetails = _.flattenDeep(
		childrenBills?.map((x) => x.billDetails!)
	);
	const allBillDetails = billDetails?.concat(childBillDetails);

	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8 pt-0 mt-0">
			<h4>
				Promotion: {promotion?.name}{' '}
				{!_.isEmpty(promotion?.coupons)
					? `( ${promotion?.coupons![0].couponCode} )`
					: ''}
			</h4>
			<Description
				title={`${t(translations.checkout.discount)}: `}
				value={`-${CurrencyHelper.formatPrice(
					_.sumBy(allBillDetails, (x) => x?.promotionDiscount || 0)
				)}`}
				valueClassName={'text-error'}
			/>
			<div
				onClick={() => {
					deletePromotion();
				}}
				className="absolute cursor-pointer flex justify-center text-error items-center right-1 top-0 bottom-0"
			>
				<Icon path={mdiClose} />
			</div>
		</div>
	);
};
const Tip = () => {
	const {
		tip,
		setTip,
		payments,
		setTipAfterCheckout,
		done,
		calculateAmountNeedToPay,
		tipIds,
	} = useCheckOutContext();
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	if (_.isEmpty(tip)) {
		return null;
	}

	const onDeleteTip = () => {
		if (done) {
			tipIds?.forEach(async (x) => {
				const res = await TipApiService.deleteTip(x || '');
				if (!res.succeeded) {
					showError(res);
					return;
				}
			});
		}
		setTip(undefined);
		setTipAfterCheckout(undefined);
		if (calculateAmountNeedToPay() !== 0) {
			return;
		}
		let newPayments = _.clone(payments);
		const creditIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.CREDIT_CARD
		);
		const appIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.APP
		);
		const cashIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.CASH
		);
		if (creditIndex > -1 && newPayments) {
			const oldCardCharge = newPayments[creditIndex].cardCharge || 0;
			const newAmount =
				(newPayments[creditIndex].amount || 0) -
				oldCardCharge -
				(tip?.totalTip || 0);

			newPayments[creditIndex].amount = priceFixed(
				newAmount + calculateCardCharge(`${newAmount}`, branch)
			);

			newPayments[creditIndex].cardCharge = priceFixed(
				calculateCardCharge(`${newAmount}`, branch)
			);
			return;
		}
		if (appIndex > -1 && newPayments) {
			newPayments[appIndex].amount = priceFixed(
				(newPayments[appIndex].amount || 0) - (tip?.totalTip || 0)
			);

			return;
		}
		if (cashIndex > -1 && newPayments) {
			newPayments[cashIndex].amount = priceFixed(
				(newPayments[cashIndex].amount || 0) - (tip?.totalTip || 0)
			);
			return;
		}
	};

	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8">
			<Description
				title={t(translations.checkout.tip)}
				value={CurrencyHelper.formatPrice(tip?.totalTip)}
			/>
			<div
				onClick={onDeleteTip}
				className="absolute cursor-pointer flex justify-center text-error items-center right-1 top-0 bottom-0"
			>
				<Icon path={mdiClose} />
			</div>
		</div>
	);
};
const GiftCard = () => {
	const { totalGiftCardAmount } = useCheckOutContext();
	if (totalGiftCardAmount() === 0) {
		return null;
	}
	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8">
			<Description
				title={t(translations.checkout.totalGiftCard)}
				titleClassName="font-bold"
				value={CurrencyHelper.formatPrice(totalGiftCardAmount())}
			/>
		</div>
	);
};
const CardCharge = () => {
	const { payments } = useCheckOutContext();
	const totalCardCharge = _.sumBy(payments, (x) => x.cardCharge || 0);
	if (totalCardCharge <= 0) {
		return null;
	}
	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8">
			<Description
				title={t(translations.checkout.cardCharge)}
				value={CurrencyHelper.formatPrice(totalCardCharge)}
			/>
		</div>
	);
};

export const BillGeneral = () => {
	const {
		payments = [],
		calculateAmountNeedToPay,
		totalAmount,
		totalAmountBillDetails,
		totalTax,
		complete,
		discount,
		loading,
		customer,
		promotion,
		bill,
		billDetails = [],
		childrenBills = [],
		giftCards = [],
		tip,
		done,
	} = useCheckOutContext();
	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const [showEmployeeCode, setShowEmployeeCode] = useState(false);
	const [onlyVoucherGiftCardLoading, setOnlyVoucherGiftCardLoading] =
		useState<boolean>();
	const [employeeIdReceipt, setEmployeeIdReceipt] = useState('');
	const employees = useAppSelector((state) => state.EmployeeReducer.employees);
	const paid = _.sumBy(payments, (x) => x.amount || 0);
	const change = paid - totalAmount();
	const receiptPrintType = useAppSelector(
		(state) => state.SettingReducer.receiptPrintType
	);
	const taxPercent =
		useAppSelector((state) => state.BranchReducer.currentBranch?.taxPercent) ||
		0;
	const user = useAppSelector((state) => state.UserReducer.employee);
	const onComplete = async (employeeId?: string) => {
		if (giftCards.some((e) => e.type === 1)) {
			const requests = giftCards
				.filter((e) => e.type === 1)
				.map((e) => GiftCardApiService.addGiftCard(e));
			setOnlyVoucherGiftCardLoading(true);
			const response = await Promise.all(requests);
			if (response.every((e) => e.succeeded)) {
			} else {
				response.forEach((e) => AlertHelper.showError(e));
			}
		}
		const result = await complete(employeeId || '');

		if (result) {
			onPrint(employeeId);
		}
	};
	const onPrint = async (
		employeeId?: string,
		isDraft?: boolean,
		printType?: RECEIPT_PRINT_TYPE
	) => {
		const discountPercent = CurrencyHelper.priceFixed(
			((discount || 0) * 100) / totalAmountBillDetails()
		);
		const dataPrint = {
			billNumber: bill?.billNumber,
			bookingId: `${bill?.bookingId}`,
			billingDetails: billDetails,
			listBillingCombine: childrenBills,
			giftCards: giftCards,
			subTotalService: totalAmountBillDetails(),
			tax: totalTax(),
			balance: totalAmount(),
			tip: tip?.totalTip,
			discount: discount,
			discountPer: discountPercent,
			paymentTypes: payments,
			employee: employees?.find((x) => x.id === employeeId),
			customer: customer!,
			change: change,
			isDraft,
			customerPaid: paid,
		};
		const type = !_.isUndefined(printType) ? printType : receiptPrintType;
		if (type !== RECEIPT_PRINT_TYPE.NONE || isDraft) {
			const joint_1 = await createESCCheckOut({
				...dataPrint,
				joint: type === RECEIPT_PRINT_TYPE.ONE_CUSTOMER ? 2 : 1,
			});
			await print(joint_1);
		}
		if (type === RECEIPT_PRINT_TYPE.TWO && !isDraft) {
			setTimeout(async () => {
				const joint_2 = await createESCCheckOut({
					...dataPrint,
					joint: 2,
					isDraft: false,
				});
				await print(joint_2);
			}, 1000);
		}
	};
	const shop = useAppSelector((state) => state.ShopReducer.shop);

	const isOnlyVoucherGiftCard = useMemo(
		() =>
			_.isEmpty(childrenBills) &&
			_.isEmpty(billDetails) &&
			!_.isEmpty(giftCards) &&
			giftCards.every((e) => e.type === 1),
		[childrenBills, billDetails, giftCards]
	);

	const onCompleteOnlyVoucherGiftCard = async () => {
		const requests = giftCards
			.filter((e) => e.type === 1)
			.map((e) => GiftCardApiService.addGiftCard(e));
		setOnlyVoucherGiftCardLoading(true);
		const response = await Promise.all(requests);
		if (response.every((e) => e.succeeded)) {
			AlertHelper.showSuccess(
				`${t(translations.servicesDetail.voucherSales)} ${t(
					translations.text.success
				)}`
			);
			history.goBack();
		} else {
			response.forEach((e) => AlertHelper.showError(e));
		}
		setOnlyVoucherGiftCardLoading(false);
	};

	return (
		<div className="flex flex-col bg-white flex-1 rounded-lg">
			<div id="bill" className="flex-1 flex flex-col justify-end">
				<div className="ml-4 p-2 pl-4 pr-8">
					<Description
						title={t(translations.checkout.subtotal)}
						value={CurrencyHelper.formatPrice(totalAmountBillDetails())}
					/>
				</div>
				{currentBranch?.discountBeforeTax && <Discount />}
				{currentBranch?.discountBeforeTax && promotion && <PromotionDiscount />}
				<div className="ml-4 p-2 pl-4 pr-8 border-t border-t-base-200  ">
					<Description
						title={`${t(translations.checkout.tax)} (${taxPercent}%)`}
						value={CurrencyHelper.formatPrice(totalTax() >= 0 ? totalTax() : 0)}
					/>
				</div>
				{!currentBranch?.discountBeforeTax && <Discount />}
				{!currentBranch?.discountBeforeTax && promotion && (
					<PromotionDiscount />
				)}
				<GiftCard />
				<Tip />
				<CardCharge />
				<div className="ml-4 p-2 pl-4 pr-8 border-t border-t-base-200">
					<Description
						titleClassName="text-lg font-bold"
						valueClassName="text-lg font-bold"
						title={t(translations.bookingCustomerInfo.total)}
						value={CurrencyHelper.formatPrice(totalAmount())}
					/>
				</div>
				<div className="ml-4 rounded-l-lg mb-4 bg-base-200 p-2 pl-4 pr-8">
					<Description title="Paid" value={CurrencyHelper.formatPrice(paid)} />
					<Description
						title={t(translations.checkout.amountNeedToPay)}
						value={CurrencyHelper.formatPrice(calculateAmountNeedToPay())}
					/>
					{change > 0 && (
						<Description
							title={t(translations.checkout.change)}
							value={CurrencyHelper.formatPrice(change)}
						/>
					)}
				</div>
			</div>

			<div className={clsxm('flex', done && 'invisible')}>
				<Button
					id="print-draft-bill"
					className="flex-1 rounded-none rounded-bl-lg btn-success text-sm p-2 gap-1"
					title={t(translations.checkout.draftBill)}
					onClick={() => onPrint('', true)}
					iconBefore={<Icon path={mdiPrinter} />}
				/>
				<Button
					id="complete"
					className={clsxm(
						'flex-[2_2_0%] rounded-none rounded-br-lg text-sm px-2',
						loading && 'loading'
					)}
					primary={calculateAmountNeedToPay() <= 0 && !!customer}
					disabled={
						!isOnlyVoucherGiftCard &&
						(calculateAmountNeedToPay() > 0 || loading || _.isEmpty(customer))
					}
					title={t(translations.checkout.complete)}
					onClick={() => {
						if (isOnlyVoucherGiftCard) {
							onCompleteOnlyVoucherGiftCard();
						} else {
							if (currentBranch?.checkEmployeeCodeWhenCheckout) {
								setShowEmployeeCode(true);
							} else {
								onComplete(user?.id);
							}
						}
					}}
					iconBefore={<Icon path={mdiCheckAll} />}
				/>
			</div>
			{done && (
				<div>
					<Button
						className="w-full rounded-none btn-success text-sm p-2 gap-1"
						title="Print Salon's Receipt"
						onClick={() =>
							onPrint(
								employeeIdReceipt || user?.id,
								false,
								RECEIPT_PRINT_TYPE.ONE
							)
						}
						iconBefore={<Icon path={mdiPrinter} />}
					/>
					<Button
						className={clsxm(
							'w-full rounded-none rounded-b-lg text-sm px-2',
							loading && 'loading'
						)}
						primary
						title={"Print Customer's Receipt"}
						onClick={() =>
							onPrint(
								employeeIdReceipt || user?.id,
								false,
								RECEIPT_PRINT_TYPE.ONE_CUSTOMER
							)
						}
						iconBefore={<Icon path={mdiPrinter} />}
					/>
				</div>
			)}

			{showEmployeeCode && (
				<EmployeeCodeModal
					onCancel={() => setShowEmployeeCode(false)}
					onConfirm={(employeeId) => {
						onComplete(employeeId);
						setShowEmployeeCode(false);
						setEmployeeIdReceipt(employeeId);
					}}
				/>
			)}
		</div>
	);
};
