import { ReduxHelper } from "helpers";
import { ICustomer } from "models";
import { IGetAllCustomerRequest } from "models/RequestModels";

export const prefix = "CUSTOMER";


const selectCustomer = ReduxHelper.generateLocalAction<ICustomer>(
  `${prefix}/SELECT_CURRENT_CUSTOMER`);
  
const getAllCustomers = ReduxHelper.generateActions<IGetAllCustomerRequest | undefined, ICustomer[] | null>(
  `${prefix}/GET_ALL_CUSTOMERS`
);
const addCustomer = ReduxHelper.generateActions<Partial<ICustomer>>(
  `${prefix}/ADD_CUSTOMER`
);
const editCustomer = ReduxHelper.generateActions<Partial<ICustomer>>(
  `${prefix}/EDIT_CUSTOMER`
);
const deleteCustomer = ReduxHelper.generateActions<string>(`${prefix}/DELETE_CUSTOMER`);

const getCustomerById = ReduxHelper.generateActions<
  string | undefined,
  ICustomer
>(`${prefix}/GET_CUSTOMER_BY_ID`);
const removeCurrentCustomer = ReduxHelper.generateLocalAction(
  `${prefix}/REMOVE_CURRENT_CUSTOMER`
);
export {
  getAllCustomers,
  selectCustomer,
  addCustomer,
  editCustomer,
  getCustomerById,
  deleteCustomer,
  removeCurrentCustomer
};
