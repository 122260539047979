import { Icon } from 'components/atoms/Icon';
import { useBookingHook } from 'helpers/bookingHelper';
import { mdiAccountCancelOutline, mdiAccountEdit, mdiDelete } from '@mdi/js';
import React, { useState } from 'react';
import { BookingStatus, WALKIN_CUSTOMER } from 'utils/Consts';
import BookingApiService from 'services/BookingApiService';
import { useHistory, useLocation } from 'react-router-dom';
import { showAlert } from 'helpers/alertHelper';
import _ from 'lodash';
import { ConfirmModal } from 'components/atoms';
import { t, translations } from 'utils';
import qs from 'qs';
import { IBookingSearchQuery } from 'models/RequestModels';
export const Actions = (props: { editCustomer?: () => void }) => {
	const { customer, removeCustomer, currentBooking, selectCustomer } =
		useBookingHook();
	const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const quickSale = queries.quickSale;
	const history = useHistory();
	if (!customer) {
		return null;
	}
	const buttons = [{ id: 'Remove From Booking' }, { id: 'Additional Booking' }];
	if (currentBooking) {
		buttons.push({ id: 'Cancel Booking' });
	}
	return (
		<div className="w-full bg-white rounded-l-lg mb-2 px-4 py-2 grid grid-cols-3 gap-1 rtl-grid">
			{currentBooking && currentBooking.status <= BookingStatus.CHECKED_IN && (
				<div
					onClick={() => setCancelConfirmModal(true)}
					className="flex gap-1 shadow-lg bg-white items-center justify-center border rounded-lg text-xs p-1 border-error text-error cursor-pointer active:animate-pop "
				>
					Cancel Booking
					<Icon path={mdiDelete} />
				</div>
			)}
			<div
				onClick={() => {
					props.editCustomer && props.editCustomer();
				}}
				className="flex gap-1 items-center shadow-lg bg-white justify-center border rounded-lg text-xs p-1 cursor-pointer active:animate-pop "
			>
				Edit Customer
				<Icon path={mdiAccountEdit} />
			</div>
			<div
				onClick={() => {
					quickSale ? selectCustomer(WALKIN_CUSTOMER) : removeCustomer();
				}}
				className="flex gap-1 items-center shadow-lg bg-white justify-center border rounded-lg text-xs p-1 cursor-pointer active:animate-pop "
			>
				Remove Customer
				<Icon path={mdiAccountCancelOutline} />
			</div>
			<ConfirmModal
				visible={cancelConfirmModal}
				title={t(translations.delete)}
				subTitle={t(translations.cancelBookingConfirm)}
				onPositiveClick={async () => {
					const response = await BookingApiService.updateBookingStatus({
						id: currentBooking?.id!,
						status: BookingStatus.CANCELED,
					});
					setCancelConfirmModal(false);
					if (response.succeeded) {
						showAlert('Cancel booking successfully', 'success');
						_.delay(() => history.go(0), 500);
					}
				}}
				onNegativeClick={() => setCancelConfirmModal(false)}
				type="error"
			/>
		</div>
	);
};
