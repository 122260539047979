import { PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse, IErrorResponse, IHolidayModel } from "models";
import {
  IAddHolidayRequest,
  IGetAllHolidayRequest,
} from "models/RequestModels";
import { IPaginateResponse } from "models/ResponseModels";
import { call, put, takeEvery } from "redux-saga/effects";
import { HolidayApiService } from "services";
import { HolidayActions } from "../actions";

function* addHoliday(action: PayloadAction<IAddHolidayRequest>) {
  try {
    const result: IApiResponse<any> = yield call(
      HolidayApiService.addHoliday,
      action.payload
    );
    if (result.succeeded) {
      yield put(HolidayActions.addHoliday.success());
    } else {
      const error = result as IErrorResponse;
      yield put(HolidayActions.addHoliday.failed(error));
    }
  } catch (error) {
    yield put(HolidayActions.addHoliday.failed(error));
  }
}

function* getAllHoliday(action: PayloadAction<IGetAllHolidayRequest>) {
  try {
    const result: IPaginateResponse<IHolidayModel[]> = yield call(
      HolidayApiService.getAllHoliday,
      action.payload
    );
    if (result.succeeded) {
      yield put(HolidayActions.getAllHoliday.success(result));
    } else {
      const error = result as IErrorResponse;
      yield put(HolidayActions.getAllHoliday.failed(error));
    }
  } catch (error) {
    yield put(HolidayActions.getAllHoliday.failed(error));
  }
}

function* getHolidayById(action: PayloadAction<string>) {
  try {
    const result: IApiResponse<IHolidayModel> = yield call(
      HolidayApiService.getHolidayById,
      action.payload
    );
    if (result.succeeded) {
      yield put(HolidayActions.getHolidayById.success(result.data!));
    } else {
      const error = result as IErrorResponse;
      yield put(HolidayActions.getHolidayById.failed(error));
    }
  } catch (error) {
    yield put(HolidayActions.getHolidayById.failed(error));
  }
}

function* updateHoliday(action: PayloadAction<Partial<IHolidayModel>>) {
  try {
    const result: IApiResponse<any> = yield call(
      HolidayApiService.updateHoliday,
      action.payload
    );
    if (result.succeeded) {
      yield put(HolidayActions.updateHoliday.success());
    } else {
      const error = result as IErrorResponse;
      yield put(HolidayActions.updateHoliday.failed(error));
    }
  } catch (error) {
    yield put(HolidayActions.updateHoliday.failed(error));
  }
}

function* deleteHoliday(action: PayloadAction<string>) {
  try {
    const result: IApiResponse<any> = yield call(
      HolidayApiService.deleteHoliday,
      action.payload
    );
    if (result.succeeded) {
      yield put(HolidayActions.deleteHoliday.success());
    } else {
      const error = result as IErrorResponse;
      yield put(HolidayActions.deleteHoliday.failed(error));
    }
  } catch (error) {
    yield put(HolidayActions.deleteHoliday.failed(error));
  }
}

export function* HolidayWatcher() {
  yield takeEvery(HolidayActions.addHoliday.requestName, addHoliday);
  yield takeEvery(HolidayActions.getAllHoliday.requestName, getAllHoliday);
  yield takeEvery(HolidayActions.getHolidayById.requestName, getHolidayById);
  yield takeEvery(HolidayActions.updateHoliday.requestName, updateHoliday);
  yield takeEvery(HolidayActions.deleteHoliday.requestName, deleteHoliday);
}
