import clsxm from 'clsxs/clsxm';
import { LoadingProvider } from 'contexts/LoadingContext';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import PinField from 'react-pin-field';
import Keyboard from 'react-simple-keyboard';
import { AuthenticationActions } from 'redux/actions';
import 'react-simple-keyboard/build/css/index.css';

interface IPinCodeProps {
	onComplete: (value: string) => void;
	okTitle?: string;
}

export const PinCode = (props: IPinCodeProps) => {
	const pin = useRef<HTMLInputElement[]>();

	const onKeyChoose = (value: string) => {
		if (!pin || !pin.current) {
			return;
		}
		if (
			value === '{clear}' ||
			value === 'Backspace' ||
			value === '{login}' ||
			value === 'Enter'
		) {
			if (value === '{clear}' || value === 'Backspace') {
				pin.current.forEach((input) => (input.value = ''));
			} else {
				onComplete();
			}
		} else {
			if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(value)) {
				return;
			} else {
				if (!_.isEmpty(pin.current[2].value)) {
					pin.current[3].value = value;
					return;
				}
				if (!_.isEmpty(pin.current[1].value)) {
					pin.current[2].value = value;
					return;
				}
				if (!_.isEmpty(pin.current[0].value)) {
					pin.current[1].value = value;
					return;
				}
				pin.current[0].value = value;
			}
		}
	};
	const onKeyPressListener = (e: KeyboardEvent) => {
		onKeyChoose(e.key || e.code);
	};
	const onComplete = () => {
		if (!pin || !pin.current) {
			return;
		}
		const valueString = pin.current.reduce((re, x) => {
			return re + x.value;
		}, '');
		if (valueString.length === 4) {
			props.onComplete(valueString);
		}
	};
	useEffect(() => {
		window.addEventListener('keyup', onKeyPressListener);
		return () => {
			window.removeEventListener('keyup', onKeyPressListener);
		};
	}, []);
	return (
		<>
			<div className="pin-field-container">
				<PinField
					className={clsxm('pin-field text-md font-bold')}
					style={{ display: 'flex' }}
					length={4}
					ref={(ref) => {
						if (ref) {
							pin.current = ref;
						}
					}}
					validate={/^[0-9]$/}
				/>
			</div>

			<Keyboard
				maxLength={4}
				onKeyPress={(button: string) => onKeyChoose(button)}
				theme={
					'hg-theme-default hg-theme-numeric hg-layout-numeric numeric-theme'
				}
				layoutName={'default'}
				mergeDisplay
				buttonTheme={[
					{
						class: 'main-gradient-background text-bold',
						buttons: '{login}',
					},
				]}
				display={{
					'{clear}': 'Clear',
					'{login}': props.okTitle || 'Login',
				}}
				layout={{
					default: ['7 8 9', '4 5 6', '1 2 3', '{clear} 0 {login}'],
				}}
			/>
		</>
	);
};
