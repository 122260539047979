import { Card, Input } from 'components/atoms';
import React from 'react';
import { t, translations } from 'utils';
import { UpdateBranchFormField, IConfigBaseProps } from './IConfigBaseProps';
import { WeekDays } from 'utils/Consts';
import clsxm from 'clsxs/clsxm';
import { WorkingTimeOfDay } from './WorkingTimeOfDay';
import { useFormContext } from 'react-hook-form';
import { IBranch } from 'models/IBranch';

interface IWorkingTimeConfigsProps extends IConfigBaseProps {
	currentBranch?: IBranch;
}
export const WorkingTimeConfigs = ({
	isLoading,
	currentBranch,
}: IWorkingTimeConfigsProps) => {
	const {
		register,
		formState: { errors },
	} = useFormContext<UpdateBranchFormField>();

	return (
		<Card
			title={t(translations.branchSetting.workingTime)}
			description={t(translations.branchSetting.subWorkingTime)}
			accordion={true}
			isDefaultAccordion={true}
		>
			<div className={clsxm('grid gap-y-4')}>
				<WorkingTimeOfDay
					day={WeekDays.WHOLEWEEK}
					isLoading={isLoading}
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.SUNDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.MONDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.TUESDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.WEDNESDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.THURSDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.FRIDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.SATURDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
				/>
			</div>
			<div className={clsxm('grid grid-cols-6 gap-x-5 mt-8')}>
				<Input
					label={t(translations.branchSetting.timeBlockInterval)}
					error={errors.timeBlockInterval?.message}
					renderAfter={<>{t(translations.branchSetting.minutes)}</>}
					type="number"
					{...register('timeBlockInterval')}
				/>
				<Input
					label={t(translations.branchSetting.lateArrivalAllowed)}
					error={errors.lateArrivalTimeAllowed?.message}
					renderAfter={<>{t(translations.branchSetting.minutes)}</>}
					type="number"
					{...register('lateArrivalTimeAllowed')}
				/>
				<Input
					label={t(translations.branchSetting.timeToAlertCheckOut)}
					error={errors.timeToAlertCheckOut?.message}
					renderAfter={<>{t(translations.branchSetting.minutes)}</>}
					type="number"
					{...register('timeToAlertCheckOut')}
				/>
			</div>
		</Card>
	);
};

interface IOnlineWorkingTimeConfigsProps extends IConfigBaseProps {
	currentBranch?: IBranch;
}
export const OnlineWorkingTimeConfigs = ({
	isLoading,
	currentBranch,
}: IOnlineWorkingTimeConfigsProps) => {
	const {
		register,
		formState: { errors },
	} = useFormContext<UpdateBranchFormField>();

	return (
		<Card
			title={t(translations.branchSetting.onlineWorkingTime)}
			description={t(translations.branchSetting.subOnlineWorkingTime)}
			accordion={true}
			isDefaultAccordion={true}
		>
			<div className={clsxm('grid gap-y-4')}>
				<WorkingTimeOfDay
					day={WeekDays.WHOLEWEEK}
					isLoading={isLoading}
					online
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.SUNDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
					online
				/>
				<WorkingTimeOfDay
					day={WeekDays.MONDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
					online
				/>
				<WorkingTimeOfDay
					day={WeekDays.TUESDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
					online
				/>
				<WorkingTimeOfDay
					day={WeekDays.WEDNESDAY}
					isLoading={isLoading}
					online
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.THURSDAY}
					isLoading={isLoading}
					online
					currentBranch={currentBranch}
				/>
				<WorkingTimeOfDay
					day={WeekDays.FRIDAY}
					isLoading={isLoading}
					currentBranch={currentBranch}
					online
				/>
				<WorkingTimeOfDay
					day={WeekDays.SATURDAY}
					isLoading={isLoading}
					online
					currentBranch={currentBranch}
				/>
			</div>
			<div className={clsxm('grid grid-cols-6 gap-x-8 mt-8')}>
				<Input
					label={t(translations.branchSetting.allowTimeBeforeBookingOnline)}
					error={errors.allowTimeBeforeBookingOnline?.message}
					renderAfter={<>{t(translations.branchSetting.hour)}</>}
					type="number"
					{...register('allowTimeBeforeBookingOnline', { disabled: isLoading })}
				/>
			</div>
		</Card>
	);
};
