import { mdiStar } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import {
	Button,
	ButtonGroup,
	Container,
	IColumnProps,
	Icon,
	Input,
	Modal,
	TextArea,
} from 'components/atoms';
import Table, { ITableRef } from 'components/atoms/Table';
import { AlertHelper, TimeHelper } from 'helpers';
import _ from 'lodash';
import { IApiResponse, IReview } from 'models';
import { ITextMessage } from 'models/ITextMessage';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import TextMessageApiService from 'services/TextMessageApiService';
import { t, translations } from 'utils';
export const ReviewManagement = () => {
	const [selectedReview, setSelectedReview] = useState<IReview>();
	const buttons: FilterButtonType[] = [
		{ label: t(translations.all), value: '' },
		{ label: '1-3', value: '1,2,3' },
		{ label: '4-5', value: '4,5' },
	];
	const [activeFilter, setActiveFilter] = useState<FilterButtonType>(
		buttons[0]
	);
	const tableRef = useRef<ITableRef>(null);

	useEffect(() => {
		tableRef.current?.refresh();
	}, [activeFilter]);

	const customerNameCol: IColumnProps<IReview> = {
		key: 'id',
		header: t(translations.listBooking.customerName),
		renderCell: (data) => (
			<p className={clsxm('text-sm px-4 m-0')}>{`${
				data.customer.firstName || ''
			} ${data.customer.lastName || ''}`}</p>
		),
		// renderHeader: (
		//   <div className={clsxm("label px-4")}>
		//     <p className={clsxm("text-md")}>
		//       {t(translations.listBooking.customerName)}
		//     </p>
		//   </div>
		// ),
	};

	const reviewDateCol: IColumnProps<IReview> = {
		key: 'id',
		header: t(translations.reviewManagement.reviewDate),
		sortable: true,
		renderCell: (data) =>
			// <p className={clsxm("text-sm m-0")}>
			TimeHelper.toTimeZone(data.createdDate).format('MMM Do, YYYY'),
		// </p>
		// renderHeader: (
		//   <div className={clsxm("label px-4")}>
		//     <p className={clsxm("text-md")}>
		//       {t(translations.reviewManagement.reviewDate)}
		//     </p>
		//   </div>
		// ),
	};

	const techniciansCol: IColumnProps<IReview> = {
		key: 'id',
		header: t(translations.reviewManagement.technicians),
		renderCell: (data) => {
			const stylists = _.uniqBy(
				data!.ratingDetails,
				(element) => element.stylist?.id
			).map(
				(technician) =>
					`${technician.stylist?.firstName || ''} ${
						technician.stylist?.lastName || ''
					}`
			);
			return (
				<>
					{stylists.map((stylist) => (
						<p className={clsxm('text-sm m-0')}>{`${stylist}`}</p>
					))}
				</>
			);
		},
		// renderHeader: (
		//   <div className={clsxm("label px-4")}>
		//     <p className={clsxm("text-md")}>
		//       {t(translations.reviewManagement.technicians)}
		//     </p>
		//   </div>
		// ),
	};

	const rateCol: IColumnProps<IReview> = {
		key: 'id',
		header: t(translations.reviewManagement.rate),
		renderCell: (data) => {
			return (
				<div className={clsxm('flex flex-row')}>{renderStar(data.rate)}</div>
			);
		},
		// renderHeader: (
		//   <div className={clsxm("label px-4")}>
		//     <p className={clsxm("text-md")}>
		//       {t(translations.reviewManagement.rate)}
		//     </p>
		//   </div>
		// ),
		sortable: true,
	};

	const commentCol: IColumnProps<IReview> = {
		key: 'comment',
		header: t(translations.reviewManagement.comment),
		renderCell: (data) => (
			<p className={clsxm('text-sm m-0 whitespace-normal')}>{data.comment}</p>
		),
		// renderHeader: (
		//   <div className={clsxm("label px-4")}>
		//     <p className={clsxm("text-md")}>
		//       {t(translations.reviewManagement.comment)}
		//     </p>
		//   </div>
		// ),
	};

	const sendMessageCol: IColumnProps<IReview> = {
		key: 'comment',
		header: t(translations.action),
		renderCell: (data) =>
			data.rate <= 3 ? (
				<Button
					small
					title={t(translations.reviewManagement.sendMessage)}
					onClick={() => setSelectedReview(data)}
				/>
			) : undefined,
		// renderHeader: (
		//   <div className={clsxm("label px-4")}>
		//     <p className={clsxm("text-md")}>
		//       {t(translations.reviewManagement.comment)}
		//     </p>
		//   </div>
		// ),
	};

	return (
		<Container className="overflow-hidden">
			<Table
				ref={tableRef}
				columns={[
					customerNameCol,
					reviewDateCol,
					techniciansCol,
					rateCol,
					commentCol,
					sendMessageCol,
				]}
				noDefaultPadding
				queryUrl="/Rating/get-all-ratings"
				showSearch
				renderHeader={
					<ButtonGroup<FilterButtonType>
						buttons={buttons}
						titleField={'label'}
						valueField={'value'}
						icons={[
							<Icon path={mdiStar} className={clsxm('text-[#fadb14]')} />,
							<Icon path={mdiStar} className={clsxm('text-[#fadb14]')} />,
							<Icon path={mdiStar} className={clsxm('text-[#fadb14]')} />,
						]}
						value={activeFilter}
						onChange={setActiveFilter}
					/>
				}
				additionalParams={{
					orderBy: 'createdDate desc',
					status: 1,
					rates: `${_.isEmpty(activeFilter.value) ? '' : activeFilter.value}`,
				}}
			/>
			<SendMessageModal
				selectedReview={selectedReview}
				onMessageSent={() => {
					setSelectedReview(undefined);
					AlertHelper.showSuccess(t(translations.reviewManagement.sendSuccess));
				}}
				onCancel={() => setSelectedReview(undefined)}
			/>
		</Container>
	);
};

interface ISendMessageModalProps {
	selectedReview?: IReview;
	onMessageSent?: () => void;
	onCancel?: () => void;
}
const SendMessageModal = ({
	selectedReview,
	onMessageSent,
	onCancel,
}: ISendMessageModalProps) => {
	const [message, setMessage] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>();
	const [title, setTitle] = useState<string>();

	const onSendMessage = async () => {
		setIsLoading(true);
		try {
			const textMessageId = await createTextMessage({
				title: title,
				content: message,
				sendToAllCustomers: false,
				customerIds: [selectedReview!.customerId!],
			});
			if (textMessageId) {
				await sendMessage(textMessageId);
			}
		} catch (error: any) {
			AlertHelper.showError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const createTextMessage = (request: Partial<ITextMessage>) =>
		new Promise<string>(
			async (resolve, reject: (reason: IApiResponse<any>) => void) => {
				const result = (await TextMessageApiService.addTextMessage(
					request
				)) as unknown as IApiResponse<ITextMessage>;
				if (result.succeeded && !_.isEmpty(result.data)) {
					resolve(result.data!.id!);
				} else {
					reject(result);
				}
			}
		);

	const sendMessage = async (messageId: string) => {
		try {
			const result = (await TextMessageApiService.sendTextMessage({
				marketingTextMessageIds: [messageId],
			})) as IApiResponse<string>;
			if (result.succeeded) {
				onMessageSent && onMessageSent();
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		}
	};
	return (
		<Modal
			title={t(translations.reviewManagement.sendMessage)}
			isOpen={!_.isEmpty(selectedReview)}
			content={
				!_.isEmpty(selectedReview) ? (
					<div className={clsxm('w-full flex flex-col gap-y-4')}>
						<div className={clsxm('grid grid-cols-1 gap-4')}>
							<div className={clsxm('flex flex-row justify-between')}>
								<label className={clsxm('label')}>
									<p className={clsxm('label-text font-bold')}>
										{t(translations.listBooking.customerName)}
									</p>
								</label>
								<label className={clsxm('label')}>
									<p className={clsxm('label-text')}>
										{`${selectedReview!.customer.firstName || ''} ${
											selectedReview!.customer.lastName || ''
										}`}
									</p>
								</label>
							</div>
							<div className={clsxm('flex flex-row justify-between')}>
								<label className={clsxm('label')}>
									<p className={clsxm('label-text font-bold')}>
										{t(translations.reviewManagement.rate)}
									</p>
								</label>
								<label className={clsxm('label')}>
									{renderStar(selectedReview?.rate || 0)}
								</label>
							</div>

							<div className={clsxm('flex flex-row justify-between')}>
								<label className={clsxm('label')}>
									<p className={clsxm('label-text font-bold')}>
										{t(translations.reviewManagement.reviewDate)}
									</p>
								</label>
								<label className={clsxm('label')}>
									<p className={clsxm('label-text')}>
										{TimeHelper.toTimeZone(selectedReview?.createdDate).format(
											'MMM Do, YYYY'
										)}
									</p>
								</label>
							</div>
							<div className={clsxm('flex flex-row justify-between')}>
								<label className={clsxm('label')}>
									<p className={clsxm('label-text font-bold')}>
										{t(translations.reviewManagement.technicians)}
									</p>
								</label>
								<label className={clsxm('label')}>
									<p className={clsxm('label-text')}>
										{_.uniqBy(
											selectedReview!.ratingDetails,
											(element) => element.stylist?.id
										)
											.map(
												(technician) =>
													`${technician.stylist?.firstName || ''} ${
														technician.stylist?.lastName || ''
													}`
											)
											.map((stylist) => (
												<p className={clsxm('text-sm m-0')}>{`${stylist}`}</p>
											))}
									</p>
								</label>
							</div>
							<div className={clsxm('flex flex-row justify-between')}>
								<label className={clsxm('label')}>
									<p className={clsxm('label-text font-bold')}>
										{t(translations.reviewManagement.comment)}
									</p>
								</label>
								<label className={clsxm('label')}>
									<p className={clsxm('label-text')}>
										{selectedReview?.comment}
									</p>
								</label>
							</div>
						</div>
						<Input
							label={t(translations.reviewManagement.title)}
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							disabled={isLoading}
						/>
						<TextArea
							label={t(translations.reviewManagement.message)}
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							disabled={isLoading}
						/>
						<Button
							small
							fullWidth
							title={t(translations.reviewManagement.sendMessage)}
							onClick={onSendMessage}
							disabled={isLoading || !title || !message}
						/>
					</div>
				) : undefined
			}
			onCancel={onCancel}
			contentContainerClassName={'p-4'}
		/>
	);
};

const renderStar = (amount: number) => {
	let node: ReactNode[] = [];
	for (let index = 0; index < amount; index++) {
		node.push(
			<Icon path={mdiStar} style={{ color: '#fadb14', fontSize: 16 }} />
		);
	}
	return <>{node}</>;
};

type FilterButtonType = {
	label: string;
	value: string;
};
