import { DetailPageLayout } from 'components/molecules';
import React, { useEffect, useState } from 'react';
import FormBasicInfo from './components/FormBasicInfo';
import { I18n, _t, translations } from 'utils';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IApiResponse, ICustomer, IErrorResponse } from 'models';
import _ from 'lodash';
import moment from 'moment';
import { convertToRawPhone } from 'helpers/StringHelper';
import { convertDateRequest } from 'helpers/bookingHelper';
import CustomerApiService from 'services/CustomerApiService';
import { AlertHelper } from 'helpers';

export type UpdateCustomerField = {
	firstName: string;
	lastName?: string;
	email?: string;
	phone: string;
	dateOfBirth?: Date | string;
	gender?: number;
};

const CustomerDetailPage = () => {
	const location = useLocation();
	const { id } = useParams() as { id: string };
	const history = useHistory();

	const [customer, setCustomer] = useState<ICustomer>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/customer');
	};

	const form = useForm<UpdateCustomerField>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	const fetchCustomerById = async () => {
		setIsLoading(true);
		try {
			const res = (await CustomerApiService.getCustomerById(
				id
			)) as IApiResponse<ICustomer>;
			if (res.succeeded) {
				setCustomer(res.data);
				form.reset({
					firstName: res.data?.firstName,
					lastName: res.data?.lastName,
					email: res.data?.email,
					phone: res.data?.phone,
					dateOfBirth: res.data?.dateOfBirth
						? moment(res.data?.dateOfBirth).format('MM/DD/YYYY')
						: undefined,
					gender: res.data?.gender,
				});
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	};

	useEffect(() => {
		if (!isAdd()) {
			fetchCustomerById();
		}
	}, []);

	const submitForm = async (formData: UpdateCustomerField) => {
		setIsLoading(true);
		const data: Partial<ICustomer> = {
			id: isAdd() ? undefined : customer?.id!,
			code: `${moment().unix()}`,
			firstName: formData.firstName,
			lastName: formData.lastName,
			additionalName: '',
			color: '',
			gender: formData.gender,
			phone: convertToRawPhone(formData.phone),
			phoneNumberConfirmed: true,
			email: formData.email,
			emailConfirmed: false,
			status: 0,
			dateOfBirth: !formData.dateOfBirth
				? undefined
				: convertDateRequest(moment(formData.dateOfBirth)),
		};
		const res = isAdd()
			? await CustomerApiService.addCustomer(data)
			: await CustomerApiService.editCustomer(data);
		if (res.succeeded) {
			setIsLoading(false);
			AlertHelper.showSuccess(
				isAdd()
					? I18n.t(_t(translations.customerDetail.addNewSuccess))
					: I18n.t(_t(translations.customerDetail.editSuccess))
			);
			goBack();
		} else {
			setIsLoading(false);
			const error = res as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	return (
		<DetailPageLayout<UpdateCustomerField>
			title={I18n.t(
				_t(
					isAdd()
						? translations.customerDetail.addNew
						: translations.customerDetail.editCustomer
				)
			)}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			loading={isLoading}
		>
			{(isAdd() || !_.isEmpty(customer)) && (
				<>
					<FormBasicInfo customer={customer} />
					{/* <FormAddress /> */}
				</>
			)}
		</DetailPageLayout>
	);
};

export default CustomerDetailPage;
