import clsxm from 'clsxs/clsxm';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SkeletonLoading = () => {
	return (
		<>
			<Skeleton duration={0.4} className={clsxm('h-10 w-24')} />
			<Skeleton duration={0.4} className={clsxm('h-5')} count={11} />
		</>
	);
};

export const SkeletonChartLoading = () => {
	return (
		<div className={clsxm('flex flex-row justify-between items-end')}>
			<Skeleton duration={0.4} className={clsxm('h-32 w-10')} />
			<Skeleton duration={0.4} className={clsxm('h-40 w-10')} />
			<Skeleton duration={0.4} className={clsxm('h-32 w-10')} />
			<Skeleton duration={0.4} className={clsxm('h-36 w-10')} />
			<Skeleton duration={0.4} className={clsxm('h-28 w-10')} />
			<Skeleton duration={0.4} className={clsxm('h-20 w-10')} />
			<Skeleton duration={0.4} className={clsxm('h-24 w-10')} />
			<Skeleton duration={0.4} className={clsxm('h-14 w-10')} />
			<Skeleton duration={0.4} className={clsxm('h-14 w-10')} />
			<Skeleton duration={0.4} className={clsxm('h-10 w-10')} />
		</div>
	);
};
