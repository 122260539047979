import { IBooking } from 'models';
import {
	IStylistAndBlockTimeRequest,
	IGetBookingsRequest,
	IUpdateBookingStatusRequest,
} from 'models/RequestModels';
import { IStylistAndTimeBlockResponse } from 'models/ResponseModels';
import { BaseApiService } from './BaseApiService';

const url = '/Booking';

class BookingApiService extends BaseApiService {
	public getBookingByBranch = (request: IGetBookingsRequest) =>
		this.get<IBooking[]>(`${url}/get-all-bookings`, { params: request });
	public getAvailableStylistAndTimeBlocks = (
		bookingDetail: IStylistAndBlockTimeRequest
	) =>
		this.get<IStylistAndTimeBlockResponse>(
			`${url}/get-available-stylist-and-time-block`,
			{ params: bookingDetail }
		);
	public getBookingById = (bookingId: string) =>
		this.get<IBooking>(`${url}/get-booking/${bookingId}`);
	public createBooking = (booking: Partial<IBooking>) =>
		this.post<Partial<IBooking>, IBooking>(`${url}/create-booking/`, booking);
	public editBooking = (booking: Partial<IBooking>) =>
		this.put<Partial<IBooking>, IBooking>(`${url}/update-booking/`, booking);
	public updateBookingStatus = (booking: IUpdateBookingStatusRequest) =>
		this.put<IUpdateBookingStatusRequest>(
			`${url}/update-booking-status/`,
			booking
		);
	public getNextBookingByCustomer = (customerId: string) =>
		this.get<IBooking>(
			`${url}/get-next-booking-by-customer?CustomerId=${customerId}`
		);
	public updatePendingBooking = (booking: Partial<IBooking>) =>
		this.put<Partial<IBooking>, IBooking>(
			`${url}/update-pending-booking/`,
			booking
		);
}
export default new BookingApiService(true);
