import { mdiPower, mdiWindowMinimize } from '@mdi/js';
import { Icon } from 'components/atoms/Icon';
import { Header, Reload } from 'components/molecules/Header';
import { isPos } from 'helpers';
import { electron } from 'ipc';
import { useDispatch } from 'react-redux';
import { AuthenticationActions } from 'redux/actions';

export const AdminHeader = (props: { onSelectChangeBranch: () => void }) => {
	const dispatch = useDispatch();
	return (
		<>
			<Header
				extraRight={
					isPos() && (
						<div className="flex h-full justify-center align-middle p-1 px-2">
							<div className="flex gap-2">
								<div
									onClick={() => {
										electron.ipcRenderer.minimize();
									}}
									className=" flex cursor-pointer active:animate-pop justify-center items-center border-warning border-2 bg-white aspect-square rounded-lg text-warning"
								>
									<Icon path={mdiWindowMinimize} />
								</div>
								<div
									onClick={() => {
										dispatch(AuthenticationActions.logOut.request());
										setTimeout(() => {
											electron.ipcRenderer.quit();
										}, 200);
									}}
									className=" flex cursor-pointer active:animate-pop justify-center items-center  border-error border-2 bg-white aspect-square rounded-lg text-error"
								>
									<Icon path={mdiPower} />
								</div>
								<Reload />
							</div>
						</div>
					)
				}
			/>
		</>
	);
};
