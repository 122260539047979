/* eslint-disable react-hooks/exhaustive-deps */
import { mdiStar } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Card, Icon } from 'components/atoms';
import _ from 'lodash';
import { IReviewSummary } from 'models';
import { useDashboardContext } from 'pages/Dashboard/context';
import React, { useEffect, useState } from 'react';
import { AdminDashBoardApiService } from 'services';
import { Pie } from 'react-chartjs-2';

export const Reviews = () => {
	const { timeRange, branch } = useDashboardContext();
	const [data, setData] = useState<IReviewSummary>();
	const fetchData = async () => {
		const response = await AdminDashBoardApiService.getReviewSummary({
			fromDate: timeRange.fromDate.format('YYYY-MM-DD'),
			toDate: timeRange.toDate.format('YYYY-MM-DD'),
			branchId: branch,
		});
		if (response.succeeded && response.data) {
			setData(response.data);
		}
	};
	useEffect(() => {
		fetchData();
	}, [timeRange, branch]);
	const avgRate =
		data &&
		_.sumBy(data.reportData, (x) => x.rate * x.countReviews) /
			_.sumBy(data.reportData, (x) => x.countReviews);
	const totalReviews = data && _.sumBy(data.reportData, (x) => x.countReviews);
	return (
		<Card
			headerRight={
				<span
					className={clsxm(
						'flex justify-end items-center gap-1 text-right font-bold text-lg text-error',
						(avgRate || 0) >= 3 && 'text-warning',
						(avgRate || 0) >= 4 && 'text-success'
					)}
				>
					<Icon size={0.9} path={mdiStar} />
					{(avgRate || 0).toFixed(1)} /{' '}
					<span className={'text-black font-normal text-sm'}>
						{`${totalReviews}`}
					</span>
				</span>
			}
			title="Reviews"
			className="col-span-1 "
		>
			{data?.reportData && (
				<div className="w-full flex justify-center">
					<div className="h-50 w-50">
						<Pie
							options={{
								plugins: {
									legend: {
										position: 'bottom',
										labels: {
											usePointStyle: true,
											boxWidth: 10,
										},
									},
									datalabels: { display: false },
								},
							}}
							data={{
								labels: _.orderBy(data.reportData, ['rate'], ['desc']).map(
									(x) => `${x.rate}`
								),
								datasets: [
									{
										data: _.orderBy(data.reportData, ['rate'], ['desc']).map(
											(x) => x.countReviews
										),
										backgroundColor: [
											'#f66d44',
											'#fcae65',
											'#e6f69d',
											// '#aadea7',
											'#64c2a6',
											'#2d87bb',
										],
										// backgroundColor: [
										// 	'#52D726',
										// 	'#FFEC00',
										// 	'#7CDDDD',
										// 	'#FF7300',
										// 	'#FF0000',
										// ],
									},
								],
							}}
						/>
					</div>
				</div>
			)}
		</Card>
	);
};
