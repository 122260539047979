import { BookingStatus, Colors } from 'utils/Consts';

export const staffColors = [
	'hsl(211, 100%, 80%)',
	'hsl(68, 100%, 80%)',
	'hsl(251, 100%, 80%)',
	'hsl(56, 100%, 80%)',
	'hsl(30, 100%, 80%)',
	'hsl(160, 100%, 80%)',
	'hsl(358, 100%, 80%)',
	'hsl(256, 100%, 80%)',
	'hsl(295, 100%, 80%)',
	'hsl(101, 100%, 80%)',
	'hsl(18, 100%, 80%)',
	'hsl(138, 100%, 80%)',
	'hsl(134, 100%, 80%)',
	'hsl(55, 100%, 80%)',
	'hsl(266, 100%, 80%)',
	'hsl(359, 100%, 80%)',
	'hsl(109, 100%, 80%)',
	'hsl(57, 100%, 80%)',
	'hsl(184, 100%, 80%)',
	'hsl(42, 100%, 80%)',
	'hsl(336, 100%, 80%)',
	'hsl(295, 100%, 80%)',
	'hsl(17, 100%, 80%)',
	'hsl(286, 100%, 80%)',
	'hsl(28, 100%, 80%)',
	'hsl(202, 100%, 80%)',
	'hsl(227, 100%, 80%)',
	'hsl(121, 100%, 80%)',
	'hsl(324, 100%, 80%)',
];
export const getNameColorFromStatus = (status: number) => {
	switch (status) {
		case BookingStatus.DELETED:
			return 'DELETED';
		case BookingStatus.PENDING:
			return 'PENDING';
		case BookingStatus.CHECKED_IN:
			return 'CHECKED_IN';
		case BookingStatus.CANCELED:
			return 'CANCELED';
		case BookingStatus.CONFIRMED:
			return 'CONFIRMED';
		case BookingStatus.PROCESSING:
			return 'PROCESSING';
		case BookingStatus.FINISHED:
			return 'FINISHED';
		case BookingStatus.DONE:
			return 'DONE';
		case BookingStatus.ERROR:
			return 'ERROR';
		case BookingStatus.EXPIRED:
			return 'EXPIRED';
		case BookingStatus.SUSPENDED:
			return 'SUSPENDED';
		case BookingStatus.VOID:
			return 'EXPIRED';
	}
};

export function getStatusColor(status: number) {
	switch (status) {
		case BookingStatus.DELETED:
			return Colors.DELETED;
		case BookingStatus.PENDING:
			return Colors.PENDING;
		case BookingStatus.CHECKED_IN:
			return Colors.CHECKED_IN;
		case BookingStatus.CANCELED:
			return Colors.CANCELED;
		case BookingStatus.CONFIRMED:
			return Colors.CONFIRMED;
		case BookingStatus.PROCESSING:
			return Colors.PROCESSING;
		case BookingStatus.FINISHED:
		case BookingStatus.DONE:
			return Colors.FINISHED;
		case BookingStatus.ERROR:
			return Colors.ERROR;
		case BookingStatus.EXPIRED:
			return Colors.EXPIRED;
		case BookingStatus.SUSPENDED:
			return Colors.SUSPENDED;
	}
}
