import { ReduxHelper } from 'helpers';
import { IBooking, ICustomer, IEmployee } from 'models';
import { Moment } from 'moment';

export const prefix = 'CUSTOMER_BOOKING';

const updateBooking = ReduxHelper.generateLocalAction<Partial<IBooking>>(
	prefix + '/UPDATE_BOOKING'
);
const updateCustomer = ReduxHelper.generateLocalAction<Partial<ICustomer>>(
	prefix + '/UPDATE_CUSTOMER'
);
const updateNote = ReduxHelper.generateLocalAction<string>(
	prefix + '/UPDATE_NOTE'
);
const getAllStylists = ReduxHelper.generateLocalAction<Partial<IEmployee>[]>(
	prefix + '/GET_ALL_STYLISTS'
);

export {
	updateBooking,
	updateCustomer,
	getAllStylists,
	updateNote,
};
