/* eslint-disable react-hooks/exhaustive-deps */
import { Card, ConfirmModal } from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { DatePicker } from 'components/molecules/DatePicker';
import { AlertHelper, CurrencyHelper } from 'helpers';
import _ from 'lodash';
import {
	EPeriodType,
	IAdjustCloseOutPeriod,
	ICloseOutPeriod,
	IFinancialReport,
	IPayrollSummary,
} from 'models';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ReportApiService } from 'services';
import { t, translations } from 'utils';
import qs from 'qs';
import CloseOutApiService from 'services/CloseOutApiService';
import { useAppSelector } from 'helpers/hookHelpers';
import { Status } from 'models/Base.model';
import { IPaginateResponse } from 'models/ResponseModels';

export const CloseOutDetail = () => {
	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const { params } = useRouteMatch<{ type: string }>();
	const { location, goBack } = useHistory();
	const date = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}).date as string;
	const isAdd = useMemo(() => params.type === 'new', [params]);
	const [financial, setFinancial] = useFinancial();
	const [payrolls, setPayrolls] = usePayrolls();
	const [isLoading, setIsLoading] = useState<boolean>();
	const [lastCloseOut, setLastCloseOut] = useState<ICloseOutPeriod>();
	const [selectedDate, setSelectedDate] = useState<moment.Moment>(
		isAdd ? moment().startOf('date') : moment(date).startOf('date')
	);
	const [currentCloseDate, setCurrentCloseDate] = useState<ICloseOutPeriod>();
	const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>();
	const canEdit = useMemo(() => {
		if (isAdd) {
			return true;
		}
		if (moment(date).startOf('date').isSame(moment(lastCloseOut?.date))) {
			return true;
		}
		return false;
	}, [isAdd, lastCloseOut]);
	const lastCloseOutDate = useMemo<string | undefined>(():
		| string
		| undefined => {
		if (isAdd) {
			if (lastCloseOut) {
				return lastCloseOut.date;
			} else {
				return moment(currentBranch!.createdDate).format('YYYY') === '0001'
					? moment().startOf('year').format('YYYY-MM-DD')
					: (currentBranch!.createdDate as string);
			}
		} else {
			if (currentCloseDate) {
				return currentCloseDate.lastCloseOutDate ||
					moment(currentBranch!.createdDate).format('YYYY') === '0001'
					? moment().startOf('year').format('YYYY-MM-DD')
					: (currentBranch!.createdDate as string);
			}
		}
	}, [lastCloseOut, currentCloseDate]);

	useEffect(() => {
		getLastCloseOut();
		if (date) {
			getCloseOut(date);
		}
	}, []);

	useEffect(() => {
		if (lastCloseOutDate) {
			getReports(lastCloseOutDate, selectedDate.format('YYYY-MM-DD'));
		}
	}, [selectedDate, lastCloseOutDate]);

	const onCloseOut = () => {
		if (isAdd) {
			createNewCloseOut({
				date: selectedDate.format('YYYY-MM-DD'),
				status: Status.ACTIVE,
				branchId: currentBranch?.id,
			});
		} else {
			adjustCloseOutDate({
				adjustDate: selectedDate.format('YYYY-MM-DD'),
				date: currentCloseDate!.date,
				shopId: currentCloseDate!.shopId,
				branchId: currentCloseDate!.branchId,
				status: currentCloseDate!.status,
			});
		}
	};

	const getReports = async (fromDate: string, toDate: string) => {
		setIsLoading(true);
		try {
			const result = await Promise.all([
				ReportApiService.getOwnerFinancialSummaryReport({
					periodType: EPeriodType.DATE_RANGE,
					fromDate: fromDate,
					toDate: toDate,
				}),
				ReportApiService.getPayrollSummary({
					fromDate: fromDate,
					toDate: toDate,
					pageNumber: 1,
					pageSize: 1000,
				}),
			]);
			if (result.some((e) => !e.succeeded || _.isEmpty(e.data))) {
				AlertHelper.showError(
					result.find((e) => !e.succeeded || _.isEmpty(e.data))!
				);
			} else {
				setFinancial(result[0].data!);
				setPayrolls(result[1].data!);
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsLoading(false);
		}
	};

	const createNewCloseOut = async (request: Partial<ICloseOutPeriod>) => {
		setIsLoading(true);
		try {
			const result = await CloseOutApiService.addCloseOutPeriod(request);
			if (result.succeeded && !_.isEmpty(result.data)) {
				AlertHelper.showSuccess(t(translations.closeOut.closeOutSuccess));
				goBack();
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsLoading(false);
		}
	};

	const adjustCloseOutDate = async (request: IAdjustCloseOutPeriod) => {
		setIsLoading(true);
		try {
			const result = await CloseOutApiService.adjustCloseOutDate(request);
			if (result.succeeded && !_.isEmpty(result.data)) {
				AlertHelper.showSuccess(t(translations.closeOut.closeOutSuccess));
				goBack();
			} else {
				console.log('hjerererere', result);
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsLoading(false);
		}
	};

	const getCloseOut = async (date: string) => {
		try {
			const result = (await CloseOutApiService.getAllCloseOutPeriod({
				pageNumber: 1,
				pageSize: 1,
				toDate: date,
				fromDate: date,
			})) as IPaginateResponse<ICloseOutPeriod[]>;
			if (result.succeeded) {
				if (!_.isEmpty(result.data)) {
					setCurrentCloseDate(result.data![0]);
				}
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		}
	};

	const getLastCloseOut = async () => {
		try {
			const result = await CloseOutApiService.getLastCloseOut();
			if (result.succeeded) {
				if (!_.isEmpty(result.data)) {
					setLastCloseOut(result.data);
				}
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		}
	};

	return (
		<DetailPageLayout
			title={
				isAdd
					? t(translations.closeOut.newCloseOut)
					: t(translations.closeOut.closeOutDetai)
			}
			loading={isLoading}
			saveButtonTitle={isAdd ? t(translations.closeOut.title) : undefined}
			onSave={() => setIsShowConfirmModal(true)}
		>
			<label className="label font-bold">
				<span className="label-text text-lg">
					{t(translations.closeOut.date)}
				</span>
			</label>
			<div className="grid grid-cols-6">
				<DatePicker
					selectedDate={selectedDate}
					onSelectDate={setSelectedDate}
					disabled={isLoading || !canEdit}
					disabledAfter
				/>
			</div>
			<div className="grid grid-cols-2 gap-4 ">
				<Card title={t(translations.closeOut.finance)}>
					<FinancialInformation financial={financial} />
				</Card>
				<Card title={t(translations.closeOut.staffPayroll)}>
					<PayrollInformation payrolls={payrolls} />
				</Card>
			</div>
			<ConfirmModal
				loading={isLoading}
				onClose={() => setIsShowConfirmModal(false)}
				title={t(translations.notice)}
				subTitle={t(translations.closeOut.closeOutNotice)}
				onNegativeClick={() => setIsShowConfirmModal(false)}
				onPositiveClick={onCloseOut}
				visible={isShowConfirmModal}
				type={'error'}
			/>
		</DetailPageLayout>
	);
};

const FinancialInformation = (props: { financial: TFinancial[] }) => (
	<table className="table table-zebra m-0 w-full">
		<thead className="block">
			<tr className="flex flex-row">
				<th className={'relative flex-[3] '}>
					{t(translations.closeOut.type)}
				</th>

				<th className={'relative flex-[1] text-right'}>
					{`${t(translations.amount)}(${t(translations.currency)})`}
				</th>
			</tr>
		</thead>
		<tbody className="block h-[25rem] overflow-auto">
			{props.financial.map((row) => {
				return (
					<tr
						className={
							'cursor-pointer active:animate-pop duration-200 ease-in flex flex-row h-20'
						}
					>
						<td className="flex-[3]">{row.name}</td>
						<td className="text-right flex-[1]">
							{CurrencyHelper.formatPrice(row.value, false)}
						</td>
					</tr>
				);
			})}
		</tbody>
	</table>
);

const PayrollInformation = (props: { payrolls: TPayroll[] }) => (
	<table className="table table-zebra m-0 w-full">
		<thead className="block">
			<tr className="flex flex-row">
				<th className={'relative flex-[3]'}>{t(translations.employee)}</th>

				<th className={'relative flex-[1] text-right'}>
					{`${t(translations.payrollDetailReport.payroll)}(${t(
						translations.currency
					)})`}
				</th>
			</tr>
		</thead>
		<tbody className="block h-[25rem] overflow-auto">
			{props.payrolls.map((row) => {
				return (
					<tr
						className={
							'cursor-pointer active:animate-pop duration-200 ease-in flex flex-row h-20'
						}
					>
						<td className="relative flex-[3]">{row.name}</td>
						<td className="text-right flex-[1]">
							{CurrencyHelper.formatPrice(row.value, false)}
						</td>
					</tr>
				);
			})}
		</tbody>
	</table>
);

const useFinancial = (): [
	TFinancial[],
	(financialReport: IFinancialReport) => void
] => {
	const [financial, setFinancial] = useState<TFinancial[]>([]);
	const _setFinancial = (financialReport: IFinancialReport) => {
		setFinancial([
			{
				name: t(translations.ownerFinancialSummaryReport.gross),
				value: financialReport.revenue,
			},
			{
				name: t(translations.ownerFinancialSummaryReport.payrollExpenses),
				value: financialReport.expensesBreakdown[0].amount,
			},
			{
				name: t(translations.ownerFinancialSummaryReport.otherExpenses),
				value: financialReport.expensesBreakdown[1].amount,
			},
			{
				name: t(translations.ownerFinancialSummaryReport.totalSaleTax),
				value: financialReport.tax,
			},
			{
				name: t(translations.ownerFinancialSummaryReport.profit),
				value: financialReport.profit,
			},
		]);
	};
	return [financial, _setFinancial];
};

const usePayrolls = (): [TPayroll[], (payroll: IPayrollSummary) => void] => {
	const [payrolls, setPayrolls] = useState<TPayroll[]>([]);
	const _setPayroll = (payroll: IPayrollSummary) => {
		setPayrolls(
			payroll.details.data.map<TPayroll>((e) => ({
				name: e.employeeName,
				value: e.salaryPay,
			}))
		);
	};
	return [payrolls, _setPayroll];
};

type TFinancial = { name: string; value: number };
type TPayroll = { name: string; value: number; serviceServed?: number };
