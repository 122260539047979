export interface IBaseModel {
	id: string;
	createdBy?: string | unknown;
	createdDate?: Date | string;
	lastModifiedBy?: string | unknown;
	lastModifiedDate?: Date | unknown;
	status?: Status;
}

export enum Status {
	DELETED = -1,
	UNACTIVE,
	ACTIVE
}