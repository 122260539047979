import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducers, { RootState } from './rootReducer';
import rootSagas from './rootSagas';
import _ from 'lodash';
import {
	AppConfigActions,
	AuthenticationActions,
	BranchActions,
	ShopActions,
	UserActions,
} from 'redux/actions';
import { I18n } from 'utils';
import { electron } from 'ipc';
const config: PersistConfig<RootState> = {
	key: 'root',
	timeout: 0,
	storage: storage,
	blacklist: [
		'ReduxActionReducer',
		'ErrorReducer',
		'BookingReducer',
		'HolidayReducer',
		'AdminDashboardReducer',
		'BookingReducer',
		'CheckoutReducer',
		'CustomerBookingReducer',
		'CloseOnlineBookingReducer',
	],
	debug: true,
	transforms: [
		encryptTransform({
			secretKey: 'SCS_SmartSalon_2021',
			onError: function (error) {
				// Handle the error.
				console.log('ESA', error);
			},
		}),
	],
};
const sagaMiddleware = createSagaMiddleware();
const middleware = [];
middleware.push(sagaMiddleware);
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	middleware.push(createLogger());
} else {
	// production code
}
const reducers = persistReducer(config, rootReducers);
const enhancers = [applyMiddleware(...middleware as any)];
const persistConfig = { enhancers };
const store = createStore(reducers, undefined, compose(...enhancers)) as any;
const persistor = persistStore(store, persistConfig as any, () => {
	const stateData = store.getState() as RootState;
	const shop = stateData.ShopReducer.shop;
	const language = stateData.AppConfigReducer.language;
	const isSwitchBack = window.location.href.includes('switchBack');
	if (stateData.AppConfigReducer.calling) {
		store.dispatch(AppConfigActions.setCalling.request(false));
	}
	if (window.location.pathname.includes('/customer-booking')) {
		if (_.isEmpty(shop)) {
			store.dispatch(ShopActions.getShop.request());
		}
		return;
	}
	if (!_.isEmpty(shop)) {
		if (!isSwitchBack) {
			if (stateData.AppConfigReducer.useV1 === true) {
				window.location.replace(`https://${shop.adminUrl}.smartsalon.live/`);
			}
		} else {
			store.dispatch(AppConfigActions.useV1.request(false));
		}
	}
	if (
		!stateData.AuthenticationReducer.remember &&
		!_.isEmpty(stateData.AuthenticationReducer.token)
	) {
		store.dispatch(AuthenticationActions.logOut.request());
	} else {
		store.dispatch(AppConfigActions.showLoading.request(false));
		if (!_.isEmpty(language)) {
			I18n.locale = language;
		}
		// if (_.isEmpty(shop)) {
		store.dispatch(ShopActions.getShop.request());
		// } else {
		const token = stateData.AuthenticationReducer.token;
		if (!_.isEmpty(token)) {
			store.dispatch(UserActions.getCurrentUserInfo.request());
			// store.dispatch(AppConfigActions.getInitialData.request());
		}
		// }
	}
	if (stateData.BranchReducer.currentBranch) {
		store.dispatch(
			BranchActions.getBranchById.request(
				stateData.BranchReducer.currentBranch.id
			)
		);
	}
	if (electron) {
		electron.ipcRenderer.ready();
	}
});
const configureStore = () => {
	return { persistor, store };
};
sagaMiddleware.run(rootSagas);
export default configureStore;
