import { Card, Input, Select, TextArea } from 'components/atoms';
import { I18n, _t, translations } from 'utils';
import { ChangeEventHandler, useEffect, useRef } from 'react';
import clsxm from 'clsxs/clsxm';
import { useFormContext } from 'react-hook-form';
import { IItemCategory } from 'models';
import { UpdateServiceField } from '..';
import { useAppSelector } from 'helpers/hookHelpers';

import _ from 'lodash';
import { AvatarUpload } from 'components/molecules/AvatarUpload';

const FormBasicInfo = (props: { img?: File; setImg: (file: File) => void }) => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<UpdateServiceField>();
	const { setImg } = props;
	const categoryId = watch('itemCategoryId');
	const serviceUrl = watch('imageUrl');

	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);

	useEffect(() => {
		register('itemCategoryId');
	}, [register]);

	return (
		<Card
			title={I18n.t(_t(translations.servicesDetail.tBasicInfo))}
			description={I18n.t(_t(translations.servicesDetail.desBasicInfo))}
		>
			<div className={clsxm('grid grid-cols-4 gap-4 h-fit')}>
				<div
					className={clsxm(
						'col-span-1 flex justify-center align-middle items-center'
					)}
				>
					<AvatarUpload
						source={serviceUrl}
						onChange={(file) => {
							setImg(file);
						}}
					/>
				</div>

				<div className={'col-span-3'}>
					<div className={'grid grid-cols-2 gap-x-4 gap-y-2'}>
						<Input
							label={I18n.t(_t(translations.servicesDetail.serviceName))}
							maxLength={256}
							error={errors.name?.message}
							{...register('name', {
								required: I18n.t(
									_t(translations.servicesDetail.inputServiceName)
								),
							})}
						/>
						<Select<IItemCategory>
							options={categories}
							titleField={'name'}
							keyField={'id'}
							label={I18n.t(_t(translations.servicesDetail.serviceCategory))}
							title={'Select category'}
							fullWidth
							valueId={categoryId}
							onChange={(value) => setValue('itemCategoryId', value.id)}
						/>
					</div>
					<div className={'grid gap-x-4 gap-y-2 mt-2'}>
						<TextArea
							label={I18n.t(_t(translations.servicesDetail.serviceDescription))}
							placeholder={I18n.t(_t(translations.placeHolder.egServiceName))}
							// maxLength={256}
							{...register('description')}
						/>
					</div>
				</div>
			</div>
		</Card>
	);
};

export default FormBasicInfo;
