import { mdiBackspace, mdiCheck } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Icon } from 'components/atoms';
import { CurrencyHelper } from 'helpers';
import _ from 'lodash';
import React, {
	forwardRef,
	Ref,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
export interface INumPadProps {
	onConfirm?: (value: string) => void;
	onChange?: (text: string) => void;
	disabled?: boolean;
	unit?: string;
	isMoney?: boolean;
	value?: string;
	hideInput?: boolean;
	numberOnly?: boolean;
}
export interface INumPadRef {
	setText: (text: string) => void;
}

const keyClassName =
	'bg-[#F4F3F8] flex items-center justify-center text-black font-bold border border-base-300 py-6 text-3xl';

export const NumPad = forwardRef(
	(props: INumPadProps, ref: Ref<INumPadRef>) => {
		const [valueString, setValueString] = useState(props.value);

		useImperativeHandle(ref, () => ({
			setText(text: string) {
				setValueString(text);
			},
		}));
		const onKeyPress = (key: string) => {
			if (valueString === undefined) {
				setValueString(key);
				return;
			}
			if (key === '.') {
				if (valueString?.includes('.')) return;
			}
			setValueString(valueString + key);
		};
		const handleKeyUp = (e: KeyboardEvent) => {
			if (props.hideInput === true) {
				return;
			}
			if (e.key === 'Backspace' || e.code === 'Backspace') {
				setValueString((v) => {
					if (v !== undefined) {
						return v.slice(0, -1);
					}
				});
			}
			if (!_.isNaN(parseInt(e.key)) || e.key === '.') {
				setValueString((v) => {
					if (v === undefined) {
						return e.key;
					}
					if (e.key === '.' && v?.includes('.')) {
						return v;
					}
					return `${v}${e.key}`;
				});
				return;
			}
		};
		useEffect(() => {
			setValueString(props.value);
		}, [props.value]);
		useEffect(() => {
			window.addEventListener('keyup', (e) => handleKeyUp(e));
			return () => {
				window.removeEventListener('keyup', handleKeyUp);
			};
		}, []);
		useEffect(() => {
			props.onChange && props.onChange(valueString || '');
		}, [valueString]);
		useEffect(() => {}, []);

		return (
			<div className="w-full grid grid-cols-7 h-fit bg-PENDING">
				{props.hideInput !== true && (
					<div className="col-span-6 bg-white text-black font-bold border border-base-300 flex justify-end p-2 items-center h-16 ">
						{_.isEmpty(valueString)
							? props.isMoney && `0.00`
							: props.isMoney
							? CurrencyHelper.formatPrice(parseFloat(`${valueString}`))
							: valueString}
					</div>
				)}
				{props.hideInput !== true && (
					<div className="col-span-1 bg-[#F4F3F8] border-base-300 border flex justify-center items-center text-black p-2 h-16">
						{props.unit}
					</div>
				)}
				<div className="grid grid-cols-7 col-span-7">
					<div className="grid rtl-grid grid-cols-3 col-span-6 grid-rows-4">
						{[9, 8, 7, 6, 5, 4, 3, 2, 1].map((x) => {
							return (
								<div
									className={clsxm(keyClassName)}
									onClick={() => onKeyPress(`${x}`)}
								>
									{x}
								</div>
							);
						})}
						<div
							className={clsxm(keyClassName, props.numberOnly && 'col-span-3')}
							onClick={() => onKeyPress(`0`)}
						>
							{`0`}
						</div>
						{!props.numberOnly && (
							<div
								className={clsxm(keyClassName, 'col-span-2 rounded-bl-lg')}
								onClick={() => onKeyPress(`.`)}
							>
								{`.`}
							</div>
						)}
					</div>
					<div className="grid col-span-1 grid-rows-4">
						<div
							onClick={() => {
								setValueString((v) => {
									if (v !== undefined) {
										return v.slice(0, -1);
									}
								});
							}}
							className="row-span-2 border text-error border-base-300 flex justify-center items-center bg-[#F4F3F8]"
						>
							<Icon path={mdiBackspace} />
						</div>
						<div
							onClick={() => {
								if (!_.isEmpty(valueString) && !props.disabled) {
									props.onConfirm && props.onConfirm(`${valueString}`);
								}
							}}
							className={clsxm(
								'row-span-2  flex justify-center items-center',
								_.isEmpty(valueString) || props.disabled
									? 'bg-base-300'
									: ' main-gradient-background'
							)}
						>
							<Icon path={mdiCheck} />
						</div>
					</div>
				</div>
			</div>
		);
	}
);
