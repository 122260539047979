import { IHolidayDetail, IHolidayModel } from 'models';
import {
	IAddHolidayRequest,
	IGetAllHolidayRequest,
} from 'models/RequestModels';
import { IPaginateResponse } from 'models/ResponseModels';
import { BaseApiService } from 'services/BaseApiService';

class HolidayServices extends BaseApiService {
	public addHoliday = (request: IAddHolidayRequest) =>
		this.post<IAddHolidayRequest, any>(`${this.url}/create-holiday`, request);
	public getAllHoliday = (request: IGetAllHolidayRequest) =>
		this.get<IPaginateResponse<IHolidayModel[]>>(
			`${this.url}/get-all-holidays`,
			{
				params: request,
			}
		);
	public getHolidayById = (id: string) =>
		this.get<IHolidayModel>(`${this.url}/get-holiday/${id}`);
	public updateHoliday = (request: Partial<IHolidayModel>) =>
		this.put<Partial<IHolidayModel>>(`${this.url}/update-holiday`, request);
	public updateHolidayDetail = (request: Partial<IHolidayDetail>) =>
		this.put<Partial<IHolidayModel>>(
			`${this.url}/update-holiday-detail`,
			request
		);
	public deleteHoliday = (id: string) =>
		this.delete<string>(`${this.url}/delete-holiday/${id}`);
	public url = '/Holiday';
}

export default new HolidayServices(true);
