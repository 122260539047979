import { EFrequencyType, EWeekOfMonth } from 'models';
import { t, translations } from 'utils';
import * as yup from 'yup';

export type THolidayFormField = {
	description: string;
	staffId?: string;
	staffName?: string;
	startTime?: string;
	endTime?: string;
	fromDate: string;
	toDate: string;
	isEntireDay: boolean;
	repeat: boolean;
	frequencyType: EFrequencyType;
	maxNumberOfOccurrences?: number;
	daysOfWeek?: number[];
	daysOfMonth?: number[];
	weekOfMonth?: EWeekOfMonth;
};

export const holidayValidateSchema = yup.object().shape({
	description: yup.string(),
	staffId: yup.string(),
	staffName: yup.string(),
	startTime: yup.string(),
	endTime: yup.string(),
	fromDate: yup.string(),
	toDate: yup.string(),
	isEntireDay: yup.boolean(),
	// maxNumberOfOccurrences: yup
	// 	.number()
	// 	.notRequired()
	// 	.typeError(t(translations.validate.integer))
	// 	.integer(t(translations.validate.integer))
	// 	.min(0, t(translations.validate.lessThanZero)),
});
