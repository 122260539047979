import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IEmployeeType } from 'models';
import { getAllEmployeeTypes } from 'redux/actions/employeeType';
import { EmployeeTypeActions } from '../actions';

export interface IEmployeeTypeReducer {
	employeeTypes: IEmployeeType[];
	currentEmployeeType?: IEmployeeType;
}

const initialState: IEmployeeTypeReducer = {
	employeeTypes: [],
};

function getAllEmployeeTypeRequest(state: IEmployeeTypeReducer) {
	state.employeeTypes = [];
}

const getAllEmployeeTypesSuccess = (
	state: IEmployeeTypeReducer,
	action: PayloadAction<IEmployeeType[]>
) => {
	state.employeeTypes = action.payload;
};

function getEmployeeTypeByIdSuccess(
	state: IEmployeeTypeReducer,
	action: PayloadAction<IEmployeeType | null>
) {
	state.currentEmployeeType = action.payload!;
}

const reset = (state: IEmployeeTypeReducer) => {
	state.currentEmployeeType = undefined;
};

const selectCurrentEmployeeType = (
	state: IEmployeeTypeReducer,
	action: PayloadAction<IEmployeeType>
) => {
	state.currentEmployeeType = action.payload;
};

const addEmployeeTypeSuccess = (
	state: IEmployeeTypeReducer,
	action: PayloadAction<IEmployeeType>
) => {
	if (action.payload) {
		state.employeeTypes.push(action.payload);
	}
};

const editEmployeeTypeSuccess = (
	state: IEmployeeTypeReducer,
	action: PayloadAction<IEmployeeType>
) => {
	if (action.payload && action.payload.id) {
		const index = state.employeeTypes.findIndex(
			(x) => x.id === action.payload.id
		);
		if (index >= 0) {
			state.employeeTypes[index] = action.payload;
		}
	}
};

const deleteEmployeeTypeSuccess = (
	state: IEmployeeTypeReducer,
	action: PayloadAction<string>
) => {
	if (action.payload) {
		const index = state.employeeTypes.findIndex((x) => x.id === action.payload);
		if (index >= 0) {
			state.employeeTypes.splice(index, 1);
		}
	}
};

const EmployeeTypeReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(
			EmployeeTypeActions.getAllEmployeeTypes.request,
			getAllEmployeeTypeRequest
		)
		.addCase(
			EmployeeTypeActions.getAllEmployeeTypes.success,
			getAllEmployeeTypesSuccess
		)
		.addCase(
			EmployeeTypeActions.getEmployeeTypeById.success,
			getEmployeeTypeByIdSuccess
		)
		.addCase(EmployeeTypeActions.reset.request, reset)
		.addCase(
			EmployeeTypeActions.selectCurrentEmployeeType.request,
			selectCurrentEmployeeType
		)
		.addCase(
			EmployeeTypeActions.addNewEmployeeType.request,
			addEmployeeTypeSuccess
		)
		.addCase(
			EmployeeTypeActions.editEmployeeType.request,
			editEmployeeTypeSuccess
		)
		.addCase(
			EmployeeTypeActions.deleteEmployeeType.success,
			deleteEmployeeTypeSuccess
		);
});

export default EmployeeTypeReducer;
