import React, { useState } from 'react';
import clsxm from 'clsxs/clsxm';
import { Modal } from 'components/atoms';
import { SketchPicker } from 'react-color';
import { t, translations } from 'utils';

interface IColorPickerModalProps {
	onPickColor?: (color: string) => void;
	visible?: boolean;
	onClose?: () => void;
	defaultColor?: string;
}
export const ColorPickerModal = ({
	onPickColor,
	visible,
	onClose,
	defaultColor,
}: IColorPickerModalProps) => {
	const [selectedColor, setSelectedColor] = useState<string>(
		defaultColor || ''
	);

	return (
		<Modal
			isOpen={visible || false}
			title={t(translations.shopSetting.mainColor)}
			content={
				<SketchPicker
					onChange={(color) => {
						setSelectedColor(color.hex);
					}}
					color={selectedColor}
					styles={{
						default: {
							picker: {
								width: '100%',
								padding: 0,
								boxShadow: 'none',
								margin: 0,
							},
						},
					}}
					disableAlpha
				/>
			}
			contentContainerClassName={clsxm('overflow-x-hidden p-4')}
			// buttons={[
			//   <Button
			//     title={t(translations.shopSetting.chooseColor)}
			//     small
			//     onClick={() => onPickColor && onPickColor(selectedColor!)}
			//   />,
			// ]}
			onConfirm={() => onPickColor && onPickColor(selectedColor!)}
			onCancel={onClose}
			canConfirm
		/>
	);
};
