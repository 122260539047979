import { BaseApiService } from 'services/BaseApiService';
import { ITip } from 'models/ITip';
import { IEmployeeTip } from 'models/IEmployeeTip';
import moment from 'moment';

class TipApiService extends BaseApiService {
	public addTips = (params: ITip) =>
		this.post<ITip, IEmployeeTip[]>(`/Tip/add-bill-tips`, params);
	public addBillTipsManually = (params: ITip) =>
		this.post<ITip, IEmployeeTip[]>(`/Tip/add-bill-tips-manually`, params);
	public addBillTipsBalance = (params: ITip) =>
		this.post<ITip, IEmployeeTip[]>(`/Tip/add-bill-tips-balance`, params);
	public getTipByBillId = (billId: string) =>
		this.get<IEmployeeTip[]>(`/Tip/get-tips-by-bill`, {
			params: {
				toDate: moment().format('YYYY-MM-DD'),
				billId,
			},
		});
	public updateTipBill = (params: {
		id: string;
		employeeId: string;
		tipAmount: number;
	}) =>
		this.put<
			{
				id: string;
				employeeId: string;
				tipAmount: number;
			},
			unknown
		>(`/Tip/update-tip`, params);
	public deleteTip = (id: string) =>
		this.delete<string>(`/Tip/delete-tip/${id}`);
}

export default new TipApiService();
