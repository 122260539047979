import { IApiResponse } from "models";
import { put, takeEvery, call } from "redux-saga/effects";
import { RatingActions } from "redux/actions";
import { IErrorResponse } from "../../models/IErrorResponse";
import { PayloadAction } from "@reduxjs/toolkit";
import { ICreateRatingRequest } from "models/RequestModels";
import RatingApiService from "services/RatingApiService";

function* createRating(action: PayloadAction<ICreateRatingRequest>) {
    try {
      const result: IApiResponse<string> = yield call(RatingApiService.createRating, action.payload);
      if (result.succeeded) {
        yield put(RatingActions.createRating.success());
      } else {
        const error = result as IErrorResponse;
        yield put(RatingActions.createRating.failed(error));
      }
    } catch (error) {
      yield put(RatingActions.createRating.failed(error));
    }
  }
  export function* RatingWatcher() {
    yield takeEvery(RatingActions.createRating.requestName, createRating);
  }