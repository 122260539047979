import { Button, ConfirmModal, Icon } from 'components/atoms';
import Table, {
	EColumnType,
	IColumnProps,
	ITableRef,
} from 'components/atoms/Table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t, translations } from 'utils';
import {
	mdiCogOutline,
	mdiDeleteOutline,
	mdiPencilOutline,
	mdiPlus,
} from '@mdi/js';
import { ILoyaltyRank } from 'models/ILoyalRank';
import LoyaltyApiService from 'services/LoyaltyApiService';
import { ILoyaltySetting } from 'models/ILoyalty';
import { EarningPointMethod } from 'utils/Consts';
import { AlertHelper, CurrencyHelper } from 'helpers';

export const LoyaltyRank = () => {
	const [loyaltySetting, setLoyaltySetting] = useState<ILoyaltySetting>();
	const [showModal, setShowModal] = useState(false);
	const [rankId, setRankId] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const tableRef = useRef<ITableRef>();
	const history = useHistory();
	const columns = useMemo<IColumnProps<ILoyaltyRank>[]>(() => {
		return [
			{
				header: t(translations.loyaltyRank.rankName),
				key: 'name',
			},
			{
				header: t(translations.loyaltyRank.pointToReach),
				key: 'pointToReach',
				type: EColumnType.NUMBER,
			},
			{
				header: t(translations.loyaltyRank.extraEarningPointRate),
				key: 'extraRate',
				type: EColumnType.NUMBER,
			},
			{
				header: t(translations.loyaltyRank.customerInRank),
				key: 'countCustomersInRank',
				type: EColumnType.NUMBER,
			},
			{
				header: t(translations.action),
				key: 'id',
				renderCell: (data) => {
					return (
						<div className="flex gap-4 items-center">
							<div
								className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
								onClick={(e) => {
									e.stopPropagation();
									history.push(`loyalty-rank/edit/${data.id}`);
								}}
							>
								<Icon path={mdiPencilOutline} className="w-6 h-6" />
							</div>
							<div className="text-[#f0f0f0]">|</div>
							<div
								className="cursor-pointer rounded border border-DELETED p-2 bg-white"
								onClick={() => {
									setRankId(data.id);
									setShowModal(true);
								}}
							>
								<Icon
									path={mdiDeleteOutline}
									className="text-DELETED w-6 h-6"
								/>
							</div>
						</div>
					);
				},
				headerClassName: 'w-44',
				cellClassName: 'w-44',
			},
		];
	}, []);
	const getLoyaltySetting = async () => {
		try {
			const res = await LoyaltyApiService.getLoyaltySetting();
			if (res.succeeded && res.data) {
				setLoyaltySetting(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const onDeleteLoyaltyRank = async () => {
		setLoading(true);
		try {
			const res = await LoyaltyApiService.deleteLoyaltyRank(rankId);
			if (res.succeeded) {
				AlertHelper.showSuccess(
					t(translations.loyaltyRank.messageDeleteRankSuccess)
				);
				tableRef.current?.refresh();
			} else {
				AlertHelper.showError(res);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setShowModal(false);
		}
	};
	useEffect(() => {
		getLoyaltySetting();
	}, []);

	return (
		<div className="px-2 h-full">
			<Table<ILoyaltyRank>
				queryUrl={'/LoyaltyRank/get-all-loyalty-ranks'}
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				renderHeader={
					<div className="flex gap-x-4">
						<Button
							small
							className="btn-outline bg-white"
							title={t(translations.loyaltySetting.loyaltySetting)}
							iconBefore={<Icon path={mdiCogOutline} />}
							onClick={() => history.push('/settings/loyalty')}
						/>
						<Button
							small
							primary
							title={t(translations.addNew)}
							iconBefore={<Icon path={mdiPlus} />}
							onClick={() => history.push('/loyalty-rank/add-new')}
						/>
					</div>
				}
				renderSubHeader={
					<div className="items-center flex">
						<p className="m-0 text-lg">
							<b>{t(translations.loyaltySetting.generalRate)}</b>{' '}
							{loyaltySetting?.pointSystem === EarningPointMethod.SALE ? (
								<span>
									{t(translations.loyaltySetting.spendingAmount)} -{' '}
									<b>
										{CurrencyHelper.formatPrice(
											loyaltySetting?.pointExchangeRate
										)}{' '}
										= 1 Point
									</b>
								</span>
							) : (
								<span>
									{t(translations.loyaltySetting.usedInvoice)} -{' '}
									<b>
										{t(translations.loyaltySetting.invoice)}{' '}
										{loyaltySetting?.pointExchangeRate} = 1 Point
									</b>
								</span>
							)}
						</p>
						<div
							className="cursor-pointer rounded-full border border-[#aeaeae] p-2 bg-white ml-4"
							onClick={(e) => {
								e.stopPropagation();
								history.push(`/settings/loyalty`);
							}}
						>
							<Icon path={mdiCogOutline} className="w-6 h-6" />
						</div>
					</div>
				}
				columns={columns}
				showSearch
				// className="max-h-[calc(100vh-21.5rem)]"
			/>
			<ConfirmModal
				visible={showModal}
				title={t(translations.delete)}
				subTitle={t(translations.loyaltyRank.confirmDeleteRank)}
				onPositiveClick={() => {
					onDeleteLoyaltyRank();
				}}
				onNegativeClick={() => setShowModal(false)}
				type="error"
				loading={loading}
			/>
		</div>
	);
};
