import {
	mdiClose,
	mdiFacebook,
	mdiGoogle,
	mdiInstagram,
	mdiPlus,
	mdiTwitter,
} from '@mdi/js';
import axios from 'axios';
import clsxm from 'clsxs/clsxm';
import { Button, Card, Icon, Input, Select, Switch } from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { LoadingProvider } from 'contexts/LoadingContext';
import { AlertHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import { createMenuQr, print } from 'helpers/printHelper';
import { electron } from 'ipc';
import _ from 'lodash';
import { IApiResponse, ITheme } from 'models';
import { IShop } from 'models/IShop';
import { IFileUploadResponse } from 'models/ResponseModels';
import React, {
	ChangeEventHandler,
	InputHTMLAttributes,
	Ref,
	forwardRef,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	DefaultValues,
	useFieldArray,
	useForm,
	useFormContext,
} from 'react-hook-form';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { ShopActions, ThemeActions } from 'redux/actions';
import { history } from 'routers';
import UploadBaseService from 'services/UploadBaseService';
import { t, translations } from 'utils';
import {
	ColorPickerModal,
	IBaseShopSettingProps,
	IShopSettingFormData,
} from './components';
interface IPictureFanPage {
	data: {
		url: string;
		height: number;
		width: number;
		is_silhouette: boolean;
	};
}
interface IPageFaceBook {
	id: string;
	name: string;
	picture: IPictureFanPage;
	access_token: string;
	connected_instagram_account?: string;
}
export const ShopSetting = () => {
	const { shop } = useAppSelector((state) => state.ShopReducer);
	const shopAction = useAppSelector(
		(state) => state.ReduxActionReducer[ShopActions.prefix]
	);
	const [isLoading, setIsLoading] = useState<boolean>();
	const form = useForm<IShopSettingFormData>({
		defaultValues: shop as DefaultValues<IShopSettingFormData>,
		mode: 'onChange',
		reValidateMode: 'onChange',
	});

	const { accessToken } = useParams<{ accessToken: string }>();

	const isLoadingGetShop = useMemo(
		() =>
			shopAction === ShopActions.getShop.requestName ||
			shopAction === ShopActions.updateShop.requestName,
		[shopAction]
	);
	const dispatch = useDispatch();
	const [newLogo, setNewLogo] = useState<File>();
	const [isInitial, setIsInitial] = useState<boolean>(true);

	useEffect(() => {
		dispatch(ShopActions.getShop.request());
		dispatch(
			ThemeActions.getAllThemes.request({ pageNumber: 1, pageSize: 100 })
		);
	}, [dispatch]);

	useEffect(() => {
		if (shopAction === ShopActions.getShop.requestName) {
		} else {
			if (shopAction === ShopActions.getShop.successName) {
				form.reset();
				setIsInitial(false);
			}
		}
		if (!isInitial) {
			if (shopAction === ShopActions.updateShop.requestName) {
				setIsLoading(true);
			} else {
				setIsLoading(false);
				if (shopAction === ShopActions.updateShop.successName) {
					history.goBack();
				}
			}
		}
	}, [shopAction, form, isInitial]);

	useEffect(() => {
		if (accessToken === 'error') {
			AlertHelper.showAlert(
				'Had an error when integrate with your facebook',
				'error'
			);
		}
	}, [accessToken]);

	const onFormSubmit = form.handleSubmit(async (data) => {
		if (newLogo) {
			const uploadResult = (await UploadBaseService.uploadImage([
				newLogo,
			])) as IApiResponse<IFileUploadResponse[]>; //Dont know why to keep it in array
			if (uploadResult.succeeded) {
				form.setValue('logoUrl', uploadResult.data![0].imageUrl);
				AlertHelper.showSuccess(t(translations.shopSetting.uploadLogoSuccess));
			} else {
				AlertHelper.showError(uploadResult);
				return;
			}
		}
		const updatedShop = data as IShop;
		dispatch(ShopActions.updateShop.request(updatedShop));
	});

	return (
		<DetailPageLayout
			form={form}
			loading={isLoadingGetShop}
			onSave={onFormSubmit}
		>
			<div className={clsxm('grid grid-cols-2 gap-x-4')}>
				<GeneralConfigs
					isLoading={isLoading}
					onChooseLogo={setNewLogo}
					logo={newLogo}
				/>
				<SocialNetworkConfigs isLoading={isLoading} />
			</div>
			<div className={clsxm('grid grid-cols-2 gap-x-4')}>
				<ThemeConfigs isLoading={isLoading} />
				<ShopQrCode />
			</div>
			<div className={clsxm('grid grid-cols-2 gap-x-4')}>
				<SMSReminderConfigs isLoading={isLoading} />
				<IntegrateFacebook accessToken={accessToken} />
			</div>
		</DetailPageLayout>
	);
};

const GeneralConfigs = ({
	isLoading,
	onChooseLogo,
	logo,
}: IBaseShopSettingProps & {
	onChooseLogo?: (file: File) => void;
	logo?: File;
}) => {
	const uploadRef = useRef<HTMLInputElement>(null);
	const handleUpload = () => {
		uploadRef.current?.click();
	};
	const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		if (!_.isEmpty(event.target.files)) {
			onChooseLogo && onChooseLogo(event.target.files![0]);
		}
	};
	const { watch, register, getValues, setValue } =
		useFormContext<IShopSettingFormData>();
	const shopLogoUrl = watch('logoUrl');

	return (
		<Card
			title={t(translations.shopSetting.shop)}
			description={t(translations.shopSetting.subShop)}
		>
			<div className={clsxm('grid grid-cols-4 gap-4 h-fit')}>
				<div className={clsxm('col-span-1')}>
					<label className={clsxm('label font-medium')}>
						<span className={clsxm('label-text text-md')}>
							{t(translations.shopSetting.logo)}
						</span>
					</label>
					<div className="avatar flex-1 cursor-pointer" onClick={handleUpload}>
						<div className="w-32 rounded-xl">
							{logo ? (
								<img
									className={clsxm('m-0')}
									src={URL.createObjectURL(logo)}
									alt={t(translations.shopSetting.noLogo)}
								/>
							) : shopLogoUrl ? (
								<img
									className={clsxm('m-0')}
									src={shopLogoUrl}
									alt={t(translations.shopSetting.noLogo)}
								/>
							) : (
								<p>{t(translations.shopSetting.noLogo)}</p>
							)}
							<input
								ref={uploadRef}
								type={'file'}
								className={clsxm('hidden')}
								onChange={handleFileChange}
								accept={'.jpg,.jpeg,.png'}
								id={'logo'}
							/>
						</div>
					</div>
				</div>
				<div className={clsxm('col-span-3')}>
					<div className={clsxm('grid grid-cols-2 gap-x-4')}>
						<Input
							label={t(translations.shopSetting.name)}
							{...register('name', { disabled: isLoading })}
						/>
						<Input
							label={t(translations.shopSetting.adminUrl)}
							{...register('adminUrl', { disabled: isLoading })}
						/>
						{/* <Switch
							className="flex items-end"
							defaultChecked={
								getValues().checkEmployeeCodeWhenCheckout || false
							}
							label={t(translations.shopSetting.requireEmployeeCode)}
							onChange={(value) =>
								setValue('checkEmployeeCodeWhenCheckout', value)
							}
							value={watch('checkEmployeeCodeWhenCheckout')}
							disabled={isLoading}
						/> */}
					</div>
				</div>
			</div>
		</Card>
	);
};

const SMSReminderConfigs = ({ isLoading }: IBaseShopSettingProps) => {
	const { watch, register, getValues, setValue } =
		useFormContext<IShopSettingFormData>();
	const reminderSmsOnTheDayOfAppointment = watch(
		'reminderSmsOnTheDayOfAppointment'
	);
	return (
		<Card title={t(translations.shopSetting.smsSettings)}>
			<div className={clsxm('grid grid-cols-2 gap-x-4')}>
				<Switch
					className="flex items-end"
					defaultChecked={getValues().reminderSmsOnTheDayOfAppointment || false}
					label={t(translations.shopSetting.reminderSMS)}
					onChange={(value) =>
						setValue('reminderSmsOnTheDayOfAppointment', value)
					}
					value={reminderSmsOnTheDayOfAppointment}
					disabled={isLoading}
				/>
				{reminderSmsOnTheDayOfAppointment && (
					<Input
						label={t(translations.shopSetting.timeBeforeForReminder)}
						inputMode="numeric"
						{...register('timeBeforeForReminder', { disabled: isLoading })}
					/>
				)}
			</div>
		</Card>
	);
};

const SocialNetworkConfigs = ({ isLoading }: IBaseShopSettingProps) => {
	const { register } = useFormContext<IShopSettingFormData>();
	return (
		<Card
			title={t(translations.shopSetting.socialNetwork)}
			description={t(translations.shopSetting.subSocialNetwork)}
		>
			<div className={clsxm('grid grid-cols-2 gap-x-4')}>
				<Input
					label={t(translations.shopSetting.googleUrl)}
					renderBefore={<Icon path={mdiGoogle} />}
					{...register('googleUrl', { disabled: isLoading })}
				/>
				<Input
					label={t(translations.shopSetting.twitterUrl)}
					renderBefore={<Icon path={mdiTwitter} />}
					{...register('twitterUrl', { disabled: isLoading })}
				/>
			</div>
			<div className={clsxm('grid grid-cols-2 gap-x-4')}>
				<Input
					label={t(translations.shopSetting.facebookUrl)}
					renderBefore={<Icon path={mdiFacebook} />}
					{...register('facebookUrl', { disabled: isLoading })}
				/>
				<Input
					label={t(translations.shopSetting.instagramUrl)}
					renderBefore={<Icon path={mdiInstagram} />}
					{...register('instagramUrl', { disabled: isLoading })}
				/>
			</div>
		</Card>
	);
};

const ShopQrCode = () => {
	const { shop } = useAppSelector((state) => state.ShopReducer);
	const handlePrint = useReactToPrint({
		content: () => {
			return qrRef.current!;
		},
		pageStyle: printStyle,
	});
	const qrRef = useRef<HTMLDivElement>(null);
	const onPrint = () => {
		if (!electron) {
			handlePrint!();
		} else {
			const commands = createMenuQr(
				`https://${shop?.adminUrl}.website.smartsalon.live/menu`
			);
			print(commands);
		}
		// const content = document.getElementById('divcontents');
		// const priElement = document.getElementById('ifmcontentstoprint')
		// if (content && priElement){
		// 	const pri = priElement.contentWindow
		// 	pri.document.open();
		// 	pri.document.write(content.innerHTML);
		// 	pri.document.close();
		// 	pri.focus();
		// 	pri.print();
		// }
	};
	const url = `https://${shop?.adminUrl}.website.smartsalon.live/menu`;
	const onCopyUrl = () => {
		navigator.clipboard
			.writeText(url)
			.then(() => {
				AlertHelper.showSuccess(t(translations.shopSetting.copiedUrl));
			})
			.catch(() => {
				AlertHelper.showAlert(
					t(translations.shopSetting.copiedUrlFailed),
					'error'
				);
			});
	};
	return (
		<Card title={t(translations.shopSetting.shopMenuQrCode)}>
			<div className={clsxm('flex flex-row items-start justify-between gap-4')}>
				<div
					className={clsxm('gap-y-4 flex flex-col items-start justify-center')}
				>
					<div
						ref={qrRef}
						className={clsxm('flex-1 flex flex-col items-center justify-start')}
					>
						<label id="label-qr" className={clsxm('label font-medium hidden')}>
							<span className={clsxm('label-text text-md text-center')}>
								{t(translations.shopSetting.scanQrCode)}
							</span>
						</label>
						<QRCode value={url} size={150} className={clsxm('m-0')} />
					</div>
				</div>
				<div className={clsxm('flex-1 grid gap-4')}>
					<div className="grid grid-cols-5 gap-x-4 items-end">
						<div className="col-span-4">
							<Input
								label={t(translations.shopSetting.serviceMenuUrl)}
								disabled
								value={url}
							/>
						</div>
						<Button title={t(translations.copy)} onClick={onCopyUrl} small />
					</div>
					<Button
						small
						primary={false}
						title={t(translations.shopSetting.printQrCode)}
						onClick={onPrint}
					/>
				</div>
			</div>
		</Card>
	);
};

const ThemeConfigs = ({ isLoading }: IBaseShopSettingProps) => {
	const { themes } = useAppSelector((state) => state.ThemeReducer);
	const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
	const {
		formState: { errors },
		watch,
		register,
		setValue,
		control,
	} = useFormContext<IShopSettingFormData>();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'shopPortals',
	});

	const selectedTheme = watch('themeShopConfig.themeName');
	const selectedColor = watch('themeShopConfig.defaultColor');
	const portalUrl = watch('portalUrl')!;

	useEffect(() => {
		if (!fields.length) {
			append({ portalUrl: portalUrl });
		}
	}, [fields, append, portalUrl]);

	const themeValue = useMemo(
		() => themes.find((theme) => theme.themeName === selectedTheme),
		[selectedTheme, themes]
	);

	return (
		<Card
			title={t(translations.shopSetting.theme)}
			description={t(translations.shopSetting.subTheme)}
		>
			<div className={clsxm('grid grid-cols-2 gap-x-4')}>
				<Select<ITheme>
					options={themes}
					titleField={'themeName'}
					keyField={'id'}
					label={t(translations.shopSetting.landingPageTheme)}
					value={themeValue}
					onChange={(theme) =>
						setValue('themeShopConfig.themeName', theme.themeName)
					}
					disabled={isLoading}
					fullWidth
				/>
				<CustomInput
					label={t(translations.shopSetting.mainColor)}
					className={clsxm('caret-transparent cursor-pointer font-bold')}
					onClick={() => setShowColorPicker(true)}
					{...register('themeShopConfig.defaultColor', { disabled: isLoading })}
					renderAfter={
						<div
							className={clsxm(`w-11 h-full`)}
							style={{ backgroundColor: selectedColor }}
						/>
					}
					style={{ color: selectedColor }}
				/>
			</div>
			<ColorPickerModal
				onClose={() => setShowColorPicker(false)}
				visible={showColorPicker}
				onPickColor={(color) => {
					setShowColorPicker(false);
					setValue('themeShopConfig.defaultColor', color);
				}}
				defaultColor={selectedColor}
			/>
			<div className="form-array">
			<p className='font-semibold'>{t(translations.shopSetting.portalUrl)}</p>
				{fields.map((field, index) => (
					<div
						className="grid grid-cols-12 items-center gap-3 my-3"
						key={field.id}
					>
						<div className="col-span-10">
							<Input
								error={errors?.shopPortals?.[index]?.portalUrl?.message || ''}
								{...register(`shopPortals.${index}.portalUrl` as const, {
									disabled: isLoading,
									pattern: {
										value:
											/^(?:[a-zA-Z0-9.-]+)?(?:\.[a-zA-Z]{2,})+(?::[0-9]+)?(?:\/[^\s]*)?$/,
										message: 'Please enter a valid URL',
									},
								})}
							/>
						</div>
						<div className="col-span-1">
							<Button
								small
								primary={false}
								className="btn-outline btn-error bg-white !min-w-[50px]"
								title=""
								iconBefore={<Icon path={mdiClose} />}
								onClick={() => remove(index)}
							/>
						</div>
					</div>
				))}
				<div className="flex flex-end mt-2">
					<Button
						small
						primary={false}
						title={t(translations.addNew)}
						iconBefore={<Icon path={mdiPlus} />}
						onClick={() => append({ portalUrl: '' })}
					/>
				</div>
			</div>
		</Card>
	);
};

const IntegrateFacebook = (props: { accessToken: string }) => {
	const { shop } = useAppSelector((state) => state.ShopReducer);
	const [listFanPage, setListFanPage] = useState<IPageFaceBook[]>([]);

	const onIntegrateFb = async () => {
		window.location.replace(
			`${process.env.REACT_APP_INTEGRATION_URL}/facebook/integration/${shop?.adminUrl}`
		);
	};
	const getListPage = () => {
		if (!_.isEmpty(props.accessToken) && props.accessToken !== 'error') {
			axios
				.post(
					`${process.env.REACT_APP_INTEGRATE_FACEBOOK_URL}/page/generateLongLiveToken`,
					{
						token: props.accessToken,
					}
				)
				.then((res) => {
					axios
						.get(
							`https://graph.facebook.com/v14.0/me/accounts?fields=access_token,connected_instagram_account,about,general_info,name,picture&access_token=${res.data.longLiveToken}`
						)
						.then((res2) => {
							setListFanPage(res2.data.data);
						});
				});
		}
	};
	const onConnectFanPage = (fanPage: IPageFaceBook) => {
		axios
			.post(`${process.env.REACT_APP_INTEGRATE_FACEBOOK_URL}/page/connect`, {
				pageId: fanPage.id,
				shopId: shop?.id,
				accessToken: fanPage.access_token,
				name: fanPage.name,
				shopName: shop?.name,
				instagramId: fanPage.connected_instagram_account,
			})
			.then((res) => {
				console.log(res);
				AlertHelper.showSuccess('Connected to this page successfully');
			});
	};
	useEffect(() => {
		getListPage();
	}, []);
	return (
		<Card title="Integrate Facebook">
			<div className="flex justify-end">
				<Button
					title="Integrate With Facebook"
					onClick={onIntegrateFb}
					small
					primary
				/>
			</div>
			{!_.isEmpty(props.accessToken) && _.isEmpty(listFanPage) ? (
				<LoadingProvider loading={true}>
					<div className="w-full h-12"></div>
				</LoadingProvider>
			) : (
				listFanPage.map((x) => {
					return (
						<div className="flex text-center items-center justify-between">
							<div className="flex items-center">
								<img
									src={x.picture.data.url}
									alt="ss"
									className="m-0 h-12 w-12"
								></img>{' '}
								<p className="ml-4 font-semibold text-lg">{x.name}</p>
							</div>
							<Button
								title="Connect"
								small
								white
								className="rounded-full text-primary shadow-md"
								onClick={() => onConnectFanPage(x)}
							/>
						</div>
					);
				})
			)}
		</Card>
	);
};

export const CustomInput = forwardRef(
	(
		props: {
			label?: string;
			error?: string;
			className?: string;
			renderAfter?: React.ReactNode;
		} & InputHTMLAttributes<HTMLInputElement>,
		ref: Ref<HTMLInputElement>
	) => {
		return (
			<div className={clsxm('form-control', 'w-full')}>
				{props.label && (
					<label className="label font-medium ">
						<span className="label-text text-md">{props.label}</span>
					</label>
				)}
				<label className={clsxm(props.renderAfter && 'input-group')}>
					<input
						{...props}
						ref={ref}
						className={clsxm(
							'input input-bordered w-full h-[45px]',
							props.error && 'border-error',
							props.className
						)}
					/>
					{props.renderAfter && (
						<span className="p-0">{props.renderAfter}</span>
					)}
				</label>
				{props.error && (
					<p className="ml-2 mt-2 text-left w-full text-error">{props.error}</p>
				)}
			</div>
		);
	}
);

const printStyle = `
	@page { 
	  size: auto;  margin: 0mm ; } @media print { body { -webkit-print-color-adjust: exact; } }
	@media print {
	  div.page-footer {
	  position: fixed;
	  bottom:0mm;
	  width: 100%;
	  height: 100%;
	  font-size: 15px;
	  color: #fff;
	  /* For testing */
	  background: red; 
	  opacity: 0.5;
	  
	  page-break-after: always;
	  }
	  .page-number:before {
		/* counter-increment: page; */
		content: "Pagina "counter(page);
	  }
    
    div label {
      display: inline
    }
  
	}

	body {
	  marginBottom:50px;
	  display:flex
    justify-content: center;
    align-items: center;
	}
	`;
