import clsxm from 'clsxs/clsxm';
import { Button, Card, Input, Switch } from 'components/atoms';
import { AlertHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import { t } from 'i18n-js';
import { useFormContext } from 'react-hook-form';
import { Const, translations } from 'utils';
import { UpdateBranchFormField } from './IConfigBaseProps';
import { Divider } from 'pages/FinancialReport/components/Divider';

const OnlineBooking = ({ isLoading }: { isLoading: boolean }) => {
	const { watch, setValue, getValues } =
		useFormContext<Partial<UpdateBranchFormField>>();

	const technicianSelectionWhenBookingOnline = watch(
		'technicianSelectionWhenBookingOnline'
	) as boolean;
	const technicianSelectionWhenCheckInByTablet = watch(
		'technicianSelectionWhenCheckInByTablet'
	) as boolean;
	const showServicePriceWhenBookingOnline = watch(
		'showServicePriceWhenBookingOnline'
	) as boolean;
	const assignTechnicianToBooking = watch(
		'assignTechnicianToBooking'
	) as boolean;
	
	const checkEmployeeCodeWhenCheckout = watch(
		'checkEmployeeCodeWhenCheckout'
	) as boolean;

	const displayDurationOnPortal = watch('displayDurationOnPortal') as boolean;
	const displayEmployeesOnPortal = watch('displayEmployeesOnPortal') as boolean;

	const { shop } = useAppSelector((state) => state.ShopReducer);
	const onlineBookingUrl = `https://${shop?.adminUrl}.v2.smartsalon.live${Const.OnlineBookingUrl}`;

	const onCopyOnlineBookingUrl = () => {
		navigator.clipboard
			.writeText(onlineBookingUrl)
			.then(() => {
				AlertHelper.showSuccess(
					t(translations.branchSetting.copyOnlineBookingUrlSuccess)
				);
			})
			.catch(() => {
				AlertHelper.showAlert(
					t(translations.branchSetting.copyOnlineBookingUrlFailed),
					'error'
				);
			});
	};

	return (
		<Card
			title={t(translations.branchSetting.onlineBooking)}
			accordion={true}
			isDefaultAccordion={true}
		>
			<div className={clsxm('grid grid-cols-1 gap-y-8')}>
				<div className="grid grid-cols-5 gap-x-8 items-end">
					<div className="col-span-4">
						<Input
							label={t(translations.branchSetting.onlineBookingUrl)}
							disabled
							value={onlineBookingUrl}
						/>
					</div>
					<Button
						title={t(translations.copy)}
						onClick={onCopyOnlineBookingUrl}
						small
					/>
				</div>
			</div>
			<div className={clsxm('grid grid-cols-3 mt-3 gap-x-8')}>
				<Switch
					defaultChecked={getValues().technicianSelectionWhenCheckInByTablet}
					label={t(translations.branchSetting.showStylistTablet)}
					onChange={(value) =>
						setValue('technicianSelectionWhenCheckInByTablet', value)
					}
					value={technicianSelectionWhenCheckInByTablet}
					disabled={isLoading}
				/>
				<Switch
					defaultChecked={getValues().assignTechnicianToBooking}
					label={t(translations.branchSetting.assignTechnicianToBooking)}
					onChange={(value) => setValue('assignTechnicianToBooking', value)}
					value={assignTechnicianToBooking}
					disabled={isLoading}
				/>
				<Switch
					defaultChecked={getValues().checkEmployeeCodeWhenCheckout || false}
					label={t(translations.shopSetting.requireEmployeeCode)}
					onChange={(value) => setValue('checkEmployeeCodeWhenCheckout', value)}
					value={checkEmployeeCodeWhenCheckout}
					disabled={isLoading}
				/>
				<Divider className="col-span-3" />
				<Switch
					defaultChecked={getValues().technicianSelectionWhenBookingOnline}
					label={t(translations.branchSetting.chooseStylistOnlineBooking)}
					onChange={(value) =>
						setValue('technicianSelectionWhenBookingOnline', value)
					}
					value={technicianSelectionWhenBookingOnline}
					disabled={isLoading}
				/>
				<Switch
					defaultChecked={getValues().showServicePriceWhenBookingOnline}
					label={t(translations.branchSetting.showServicePriceOnlineBooking)}
					onChange={(value) =>
						setValue('showServicePriceWhenBookingOnline', value)
					}
					value={showServicePriceWhenBookingOnline}
					disabled={isLoading}
				/>

				<Switch
					defaultChecked={getValues().displayDurationOnPortal}
					label={t(translations.branchSetting.displayDurationOnPortal)}
					onChange={(value) => setValue('displayDurationOnPortal', value)}
					value={displayDurationOnPortal}
					disabled={isLoading}
				/>
				<Switch
					defaultChecked={getValues().displayEmployeesOnPortal}
					label={t(translations.branchSetting.displayEmployeesOnPortal)}
					onChange={(value) => setValue('displayEmployeesOnPortal', value)}
					value={displayEmployeesOnPortal}
					disabled={isLoading}
				/>
			</div>
		</Card>
	);
};

export default OnlineBooking;
