import { IApiResponse, ICustomer } from "models";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { CustomerActions } from "redux/actions";
import CustomerApiService from "services/CustomerApiService";
import { IErrorResponse } from "../../models/IErrorResponse";
import { PayloadAction } from "@reduxjs/toolkit";
import { AlertHelper } from "helpers";
import { I18n, _t, translations } from "utils";
import { RootState } from "redux/configuration/rootReducer";
import { IBranch } from "models/IBranch";
import { IGetAllCustomerRequest } from "models/RequestModels";
const getBranch = (state: RootState) => state.BranchReducer.currentBranch;

function* getCustomers(action: PayloadAction<IGetAllCustomerRequest | undefined>) {
  try {
    const result: IApiResponse<ICustomer[]> = yield call(CustomerApiService.getCustomers, action.payload);
    yield put(CustomerActions.getAllCustomers.success(result.data!));
  } catch (error) {
    yield put(CustomerActions.getAllCustomers.failed(error));
  }
}

function* addCustomer(action: PayloadAction<Partial<ICustomer>>) {
  try {
    const currentBranch: IBranch = yield select(getBranch);
    const customer = {
      ...action.payload,
      address: {
        ...action.payload.address,
        countryCode: currentBranch.address?.countryCode,
      },
    };
    const result: IApiResponse<string> = yield call(CustomerApiService.addCustomer, customer);
    if (result.succeeded) {
      yield put(CustomerActions.addCustomer.success());
      AlertHelper.showAlert("Success", "success");
    } else {
      const error = result as IErrorResponse;
      yield put(CustomerActions.addCustomer.failed(error));
      AlertHelper.showError(error);
    }
  } catch (error) {
    yield put(CustomerActions.addCustomer.failed(error));
    AlertHelper.showError(error as IErrorResponse);
  }
}

function* editCustomer(action: PayloadAction<Partial<ICustomer>>) {
  try {
    const result: IApiResponse<string> = yield call(CustomerApiService.editCustomer, action.payload);
    if (result.succeeded) {
      yield put(CustomerActions.editCustomer.success());
      AlertHelper.showAlert("Success", "success");
    } else {
      const error = result as IErrorResponse;
      yield put(CustomerActions.editCustomer.failed(error));
      AlertHelper.showError(error);
    }
  } catch (error) {
    yield put(CustomerActions.editCustomer.failed(error));
    AlertHelper.showError(error as IErrorResponse);
  }
}

function* getCustomerById(action: PayloadAction<string>) {
  try {
    const result: IApiResponse<ICustomer> = yield call(CustomerApiService.getCustomerById, action.payload);
    if (result.succeeded) {
      yield put(CustomerActions.getCustomerById.success(result.data!));
    } else {
      const errorResult = result as IErrorResponse;
      yield put(CustomerActions.getCustomerById.failed(errorResult));
      AlertHelper.showError(errorResult);
    }
  } catch (error) {
    yield put(CustomerActions.getCustomerById.failed(error));
  }
}
function* deleteCustomer(action: PayloadAction<string>) {
  try {
    const result: IApiResponse<string> = yield call(CustomerApiService.deleteCustomer, action.payload);
    if (result.succeeded) {
      yield put(CustomerActions.deleteCustomer.success());
      AlertHelper.showAlert("Success", "success");
    } else {
      const error = result as IErrorResponse;
      yield put(CustomerActions.deleteCustomer.failed(error));
      AlertHelper.showError(error);
    }
  } catch (error) {
    yield put(CustomerActions.deleteCustomer.failed(error));
    AlertHelper.showError(error as IErrorResponse);
  }
}
export function* CustomerWatcher() {
  yield takeEvery(CustomerActions.getAllCustomers.requestName, getCustomers);
  yield takeEvery(CustomerActions.addCustomer.requestName, addCustomer);
  yield takeEvery(CustomerActions.editCustomer.requestName, editCustomer);
  yield takeEvery(CustomerActions.getCustomerById.requestName, getCustomerById);
  yield takeEvery(CustomerActions.deleteCustomer.requestName, deleteCustomer);
}
