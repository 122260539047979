import { IPaginateRequest } from 'models/RequestModels';
import { BaseApiService } from './BaseApiService';

const prefix = '/Theme';

class ThemeApiService extends BaseApiService {
	public getAllThemes = (request: IPaginateRequest) =>
		this.get(`${prefix}/get-all-themes`, { params: request });
}

export default new ThemeApiService(true);
