import { createReducer } from '@reduxjs/toolkit';
import { EmployeeActions } from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { EEmployeeTypeCode, IEmployee } from 'models/IEmployee';

export interface IEmployeeReducer {
	employees?: IEmployee[];
	selectedEmployee?: IEmployee;
}
const initialState: IEmployeeReducer = {
	employees: [],
};

//Get employee
const getAllEmployeesSuccess = (
	state: IEmployeeReducer,
	action: PayloadAction<IEmployee[]>
) => {
	state.employees = action.payload;
};
function selectEmployee(
	state: IEmployeeReducer,
	action: PayloadAction<IEmployee>
) {
	state.selectedEmployee = action.payload;
}

function getEmployeeByIdSuccess(
	state: IEmployeeReducer,
	action: PayloadAction<IEmployee>
) {
	state.selectedEmployee = action.payload;
}
function removeCurrentEmployee(state: IEmployeeReducer) {
	state.selectedEmployee = undefined;
}
const getEmployeesByBranchSuccess = (
	state: IEmployeeReducer,
	action: PayloadAction<IEmployee[]>
) => {
	state.employees = action.payload;
};

function addEmployee(
	state: IEmployeeReducer,
	action: PayloadAction<IEmployee>
) {
	if (state.employees) {
		state.employees.push(action.payload);
	} else {
		state.employees = [action.payload];
	}
}

function editEmployee(
	state: IEmployeeReducer,
	action: PayloadAction<IEmployee>
) {
	const index =
		state.employees && action.payload && action.payload.id
			? state.employees.findIndex((x) => x.id === action.payload.id)
			: -1;

	if (index > -1 && state.employees) {
		state.employees[index] = action.payload;
	}
}

function deleteEmployee(
	state: IEmployeeReducer,
	action: PayloadAction<string>
) {
	const index =
		state.employees && action.payload && action.payload
			? state.employees.findIndex((x) => x.id === action.payload)
			: -1;

	if (index > -1 && state.employees) {
		state.employees.splice(index, 1);
	}
}

const EmployeeReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(EmployeeActions.getAllEmployees.success, getAllEmployeesSuccess)
		.addCase(EmployeeActions.selectEmployee.request, selectEmployee)
		.addCase(EmployeeActions.getEmployeeById.success, getEmployeeByIdSuccess)
		.addCase(
			EmployeeActions.getEmployeesByBranch.success,
			getEmployeesByBranchSuccess
		)
		.addCase(
			EmployeeActions.getEmployeesByService.success,
			getAllEmployeesSuccess
		)
		.addCase(
			EmployeeActions.removeCurrentEmployee.request,
			removeCurrentEmployee
		)
		.addCase(EmployeeActions.addEmployee.success, addEmployee)
		.addCase(EmployeeActions.editEmployee.success, editEmployee)
		.addCase(EmployeeActions.deleteEmployee.success, deleteEmployee);
});

export default EmployeeReducer;
