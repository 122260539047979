import moment from 'moment';
import { BaseApiService } from './BaseApiService';
import { IFileUploadResponse } from 'models/ResponseModels';
import { IApiResponse } from 'models/IApiResponse';
import { IErrorResponse } from 'models/IErrorResponse';
import configureStore from 'redux/configuration/configureStore';

class UploadBaseService extends BaseApiService {
  uploadImage = (files: any): Promise<IApiResponse<IFileUploadResponse> | IErrorResponse> => {
    const formData = new FormData();
    const store = configureStore().store;
    files.forEach((file: any) => {
      formData.append("files", file);
    });
    formData.append("title", `IMG_${moment().unix()}`);
    formData.append("ShopId", store.getState().ShopReducer.shop?.id!);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return this.post("/Picture/upload-picture", formData, config);
  };

  deleteImage = (id: string) => this.delete<string>(`/Picture/delete-picture/${id}`);
}

export default new UploadBaseService(true);
