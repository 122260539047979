import clsxm from 'clsxs/clsxm';
import { Container, ButtonGroup, Select, Button } from 'components/atoms';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { TDateRange } from 'components/molecules/ModalCalendar';
import { StaffPickerModal } from 'components/molecules/StaffPickerModal';
import { LoadingProvider } from 'contexts/LoadingContext';
import { AlertHelper } from 'helpers';
import _ from 'lodash';
import {
	IApiResponse,
	IEmployee,
	IPayrollDetail,
	IPayrollOverview,
} from 'models';
import { IPayrollRequest } from 'models/RequestModels';
import moment from 'moment';
import { getDateString } from 'pages/FinancialReport';
import React, { useEffect, useState } from 'react';
import { ReportApiService } from 'services';
import { t, translations } from 'utils';
import { DateRangeType, DATE_RANGE_TYPES } from 'utils/Consts';
import {
	FeeAndPayment,
	PayrollReportContext,
	ReportRollView,
	ServedServices,
} from './components';
import { DetailTable } from './components/DetailTable';

export const PayrollDetailReport = () => {
	const [selectedStaff, setSelectedStaff] = useState<IEmployee>();
	const [isShowStaffPicker, setIsShowStaffPicker] = useState<boolean>();
	const [report, setReport] = useState<[IPayrollOverview, IPayrollDetail]>();
	const [isDetail, setIsDetail] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>();
	const [isShowDateRange, setIsShowDateRange] = useState<boolean>(); //workaround until change daterange props
	const [dateFilter, setDateFilter] = useState<TDateRange>({
		fromDate: moment().startOf('date'),
		toDate: moment().startOf('date'),
	});
	const [selectedDateRangeType, setSelectedDateRangeType] = useState<
		(typeof DATE_RANGE_TYPES)[0]
	>(DATE_RANGE_TYPES[0]);

	useEffect(() => {
		if (!_.isEmpty(selectedStaff)) {
			getReport({
				pageSize: 1000,
				pageNumber: 1,
				employeeId: selectedStaff?.id,
				fromDate: dateFilter.fromDate!.format('YYYY-MM-DD'),
				toDate: dateFilter.toDate!.format('YYYY-MM-DD'),
			});
		}
	}, [dateFilter, selectedStaff]);

	useEffect(() => {
		setIsShowDateRange(false);
		let timeOut = setTimeout(() => {
			switch (selectedDateRangeType.value) {
				case DateRangeType.TODAY:
					setDateFilter({ fromDate: moment(), toDate: moment() });
					break;
				case DateRangeType.YESTERDAY:
					setDateFilter({
						fromDate: moment().add(-1, 'day'),
						toDate: moment().add(-1, 'day'),
					});
					break;
				case DateRangeType.WEEKLY:
					setDateFilter({
						fromDate: moment().startOf('week'),
						toDate: moment().endOf('week'),
					});
					setIsShowDateRange(true);
					break;
				case DateRangeType.SEMI_MONTH_1st:
					setDateFilter({
						fromDate: moment().startOf('month'),
						toDate: moment().startOf('month').add(14, 'day'),
					});
					setIsShowDateRange(true);
					break;
				case DateRangeType.SEMI_MONTH_2nd:
					setDateFilter({
						fromDate: moment().startOf('month').add(15, 'day'),
						toDate: moment().endOf('month'),
					});
					setIsShowDateRange(true);
					break;
				case DateRangeType.MONTHLY:
					setDateFilter({
						fromDate: moment().startOf('month'),
						toDate: moment().endOf('month'),
					});
					setIsShowDateRange(true);
					break;
				default:
					setIsShowDateRange(true);
					break;
			}
			clearTimeout(timeOut);
		}, 50);
	}, [selectedDateRangeType]);

	const getReport = async (request: IPayrollRequest) => {
		setIsLoading(true);
		try {
			const result = (await Promise.all([
				ReportApiService.getPayrollOverview(request),
				ReportApiService.getPayrollDetail(request),
			])) as unknown as [IApiResponse, IApiResponse];
			if (result.every((element) => element.succeeded)) {
				setReport(
					result.map((e) => e.data!) as unknown as [
						IPayrollOverview,
						IPayrollDetail
					]
				);
			} else {
				result.forEach((element) => {
					!element.succeeded && AlertHelper.showError(element);
					setReport(undefined);
				});
			}
		} catch (error) {
			console.error('Network error', error);
		} finally {
			setIsLoading(false);
		}
	};

	const BUTTONS = [
		{
			id: '1',
			title: t(translations.payrollDetailReport.overview),
			value: false,
		},
		{ id: '2', title: t(translations.payrollDetailReport.detail), value: true },
	];

	return (
		<LoadingProvider loading={isLoading} className={'h-full'}>
			<Container className="bg-white">
				<PayrollReportContext.Provider
					value={{ report: report, loading: isLoading, isDetail: isDetail }}
				>
					<div className="h-full">
						<div className="grid grid-cols-5 gap-4">
							<div className="col-span-3">
								<div className="grid grid-cols-4 gap-4 mb-4">
									<Button
										title={
											selectedStaff
												? `${selectedStaff?.firstName || ''} ${
														selectedStaff?.lastName || ''
												  }`
												: t(translations.mainBooking.selectStylist)
										}
										small
										onClick={() => setIsShowStaffPicker(true)}
										white
										className="border-solid border-primary"
									/>

									<Select<(typeof DATE_RANGE_TYPES)[0]>
										options={DATE_RANGE_TYPES}
										keyField="id"
										titleField="title"
										fullWidth
										onChange={setSelectedDateRangeType}
										value={selectedDateRangeType}
									/>
									{isShowDateRange && (
										<DateRangePicker
											onSelectDateRange={setDateFilter}
											format={'MMM Do, YYYY'}
											disabled={
												isLoading ||
												selectedDateRangeType.value !== DateRangeType.DATE_RANGE
											}
											selectedDateRange={dateFilter}
										/>
									)}
								</div>
								{!isLoading && !_.isEmpty(report) && (
									<>
										<h2 className={clsxm('text-3xl my-4 text-ERROR')}>
											{report![0].employeeName || ''}
										</h2>
										<div>
											<h4 className={clsxm('my-4')}>
												{getDateString(
													moment(report && report[0].fromDate),
													moment(report && report[0].toDate)
												)}
											</h4>
										</div>
										<ButtonGroup<(typeof BUTTONS)[0]>
											buttons={BUTTONS}
											titleField="title"
											valueField="value"
											onChange={(value) => setIsDetail(value.value)}
											value={BUTTONS.find((e) => e.value === isDetail)}
											selectedId={BUTTONS.find((e) => e.value === isDetail)!.id}
										/>
									</>
								)}
								{!isLoading && !_.isEmpty(report) && (
									<>
										<DetailTable />
										<div className="grid grid-cols-2 gap-4 h-64 overflow-hidden">
											<FeeAndPayment />
											<ServedServices />
										</div>
									</>
								)}
							</div>
							<div className="col-span-2">
								{!isLoading && !_.isEmpty(report) && <ReportRollView />}
							</div>
						</div>
					</div>
				</PayrollReportContext.Provider>
				<StaffPickerModal
					selectedStaff={selectedStaff}
					onSelectStaff={(staff) => {
						setSelectedStaff(staff as IEmployee);
						setIsShowStaffPicker(false);
					}}
					visible={isShowStaffPicker}
					onCancel={() => setIsShowStaffPicker(false)}
				/>
			</Container>
		</LoadingProvider>
	);
};
