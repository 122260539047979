import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
} & RouteProps;

function PrivateRoute({ isAuthenticated, children, ...rest }: ProtectedRouteProps) {
  return isAuthenticated ? <Route {...rest} /> : <Redirect to="/login" />;
}

export default PrivateRoute;
