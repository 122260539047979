import { Modal } from 'components/atoms';
import {
	INumPadProps,
	INumPadRef,
	NumPad,
} from 'components/molecules/ModalNumPad/NumPad';
import { LoadingProvider } from 'contexts/LoadingContext';
import React, {
	forwardRef,
	ReactNode,
	Ref,
	useImperativeHandle,
	useRef,
} from 'react';

interface IModalNumPadProps extends INumPadProps {
	title?: string;
	onCancel?: () => void;
	children?: ReactNode;
	showNumpad?: boolean;
	canConfirm?: boolean;
	loading?: boolean;
}

export const ModalNumPad = forwardRef(
	(props: IModalNumPadProps, ref: Ref<INumPadRef>) => {
		const numpadRef = useRef<INumPadRef>();
		useImperativeHandle(ref, () => ({
			setText(text: string) {
				if (numpadRef) {
					numpadRef.current?.setText(text);
				}
			},
		}));
		return (
			<Modal
				isOpen={true}
				onCancel={() => props.onCancel && props.onCancel()}
				title={props.title}
				className={'w-[600px]'}
				content={
					<LoadingProvider
						className="w-full flex flex-col"
						loading={props.loading}
					>
						<div className="w-full flex flex-1 flex-col">
							<div className="flex-1">{props.children}</div>
							{props.showNumpad && (
								<div id="numpad" className="flex flex-col justify-end">
									<NumPad
										ref={(x) => {
											if (x) {
												numpadRef.current = x;
											}
										}}
										{...props}
									/>
								</div>
							)}
						</div>
					</LoadingProvider>
				}
			/>
		);
	}
);
