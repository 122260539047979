import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { BookingActions, CheckoutActions } from '../actions';
import _ from 'lodash';
import { ITip } from 'models/ITip';
import { IGiftCard, IBooking, IBookingDetail } from 'models';
import { IBilling } from 'models/IBilling';
import { DiscounterType, PaymentType, TipType } from 'utils/Consts';
import { IPaymentType } from 'models/ITransaction';
import { IPromotion } from 'models/IPromotion';
export interface ICheckoutReducer {
	tip?: ITip;
	tipType: TipType;
	discount?: number;
	discountPercent?: number;
	discounterType?: DiscounterType;
	listGiftCard: Partial<IGiftCard>[];
	listBookingCombine: IBooking[];
	listBilling: Partial<IBilling>[];
	listPaymentType: Partial<IPaymentType>[];
	promotion?: Partial<IPromotion>;
}

const initialState: ICheckoutReducer = {
	tip: {},
	tipType: TipType.AUTO,
	listGiftCard: [],
	listBookingCombine: [],
	listBilling: [],
	listPaymentType: [],
};

function addTipLocal(state: ICheckoutReducer, action: PayloadAction<ITip>) {
	state.tip = action.payload;
}
function addTipTypeLocal(
	state: ICheckoutReducer,
	action: PayloadAction<TipType>
) {
	state.tipType = action.payload;
}
function addDiscount(
	state: ICheckoutReducer,
	action: PayloadAction<{
		discount: number;
		discountPercent: number;
		discounterType: DiscounterType;
	}>
) {
	state.discount = action.payload.discount;
	state.discountPercent = action.payload.discountPercent;
	state.discounterType = action.payload.discounterType;
}

function reset(state: ICheckoutReducer) {
	state.tip = undefined;
	state.discount = undefined;
	state.discountPercent = undefined;
	state.listGiftCard = [];
	state.listBookingCombine = [];
	state.listBilling = [];
	state.listPaymentType = [];
}

const addGiftCardSuccess = (
	state: ICheckoutReducer,
	action: PayloadAction<Partial<IGiftCard>>
) => {
	const list = Array.from(state.listGiftCard);
	_.remove(list, (x) => x.id === action.payload.id);
	state.listGiftCard = [...list, action.payload];
};
const deleteGiftCardSuccess = (
	state: ICheckoutReducer,
	action: PayloadAction<string>
) => {
	state.listGiftCard = _.filter(
		state.listGiftCard,
		(e) => e.id !== action.payload
	);
};
const addPaymentType = (
	state: ICheckoutReducer,
	action: PayloadAction<Partial<IPaymentType>>
) => {
	let list = Array.from(state.listPaymentType);
	if (
		action.payload.paymentType === PaymentType.GIFT_CARD &&
		!list.map((x) => x.giftCode).includes(action.payload.giftCode)
	) {
		list.push(action.payload!);
	} else {
		list = state.listPaymentType.filter(
			(x) => x.paymentType !== action.payload.paymentType
		);
		list.push(action.payload!);
	}
	state.listPaymentType = list;
};
const deletePaymentType = (
	state: ICheckoutReducer,
	action: PayloadAction<{
		paymentType: number;
		giftCode?: string;
	}>
) => {
	let list = Array.from(state.listPaymentType);

	if (
		list.map((x) => x.paymentType).includes(action.payload.paymentType) &&
		_.isEmpty(action.payload.giftCode)
	) {
		list = state.listPaymentType.filter(
			(x) => x.paymentType !== action.payload.paymentType
		);
	} else {
		list = state.listPaymentType.filter(
			(x) => x.giftCode !== action.payload.giftCode
		);
	}
	state.listPaymentType = list;
};
const removeAllPaymentType = (state: ICheckoutReducer) => {
	state.listPaymentType = [];
};
const removeDiscount = (
	state: ICheckoutReducer,
	action: PayloadAction<any>
) => {
	state.discount = undefined;
	state.discountPercent = undefined;
};
const deleteExtraAmount = (
	state: ICheckoutReducer,
	action: PayloadAction<{
		billIndex: number;
		bookingItemIndex: number;
	}>
) => {
	state.discount = undefined;
	state.discountPercent = undefined;
	//update to list booking
	const listBooking = state.listBookingCombine;
	let bookingDetail =
		listBooking[action.payload.billIndex].bookingDetails[
		action.payload.bookingItemIndex
		];
	bookingDetail.extraAmount = 0;
	bookingDetail.note = '';
	listBooking[action.payload.billIndex].bookingDetails[
		action.payload.bookingItemIndex
	] = bookingDetail;
	state.listBookingCombine = listBooking;
	//update to list billing
	let listBill = Array.from(state.listBilling);
	let billDetail =
		listBill[action.payload.billIndex].billDetails![
		action.payload.bookingItemIndex
		];
	billDetail.extraAmount = 0;
	billDetail.note = '';
	listBill[action.payload.billIndex].billDetails![
		action.payload.bookingItemIndex
	] = billDetail;
	state.listBilling = listBill;
};

const addExtraAmount = (
	state: ICheckoutReducer,
	action: PayloadAction<{
		billIndex: number;
		bookingItemIndex: number;
		amount: number;
		note: string;
	}>
) => {
	state.discount = undefined;
	state.discountPercent = undefined;
	//update to list booking
	const listBooking = state.listBookingCombine;
	let bookingDetail =
		listBooking[action.payload.billIndex].bookingDetails[
		action.payload.bookingItemIndex
		];
	bookingDetail.extraAmount = action.payload.amount;
	bookingDetail.note = action.payload.note;
	listBooking[action.payload.billIndex].bookingDetails[
		action.payload.bookingItemIndex
	] = bookingDetail;

	state.listBookingCombine = listBooking;

	//update to list billing
	let listBill = Array.from(state.listBilling);
	let billDetail =
		listBill[action.payload.billIndex].billDetails![
		action.payload.bookingItemIndex
		];
	billDetail.extraAmount = action.payload.amount;
	billDetail.note = action.payload.note;
	listBill[action.payload.billIndex].billDetails![
		action.payload.bookingItemIndex
	] = billDetail;
	state.listBilling = listBill;
};

const addBookingCombine = (
	state: ICheckoutReducer,
	action: PayloadAction<IBooking>
) => {
	let booking = Array.from(state.listBookingCombine!);
	booking.push(action.payload);
	state.listBookingCombine = booking;
	state.discount = undefined;
	state.discountPercent = undefined;
};

const updateBookingToListCombine = (
	state: ICheckoutReducer,
	action: PayloadAction<IBooking>
) => {
	const index = _.findIndex(
		state.listBookingCombine,
		(x) => x.id === action.payload.id
	);
	const listBooking = Array.from(state.listBookingCombine);
	listBooking.splice(index, 1, action.payload);
	state.listBookingCombine = listBooking;
};

const deleteBookingCombine = (
	state: ICheckoutReducer,
	action: PayloadAction<string>
) => {
	_.remove(
		state.listBookingCombine,
		(booking) => booking.id === action.payload
	);

	state.listBookingCombine = Array.from(state.listBookingCombine).filter(
		(x) => x.id !== action.payload
	);
	state.listBilling = Array.from(state.listBilling).filter(
		(x) => x.bookingId !== action.payload
	);
	state.discount = undefined;
	state.discountPercent = undefined;
};

const createBillSuccess = (
	state: ICheckoutReducer,
	action: PayloadAction<Partial<IBilling>>
) => {
	let billing = Array.from(state.listBilling!);
	billing.push(action.payload);

	state.listBilling = billing;
};

const applyPromotion = (
	state: ICheckoutReducer,
	action: PayloadAction<Partial<IPromotion> | undefined>
) => {
	state.promotion = action.payload;
};

const updatePromotionDiscount = (
	state: ICheckoutReducer,
	action: PayloadAction<{ id: string, promotionDiscount: number }[]>
) => {
	let newListBookingCombine = Array.from(state.listBookingCombine)
	newListBookingCombine.forEach(x => {
		x.bookingDetails.forEach(y => {
			action.payload.forEach((z) => {
				if (z.id === y.id) {
					y.promotionDiscount = z.promotionDiscount
				}
			})
		})
	})
	state.listBookingCombine = newListBookingCombine
};

const BranchReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(CheckoutActions.addTipLocal.request, addTipLocal)
		.addCase(CheckoutActions.addDiscount.request, addDiscount)
		.addCase(CheckoutActions.addToListGiftCard.request, addGiftCardSuccess)
		.addCase(CheckoutActions.deleteGiftCard.success, deleteGiftCardSuccess)
		.addCase(CheckoutActions.resetAll.request, reset)
		.addCase(BookingActions.addExtraAmount.request, removeDiscount)
		.addCase(CheckoutActions.addPaymentType.request, addPaymentType)
		.addCase(CheckoutActions.deletePaymentType.request, deletePaymentType)
		.addCase(CheckoutActions.removeAllPaymentType.request, removeAllPaymentType)
		.addCase(CheckoutActions.addBookingCombine.request, addBookingCombine)
		.addCase(CheckoutActions.createBill.success, createBillSuccess)
		.addCase(CheckoutActions.deleteBookingCombine.request, deleteBookingCombine)
		.addCase(CheckoutActions.addExtraAmount.request, addExtraAmount)
		.addCase(CheckoutActions.addTipTypeLocal.request, addTipTypeLocal)
		.addCase(CheckoutActions.deleteExtraAmount.request, deleteExtraAmount)
		.addCase(
			CheckoutActions.updateBookingToListCombine.request,
			updateBookingToListCombine
		)
		.addCase(CheckoutActions.applyPromotion.request, applyPromotion)
		.addCase(CheckoutActions.updatePromotionDiscount.request, updatePromotionDiscount)
);

export default BranchReducer;
