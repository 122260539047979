import { Avatar } from 'components/atoms/Avatar';
import { Tag } from 'components/atoms/Tag';
import { StringHelper } from 'helpers';
import { useBookingHook } from 'helpers/bookingHelper';

import React, { useEffect, useState } from 'react';
import { BookingStatus } from 'utils/Consts';
import BookingApiService from 'services/BookingApiService';
import { useHistory } from 'react-router-dom';
import { showAlert } from 'helpers/alertHelper';
import _ from 'lodash';
import { ConfirmModal, Icon } from 'components/atoms';
import { t, translations } from 'utils';
import { mdiAccount } from '@mdi/js';
export const CustomerInfo = () => {
	const { customer, currentBooking } = useBookingHook();
	const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
	const history = useHistory();

	if (!customer) {
		return null;
	}
	const buttons = [{ id: 'Remove From Booking' }, { id: 'Additional Booking' }];
	if (currentBooking) {
		buttons.push({ id: 'Cancel Booking' });
	}

	return (
		<div className="w-full bg-white rounded-l-lg mb-2 px-4 py-2 flex">
			<div>
				<Avatar
					small
					name={StringHelper.getShortName(
						`${customer.firstName || ''} ${customer.lastName || ''}`
					)}
				/>
			</div>
			<div className="ml-4 flex flex-col flex-1 ">
				<span className="p-0 font-semibold text-sm ">
					{`${customer.firstName || ''} ${customer.lastName || ''}`}
					{customer.newCustomer && (
						<Tag
							label={t(translations.bookingCustomerInfo.new)}
							className="p-2 bg-[#9CD892] text-xs"
						/>
					)}
				</span>
				<span className="p-0 text-sm ">{`${StringHelper.formatPhoneNumber(
					customer.phone
				)}`}</span>
			</div>
			<div
				onClick={() => {
					history.push(`/customer/details/${customer.id}`);
				}}
				className="flex gap-1 h-10 items-center bg-white justify-center border rounded-lg text-xs p-1 cursor-pointer active:animate-pop "
			>
				View Customer Info
				<Icon path={mdiAccount} />
			</div>
			<ConfirmModal
				visible={cancelConfirmModal}
				title={t(translations.delete)}
				subTitle={t(translations.cancelBookingConfirm)}
				onPositiveClick={async () => {
					const response = await BookingApiService.updateBookingStatus({
						id: currentBooking?.id!,
						status: BookingStatus.CANCELED,
					});
					setCancelConfirmModal(false);
					if (response.succeeded) {
						showAlert('Cancel booking successfully', 'success');
						_.delay(() => history.go(0), 500);
					}
				}}
				onNegativeClick={() => setCancelConfirmModal(false)}
				type="error"
			/>
		</div>
	);
};
