import { Card } from 'components/atoms';
import { CurrencyHelper } from 'helpers';
import { EPeriodType, ITopStaffs } from 'models';
import { useDashboardContext } from 'pages/Dashboard/context';
import React, { useEffect, useState } from 'react';
import { AdminDashBoardApiService } from 'services';
import { DateRangeType } from 'utils/Consts';

const convertPeriodType = (period: number): EPeriodType => {
	switch (period) {
		case DateRangeType.TODAY:
			return EPeriodType.DAY;
		case DateRangeType.MONTHLY:
			return EPeriodType.MONTH;
		case DateRangeType.WEEKLY:
			return EPeriodType.WEEK;
		case DateRangeType.SEMI_MONTH_1st:
			return EPeriodType.SEMI_MONTH;
		case DateRangeType.SEMI_MONTH_2nd:
			return EPeriodType.SEMI_MONTH;
		case DateRangeType.YESTERDAY:
			return EPeriodType.DAY;
		default:
			return EPeriodType.DATE_RANGE;
	}
};

export const TopStaffs = () => {
	const { timeRange, branch, period } = useDashboardContext();
	const [data, setData] = useState<ITopStaffs[]>();
	const fetchData = async () => {
		const response = await AdminDashBoardApiService.getTopStaffs({
			date: timeRange.toDate.format('YYYY-MM-DD'),
			periodType: convertPeriodType(period.value),
			pageNumber: 1,
			pageSize: 5,
			branchId: branch,
		});
		if (response.succeeded && response.data) {
			setData(response.data);
		}
	};
	useEffect(() => {
		fetchData();
	}, [timeRange, branch]);
	return (
		<Card title="Top Staffs" className="col-span-1">
			{data?.map((x) => {
				return (
					<div className="flex justify-between px-4 py-2 my-2 bg-[#f4f3f8] rounded-lg">
						<strong>{x.stylistName}</strong>
						<strong>{CurrencyHelper.formatBalance(x.thisPeriod)}</strong>
					</div>
				);
			})}
		</Card>
	);
};
