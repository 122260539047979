/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import clsxm from '../../clsxs/clsxm';

interface IButtonGroupProps<T> {
	buttons: T[];
	icons?: ReactNode[];
	valueField: keyof T;
	titleField: keyof T;
	className?: string;
	buttonClassNames?: string[];
	value?: T;
	selectedId?: T[keyof T];
	onChange?: (value: T) => void;
	activeClassName?: string;
	small?: boolean;
	disabled?: boolean;
}

export const ButtonGroup = <T,>(props: IButtonGroupProps<T>) => {
	const [active, setActive] = useState(props.value);
	const [selected, setSelected] = useState(`${props.selectedId}`);
	useEffect(() => {
		setActive(props.value);
	}, [props.value]);
	useEffect(() => {
		setSelected(`${props.selectedId}`);
	}, [props.selectedId]);
	return (
		<div className={clsxm('btn-group', props.className)}>
			{props.buttons.map((item, index) => {
				return (
					<button
						// id={
						// 	props.buttonClassNames &&
						// 	props.buttonClassNames[index].split(/(?<=^\S+)\s/)[0]
						// }
						onClick={() => props.onChange && props.onChange(item)}
						key={`${item[props.valueField]}`}
						className={clsxm(
							'btn gap-2',
							'bg-white',
							'mt-0 mb-0',
							props.small && 'btn-sm',
							(active &&
								item[props.valueField] === active[props.valueField] &&
								!props.disabled) ||
								(selected &&
									item[props.valueField] === props.selectedId &&
									!props.disabled)
								? `btn-active main-gradient-background ${props.activeClassName}`
								: `btn-outline ${
										props.buttonClassNames &&
										(props.buttonClassNames[index] || props.buttonClassNames[0])
								  }`
						)}
						disabled={props.disabled}
					>
						{props.icons && props.icons[index]}
						{`${item[props.titleField]}`}
					</button>
				);
			})}
		</div>
	);
};

interface IButtonGroupMultiProps<T> {
	buttons: T[];
	icons?: ReactNode[];
	// valueField: keyof T;
	titleField: keyof T;
	keyField: keyof T;
	className?: string;
	buttonClassNames?: string[];
	// value?: T[];
	// selectedId?: T[keyof T][];
	onChange?: (activeValue: T[]) => void;
	activeClassName?: string;
	small?: boolean;
	disabled?: boolean;
	defaultActive?: T[];
	active?: T[];
	atLeastOneActive?: boolean;
}

export const ButtonGroupMulti = <T,>(props: IButtonGroupMultiProps<T>) => {
	const [active, setActive] = useState<T[]>(
		props.defaultActive || (!_.isEmpty(props.buttons) ? [props.buttons[0]] : [])
	);
	// useEffect(() => {
	// 	props.onChange && props.onChange(active);
	// }, [active]);

	useEffect(() => {
		setActive(props.active || []);
	}, [props.active]);

	const onButtonPress = (item: T) => {
		const activeIndex = active.findIndex(
			(e) => e[props.keyField] === item[props.keyField]
		);
		const newActive = active.slice();
		activeIndex !== -1
			? newActive.length === 1
				? !props.atLeastOneActive && newActive.splice(activeIndex, 1)
				: newActive.splice(activeIndex, 1)
			: newActive.push(item);
		setActive(newActive);
		props.onChange && props.onChange(newActive);
	};
	return (
		<div className={clsxm('btn-group', props.className)}>
			{props.buttons.map((item, index) => {
				return (
					<button
						// id={
						// 	props.buttonClassNames &&
						// 	props.buttonClassNames[index].split(/(?<=^\S+)\s/)[0]
						// }
						onClick={() => onButtonPress(item)}
						key={`${item[props.keyField]}`}
						className={clsxm(
							'btn gap-2',
							'bg-white',
							'mt-0 mb-0',
							props.small && 'btn-sm',
							active &&
								active.some(
									(e) => e[props.keyField] === item[props.keyField]
								) &&
								!props.disabled
								? `btn-active main-gradient-background ${props.activeClassName}`
								: `btn-outline ${
										props.buttonClassNames &&
										(props.buttonClassNames[index] || props.buttonClassNames[0])
								  }`
						)}
						disabled={props.disabled}
					>
						{props.icons && props.icons[index]}
						{`${item[props.titleField]}`}
					</button>
				);
			})}
		</div>
	);
};
