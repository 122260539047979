import { LoadingProvider } from 'contexts/LoadingContext';
import _ from 'lodash';
import { useEffect } from 'react';
import ShortlyApiService from 'services/ShortlyApiService';
import { BookingStatus } from 'utils/Consts';

export const RedirectShortly = (props: { isAppointment?: boolean }) => {
	const path = window.location.pathname.split('/');
	const openSmartSalon = () => {
		window.location.href = 'https://smartsalon.live/';
	};
	const redirectByCode = async () => {
		if (_.isEmpty(path) || _.isEmpty(path[1])) {
			openSmartSalon();
			return;
		}
		if (props.isAppointment && !_.isEmpty(path[2])) {
			const shopId = path[2];
			window.location.href = `https://${shopId}.${process.env.REACT_APP_PRODUCTION_URL}/appointment`;
		}
		const shortUrlByCodeResponse = await ShortlyApiService.getUrlByCode(
			path[1]
		);
		if (shortUrlByCodeResponse.succeeded && shortUrlByCodeResponse.data) {
			const data = shortUrlByCodeResponse.data;
			const type = data.type;
			if (type === 'Booking') {
				try {
					const booking = JSON.parse(`${data.objectData}`) as any;
					if (booking.PaymentStatus === 3) {
						if (booking.Id && booking.BranchId) {
							window.location.href = `https://${data.adminUrl}.${process.env.REACT_APP_PRODUCTION_URL}/rating/${booking.BranchId}/${booking.Id}`;
							// history.push(`/rating/${booking.branchId}/${booking.id}`);
						} else {
						}
					} else {
						if (booking.Status === BookingStatus.PENDING) {
							window.location.href = `https://${data.adminUrl}.${process.env.REACT_APP_PRODUCTION_URL}/customer-booking/${booking.Id}`;
						}
					}
				} catch (error) {
					window.location.href = 'https://smartsalon.live/';
				}
			}
		}
	};
	useEffect(() => {
		redirectByCode();
	}, []);
	return (
		<LoadingProvider loading>
			<div className="h-screen w-screen bg-white " />
		</LoadingProvider>
	);
};
