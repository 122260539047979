import { mdiClose, mdiCheck } from '@mdi/js';
import { Button, Description, Icon, Input, Modal } from 'components/atoms';
import { LoadingProvider } from 'contexts/LoadingContext';
import { CurrencyHelper } from 'helpers';
import { showAlert } from 'helpers/alertHelper';
import { t } from 'i18n-js';
import _ from 'lodash';
import { IGiftCard } from 'models';
import { Divider } from 'pages/FinancialReport/components/Divider';
import React, { useCallback, useEffect, useState } from 'react';
import GiftCardApiService from 'services/GiftCardApiService';
import { translations } from 'utils';

export const CheckGiftCardModal = (props: { onCancel: () => void }) => {
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState('');
	const [giftCard, setGiftCard] = useState<IGiftCard>();

	const onCheckGiftCard = useCallback(async () => {
		if (_.isEmpty(code)) {
			return;
		}
		setLoading(true);
		const response = await GiftCardApiService.getGiftCardByCode(`${code}`);
		setLoading(false);
		if (response.succeeded && response.data) {
			setGiftCard(response.data);
		} else {
			showAlert(t(translations.checkout.giftCardNotFound), 'error');
		}
	}, [code]);
	const onKeyPressListener = useCallback(
		(e: KeyboardEvent) => {
			const value = e.key || e.code;
			if (value === 'Enter') {
				onCheckGiftCard();
			}
		},
		[onCheckGiftCard]
	);
	useEffect(() => {
		window.addEventListener('keyup', onKeyPressListener);
		return () => {
			window.removeEventListener('keyup', onKeyPressListener);
		};
	}, [onKeyPressListener]);
	return (
		<Modal
			isOpen={true}
			onCancel={() => props.onCancel && props.onCancel()}
			title={'Check Gift Card'}
			className={'w-[600px]'}
			content={
				<LoadingProvider className="w-full flex flex-col" loading={loading}>
					<div className="w-full flex flex-1 flex-col p-4">
						<div className="flex-1">
							<div className="flex gap-2 items-end">
								<Input
									label={t(translations.checkout.giftCardCode)}
									value={code}
									onChange={(e) => setCode(e.target.value)}
									placeholder={t(translations.checkout.scanQrOrSwipe)}
									autoFocus
									renderAfter={
										<div className="flex">
											<Button
												onClick={() => setCode('')}
												title={t(translations.clear)}
												className="text-error h-[45px] min-h-[45px]"
												ghost
												small
												iconBefore={<Icon path={mdiClose} />}
											/>
										</div>
									}
								/>
								<Button
									onClick={() => onCheckGiftCard()}
									title={t(translations.checkout.checkCoupon)}
									primary={!_.isEmpty(code)}
									disabled={_.isEmpty(code)}
									small
									className=" h-[45px] min-h-[45px]"
									iconBefore={<Icon path={mdiCheck} />}
								/>
							</div>
							{!_.isEmpty(giftCard) && (
								<div className="flex w-full justify-between gap-4 mt-6">
									<div className="flex flex-col w-full">
										<Description
											title={`Total ${t(translations.checkout.amount)}`}
											value={`${CurrencyHelper.formatPrice(giftCard?.amount)}`}
										/>
										<Description
											title={'Used'}
											value={`-${CurrencyHelper.formatPrice(
												giftCard?.amount - giftCard.balance
											)}`}
											valueClassName={'text-error'}
										/>
										<Divider />

										<Description
											title={`Current ${t(translations.checkout.balance)}`}
											value={`${CurrencyHelper.formatPrice(giftCard?.balance)}`}
											valueClassName={'text-primary'}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</LoadingProvider>
			}
		/>
	);
};
