import { mdiClose, mdiPlay } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, ConfirmModal, Container, Icon } from 'components/atoms';
import { Header } from 'components/molecules/Header';
import { LoadingProvider } from 'contexts/LoadingContext';
import _ from 'lodash';
import { IPaymentType } from 'models/ITransaction';
import { checkoutTour } from 'models/TourGuide';
import { BillDetails } from 'pages/CheckOut/BillDetails';
import { CheckOutProvider, useCheckOutContext } from 'pages/CheckOut/context';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppConfigActions, BookingActions } from 'redux/actions';
import { adjustTip, welcome } from 'services/CloverApiService';
import { BillPayment } from './BillPayment';
import { useEffect, useState } from 'react';
import { WALKIN_CUSTOMER } from 'utils/Consts';
import { AlertHelper } from 'helpers';
import WebModal from 'react-modal';
import GiftCardApiService from 'services/GiftCardApiService';

const Body = () => {
	const { loading, setCustomer, giftCards, done } = useCheckOutContext();
	const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams<{ id: string }>();
	console.log(params);

	const [payWarningModal, setPayWarningModal] = useState(false);
	useEffect(() => {
		if (params.id === 'sale-giftcard') {
			setCustomer(WALKIN_CUSTOMER);
		}
	}, []);
	const onClose = async () => {
		// const cloverPayment = _.find(payments, (x) => x.isClover) as IPaymentType;
		// if (cloverPayment && !cloverPayment.final) {
		// 	try {
		// 		const response = await adjustTip(
		// 			`${cloverPayment.transactionReferenceNumber}`,
		// 			0
		// 		);
		// 		welcome();
		// 	} catch (error) {}
		// }

		if (!_.isEmpty(giftCards) && params.id === 'sale-giftcard' && !done) {
			setPayWarningModal(true);
			return;
		}
		dispatch(BookingActions.resetBooking.request());
		history.replace('/today');
	};

	const onDeleteGiftCard = () => {
		history.replace('/today');
		giftCards?.forEach((x) => {
			GiftCardApiService.deleteGiftCard(`${x.id}`);
		});
	};
	return (
		<Container className="px-0 h-screen overflow-hidden">
			<Header
				title="Check Out"
				showClose
				onClose={onClose}
				// extraLeft={
				// 	<div className="w-11/12 flex items-end justify-end my-auto ">
				// 		<Button
				// 			className={clsxm(
				// 				'btn-choose-booking-time bg-white btn-outline btn-primary capitalize font-medium'
				// 			)}
				// 			small
				// 			title={'Start Tour'}
				// 			onClick={onStartGuide}
				// 			iconAfter={<Icon path={mdiPlay} />}
				// 		/>
				// 	</div>
				// }
			/>

			<LoadingProvider
				loading={loading}
				className="grid h-full grid-cols-2 gap-1 mt-16 overflow-hidden "
			>
				<BillDetails />
				<BillPayment />
				<ConfirmModal
					title="Warning"
					type="warning"
					visible={payWarningModal}
					subTitle={'Gift card has not been paid. Do you want to exit?'}
					onPositiveClick={onDeleteGiftCard}
					onNegativeClick={() => setPayWarningModal(false)}
				/>
			</LoadingProvider>
		</Container>
	);
};

export const CheckOut = () => {
	const params = useParams<{ id: string }>();
	return (
		<CheckOutProvider billId={params.id}>
			<Body />
		</CheckOutProvider>
	);
};
