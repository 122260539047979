import { mdiPackageVariantClosedCheck } from '@mdi/js';
import { Button, Container, Icon } from 'components/atoms';
import Table, { EColumnType, IColumnProps } from 'components/atoms/Table';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { TDateRange } from 'components/molecules/ModalCalendar';
import { ICloseOutPeriod } from 'models';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t, translations } from 'utils';

export const CloseOutPeriod = () => {
	const [dateFilter, setDateFilter] = useState<TDateRange>({
		fromDate: moment().startOf('month'),
		toDate: moment().startOf('date'),
	});
	const dateCol: IColumnProps<ICloseOutPeriod> = {
		header: t(translations.closeOut.date),
		key: 'date',
		type: EColumnType.DATE,
	};
	const history = useHistory();
	return (
		<Container className="block h-full">
			<div className="w-full grid grid-cols-6">
				<DateRangePicker
					onSelectDateRange={setDateFilter}
					selectedDateRange={dateFilter}
				/>
				<div className="col-span-4" />
				<Button
					small
					title={t(translations.closeOut.title)}
					iconBefore={<Icon path={mdiPackageVariantClosedCheck} />}
					onClick={() => history.push('/close-out/new')}
				/>
			</div>
			<Table
				columns={[dateCol]}
				showIndex
				showSearch={false}
				queryUrl="/CloseOutPeriod/get-all-close-out-periods"
				onClickRow={(row) => history.push(`/close-out/edit?date=${row.date}`)}
				additionalParams={{
					fromDate: dateFilter.fromDate?.format('YYYY-MM-DD'),
					toDate: dateFilter.toDate?.format('YYYY-MM-DD'),
					orderBy: 'date desc'
				}}
			/>
		</Container>
	);
};
