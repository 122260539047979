import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { translations, _t, I18n, t } from 'utils';
import CustomerApiService from 'services/CustomerApiService';
import { AlertHelper, StringHelper } from 'helpers';
import { IApiResponse, IBookingDetail, IErrorResponse } from 'models';
import { convertToRawPhone } from 'helpers/StringHelper';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { CustomerBookingActions } from 'redux/actions';
import { useAppSelector } from 'helpers/hookHelpers';
import { Button } from 'components/atoms/Button';
import InputMask from 'react-input-mask';
import { TextArea } from 'components/atoms/TextArea';
import { useForm } from 'react-hook-form';
import { Input } from 'components/atoms/Input';
import PromotionApiService from 'services/PromotionApiService';
import {
	IApplyPromotionRequest,
	IApplyPromotionResponse,
	IGetAvailablePromotionsRequest,
	IPromotion,
} from 'models/IPromotion';
import { LoadingProvider } from 'contexts/LoadingContext';
import clsxm from 'clsxs/clsxm';
import { Description } from 'components/atoms';
import { formatBalance } from 'helpers/currencyHelper';
import { calculateTotalAmountBillDetails } from 'helpers/bookingHelper';
import { showAlert } from 'helpers/alertHelper';

type FormData = {
	name: string;
	email: string;
};

export const CustomerInfo = () => {
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const note = useSelector(
		(state: RootState) => state.CustomerBookingReducer.note
	);

	const branch = useAppSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const customer = useAppSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	const [status, setStatus] = useState(
		!_.isEmpty(customer) ? 'confirm' : 'phone'
	);

	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [tempCoupon, setTempCoupon] = useState<string>('');
	const [errorMsg, setErrorMsg] = useState<string>();

	const {
		register,
		handleSubmit,
		clearErrors,
		setValue,
		formState: { errors },
	} = useForm<FormData>();

	const [isEdit, setIsEdit] = useState(false);
	const [coupon, setCoupon] = useState<string>('');
	const booking = useAppSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [availablePromotions, setAvailablePromotions] = useState<IPromotion[]>(
		[]
	);
	const [currentPromotion, setCurrentPromotion] = useState<IPromotion>();

	const currrentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	useEffect(() => {
		if (customer) {
			getAvailablePromotions();
		}
	}, [customer]);

	const getCustomerByPhone = async () => {
		setIsLoading(true);
		await CustomerApiService.getCustomerByPhone(convertToRawPhone(phoneNumber)!)
			.then((res: any) => {
				if (res.succeeded) {
					if (!_.isEmpty(res.data)) {
						dispatch(CustomerBookingActions.updateCustomer.request(res.data));
						setStatus('confirm');
					} else {
						setStatus('name');
					}
				} else {
					const error = res as IErrorResponse;
					AlertHelper.showError(error);
				}
			})
			.catch((err) => {
				console.log(err);
			});
		setIsLoading(false);
	};
	const getAvailablePromotions = async () => {
		setLoading(true);
		const request: IGetAvailablePromotionsRequest = {
			date: moment.utc(moment()).format('MM-DD-YYYY HH:mm'),
			customerId: customer?.id!,
			branchId: booking?.branchId!,
			bookingInfos: booking?.bookingDetails?.map((x) => {
				return {
					itemId: x.itemId || '',
					price: _.sum([x.item?.price, x.extraAmount]),
				};
			}),
		};
		const res = await PromotionApiService.getAvailablePromotion(request);
		setLoading(false);
		const resData = res as IApiResponse<IPromotion[]>;
		if (resData.succeeded && !_.isEmpty(resData.data)) {
			setAvailablePromotions(resData.data!);
		} else {
			setAvailablePromotions([]);
		}
	};
	const addCustomer = async (values: any) => {
		const params = {
			code: `${moment().unix()}`,
			firstName: values.name,
			phone: convertToRawPhone(phoneNumber),
			phoneNumberConfirmed: !_.isEmpty(phoneNumber) ? true : false,
			email: values.email,
			shopId: shop?.id,
			status: 1,
			newCustomer: true,
		};
		await CustomerApiService.addCustomer(params)
			.then((res: any) => {
				if (res.succeeded) {
					setStatus('confirm');
					dispatch(CustomerBookingActions.updateCustomer.request(res.data));
					setIsLoading(false);
				} else {
					const error = res as IErrorResponse;
					AlertHelper.showError(error);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const editCustomer = async (values: any) => {
		const params = {
			id: customer?.id,
			code: `${moment().unix()}`,
			firstName: values.name,
			phone: convertToRawPhone(customer!.phone || ''),
			phoneNumberConfirmed: !_.isEmpty(customer?.phone) ? true : false,
			email: values.email,
			shopId: shop?.id,
			status: 1,
			newCustomer: true,
		};
		await CustomerApiService.editCustomer(params)
			.then((res: any) => {
				if (res.succeeded) {
					setStatus('confirm');
					dispatch(CustomerBookingActions.updateCustomer.request(res.data));
					setIsLoading(false);
				} else {
					const error = res as IErrorResponse;
					AlertHelper.showError(error);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handleCustomer = handleSubmit(async (data) => {
		setIsLoading(true);
		try {
			let res = (await CustomerApiService.getCustomerByPhone(
				convertToRawPhone(customer!.phone || phoneNumber)!
			)) as any;
			if (res.succeeded) {
				if (!_.isEmpty(res.data)) {
					if (
						res.data.phone === convertToRawPhone(customer!.phone || phoneNumber)
					) {
						editCustomer(data);
					}
				} else {
					addCustomer(data);
				}
			} else {
				const error = res as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
		setIsEdit(false);
	});

	const onSubmitCoupon = async () => {
		const couponResult = (await PromotionApiService.getPromotionByCouponCode({
			couponCode: tempCoupon,
			branchId: branch?.id || '',
			customerId: customer!.id!,
			date: booking!.date! as string,
			serviceIds:
				booking?.bookingDetails?.map((detail) => detail.itemId!) || [],
		})) as IApiResponse<IPromotion>;
		if (couponResult.succeeded) {
			const applyResult = (await PromotionApiService.applyPromotion({
				...booking!,
				applyPromotionDetails: [
					...booking!.bookingDetails!.map((element) => ({
						...element,
						stylistId:
							element.stylistId === 'Anyone' ? undefined : element.stylistId,
					})),
				] as any,
				couponCodes: `${booking?.couponCodes || ''}${
					booking?.couponCodes ? ',' : ''
				}${tempCoupon}`,
				customerId: customer?.id,
				branchId: branch?.id,
			})) as IApiResponse<IApplyPromotionResponse>;
			if (applyResult.succeeded) {
				const applyDetails = applyResult.data!.applyPromotionDetails!.map(
					(detail) => {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { id, stylistId, ..._rest } = detail as any;
						return _rest;
					}
				);
				const bookingDetails = booking?.bookingDetails!.map(
					(element, index) => ({
						...element,
						...applyDetails[index],
					})
				) as Partial<IBookingDetail>[];
				dispatch(
					CustomerBookingActions.updateBooking.request({
						...booking,
						...applyResult.data,
						bookingDetails: bookingDetails,
					})
				);
				setCoupon(tempCoupon);
				setErrorMsg('');
			} else {
				setErrorMsg((applyResult as IErrorResponse).message[0].Text);
			}
		} else {
			setErrorMsg((couponResult as IErrorResponse).message[0].Text);
		}
	};

	const CustomerPhone = () => {
		return (
			<div className="mb-[400px]">
				<div className="w-full mb-4 p-4 space-y-4 bg-white rounded-md">
					<h5 className="m-0 text-black font-semibold">
						{I18n.t(_t(translations.customerBooking.enterPhone))}
					</h5>
					<div className="w-1/2 pb-4">
						<InputMask
							className="input input-bordered w-full"
							mask="999-999-9999"
							placeholder={I18n.t(_t(translations.customerBooking.phoneNumber))}
							autoFocus={true}
							value={phoneNumber}
							onChange={(event) => setPhoneNumber(event.target.value)}
						/>
					</div>
				</div>
				<div className="flex flex-row justify-end">
					<div className="w-full flex justify-end">
						<Button
							small
							onClick={() => getCustomerByPhone()}
							disabled={!StringHelper.checkPhoneNumber(phoneNumber)}
							className="bg-white text-black border-black/20"
						>
							{I18n.t(_t(translations.next))}
						</Button>
					</div>
				</div>
			</div>
		);
	};

	const CustomerName = () => {
		return (
			<>
				<form
					onSubmit={handleCustomer}
					noValidate
					className="w-full p-4 bg-white rounded-md mb-5"
				>
					<Input
						label={I18n.t(_t(translations.customerBooking.yourName))}
						type="text"
						placeholder={I18n.t(_t(translations.customerBooking.enterName))}
						{...register('name', {
							required: I18n.t(_t(translations.customerBooking.nameRequire)),
						})}
						error={errors.name && errors.name.message}
						defaultValue={isEdit ? customer?.firstName : ''}
					/>
					<Input
						label={I18n.t(_t(translations.customerBooking.yourEmail))}
						type="email"
						placeholder={I18n.t(
							_t(translations.customerBooking.enterYourEmail)
						)}
						{...register('email', {
							pattern: {
								value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
								message: I18n.t(
									_t(translations.resetPassword.emailFormatMessage)
								),
							},
						})}
						error={errors.email && errors.email.message}
						defaultValue={isEdit ? customer?.email : ''}
					/>
				</form>
				<div className="w-full flex justify-end space-x-2">
					<Button
						small
						onClick={() => {
							dispatch(CustomerBookingActions.updateCustomer.request({}));
							setStatus('phone');
						}}
						className="bg-white text-black border-black/20 "
					>
						{I18n.t(_t(translations.back))}
					</Button>
					<Button
						small
						className="customer-booking-background border-0"
						onClick={() => handleCustomer()}
					>
						{I18n.t(_t(translations.next))}
					</Button>
				</div>
			</>
		);
	};

	const CustomerConfirm = () => {
		const endDate = (promotion: IPromotion) => {
			return !_.isEmpty(promotion.endDateUtc)
				? `- ${moment(promotion.endDateUtc).format('MM/DD/YYYY')}`
				: `- ${t(translations.checkout.promotionInfinity)}`;
		};

		const applyPromotion = async (promotion: IPromotion) => {
			console.log('đưaădă', promotion);

			const tax = _.sumBy(
				booking?.bookingDetails,
				(bookingDetail) =>
					(((bookingDetail.item?.price || 0) +
						(bookingDetail.extraAmount || 0) -
						(branch?.discountBeforeTax ? bookingDetail.discount || 0 : 0) -
						(branch?.discountBeforeTax
							? bookingDetail.promotionDiscount || 0
							: 0)) *
						(branch?.taxPercent || 0)) /
					100
			);

			//--------------------------------------------------------------------

			const data: IApplyPromotionRequest = {
				...booking,
				bookingDetails: undefined,
				totalAmount:
					calculateTotalAmountBillDetails(booking?.bookingDetails as any) + tax,
				customer: undefined,
				promotionIds: !_.isEmpty(promotion.coupons) ? undefined : promotion.id,
				couponCodes: !_.isEmpty(promotion?.coupons)
					? promotion?.coupons![0].couponCode
					: undefined,
				applyPromotionDetails: booking?.bookingDetails?.map((x) => {
					return {
						...x,
						stylistId: x.stylistId === 'Anyone' ? undefined : x.stylistId,
					};
				}),
				stylistId: undefined,
				branchId: currrentBranch?.id,
				customerId: customer?.id,
			};

			//---------------------------------------------------------------------

			const response = (await PromotionApiService.applyPromotion(
				data
			)) as IApiResponse<{
				applyPromotionDetails: {
					promotionDiscount: number;
					id: string;
					itemId: string;
				}[];
			}>;
			if (!_.isEmpty(response.message)) {
				showAlert(`${response.message[0].text}`, 'error');
				return;
			}
			if (response.succeeded && response.data) {
				setCurrentPromotion(promotion);

				let newBooking = Object.assign({}, booking);

				newBooking.promotionIds = promotion.id;
				newBooking.bookingDetails = booking?.bookingDetails?.map((x) => {
					return {
						...x,
						promotionDiscount:
							_.find(
								response.data?.applyPromotionDetails,
								(y) => y.itemId === x.itemId
							)?.promotionDiscount || 0,
					};
				});

				dispatch(CustomerBookingActions.updateBooking.request(newBooking));
			}
		};

		const onDeletePromotion = () => {
			setCurrentPromotion(undefined);
			let newBooking = Object.assign({}, booking);
			newBooking.promotionIds = '';
			newBooking.bookingDetails = booking?.bookingDetails?.map((x) => {
				return {
					...x,
					promotionDiscount: 0,
				};
			});

			dispatch(CustomerBookingActions.updateBooking.request(newBooking));
		};

		return (
			<div className="mb-[400px]">
				<div className="w-full p-4 bg-white rounded-md mb-5">
					<div className="flex flex-row justify-between">
						<div className="basis-1/2">
							<div className="space-y-2">
								<div>
									<span className="text-black">
										{I18n.t(_t(translations.customerBooking.customerName))}
									</span>
								</div>
								<div>
									<span className="text-black">
										{`${I18n.t(_t(translations.customerBooking.phoneNumber))}:`}
									</span>
								</div>
								<div>
									<span className="text-black">
										{I18n.t(_t(translations.customerBooking.email))}
									</span>
								</div>
							</div>
						</div>
						<div className="basis-1/2">
							<div className="w-full space-y-2 text-end">
								<h5 className="m-0 text-black font-semibold">
									{`${customer?.firstName} ${customer?.lastName}`}
								</h5>
								<h5 className="m-0 text-black font-semibold">
									{customer?.phone}
								</h5>
								<h5 className="m-0 text-black font-semibold">
									{customer?.email}
								</h5>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full p-4 bg-white rounded-md mb-5">
					<h5 className="m-0 text-black font-semibold">
						{I18n.t(_t(translations.customerBooking.note))}
					</h5>
					<TextArea
						value={note}
						rows={5}
						onChange={(e) =>
							dispatch(
								CustomerBookingActions.updateNote.request(e.target.value)
							)
						}
					></TextArea>
				</div>

				<div className="w-full p-4 bg-white rounded-md mb-5">
					<h5 className="m-0 text-black font-semibold">
						{I18n.t(_t(translations.customerBooking.couponCode))}
					</h5>
					<div className="px-2 flex flex-row gap-x-2">
						<Input
							value={tempCoupon}
							onChange={(e) => setTempCoupon(e.target.value)}
							error={errorMsg && errorMsg}
						></Input>
						<Button
							small
							onClick={() => {
								if (coupon) {
									dispatch(
										CustomerBookingActions.updateBooking.request({
											couponCodes: '',
											bookingDetails: booking!.bookingDetails!.map(
												(element) => ({
													...element,
													promotionDiscount: 0,
												})
											),
										})
									);
									setCoupon('');
									setTempCoupon('');
								} else {
									onSubmitCoupon();
								}
							}}
							className="bg-white text-black border-black/20"
						>
							{coupon
								? I18n.t(_t(translations.customerBooking.useCouponLater))
								: I18n.t(_t(translations.customerBooking.applyCoupon))}
						</Button>
					</div>
				</div>
				<div className="w-full p-4 bg-white rounded-md mb-5 h-min">
					<div className="flex flex-row justify-between align-middle mb-2">
						<h5 className="m-0 text-black font-semibold">Promotions</h5>
						{currentPromotion && (
							<Button
								className="bg-white text-black border-black/20"
								small
								onClick={onDeletePromotion}
							>
								Change Other
							</Button>
						)}
					</div>

					{currentPromotion && (
						<div>
							<li
								className={clsxm(
									'px-4 py-3 m-0 my-4 relative active:animate-pop',
									'duration-500 ease-in cursor-pointer border rounded-lg flex flex-col justify-between w-full'
								)}
							>
								<Description
									title={t(translations.checkout.promotion)}
									value={currentPromotion.name}
									valueClassName="text-PROCESSING"
								/>
								<Description
									title={t(translations.checkout.promotionType)}
									value={currentPromotion.promotionTypeCode}
								/>
								<Description
									title={t(translations.promotionDetail.discountAmount)}
									value={
										!currentPromotion.usePercentage
											? formatBalance(currentPromotion.discountAmount)
											: `${currentPromotion.discountPercentage}%`
									}
								/>
								<Description
									title={t(translations.billing.time)}
									value={`${moment(currentPromotion.startDateUtc).format(
										'MM/DD/YYYY'
									)} ${endDate(currentPromotion)}`}
								/>
								<Description
									title={t(translations.description)}
									value={currentPromotion.description}
									isHtml
								/>
							</li>
						</div>
					)}

					{_.isEmpty(currentPromotion) && availablePromotions ? (
						<div className="p-4 overflow-y-auto w-full h-[40vh] overflow-hidden ">
							<LoadingProvider loading={loading}>
								<ul className="bg-white mt-4 rounded-lg text-gray-900 list-none pl-0 ">
									{availablePromotions.map((x) => {
										return (
											<li
												onClick={() => {
													applyPromotion(x);
												}}
												className={clsxm(
													'px-4 py-3 m-0 my-4 relative active:animate-pop',
													'duration-500 ease-in cursor-pointer border rounded-lg flex flex-col justify-between w-full'
												)}
											>
												<Description
													title={t(translations.checkout.promotion)}
													value={x.name}
													valueClassName="text-PROCESSING"
												/>
												<Description
													title={t(translations.checkout.promotionType)}
													value={x.promotionTypeCode}
												/>
												<Description
													title={t(translations.promotionDetail.discountAmount)}
													value={
														!x.usePercentage
															? formatBalance(x.discountAmount)
															: `${x.discountPercentage}%`
													}
												/>
												<Description
													title={t(translations.billing.time)}
													value={`${moment(x.startDateUtc).format(
														'MM/DD/YYYY'
													)} ${endDate(x)}`}
												/>
												<Description
													title={t(translations.description)}
													value={x.description}
													isHtml
												/>
											</li>
										);
									})}
								</ul>
							</LoadingProvider>
						</div>
					) : null}
				</div>

				<div className="w-full flex justify-end space-x-2">
					<Button
						small
						className="bg-white text-black border-black/20"
						onClick={() => handelEdit()}
					>
						{I18n.t(_t(translations.edit))}
					</Button>
				</div>
			</div>
		);
	};

	const handelEdit = () => {
		setStatus('name');
		setIsEdit(true);
	};
	const renderSwitch = (status: string) => {
		switch (status) {
			case 'name':
				return CustomerName();
			case 'confirm':
				return CustomerConfirm();
			default:
				return CustomerPhone();
		}
	};
	return (
		<LoadingProvider loading={isLoading}>
			{renderSwitch(status)}
		</LoadingProvider>
	);
};
