import { IInputProps } from "components/atoms/Input";
import { MaskInput } from "components/atoms/MaskInput";
import _ from "lodash";
import moment, { Moment } from "moment";
import React from "react";
import { Controller, ControllerProps } from "react-hook-form";

interface IDateFormInput extends Partial<ControllerProps> {
  error?: string;
  label?: string;
  hint?: string;
  placeholder?: string;
  renderBefore?: React.ReactNode;
  renderAfter?: JSX.Element;
}

export const DateFormInput = (props: IDateFormInput) => {
  return (
    <Controller
      control={props.control}
      name={props.name || ""}
      rules={{
        ...props.rules,
        validate: (val) => {
          if (!_.isEmpty(val) && !moment(val, "MM/DD/YYYY").isValid()) {
            return "Please enter valid date";
          }
        },
      }}
      render={(inputProps) => <MaskInput {...props} {...inputProps.field} mask="99/99/9999" />}
    />
  );
};
