import React, { Component, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { I18n, _t, translations } from 'utils';
//import * as images from './images';
import { convertStringToUpperCaseFirstChar } from 'helpers/StringHelper';
import RatingApiService from 'services/RatingApiService';
import BranchApiService from 'services/BranchApiService';
import { IBranch } from 'models/IBranch';
import { showAlert, showError } from 'helpers/alertHelper';
import {
	Button,
	Card,
	ConfirmModal,
	Container,
	Icon,
	Input,
	TextArea,
} from 'components/atoms';
import { StringHelper, TimeHelper } from 'helpers';
import _, { findIndex, findLastIndex } from 'lodash';
import { mdiCheckCircle } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { ICreateRatingRequest } from 'models/RequestModels';
import { LoadingProvider } from 'contexts/LoadingContext';
import CustomerApiService from 'services/CustomerApiService';
import { convertToRawPhone } from 'helpers/StringHelper';
import moment from 'moment';
import BookingApiService from 'services/BookingApiService';
import { IBooking, IErrorResponse } from 'models';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { mdiCalendarMultipleCheck } from '@mdi/js';

interface IRatingProps {}

interface IBookingSuccess {
	booking: IBooking;
}
const Header = () => {
	return (
		<div className="flex items-center justify-center main-gradient-background w-screen fixed z-50 h-16">
			<h4 className="text-white uppercase m-0 text-2xl inline font-bold subpixel-antialiased">
				Customer Appointment
			</h4>
		</div>
	);
};

const SearchAppointment = (props: {
	onNext: (phoneNumber: string) => void;
}) => {
	const { onNext } = props;

	const [phoneNumber, setPhoneNumber] = useState('');

	return (
		<div className="flex flex-col items-center justify-center w-screen text-center">
			<div className="h-24" />
			<div className="h-4" />
			<div className="shadow-md rounded-lg w-fit m-4 p-4 lg:p-8">
				<div className="w-full mb-4 p-4 space-y-4 bg-white rounded-md">
					<h4 className="m-0 text-black font-semibold">
						{I18n.t(_t(translations.customerBooking.enterPhone))}
					</h4>
					<div className="w-full pb-4 flex flex-row justify-between">
						<InputMask
							className="input input-bordered w-full mx-2"
							mask="999-999-9999"
							placeholder={I18n.t(_t(translations.customerBooking.phoneNumber))}
							autoFocus={true}
							value={phoneNumber}
							onChange={(event) => setPhoneNumber(event.target.value)}
						/>
						<Button
							small
							onClick={() => onNext(phoneNumber)}
							className="bg-PROCESSING text-white border-black/20 mx-2"
						>
							{I18n.t(_t(translations.next))}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
const BookingInfo = (props: IBookingSuccess) => {
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);

	return (
		<div className="m-4">
			<div className="flex justify-between">
				<strong style={{ color: '#9F9F9F' }}>
					{I18n.t(_t(translations.bookingPage.shop))}
				</strong>
				<div>
					<span>{shop?.name}</span>
				</div>
			</div>
			<div className="flex justify-between">
				<strong style={{ color: '#9F9F9F' }}>
					{I18n.t(_t(translations.bookingPage.startTimeAt))}
				</strong>
				<div>
					<span>
						{TimeHelper.toTimeZone(props.booking.startTimeExpected).format(
							'MMMM Do YYYY, h:mm A'
						)}
					</span>
				</div>
			</div>{' '}
			<div className="flex justify-between">
				<strong style={{ color: '#9F9F9F' }}>
					{I18n.t(_t(translations.address))}
				</strong>
				<div>
					<span>{`${branch?.address?.street}, ${branch?.address?.city}, ${branch?.address?.state}`}</span>
				</div>
			</div>
			<div className="flex justify-between">
				<strong style={{ color: '#9F9F9F' }}>{'Services'}</strong>
				<div className="align-middle flex flex-col">
					{props.booking.bookingDetails.map((bookingDetail) => {
						return (
							<div className="justify-end">
								<p className="font-bold m-0 p-0 text-right">
									{bookingDetail.item?.name}
								</p>
								<p className="m-0 p-0">
									{_.isEmpty(bookingDetail.stylist)
										? `${bookingDetail.item?.duration} minutes`
										: `${bookingDetail.item?.duration} minutes with ${
												bookingDetail.stylist!.firstName
										  } ${bookingDetail.stylist!.lastName}`}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
const SearchBookingSuccess = (props: {
	bookingByCustomer: IBooking | undefined;
	isEmptyBooking?: boolean | undefined;
	handleCancel: () => void;
}) => {
	const history = useHistory();
	return (
		<>
			{!props.isEmptyBooking && props.bookingByCustomer ? (
				<div className="flex align-middle justify-center">
					<div>
						<div className="px-8 pt-24 pb-16 text-center">
							<div className="w-full flex justify-center">
								<Icon
									path={mdiCalendarMultipleCheck}
									size={3}
									color="#52c41a"
								/>
							</div>
							<div className="font-semibold text-2xl mt-8">
								<strong>
									{
										'Here is your appointment information. Thank you for choosing us.'
									}
								</strong>
							</div>
							<div className="mt-2">
								<strong>
									{
										'Here is your appointment information. Thank you for choosing us.'
									}
								</strong>
							</div>
						</div>
						<BookingInfo booking={props.bookingByCustomer} />
						<div className="flex justify-center mb-4 items-center">
							<Button
								onClick={() => props.handleCancel()}
								key="console"
								small
								className="btn-error mt-6 mx-2"
							>
								{'Cancel Booking'}
							</Button>
							<Button
								onClick={() =>
									history.push(
										`customer-booking/${
											props.bookingByCustomer && props.bookingByCustomer.id
										}`
									)
								}
								key="console"
								small
								className="btn-outline btn-primary mt-6 mx-2"
							>
								{'Edit booking'}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div className="flex align-middle justify-center">
					<div>
						<div className="px-8 pt-24 pb-16 text-center">
							<div className="w-full flex justify-center">
								<Icon
									path={mdiCalendarMultipleCheck}
									size={3}
									color="#52c41a"
								/>
							</div>
							<div className="font-semibold text-2xl mt-8">
								<strong>{'You are not having any appointment.'}</strong>
							</div>
							<div className="mt-2">
								<strong>{'Make a booking now'}</strong>
							</div>
						</div>
						<div className="flex justify-center mb-4 items-center">
							<Button
								onClick={() => history.push(`customer-booking`)}
								key="console"
								small
								className="btn-outline btn-primary mt-6 px-4 "
							>
								{'Booking Now'}
							</Button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const CustomerAppointment = (props: IRatingProps) => {
	const [booking, setBooking] = useState<IBooking>();
	const [isGetPhoneSuccess, setIsGetPhoneSuccess] = useState(false);
	const [isEmptyBooking, setIsEmptyBooking] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		return () => {
			setIsGetPhoneSuccess(false);
		};
	}, []);
	const onSearchBookingByCustomer = async (customerId: string) => {
		const response = await BookingApiService.getNextBookingByCustomer(
			customerId
		);
		if (response.succeeded) {
			if (!_.isEmpty(response.data)) {
				setBooking(response.data);
				setIsEmptyBooking(false);
			} else {
				setIsEmptyBooking(true);
			}
		} else {
			showError(response.errors);
			// return undefined;
		}
	};
	const onNext = async (phoneNumber: string) => {
		setLoading(true);
		const response = await CustomerApiService.getCustomerByPhone(
			convertToRawPhone(phoneNumber) || ''
		);
		if (response.succeeded && response.data) {
			await onSearchBookingByCustomer(response.data.id);
			setIsGetPhoneSuccess(true);
		} else {
			showAlert('Wrong phone number, input again', 'error');
			setIsGetPhoneSuccess(false);
			return undefined;
		}
		setLoading(false);
	};
	const onCancelBooking = async (booking: IBooking) => {
		setLoading(true);
		try {
			const params: IBooking = {
				...booking,
				status: 2,
			};
			const result = await BookingApiService.updatePendingBooking(params);
			setLoading(false);
			setShowDeleteModal(false);
			if (result.succeeded) {
				setIsEmptyBooking(true);
			} else {
				showAlert('Cancel booking fail, please try again', 'error');
			}
		} catch {}
	};
	return (
		<LoadingProvider loading={loading}>
			<div className="bg-white h-screen">
				<Header />
				{isGetPhoneSuccess ? (
					<SearchBookingSuccess
						bookingByCustomer={booking}
						isEmptyBooking={isEmptyBooking}
						handleCancel={() => setShowDeleteModal(true)}
					></SearchBookingSuccess>
				) : (
					<SearchAppointment onNext={onNext} />
				)}

				<ConfirmModal
					visible={showDeleteModal}
					title={I18n.t(_t(translations.cancel))}
					onPositiveClick={() => {
						booking && onCancelBooking(booking);
					}}
					onNegativeClick={() => setShowDeleteModal(false)}
					loading={loading}
					subTitle={'Do you want to cancel this booking?'}
				/>
			</div>
		</LoadingProvider>
	);
};
export default CustomerAppointment;
