export enum EEscCommandType {
  style,
  size,
  text,
  table,
  qrCode,
  barcode,
  drawLine,
  align,
  control,
  cashDraw,
  beep,
  feed,
  encode,
  font,
  cut,
}
