import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IHolidayModel } from 'models';
import { IPaginateResponse } from 'models/ResponseModels';
import { HolidayActions } from '../actions';

interface IHolidayState {
	paginateHoliday?: IPaginateResponse<IHolidayModel[]>;
	currentHoliday?: IHolidayModel;
}

const initialState: IHolidayState = {};

function getAllHolidaySuccess(
	state: IHolidayState,
	action: PayloadAction<IPaginateResponse<IHolidayModel[]>>
) {
	state.paginateHoliday = {
		...action.payload,
		data: action.payload.data,
	};
}
function clearHoliday(state: IHolidayState) {
	state.currentHoliday = undefined;
	state.paginateHoliday = undefined;
}
function selectHoliday(
	state: IHolidayState,
	action: PayloadAction<IHolidayModel>
) {
	state.currentHoliday = action.payload;
}
function getHolidayByIdSuccess(
	state: IHolidayState,
	action: PayloadAction<IHolidayModel>
) {
	state.currentHoliday = action.payload;
}

const HolidayReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(HolidayActions.getAllHoliday.success, getAllHolidaySuccess)
		.addCase(HolidayActions.clearHoliday.request, clearHoliday)
		.addCase(HolidayActions.selectHoliday.request, selectHoliday)
		.addCase(HolidayActions.getHolidayById.success, getHolidayByIdSuccess);
});

export default HolidayReducer;
