import clsxm from 'clsxs/clsxm';
import { Button, Card, ConfirmModal, Icon, Input } from 'components/atoms';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CloverActions } from 'redux/actions';
import { BaseApiService } from 'services/BaseApiService';
import { getListDevices, getToken } from 'services/CloverApiService';
import { t, translations } from 'utils';
import { IConfigBaseProps } from './IConfigBaseProps';
import { isPos } from 'helpers';
import BranchApiService from 'services/BranchApiService';
import { mdiCheck } from '@mdi/js';
import { Colors } from 'utils/Consts';

class CloverApi extends BaseApiService {}

interface ITaxConfigProps extends IConfigBaseProps {}

const DevicesTable = () => {
	const devices = useAppSelector((state) => state.CloverReducer.devices) || [];
	const dispatch = useDispatch();
	const deviceId = useAppSelector((state) => state.CloverReducer.deviceId);
	const [selected, setSelected] = useState<string | undefined>(undefined);
	const getDeviceName = (serial: string) => {
		const device = _.find(devices, (x) => x.serial === serial);
		if (device) {
			return `${device.productName || ''} - ${device.model || ''}`;
		}
		return '';
	};
	const onSetDefault = () => {
		dispatch(CloverActions.setCloverDeviceId.request(selected || ''));
		setTimeout(() => {
			setSelected(undefined);
		}, 200);
	};
	return (
		<div className="overflow-x-auto">
			<table className="table w-full">
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
						<th>Code</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{devices.map((x, index) => {
						return (
							<tr
								className="cursor-pointer"
								onClick={() => setSelected(x.serial)}
							>
								<th>{index + 1}</th>
								<td>{getDeviceName(x.serial)}</td>
								<td>{x.serial}</td>
								<td>
									{x.serial === deviceId ? (
										<Icon path={mdiCheck} color={Colors.CHECKED_IN} />
									) : (
										<></>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<ConfirmModal
				onPositiveClick={() => onSetDefault()}
				onClose={() => setSelected(undefined)}
				visible={!_.isEmpty(selected)}
				onNegativeClick={() => setSelected(undefined)}
				title={'Select default Clover device'}
				subTitle={`Are you sure want to set\n ${getDeviceName(
					selected || ''
				)} - ${selected} \n as default Clover device`}
			/>
		</div>
	);
};
export const CloverConfigs = ({ isLoading }: ITaxConfigProps) => {
	const { merchantId, code, accessToken } = useAppSelector(
		(state) => state.CloverReducer
	);
	const shop = useAppSelector((state) => state.ShopReducer.shop);
	const currentBranchId = useAppSelector(
		(state) => state.BranchReducer.currentBranch?.id
	);
	const dispatch = useDispatch(); 
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as {
		merchantId?: string;
		code?: string;
	};
	const getDevices = async (merchant = merchantId) => {
		const devicesResponse = await getListDevices(`${merchant}`);
		if (devicesResponse.status === 200 && devicesResponse.data) {
			const listDevices = devicesResponse.data.elements;
			if (!_.isEmpty(listDevices)) {
				dispatch(CloverActions.setCloverDevices.request(listDevices));
			}
		}
	};
	const getOAuth = async (merchantId: string, code: string) => {
		const response = await getToken(code);
		if (response.data && response.data) {
			dispatch(
				CloverActions.setCloverAccessToken.request(response.data.accessToken)
			);
			setTimeout(async () => {
				getDevices(merchantId);
			}, 200);
		}
	};
	useEffect(() => {
		if (!_.isEmpty(queries.merchantId) && !_.isEmpty(queries.code)) {
			dispatch(
				CloverActions.setCloverConfig.request({
					merchantId: `${queries.merchantId}`,
					code: `${queries.code}`,
				})
			);
			BranchApiService.saveClover({
				id: `${currentBranchId}`,
				cloverMerchantId: `${queries.merchantId}`,
				cloverDeviceIds: '',
			});
			getOAuth(`${queries.merchantId}`, `${queries.code}`);
		}
	}, [location]);
	return (
		<Card
			title={t(translations.branchSetting.clover)}
			description={t(translations.branchSetting.subClover)}
			accordion={true}		
		>
			<div className={clsxm('grid grid-cols-4 gap-8 content-end')}>
				{/* <div className={clsxm("grid grid-cols-4 gap-x-8")}> */}
				<Input
					value={merchantId === 'null' ? '' : merchantId}
					label={t(translations.branchSetting.merchantId)}
					disabled
				/>
				{/* <Input
					label={t(translations.branchSetting.deviceId)}
					hint={
						'Open your clover device, choose Setup -> Devices, get the ID with 13 characters'
					}
				/> */}
				<div>
					{!isPos() && (
						<Button
							small
							onClick={() => {
								dispatch(CloverActions.reset.request());

								setTimeout(() => {
									window.location.replace(
										`${process.env.REACT_APP_CLOVER_API}/oauth/authorize?client_id=${process.env.REACT_APP_CLOVER_APP_ID}&redirect_uri=${process.env.REACT_APP_INTEGRATION_URL}/clover/integration/${shop?.adminUrl}/${currentBranchId}`
									);
								}, 500);
							}}
							labelSpacing
							title={
								_.isEmpty(accessToken) || accessToken === 'null'
									? 'Integrate with Clover'
									: 'Integrated'
							}
							primary={_.isEmpty(accessToken) || accessToken === 'null'}
						/>
					)}
				</div>
				{!_.isEmpty(accessToken) && accessToken !== 'null' && (
					<div className="col-span-2 flex items-end justify-end">
						<Button
							small
							onClick={() => {
								getDevices();
							}}
							className="btn-outline"
							labelSpacing
							title="Get Devices"
						/>
					</div>
				)}
				{/* </div> */}
			</div>
			<DevicesTable />
		</Card>
	);
};
