import { IGallery } from 'models';
import { BaseApiService } from 'services/BaseApiService';
import {
	IGetGalleryRequest,
	IAddGalleryRequest,
	IGetPictureRequest,
	ICreateThemeShopContent,
	ICreateThemeShopService,
} from 'models/RequestModels';
import configureStore from 'redux/configuration/configureStore';

class GalleryApiService extends BaseApiService {
	public getAllGallery = (params: IGetGalleryRequest) => {
		return this.get<IGallery>(`/Gallery/get-all-galleries/`, {
			params,
		});
	};

	public addGallery = (addGalleryRequest: IAddGalleryRequest) => {
		return this.post<IAddGalleryRequest>(
			`/Gallery/create-gallery`,
			addGalleryRequest
		);
	};

	public updateGallery = (addGalleryRequest: IAddGalleryRequest) => {
		return this.put<IAddGalleryRequest>(
			`/Gallery/update-gallery`,
			addGalleryRequest
		);
	};

	public deleteGallery = (id: any) => {
		return this.delete<any>(`/Gallery/delete-gallery/${id}`);
	};

	public getThemeShop = () => {
		const store = configureStore().store;
		const id = store.getState().ShopReducer.shop?.id!;
		return this.get<any>(`/LandingPageCMS/get-themeshopcontent-by-id/${id}`);
	};

	public getAllPicture = (params: IGetPictureRequest) => {
		return this.get<any>(`/Picture/get-all-pictures/`, {
			params,
		});
	};

	public getThemeShopService = (additionalParams?: any) => {
		const params = {
			pageSize: 1000,
			...additionalParams,
		};
		return this.get<any>(`/LandingPageCMS/get-themeShop-services`, {
			params,
		});
	};

	public deletePicture = (id: any) => {
		return this.delete<any>(`/Picture/delete-picture/${id}`);
	};

	public updateThemeShopContent = (params: any) => {
		const store = configureStore().store;
		const dataPost: ICreateThemeShopContent[] = [
			{
				id: params.id,
				type: params.type,
				title: params.title,
				subTitle: params.subTitle,
				description: params.description,
				url: params.url,
				priority: params.priority,
				shopId: store.getState().ShopReducer.shop?.id!,
			},
		];
		return this.put<any>(`/LandingPageCMS/update-themeshopcontent`, {
			themeShopContentList: dataPost,
		});
	};

	public updateThemeShopService = (params: any) => {
		const store = configureStore().store;
		params.map((a: any) => {
			a.shopId = store.getState().ShopReducer.shop?.id!;
		});
		return this.put<any>(`/LandingPageCMS/update-themeshopservice`, {
			themeShopServiceList: params,
		});
	};
}

export default new GalleryApiService();
