import {
	mdiArrowLeft,
	mdiArrowRight,
	mdiChevronLeft,
	mdiChevronRight,
	mdiPlus,
} from '@mdi/js';
import { Button, Icon } from 'components/atoms';
import { Avatar } from 'components/atoms/Avatar';
import { Select } from 'components/atoms/Select';
import { BookingTypeIcon } from 'components/molecules/BookingTypeIcon';
import { DatePicker } from 'components/molecules/DatePicker';
import { StatusTag } from 'components/molecules/StatusTag';
import { LoadingProvider } from 'contexts/LoadingContext';
import { BookingHelper, ColorHelper, TimeHelper } from 'helpers';
import { showAlert } from 'helpers/alertHelper';
import { EventType, IEventCalendar } from 'helpers/calendarHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import I18n from 'i18n-js';
import _ from 'lodash';
import { IEmployee } from 'models';
import { WorkingHour } from 'models/IBranch';
import moment, { Moment } from 'moment';
import momentTz from 'moment-timezone';
import { CalendarProvider, useCalendarContext } from 'pages/Calendar/context';
import qs from 'qs';
import { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import {
	Calendar,
	CalendarProps,
	ResourceHeaderProps,
	momentLocalizer,
} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { useHistory, useLocation } from 'react-router-dom';
import { BookingActions } from 'redux/actions';
import { numberOfBookingOfEmployee } from 'redux/selectors/employee';
import { _t, translations } from 'utils';
import { BookingStatus } from 'utils/Consts';
let firstUseScreen = true;

const DragAndDropCalendar = withDragAndDrop<IEventCalendar, Partial<IEmployee>>(
	Calendar as ComponentType<CalendarProps<IEventCalendar, Partial<IEmployee>>>
);
interface ISlotInfo {
	value?: Date;
	resource?: string;
}

const Header = () => {
	const { stylist, setDate, date, stylists, setStylist } = useCalendarContext();
	const history = useHistory();
	const listStylist = useMemo(
		() =>
			[{ id: 'all-employee', name: 'All Staffs' }].concat(
				stylists.map((x) => {
					return {
						id: x.id,
						name: `${x.firstName || ''} ${x.lastName || ''}`,
					};
				})
			),
		[stylists]
	);
	const queryDate = useLocation().search;
	const queries = qs.parse(queryDate, {
		ignoreQueryPrefix: true,
	}) as {
		date: string;
	};

	useEffect(() => {
		if (!_.isEmpty(queries)) {
			const dateString = queries.date;
			setDate && setDate(moment(dateString, 'MM/DD/YYYY'));
		}
	}, [queryDate]);

	const onChangeDate = (date: Moment) => {
		const params = new URLSearchParams();
		params.append('date', date.format('MM/DD/YYYY'));

		history.push({ search: params.toString() });
	};

	return (
		<div className="w-full grid grid-cols-3 p-4 items-center">
			<div id="test-joy">
				<Select<{ id: string; name: string }>
					options={listStylist}
					onChange={(value) => {
						setStylist && setStylist(value.id);
					}}
					valueId={stylist}
					title="All Staffs"
					keyField={'id'}
					titleField={'name'}
					className="min-w-[200px]"
				/>
			</div>
			<div className="flex justify-center gap-2" id="test-joy2">
				<div
					onClick={() => onChangeDate(moment(date).subtract(1, 'day'))}
					className="btn btn-primary btn-outline bg-white rounded-full"
				>
					<Icon path={mdiChevronLeft} />
				</div>
				<div className="flex gap-1 justify-center">
					<Button
						onClick={() => onChangeDate(moment())}
						small
						title="Today"
						className="btn btn-primary btn-outline bg-white rounded-full"
					/>
					<DatePicker
						onSelectDate={(date) => onChangeDate(date)}
						selectedDate={date}
					/>
				</div>
				<div
					onClick={() => onChangeDate(moment(date).add(1, 'day'))}
					className="btn btn-primary btn-outline bg-white rounded-full"
				>
					<Icon path={mdiChevronRight} />
				</div>
			</div>
			<div className="flex justify-end gap-2">
				{/* <Button
					iconBefore={<Icon path={mdiSunClock} />}
					small
					title="New Holiday"
					className="btn-outline btn-primary bg-white"
					onClick={() => history.push('/holiday/new/staff')}
					id="test-joy6"
				/> */}

				<Button
					onClick={() => {
						const query = qs.stringify({
							date: date.format('MM-DD-YYYY'),
						});

						history.push(`/booking/new?${query}`);
					}}
					iconBefore={<Icon path={mdiPlus} />}
					small
					className="bg-PENDING border-none text-black"
					title="New Appointment"
					id="test-joy5"
				/>
			</div>
		</div>
	);
};

const StaffHeader = (props: { resource: ResourceHeaderProps }) => {
	const { resource } = props;
	const employee = resource.resource as IEmployee;
	const numberOfTurns = useAppSelector((state) =>
		numberOfBookingOfEmployee(state.BookingReducer.bookings, employee.id)
	);
	const isAnyone = employee.id === 'Anyone';
	const turnString = !isAnyone ? `(${numberOfTurns})` : '';
	return (
		<>
			<Avatar
				source={employee.imageUrl}
				small
				color={isAnyone ? 'white' : employee.color}
				className={'text-xs'}
				name={!isAnyone ? resource.label?.toString()! : ''}
			/>
			<h6>{`${resource.label?.toString()!}${turnString}`}</h6>
		</>
	);
};

const Event = (props: { event: IEventCalendar }) => {
	const { event } = props;
	const timeString = `${moment(event.start).format('h:mm')}-${moment(
		event.end
	).format('h:mm')}  `;
	if (event.type === EventType.Holiday || event.type === EventType.Break) {
		return (
			<>
				<div className="w-full p-2 flex justify-between text-xs">
					<span>{timeString}</span>
				</div>
				<span className="m-2">{`${I18n.t(
					_t(
						event.type === EventType.Break
							? translations.calendar.breaking
							: translations.holiday.header
					)
				)}`}</span>
			</>
		);
	}
	if (!event.booking) {
		return null;
	}
	return (
		<div
			className="pop-over z-50"
			// onClick={() => {
			// 	if (event.booking) {
			// 		BookingHelper.openBooking(event.booking);
			// 	}
			// }}
			// onTouchEnd={() => {
			// 	alert(event.booking?.id);
			// 	if (event.booking) {
			// 		BookingHelper.openBooking(event.booking);
			// 	}
			// }}
		>
			<div className="w-full flex flex-col justify-between text-xs font-light">
				<span>{timeString}</span>
				<div className="gap-1 flex ">
					<StatusTag className="p-2 text-xs" status={event.booking.status} />
					<div
						className={`text-${ColorHelper.getNameColorFromStatus(
							event.booking.status
						)}`}
					>
						<BookingTypeIcon
							type={event.booking.bookingType || 0}
							inputFrom={event.booking.inputFrom || 0}
						/>
					</div>
				</div>
			</div>
			<span className="text-xs">{event.title}</span>
			{event.services?.map((service) => (
				<span className="text-xs font-light">{service?.name}</span>
			))}
		</div>
	);
};

const Main = () => {
	const {
		date,
		view,
		stylist,
		stylistsByTurn,
		events,
		onChangeEvent,
		loading,
	} = useCalendarContext();
	const { currentBranch } = useAppSelector((state) => state.BranchReducer);
	const bookings = useAppSelector((state) => state.BookingReducer.bookings);
	const bookingAction = useAppSelector(
		(state) => state.ReduxActionReducer['BOOKING']
	);
	const history = useHistory();

	moment.tz.setDefault(currentBranch?.localTimeZone || momentTz.tz.guess());
	const globalizeLocalizer = momentLocalizer(moment);
	useEffect(() => {
		if (bookings && date.isSame(moment(), 'date')) {
			const indicator = document.getElementsByClassName(
				'rbc-current-time-indicator'
			)[0];

			if (indicator) {
				var elementPosition = indicator.getBoundingClientRect().top;
				const cView = document.getElementsByClassName('rbc-time-content')[0];
				if (cView) {
					cView.scrollTo({
						top: elementPosition - 400,
						behavior: 'smooth',
					});
				}

				// indicator.scrollIntoView({
				// 	behavior: 'smooth',
				// 	block: 'start',
				// });
			}

			// setScrollToTime(moment().subtract(4, 'hour').toDate());
		}
	}, [bookings, date]);

	useEffect(() => {
		return () => {
			firstUseScreen = false;
		};
	}, []);

	const getMinMaxDay = () => {
		const timeZoneDate = TimeHelper.toTimeZone(date.toDate());
		const dayIndex = timeZoneDate.day();
		const workingDayTime = _.find(currentBranch?.workingHours, (x) =>
			x.days?.includes(dayIndex.toString())
		) as WorkingHour;
		if (!workingDayTime) {
			return {
				min: moment().startOf('day').toDate(),
				max: moment().endOf('day').toDate(),
			};
		}
		const isDST = moment(date).isDST();
		// const changeDst = isDST ? -1 : 0;
		const changeDst = 0;
		const workingTimeStart = moment
			.utc(workingDayTime.workingTimeStartAt)
			.add(changeDst, 'hour')
			.format('HH:mm');
		const workingTimeEnd = moment
			.utc(workingDayTime.workingTimeEndAt)
			.format('HH:mm');
		const { startTime, endTime } = TimeHelper.convertWorkingTime(
			`${workingDayTime.workingTimeStartAt}`,
			`${workingDayTime.workingTimeEndAt}`,
			moment(date)
		);
		const diff = endTime.diff(startTime, 'h');
		const min = TimeHelper.toTimeZone(
			`${moment(date).format('YYYY-MM-DD')}T${workingTimeStart}`
		)
			// .subtract(currentBranch?.timeBlockInterval, 'minute')
			.toDate();
		const max = TimeHelper.toTimeZone(
			moment(min).add(diff, 'h').toDate()
		).toDate();
		return { min, max };
	};
	const checkWorkingTime = (time: Date) => {
		const timezoneTime = TimeHelper.toTimeZone(time);
		const timeCal = timezoneTime.minutes() + timezoneTime.hours() * 60;
		const dayIndex = timezoneTime.day();
		const workingDayTime = _.find(currentBranch?.workingHours, (x) =>
			x.days?.includes(dayIndex.toString())
		) as WorkingHour;
		if (!workingDayTime) {
			return false;
		}
		const isDST = moment(date).isDST();
		// const changeDst = isDST ? -1 : 0;
		const changeDst = 0;

		const workingTimeStartAt = workingDayTime.workingTimeStartAt;

		const workingTimeEndAt = workingDayTime.workingTimeEndAt;

		const startTime = TimeHelper.getInitialWorkingTime(
			workingTimeStartAt as string
		)
			.add(changeDst, 'h')
			.subtract(currentBranch?.timeBlockInterval || 0, 'minute')
			.format('HH:mm');
		const endTime = TimeHelper.getInitialWorkingTime(workingTimeEndAt as string)
			.add(changeDst, 'h')
			.format('HH:mm');
		return moment(timeCal).isBetween(
			TimeHelper.convertDurationToNumber(startTime),
			TimeHelper.convertDurationToNumber(endTime) === 0
				? 24 * 60
				: TimeHelper.convertDurationToNumber(endTime)
		);
	};

	const resourceStylist =
		stylist !== 'all-employee' &&
		_.find(stylistsByTurn, (x) => x.id === stylist)
			? [_.find(stylistsByTurn, (x) => x.id === stylist)!]
			: [
					{
						id: 'Anyone',
						firstName: 'Anyone',
						lastName: '',
					},
			  ].concat(_.sortBy(stylistsByTurn, (x) => x.turn || 0));

	const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
	const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

	useEffect(() => {
		const elementCalendar = document.querySelector('.rbc-time-content');
		const handleScroll = () => {
			setCanScrollLeft(elementCalendar!.scrollLeft > 0);
			setCanScrollRight(
				Math.round(elementCalendar!.scrollLeft) <
					Math.round(
						elementCalendar!.scrollWidth - elementCalendar!.clientWidth
					)
			);
		};

		if (!_.isEmpty(events) && elementCalendar) {
			handleScroll();
			elementCalendar.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (elementCalendar) {
				elementCalendar.removeEventListener('scroll', handleScroll);
			}
		};
	}, [events]);

	const scroll = (direction: 'left' | 'right', amount: number) => {
		// amount : Number witdth scroll direction
		const elementCalendar = document.querySelector('.rbc-time-content');
		if (elementCalendar) {
			if (direction === 'left') {
				elementCalendar.scrollLeft -= amount;
			} else if (direction === 'right') {
				elementCalendar.scrollLeft += amount;
			}
		}
	};

	return (
		<LoadingProvider
			loading={
				bookingAction === BookingActions.getBookingsByBranch.requestName ||
				loading === true
			}
		>
			{canScrollLeft && (
				<Button
					className="absolute btn text-white right-[100px] bottom-5 min-w-[50px] z-[100] rounded-full focus:ring-4 focus:outline-none focus:ring-blue-300 shadow-lg shadow-blue-500/50"
					onClick={() => scroll('left', 150)}
				>
					<Icon path={mdiArrowLeft} size={1} />
				</Button>
			)}
			{canScrollRight && (
				<Button
					className="absolute btn right-3 bottom-5 min-w-[50px] z-[100] rounded-full text-white focus:ring-4 focus:outline-none focus:ring-blue-300 shadow-lg shadow-blue-500/50"
					onClick={() => scroll('right', 150)}
				>
					<Icon path={mdiArrowRight} size={1} />
				</Button>
			)}
			<DragAndDropCalendar
				selectable
				dayLayoutAlgorithm="overlap"
				onSelecting={() => true}
				onSelectEvent={(event) => {
					if (event.booking) {
						BookingHelper.openBooking(event.booking);
					}
				}}
				onEventDrop={(a: any) => {
					onChangeEvent({
						event: a.event,
						start: a.start as Date,
						end: a.end as Date,
						resourceId: a.resourceId,
					});
				}}
				onEventResize={(a: any) => {
					onChangeEvent({
						event: a.event,
						start: a.start as Date,
						end: a.end as Date,
						resourceId: a.resourceId,
						isResize: true,
					});
				}}
				draggableAccessor={(event) => {
					if (event.type === EventType.Break) {
						return false;
					}
					if (_.isEmpty(event.booking)) {
						return true;
					}
					if (moment().isAfter(moment(event.start), 'date')) {
						return false;
					}
					if (
						event.booking?.status !== BookingStatus.PENDING &&
						event.booking?.status !== BookingStatus.PROCESSING &&
						event.booking?.status !== BookingStatus.CHECKED_IN
					) {
						return false;
					}
					return true;
				}}
				events={events}
				startAccessor="start"
				endAccessor="end"
				localizer={globalizeLocalizer}
				view={view}
				longPressThreshold={20}
				step={currentBranch?.timeBlockInterval}
				timeslots={60 / (currentBranch?.timeBlockInterval || 1)}
				className="heightCalendar"
				min={moment(getMinMaxDay().min).startOf('hour').toDate()}
				max={getMinMaxDay().max}
				date={date.toDate()}
				defaultDate={moment().toDate()}
				resources={resourceStylist}
				resourceIdAccessor={(stylist) => stylist.id}
				resourceTitleAccessor={(stylist) =>
					`${stylist.firstName || stylist.lastName}`
				}
				eventPropGetter={(event) => {
					const resource = _.find(
						stylistsByTurn,
						(stylist) => stylist.id === event.resourceId
					);
					return {
						className:
							event.type === EventType.Break
								? 'breaking'
								: event.type === EventType.Holiday
								? 'holiday'
								: '',
						style: {
							backgroundColor:
								event.type === EventType.Holiday ||
								event.type === EventType.Break
									? '#f5f5f5'
									: resource
									? resource!.color
									: 'white',
							borderRadius: 8,
							borderWidth: 3,
							borderColor:
								event.type === EventType.Holiday ||
								event.type === EventType.Break
									? 'black'
									: ColorHelper.getStatusColor(event.status!),
							// border: "none",
						},
					};
				}}
				components={{
					resourceHeader: (resource) => <StaffHeader resource={resource} />,
					timeSlotWrapper: (slot: any) => {
						var isWorkingTime = checkWorkingTime(slot.value);
						const onCreateNewBooking = (slotInfo?: ISlotInfo) => {
							let date = moment().format('MM-DD-YYYY');
							let time;
							let stylistId;
							if (slotInfo) {
								if (moment(slotInfo.value).isBefore(moment())) {
									showAlert(
										I18n.t(_t(translations.calendar.errorAddBookingPast)),
										'warning'
									);
									return;
								}
								date = moment(slotInfo.value).format('MM-DD-YYYY');
								time = moment(slotInfo.value).format('HHmm');
								stylistId =
									slotInfo.resource !== 'Anyone'
										? slotInfo.resource
										: undefined;
							}
							const query = qs.stringify({
								date,
								time,
								stylistId: stylistId,
							});

							history.push(`/booking/new?${query}`);
						};
						return (
							<div
								className={`time-slot ${
									isWorkingTime ? '' : 'out-working-time-slot'
								} ${slot.resource === 'Anyone' ? 'anyone' : 'has-stylist'}`}
								onClick={() => onCreateNewBooking(slot as ISlotInfo)}
								style={{
									backgroundColor: isWorkingTime ? 'white' : '#f5f5f5',
								}}
							>
								<div className="time-label">
									<span>{moment(slot.value).format('hh:mmA')}</span>
									{/* <a href={"/booking/new"}>Add New</a> */}
								</div>
							</div>
						);
					},
					event: (event) => {
						return <Event event={event.event} />;
					},
					toolbar: () => null,
				}}
			/>
		</LoadingProvider>
	);
};

export const MainCalendar = () => {
	const [showCalendar, setShowCalendar] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setShowCalendar(true);
		}, 100);
	}, []);
	return (
		<CalendarProvider>
			<Header />
			{showCalendar && (
				<div onContextMenu={(e) => e.preventDefault()}>
					<Main />
				</div>
			)}
		</CalendarProvider>
	);
};
