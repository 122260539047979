/* eslint-disable react-hooks/exhaustive-deps */
import clsxm from 'clsxs/clsxm';
import { Description, Button } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { GenerateHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { useCheckOutContext } from 'pages/CheckOut/context';
import { useEffect, useRef, useState } from 'react';
import { t, translations } from 'utils';
import { AppType, PaymentType } from 'utils/Consts';
import * as images from '../images';

const PAY_APPS = [
	{
		imgUrl: images.cashApp,
		title: t(translations.checkout.cashApp),
		appType: AppType.CASH_APP,
	},
	{
		imgUrl: images.venmo,
		title: t(translations.checkout.venmoApp),
		appType: AppType.VENMO,
	},
	{
		imgUrl: images.zelle,
		title: t(translations.checkout.zelleApp),
		appType: AppType.ZELLE,
	},
];

export const AppModal = (props: { onCancel: () => void }) => {
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const { calculateAmountNeedToPay, addPayment } = useCheckOutContext();
	const numpadRef = useRef<INumPadRef>();
	const [app, setApp] = useState<AppType>();
	const [referenceCode, setReferenceCode] = useState('');

	const onUpdateText = (text: string) => {
		if (numpadRef) {
			numpadRef.current?.setText(text);
		}
	};

	const onClear = () => {
		onUpdateText('');
	};

	const onAddPayment = (transactionReferenceNumber?: string) => {
		if (!_.isUndefined(app)) {
			addPayment({
				paymentType: PaymentType.APP,
				amount: calculateAmountNeedToPay(),
				appName: _.find(PAY_APPS, (x) => x.appType === app)?.title,
				transactionReferenceNumber:
					transactionReferenceNumber ||
					GenerateHelper.generateFourDigitsNumber().toString(),
			});
			props.onCancel();
		}
	};

	useEffect(() => {
		if (!branch?.paymentReferenceCodeRequiring) {
			onAddPayment();
		}
	}, [app]);

	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			showNumpad={branch?.paymentReferenceCodeRequiring}
			title={t(translations.checkout.chooseAppToPay)}
			onConfirm={(x) => {
				onAddPayment(x);
			}}
			onChange={(x) => setReferenceCode(x)}
		>
			<div className="flex flex-col h-full justify-between">
				<div className="flex gap-8 items-center justify-center mt-10 ">
					{PAY_APPS.map((x) => {
						return (
							<div
								onClick={() => setApp(x.appType)}
								className={clsxm(
									'flex flex-col items-center justify-center border border-primary rounded-lg p-3 w-32 cursor-pointer active:animate-pop duration-500 ease-in',
									app === x.appType ? 'bg-PROCESSING text-white' : ''
								)}
							>
								<img src={x.imgUrl} alt={x.title} />
								<h5>{x.title}</h5>
							</div>
						);
					})}
				</div>
				{branch?.paymentReferenceCodeRequiring && (
					<div className="flex h-fit flex-1 items-end p-4">
						<div className="flex w-full items-center justify-between ">
							<div>
								<Description title="Last 4 digits" value={referenceCode} />
							</div>

							<div className="flex gap-2">
								<Button
									onClick={() => onClear()}
									small
									title={t(translations.clear)}
									className="btn-outline btn-error"
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</ModalNumPad>
	);
};
