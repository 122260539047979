import { ReduxHelper } from 'helpers';
import { listPageTourGuide } from 'models/TourGuide';

export const prefix = 'APP_CONFIG';

const showLoading = ReduxHelper.generateLocalAction<boolean>(
	prefix + '/SHOW_LOADING'
);
const showNotification = ReduxHelper.generateLocalAction<boolean>(
	prefix + '/SHOW_NOTIFICATION'
);
const showLanguage = ReduxHelper.generateLocalAction<boolean>(
	prefix + '/SHOW_LANGUAGE'
);
const chooseLanguage = ReduxHelper.generateLocalAction<string>(
	prefix + '/CHOOSE_LANGUAGE'
);
const setUuid = ReduxHelper.generateLocalAction<string>(prefix + '/SET_UUID');
const getInitialData = ReduxHelper.generateActions(
	`${prefix}/GET_INITIAL_DATA`
);
const setCalling = ReduxHelper.generateLocalAction<boolean>(
	prefix + '/SET_CALLING'
);
const useV1 = ReduxHelper.generateLocalAction<boolean>(prefix + '/USE_V1');
const usedTourGuide = ReduxHelper.generateLocalAction<{
	key: typeof listPageTourGuide[number];
	used?: boolean;
}>('USED_TOUR_GUIDE');

export {
	showLoading,
	showNotification,
	showLanguage,
	chooseLanguage,
	getInitialData,
	setUuid,
	setCalling,
	usedTourGuide,
	useV1,
};
