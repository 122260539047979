import clsxm from 'clsxs/clsxm';
import { Button, Description } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { CurrencyHelper, TimeHelper } from 'helpers';
import { showAlert } from 'helpers/alertHelper';
import { priceFixed } from 'helpers/currencyHelper';
import { generateFourDigitsNumber } from 'helpers/generateHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { IBranch } from 'models/IBranch';
import { ITip } from 'models/ITip';
import moment from 'moment';
import { useCheckOutContext } from 'pages/CheckOut/context';
import React, { useRef, useState } from 'react';
import {
	payment,
	printReceipt,
	readingTip,
	reset,
	thankYou,
	welcome,
} from 'services/CloverApiService';
import { t, translations } from 'utils';
import { PaymentType, PAYMENT_METHOD_ID } from 'utils/Consts';
import * as Sentry from '@sentry/react';
import { createCloverReceipt } from 'helpers/printHelper';
import { print } from 'helpers/printHelper';

enum ECardType {
	OTHER,
	CLOVER,
}

export const calculateCardCharge = (amountCard = '', branch?: IBranch) => {
	if (
		branch?.cardChargeFlatRate !== undefined &&
		branch?.cardChargeFlatRate! !== 0
	) {
		return branch?.cardChargeFlatRate || 0;
	} else {
		return priceFixed(
			(parseFloat(amountCard || '') * (branch?.cardChargePercent || 0)) / 100
		);
	}
};

export const CardModal = (props: { onCancel: () => void }) => {
	const { calculateAmountNeedToPay, addPayment, setTip, bill, tip } =
		useCheckOutContext();
	const cloverDeviceId = useAppSelector(
		(state) => state.CloverReducer.deviceId
	);
	const hasCardType = !_.isEmpty(cloverDeviceId);
	const shopName = useAppSelector((state) => state.ShopReducer.shop?.adminUrl);
	const [loading, setLoading] = useState(false);
	const numpadRef = useRef<INumPadRef>();
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const [amount, setAmount] = useState<string>();
	const [useCardType, setUseCardType] = useState<ECardType | undefined>(
		hasCardType ? undefined : ECardType.OTHER
	);

	const onUpdateText = (text: string) => {
		if (numpadRef) {
			numpadRef.current?.setText(text);
		}
	};
	const onExact = () => {
		onUpdateText(`${calculateAmountNeedToPay()}`);
	};
	const onClear = () => {
		onUpdateText('');
	};

	const onAskForTip = async () => {
		setLoading(true);
		const tipResponse = await readingTip(calculateAmountNeedToPay() * 100);
		setLoading(false);
		welcome();
		if (tipResponse.status === 200 && tipResponse.data) {
			if (tipResponse.data.response !== 0) {
				const tipAmount = tipResponse.data.response / 100;
				const newTip: ITip = {
					billId: bill?.id,
					date: TimeHelper.fromTimeZoneStringToUtc(moment.utc().toISOString()),
					totalTip: tipAmount,
				};
				setTip(newTip);
			}
		}
	};
	const cloverConfirm = async (value: string) => {
		try {
			setLoading(true);
			const final = !_.isEmpty(tip);

			const paymentResponse = await payment(
				priceFixed(
					(parseFloat(value) +
						(calculateCardCharge(amount, branch) || 0) -
						(tip?.totalTip || 0)) *
						100
				),
				final,
				_.ceil((tip?.totalTip || 0) * 100, 2)
			);
			setLoading(false);
			if (paymentResponse.data && paymentResponse.data.result !== 'FAIL') {
				const cloverESCPOS = createCloverReceipt(paymentResponse.data.payment);
				// await print(cloverESCPOS);
				if (!final) {
					await printReceipt(paymentResponse.data.payment.id);
				}
				if (paymentResponse.data.issues.partialPayment) {
					addPayment({
						paymentType: PaymentType.CREDIT_CARD,
						paymentMethodId: PAYMENT_METHOD_ID.CLOVER,
						amount:
							(paymentResponse.data.issues.partialPayment.actualAmount +
								(paymentResponse.data.payment.tipAmount || 0)) /
							100,
						transactionReferenceNumber: amount,
						cardCharge: calculateCardCharge(`${amount}`, branch) || 0,
						isClover: true,
						final: final,
						issueTime: moment(),
					});
					showAlert(
						`${t(
							translations.checkout.partialPayment
						)} ${CurrencyHelper.formatPrice(
							paymentResponse.data.issues.partialPayment.actualAmount / 100
						)}`,
						'warn'
					);
					welcome();
				} else {
					addPayment({
						paymentType: PaymentType.CREDIT_CARD,
						paymentMethodId: PAYMENT_METHOD_ID.CLOVER,
						amount:
							(paymentResponse.data.payment.amount +
								(paymentResponse.data.payment.tipAmount || 0)) /
							100,
						transactionReferenceNumber: paymentResponse.data.payment.id,
						cardCharge: calculateCardCharge(amount, branch) || 0,
						isClover: true,
						issueTime: moment(),
					});
					thankYou();
					setTimeout(() => {
						welcome();
					}, 3000);
				}
				props.onCancel();
			} else {
				Sentry.captureException(paymentResponse.data, {
					level: 'error',
					extra: {
						cloverCancel: true,
					},
					tags: {
						shopName,
						type: 'Clover',
					},
				});
				showAlert(t(translations.checkout.paymentCanceled), 'error');
				setLoading(false);
				welcome();
			}
		} catch (error: any) {
			setLoading(false);
			Sentry.captureException(error, {
				level: 'error',
				tags: {
					shopName,
					type: 'Clover',
				},
			});
			if (!_.isEmpty(error.message)) {
				if (error.message.includes('503')) {
					reset();
				}
				showAlert(t(translations.checkout.paymentError), 'error');
			}
		}
	};
	const onConfirm = async (value: string) => {
		if (useCardType === ECardType.OTHER) {
			addPayment({
				paymentType: PaymentType.CREDIT_CARD,
				amount: parseFloat(value) + calculateCardCharge(amount, branch),
				transactionReferenceNumber: `${generateFourDigitsNumber()}`,
				cardCharge: calculateCardCharge(amount, branch) || 0,
			});
			props.onCancel();
			return;
		}
		if (useCardType === ECardType.CLOVER) {
			cloverConfirm(value);
		}
	};
	const onSelectCardType = (type: ECardType) => {
		setUseCardType(type);
	};
	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			isMoney
			onChange={(x) => {
				if (parseFloat(x) > calculateAmountNeedToPay()) {
					setAmount(`${calculateAmountNeedToPay()}`);
					onUpdateText(`${calculateAmountNeedToPay()}`);
				} else {
					setAmount(x);
				}
			}}
			showNumpad={useCardType !== undefined}
			unit="Usd"
			loading={loading}
			title={
				useCardType === undefined
					? 'Select card payment type'
					: t(translations.checkout.debitCreditCard)
			}
			onConfirm={(x) => onConfirm(x)}
		>
			{useCardType === undefined ? (
				<div className="gap-2 grid grid-cols-6 p-4 ">
					<Button
						{...props}
						className={clsxm('btn-outline col-span-3 btn-secondary bg-white')}
						col
						onClick={() => onSelectCardType(ECardType.CLOVER)}
						title={'Clover'}
					/>
					<Button
						{...props}
						className={clsxm('col-span-3 btn-outline btn-secondary bg-white')}
						col
						onClick={() => onSelectCardType(ECardType.OTHER)}
						title={'Other'}
					/>
				</div>
			) : (
				<div className="flex flex-col h-full flex-1 items-end  justify-between p-4">
					<div className="flex justify-end w-full gap-2">
						{useCardType === ECardType.CLOVER && (
							<Button
								small
								onClick={() => onAskForTip()}
								title={t(translations.checkout.askForTip)}
								className="w-1/2"
							/>
						)}
						{hasCardType && (
							<Button
								small
								className="btn-outline"
								onClick={() => setUseCardType(undefined)}
								title={'Back'}
							/>
						)}
					</div>

					<div className="flex w-full items-center justify-between ">
						<div>
							<Description
								title={t(translations.checkout.amountNeedToPay)}
								value={`${CurrencyHelper.formatPrice(
									calculateAmountNeedToPay() - (tip?.totalTip || 0)
								)}`}
							/>
							<Description
								title={t(translations.checkout.tip)}
								value={`${CurrencyHelper.formatPrice(tip?.totalTip || 0)}`}
							/>
							<Description
								title={t(translations.checkout.cardCharge)}
								value={`${CurrencyHelper.formatPrice(
									calculateCardCharge(amount, branch) || 0
								)}`}
							/>
							<div className="divider m-0" />
							<Description
								title={t(translations.payrollDetailReport.total)}
								value={`${CurrencyHelper.formatPrice(
									(calculateAmountNeedToPay() || 0) +
										(calculateCardCharge(amount, branch) || 0)
								)}`}
							/>
						</div>

						<div className="flex gap-2">
							<Button
								onClick={() => onExact()}
								small
								title={t(translations.checkout.exact)}
								className="btn-outline btn-primary"
							/>
							<Button
								onClick={() => onClear()}
								small
								title={t(translations.clear)}
								className="btn-outline btn-error"
							/>
						</div>
					</div>
				</div>
			)}
		</ModalNumPad>
	);
};
