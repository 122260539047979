import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from 'redux/configuration/configureStore';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { PersistGate } from 'redux-persist/integration/react';
import toast, { Toaster } from 'react-hot-toast';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { initFacebookSdk } from './helpers/facebookHelper';
import * as Sentry from '@sentry/react';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import moment from 'moment';
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels
);
// configureStore().persistor.purge();
moment.locale('en');
Sentry.init({
	dsn: 'https://e1019818c8d9460e8a28116811ae5c6f@sentry.scssolutions.io/2',
	integrations: [new Sentry.BrowserTracing()],
	tracesSampleRate: 1.0,
});
initFacebookSdk().then(() => {
	ReactDOM.render(
		<React.StrictMode>
			<Provider store={configureStore().store}>
				<PersistGate loading={null} persistor={configureStore().persistor}>
					<div className=" prose max-w-none">
						<App />
					</div>
					<Toaster />
				</PersistGate>
			</Provider>
		</React.StrictMode>,
		document.getElementById('root')
	);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.unregister();
