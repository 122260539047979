/* eslint-disable react-hooks/exhaustive-deps */
import { mdiCancel, mdiDelete, mdiPlus, mdiPlusCircle } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Icon, Modal } from 'components/atoms';
import { TimeRangePickerV2 } from 'components/atoms/TimePickerV2';
import { useAppSelector } from 'helpers/hookHelpers';
import { getDayOfWeek } from 'helpers/StringHelper';
import {
	getInitialWorkingTime,
	toTimeZone,
} from 'helpers/timeHelper';
import _ from 'lodash';
import { IBranch, OnlineWorkingHour, WorkingHour } from 'models/IBranch';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { WeekDays } from 'utils/Consts';
import { IStaffDetailForm } from '../context';
import { convertTimeRequest } from 'helpers/bookingHelper';

export const WorkingTimeOfDay = (props: {
	day: number;
	isLoading?: boolean;
	online?: boolean;
}) => {
	const { setValue, watch } = useFormContext<IStaffDetailForm>();
	const [showModal, setShowModal] = useState(false);
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const { day } = props;
	const isWholeWeek = day === WeekDays.WHOLEWEEK;
	const dayOfWeek = getDayOfWeek(day);
	const [defaultCheck, defaultValue] = initialWorkingHour(
		day,
		watch(props.online ? 'employeeOnlineBookingTimes' : 'employeeWorkingTimes'),
		isWholeWeek,
		branch,
		props.online
	);
	const isWorkingDay = (): boolean => {
		return !_.isEmpty(
			_.find(branch?.workingHours, (x) => x.days === day.toString())
		);
	};

	const fieldName = `${dayOfWeek.toLowerCase().replace(' ', '')}${
		props.online ? 'Online' : ''
	}` as keyof IStaffDetailForm;
	const workingHourCheck = watch(
		(fieldName + 'Check') as keyof IStaffDetailForm
	) as boolean;
	const watcher = watch(fieldName) as string[];
	const [timeChosen, setTimeChosen] = useState<Moment[]>(
		_.isEmpty(watcher)
			? []
			: [toTimeZone(watcher[0]), toTimeZone(watcher[1])].concat(
					watcher[2] ? [toTimeZone(watcher[2]), toTimeZone(watcher[3])] : []
			  )
	);

	useEffect(() => {
		if (!_.isEmpty(watcher)) {
			setTimeChosen(
				[toTimeZone(watcher[0]), toTimeZone(watcher[1])].concat(
					watcher[2] ? [toTimeZone(watcher[2]), toTimeZone(watcher[3])] : []
				)
			);
		}
	}, [watcher]);
	useEffect(() => {
		setValue((fieldName + 'Check') as keyof IStaffDetailForm, defaultCheck);
		let workingTimes = [
			getInitialWorkingTime(defaultValue[0]).format(),
			getInitialWorkingTime(defaultValue[1]).format(),
		];
		if (defaultValue[2]) {
			workingTimes = workingTimes.concat(
				getInitialWorkingTime(defaultValue[2]).format(),
				getInitialWorkingTime(defaultValue[3]).format()
			);
		}
		setValue(fieldName, workingTimes);
	}, [
		watch(props.online ? 'employeeOnlineBookingTimes' : 'employeeWorkingTimes'),
	]);

	const onConfirm = () => {
		const value = timeChosen || [];
		if (isWholeWeek) {
			setValue(
				('sunday' + (props.online ? 'Online' : '')) as keyof IStaffDetailForm,
				[
					value[0].format(),
					value[1].format(),
					value[2] ? value[2].format() : undefined,
					value[3] ? value[3].format() : undefined,
				]
			);
			setValue(
				('monday' + (props.online ? 'Online' : '')) as keyof IStaffDetailForm,
				[
					value[0].format(),
					value[1].format(),
					value[2] ? value[2].format() : undefined,
					value[3] ? value[3].format() : undefined,
				]
			);
			setValue(
				('tuesday' + (props.online ? 'Online' : '')) as keyof IStaffDetailForm,
				[
					value[0].format(),
					value[1].format(),
					value[2] ? value[2].format() : undefined,
					value[3] ? value[3].format() : undefined,
				]
			);
			setValue(
				('wednesday' +
					(props.online ? 'Online' : '')) as keyof IStaffDetailForm,
				[
					value[0].format(),
					value[1].format(),
					value[2] ? value[2].format() : undefined,
					value[3] ? value[3].format() : undefined,
				]
			);
			setValue(
				('thursday' + (props.online ? 'Online' : '')) as keyof IStaffDetailForm,
				[
					value[0].format(),
					value[1].format(),
					value[2] ? value[2].format() : undefined,
					value[3] ? value[3].format() : undefined,
				]
			);
			setValue(
				('friday' + (props.online ? 'Online' : '')) as keyof IStaffDetailForm,
				[
					value[0].format(),
					value[1].format(),
					value[2] ? value[2].format() : undefined,
					value[3] ? value[3].format() : undefined,
				]
			);
			setValue(
				('saturday' + (props.online ? 'Online' : '')) as keyof IStaffDetailForm,
				[
					value[0].format(),
					value[1].format(),
					value[2] ? value[2].format() : undefined,
					value[3] ? value[3].format() : undefined,
				]
			);
			setValue(
				('wholeweek' +
					(props.online ? 'Online' : '')) as keyof IStaffDetailForm,
				[
					value[0].format(),
					value[1].format(),
					value[2] ? value[2].format() : undefined,
					value[3] ? value[3].format() : undefined,
				]
			);
		} else {
			setValue(fieldName, [
				value[0].format(),
				value[1].format(),
				value[2] ? value[2].format() : undefined,
				value[3] ? value[3].format() : undefined,
			]);
			setValue((fieldName + 'Check') as keyof IStaffDetailForm, true);
		}
		setShowModal(false);
	};

	const onChooseTime = (value: Moment[]) => {
		console.group('TIME');
		const utcValue = value.map((x) => {
			return x;
		});
		console.groupEnd();
		setTimeChosen(utcValue);
	};
	return (
		<div
			className={clsxm('flex w-full justify-center items-start working-time')}
		>
			{(isWorkingDay() || isWholeWeek) && (
				<div className={clsxm('flex flex-col justify-end items-center')}>
					{workingHourCheck || isWholeWeek ? (
						<div>
							<div
								onClick={() => setShowModal(true)}
								className={clsxm(
									'flex rounded-md text-xs font-bold justify-center hover:main-gradient-background items-center p-2 bg-base-200 active:animate-pop cursor-pointer'
								)}
							>
								{getInitialWorkingTime(
									!_.isEmpty(watcher) ? watcher[0] : defaultValue[0]
								).format('hh:mm A')}{' '}
								-
								{getInitialWorkingTime(
									!_.isEmpty(watcher) ? watcher[1] : defaultValue[1]
								).format('hh:mm A')}
							</div>
							{!_.isEmpty(watcher) && watcher[2] && (
								<div className="pt-1 text-xs">
									<strong>Break time</strong>
									<div
										onClick={() => setShowModal(true)}
										className={clsxm(
											'flex rounded-md text-xs font-bold justify-center hover:main-gradient-background items-center p-2 bg-warning active:animate-pop cursor-pointer'
										)}
									>
										{getInitialWorkingTime(watcher[2]).format('hh:mm A')} -
										{getInitialWorkingTime(watcher[3]).format('hh:mm A')}
									</div>
								</div>
							)}
						</div>
					) : (
						<div
							onClick={() => setShowModal(true)}
							className={clsxm(
								'flex w-full rounded-md text-xs font-bold justify-center hover:main-gradient-background items-center p-2 bg-base-200 active:animate-pop cursor-pointer'
							)}
						>
							<Icon size={0.7} path={mdiPlusCircle} />
						</div>
					)}
				</div>
			)}
			{showModal && (
				<Modal
					title={`Edit ${getDayOfWeek(props.day)} ${
						props.online ? 'online' : ''
					} working time`}
					isOpen={true}
					canConfirm
					onCancel={() => setShowModal(false)}
					onConfirm={() => onConfirm()}
					buttons={[
						<Button
							small
							onClick={() => {
								setShowModal(false);
								setValue(
									(fieldName + 'Check') as keyof IStaffDetailForm,
									false
								);
							}}
							title="Day Off"
							className="btn-outline btn-error"
							iconBefore={<Icon path={mdiCancel} />}
						/>,
					]}
					content={
						<div className="p-4">
							<strong>{props.online ? 'Online ' : ''}Working Time</strong>
							<div className="flex mt-2 gap-2">
								<TimeRangePickerV2
									startProps={{
										value: timeChosen[0],
										placement: 'topRight',
									}}
									endProps={{
										value: timeChosen[1],
										placement: 'bottomRight',
									}}
									// defaultValue={[
									// 	getInitialWorkingTime(defaultValue[0], undefined, true),
									// 	getInitialWorkingTime(defaultValue[1], undefined, true),
									// ]}
									onChooseTime={onChooseTime}
									// disabled={(!isWholeWeek && !workingHourCheck) || isLoading}
									minuteStep={15}
								/>
							</div>

							{timeChosen[2] && (
								<div className="mt-1 ml-8">
									<strong>Break Time</strong>
									<div className="flex gap-2">
										<TimeRangePickerV2
											startProps={{
												value: timeChosen[2],
												placement: 'bottomRight',
											}}
											endProps={{
												value: timeChosen[3],
												placement: 'bottomRight',
											}}
											defaultValue={[timeChosen[0], timeChosen[1]]}
											onChooseTime={(time) => {
												setTimeChosen([
													timeChosen[0],
													timeChosen[1],
													time[0],
													time[1],
												]);
											}}
											// disabled={(!isWholeWeek && !workingHourCheck) || isLoading}
											minuteStep={15}
										/>
									</div>
								</div>
							)}
							{!props.online && (
								<div className="flex w-full justify-end mt-2">
									<Button
										onClick={() => {
											if (_.isEmpty(timeChosen[2])) {
												setTimeChosen(timeChosen.concat(timeChosen));
											} else {
												setTimeChosen([timeChosen[0], timeChosen[2]]);
											}
										}}
										title={`${
											_.isEmpty(timeChosen[2]) ? 'Add' : 'Remove'
										} Break Time`}
										className={clsxm(
											'text-xs',
											_.isEmpty(timeChosen[2]) ? 'text-primary' : 'text-error'
										)}
										small
										white
										iconBefore={
											<Icon
												path={_.isEmpty(timeChosen[2]) ? mdiPlus : mdiDelete}
											/>
										}
									/>
								</div>
							)}
						</div>
					}
				/>
			)}
		</div>
	);
};

const initialWorkingHour = (
	day: WeekDays,
	workingHours: (WorkingHour & OnlineWorkingHour)[],
	isWholeWeek: boolean,
	branch?: IBranch,
	online?: boolean
): [defaultActive: boolean, workingHour: string[]] => {
	const branchWorkingHours = _.find(branch?.workingHours, (element) => {
		return element.days! === day.toString();
	});
	const branchOnlineBookingTime = _.find(
		branch?.onlineBookingTimes,
		(element) => {
			return element.days! === day.toString();
		}
	);
	let defaultWorkingTime = online
		? [
				branch?.onlineBookingTimes![0]?.onlineBookingTimeStartAt! ||
					convertTimeRequest(toTimeZone().startOf('date').hour(8)),
				branch?.onlineBookingTimes![0]?.onlineBookingTimeEndAt! ||
					convertTimeRequest(
						toTimeZone().startOf('date').hour(22),
						toTimeZone()
							.startOf('date')
							.hour(22)
							.utc()
							.isAfter(toTimeZone().startOf('date').hour(8).utc(), 'date')
					),
		  ]
		: [
				branch?.workingHours![0]?.workingTimeStartAt! ||
					convertTimeRequest(toTimeZone().startOf('date').hour(8)),
				branch?.workingHours![0]?.workingTimeEndAt! ||
					convertTimeRequest(
						toTimeZone().startOf('date').hour(22),
						toTimeZone()
							.startOf('date')
							.hour(22)
							.utc()
							.isAfter(toTimeZone().startOf('date').hour(8).utc(), 'date')
					),
		  ];
	if (branchWorkingHours && !online) {
		defaultWorkingTime = [
			branchWorkingHours.workingTimeStartAt!,
			branchWorkingHours.workingTimeEndAt!,
			branchWorkingHours.breakingTimeStartAt || '',
			branchWorkingHours.breakingTimeEndAt || '',
		];
	}
	if (branchOnlineBookingTime && online) {
		defaultWorkingTime = [
			branchOnlineBookingTime.onlineBookingTimeStartAt!,
			branchOnlineBookingTime.onlineBookingTimeEndAt!,
			branchOnlineBookingTime.breakingTimeStartAt || '',
			branchOnlineBookingTime.breakingTimeEndAt || '',
		];
	}

	if (isWholeWeek) {
		return workingHours?.length === 7 &&
			workingHours.every(
				(element: WorkingHour & OnlineWorkingHour) =>
					(online
						? element.onlineBookingTimeStartAt
						: element.workingTimeStartAt) ===
						(online
							? workingHours![0].onlineBookingTimeStartAt
							: workingHours![0].workingTimeStartAt) &&
					(online
						? element.onlineBookingTimeEndAt
						: element.workingTimeEndAt) ===
						(online
							? workingHours![0].onlineBookingTimeEndAt
							: workingHours![0].workingTimeEndAt)
			)
			? [
					true,
					[
						online
							? workingHours![0].onlineBookingTimeStartAt!
							: workingHours![0].workingTimeStartAt!,
						online
							? workingHours![0].onlineBookingTimeEndAt!
							: workingHours![0].workingTimeEndAt!,
					],
			  ]
			: [false, defaultWorkingTime];
	} else {
		const workingHour = _.find(workingHours, (element) => {
			return element.days! === day.toString();
		});
		if (workingHour) {
			return [
				true,
				[
					online
						? workingHour!.onlineBookingTimeStartAt!
						: workingHour!.workingTimeStartAt!,
					online
						? workingHour!.onlineBookingTimeEndAt!
						: workingHour!.workingTimeEndAt!,
					workingHour.breakingTimeStartAt || '',
					workingHour.breakingTimeEndAt || '',
				],
			];
		} else {
			return [false, defaultWorkingTime];
		}
	}
};
