import { mdiClose } from '@mdi/js';
import { ConfirmModal, Description, Icon } from 'components/atoms';
import { PaymentTypes } from 'components/molecules/PaymentTypes';
import { CurrencyHelper } from 'helpers';
import { showAlert } from 'helpers/alertHelper';
import { getPaymentName } from 'helpers/bookingHelper';
import I18n, { t } from 'i18n-js';
import _ from 'lodash';
import { IPaymentType } from 'models/ITransaction';
import moment from 'moment';
import { useCheckOutContext } from 'pages/CheckOut/context';
import React, { useState } from 'react';
import { refund, voidPayment, welcome } from 'services/CloverApiService';
import { translations, _t } from 'utils';
import { PaymentType } from 'utils/Consts';

export const PaymentMethods = () => {
	const { payments, removePayment } = useCheckOutContext();
	const [paymentCard, setPaymentCard] = useState<Partial<IPaymentType>>();
	const [currentIndex, setCurrentIndex] = useState<number>();
	const [loading, setLoading] = useState(false);
	const onRemovePayment = (payment: Partial<IPaymentType>, index: number) => {
		if (payment.isClover) {
			setPaymentCard(payment);
			setCurrentIndex(index);
		} else {
			removePayment(index);
		}
	};
	const onRefund = async () => {
		setLoading(true);
		const response = checkIsVoid()
			? await voidPayment(`${paymentCard!.transactionReferenceNumber}`)
			: await refund(
					`${paymentCard!.transactionReferenceNumber}`,
					paymentCard!.amount || 0,
					true
			  );
		onCloseConfirm();
		welcome();
		if (response.status === 200) {
			removePayment(currentIndex!);
			showAlert('Refund Successfully', 'success');
		} else {
		}
	};
	const onCloseConfirm = () => {
		setLoading(false);
		setCurrentIndex(undefined);
		setPaymentCard(undefined);
	};
	const checkIsVoid = () => {
		return Math.abs(moment().diff(paymentCard?.issueTime, 'minute')) < 25;
	};
	return (
		<div id="payment-methods">
			<PaymentTypes onRemove={onRemovePayment} payments={payments || []} />
			<ConfirmModal
				onPositiveClick={() => onRefund()}
				type="warning"
				onClose={() => onCloseConfirm()}
				visible={!_.isEmpty(paymentCard)}
				onNegativeClick={() => onCloseConfirm()}
				title={`${checkIsVoid() ? 'Void' : 'Refund'} transaction`}
				loading={loading}
				subTitle={`Are you sure want to ${
					checkIsVoid() ? 'void' : 'refund'
				} this transaction ? Money will be back to the customer account`}
			/>
		</div>
	);
};
