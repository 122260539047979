import { IBranch, IPrinter } from 'models/IBranch';
import {} from 'models/RequestModels';
import { BaseApiService } from './BaseApiService';

const url = '/Branch';

class BranchApiService extends BaseApiService {
	public getBranchesByShopId = (id: string) =>
		this.get<IBranch[]>(`${url}/get-all-branches`, {
			params: {
				shopId: id,
			},
		});
	public getBranchById = (id: string) =>
		this.get<IBranch>(`${url}/get-branch/${id}`);
	public updateBranch = (request: IBranch) => {
		return this.put<IBranch, IBranch>(`${url}/update-branch`, request);
	};
	public deleteBranch = (id: string) =>
		this.delete<string>(`${url}/delete-branch/${id}`);
	public createBranch = (request: Partial<IBranch>) =>
		this.post<Partial<IBranch>, IBranch>(`${url}/create-branch`, request);
	public setPrinter = (request: Partial<IPrinter>) =>
		this.post<Partial<IPrinter>, any>(`${url}/add-printer-setting`, request);
	public saveClover = (request: {
		id: string;
		cloverMerchantId: string;
		cloverDeviceIds: string;
	}) => this.post(`${url}/save-clover-devices`, request);
}
export default new BranchApiService(true);
