import { IApiResponse, IEmployeeType } from 'models';
import { put, takeEvery, call } from 'redux-saga/effects';
import { EmployeeTypeActions } from 'redux/actions';
import { IErrorResponse } from '../../models/IErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import { IEditEmployeeTypeRequest } from 'models/RequestModels';
import { AlertHelper } from 'helpers';
import EmployeeTypeApiService from 'services/EmployeeTypeApiService';

function* getAllEmployeeTypes() {
	try {
		const result: IApiResponse<IEmployeeType[]> = yield call(
			EmployeeTypeApiService.getAllEmployeeTypes
		);
		if (result.succeeded) {
			yield put(EmployeeTypeActions.getAllEmployeeTypes.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			yield put(EmployeeTypeActions.getAllEmployeeTypes.failed(error));
		}
	} catch (error) {
		yield put(EmployeeTypeActions.getAllEmployeeTypes.failed(error));
	}
}

function* getEmployeeTypeById(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<IEmployeeType> = yield call(
			EmployeeTypeApiService.getEmployeeTypeById,
			action.payload
		);
		if (result.succeeded) {
			yield put(EmployeeTypeActions.getEmployeeTypeById.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
			yield put(EmployeeTypeActions.getEmployeeTypeById.failed(error));
		}
	} catch (error) {
		yield put(EmployeeTypeActions.getAllEmployeeTypes.failed(error));
	}
}

// function* addNewEmployeeType(action: PayloadAction<IEditEmployeeTypeRequest>) {
// 	try {
// 		const result: IApiResponse<IEmployeeType> = yield call(
// 			EmployeeTypeApiService.addNewEmployeeType,
// 			action.payload
// 		);
// 		if (result.succeeded) {
// 			yield put(EmployeeTypeActions.addNewEmployeeType.success(result.data!));
// 		} else {
// 			const error = result as IErrorResponse;
// 			AlertHelper.showError(error);
// 			yield put(EmployeeTypeActions.addNewEmployeeType.failed(error));
// 		}
// 	} catch (error) {
// 		yield put(EmployeeTypeActions.addNewEmployeeType.failed(error));
// 	}
// }

// function* editEmployeeType(action: PayloadAction<IEditEmployeeTypeRequest>) {
// 	try {
// 		const result: IApiResponse<IEmployeeType> = yield call(
// 			EmployeeTypeApiService.editEmployeeType,
// 			action.payload
// 		);
// 		if (result.succeeded) {
// 			yield put(EmployeeTypeActions.editEmployeeType.success(result.data!));
// 		} else {
// 			const error = result as IErrorResponse;
// 			AlertHelper.showError(error);
// 			yield put(EmployeeTypeActions.editEmployeeType.failed(error));
// 		}
// 	} catch (error) {
// 		yield put(EmployeeTypeActions.editEmployeeType.failed(error));
// 	}
// }

function* deleteEmployeeType(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<string> = yield call(
			EmployeeTypeApiService.deleteEmployeeType,
			action.payload
		);
		if (result.succeeded) {
			yield put(EmployeeTypeActions.deleteEmployeeType.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
			yield put(EmployeeTypeActions.deleteEmployeeType.failed(error));
		}
	} catch (error) {
		yield put(EmployeeTypeActions.deleteEmployeeType.failed(error));
	}
}

export function* EmployeeTypeWatcher() {
	yield takeEvery(
		EmployeeTypeActions.getAllEmployeeTypes.requestName,
		getAllEmployeeTypes
	);
	// yield takeEvery(
	// 	EmployeeTypeActions.addNewEmployeeType.requestName,
	// 	addNewEmployeeType
	// );
	// yield takeEvery(
	// 	EmployeeTypeActions.editEmployeeType.requestName,
	// 	editEmployeeType
	// );
	yield takeEvery(
		EmployeeTypeActions.deleteEmployeeType.requestName,
		deleteEmployeeType
	);
	yield takeEvery(
		EmployeeTypeActions.getEmployeeTypeById.requestName,
		getEmployeeTypeById
	);
}
