import { PayloadAction } from '@reduxjs/toolkit';
import { AlertHelper } from 'helpers';
import { IApiResponse, IErrorResponse, ITheme } from 'models';
import { IPaginateRequest } from 'models/RequestModels';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ThemeActions } from 'redux/actions';
import { ThemeApiService } from 'services';

function* getAllThemes(action: PayloadAction<IPaginateRequest>) {
	try {
		const result: IApiResponse<ITheme[]> = yield call(
			ThemeApiService.getAllThemes,
			action.payload
		);
		if (result.succeeded) {
			yield put(ThemeActions.getAllThemes.success(result.data!));
		} else {
			AlertHelper.showError(result);
			yield put(ThemeActions.getAllThemes.failed(result as IErrorResponse));
		}
	} catch (error) {
		console.error('Network error', error);
	}
}

export function* ThemeWatcher() {
	yield takeEvery(ThemeActions.getAllThemes.requestName, getAllThemes);
}
