import I18n from "i18n-js";
import { convertLanguageJsonToObject } from "./translations";

import en from "./en/translation.json";
import vi from "./vi/translation.json";

convertLanguageJsonToObject(en);
convertLanguageJsonToObject(vi);
I18n.fallbacks = true;
I18n.translations = {
  en,
  // vi,
};
I18n.locale = "vi";
export default I18n;
export const _t = (id: string, ...rest: any[]): [string, ...any[]] => {
  if (!id) {
    id = "_NOT_TRANSLATED_";
  }
  return [id, ...rest];
};

export const t = (
	value: string,
	options?: I18n.TranslateOptions
) => {
	return I18n.t(_t(value), options);
};
