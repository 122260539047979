import { Card, Input, TextArea } from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { AlertHelper } from 'helpers';
import _ from 'lodash';
import { ILoyaltyRank } from 'models/ILoyalRank';
import React, { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import LoyaltyApiService from 'services/LoyaltyApiService';
import { t, translations } from 'utils';

interface IFormDataLoyaltyRank {
	name: string;
	description: string;
	pointToReach: number;
	extraRate: number;
}
const FormInfo = () => {
	const {
		formState: { errors },
		register,
	} = useFormContext<IFormDataLoyaltyRank>();
	return (
		<Card title="Rank Info" className="w-1/2 mx-auto">
			<div className="grid md:grid-cols-3 gap-4 pt-8">
				<Input
					label={t(translations.loyaltyRank.rankName)}
					placeholder={t(translations.loyaltyRank.rankNamePlaceholder)}
					{...register('name', {
						required: t(translations.loyaltyRank.requiredName),
					})}
					autoFocus
					error={errors.name?.message}
				/>
				<Input
					label={t(translations.loyaltyRank.pointToReach)}
					type="number"
					{...register('pointToReach', {
						required: t(translations.loyaltyRank.requiredPointToReach),
						pattern: {
							value: /^[0-9]*[1-9]+$|^[1-9]+[0-9]*$/,
							message: 'Invalid value, point to reach is a natural number',
						},
						min: {
							value: 0,
							message: t(translations.validate.lessThanZero),
						},
					})}
					error={errors.pointToReach?.message}
					alignRight
				/>
				<div>
					<Input
						label={t(translations.loyaltyRank.extraEarningPointRate)}
						type="number"
						{...register('extraRate', {
							required: t(translations.loyaltyRank.requiredExtraRate),
							min: {
								value: 0,
								message: t(translations.validate.lessThanZero),
							},
						})}
						error={errors.extraRate?.message}
						alignRight
					/>
					<p className="m-0 text-sm mt-2 text-primary text-right">
						Customer earning point in this rank will be multiplied
					</p>
				</div>
			</div>
			<TextArea
				label={t(translations.loyaltyRank.rankDescription)}
				placeholder={t(translations.loyaltyRank.rankDescriptionPlaceholder)}
				{...register('description')}
				error={errors.description?.message}
			/>
		</Card>
	);
};

export const LoyaltyRankDetailPage = () => {
	const [loading, setLoading] = useState(false);
	const [rankDetail, setRankDetail] = useState<ILoyaltyRank>();

	const location = useLocation();
	const { id } = useParams() as { id: string };
	const history = useHistory();

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/loyalty-rank');
	};
	const form = useForm<IFormDataLoyaltyRank>({
		reValidateMode: 'onChange',
		mode: 'onChange',
		defaultValues: { extraRate: 1, pointToReach: 0 },
	});

	const fetchRankDetailById = async () => {
		setLoading(true);
		try {
			const res = await LoyaltyApiService.getLoyaltyRankById(id);
			if (res.succeeded && res.data) {
				form.reset(res.data);
				setRankDetail(res.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const submitForm = async (formData: IFormDataLoyaltyRank) => {
		setLoading(true);
		const data = {
			id: isAdd() ? undefined : rankDetail?.id,
			name: formData.name,
			description: formData.description,
			pointToReach: formData.pointToReach,
			extraRate: formData.extraRate,
			status: isAdd() ? 1 : rankDetail?.status,
		};
		try {
			const res = isAdd()
				? await LoyaltyApiService.createLoyaltyRank(data)
				: await LoyaltyApiService.updateLoyaltyRank(data);
			if (res.succeeded && res.data) {
				AlertHelper.showSuccess(
					isAdd()
						? t(translations.loyaltyRank.messageAddRankSuccess)
						: t(translations.loyaltyRank.messageUpdateRankSuccess)
				);
				goBack();
			} else {
				AlertHelper.showError(res);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const onDeleteLoyaltyRank = async () => {
		setLoading(true);
		try {
			const res = await LoyaltyApiService.deleteLoyaltyRank(rankDetail!.id);
			if (res.succeeded) {
				AlertHelper.showSuccess(
					t(translations.loyaltyRank.messageDeleteRankSuccess)
				);
				goBack();
			} else {
				AlertHelper.showError(res);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		!isAdd() && fetchRankDetailById();
	}, []);
	return (
		<DetailPageLayout
			title={
				isAdd()
					? t(translations.loyaltyRank.addNewRankTitle)
					: t(translations.loyaltyRank.updateRankTitle)
			}
			loading={loading}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			showDelete={!isAdd()}
			deleteSubTitle={t(translations.loyaltyRank.confirmDeleteRank)}
			onDelete={onDeleteLoyaltyRank}
		>
			{(isAdd() || !_.isEmpty(rankDetail)) && <FormInfo />}
		</DetailPageLayout>
	);
};
