import {
	IMarketingEmail,
	IMarketingEmailSendRequest,
} from 'models/IMarketingEmail';
import { BaseApiService } from 'services/BaseApiService';

class EmailBlastApiService extends BaseApiService {
	public getAllMarketingEmail = (query?: any) =>
		this.get<IMarketingEmail[]>(`/MarketingEmail/get-all-marketing-emails`, {
			params: query,
		});
	public getMarketingEmailById = (id: string) =>
		this.get<IMarketingEmail>(`/MarketingEmail/get-marketing-email/${id}`);
	public addMarketingEmail = (
		addTextMessageRequest: Partial<IMarketingEmail>
	) =>
		this.post<Partial<IMarketingEmail>, string>(
			`/MarketingEmail/create-marketing-email`,
			addTextMessageRequest
		);
	public editMarketingEmail = (
		editTextMessageRequest: Partial<IMarketingEmail>
	) =>
		this.put<Partial<IMarketingEmail>, string>(
			`/MarketingEmail/update-marketing-email`,
			editTextMessageRequest
		);
	public deleteMarketingEmail = (id: string) =>
		this.delete<string>(`/MarketingEmail/delete-marketing-email/${id}`);
	public sendMarketingEmail = (
		sendTextMessageRequest: Partial<IMarketingEmailSendRequest>
	) =>
		this.post<Partial<IMarketingEmailSendRequest>, string>(
			`/MarketingEmail/send-marketing-emails`,
			sendTextMessageRequest
		);
}

export default new EmailBlastApiService();
