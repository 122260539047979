import { LoadingProvider } from 'contexts/LoadingContext';
import _ from 'lodash';
import { IApiResponse, IBooking } from 'models';
import { IShortly } from 'models/IShortly';
import React from 'react';
import { history } from 'routers';
import ShortlyApiService from 'services/ShortlyApiService';
import { BookingStatus } from 'utils/Consts';
// import '../../loading.css';

export class Shortly extends React.Component {
	state = {
		isFalse: false,
	};
	async componentDidMount() {
		const path = history.location.pathname;
		const shortlyId = path.split('/')[2];
		if (shortlyId) {
			const shortlyResponse = (await ShortlyApiService.getById(
				shortlyId
			)) as IApiResponse<IShortly>;
			if (_.isEmpty(shortlyResponse.errors) && shortlyResponse.succeeded) {
				const shortly = shortlyResponse.data;
				if (shortly?.type === 'Booking') {
					try {
						const booking = JSON.parse(`${shortly.objectData}`) as any;
						if (booking.PaymentStatus === 3) {
							if (booking.Id && booking.BranchId) {
								// history.push(`/rating/${booking.branchId}/${booking.id}`);
							} else {
							}
						} else {
							if (booking.Status === BookingStatus.PENDING) {
								history.push(`/customer-booking/${booking.Id}`);
							}
						}
					} catch (error) {
						window.location.href = 'https://smartsalon.live/';
					}
				}

				// if (shortly?.controller === 'Rating') {
				// 	const booking = JSON.parse(shortly.objectData) as any;
				// 	if (booking.Id && booking.BranchId) {
				// 		history.push(`/rating/${booking.BranchId}/${booking.Id}`);
				// 	}
				// }
			} else {
				// window.location.href = 'https://smartsalon.live/';
			}
		} else {
			// window.location.href = 'https://smartsalon.live/';
		}
	}

	render() {
		return this.renderLoading();
	}

	renderLoading() {
		return (
			<LoadingProvider loading>
				<div className="h-screen w-screen bg-white " />
			</LoadingProvider>
		);
	}
}
