import { DetailPageLayout } from 'components/molecules';
import { translations, t } from 'utils';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm, useFormContext } from 'react-hook-form';
import {
	Button,
	ButtonGroup,
	Card,
	Input,
	Modal,
	Select,
	Switch,
} from 'components/atoms';
import { ITemplate } from 'models/ITemplate';
import ReactQuill from 'react-quill';
import TemplateApiService from 'services/TemplateApiService';
import { IApiResponse, IErrorResponse } from 'models';
import { AlertHelper } from 'helpers';
import { isEmpty } from 'lodash';
import {
	IMarketingEmail,
	IMarketingEmailSendRequest,
} from 'models/IMarketingEmail';
import EmailBlastApiService from 'services/EmailBlastApiService';
import { useAppSelector } from 'helpers/hookHelpers';

const HaveNotVisited = () => {
	const numberDays = [14, 21, 30, 60, 90, 180, 365];
	const buttons = numberDays.map((number) => {
		return {
			label: `${number} ` + t(translations.textMessageDetail.days),
			value: number,
		};
	});

	const { watch, setValue } = useFormContext<IMarketingEmail>();
	const value = watch('haveNotVisitedIn');
	const sendToAllCustomers = watch('sendToAllCustomers');
	return (
		<div>
			<label className="label font-medium ">
				<span className="label-text text-md">
					{t(translations.textMessageDetail.haveNotVisited)}
				</span>
			</label>
			<ButtonGroup<{ label: string; value: number }>
				buttons={buttons}
				valueField="value"
				titleField="label"
				selectedId={value}
				onChange={(value) => setValue('haveNotVisitedIn', value.value)}
				disabled={sendToAllCustomers}
			/>
		</div>
	);
};
const BirthdayMonth = () => {
	const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const renderMonthLabel = (value: number) => {
		switch (value) {
			case 1:
				return t(translations.month.january);
			case 2:
				return t(translations.month.february);
			case 3:
				return t(translations.month.march);
			case 4:
				return t(translations.month.april);
			case 5:
				return t(translations.month.may);
			case 6:
				return t(translations.month.june);
			case 7:
				return t(translations.month.july);
			case 8:
				return t(translations.month.august);
			case 9:
				return t(translations.month.september);
			case 10:
				return t(translations.month.october);
			case 11:
				return t(translations.month.november);
			default:
				return t(translations.month.december);
		}
	};
	const buttons = months.map((number) => {
		return {
			label: renderMonthLabel(number),
			value: number,
		};
	});

	const { watch, setValue } = useFormContext<IMarketingEmail>();

	const value = watch('birthdayMonth');
	const sendToAllCustomers = watch('sendToAllCustomers');
	return (
		<div>
			<label className="label font-medium ">
				<span className="label-text text-md">
					{t(translations.textMessageDetail.birthdayMonth)}
				</span>
			</label>
			<ButtonGroup<{ label: string; value: number }>
				buttons={buttons}
				valueField="value"
				titleField="label"
				selectedId={value}
				onChange={(value) => setValue('birthdayMonth', value.value)}
				disabled={sendToAllCustomers}
			/>
		</div>
	);
};

const ReviewGroup = () => {
	const buttons = [
		{
			label: '1 - 3 stars',
			value: 0,
		},
		{
			label: '4 - 5 stars',
			value: 1,
		},
	];

	const { watch, setValue } = useFormContext<IMarketingEmail>();

	const value = watch('reviewGroup');
	const sendToAllCustomers = watch('sendToAllCustomers');
	return (
		<div>
			<label className="label font-medium ">
				<span className="label-text text-md">
					{t(translations.textMessageDetail.reviewGroup)}
				</span>
			</label>
			<ButtonGroup<{ label: string; value: number }>
				buttons={buttons}
				valueField="value"
				titleField="label"
				selectedId={value}
				onChange={(value) => setValue('reviewGroup', value.value)}
				disabled={sendToAllCustomers}
			/>
		</div>
	);
};

const FormGroupCustomer = () => {
	const { setValue, watch } = useFormContext<IMarketingEmail>();
	const sendToAllCustomers = watch('sendToAllCustomers');
	return (
		<Card title={t(translations.emailBlastDetail.titleGroupCustomer)}>
			<div className="grid gap-y-4">
				<div className="grid grid-cols-4 md:grid-cols-8">
					<Switch
						label={t(translations.textMessageDetail.allCustomer)}
						value={sendToAllCustomers}
						onChange={() => {
							setValue('sendToAllCustomers', !sendToAllCustomers);
						}}
						primary
						fullWidth
					/>
				</div>
				<HaveNotVisited />
				<BirthdayMonth />
				<ReviewGroup />
			</div>
		</Card>
	);
};
interface IFormContentProps {
	email?: IMarketingEmail;
	templates: ITemplate[];
}
const FormContent = (props: IFormContentProps) => {
	const reactQuillRef = useRef(null) as any;
	const [valueContent, setValueContent] = useState<string>(
		props.email?.content || ''
	);
	const {
		formState: { errors },
		setValue,
		register,
		watch,
	} = useFormContext<IMarketingEmail>();
	const templateId = watch('emailTemplateId');
	useEffect(() => {
		register('emailTemplateId', {
			required: t(translations.emailBlastDetail.messageEmailTemplate),
		});
		register('content', {
			required: t(translations.emailBlastDetail.messageEmailContent),
		});
	}, [register]);
	useEffect(() => {
		setValue('content', valueContent);
	}, [valueContent]);
	return (
		<Card>
			<div className="grid gap-y-4">
				<Input
					label={t(translations.emailBlast.emailSubject)}
					placeholder={t(translations.emailBlastDetail.placeHolderSubject)}
					maxLength={256}
					error={errors.subject?.message}
					{...register('subject', {
						required: t(translations.emailBlastDetail.messageEmailSubject),
					})}
				/>
				<Select<ITemplate>
					options={props.templates}
					titleField="subject"
					keyField="id"
					label={t(translations.emailBlastDetail.emailTemplate)}
					title={t(translations.emailBlastDetail.placeHolderEmailTemplate)}
					onChange={(value) => {
						setValue('emailTemplateId', value.id);
					}}
					valueId={templateId}
					fullWidth
					error={errors.emailTemplateId?.message}
				/>
				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{t(translations.templateDetail.templateEmailContent)}
						</span>
					</label>
					<ReactQuill
						ref={reactQuillRef}
						theme="snow"
						value={valueContent}
						onChange={setValueContent}
					/>
					{errors.content?.message && (
						<p className="ml-2 mt-2 text-left w-full text-error">
							{errors.content?.message}
						</p>
					)}
				</div>
			</div>
		</Card>
	);
};

export const EmailBlastDetailPage = () => {
	const location = useLocation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [isPreview, setIsPreview] = useState(false);
	const { id } = useParams() as { id: string };
	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const [currentEmail, setCurrentEmail] = useState<
		IMarketingEmail | undefined
	>();
	const [templates, setTemplates] = useState<ITemplate[]>([]);

	const form = useForm<IMarketingEmail>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/email-blast');
	};

	const fetchEmailDetail = async (id: string) => {
		setLoading(true);
		try {
			const result = (await EmailBlastApiService.getMarketingEmailById(
				id
			)) as IApiResponse<IMarketingEmail>;
			if (result.succeeded) {
				setCurrentEmail(result.data);
				if (!isEmpty(result.data)) {
					form.setValue('subject', result.data?.subject || '');
					form.setValue(
						'sendToAllCustomers',
						result.data?.sendToAllCustomers || false
					);
					form.setValue('haveNotVisitedIn', result.data!.haveNotVisitedIn);
					form.setValue('birthdayMonth', result.data!.birthdayMonth);
					form.setValue('reviewGroup', result.data!.reviewGroup);
					form.setValue('emailTemplateId', result.data?.emailTemplateId || '');
					form.setValue('content', result.data?.content || '');
				}
			}
			setLoading(false);
		} catch (error) {}
	};
	const fetchAllTemplateEmail = async () => {
		setLoading(true);
		try {
			const res = (await TemplateApiService.getTemplates()) as IApiResponse<
				ITemplate[]
			>;
			if (res.succeeded) {
				setTemplates(res.data || []);
			} else {
				const error = res as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const submitForm = async (formData: IMarketingEmail) => {
		const data: Partial<IMarketingEmail> = {
			id: isAdd() ? undefined : id,
			subject: formData.subject,
			emailTemplateId: formData.emailTemplateId,
			content: formData.content,
			sendToAllCustomers: formData.sendToAllCustomers,
			haveNotVisitedIn: formData.haveNotVisitedIn,
			birthdayMonth: formData.birthdayMonth,
			reviewGroup: formData.reviewGroup,
			branchId: currentEmail?.branchId
				? currentEmail?.branchId
				: currentBranch?.id,
		};
		setLoading(true);
		try {
			let response = isAdd()
				? await EmailBlastApiService.addMarketingEmail(data)
				: await EmailBlastApiService.editMarketingEmail(data);
			if (response.succeeded) {
				AlertHelper.showSuccess(
					isAdd()
						? t(translations.emailBlastDetail.messageAddEmailSuccess)
						: t(translations.emailBlastDetail.messageUpdateEmailSuccess)
				);
				goBack();
			} else {
				const error = response as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const handleSend = async () => {
		const data: Partial<IMarketingEmailSendRequest> = {
			marketingEmailIds: [id],
		};
		setLoading(true);
		try {
			const result = await EmailBlastApiService.sendMarketingEmail(data);
			if (result.succeeded) {
				setLoading(false);
				AlertHelper.showSuccess(
					t(translations.emailBlastDetail.messageSendSuccess)
				);
				goBack();
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
			setLoading(false);
		} catch (error) {}
	};
	useEffect(() => {
		const ac = new AbortController();
		fetchAllTemplateEmail();
		if (!isAdd()) {
			fetchEmailDetail(id);
		}
		return () => {
			ac.abort();
		};
	}, []);
	return (
		<DetailPageLayout
			title={t(
				isAdd()
					? translations.emailBlastDetail.addNew
					: translations.emailBlastDetail.editEmail
			)}
			loading={loading}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			extraRight={
				isAdd() ? (
					<Button
						className="btn btn-outline"
						small
						title={t(translations.templateDetail.preview)}
						onClick={(e) => {
							setIsPreview(true);
						}}
					/>
				) : (
					<>
						<Button
							className="btn btn-outline"
							small
							title={t(translations.templateDetail.preview)}
							onClick={(e) => {
								setIsPreview(true);
							}}
						/>
						<Button
							className="btn btn-outline"
							small
							primary
							title={t(translations.textMessageDetail.send)}
							onClick={(e) => {
								handleSend();
							}}
						/>
					</>
				)
			}
		>
			{isAdd() || !isEmpty(currentEmail) ? (
				<>
					<FormGroupCustomer />
					<FormContent email={currentEmail} templates={templates} />
				</>
			) : null}
			<Modal
				title={t(translations.templateDetail.preview)}
				isOpen={isPreview}
				content={
					<div className="p-8">
						<h3 className="text-lg font-semibold my-2">
							{t(translations.emailBlast.emailSubject)}
						</h3>
						<p>{form.watch('subject')}</p>
						<h3 className="text-lg font-semibold my-2">
							{t(translations.emailBlastDetail.content)}
						</h3>
						<div
							dangerouslySetInnerHTML={{
								__html: form.watch('content'),
							}}
						></div>
					</div>
				}
				onCancel={() => setIsPreview(false)}
			/>
		</DetailPageLayout>
	);
};
