import {
	IFinancialReport,
	IPayrollDetail,
	IPayrollOverview,
	IPayrollSummary,
} from 'models';
import { IFinancialReportRequest, IPayrollRequest } from 'models/RequestModels';
import { BaseApiService } from 'services/BaseApiService';

const url = '/Report';

class ReportApi extends BaseApiService {
	public getPayrollSummary = (params: IPayrollRequest) =>
		this.get<IPayrollSummary>(`${url}/payroll-summary`, { params });
	public getPayrollDetail = (params: IPayrollRequest) =>
		this.get<IPayrollDetail>(`${url}/employee-payroll-detail`, { params });
	public getPayrollOverview = (params: IPayrollRequest) =>
		this.get<IPayrollOverview>(`${url}/employee-payroll-overview`, { params });
	public getOwnerFinancialSummaryReport = (request: IFinancialReportRequest) =>
		this.get<IFinancialReport>(`${url}/owner-report-financial-summary`, {
			params: request,
		});
}

export default new ReportApi(true);
