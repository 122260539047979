import { Container, Input, Modal, Switch, TextArea } from 'components/atoms';
import { isEmpty } from 'lodash';
import { IPaymentMethod } from 'models';
import { translations, t } from 'utils';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { PaymentMethodActions } from 'redux/actions';
import { PaymentType } from 'utils/Consts';

interface IPaymentMethodModalProps {
	isOpen: boolean;
	onCancel: () => void;
	dataPaymentMethod?: IPaymentMethod;
}
export const PaymentMethodModal = (props: IPaymentMethodModalProps) => {
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors },
	} = useForm<IPaymentMethod>({
		reValidateMode: 'onChange',
		mode: 'onChange',
		defaultValues: {
			status: 0,
		},
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isEmpty(props.dataPaymentMethod)) {
			reset(props.dataPaymentMethod);
		}
	}, []);
	const status = watch('status');

	const onSubmit = async (formData: IPaymentMethod) => {
		console.log(formData);
		if (isEmpty(props.dataPaymentMethod)) {
			dispatch(
				PaymentMethodActions.createPaymentMethod.request({
					...formData,
					paymentType: PaymentType.OTHER,
				})
			);
		} else {
			dispatch(
				PaymentMethodActions.updatePaymentMethod.request({
					...formData,
					paymentType: PaymentType.OTHER,
				})
			);
		}
		props.onCancel();
	};
	return (
		<Modal
			title={
				isEmpty(props.dataPaymentMethod)
					? t(translations.branchSetting.addPaymentMethod)
					: t(translations.branchSetting.editPaymentMethod)
			}
			isOpen={props.isOpen}
			content={
				<Container>
					<div className="grid gap-y-2">
						<form>
							<Input
								label={t(translations.branchSetting.paymentMethodName)}
								placeholder={t(translations.placeHolder.paymentMethod)}
								maxLength={256}
								error={errors.name?.message}
								{...register('name', {
									required: t(
										translations.branchSetting.paymentMethodNameMessage
									),
								})}
							/>
							<Input
								label={t(translations.branchSetting.paymentMethodCode)}
								placeholder={t(
									translations.branchSetting.paymentMethodCodePlaceHolder
								)}
								maxLength={256}
								error={errors.code?.message}
								{...register('code', {
									required: t(
										translations.branchSetting.paymentMethodCodeMessage
									),
								})}
							/>
							<div className="grid grid-cols-2">
								<Switch
									label={t(translations.categoryDetail.labelStatus)}
									value={status === 1 ? true : false}
									onChange={(value) => {
										setValue('status', value ? 1 : 0);
									}}
									primary
								/>
							</div>
							<TextArea
								label={t(translations.description)}
								placeholder={t(
									translations.placeHolder.paymentMethodDescription
								)}
								error={errors.description?.message}
								{...register('description')}
							/>
						</form>
					</div>
				</Container>
			}
			onCancel={props.onCancel}
			canConfirm
			onConfirm={handleSubmit(onSubmit)}
		/>
	);
};
