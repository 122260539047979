import _ from 'lodash';
import { IAddRefund, IBillingDetail, IRefund } from 'models';
import { IBilling } from 'models/IBilling';
import { ITip } from 'models/ITip';
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useParams } from 'react-router-dom';
import BillingApiService from 'services/BillingApiService';
import TipApiService from 'services/TipApiService';
import { PaymentType, TipType } from 'utils/Consts';
import { useAppSelector } from 'helpers/hookHelpers';
import { AlertHelper, BookingHelper } from 'helpers';
import momentTz from 'moment-timezone';
import { RefundApiSerivce } from 'services';
import { t, translations } from 'utils';
import {
	cloverRefund,
	showReceiptOptions,
	voidPayment,
	welcome,
} from 'services/CloverApiService';

const getTipApi = (tipType: TipType, tip: ITip) => {
	switch (tipType) {
		case TipType.AUTO:
			return TipApiService.addTips(tip);
		case TipType.MANUALLY:
			return TipApiService.addBillTipsManually(tip);
		default:
			return TipApiService.addBillTipsBalance(tip);
	}
};
interface IBillDetailContext {
	bill?: IBilling;
	billDetails?: Partial<IBillingDetail>[];
	tip?: ITip;
	tipType?: TipType;
	editing?: boolean;
	refunding?: boolean;
	refundPartial?: boolean;
	refund?: Partial<IRefund>;
	refundDetails?: Partial<IBillingDetail>[];
	newTip?: ITip;
	loading?: boolean;
	setRefund: (refund: Partial<IRefund>) => void;
	setEditing: (editing: boolean) => void;
	setRefunding: (refunding: boolean) => void;
	setRefundPartial: (refundPartial: boolean) => void;
	setRefundDetails: (refundDetails: Partial<IBillingDetail>[]) => void;
	updateBillDetail: (
		billDetail: Partial<IBillingDetail>,
		index: number
	) => void;
	removeRefundDetail: (billDetailId: string) => void;
	createRefund: (employeeCode?: string) => void;
	voidBill: () => void;
	addNewBillDetail: (billDetail: Partial<IBillingDetail>) => void;
	saveBill: () => void;
	cancelEditBill: () => void;
	setNewTip: (newTip: ITip) => void;
	setNewTipType: (tipType: number) => void;
	setLoading: (loading: boolean) => void;
}
const BillDetailContext = createContext<IBillDetailContext>({
	setRefund: () => {},
	setEditing: () => {},
	setRefunding: () => {},
	setRefundPartial: () => {},
	setRefundDetails: () => {},
	updateBillDetail: () => {},
	removeRefundDetail: () => {},
	createRefund: () => {},
	voidBill: () => {},
	addNewBillDetail: () => {},
	saveBill: () => {},
	cancelEditBill: () => {},
	setNewTip: () => {},
	setNewTipType: () => {},
	setLoading: () => {},
});
export const useBillDetailContext = () => {
	return useContext(BillDetailContext);
};

export const BillDetailProvider: React.FC<{
	children?: React.ReactNode;
	billId?: string;
}> = ({ children, billId }) => {
	const [bill, setBill] = useState<IBilling>();
	const [billDetails, setBillDetails] = useState<Partial<IBillingDetail>[]>();
	const [tip, setTip] = useState<ITip>();
	const [newTip, setNewTip] = useState<ITip>();
	const [newTipType, setNewTipType] = useState(TipType.AUTO);
	const [editing, setEditing] = useState(false);
	const [refunding, setRefunding] = useState(false);
	const [refundPartial, setRefundPartial] = useState(false);
	const [refund, setRefund] = useState<Partial<IRefund>>();
	const [refundDetails, setRefundDetails] = useState<Partial<IBillingDetail>[]>(
		[]
	);
	const [loading, setLoading] = useState(false);
	const params = useParams<{ id: string }>();

	const user = useAppSelector((state) => state.UserReducer.employee);
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);

	const getBillById = async (id: string) => {
		const [response, tipResponse] = await Promise.all([
			BillingApiService.getBillById(id),
			TipApiService.getTipByBillId(id),
		]);
		if (response && response.data) {
			setBill(response.data);
			setBillDetails(response.data.billDetails.filter((x) => !x.giftCardId));
			const refundResponse = response.data.refundBillId
				? await BillingApiService.getBillById(response.data.refundBillId)
				: undefined;
			if (refundResponse && refundResponse.data) {
				setRefundDetails(refundResponse.data.billDetails);
			}
		}
		if (tipResponse && tipResponse.data) {
			const totalAmount = _.sumBy(tipResponse.data, (x) => x.tipAmount);
			if (totalAmount > 0) {
				const newTip: ITip = {
					billId: id,
					date: tipResponse.data[0].date,
					tips: tipResponse.data,
					totalTip: totalAmount,
				};
				setTip(newTip);
			}
		}
	};
	const updateBillDetail = (
		billDetail: Partial<IBillingDetail>,
		index: number
	) => {
		const newBillDetails = Array.from(billDetails || []);
		newBillDetails[index] = {
			...newBillDetails[index],
			...billDetail,
		};
		setBillDetails(newBillDetails);
	};

	const addNewBillDetail = (billDetail: Partial<IBillingDetail>) => {
		const newBillDetails = Array.from(billDetails || []);
		newBillDetails.push({ ...billDetail, bookingId: bill?.bookingId });
		setBillDetails(newBillDetails);
	};

	const saveBill = async () => {
		setLoading(true);
		const giftCardDetails = bill?.billDetails.filter((x) => x.giftCardId) || [];
		const updateBill = await BillingApiService.updateBill({
			...bill,
			customer: undefined,
			totalAmount: undefined,
			realAmount: undefined,
			totalTax: undefined,
			totalQuantity: undefined,
			totalExtraAmount: 0,
			billDetails: _.clone(billDetails || [])
				.map((x) => {
					return {
						...x,
						item: undefined,
						stylist: undefined,
					};
				})
				.concat(
					giftCardDetails.map((x) => {
						return {
							...x,
							stylist: undefined,
							item: undefined,
						};
					})
				),
		});
		const deleteTipResponses =
			tip && newTip
				? await Promise.all(
						tip.tips?.forEach((x) => TipApiService.deleteTip(x.id!)) || []
				  )
				: undefined;
		const tipResponse = newTip
			? await getTipApi(newTipType, {
					...newTip,
					billId: bill?.id,
			  })
			: undefined;
		if (updateBill.succeeded && updateBill.data) {
			AlertHelper.showSuccess('Update bill successfully!');
			getBillById(params.id);
			setEditing(false);
		} else {
			AlertHelper.showError(updateBill);
		}
		setLoading(false);
	};
	const cancelEditBill = () => {
		setBillDetails(bill?.billDetails.filter((x) => !x.giftCardId));
		setEditing(false);
	};
	const removeRefundDetail = (billDetailId: string) => {
		const newRefundDetails = refundDetails.filter(
			(e) => e.billDetailId !== billDetailId
		);
		setRefundDetails(newRefundDetails);
	};

	const createRefund = async (employeeCode?: string) => {
		setLoading(true);
		const request: IAddRefund = {
			date: BookingHelper.convertDateRequest(momentTz().startOf('date')),
			billId: bill?.id,
			customerId: bill?.customerId,
			refundSharingType: refund?.refundSharingType,
			paymentType: PaymentType.CASH,
			description: '',
			employeeCode: employeeCode
				? employeeCode
				: user?.code || bill?.billDetails[0].stylist?.code,
			cardCharge: 0,
			billDetails: refundDetails || [],
			branchId: branch?.id,
		};
		try {
			const result = await RefundApiSerivce.createRefund(request);
			if (result.succeeded) {
				AlertHelper.showSuccess(t(translations.billing.createRefundSuccess));
				setRefunding(false);
				setRefundPartial(false);
				bill?.transactions?.forEach(async (x) => {
					if (x.paymentType === 2) {
						await cloverRefund(
							`${x.transactionReferenceNumber}`,
							(refund?.totalAmount || 0) * 100,
							refund?.totalAmount === x.amount
						);
						await showReceiptOptions('Print refund receipt');
						welcome();
					}
				});
				getBillById(params.id);
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};
	const voidBill = async () => {
		setLoading(true);
		const request = {
			id: bill?.id,
		};
		try {
			const result = await BillingApiService.voidBill(request);
			if (result.succeeded) {
				AlertHelper.showSuccess(
					t(translations.billing.messageVoidSuccessfully)
				);
				getBillById(params.id);
				if (
					bill?.transactions?.some(
						(e) => e.paymentMethod?.code.toUpperCase() === 'CLOVER'
					)
				) {
					const cloverVoidBill = bill.transactions.filter(
						(e) => e.paymentMethod?.code.toUpperCase() === 'CLOVER'
					);
					await Promise.all(
						cloverVoidBill.map((e) =>
							voidPayment(e.transactionReferenceNumber!)
						)
					);
					await showReceiptOptions('Print refund receipt');
					welcome();
				}
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		getBillById(params.id);
	}, [params]);

	const value: IBillDetailContext = {
		bill: useMemo(() => bill, [bill]),
		billDetails: useMemo(() => billDetails, [billDetails]),
		tip: useMemo(() => tip, [tip]),
		editing: useMemo(() => editing, [editing]),
		refunding: useMemo(() => refunding, [refunding]),
		refundPartial: useMemo(() => refundPartial, [refundPartial]),
		refund: useMemo(() => refund, [refund]),
		refundDetails: useMemo(() => refundDetails, [refundDetails]),
		newTip: useMemo(() => newTip, [newTip]),
		loading: useMemo(() => loading, [loading]),
		setRefund,
		setEditing,
		setRefunding,
		setRefundPartial,
		setRefundDetails,
		updateBillDetail,
		removeRefundDetail,
		createRefund,
		voidBill,
		addNewBillDetail,
		saveBill,
		cancelEditBill,
		setNewTip,
		setNewTipType,
		setLoading,
	};

	return (
		<BillDetailContext.Provider value={value}>
			{children}
		</BillDetailContext.Provider>
	);
};
