import { DetailPageLayout } from 'components/molecules';
import { translations, t } from 'utils';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm, useFormContext } from 'react-hook-form';
import { Button, Card, Input, Modal } from 'components/atoms';
import { ITemplate } from 'models/ITemplate';
import ReactQuill from 'react-quill';
import TemplateApiService from 'services/TemplateApiService';
import { IApiResponse, IErrorResponse } from 'models';
import { AlertHelper } from 'helpers';
import { isEmpty } from 'lodash';

const FormContent = (props: { template?: ITemplate }) => {
	const reactQuillRef = useRef(null) as any;
	const [valueContent, setValueContent] = useState<string>(
		props.template?.formatedHtmlBody || ''
	);
	const {
		formState: { errors },
		setValue,
		register,
	} = useFormContext<ITemplate>();
	useEffect(() => {
		setValue('bodyContent', reactQuillRef.current.getEditor().getText());
		setValue('formatedHtmlBody', valueContent);
	}, [valueContent]);
	return (
		<Card>
			<div className="grid gap-y-4">
				<Input
					label={t(translations.templateDetail.templateName)}
					placeholder={t(translations.templateDetail.inputTemplateName)}
					maxLength={256}
					error={errors.subject?.message}
					{...register('subject', {
						required: t(translations.templateDetail.messageTemplateName),
					})}
				/>
				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{t(translations.templateDetail.templateEmailContent)}
						</span>
					</label>
					<ReactQuill
						ref={reactQuillRef}
						theme="snow"
						value={valueContent}
						onChange={setValueContent}
					/>
				</div>
			</div>
		</Card>
	);
};

export const TemplateDetailPage = () => {
	const location = useLocation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [isPreview, setIsPreview] = useState(false);
	const { id } = useParams() as { id: string };
	const [currentTemplate, setCurrentTemplate] = useState<
		ITemplate | undefined
	>();

	const form = useForm<ITemplate>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/email-blast');
	};

	const fetchTemplateDetail = async (id: string) => {
		setLoading(true);
		try {
			const result = (await TemplateApiService.getTemplateById(
				id
			)) as IApiResponse<ITemplate>;
			if (result.succeeded) {
				setCurrentTemplate(result.data);
				form.setValue('subject', result.data?.subject || '');
				form.setValue('bodyContent', result.data?.bodyContent || '');
				form.setValue('formatedHtmlBody', result.data?.formatedHtmlBody || '');
			}
			setLoading(false);
		} catch (error) {}
	};
	const onDeleteTemplate = async () => {
		setLoading(true);
		try {
			const result = await TemplateApiService.deleteTemplate(id);
			if (result.succeeded) {
				AlertHelper.showSuccess(
					t(translations.templateDetail.messageDeleteSuccess)
				);
				goBack();
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const submitForm = async (formData: ITemplate) => {
		const data: Partial<ITemplate> = {
			id: isAdd() ? undefined : id,
			subject: formData.subject,
			bodyContent: formData.bodyContent,
			formatedHtmlBody: formData.formatedHtmlBody,
		};
		setLoading(true);
		try {
			let response = isAdd()
				? await TemplateApiService.addTemplate(data)
				: await TemplateApiService.editTemplate(data);
			if (response.succeeded) {
				AlertHelper.showSuccess(
					isAdd()
						? t(translations.templateDetail.messageAddTemplateSuccess)
						: t(translations.templateDetail.messageUpdateTemplateSuccess)
				);
				goBack();
			} else {
				const error = response as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		const ac = new AbortController();
		if (!isAdd()) {
			fetchTemplateDetail(id);
		}
		return () => {
			ac.abort();
		};
	}, []);
	return (
		<DetailPageLayout
			title={t(
				isAdd()
					? translations.templateDetail.addNew
					: translations.templateDetail.editTemplate
			)}
			loading={loading}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			showDelete={!isAdd()}
			onDelete={onDeleteTemplate}
			deleteTitle={t(translations.delete)}
			deleteSubTitle={t(translations.templateDetail.deleteConfirmContent)}
			extraRight={
				<Button
					className="btn btn-outline"
					small
					title={t(translations.templateDetail.preview)}
					onClick={(e) => {
						setIsPreview(true);
					}}
				/>
			}
		>
			{isAdd() || !isEmpty(currentTemplate) ? (
				<FormContent template={currentTemplate} />
			) : null}
			<Modal
				title={t(translations.templateDetail.preview)}
				isOpen={isPreview}
				content={
					<div className="p-8">
						<div
							dangerouslySetInnerHTML={{
								__html: form.getValues('formatedHtmlBody'),
							}}
						></div>
					</div>
				}
				onCancel={() => setIsPreview(false)}
			/>
		</DetailPageLayout>
	);
};
