/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Table, { IColumnProps, ITableRef } from 'components/atoms/Table';
import { AlertHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import { toTimeZone } from 'helpers/timeHelper';
import { IEmployee, IHolidayModel } from 'models';
import { IGetAllHolidayRequest } from 'models/RequestModels';
import { IPaginateResponse } from 'models/ResponseModels';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { HolidayActions } from 'redux/actions';
import { HolidayApiService } from 'services';
import { t, translations } from 'utils';
import { TDateRange } from 'components/molecules/ModalCalendar';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { CustomInput } from 'pages/SettingStack/ShopSetting';
import { StaffPickerModal } from 'components/molecules/StaffPickerModal';
import { LoadingProvider } from 'contexts/LoadingContext';
import { history } from 'routers';
import { Button, Icon } from 'components/atoms';
import { mdiPlus } from '@mdi/js';

export const StaffHoliday = () => {
	const { employees } = useAppSelector((state) => state.EmployeeReducer);
	const [selectedStaff, setSelectedStaff] = useState<Partial<IEmployee>>({
		firstName: t(translations.all),
	});
	const [holidays, setHolidays] =
		useState<IPaginateResponse<THolidayLocal[]>>();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState<boolean>();
	const [dateFilter, setDateFilter] = useState<Required<TDateRange>>({
		fromDate: moment().startOf('date'),
		toDate: moment().startOf('date').add(3, 'month'),
	});
	const [showStaffPickerModal, setShowStaffPickerModal] = useState<boolean>();
	const baseRequest = useMemo(
		(): IGetAllHolidayRequest => ({
			pageSize: 20,
			pageNumber: 1,
			employeeHoliday: true,
			employeeId: selectedStaff?.id,
			fromDate: dateFilter.fromDate.format('YYYY-MM-DD'),
			toDate: dateFilter.toDate.format('YYYY-MM-DD'),
		}),
		[selectedStaff, dateFilter]
	);
	const tableRef = useRef<ITableRef>(null);

	useEffect(() => {
		getAllHoliday(baseRequest);
	}, [baseRequest]);

	const staffCol: IColumnProps<THolidayLocal> = {
		header: t(translations.holiday.staff),
		key: 'employeeName',
	};
	const startAtCol: IColumnProps<THolidayLocal> = {
		header: t(translations.holiday.startAt),
		key: 'startTime',
		renderCell: (item) => (
			<>
				<div>
					{!item.startTime
						? moment(item.fromDate).format('MMM Do, YYYY')
						: toTimeZone(item.startTime).format('MMM Do, YYYY')}
				</div>
				<div>
					{!item.startTime
						? moment(item.fromDate).startOf('date').format('hh:mm A')
						: `${toTimeZone(item.startTime).format('hh:mm A')}`}
				</div>
			</>
		),
	};
	const endAtCol: IColumnProps<THolidayLocal> = {
		header: t(translations.holiday.endAt),
		key: 'endTime',
		renderCell: (item) => (
			<>
				<div>
					{!item.endTime
						? moment(item.toDate).format('MMM Do, YYYY')
						: toTimeZone(item.endTime).format('MMM Do, YYYY')}
				</div>
				<div>
					{!item.endTime
						? moment(item.toDate).endOf('date').format('hh:mm A')
						: toTimeZone(item.endTime).format('hh:mm A')}
				</div>
			</>
		),
	};
	const descriptionCol: IColumnProps<THolidayLocal> = {
		header: t(translations.description),
		key: 'description',
	};

	const getAllHoliday = async (request: IGetAllHolidayRequest) => {
		setIsLoading(true);
		try {
			const result = (await HolidayApiService.getAllHoliday(
				request
			)) as IPaginateResponse<IHolidayModel[]>;
			if (result.succeeded) {
				setHolidays({
					...result,
					data: formatHolidays(result.data || [], employees || []),
				});
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="flex flex-col h-full overflow-hidden">
			<div className="grid grid-cols-6 w-full gap-4 px-4">
				<CustomInput
					placeholder={t(translations.payrollDetailReport.searchEmployee)}
					className={'caret-transparent cursor-pointer font-bold'}
					onClick={() => setShowStaffPickerModal(true)}
					value={`${selectedStaff?.firstName || ''} ${
						selectedStaff?.lastName || ''
					}`}
				/>
				<DateRangePicker
					onSelectDateRange={(value) =>
						setDateFilter(value as Required<TDateRange>)
					}
					disabled={isLoading}
					selectedDateRange={dateFilter}
				/>
				<div className="col-span-3" />
				<Button
					title={t(translations.addNew)}
					small
					onClick={() => history.push('/holiday/new/staff')}
					iconBefore={<Icon path={mdiPlus} />}
				/>
			</div>

			<LoadingProvider loading={isLoading} className="h-full overflow-hidden">
				<Table<THolidayLocal>
					ref={tableRef}
					columns={[staffCol, startAtCol, endAtCol, descriptionCol]}
					onClickRow={(holiday) => {
						dispatch(HolidayActions.selectHoliday.request(holiday));
						history.push('/holiday/edit/' + holiday.id);
					}}
					data={holidays?.data || []}
					pagination={{
						onPageChange: (page) =>
							getAllHoliday({
								...baseRequest,
								pageNumber: page,
							}),
						page: holidays?.pageNumber || 1,
						totalItem: holidays?.totalRecords || 0,
						totalPage: holidays?.totalPages || 0,
						pageSize: baseRequest.pageSize,
					}}
					containerClassName={'h-full flex flex-col overflow-hidden'}
					className={'overflow-y-auto'}
				/>
			</LoadingProvider>
			<StaffPickerModal
				selectedStaff={selectedStaff}
				onSelectStaff={(staff) => {
					setShowStaffPickerModal(false);
					setSelectedStaff(staff);
				}}
				visible={showStaffPickerModal}
				all
			/>
		</div>
	);
};

type THolidayLocal = IHolidayModel & {
	employeeName: string;
};

const formatHolidays = (
	holidayDetails: IHolidayModel[],
	employees: IEmployee[]
) => {
	const employee = employees.filter((employee) =>
		holidayDetails.some(
			(holidayDetail) => employee.id === holidayDetail.employeeId
		)
	);
	return holidayDetails.map<THolidayLocal>((e) => {
		const currentEmployee = employee.find(
			(employee) => employee.id === e.employeeId
		);
		return {
			...e,
			employeeName: `${currentEmployee?.firstName || ''} ${
				currentEmployee?.lastName || ''
			}`,
		};
	});
};
