import { createReducer, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { listPageTourGuide } from 'models/TourGuide';
import { AppConfigActions } from '../actions';

export interface IAppConfigReducer {
	showLoading: boolean;
	showNotification: boolean;
	language: string;
	uuid?: string;
	calling?: boolean;
	useV1?: boolean;
	tourGuide: { [key in typeof listPageTourGuide[number]]: boolean };
}

const initialState: IAppConfigReducer = {
	showLoading: false,
	showNotification: false,
	language: 'en',
	tourGuide: {},
};

function isRequest(action: AnyAction) {
	return action.type.endsWith('_REQUEST') && !action.type.includes('LOCAL');
}
function notRequest(action: AnyAction) {
	return !action.type.endsWith('_REQUEST');
}
const AppConfigReducer = createReducer(
	initialState,
	(builder) =>
		builder
			.addCase(
				AppConfigActions.useV1.request,
				(state, action: PayloadAction<boolean>) => {
					state.useV1 = action.payload;
				}
			)
			.addCase(
				AppConfigActions.showLoading.request,
				(state, action: PayloadAction<boolean>) => {
					state.showLoading = action.payload;
				}
			)
			.addCase(
				AppConfigActions.setCalling.request,
				(state, action: PayloadAction<boolean>) => {
					state.calling = action.payload;
				}
			)
			.addCase(
				AppConfigActions.showNotification.request,
				(state, action: PayloadAction<boolean>) => {
					state.showNotification = action.payload;
				}
			)
			.addCase(
				AppConfigActions.chooseLanguage.request,
				(state, action: PayloadAction<string>) => {
					state.language = action.payload;
				}
			)
			.addCase(
				AppConfigActions.setUuid.request,
				(state, action: PayloadAction<string>) => {
					state.uuid = action.payload;
				}
			)
			.addCase(
				AppConfigActions.usedTourGuide.request,
				(
					state,
					action: PayloadAction<{
						key: typeof listPageTourGuide[number];
						used?: boolean;
					}>
				) => {
					let newTourGuide = _.isEmpty(state.tourGuide)
						? {}
						: _.clone(state.tourGuide);
					newTourGuide[action.payload.key] = action.payload.used || false;
					state.tourGuide = newTourGuide;
				}
			)
			.addMatcher(isRequest, (state) => {
				state.showLoading = true;
			})
			.addMatcher(notRequest, (state) => {
				state.showLoading = false;
			}).addMatcher
);

export default AppConfigReducer;
