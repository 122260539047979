import clsxm from 'clsxs/clsxm';
import { Description, Modal } from 'components/atoms';
import { LoadingProvider } from 'contexts/LoadingContext';
import { showError } from 'helpers/alertHelper';
import { formatBalance } from 'helpers/currencyHelper';
import _ from 'lodash';
import { IApiResponse } from 'models';
import { IGetAvailablePromotionsRequest, IPromotion } from 'models/IPromotion';
import moment from 'moment';
import { useCheckOutContext } from 'pages/CheckOut/context';
import { useEffect, useState } from 'react';
import PromotionApiService from 'services/PromotionApiService';
import { t, translations } from 'utils';

// const Descriptions = (props: {
// 	title: string;
// 	value?: string;
// 	className?: string;
// 	isHtml?: boolean;
// }) => {
// 	return (
// 		<div className="flex justify-between w-full">
// 			<div className="">{props.title}: </div>
// 			{props.isHtml ? (
// 				<div dangerouslySetInnerHTML={{ __html: props.value || '' }} />
// 			) : (
// 				<div className={clsxm('font-bold', props.className)}>
// 					{props.value || ''}
// 				</div>
// 			)}
// 		</div>
// 	);
// };

export const PromotionModal = (props: {
	onCancel: () => void;
	onOk: (promotion: IPromotion) => void;
}) => {
	const { bill, billDetails = [], childrenBills = [] } = useCheckOutContext();

	const [loading, setLoading] = useState(false);
	const [promotions, setPromotions] = useState<IPromotion[]>([]);

	useEffect(() => {
		getAvailablePromotions();
	}, []);

	const childBillDetails = _.flatMapDeep(
		childrenBills.map((x) => x.billDetails!)
	);
	const allBillDetails = billDetails?.concat(childBillDetails);

	const getAvailablePromotions = async () => {
		setLoading(true);
		const request: IGetAvailablePromotionsRequest = {
			date: moment.utc(moment()).format('MM-DD-YYYY HH:mm'),
			customerId: bill?.customerId || '',
			branchId: bill?.branchId!,
			bookingInfos: allBillDetails.map((x) => {
				return {
					itemId: x.itemId || '',
					price: _.sum([x.item?.price, x.extraAmount]),
				};
			}),
		};
		const res = await PromotionApiService.getAvailablePromotion(request);
		setLoading(false);
		const resData = res as IApiResponse<IPromotion[]>;
		if (resData.succeeded && !_.isEmpty(resData.data)) {
			setPromotions(resData.data!);
		} else {
			showError(res);
		}
	};
	const endDate = (promotion: IPromotion) => {
		return !_.isEmpty(promotion.endDateUtc)
			? `- ${moment(promotion.endDateUtc).format('MM/DD/YYYY')}`
			: `- ${t(translations.checkout.promotionInfinity)}`;
	};
	const applyPromotion = async (promotion: IPromotion) => {
		props.onOk(promotion);
	};
	return (
		<Modal
			isOpen={true}
			onCancel={() => props.onCancel && props.onCancel()}
			title={t(translations.checkout.applyPromotion)}
			className={'w-[600px]'}
			content={
				<div className="p-4 overflow-y-auto w-full h-full overflow-hidden ">
					<LoadingProvider loading={loading}>
						<ul
							id="promotions"
							className="bg-white mt-4 rounded-lg text-gray-900 list-none pl-0 "
						>
							{promotions.map((x) => {
								return (
									<li
										id="promotion"
										onClick={() => {
											applyPromotion(x);
										}}
										className={clsxm(
											'px-4 py-3 m-0 my-4 relative active:animate-pop',
											'duration-500 ease-in cursor-pointer border rounded-lg flex flex-col justify-between w-full'
										)}
									>
										<Description
											title={t(translations.checkout.promotion)}
											value={x.name}
											valueClassName="text-PROCESSING"
										/>
										<Description
											title={t(translations.checkout.promotionType)}
											value={x.promotionTypeCode}
										/>
										<Description
											title={t(translations.promotionDetail.discountAmount)}
											value={
												!x.usePercentage
													? formatBalance(x.discountAmount)
													: `${x.discountPercentage}%`
											}
										/>
										<Description
											title={t(translations.billing.time)}
											value={`${moment(x.startDateUtc).format(
												'MM/DD/YYYY'
											)} ${endDate(x)}`}
										/>
										<Description
											title={t(translations.description)}
											value={x.description}
											isHtml
										/>
									</li>
								);
							})}
						</ul>
					</LoadingProvider>
				</div>
			}
		/>
	);
};
