/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from 'components/atoms/Container';
import { LoadingProvider } from 'contexts/LoadingContext';
import { TimeHelper } from 'helpers';
import { useBookingHook } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { IBookingDetail, IService } from 'models';
import { IBookingSearchQuery } from 'models/RequestModels';
import moment from 'moment';
import { BookingHeader } from 'pages/Booking/Header';

import { MainBooking } from 'pages/Booking/MainBooking';
import { SideBooking } from 'pages/Booking/SideBooking';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { BookingActions } from 'redux/actions';
import { CATEGORY_COLORS } from 'utils/Colors';
import { t, translations } from 'utils';
import clsxm from 'clsxs/clsxm';
import { SearchInput } from 'components/molecules';
import { convertDurationToStringDetail } from 'helpers/timeHelper';
import { Status } from 'models/Base.model';

const Service = (props: any) => {
	const { service } = props;
	const categories = useAppSelector((state) =>
		state.CategoryReducer.categories.filter((x) => x.status === Status.ACTIVE)
	);
	const categoryIndex = _.findIndex(
		categories,
		(x) => x.id === service.itemCategoryId
	);
	const { currentIndex, bookingDetails, disabledEdit } = useBookingHook();
	const currentService =
		currentIndex !== undefined
			? bookingDetails[currentIndex]?.item || undefined
			: undefined;
	const color = CATEGORY_COLORS[categoryIndex] || _.last(CATEGORY_COLORS);
	return (
		<div
			onClick={() => {
				if (!disabledEdit) {
					props.onSelect();
				}
			}}
			key={`${service.id}`}
			className={clsxm(
				'service-item',
				'col-span-1 cursor-pointer aspect-[4/2.5] font-bold shadow-md rounded-lg overflow-hidden border-2 ',
				!disabledEdit && 'active:animate-pop duration-500 ease-in',
				disabledEdit && 'text-base-300',
				// currentService && currentService.id === service.id
				// 	? 'border-[3px] border-primary text-black' :
				'border-none'
			)}
			style={{ backgroundColor: color }}
		>
			<div
				className={clsxm(
					'w-full h-full p-2 flex flex-col justify-between  rounded-t-lg rounded-br-full bg-base-200'
					// currentService && currentService.id === service.id && "border-4 border-primary"
				)}
			>
				<div className="uppercase text-xs text-center break-words leading-4 line-clamp-2">
					{service.name}
				</div>
				<div className="text-center text-xs font-medium">{`(${t(
					translations.currency
				)}${service?.price} - ${convertDurationToStringDetail(
					service?.duration!
				)})`}</div>
			</div>
		</div>
	);
};
const ListServices = (props: any) => {
	const {
		bookingDetails,
		onSelectService,
		getAvailableStylistAndTimeBlocks,
		editBookingDetail,
		currentIndex,
		changeBookingDetail,
	} = useBookingHook();
	const services = useAppSelector((state) => state.ServiceReducer.services);
	const tempStylist = useAppSelector(
		(state) => state.BookingReducer.tempStylist
	);
	const listCategories = useAppSelector((state) =>
		state.CategoryReducer.categories.filter((x) => x.status === Status.ACTIVE)
	);
	const [lastEditService, setLastEditService] = useState(false);
	const [searchText, setSearchText] = useState<string>();
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const quickSale = queries.quickSale;
	let listServices = services
		.slice()
		.sort((sA, sB) =>
			(sA.indexNumber || 0) > (sB.indexNumber || 0)
				? 1
				: sA.indexNumber === sB.indexNumber
				? 0
				: -1
		)
		.filter(
			(x) =>
				!_.isEmpty(x.itemCategoryId) &&
				listCategories.map((x) => x.id).includes(x.itemCategoryId) &&
				(props.selectedCategory === 'all' ||
					x.itemCategoryId === props.selectedCategory) &&
				(_.isEmpty(searchText) ||
					x.name
						.toLocaleLowerCase()
						.includes(`${searchText || ''}`.toLocaleLowerCase()))
		);
	if (tempStylist) {
		listServices = listServices.filter((x) =>
			tempStylist.serviceIds?.includes(x.id)
		);
	}
	if (props.selectedCategory === 'all') {
		listServices = _.orderBy(listServices, (x) => x.name);
	} else {
		listServices = _.orderBy(listServices, 'itemCategoryId');
	}
	const onSelect = (service: IService) => {
		if (currentIndex !== undefined) {
			if (bookingDetails[currentIndex].itemId === service.id) {
				setLastEditService(false);
				return;
			}
		}
		if (editBookingDetail && !lastEditService && !quickSale) {
			getAvailableStylistAndTimeBlocks(
				{
					serviceId: service.id,
				},
				currentIndex,
				true
			);
			changeBookingDetail(
				{
					item: service,
					itemId: service.id,
				},
				currentIndex
			);
			setLastEditService(true);
		} else {
			onSelectService(service);
			setLastEditService(false);
			setTimeout(() => {
				if (!_.isEmpty(bookingDetails[0].item)) {
					getAvailableStylistAndTimeBlocks(
						{
							serviceId: service.id,
						},
						bookingDetails.length,
						true
					);
				}
			}, 200);
		}
	};
	return (
		<div className="col-span-5 flex flex-1 flex-col pl-4 p-4 bg-white rounded-r-lg overflow-hidden ">
			<div className="flex justify-between align-middle mb-2 ">
				<h3 className="mt-1">{t(translations.mainBooking.services)}</h3>
				<div className="w-1/3">
					<SearchInput
						placeholder={t(translations.placeHolder.searchService)}
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
					/>
				</div>
			</div>
			<div className="flex-1 overflow-y-auto pb-2">
				<div className="grid grid-cols-3 xl:grid-cols-6 2xl:grid-cols-10  gap-2 w-full  ">
					{listServices.map((x) => {
						return <Service onSelect={() => onSelect(x)} service={x} />;
					})}
				</div>
			</div>
		</div>
	);
};

const Category = (props: {
	active?: boolean;
	label: string;
	index?: number;
	onSelect: () => void;
}) => {
	const color = props.index
		? CATEGORY_COLORS[props.index] || _.last(CATEGORY_COLORS)
		: 'white';

	return (
		<div
			onClick={() => props.onSelect()}
			style={{ backgroundColor: color }}
			className={clsxm(
				'flex justify-center items-center font-bold cursor-pointer uppercase p-4 min-h-[50px] mx-1 rounded-lg shadow-lg mb-3 ',
				props.active && 'main-gradient-background'
			)}
		>
			{props.label}
		</div>
	);
};

const ListCategories = (props: any) => {
	const categories = useAppSelector((state) =>
		state.CategoryReducer.categories.filter((x) => x.status === Status.ACTIVE)
	);
	const [selectedCategory, setSelectedCategory] = useState('all');

	return (
		<div
			id="categories"
			className="w-full row-span-1 rounded-r-lg pl-4 grid grid-flow-col auto-cols-max overflow-x-auto pt-0 overflow-y-hidden "
		>
			<Category
				active={selectedCategory === 'all'}
				label={t(translations.all)}
				onSelect={() => {
					setSelectedCategory('all');
					props.onSelect('all');
				}}
			/>
			{categories.map((x, index) => {
				return (
					<Category
						active={selectedCategory === x.id}
						label={x.name}
						index={index}
						onSelect={() => {
							setSelectedCategory(x.id);
							props.onSelect(x.id);
						}}
					/>
				);
			})}
		</div>
	);
};

export const PriceList = () => {
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const [selectedCategory, setSelectedCategory] = useState('all');

	return (
		<Container className="px-0 h-screen overflow-hidden">
			<div className="grid h-full grid-cols-4 gap-4 overflow-hidden ">
				<div className="col-span-5 gap-2 grid grid-rows-[repeat(11,minmax(0,1fr))] overflow-hidden">
					<ListCategories onSelect={(x: any) => setSelectedCategory(x)} />
					<div className="row-[span_9_/_span_9] grid grid-cols-8 gap-2">
						<div className="col-span-8 h-full flex flex-col rounded-r-lg overflow-hidden ">
							<ListServices selectedCategory={selectedCategory} />
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};
