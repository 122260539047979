import { IExpense } from 'models';
import { IGetAllExpenseRequest } from 'models/RequestModels';
import { BaseApiService } from './BaseApiService';

const prefix = '/Expense';

class ExpenseApiService extends BaseApiService {
	public getAllExpenses = (request: IGetAllExpenseRequest) =>
		this.get<IExpense[]>(`${prefix}/get-all-expenses`, { params: request });
	public getExpenseById = (id: string) =>
		this.get<IExpense>(`${prefix}/get-expense/${id}`);
	public createExpense = (request: Partial<IExpense>) =>
		this.post<Partial<IExpense>, IExpense>(`${prefix}/create-expense`, request);
	public updateExpense = (request: IExpense) =>
		this.put<IExpense, IExpense>(`${prefix}/update-expense`, request);
	public deleteExpense = (id: string) =>
		this.delete<string>(`${prefix}/delete-expense/${id}`);
}

export default new ExpenseApiService(true);
