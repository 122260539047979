import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IShop } from 'models/IShop';
import { ShopActions } from 'redux/actions';

export interface IShopReducer {
	shopId?: string;
	shop?: IShop;
}

const initialState: IShopReducer = {};

const updateShopSuccess = (
	state: IShopReducer,
	action: PayloadAction<IShop>
) => {
	state.shop = action.payload;
	state.shopId = action.payload.id;
};

const ShopReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(
			ShopActions.getShop.success,
			(state: IShopReducer, action: PayloadAction<IShop>) => {
				state.shopId = action.payload.id;
				state.shop = action.payload;
			}
		)
		.addCase(ShopActions.updateShop.success, updateShopSuccess)
);

export default ShopReducer;
