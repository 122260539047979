import clsxm from 'clsxs/clsxm';
import { Avatar } from 'components/atoms/Avatar';
import { useAppSelector } from 'helpers/hookHelpers';
import { IEmployee } from 'models';
import React from 'react';
import { numberOfBookingOfEmployee } from 'redux/selectors/employee';

interface IStaffCardProps {
	staff?: IEmployee;
	isSelected?: boolean;
	onSelect: () => void;
	className?: string;
	label?: string;
	showTurn?: boolean;
	disable?: boolean;
}

export const StaffCard = (props: IStaffCardProps) => {
	const turn = useAppSelector((state) =>
		numberOfBookingOfEmployee(
			state.BookingReducer.bookings,
			`${props.staff?.id}`
		)
	);
	const turnLabel = `(${turn || 0})`;
	return (
		<div
			onClick={() => !props.disable && props.onSelect()}
			style={{ backgroundColor: props.staff ? props.staff.color : 'white' }}
			className={clsxm(
				'align-middle border-none w-full relative overflow-hidden border-2  duration-200 hover:text-black items-center justify-center inline-flex flex-col font-semibold text-center text-xs p-1 rounded-lg text-[#000] gap-1',
				props.isSelected && 'main-gradient-background',
				props.disable
					? 'disabled bg-base-200 cursor-not-allowed'
					: 'cursor-pointer shadow-md active:animate-pop',
				props.className
			)}
		>
			{/* {props.staff && (
				<div className="absolute top-0 left-0 bottom-0 right-0 rounded-lg bg-white rounded-br-full" />
			)} */}
			{props.staff && (
				<Avatar
					className="w-2/5"
					small
					source={props.staff.imageUrl}
					name={`${props.staff.firstName}`[0].toUpperCase()}
				/>
			)}
			{props.staff && !props.label && (
				<span className="hover:text-base-100 text-2xs z-10">{`${
					props.staff.firstName || props.staff.lastName || ''
				} ${(props.showTurn && turnLabel) || ''}`}</span>
			)}
			{!props.staff && props.label && (
				<span className="hover:text-base-100 z-10">{props.label}</span>
			)}
		</div>
	);
};
