import clsxm from 'clsxs/clsxm';
import { ReactNode } from 'react';
import Modal from 'react-modal';
import { t, translations } from 'utils';
import { Button } from './Button';

interface IConfirmModalProps {
	visible?: boolean;
	onClose?: () => void;
	onOpen?: () => void;
	title?: string;
	subTitle?: string;
	onPositiveClick?: () => void;
	onNegativeClick?: () => void;
	loading?: boolean;
	type?: 'warning' | 'error' | 'normal';
	children?: ReactNode;
}
export const ConfirmModal = ({
	onClose,
	onOpen,
	title,
	visible,
	subTitle,
	onNegativeClick,
	onPositiveClick,
	loading,
	type,
	children,
}: IConfirmModalProps) => {
	const positiveButtonColor = () => {
		switch (type) {
			case 'normal':
				return 'btn-primary';
			case 'error':
				return 'btn-error';
			case 'warning':
				return 'btn-warning';
			default:
				return 'btn-primary';
		}
	};
	return (
		<Modal
			isOpen={visible || false}
			style={{
				content: {
					width: '30rem',
					margin: '5% auto',
					padding: 0,
					borderWidth: 0,
				},
				overlay: {
					zIndex: 99999999999999,
				},
			}}
			className={clsxm(
				'shadow-md rounded-lg flex flex-col overflow-hidden bg-white'
			)}
			onAfterOpen={onOpen}
			onAfterClose={onClose}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
		>
			<div className={clsxm('flex-1 flex flex-col h-full p-4')}>
				<div className={clsxm('flex-1 py-4')}>
					<label className={clsxm('text-lg font-semibold text-center ')}>
						{title || t(translations.text.areYouSure)}
					</label>
					{subTitle && (
						<p className={clsxm('text-sm text-[grey]')}>{subTitle}</p>
					)}
					<div>{children}</div>
				</div>
				<div
					className={clsxm(
						'h-[48px] flex flex-row-reverse items-end content-end gap-4 '
					)}
				>
					<Button
						small
						title={t(translations.yes)}
						// iconBefore={<Icon path={mdiDelete} />}
						className={clsxm('btn text-white', positiveButtonColor())}
						onClick={onPositiveClick}
						loading={loading}
					/>
					<Button
						small
						title={t(translations.no)}
						// iconBefore={<Icon path={mdiContentSave} />}
						className="btn btn-outline"
						onClick={onNegativeClick}
						loading={loading}
					/>
				</div>
			</div>
		</Modal>
	);
};
