/* eslint-disable react-hooks/exhaustive-deps */
import { BookingHelper } from 'helpers';
import { showAlert, showError } from 'helpers/alertHelper';
import {
	calculateTotalAmountBillDetails,
	calculateTotalTax,
} from 'helpers/bookingHelper';
import { priceFixed } from 'helpers/currencyHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import _, { clone } from 'lodash';
import { IApiResponse, IBillingDetail, ICustomer, IGiftCard } from 'models';
import { IBilling } from 'models/IBilling';
import { IApplyPromotionRequest, IPromotion } from 'models/IPromotion';
import { ITip } from 'models/ITip';
import { IPaymentType } from 'models/ITransaction';
import moment from 'moment';
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { TransactionApiService } from 'services';
import BillingApiService from 'services/BillingApiService';
import { adjustTip } from 'services/CloverApiService';
import PromotionApiService from 'services/PromotionApiService';
import TipApiService from 'services/TipApiService';
import { DiscounterType, PaymentType, TipType } from 'utils/Consts';

const getTipApi = (tipType: TipType, tip: ITip) => {
	switch (tipType) {
		case TipType.AUTO:
			return TipApiService.addTips(tip);
		case TipType.MANUALLY:
			return TipApiService.addBillTipsManually(tip);
		default:
			return TipApiService.addBillTipsBalance(tip);
	}
};
interface ICheckOutContext {
	bill?: IBilling;
	billDetails?: Partial<IBillingDetail>[];
	tip?: ITip;
	tipType: TipType;
	discount?: number;
	discounterType?: DiscounterType;
	giftCards?: Partial<IGiftCard>[];
	childrenBills?: Partial<IBilling>[];
	payments?: Partial<IPaymentType>[];
	employeeId?: string;
	promotion?: IPromotion;
	customer?: Partial<ICustomer>;
	setDiscount: (discount: number) => void;
	setDiscounterType: (type?: DiscounterType) => void;
	calculateAmountNeedToPay: () => number;
	totalGiftCardAmount: () => number;
	totalTax: () => number;
	addPayment: (payment: Partial<IPaymentType>) => void;
	removePayment: (index: number) => void;
	totalAmount: () => number;
	totalAmountBillDetails: () => number;
	setGiftCards: (giftCard: Partial<IGiftCard>[]) => void;
	disableDiscount: (type: 'discount' | 'promotion') => boolean;
	loading?: boolean;
	setCustomer: (customer: Partial<ICustomer>) => void;
	editExtra?: (
		index: number,
		data?: { amount: number; note?: string },
		billCombineId?: string
	) => void;
	setTip: (tip?: ITip) => void;
	saveBill: (employeeId?: string) => Promise<IBilling | undefined>;
	complete: (employeeId: string) => Promise<boolean>;
	done?: boolean;
	setChildrenBills: (billings: Partial<IBilling>[]) => void;
	applyPromotion: (promotion: IPromotion) => void;
	setPromotion: (promotion?: IPromotion) => void;
	deletePromotion: () => void;
	setTipType: (type: TipType) => void;
	setPayments: (payments: Partial<IPaymentType>[]) => void;
	setTipAfterCheckout: (tip?: ITip) => void;
	tipIds?: string[];
}
const CheckOutContext = createContext<ICheckOutContext>({
	tipType: TipType.AUTO,
	setDiscount: () => {},
	setDiscounterType: () => {},
	totalAmountBillDetails: () => 0,
	calculateAmountNeedToPay: () => 0,
	totalGiftCardAmount: () => 0,
	setTip: () => {},
	totalTax: () => 0,
	totalAmount: () => 0,
	addPayment: () => {},
	removePayment: () => {},
	disableDiscount: () => false,
	setGiftCards: () => {},
	saveBill: async () => undefined,
	complete: async () => false,
	setChildrenBills: () => {},
	setCustomer: () => {},
	applyPromotion: () => {},
	setPromotion: () => {},
	deletePromotion: () => {},
	setTipType: () => {},
	setPayments: () => {},
	setTipAfterCheckout: () => {},
});
export const useCheckOutContext = () => {
	return useContext(CheckOutContext);
};

export const CheckOutProvider: React.FC<{
	children?: React.ReactNode;
	billId?: string;
}> = ({ children, billId }) => {
	const [bill, setBill] = useState<IBilling>();
	const [billDetails, setBillDetails] = useState<Partial<IBillingDetail>[]>();
	const [tip, setTip] = useState<ITip>();
	const [tipAfterCheckout, setTipAfterCheckout] = useState<ITip>();
	const [tipType, setTipType] = useState(TipType.AUTO);
	const [customer, setCustomer] = useState<Partial<ICustomer>>();
	const [loading, setLoading] = useState(false);
	const [discount, setDiscount] = useState<number>();
	const [giftCards, setGiftCards] = useState<Partial<IGiftCard>[]>([]);
	const [payments, setPayments] = useState<Partial<IPaymentType>[]>([]);
	const [promotion, setPromotion] = useState<IPromotion>();
	const [childrenBills, setChildrenBills] = useState<Partial<IBilling>[]>([]);
	const [discounterType, setDiscounterType] = useState<DiscounterType>();
	const [childUpdate, setChildUpdate] = useState<string[]>([]);
	const [done, setDone] = useState(false);
	const [tipIds, setTipIds] = useState<string[]>([]);

	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);

	const employees = useAppSelector((state) => state.EmployeeReducer.employees);
	const getBillById = async (id: string) => {
		const [response, tipResponse] = await Promise.all([
			BillingApiService.getBillById(id),
			TipApiService.getTipByBillId(id),
		]);
		if (response && response.data) {
			setBill(response.data);
			if (response.data.paymentStatus === 3) {
				setDone(true);
			}
			setBillDetails(
				response.data.billDetails.filter((x) => _.isEmpty(x.giftCardId))
			);
			setCustomer(
				response.data.customer || {
					id: response.data.customerId,
					firstName: _.split(response.data.customerName, ' ')[0],
					lastName: _.split(response.data.customerName, ' ')[1] || '',
					phone: response.data.customerPhone,
				}
			);
			setGiftCards(
				response.data.billDetails
					.filter((x) => !_.isEmpty(x.giftCardId))
					.map((x) => x.giftCard!)
			);
			setDiscounterType(response.data.discounterType);
			if (response.data.discount && response.data.discount !== 0) {
				setDiscount(response.data.discount);
			}

			if (!_.isEmpty(response.data?.promotionIds)) {
				getPromotionOnBill(response.data?.promotionIds);
			}
		}
		if (tipResponse && tipResponse.data) {
			const totalAmount = _.sumBy(tipResponse.data, (x) => x.tipAmount);
			if (totalAmount > 0) {
				const newTip: ITip = {
					billId: id,
					date: tipResponse.data[0].date,
					tips: tipResponse.data,
					totalTip: totalAmount,
				};
				setTip(newTip);
				setTipIds(tipResponse.data?.map((x) => `${x.id}`));
			}
		}
	};
	const addPayment = (payment: Partial<IPaymentType>) => {
		setPayments(payments.concat(payment));
	};
	const removePayment = (index: number) => {
		setPayments(payments.filter((x, i) => i !== index));
	};

	const totalTax = (): number => {
		const amountBillDetails = totalAmountBillDetails();
		const discountAmount = totalDiscount() + totalPromotionDiscount();
		return calculateTotalTax(amountBillDetails, discountAmount);
	};
	const totalDiscount = (): number => {
		const childBillDetails = _.flattenDeep(
			childrenBills.map((x) => x.billDetails!)
		);

		if (!discount) {
			return _.sumBy(billDetails?.concat(childBillDetails), (x) =>
				x.giftCardId ? 0 : x.discount || 0
			);
		}
		return discount;
	};
	const totalPromotionDiscount = (): number => {
		const childBillDetails = _.flattenDeep(
			childrenBills.map((x) => x.billDetails!)
		);
		return _.sumBy(billDetails?.concat(childBillDetails), (x) =>
			x.giftCardId ? 0 : x.promotionDiscount || 0
		);
	};
	const totalAmountBillDetails = () => {
		const childBillDetails = _.flattenDeep(
			childrenBills.map((x) => x.billDetails!)
		);
		return calculateTotalAmountBillDetails(
			billDetails?.concat(childBillDetails) || []
		);
	};
	const totalGiftCardAmount = () => {
		return _.sumBy(giftCards, (x) => (x.type === 0 ? x.amount || 0 : 0));
	};
	const totalAmount = (): number => {
		const amountBillDetails = totalAmountBillDetails();
		const amountGiftCards = totalGiftCardAmount();
		const discountAmount = totalDiscount() + totalPromotionDiscount();
		const cardCharge = _.sumBy(payments, (x) => x.cardCharge || 0);
		const tax = currentBranch?.discountBeforeTax
			? ((amountBillDetails - discountAmount) *
					(currentBranch.taxPercent || 0)) /
			  100
			: (amountBillDetails * (currentBranch?.taxPercent || 0)) / 100;
		return priceFixed(
			amountBillDetails +
			amountGiftCards -
			discountAmount +
			tax +
			(tip ? tip.totalTip || 0 : 0) +
			cardCharge
		);
	};

	const calculateAmountNeedToPay = (): number => {
		const amount = totalAmount();
		const totalPayments = _.sumBy(payments, (x) => x.amount || 0);
		return priceFixed(_.max([amount - totalPayments, 0]) || 0);
	};
	const editExtra = (
		index: number,
		data?: { amount: number; note?: string },
		billCombineId?: string
	) => {
		setPromotion(undefined);
		setDiscount(undefined);
		if (!billCombineId) {
			setBillDetails(
				billDetails?.map((x, i) => {
					if (i === index) {
						if (data) {
							return {
								...x,
								extraAmount: data.amount,
								note: data.note,
								promotionDiscount: 0,
								discount: 0,
								tax: 0,
							};
						}
						//else: remove extra
						else {
							return {
								...x,
								extraAmount: undefined,
								note: undefined,
								promotionDiscount: 0,
								discount: 0,
								tax: 0,
							};
						}
					}
					return { ...x, promotionDiscount: 0, discount: 0, tax: 0 };
				})
			);
			//delete all promotion or discount when change extra amount of children bill
			let newChildBills = Array.from(childrenBills);
			newChildBills.forEach((element) => {
				element?.billDetails?.forEach((x) => {
					x.discount = 0;
					x.promotionDiscount = 0;
					x.tax = 0;
				});
			});
			setChildrenBills(newChildBills);
		} else {
			const newChildBills = Array.from(childrenBills);
			const billIndex = _.findIndex(
				newChildBills,
				(x) => x.id === billCombineId
			);
			if (billIndex >= 0) {
				const newBill = {
					...newChildBills[billIndex],
					billDetails: newChildBills[billIndex].billDetails?.map((x, i) => {
						if (i === index) {
							if (data) {
								return {
									...x,
									extraAmount: data.amount,
									note: data.note,
									promotionDiscount: 0,
									discount: 0,
									tax: 0,
								};
							} else {
								return {
									...x,
									extraAmount: undefined,
									note: undefined,
									promotionDiscount: 0,
									discount: 0,
									tax: 0,
								};
							}
						}
						return { ...x, promotionDiscount: 0, discount: 0, tax: 0 };
					}),
				};
				newChildBills[billIndex] = newBill;

				setChildrenBills(newChildBills);
				setChildUpdate(_.uniq(childUpdate.concat([billCombineId])));
			}
			//delete all promotion or discount when change extra amount of bill
			setBillDetails(
				billDetails?.map((x, i) => {
					return { ...x, promotionDiscount: 0, discount: 0, tax: 0 };
				})
			);
		}
	};
	const disableDiscount = (type: 'discount' | 'promotion'): boolean => {
		if (billDetails) {
			if (type === 'discount') {
				if (discount && discount !== 0) {
					return true;
				}
				if (promotion && promotion.id) {
					return true;
				}
				return !_.isEmpty(
					billDetails.find((x) => x.discount && x.discount > 0)
				);
			}
			return false;
		}

		return false;
	};
	const complete = async (employeeId: string): Promise<boolean> => {
		const newBill = await saveBill(employeeId);
		if (!newBill) {
			return false;
		}
		const transactionResponse = await createTransaction(newBill!, employeeId);
		if (transactionResponse) {
			setDone(true);
			return true;
		} else {
			return false;
		}
	};

	const saveBill = async (
		employeeId?: string
	): Promise<IBilling | undefined> => {
		setLoading(true);
		if (!bill) {
			const response = await BillingApiService.createBill({
				shopId: currentBranch?.shopId,
				branchId: currentBranch?.id,
				customerId: customer?.id,
				billDetails: giftCards
					?.filter((e) => e.type !== 1)
					.map((x) => {
						return {
							giftCardId: x.id,
							price: x.amount,
							amount: x.amount,
							tax: 0,
							quantity: 1,
							stylistId: employeeId,
							promotionDiscount: 0,
						};
					}),
				promotionIds: !_.isEmpty(promotion?.coupons)
					? undefined
					: promotion?.id,
				couponCodes: !_.isEmpty(promotion?.coupons)
					? promotion?.coupons![0].couponCode
					: undefined,
				date: BookingHelper.convertDateRequest(moment()),
			});
			setLoading(false);
			if (response.succeeded && response.data) {
				return response.data;
			}
			return undefined;
		}
		let newBill = _.clone(bill);
		let childBillDetails: Partial<IBillingDetail>[] = [];
		childrenBills.forEach((x) => {
			childBillDetails = childBillDetails.concat(x.billDetails || []);
		});

		const updateBill = await BillingApiService.updateBill({
			...bill,
			totalAmount: undefined,
			realAmount: undefined,
			totalTax: undefined,
			totalQuantity: undefined,
			customerId: customer?.id,
			totalExtraAmount: 0,
			bookingId: _.isEmpty(bill?.bookingId) ? undefined : bill?.bookingId,
			discount:
				discounterType === null || discounterType === undefined
					? undefined
					: totalDiscount(),
			discounterType:
				discounterType === null || discounterType === undefined
					? undefined
					: discounterType,
			billDetails: _.clone(billDetails || [])
				?.map((x) => {
					return {
						...x,
						item: undefined,
						stylist: undefined,
						bookingId: bill.bookingId,
						promotionDiscount: 0,
						tax: 0,
					};
				})
				.concat(
					childBillDetails.map((x) => {
						return {
							...x,
							billId: bill.id,
							item: undefined,
							stylist: undefined,
							id: undefined,
							bookingId: x.bookingId,
							promotionDiscount: 0,
							tax: 0,
						};
					})
				)
				.concat(
					giftCards
						?.filter((e) => e.type !== 1)
						.map((x) => {
							return {
								giftCardId: x.id,
								price: x.amount,
								amount: x.amount,
								tax: 0,
								quantity: 1,
								stylistId: employeeId,
								stylist: undefined,
								item: undefined,
								bookingId: undefined,
								promotionDiscount: 0,
							};
						})
				),
			promotionIds: !_.isEmpty(promotion) ? promotion?.id : bill.promotionIds,
			couponCodes: !_.isEmpty(promotion?.coupons)
				? promotion?.coupons![0].couponCode
				: bill.couponCodes,
		});
		const tipResponse = tip
			? await getTipApi(tipType, {
					...tip,
					billId: newBill?.id,
			  })
			: undefined;
		setLoading(false);
		if (updateBill.succeeded && updateBill.data) {
			return newBill;
		} else {
			return undefined;
		}
	};
	const createTransaction = async (
		billInfo: IBilling,
		employeeId: string
	): Promise<boolean> => {
		setLoading(true);
		const paymentType: IPaymentType = {
			paymentType: PaymentType.CASH,
			amount: 0,
			currencyCode: 'USD',
		};
		const transactionResponse = await TransactionApiService.createTransaction({
			billId: billInfo.id,
			branchId: currentBranch?.id,
			employeeCode: _.find(employees, (x) => x.id === employeeId)?.code,
			paymentTypes: _.isEmpty(payments) ? [paymentType] : payments, // for bill 0$
			date: BookingHelper.convertDateRequest(moment()),
			customerId: billInfo.customerId,
		});
		setLoading(false);
		if (transactionResponse.succeeded && transactionResponse.data) {
			return true;
		} else {
			// setCompleted(false);
			showError(transactionResponse.errors);
			return false;
		}
	};

	//-------------------------------------------------------------------------------------------------------------

	const getNewBillPromotion = (
		applyPromotionDetails: { promotionDiscount: number; id: string }[],
		currentBillDetails: Partial<IBillingDetail>[]
	) => {
		let newBillDetails: Partial<IBillingDetail>[] = Array.from(
			currentBillDetails || []
		);
		newBillDetails = newBillDetails.map((x) => {
			const billDetailRes = _.find(
				applyPromotionDetails,
				(y) => y?.id === x?.id
			);
			if (!_.isEmpty(billDetailRes)) {
				return {
					...x,
					promotionDiscount: billDetailRes?.promotionDiscount || 0,
				};
			}
			return { ...x };
		});
		return newBillDetails;
	};

	//-------------------------------------------------------------------------------------------------------------

	const applyPromotion = async (promotion: IPromotion) => {
		const childBillDetails = _.flatMapDeep(
			childrenBills.map((x) => x.billDetails!)
		);
		const allBillDetails = billDetails?.concat(childBillDetails);
		const amountBillDetails = totalAmountBillDetails();
		const tax = currentBranch?.discountBeforeTax
			? (amountBillDetails * (currentBranch.taxPercent || 0)) / 100
			: 0;

		//--------------------------------------------------------------------

		const data: IApplyPromotionRequest = {
			...bill,
			totalAmount: totalAmountBillDetails() + tax,
			customer: undefined,
			promotionIds: !_.isEmpty(promotion.coupons) ? undefined : promotion.id,
			couponCodes: !_.isEmpty(promotion?.coupons)
				? promotion?.coupons![0].couponCode
				: undefined,
			applyPromotionDetails: allBillDetails,
			stylistId: undefined,
		};

		//---------------------------------------------------------------------

		const response = (await PromotionApiService.applyPromotion(
			data
		)) as IApiResponse<{
			applyPromotionDetails: { promotionDiscount: number; id: string }[];
		}>;
		if (!_.isEmpty(response.message)) {
			showAlert(`${response.message[0].text}`, 'error');
			return;
		}
		if (response.succeeded && response.data) {
			setPromotion(promotion);
			const newBillDetails = getNewBillPromotion(
				response.data.applyPromotionDetails,
				billDetails!
			);
			if (!_.isEmpty(childBillDetails)) {
				let newChildBills = Array.from(childrenBills);
				newChildBills = newChildBills.map((x) => {
					return {
						...x,
						billDetails: getNewBillPromotion(
							response?.data?.applyPromotionDetails!,
							x.billDetails!
						),
					};
				});
				setChildrenBills(newChildBills);
			}

			setBillDetails(newBillDetails);
			setBill({ ...bill!, billDetails: newBillDetails });
			console.log(bill);
		}
	};

	//-------------------------------------------------------------------------------------------------------------

	const deletePromotion = () => {
		setPromotion(undefined);
		let newBillDetails = Array.from(billDetails || []);
		newBillDetails = newBillDetails.map((x) => {
			return { ...x, promotionDiscount: 0, tax: 0 };
		});
		let newChildrenBills = Array.from(childrenBills);
		if (!_.isEmpty(newChildrenBills)) {
			newChildrenBills = newChildrenBills.map((x) => {
				return {
					...x,
					billDetails: x.billDetails?.map((y) => {
						return { ...y, promotionDiscount: 0, tax: 0 };
					}),
				};
			});
			setChildrenBills(newChildrenBills);
		}

		setBillDetails(newBillDetails);
		setBill({ ...bill!, billDetails: newBillDetails });
	};

	//-------------------------------------------------------------------------------------------------------------

	const getPromotionOnBill = async (ids?: string, couponCode?: string) => {
		if (!_.isEmpty(ids)) {
			const arrId = ids?.split(/,(.*)/s);
			const res = await PromotionApiService.getPromotionById(arrId![0]);
			if (!_.isEmpty(res.errors)) {
				showError(res.errors);
				return undefined;
			}
			const resData = res as IApiResponse<IPromotion>;
			if (!_.isEmpty(resData.data) && resData.succeeded) {
				setPromotion(resData.data);
				return;
			}
		}
		if (!_.isEmpty(couponCode)) {
			// const request: IGetPromotionByCouponCode = {
			// 	couponCode,
			// 	date: moment.utc(moment()).format('MM-DD-YYYY HH:mm'),
			// 	customerId: bill?.customerId || '',
			// 	serviceIds: allBillDetails.map((x) => x.item?.id!),
			// 	branchId: bill?.branchId!,
			// };
			// const res = await PromotionApiService.getPromotionByCouponCode(request);
			return undefined;
		}
		return undefined;
	};

	//-------------------------------------------------------------------------------------------------------------

	useEffect(() => {
		if (discount === 0) {
			setBillDetails(
				billDetails?.map((x) => {
					return {
						...x,
						discount: 0,
					};
				})
			);
		}
	}, [discount]);

	useEffect(() => {
		if (billId && billId !== 'sale-giftcard') {
			getBillById(billId);
		}
	}, []);

	//-------------------------------------------------------------------------------------------------------------

	useEffect(() => {
		if (!_.isEmpty(childrenBills)) {
			deletePromotion();
		}
	}, [childrenBills.length]);

	//-------------------------------------------------------------------------------------------------------------

	useEffect(() => {
		if (done && tipAfterCheckout) {
			const addTip = async () => {
				const tipResponse = await getTipApi(tipType, {
					...tipAfterCheckout,
					billId: bill?.id,
				});
				if (tipResponse.succeeded) {
					showAlert('Add tip successfully', 'success');
					tipResponse.data &&
						setTipIds(tipResponse.data?.map((x) => `${x.id}`));
					const cloverPayment = _.find(
						payments,
						(x) => x.isClover
					) as Partial<IPaymentType>;
					// if (cloverPayment) {
					// 	await adjustTip(
					// 		`${cloverPayment.transactionReferenceNumber}`,
					// 		tipAfterCheckout.totalTip || 0
					// 	);
					// }
				} else {
					showError(tipResponse);
				}
			};
			addTip();
		}
	}, [done, tipAfterCheckout]);

	//-------------------------------------------------------------------------------------------------------------

	// const value : ICheckOutContext = useMemo(() => ({
	// 	bill,
	// 	billDetails,
	// 	tip,
	// 	tipType,
	// 	discount,
	// 	giftCards,
	// 	payments,
	// 	promotion,
	// 	calculateAmountNeedToPay,
	// 	editExtra,
	// 	addPayment,
	// 	removePayment,
	// }),[bill, billDetails, tip,tipType,discount,gi])
	console.log('payments;', payments);

	const value: ICheckOutContext = {
		bill: useMemo(() => bill, [bill]),
		billDetails: useMemo(() => billDetails, [billDetails]),
		tip: useMemo(() => tip, [tip]),
		tipType: useMemo(() => tipType, [tipType]),
		discount: useMemo(() => discount, [discount]),
		giftCards: useMemo(() => giftCards, [giftCards]),
		payments: useMemo(() => payments, [payments]),
		promotion: useMemo(() => promotion, [promotion]),
		discounterType: useMemo(() => discounterType, [discounterType]),
		childrenBills: useMemo(() => childrenBills, [childrenBills]),
		loading: useMemo(() => loading, [loading]),
		customer,
		done,
		setCustomer,
		calculateAmountNeedToPay,
		editExtra,
		addPayment,
		removePayment,
		totalAmount,
		totalAmountBillDetails,
		totalTax,
		totalGiftCardAmount,
		setTip,
		setDiscounterType,
		setDiscount,
		disableDiscount,
		setGiftCards,
		saveBill,
		complete,
		setChildrenBills,
		applyPromotion,
		setPromotion,
		deletePromotion,
		setTipType,
		setPayments,
		setTipAfterCheckout,
		tipIds,
	};
	// console.group('=============== VALUE CHECKOUT ==========');
	// console.log(value);
	// console.groupEnd();

	return (
		<CheckOutContext.Provider value={value}>
			{children}
		</CheckOutContext.Provider>
	);
};
