import { I18n, _t, translations } from 'utils';
import { useState } from 'react';
import clsxm from 'clsxs/clsxm';
import { useFormContext } from 'react-hook-form';

import { DatePicker } from 'components/molecules/DatePicker';
import React from 'react';
import './styles.css';

import { TabView } from 'components/molecules/Tab';
import CommissionRate from './CommissionRate';
import { ChargeRate, IWageType } from 'utils/Consts';
import { IStaffDetailForm, useStaffDetailContext } from '../context';
import moment from 'moment';
import { ButtonGroup, Card, Input } from 'components/atoms';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';

const WAGES_TYPE = [
	{
		title: I18n.t(_t(translations.staffDetail.hourlyWages)),
		value: IWageType.HOURLY,
		symbol: '($/H)',
	},
	{
		title: I18n.t(_t(translations.staffDetail.weeklyWages)),
		value: IWageType.WEEKLY,
		symbol: '($/W)',
	},
	{
		title: I18n.t(_t(translations.staffDetail.semimonthlyWages)),
		value: IWageType.SEMIMONTHLY,
		symbol: '($/SM)',
	},
	{
		title: I18n.t(_t(translations.staffDetail.monthlyWages)),
		value: IWageType.MONTHLY,
		symbol: '($/M)',
	},
];

const Commission = () => {
	const { setValue, register } = useFormContext<IStaffDetailForm>();
	const { staff } = useStaffDetailContext();
	const { defaultEmployeeCommission } = useAppSelector(
		(state) => state.BranchReducer.currentBranch!
	);
	return (
		<div className="flex flex-col gap-3">
			<div className="font-semibold text-base-content">
				{I18n.t(_t(translations.staffDetail.employeeOwnerCommission))}
			</div>
			<div className="flex flex-col gap-3" id="commission-rate">
				<CommissionRate
					defaultValue={
						_.isNumber(staff?.servicesCommissionPercent)
							? staff?.servicesCommissionPercent
							: defaultEmployeeCommission
					}
					onSelect={(value) => {
						setValue('servicesCommissionPercent', value);
					}}
					title={I18n.t(_t(translations.staffDetail.forService))}
				/>
				<CommissionRate
					defaultValue={
						_.isNumber(staff?.giftCardCommissionPercent)
							? staff?.giftCardCommissionPercent
							: defaultEmployeeCommission
					}
					onSelect={(value) => {
						setValue('giftCardCommissionPercent', value);
					}}
					title={I18n.t(_t(translations.staffDetail.forGiftCard))}
				/>
			</div>
			<div id="commission-check-rate">
				<CommissionRate
					defaultValue={
						_.isNumber(staff?.checkPercent)
							? staff?.checkPercent
							: ChargeRate.CASH_CHECK_COMMISSION
					}
					onSelect={(value) => {
						setValue('checkPercent', value);
					}}
					title={I18n.t(_t(translations.staffDetail.cardCheckPercentage))}
				/>
			</div>
			<div className="divider my-3" />
			<div className={clsxm('grid grid-cols-2 gap-x-4 gap-y-4 ')}>
				<div id="card-charge">
					<Input
						type={'number'}
						{...register('cardChargePercent')}
						label={I18n.t(_t(translations.staffDetail.cardCharge))}
						renderAfter={<div>%</div>}
					/>
				</div>
				<div id="tip-card-charge">
					<Input
						type={'number'}
						{...register('tipCardChargePercent')}
						label={I18n.t(_t(translations.staffDetail.percentTipCardCharge))}
						renderAfter={<div>%</div>}
					/>
				</div>
			</div>
		</div>
	);
};
const SelectSalaryWages = (props: {}) => {
	const {
		watch,
		setValue,
		register,
		formState: { errors },
	} = useFormContext<IStaffDetailForm>();

	const defaultSalaryType = watch('salaryType');
	const [salaryType, setSalaryType] = useState(defaultSalaryType);

	return (
		<div id="salary-setup">
			<div className="font-semibold text-base-content">
				{I18n.t(_t(translations.staffDetail.salary))}
			</div>

			<div>
				<ButtonGroup<{ title: string; value: number }>
					buttons={WAGES_TYPE}
					value={WAGES_TYPE.find((x) => x.value === salaryType)}
					valueField="value"
					titleField="title"
					onChange={(e) => {
						setSalaryType(e.value);
						setValue('salaryType', e.value);
					}}
				/>
			</div>
			{/* <div className="flex gap-4 flex-wrap">
				 {WAGES_TYPE.map((x) => {
					return (
						<div
							className="flex justify-center items-center align-middle gap-2 cursor-pointer"
							key={x.title}
							onClick={() => {
								setSalaryType(x.value);
								setValue('salaryType', x.value);
							}}
						>
							<div
								className={clsxm(
									'w-5 h-5 flex flex-1 justify-center items-center align-middle border rounded-full'
								)}
								key={x.title}
							>
								<input
									type="radio"
									className={clsxm(
										'radio border-0 rounded-full w-3 h-3',
										salaryType === x.value ? 'bg-primary' : 'bg-white'
									)}
									name={`radio-${x.title}`}
								/>
							</div>
							<p>{x.title}</p>
						</div>
					);
				})}
			</div> */}
			{salaryType ? (
				<div className="w-1/3 mt-4">
					<Input
						renderAfter={
							<div>
								{WAGES_TYPE.find((x) => x.value === salaryType)?.symbol}
							</div>
						}
						error={errors.salary?.message}
						alignRight
						{...register('salary', { required: 'Please input salary' })}
					/>
				</div>
			) : null}
		</div>
	);
};
const Salary = (props: {}) => {
	const { setValue, register } = useFormContext<IStaffDetailForm>();
	const { staff } = useStaffDetailContext();
	return (
		<div className="flex flex-col gap-3">
			<div>
				<div>
					<SelectSalaryWages />
					<div className="divider" />
					<div id="salary-others">
						<CommissionRate
							defaultValue={staff?.checkPercent}
							onSelect={(value) => {
								setValue('checkPercent', value);
							}}
							title={I18n.t(_t(translations.staffDetail.cardCheckPercentage))}
						/>
						<div className="divider" />
						<div className="w-1/3">
							<Input
								label={I18n.t(
									_t(translations.staffDetail.percentTipCardCharge)
								)}
								renderAfter={<div>%</div>}
								alignRight
								{...register('tipCardChargePercent')}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const SalaryCommission = (props: {}) => {
	const { staff } = useStaffDetailContext();
	const { register, setValue } = useFormContext<IStaffDetailForm>();

	return (
		<div className="flex flex-col gap-3">
			<div>
				<div>
					<SelectSalaryWages />

					<div className="divider" />
					<div id="salary-commission">
						<CommissionRate
							defaultValue={staff?.servicesCommissionPercent}
							onSelect={(value) => {
								setValue('servicesCommissionPercent', value);
							}}
							title={I18n.t(_t(translations.staffDetail.forService))}
						/>
						<CommissionRate
							defaultValue={staff?.giftCardCommissionPercent}
							onSelect={(value) => {
								setValue('giftCardCommissionPercent', value);
							}}
							title={I18n.t(_t(translations.staffDetail.forGiftCard))}
						/>
						<CommissionRate
							defaultValue={staff?.checkPercent}
							onSelect={(value) => {
								setValue('checkPercent', value);
							}}
							title={I18n.t(_t(translations.staffDetail.cardCheckPercentage))}
						/>

						<div className="divider" />

						<div className={clsxm('grid grid-cols-2 gap-x-4 gap-y-4 ')}>
							<Input
								type={'number'}
								{...register('cardChargePercent')}
								label={I18n.t(_t(translations.staffDetail.cardCharge))}
								renderAfter={<div>%</div>}
							/>
							<Input
								type={'number'}
								{...register('tipCardChargePercent')}
								label={I18n.t(
									_t(translations.staffDetail.percentTipCardCharge)
								)}
								renderAfter={<div>%</div>}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const PayrollSetup = (props: { onChangeTab: (i: number) => void }) => {
	const { watch, setValue } = useFormContext<IStaffDetailForm>();
	const { onChangeTab } = props;

	const { isAdd } = useStaffDetailContext();
	return (
		<Card
			title={I18n.t(_t(translations.staffDetail.payrollSetup))}
			id="payroll"
		>
			{/* <div className={clsxm('grid grid-cols-2 gap-x-4 gap-y-2')}> */}
			<div>
				<TabView
					tabs={[
						I18n.t(_t(translations.staffDetail.commission)),
						I18n.t(_t(translations.staffDetail.salary)),
						I18n.t(_t(translations.staffDetail.salaryAndCommission)),
					]}
					onChangeTab={(i) => {
						setValue('incomeType', i + 1);
						onChangeTab(i);
					}}
					defaultTab={(watch('incomeType') || 1) - 1}
					tabPanels={[<Commission />, <Salary />, <SalaryCommission />]}
					className="bg-white pl-0"
				/>
			</div>

			<div className="divider" />

			{!isAdd && (
				<div className="w-1/5">
					<div>
						<label className="label font-medium ">
							<span className="label-text text-md">
								{I18n.t(_t(translations.staffDetail.salaryEffectiveStartDate))}
							</span>
						</label>
						<DatePicker
							className="w-full"
							format={'MM/DD/YYYY'}
							selectedDate={moment(watch('salaryEffectiveStartDate'))}
							onSelectDate={(date) =>
								setValue('salaryEffectiveStartDate', date.toString())
							}
						/>
					</div>
				</div>
			)}
		</Card>
	);
};

export default PayrollSetup;
