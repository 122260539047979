import {
	mdiApplicationEdit,
	mdiBlockHelper,
	mdiCancel,
	mdiCashRefund,
	mdiCheckAll,
	mdiPrinter,
} from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, ConfirmModal, Description, Icon } from 'components/atoms';
import { AlertHelper, CurrencyHelper } from 'helpers';
import {
	calculateTotalAmountBillDetails,
	calculateTotalTax,
} from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import { createESCCheckOut, print } from 'helpers/printHelper';
import _ from 'lodash';
import { IPaymentType } from 'models/ITransaction';
import { useBillDetailContext } from 'pages/BillDetail/context';
import { useCheckOutContext } from 'pages/CheckOut/context';
import React, { useCallback, useMemo, useState } from 'react';
import { t, translations } from 'utils';
import { EmployeeCodeModal } from './Modals/EmployeeCodeModal';
import { RefundModal } from './Modals/RefundTotalBillModal';
import { RefundTypeModal } from './Modals/RefundTypeModal';
import { TipModal } from './Modals/TipModal';
import moment from 'moment';

const Discount = () => {
	const { bill } = useBillDetailContext();
	if (!bill || bill.discount === 0) {
		return null;
	}
	const discount = bill.discount || 0;
	const discountPercent = CurrencyHelper.priceFixed(
		(discount * 100) / calculateTotalAmountBillDetails(bill.billDetails || [])
	);
	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8">
			<Description
				title={`${t(translations.checkout.discount)} (${discountPercent}%)`}
				value={CurrencyHelper.formatPrice(discount)}
			/>
		</div>
	);
};
const PromotionDiscount = () => {
	const { bill } = useBillDetailContext();
	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8 pt-0 mt-0">
			<Description
				title={`${t(translations.checkout.promotionDiscount)}`}
				value={CurrencyHelper.formatPrice(bill?.promotionDiscount || 0)}
			/>
		</div>
	);
};
const Tip = () => {
	const { tip } = useBillDetailContext();
	if (_.isEmpty(tip)) {
		return null;
	}
	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8">
			<Description
				title={t(translations.checkout.tip)}
				titleClassName="font-bold"
				value={CurrencyHelper.formatPrice(tip?.totalTip)}
			/>
		</div>
	);
};
const GiftCard = () => {
	const { bill } = useBillDetailContext();
	if (!bill) {
		return null;
	}
	const totalGiftCardAmount = _.sumBy(
		bill.billDetails.filter((x) => x.giftCardId),
		(x) => x.amount || 0
	);
	if (totalGiftCardAmount === 0) {
		return null;
	}
	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8">
			<Description
				title={t(translations.checkout.totalGiftCard)}
				titleClassName="font-bold"
				value={CurrencyHelper.formatPrice(totalGiftCardAmount)}
			/>
		</div>
	);
};
const CardCharge = () => {
	const { bill } = useBillDetailContext();
	if (_.isEmpty(bill)) {
		return null;
	} else if (bill!.cardCharge <= 0) {
		return null;
	}
	return (
		<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8">
			<Description
				title={t(translations.checkout.cardCharge)}
				value={CurrencyHelper.formatPrice(bill?.cardCharge)}
			/>
		</div>
	);
};

type modalType = 'partial' | 'total' | 'tip';
export const BillGeneral = () => {
	const {
		bill,
		setEditing,
		editing,
		tip,
		refunding,
		refundDetails,
		loading,
		setRefunding,
		setRefundDetails,
		setRefundPartial,
		createRefund,
		voidBill,
		saveBill,
		cancelEditBill,
	} = useBillDetailContext();
	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const [showEmployeeCode, setShowEmployeeCode] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [showAlertRefund, setShowAlertRefund] = useState(false);
	const [showRefundModal, setShowRefundModal] = useState(false);
	const [modal, setModal] = useState<modalType>();
	const employees = useAppSelector((state) => state.EmployeeReducer.employees);
	const receiptPrintType = useAppSelector(
		(state) => state.SettingReducer.receiptPrintType
	);
	const paid = _.sumBy(bill?.transactions, (x) => x.amount);
	const change = paid - (bill?.realAmount || 0) - (bill?.cardCharge || 0);
	const taxPercent =
		useAppSelector((state) => state.BranchReducer.currentBranch?.taxPercent) ||
		0;
	const user = useAppSelector((state) => state.UserReducer.employee);
	// const onComplete = async (employeeId?: string) => {
	// 	const result = await complete(employeeId || '');

	// 	if (result) {
	// 		onPrint(employeeId);
	// 	}
	// };
	const hasCloverPayment = useMemo(
		() =>
			bill?.transactions?.some(
				(e) => e.paymentMethod?.code.toUpperCase() === 'CLOVER'
			),
		[bill]
	);
	const shouldDisableVoid = useCallback(() => {
		const cloverTransaction =
			hasCloverPayment && !_.isEmpty(bill!.transactions)
				? bill!.transactions?.sort((a, b) =>
						a.createdDate! > b.createdDate!
							? 1
							: a.createdDate! < b.createdDate!
							? -1
							: 0
				  )[0]
				: null;
		if (
			cloverTransaction &&
			moment
				.utc(cloverTransaction.createdDate)
				.add(25, 'minute')
				.isBefore(moment().utc(), 'minute')
		) {
			return true;
		}
		return false;
	}, [bill]);

	const onPrint = async () => {
		const discount = bill?.discount || 0;
		const discountPercent = CurrencyHelper.priceFixed(
			(discount * 100) /
				calculateTotalAmountBillDetails(bill?.billDetails || [])
		);
		const dataPrint = {
			billNumber: bill?.billNumber,
			bookingId: `${bill?.bookingId}`,
			billingDetails: bill?.billDetails.filter((x) => !x.giftCardId) || [],
			giftCards: bill?.billDetails.filter((x) => !!x.giftCardId) || [],
			subTotalService: calculateTotalAmountBillDetails(bill?.billDetails || []),
			tax: calculateTotalTax(
				calculateTotalAmountBillDetails(bill?.billDetails || []),
				bill?.discount || 0
			),
			listBillingCombine: [],
			balance: bill?.realAmount || 0,
			tip: tip?.totalTip,
			discount: discount,
			discountPer: discountPercent,
			paymentTypes:
				bill?.transactions?.map((x) => {
					const payment: Partial<IPaymentType> = {
						paymentType: x.paymentType,
						amount: x.amount,
						transactionReferenceNumber: x.transactionReferenceNumber,
						giftCode: x.giftCode,
						appName: x.appName,
					};
					return payment;
				}) || [],
			customer: bill?.customer!,
			change: change,
			customerPaid: paid,
		};
		const joint_1 = await createESCCheckOut(dataPrint);
		await print(joint_1);
	};

	const shop = useAppSelector((state) => state.ShopReducer.shop);
	return (
		<div className="flex flex-col bg-white flex-1 rounded-lg">
			<div className="flex-1 flex flex-col justify-end">
				<div className="ml-4 p-2 pl-4 pr-8">
					<Description
						title={t(translations.checkout.subtotal)}
						value={CurrencyHelper.formatPrice(
							calculateTotalAmountBillDetails(bill?.billDetails || [])
						)}
					/>
				</div>
				{currentBranch?.discountBeforeTax && <Discount />}
				{currentBranch?.discountBeforeTax && bill?.promotionDiscount !== 0 && (
					<PromotionDiscount />
				)}
				<div className="ml-4 p-2 pl-4 pr-8 border-t border-t-base-200  ">
					<Description
						title={`${t(translations.checkout.tax)} (${taxPercent}%)`}
						value={CurrencyHelper.formatPrice(
							calculateTotalTax(
								calculateTotalAmountBillDetails(bill?.billDetails || []),
								bill?.discount || 0 + (bill?.promotionDiscount || 0)
							)
						)}
					/>
				</div>
				{!currentBranch?.discountBeforeTax && <Discount />}
				{!currentBranch?.discountBeforeTax && bill?.promotionDiscount !== 0 && (
					<PromotionDiscount />
				)}
				<GiftCard />
				<Tip />
				<CardCharge />
				<div className="ml-4 p-2 pl-4 pr-8 border-t border-t-base-200">
					<Description
						titleClassName="text-lg font-bold"
						valueClassName="text-lg font-bold"
						title={t(translations.bookingCustomerInfo.total)}
						value={CurrencyHelper.formatPrice(bill?.realAmount)}
					/>
				</div>
				<div className="ml-4 rounded-l-lg mb-4 bg-base-200 p-2 pl-4 pr-8">
					<Description title="Paid" value={CurrencyHelper.formatPrice(paid)} />
					<Description
						title={t(translations.checkout.amountNeedToPay)}
						value={CurrencyHelper.formatPrice(
							(bill?.realAmount || 0) + (bill?.cardCharge || 0) - paid
						)}
					/>
					{change > 0 && (
						<Description
							title={t(translations.checkout.change)}
							value={CurrencyHelper.formatPrice(change)}
						/>
					)}
				</div>
				{!_.isEmpty(refundDetails) && (
					<div className="relative ml-4 p-2 border-t border-t-base-200 pl-4 pr-8">
						<Description
							title={t(translations.billing.refund)}
							value={`-${CurrencyHelper.formatPrice(
								Math.abs(
									_.sumBy(refundDetails, (e) => (e.amount || 0) + (e.tax || 0))
								)
							)}`}
						/>
					</div>
				)}
			</div>
			{bill?.status !== -1 &&
				(refunding || editing ? (
					<>
						{editing && (
							<div className={clsxm('flex w-full border-t border-base-200')}>
								<Button
									className="rounded-none flex-1  text-sm p-2 gap-1 btn-ghost text-FINISHED"
									title={`Adjust Tips`}
									onClick={() => {
										setModal('tip');
									}}
									iconBefore={<Icon path={mdiCashRefund} />}
									disabled={!_.isEmpty(refundDetails) || loading}
								/>
							</div>
						)}
						<div className={clsxm('flex w-full')}>
							<Button
								className="rounded-none flex-1 rounded-bl-lg text-sm p-2 gap-1 border-none text-white"
								title={t(translations.cancel)}
								onClick={() => {
									setRefundDetails([]);
									setRefunding(false);
									setRefundPartial(false);
									editing && cancelEditBill();
								}}
								iconBefore={<Icon path={mdiCancel} />}
								disabled={loading}
							/>
							<Button
								className={clsxm(
									'rounded-none flex-1 rounded-br-lg text-sm px-2 text-white border-none bg-CONFIRMED'
								)}
								title={t(translations.checkout.complete)}
								onClick={() => {
									editing ? saveBill() : setShowAlertRefund(true);
								}}
								iconBefore={<Icon path={mdiCheckAll} />}
								disabled={(refunding && _.isEmpty(refundDetails)) || loading}
								loading={loading}
							/>
						</div>
					</>
				) : (
					<>
						<div className={clsxm('flex w-full border-t border-base-200')}>
							<Button
								className="rounded-none flex-1  text-sm p-2 gap-1 btn-ghost text-FINISHED"
								title={t(translations.billing.refund)}
								onClick={() => {
									setShowRefundModal(true);
								}}
								iconBefore={<Icon path={mdiCashRefund} />}
								disabled={!_.isEmpty(refundDetails) || loading}
							/>
							{!shouldDisableVoid() && (
								<Button
									className={clsxm(
										'rounded-none flex-1  text-sm px-2  btn-ghost text-DELETED '
									)}
									title={t(translations.billing.void)}
									onClick={() => {
										setShowAlert(true);
									}}
									iconBefore={<Icon path={mdiBlockHelper} />}
									disabled={!_.isEmpty(refundDetails) || loading}
									loading={loading}
								/>
							)}
						</div>
						<div className={clsxm('flex w-full')}>
							<Button
								className="rounded-none flex-1 rounded-bl-lg text-sm p-2 gap-1 border-none text-white"
								title={t(translations.checkout.reprintBill)}
								onClick={() => onPrint()}
								iconBefore={<Icon path={mdiPrinter} />}
								disabled={loading}
							/>
							<Button
								className={clsxm(
									'rounded-none flex-1 rounded-br-lg text-sm px-2 text-white border-none',
									editing ? 'bg-CONFIRMED' : 'bg-PENDING'
								)}
								title={t(
									editing ? translations.checkout.complete : translations.edit
								)}
								onClick={() => {
									setEditing(!editing);
								}}
								iconBefore={
									<Icon path={editing ? mdiCheckAll : mdiApplicationEdit} />
								}
								loading={loading}
								disabled={loading}
							/>
						</div>
					</>
				))}

			<ConfirmModal
				visible={showAlert}
				title={'Void Bill'}
				subTitle={'Are you sure want to void this bill'}
				onPositiveClick={() => {
					if (shouldDisableVoid()) {
						AlertHelper.showAlert(
							t(translations.checkout.cloverVoidOverdue),
							'error'
						);
					} else {
						voidBill();
					}
					setShowAlert(false);
				}}
				onNegativeClick={() => setShowAlert(false)}
				type="error"
			/>
			<ConfirmModal
				visible={showAlertRefund}
				title={t(translations.text.areYouSure)}
				subTitle={t(translations.billing.refundCantBeUndo)}
				onPositiveClick={() => {
					if (shop?.checkEmployeeCodeWhenCheckout !== false) {
						setShowEmployeeCode(true);
					} else {
						createRefund();
					}
					setShowAlertRefund(false);
				}}
				onNegativeClick={() => setShowAlertRefund(false)}
				type="error"
			/>
			{showRefundModal && (
				<RefundTypeModal
					visible={showRefundModal}
					onCancel={() => {
						setShowRefundModal(false);
					}}
					onChooseType={(type) => {
						type === 'total' && setModal('total');
						if (type === 'partial') {
							setRefunding(true);
							setRefundPartial(true);
						}
						setShowRefundModal(false);
					}}
				/>
			)}
			{modal === 'total' && (
				<RefundModal onCancel={() => setModal(undefined)} />
			)}
			{modal === 'tip' && <TipModal onCancel={() => setModal(undefined)} />}
			{showEmployeeCode && (
				<EmployeeCodeModal
					onCancel={() => setShowEmployeeCode(false)}
					onConfirm={(employeeCode) => {
						createRefund(employeeCode);
						setShowEmployeeCode(false);
					}}
				/>
			)}
		</div>
	);
};
