import { mdiChevronDown, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import clsxm from 'clsxs/clsxm';
import React, { DetailedHTMLProps, useState } from 'react';

interface ICardProps
	extends DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {
	title?: string;
	description?: string;
	headerRight?: React.ReactNode;
	accordion?: boolean;
	isDefaultAccordion?: boolean;
	onHeaderClick?: () => void;
}

export const Card = (props: ICardProps) => {
	const {
		className,
		children,
		title,
		description,
		headerRight,
		accordion,
		isDefaultAccordion,
		onHeaderClick,
	} = props;

	const [showAccordion, setShowAccordion] = useState<boolean>(isDefaultAccordion || false);

	const handleOnClick = () => {
		if(onHeaderClick) onHeaderClick();
		if (accordion) {
			setShowAccordion(!showAccordion);
		}
		
		return;
	};
	return (
		<div
			{...props}
			className={clsxm(
				`p-4 rounded-lg bg-base-100 shadow-md ${className || ''} `
			)}
		>
			{(title || description) && (
				<div
					onClick={handleOnClick}
					className={clsxm(
						'grid grid-cols-2 justify-between',
						!headerRight ? 'grid-cols-1' : '',
						(onHeaderClick || accordion) && 'cursor-pointer'
					)}
				>
					<div>
						{title && (
							<p className={clsxm('card-title m-0 text-lg')}>{title}</p>
						)}
						{description && (
							<p className={clsxm('m-0 text-sm')}>{description}</p>
						)}
					</div>
					{headerRight}
					{accordion && (
						<div className="flex-1 flex justify-end cursor-pointer">
							<Icon
								size={1.2}
								path={showAccordion ? mdiChevronDown : mdiChevronRight}
							/>
						</div>
					)}
				</div>
			)}
			{(title || description) && (
				<div className={clsxm('border-b border-b-base-200 my-2')} />
			)}
			{(accordion && showAccordion) && children}
			{!accordion && children}
		</div>
	);
};
