import clsxm from 'clsxs/clsxm';
import { Card } from 'components/atoms';
import { BookingTypeIcon } from 'components/molecules/BookingTypeIcon';
import { StatusTag } from 'components/molecules/StatusTag';
import { BookingHelper, CurrencyHelper, TimeHelper } from 'helpers';
import _ from 'lodash';
import { EBookingInputFrom, EBookingType, IBooking } from 'models';
import moment from 'moment';
import { useDashboardContext } from 'pages/Dashboard/context';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BookingApiService from 'services/BookingApiService';
import { BookingStatus } from 'utils/Consts';

export const UpcomingBookings = () => {
	const { branch } = useDashboardContext();
	const [bookings, setBookings] = useState<IBooking[]>([]);
	const history = useHistory();
	const fetchData = async () => {
		const response = await BookingApiService.getBookingByBranch({
			bookingStatus: `${BookingStatus.PENDING}`,
			orderBy: 'startTimeExpected',
			fromDate: moment().format('YYYY-MM-DD'),
			toDate: moment().format('YYYY-MM-DD'),
			pageSize: 1000,
			branchId: branch,
		});
		if (response.succeeded && response.data) {
			setBookings(response.data);
		}
	};
	useEffect(() => {
		fetchData();
	}, [branch]);
	return (
		<div className="col-span-1 flex overflow-hidden max-h-[116vh] pb-2 ">
			<Card title="Upcomings" className="overflow-y-auto flex-1 ">
				<div>
					<div className="">
						<strong className="text-2xl">{bookings.length}</strong>
						<div className="flex gap-1 text-[#EAA404] font-light">
							<BookingTypeIcon
								type={EBookingType.Appointment}
								color={'#EAA404'}
							/>
							<span>{`${
								bookings.filter(
									(x) => x.inputFrom !== EBookingInputFrom.OnlineSite
								).length
							} appointments`}</span>
						</div>
						<div className="flex gap-1 text-[#323DAD] font-light">
							<BookingTypeIcon
								inputFrom={EBookingInputFrom.OnlineSite}
								color={'#323DAD'}
							/>
							<span>{`${
								bookings.filter(
									(x) => x.inputFrom === EBookingInputFrom.OnlineSite
								).length
							} online appointments`}</span>
						</div>
					</div>
					{bookings.map((x) => {
						return (
							<div
								onClick={() => BookingHelper.openBooking(x)}
								className={clsxm(
									'rounded-xl cursor-pointer active:animate-pop my-2 border-2 border-base-200'
								)}
							>
								<div
									className={clsxm(
										'rounded-t-xl text-sm bg-base-200 p-1 flex justify-between w-full'
									)}
								>
									<strong>{`${x.customer.firstName || ''} ${
										x.customer.lastName || ''
									}`}</strong>
									<BookingTypeIcon
										size={0.8}
										type={x.bookingType}
										inputFrom={x.inputFrom}
									/>
								</div>
								<div className="px-2 py-1 flex justify-between">
									<div className="flex-1">
										<p className="text-xs m-0 mr-2">
											{`${TimeHelper.toTimeZone(
												x.startTime || x.startTimeExpected
											).format('h:mmA')} - ${TimeHelper.toTimeZone(
												x.endTime || x.endTimeExpected
											).format('h:mmA')} `}
										</p>
										{x.bookingDetails.map((bd) => {
											return (
												<div className="my-1">
													<p className="text-xs m-0 mr-2">
														<strong>{bd.item?.name}</strong>
														{bd.stylist && ` (${bd.stylist?.firstName}) `}
													</p>
												</div>
											);
										})}
									</div>
									<strong className="text-sm">
										{CurrencyHelper.formatBalance(x.totalAmount)}
									</strong>
								</div>
							</div>
						);
					})}
				</div>
			</Card>
		</div>
	);
};
