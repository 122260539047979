import { createReducer } from "@reduxjs/toolkit";
import { AuthenticationActions, UserActions } from "../actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "models/IUser";
import { IEmployee } from "models";
import { ICurrentUserResponse } from "models/ResponseModels";

interface IUserState {
  user?: IUser;
  employee?: IEmployee;
  error?: Error;
}
const initialState: IUserState = {};

//Login
const getUserInfoSuccess = (state: IUserState, action: PayloadAction<ICurrentUserResponse>) => {
  state.user = action.payload.user;
  state.employee = action.payload.employee;
};
// const getUserInfoFailed = (state: IUserState, action: PayloadAction<any>) => {
//   state.error = action.payload;
// };

const UserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(UserActions.getCurrentUserInfo.success, getUserInfoSuccess)
    // .addCase(UserActions.getUserInfo.failed, getUserInfoFailed)
    .addCase(AuthenticationActions.logOut.request, (state: IUserState) => {
      state.user = undefined;
      state.employee = undefined;
      state.error = undefined;
    });
});

export default UserReducer;
