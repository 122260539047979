import { createReducer } from '@reduxjs/toolkit';
import { AuthenticationActions } from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'models/IUser';
import { IApiResponse, IErrorResponse } from 'models';
import { ILoginRequest } from 'models/RequestModels';

interface IAuthenticationState {
	user?: IUser;
	apiResponse?: IApiResponse<any>;
	error?: IErrorResponse;
	token?: string;
	refreshToken?: string;
	remember?: boolean;
	deviceId?: string;
	adminAccount?: string;
}
const initialState: IAuthenticationState = {};

const authenticateFailed = (
	state: IAuthenticationState,
	action: PayloadAction<IErrorResponse>
) => {
	state.user = undefined;
	// state.error = action.payload;
};
const clearError = (state: IAuthenticationState) => {
	state.error = undefined;
};
const setToken = (
	state: IAuthenticationState,
	action: PayloadAction<{ token: string; refreshToken: string }>
) => {
	state.token = action.payload.token;
	state.refreshToken = action.payload.refreshToken;
};
const clearToken = (state: IAuthenticationState) => {
	state.token = undefined;
	state.refreshToken = undefined;
};
const setDeviceId = (
	state: IAuthenticationState,
	action: PayloadAction<{ deviceId: string }>
) => {
	state.deviceId = action.payload.deviceId;
};
const logout = (state: IAuthenticationState) => {
	state.token = undefined;
	state.refreshToken = undefined;
	state.error = undefined;
	state.apiResponse = undefined;
	state.deviceId = undefined;
};

const setAdminAccount = (
	state: IAuthenticationState,
	action: PayloadAction<string>
) => {
	state.adminAccount = action.payload;
};

// const resetAndForgotFailed = (state: IAuthenticationState, action: PayloadAction<IApiResponse>) => {
//   state.error = action.payload;
// };

const authenticateWithPin = (state: IAuthenticationState) => {
	state.remember = true;
};

const AuthenticationReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(AuthenticationActions.setToken.request, setToken)
		.addCase(AuthenticationActions.setDeviceId.request, setDeviceId)
		.addCase(AuthenticationActions.logOut.request, logout)
		.addCase(AuthenticationActions.clearToken.request, clearToken)
		.addCase(
			AuthenticationActions.authenticate.request,
			(state: IAuthenticationState, action: PayloadAction<ILoginRequest>) => {
				state.remember = action.payload.rememberMe;
			}
		)
		.addCase(AuthenticationActions.authenticate.failed, authenticateFailed)
		.addCase(AuthenticationActions.clearError.request, clearError)
		.addCase(AuthenticationActions.setAdminAccount.request, setAdminAccount)
		.addCase(
			AuthenticationActions.authenticateWithPin.request,
			authenticateWithPin
		);
});

export default AuthenticationReducer;
