import { Button, Card, ConfirmModal, Icon, Input } from 'components/atoms';
import { I18n, _t, translations } from 'utils';
import { useEffect, useState } from 'react';
import clsxm from 'clsxs/clsxm';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'helpers/hookHelpers';

import _ from 'lodash';
import { MaskInput } from 'components/atoms/MaskInput';
import { DatePicker } from 'components/molecules/DatePicker';
import { IStaffDetailForm, useStaffDetailContext } from '../context';
import { AvatarUpload } from 'components/molecules/AvatarUpload';
import moment from 'moment';
import { AlertHelper } from 'helpers';
import { IApiResponse, IErrorResponse } from 'models';
import UserApiService from 'services/UserApiService';
import { mdiReload } from '@mdi/js';
import { DateFormInput } from 'components/molecules/DateFormInput';

const HeaderSection = (props: {
	title: string;
	description?: string;
	divider?: boolean;
}) => {
	const { title, description, divider } = props;
	return (
		<div>
			<p className="font-semibold text-lg m-0">{title}</p>
			{description && <p className="m-0 text-sm">{description}</p>}
			{divider && <div className={clsxm('border-b border-b-base-200 my-2')} />}
		</div>
	);
};

const BasicInfo = (props: {}) => {
	const {
		formState: { errors },
		watch,
		setValue,
		control,
		register,
	} = useFormContext<IStaffDetailForm>();
	const { staff, isAdd } = useStaffDetailContext();
	const [adminPass, setAdminPass] = useState('');
	const [newPass, setNewPass] = useState('');
	const [showResetPassModal, setShowResetPassModal] = useState(false);
	const staffImgUrl = watch('imageUrl');

	const { setImg } = useStaffDetailContext();

	const user = useAppSelector((state) => state.UserReducer.user);

	useEffect(() => {
		register('imageUrl');
	}, [register]);

	useEffect(() => {
		if (isAdd) {
			setValue(
				'userName',
				`${watch('firstName')}${watch('lastName')}`.replace(/ /g, '')
			);
		}
	}, [watch('firstName'), watch('lastName')]);

	const turnOffResetModal = () => {
		setShowResetPassModal(false);
		setAdminPass('');
		setNewPass('');
	};

	const resetPassword = async () => {
		const result = (await UserApiService.resetUserPassword({
			userId: user?.id || '',
			adminPassword: adminPass,
		})) as IApiResponse<string>;
		if (result.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.staffDetail.resetPasswordSuccess))
			);
			setNewPass(result.data!);
		} else {
			AlertHelper.showError(result as IErrorResponse);
		}
	};
	return (
		<Card
			title={I18n.t(_t(translations.staffDetail.basicInfo))}
			description={I18n.t(_t(translations.staffDetail.description))}
			id="basic-info"
		>
			<div className={clsxm('grid grid-cols-4 gap-4 h-fit ')}>
				<div
					className={clsxm(
						'col-span-1 flex justify-center align-middle items-center'
					)}
				>
					<AvatarUpload
						source={staffImgUrl}
						onChange={(file) => {
							setImg(file);
						}}
					/>
				</div>
				<div className={clsxm('col-span-3')}>
					<div className={clsxm('grid grid-cols-2 gap-x-4 gap-y-2')}>
						<Input
							label={I18n.t(_t(translations.staffDetail.firstName))}
							maxLength={256}
							error={errors.firstName?.message}
							{...register('firstName', {
								required: I18n.t(_t(translations.staffDetail.messageFirstName)),
							})}
							placeholder={I18n.t(_t(translations.placeHolder.firstName))}
						/>
						<Input
							label={I18n.t(_t(translations.staffDetail.lastName))}
							maxLength={256}
							error={errors.lastName?.message}
							{...register('lastName', {
								required: I18n.t(_t(translations.staffDetail.messageLastName)),
							})}
							placeholder={I18n.t(_t(translations.placeHolder.lastName))}
						/>
					</div>
					<div className={clsxm('grid grid-cols-3 gap-x-4 gap-y-2 mt-2')}>
						<Controller
							render={(inputProps) => (
								<MaskInput
									error={errors.phone?.message}
									label={I18n.t(_t(translations.phone))}
									placeholder={I18n.t(_t(translations.placeHolder.phone))}
									{...inputProps.field}
									mask="999-999-9999"
								/>
							)}
							defaultValue={staff?.phone || ''}
							rules={{
								required: "Please enter staff's phone number",
							}}
							control={control}
							name={'phone'}
						/>

						<Input
							label={I18n.t(_t(translations.email))}
							placeholder={I18n.t(_t(translations.placeHolder.email))}
							error={errors.email?.message}
							{...register('email', {
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: 'Invalid email address',
								},
							})}
						/>
						<div>
							<DateFormInput
								error={errors.dateOfBirth?.message}
								label={I18n.t(_t(translations.staffDetail.birthDate))}
								rules={{ required: false }}
								placeholder="Date of birth"
								control={control as any}
								name={'dateOfBirth'}
								defaultValue={
									staff
										? staff.dateOfBirth
											? moment(staff.dateOfBirth).format('MM/DD/YYYY')
											: ''
										: ''
								}
							/>
							{/* <label className="label font-medium ">
								<span className="label-text text-md">
									{I18n.t(_t(translations.staffDetail.birthDate))}{' '}
								</span>
							</label>
							<DatePicker
								className="w-full"
								format={'MM/DD/YYYY'}
								selectedDate={moment(watch('dateOfBirth'))}
								onSelectDate={(date) =>
									setValue('dateOfBirth', date.toString())
								}
								title={_.isEmpty(watch('dateOfBirth')) ? 'Select date' : ''}
							/> */}
						</div>
					</div>

					<div className={clsxm('grid grid-cols-3 gap-x-4 gap-y-2 mt-2')}>
						<Input
							label={I18n.t(_t(translations.staffDetail.userName))}
							maxLength={256}
							{...register('userName')}
							disabled={!isAdd}
						/>
						{!isAdd && (
							<>
								<Input
									label={I18n.t(_t(translations.staffDetail.pinCode))}
									maxLength={256}
									{...register('code')}
									disabled
								/>

								<Button
									className="mt-8"
									small
									primary
									onClick={() => setShowResetPassModal(true)}
									title={I18n.t(_t(translations.staffDetail.resetPassword))}
								/>
							</>
						)}
					</div>
				</div>
			</div>

			<ConfirmModal
				onNegativeClick={turnOffResetModal}
				onPositiveClick={turnOffResetModal}
				visible={showResetPassModal}
				title="Reset Password"
			>
				<div className="flex gap-4 items-end justify-center align-middle my-2">
					<Input
						value={adminPass}
						label="Input admin password"
						onChange={(e) => setAdminPass(e.target.value)}
					/>
					<Button
						className="flex gap-2 "
						onClick={resetPassword}
						title={'Reset'}
						iconAfter={<Icon path={mdiReload} />}
						small
						primary
						disabled={_.isEmpty(adminPass)}
					/>
				</div>
				{!_.isEmpty(newPass) ? (
					<div dangerouslySetInnerHTML={{ __html: newPass || '' }} />
				) : null}
			</ConfirmModal>
		</Card>
	);
};

export default BasicInfo;
