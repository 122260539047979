import clsxm from 'clsxs/clsxm';
import { Modal } from 'components/atoms/Modal';
import { TimeBlock } from 'components/molecules/TimeBlock';
import { TimeHelper } from 'helpers';
import { useBookingHook } from 'helpers/bookingHelper';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BookingStatus } from 'utils/Consts';

import { t, translations } from 'utils';
import { useAppSelector } from 'helpers/hookHelpers';
import { BookingActions } from 'redux/actions';

interface ITimeModalProps {
	onCancel: () => void;
	onSelect?: (id: string, label: string) => void;
	disableCancel?: boolean;
}

const checkIsMorning = (time: string) =>
	parseFloat(TimeHelper.toTimeZone(time).format('HH')) < 12;

export const TimeModal = (props: ITimeModalProps) => {
	const {
		getAvailableStylistAndTimeBlocks,
		timeActiveId,
		currentBooking,
		timeBlocks,
	} = useBookingHook();

	const activeBlock = {
		label: `${timeActiveId}`,
		id: `${TimeHelper.generateTimeBlockId(`${timeActiveId}`)}`,
	};
	
	useEffect(() => {
		// if (_.isEmpty(bookingDetails[0].stylistId)) {
		getAvailableStylistAndTimeBlocks();
		// }
	}, []);

	const getTimeBlocks = () => {
		let timeBlockList = _.clone(
			TimeHelper.generateTimeBlocks(
				timeBlocks.filter((x) => !x.noStylistsAvailable && !x.unavailable)
			)
		);
		if (timeActiveId) {
			const activeBlock = {
				label: timeActiveId,
				id: timeActiveId!,
			};
			const hasBlock = !_.isEmpty(
				_.find(
					timeBlockList,
					(x) => x.id === activeBlock.id || x.label === activeBlock.id
				)
			);
			if (!hasBlock) {
				timeBlockList.push(activeBlock);
				timeBlockList = _.orderBy(timeBlockList, (x) => moment(x.label).unix());
			}
		}
		return timeBlockList;
	};

	const morningTimeBlock = getTimeBlocks().filter((x) =>
		checkIsMorning(x.label)
	);
	const afternoonTimeBlock = getTimeBlocks().filter(
		(x) => !checkIsMorning(x.label)
	);
	const bookingAction = useAppSelector(
		(state) => state.ReduxActionReducer['BOOKING']
	);

	const checkShowWarning = () =>{
		return bookingAction === BookingActions.getAvailableStylistAndTimeBlocks.successName && _.isEmpty(morningTimeBlock) && _.isEmpty(afternoonTimeBlock)
	}
	return (
		<>
			<Modal
				isOpen={true}
				onCancel={() => props.onCancel && props.onCancel()}
				title={'Select Time'}
				className={'booking-time-picker-modal w-fit min-w-[900px]'}
				disableCancel={props.disableCancel}
				content={
					<div className="p-4 overflow-y-auto h-full overflow-hidden w-full">
						{currentBooking?.status === BookingStatus.CHECKED_IN ? (
							<div>
								<h6>
									{checkIsMorning(activeBlock.label)
										? t(translations.customerBooking.morning)
										: t(translations.customerBooking.afternoon)}
								</h6>
								<TimeBlock {...activeBlock} />
							</div>
						) : (
							<div>
								{!_.isEmpty(morningTimeBlock) && (
									<div id="morning-time-block">
										<h6>{'Morning'}</h6>
										<div
											className={clsxm(
												'grid grid-flow-row-dense grid-cols-8 gap-2 p-4 h-fit'
											)}
										>
											{morningTimeBlock.map((x) => {
												return (
													<TimeBlock
														className="morning-time-0"
														onClick={() =>
															props.onSelect && props.onSelect(x.id, x.label)
														}
														id={`${x.id}`}
														active={x.label === activeBlock.label}
														label={x.label}
													/>
												);
											})}
										</div>
									</div>
								)}
								{!_.isEmpty(afternoonTimeBlock) && (
									<div id="after-time-block">
										<h6>{'Afternoon'}</h6>
										<div
											className={clsxm(
												'grid grid-flow-row-dense grid-cols-8 gap-2 p-4 h-fit'
											)}
										>
											{afternoonTimeBlock.map((x) => {
												return (
													<TimeBlock
														className="after-time-0"
														onClick={() =>
															props.onSelect && props.onSelect(x.id, x.label)
														}
														id={`${x.id}`}
														active={x.label === activeBlock.label}
														label={x.label}
													/>
												);
											})}
										</div>
									</div>
								)}
								{checkShowWarning() &&
									<div className="mt-6 items-center justify-center gap-2 ">
										<div className="items-center justify-center">
											<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" className="fill-current w-10 h-10 mx-auto" role="img" viewBox="0 0 576 512">
												<path fill="#A16207" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path>
											</svg>
											<h2 className="mt-2 text-center text-lg font-semibold">
												{t(translations.bookingPage.noTime)}
											</h2>
										</div>
									</div>
								}
							</div>
						)}
					</div>
				}
			/>
		</>
	);
};
