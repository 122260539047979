import clsxm from 'clsxs/clsxm';
import { Card, Input, Switch } from 'components/atoms';
import { t } from 'i18n-js';
import { useFormContext } from 'react-hook-form';
import { translations } from 'utils';
import { UpdateBranchFormField } from './IConfigBaseProps';

const PayrollConfiguration = ({ isLoading }: { isLoading: boolean }) => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
		getValues,
	} = useFormContext<UpdateBranchFormField>();

	const caculateTurnMethod = watch('calculateTurnMethod') as number;

	return (
		<Card
			title={t(translations.branchSetting.payrollConfiguration)}
			className="col-span-5"
			isDefaultAccordion={true}
			accordion={true}		
		>
			<div className={clsxm('flex gap-3')}>
				<Input
					label={t(translations.branchSetting.defaultCommission)}
					error={errors.defaultEmployeeCommission?.message}
					renderAfter={<>%</>}
					type="number"
					{...register('defaultEmployeeCommission', {
						disabled: isLoading,
					})}
				/>
				<Input
					label={t(translations.branchSetting.cardChargeByPercent)}
					error={errors.cardChargePercent?.message}
					renderAfter={<>%</>}
					type="number"
					{...register('cardChargePercent', { disabled: isLoading })}
				/>
				<Input
					label={t(translations.branchSetting.cardChargeByValue)}
					error={errors.cardChargeFlatRate?.message}
					renderAfter={<>$</>}
					type="number"
					{...register('cardChargeFlatRate', { disabled: isLoading })}
				/>
			</div>
			<div className="grid grid-cols-10 mt-3 gap-5">
				<div className="col-span-3">
					<Switch
						label="Turn caculating by value"
						onChange={(value) => {
							setValue('calculateTurnMethod', value ? 1 : 0);
							if (!value) {
								setValue('valuePerTurn', 1);
							}
						}}
						fullWidth
						value={caculateTurnMethod === 1}
						defaultChecked={getValues().calculateTurnMethod === 1}
					/>
				</div>
				<div className="col-span-7">
					{caculateTurnMethod === 1 && (
						<Input
							error={errors.valuePerTurn?.message}
							renderAfter={<>{t(translations.currency)}</>}
							type="number"
							{...register('valuePerTurn', { disabled: isLoading })}
						/>
					)}
				</div>
			</div>
		</Card>
	);
};

export default PayrollConfiguration;
