import { useAppSelector } from 'helpers/hookHelpers';
import { getTurnChartData } from 'redux/selectors/employee';

import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import { useTodayContext } from 'pages/Today/context';
import { CurrencyHelper } from 'helpers';

export const TurnChart = () => {
	const { chartType } = useTodayContext();
	const { labels, turnData, amountData } = useAppSelector((state) =>
		getTurnChartData(state)
	);
	const options: ChartOptions<'bar'> = useMemo(() => {
		return {
			indexAxis: 'y' as const,
			plugins: {
				title: {
					display: false,
				},
				datalabels: {
					display: (context) => {
						const index = context.dataIndex;
						const value = context.dataset.data[index];
						return value !== 0;
					},
					formatter(value, context) {
						if (chartType === 1) {
							return value;
						} else {
							return `${CurrencyHelper.formatBalance(value)}`;
						}
					},
					color: 'white',
					align: 'center',
				},
			},
			responsive: true,
			scales: {
				x: {
					stacked: true,
					ticks: {
						min: 0,
						max: chartType === 1 ? 50 : 500000,
						stepSize: chartType === 1 ? 10 : 1000,
					},
				},
				y: {
					stacked: true,
				},
			},
		};
	}, [chartType]);
	const data = {
		labels,
		datasets: chartType === 1 ? turnData : amountData || [],
	};
	const { innerHeight } = window;
	return (
		<div id="turn-chart" className="h-full w-full overflow-hidden ">
			<Bar
				className="overflow-hidden"
				height={_.min([150, innerHeight / 4])}
				plugins={[ChartDataLabels]}
				options={options}
				data={data}
			/>
		</div>
	);
};
