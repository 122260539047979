import {
	Card,
	Input,
	TextArea,
	Switch,
	ButtonGroup,
	Select,
	Button,
	Modal,
} from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { AlertHelper } from 'helpers';
import { showError } from 'helpers/alertHelper';
import _ from 'lodash';
import { IApiResponse } from 'models';
import { ITextMessage, ITextMessageSendRequest } from 'models/ITextMessage';
import { ITextTemplate } from 'models/ITextTemplate';
import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TextMessageApiService from 'services/TextMessageApiService';
import TextTemplateApiService from 'services/TextTemplateApiService';
import { _t, translations, I18n, t } from 'utils';

type ITextTemplateForm = ITextTemplate & {};

export const TextTemplateDetailPage = () => {
	const location = useLocation();
	const history = useHistory();
	const { id } = useParams() as { id: string };

	const isAdd = location.pathname.includes('add');
	const [loading, setLoading] = useState(false);
	const [templates, setTemplates] = useState<ITextTemplate[]>([]);
	const [isPreview, setIsPreview] = useState(false);

	const form = useForm<ITextTemplateForm>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	useEffect(() => {
		if (!isAdd) {
			fetchTemplateDetail();
		}
	}, []);

	const fetchTemplateDetail = async () => {
		setLoading(true);

		const result = await TextTemplateApiService.getTemplateById(id);
		setLoading(false);

		if (result.succeeded) {
			const x = result.data;
			form.reset({
				content: x?.content,
				id: x?.id,
				name: x?.name,
			});
		}
	};

	const onDeleteTemplate = async () => {
		setLoading(true);

		const result = await TextTemplateApiService.deleteTemplate(id);
		if (result.succeeded) {
			setLoading(false);
			AlertHelper.showSuccess(
				I18n.t(_t(translations.templateDetail.messageDeleteSuccess))
			);
			history.goBack();
		} else {
			setLoading(false);

			AlertHelper.showError(result);
		}
	};

	const submitForm = async (formData: ITextTemplateForm) => {
		setLoading(true);
		const data = {
			id: formData.id,
			name: formData.name,
			content: formData.content,
		};

		const res = isAdd
			? await TextTemplateApiService.addTemplate(data)
			: await TextTemplateApiService.editTemplate(data);
		setLoading(false);

		if (res.succeeded) {
			AlertHelper.showSuccess(
				isAdd
					? I18n.t(_t(translations.templateDetail.messageAddTemplateSuccess))
					: I18n.t(_t(translations.templateDetail.messageUpdateTemplateSuccess))
			);

			history.goBack();
		} else {
			AlertHelper.showError(res);
		}
	};

	return (
		<DetailPageLayout<ITextTemplateForm>
			title={I18n.t(
				_t(
					isAdd
						? translations.templateDetail.addNew
						: translations.templateDetail.editTemplate
				)
			)}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			loading={loading}
			extraRight={
				<Button
					className="btn btn-outline"
					small
					title={t(translations.templateDetail.preview)}
					onClick={(e) => {
						setIsPreview(true);
					}}
				/>
			}
			showDelete={!isAdd}
			onDelete={onDeleteTemplate}
		>
			<Body
				templates={templates}
				isPreview={isPreview}
				setIsPreview={setIsPreview}
			/>
		</DetailPageLayout>
	);
};

const Body = (props: {
	templates: ITextTemplate[];
	isPreview: boolean;
	setIsPreview: (a: boolean) => void;
}) => {
	const {
		formState: { errors },
		watch,
		setValue,
		control,
		register,
	} = useFormContext<ITextTemplate>();

	return (
		<>
			<Card className="mx-48 flex flex-col gap-3">
				<Input
					label={I18n.t(_t(translations.templateDetail.templateName))}
					maxLength={256}
					error={errors.name?.message}
					{...register('name', {
						required: I18n.t(
							_t(translations.templateDetail.messageTemplateContent)
						),
					})}
				/>

				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{I18n.t(_t(translations.templateDetail.templateContent))}
							<span className="text-DELETED">
								{' '}
								Character count: {(watch('content') || '').length}/
								{Math.ceil((watch('content') || '').length / 160)} SMS
							</span>
						</span>
					</label>
					<div>
						<TextArea
							placeholder={I18n.t(
								_t(translations.templateDetail.inputTemplateContent)
							)}
							error={errors.content?.message}
							{...register('content')}
							rows={10}
						/>
					</div>
				</div>
			</Card>
			<Modal
				title={t(translations.templateDetail.preview)}
				isOpen={props.isPreview}
				content={
					<div className="p-8">
						<h3 className="text-lg font-semibold my-2">Content</h3>
						<div>{watch('content')}</div>
					</div>
				}
				onCancel={() => props.setIsPreview(false)}
			/>
		</>
	);
};
