import { Button, Card, ConfirmModal, Icon, Input } from 'components/atoms';
import Table, { EColumnType, IColumnProps } from 'components/atoms/Table';
import { AlertHelper } from 'helpers';
import { IApiResponse, IErrorResponse } from 'models';
import { IPromotionCoupon, IPromotionCouponRequest } from 'models/IPromotion';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PromotionApiService from 'services/PromotionApiService';
import { I18n, _t, translations } from 'utils';
import {
	mdiCheck,
	mdiClose,
	mdiCloseCircleOutline,
	mdiContentCopy,
	mdiDelete,
	mdiPlus,
} from '@mdi/js';
import { isEmpty } from 'lodash';

interface ICouponTableProps {
	dataSource: IPromotionCoupon[];
	setData: (value: IPromotionCoupon[]) => void;
	editable: boolean;
	setShowModalDelete: (value: boolean) => void;
	setCouponDelete: (value: IPromotionCoupon) => void;
}

const CouponTable = (props: ICouponTableProps) => {
	const handleChange = (value: string) => {
		const newListCoupons = props.dataSource.map((coupon) =>
			coupon.id === `tempCoupon${props.dataSource.length - 1}`
				? { ...coupon, couponCode: value }
				: coupon
		);
		props.setData(newListCoupons);
	};
	const columns = useMemo<IColumnProps<IPromotionCoupon>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.promotionDetail.coupon)),
				key: 'couponCode',
				renderCell: (data) => {
					return data.id === `tempCoupon${props.dataSource.length - 1}` &&
						props.editable ? (
						<Input
							onChange={(e) => {
								handleChange(e.target.value);
							}}
							autoFocus
							placeholder="Please enter coupon code"
						/>
					) : (
						data.couponCode
					);
				},
			},
			{
				header: I18n.t(_t(translations.promotionDetail.used)),
				key: 'used',
				renderCell: (data) => {
					return data.id === `tempCoupon${props.dataSource.length - 1}` &&
						props.editable ? null : data.used ? (
						<Icon path={mdiCheck} />
					) : (
						<Icon path={mdiClose} />
					);
				},
			},
			{
				header: I18n.t(_t(translations.action)),
				key: 'id',
				type: EColumnType.OTHERS,
				renderCell: (data) => {
					return data.id === `tempCoupon${props.dataSource.length - 1}` &&
						props.editable ? null : (
						<div className="flex justify-end gap-x-4">
							<Button
								small
								onClick={() => {
									navigator.clipboard.writeText(data.couponCode);
									AlertHelper.showSuccess(
										I18n.t(_t(translations.promotionDetail.copiedCode))
									);
								}}
								iconBefore={<Icon path={mdiContentCopy} />}
							/>
							<Button
								className="btn btn-outline text-ERROR"
								small
								onClick={() => {
									props.setCouponDelete(data);
									props.setShowModalDelete(true);
								}}
								iconBefore={<Icon path={mdiDelete} />}
							/>
						</div>
					);
				},
			},
		];
	}, [props.dataSource, props.editable]);
	return <Table data={props.dataSource} columns={columns} className="h-fit" />;
};

interface ICouponProps {
	isAdd: boolean;
	couponCode: IPromotionCoupon[];
	setCoupons: (value: IPromotionCoupon[]) => void;
}
export const CouponCode = (props: ICouponProps) => {
	const [coupons, setCoupons] = useState<IPromotionCoupon[]>([]);
	const [editable, setEditable] = useState<boolean>(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [couponDelete, setCouponDelete] = useState<IPromotionCoupon>();
	const { id } = useParams() as { id: string };

	const onAddNew = () => {
		setCoupons([
			...coupons,
			{
				id: `tempCoupon${coupons.length}`,
				promotionId: '',
				couponCode: '',
				used: false,
				quantity: 0,
				shopId: '',
			},
		]);
		setEditable(true);
	};

	const fetchCoupons = async () => {
		try {
			const result = (await PromotionApiService.getPromotionCoupon({
				promotionId: id,
			})) as IApiResponse<IPromotionCoupon[]>;
			if (result.succeeded) {
				setCoupons(result.data || []);
			}
		} catch (error) {}
	};

	const onUpdateCoupon = async () => {
		const codes = coupons.map((e) => e.couponCode);
		const data: Partial<IPromotionCouponRequest> = {
			promotionId: id,
			couponCodes: codes,
		};
		try {
			const result = await PromotionApiService.updatePromotionCoupon(data);
			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.promotionDetail.messageAddedCoupon))
				);
				setEditable(false);
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const onDelete = async (value: IPromotionCoupon) => {
		const newData = coupons.filter((e) => e.id !== value.id);
		setCoupons(newData);
		if (props.isAdd) {
			props.setCoupons(coupons);
		} else {
			try {
				const result = await PromotionApiService.removePromotionCoupon({
					promotionId: id,
					couponCodes: [value.couponCode],
				});
				if (result.succeeded) {
					AlertHelper.showSuccess(
						I18n.t(_t(translations.promotionDetail.messageDeletedCoupon))
					);
					fetchCoupons();
				} else {
					const error = result as IErrorResponse;
					AlertHelper.showError(error);
				}
			} catch (error) {}
		}
	};

	useEffect(() => {
		const ac = new AbortController();
		if (!props.isAdd) {
			fetchCoupons();
		} else {
			if (!isEmpty(props.couponCode)) {
				setCoupons(props.couponCode);
			}
		}
		return () => {
			ac.abort();
		};
	}, []);

	return (
		<Card>
			<div className="flex justify-end gap-x-4">
				<Button
					small
					onClick={() => {
						onAddNew();
					}}
					title={I18n.t(_t(translations.addNew))}
					disabled={editable}
					iconBefore={<Icon path={mdiPlus} />}
				/>
				<Button
					small
					onClick={(e) => {
						if (props.isAdd) {
							props.setCoupons(coupons);
							setEditable(false);
						} else {
							onUpdateCoupon();
						}
					}}
					iconBefore={<Icon path={mdiCheck} />}
					disabled={!editable}
					title={I18n.t(_t(translations.save))}
				/>
				<Button
					small
					onClick={(e) => {
						if (editable) {
							const newCoupons = coupons.slice(0, coupons.length - 1);
							setCoupons(newCoupons);
							setEditable(false);
						}
					}}
					iconBefore={<Icon path={mdiCloseCircleOutline} />}
					disabled={!editable}
					title={I18n.t(_t(translations.cancel))}
				/>
			</div>
			<CouponTable
				dataSource={coupons}
				setData={(value) => {
					setCoupons(value);
				}}
				editable={editable}
				setShowModalDelete={(value) => setShowModalDelete(value)}
				setCouponDelete={(value) => setCouponDelete(value)}
			/>
			<ConfirmModal
				visible={showModalDelete}
				title={I18n.t(_t(translations.delete))}
				subTitle={I18n.t(
					_t(translations.promotionDetail.deleteCouponConfirmContent)
				)}
				onPositiveClick={() => {
					onDelete(couponDelete!);
					setShowModalDelete(false);
				}}
				onNegativeClick={() => setShowModalDelete(false)}
				type="error"
			/>
		</Card>
	);
};
