import clsxm from 'clsxs/clsxm';
import React from 'react';

interface ISwitchProps {
	value?: boolean;
	onChange: (value: boolean) => void;
	label?: string;
	primary?: boolean;
	defaultChecked?: boolean;
	className?: string;
	labelClassName?: string;
	checkBoxClassName?: string;
	disabled?: boolean;
	fullWidth?: boolean;
	subLabel?: string;
}
export const Switch = (props: ISwitchProps) => {
	return (
		<div className={props.className}>
			<label
				className={clsxm(
					`label font-medium cursor-pointer ${
						props.fullWidth ? 'w-full' : 'w-2/3'
					} items-start`,
					props.labelClassName
				)}
			>
				<div className="flex flex-col mx-1">
					{props.label && <span className="label-text">{props.label}</span>}
					{props.subLabel && (
						<span className="text-sm font-normal">{props.subLabel}</span>
					)}
				</div>
				<input
					onChange={() => props.onChange(!props.value)}
					type="checkbox"
					className={clsxm(
						'toggle',
						props.value && 'toggle-primary',
						props.checkBoxClassName
					)}
					checked={props.value}
					defaultChecked={props.defaultChecked}
					disabled={props.disabled}
				/>
			</label>
		</div>
	);
};
