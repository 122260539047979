import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
  IBookingActivity,
  IRecentSalesChartDataSource,
  ITopServices,
  ITopStaffs,
  IUpComingBookingsChartDataSource,
} from "models";
import { IPaginateResponse } from "models/ResponseModels";
import { AdminDashboardActions } from "redux/actions";

interface IAdminDashboardState {
  recentSalesChart?: {
    data?: IRecentSalesChartDataSource;
    isLoading?: boolean;
  };
  upComingBookingChart?: {
    data?: IUpComingBookingsChartDataSource;
    isLoading?: boolean;
  };
  bookingActivity?: IPaginateResponse<IBookingActivity[]> & any;
  todayNextBookingActivity?: IPaginateResponse<IBookingActivity[]> & any;
  topServices?: { data?: ITopServices[]; isLoading?: boolean };
  topStaffs?: { data?: ITopStaffs[]; isLoading?: boolean };
}

const initialState: IAdminDashboardState = {};

const getRecentSalesChartRequest = (state: IAdminDashboardState) => {
  state.recentSalesChart = {
    ...state.recentSalesChart,
    isLoading: true,
  };
};

const getRecentSalesChartSuccess = (
  state: IAdminDashboardState,
  action: PayloadAction<IRecentSalesChartDataSource>
) => {
  state.recentSalesChart = { data: action.payload, isLoading: false };
};

const getUpComingBookingsChartRequest = (state: IAdminDashboardState) => {
  state.upComingBookingChart = {
    ...state.upComingBookingChart,
    isLoading: true,
  };
};

const getUpComingBookingsChartSuccess = (
  state: IAdminDashboardState,
  action: PayloadAction<IUpComingBookingsChartDataSource>
) => {
  state.upComingBookingChart = { data: action.payload, isLoading: false };
};

const getBookingActivityRequest = (state: IAdminDashboardState) => {
  state.bookingActivity = {
    ...state.bookingActivity,
    isLoading: true,
  };
};

const getBookingActivitySuccess = (
  state: IAdminDashboardState,
  action: PayloadAction<IPaginateResponse<IBookingActivity[]>>
) => {
  state.bookingActivity = {
    ...action.payload,
    data: state.bookingActivity?.data?.length
      ? state.bookingActivity?.data?.concat(action.payload.data!)
      : action.payload.data,
    isLoading: false,
  };
};

const getTodayNextBookingActivityRequest = (state: IAdminDashboardState) => {
  state.todayNextBookingActivity = {
    ...state.todayNextBookingActivity,
    isLoading: true,
  };
};

const getTodayNextBookingActivitySuccess = (
  state: IAdminDashboardState,
  action: PayloadAction<IPaginateResponse<IBookingActivity[]>>
) => {
  state.todayNextBookingActivity = {
    ...action.payload,
    data: state.todayNextBookingActivity?.data?.length
      ? state.todayNextBookingActivity?.data?.concat(action.payload.data!)
      : action.payload.data,
    isLoading: false,
  };
};

const getTopServicesRequest = (state: IAdminDashboardState) => {
  state.topServices = { isLoading: true };
};

const getTopServicesSuccess = (
  state: IAdminDashboardState,
  action: PayloadAction<ITopServices[]>
) => {
  state.topServices = { data: action.payload, isLoading: false };
};

const getTopStaffsRequest = (state: IAdminDashboardState) => {
  state.topStaffs = {
    isLoading: true,
  };
};

const getTopStaffsSuccess = (
  state: IAdminDashboardState,
  action: PayloadAction<ITopStaffs[]>
) => {
  state.topStaffs = { data: action.payload, isLoading: false };
};

const clearData = (state: IAdminDashboardState) => {
  state.bookingActivity = undefined;
  state.recentSalesChart = undefined;
  state.todayNextBookingActivity = undefined;
  state.topServices = undefined;
  state.topStaffs = undefined;
  state.upComingBookingChart = undefined;
};

const adminDashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      AdminDashboardActions.getRecentSalesChart.request,
      getRecentSalesChartRequest
    )
    .addCase(
      AdminDashboardActions.getRecentSalesChart.success,
      getRecentSalesChartSuccess
    )
    .addCase(
      AdminDashboardActions.getUpComingBookingsChart.request,
      getUpComingBookingsChartRequest
    )
    .addCase(
      AdminDashboardActions.getUpComingBookingsChart.success,
      getUpComingBookingsChartSuccess
    )
    .addCase(
      AdminDashboardActions.getBookingActivity.request,
      getBookingActivityRequest
    )
    .addCase(
      AdminDashboardActions.getBookingActivity.success,
      getBookingActivitySuccess
    )
    .addCase(
      AdminDashboardActions.getTodayNextBookingActivity.request,
      getTodayNextBookingActivityRequest
    )
    .addCase(
      AdminDashboardActions.getTodayNextBookingActivity.success,
      getTodayNextBookingActivitySuccess
    )
    .addCase(
      AdminDashboardActions.getTopServices.request,
      getTopServicesRequest
    )
    .addCase(
      AdminDashboardActions.getTopServices.success,
      getTopServicesSuccess
    )
    .addCase(AdminDashboardActions.getTopStaffs.request, getTopStaffsRequest)
    .addCase(AdminDashboardActions.getTopStaffs.success, getTopStaffsSuccess)
    .addCase(AdminDashboardActions.clearData.request, clearData);
});

export default adminDashboardReducer;
