import { IApiResponse, IEmployee, IRole } from 'models';
import { put, takeEvery, call } from 'redux-saga/effects';
import { RoleActions } from 'redux/actions';
import { IErrorResponse } from '../../models/IErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	IAddEmployeeRequest,
	IEditEmployeeRequest,
} from 'models/RequestModels';
import UploadBaseService from 'services/UploadBaseService';
import { IFileUploadResponse } from 'models/ResponseModels';
import { AlertHelper } from 'helpers';
import { I18n, _t, translations } from 'utils';
import RoleApiService from 'services/RoleApiService';
function* getAllRoles() {
	try {
		const result: IApiResponse<IRole[]> = yield call(
			RoleApiService.getAllRoles
		);
		if (result.succeeded) {
			yield put(RoleActions.getAllRoles.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			yield put(RoleActions.getAllRoles.failed(error));
		}
	} catch (error) {
		yield put(RoleActions.getAllRoles.failed(error));
	}
}
export function* RoleWatcher() {
	yield takeEvery(RoleActions.getAllRoles.requestName, getAllRoles);
}
