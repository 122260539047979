import { getDayOfWeek } from 'helpers/StringHelper';
import _ from 'lodash';
import { EEmployeeTypeCode, IApiResponse, IEmployee, IService } from 'models';
import { OnlineWorkingHour, WorkingHour } from 'models/IBranch';
import React, {
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { createContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import EmployeeApiService from 'services/EmployeeApiService';
import momentTz from 'moment-timezone';
import { useAppSelector } from 'helpers/hookHelpers';
import UploadBaseService from 'services/UploadBaseService';
import { IFileUploadResponse } from 'models/ResponseModels';
import { showError, showSuccess } from 'helpers/alertHelper';
import moment from 'moment';
import { IAddEmployeeRequest } from 'models/RequestModels';
import { ChargeRate, DefaultPassword } from 'utils/Consts';
import { StringHelper } from 'helpers';
import { useDispatch } from 'react-redux';
import { EmployeeActions } from 'redux/actions';

export type IStaffDetailForm = Omit<
	IEmployee,
	| 'employeeTypes'
	| 'dateOfBirth'
	| 'hireDate'
	| 'address'
	| 'employeeTypeCode'
	| 'salaryEffectiveStartDate'
	| 'employeeWorkingTimes'
> & {
	hireDate?: string;
	dateOfBirth?: string;
	employeeTypes: {
		id: string;
		code?: string;
		description?: string;
		roleId?: string;
		name?: string;
		roleName?: string;
	}[];
	address: {
		street?: string;
		city?: string;
		state?: string;
		zipCode?: string;
		countryCode?: string;
	};
	employeeTypeCode: EEmployeeTypeCode;
	salaryEffectiveStartDate: string;
	employeeWorkingTimes: WorkingHour[];
	employeeOnlineBookingTimes: WorkingHour[];
	wholeweek: string[];
	sunday: string[];
	monday: string[];
	tuesday: string[];
	wednesday: string[];
	thursday: string[];
	friday: string[];
	saturday: string[];
	wholeweekCheck: boolean;
	sundayCheck: boolean;
	mondayCheck: boolean;
	tuesdayCheck: boolean;
	wednesdayCheck: boolean;
	thursdayCheck: boolean;
	fridayCheck: boolean;
	saturdayCheck: boolean;
	//online
	wholeweekOnline: string[];
	sundayOnline: string[];
	mondayOnline: string[];
	tuesdayOnline: string[];
	wednesdayOnline: string[];
	thursdayOnline: string[];
	fridayOnline: string[];
	saturdayOnline: string[];
	wholeweekOnlineCheck: boolean;
	sundayOnlineCheck: boolean;
	mondayOnlineCheck: boolean;
	tuesdayOnlineCheck: boolean;
	wednesdayOnlineCheck: boolean;
	thursdayOnlineCheck: boolean;
	fridayOnlineCheck: boolean;
	saturdayOnlineCheck: boolean;
	valuePerTurn?: number;
	calculateTurnMethod?: number;
	displayDurationOnPortal: boolean;
	displayEmployeesOnPortal: boolean;
};

interface IStaffDetailContext {
	loading?: boolean;
	staff?: IEmployee;
	form?: UseFormReturn<IStaffDetailForm, object>;
	submitForm: (formData: IStaffDetailForm) => void;
	isAdd?: boolean;
	currentEmployeeTypeIds?: string[];
	setCurrentEmployeeTypeIds: (employeeTypes: string[]) => void;
	servicesOnTable: Partial<IService>[];
	setServicesOnTable: (services: Partial<IService>[]) => void;
	setImg: (file: File) => void;
	onDeleteStaff: () => void;
}

const StaffDetailContext = createContext<IStaffDetailContext>({
	submitForm: () => {},
	setCurrentEmployeeTypeIds: () => {},
	setServicesOnTable: () => {},
	servicesOnTable: [],
	setImg: () => {},
	onDeleteStaff: () => {},
});

const useStaffDetailContext = () => {
	return useContext(StaffDetailContext);
};

const StaffDetailProvider: React.FC<{
	children?: ReactNode;
	staffId?: string;
}> = ({ children, staffId }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const form = useForm<IStaffDetailForm>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});
	const { currentBranch } = useAppSelector((state) => state.BranchReducer);
	const allEmployeeTypes = useAppSelector(
		(state) => state.EmployeeTypeReducer.employeeTypes
	);
	const allServices = useAppSelector((state) => state.ServiceReducer.services);
	const [img, setImg] = useState<File>();

	const [loading, setLoading] = useState(false);
	const [staff, setStaff] = useState<IEmployee>();
	const [currentEmployeeTypeIds, setCurrentEmployeeTypeIds] = useState<
		string[]
	>([]);

	const [servicesOnTable, setServicesOnTable] = useState<Partial<IService>[]>(
		[]
	);

	useEffect(() => {
		if (staff?.serviceDurations) {
			let servicesByStaff: Partial<IService>[] = staff?.serviceDurations?.map(
				(x) => {
					const service = _.find(allServices, (e) => e.id === x.serviceId);
					return {
						id: x.serviceId,
						duration:
							x.duration && x.duration !== 0 ? x.duration : service?.duration,
						name: service?.name || '',
						price: service?.price,
						itemCategoryId: service?.itemCategoryId,
					};
				}
			);
			setServicesOnTable(servicesByStaff);
		}
	}, [staff?.serviceDurations]);

	useEffect(() => {
		if (!isAdd) {
			getEmployeeById();
		} else {
			form.setValue('userName', '');
			form.setValue(
				'servicesCommissionPercent',
				currentBranch?.defaultEmployeeCommission
			);
			form.setValue(
				'giftCardCommissionPercent',
				currentBranch?.defaultEmployeeCommission
			);
			form.setValue('checkPercent', currentBranch?.defaultEmployeeCommission);
			form.setValue('cardChargePercent', ChargeRate.CARD_CHARGE);
			form.setValue('tipCardChargePercent', ChargeRate.TIP_CARD_CHARGE);
		}
	}, []);

	const isAdd = location.pathname.includes('add');

	const getEmployeeById = async () => {
		setLoading(true);
		try {
			const res = (await EmployeeApiService.getEmployeeById(
				staffId!
			)) as IApiResponse<IEmployee>;
			if (res.succeeded) {
				setStaff(res.data);
				const roles = res.data?.employeeTypes?.map((x) => x?.id || '');
				setCurrentEmployeeTypeIds(roles!);
				form.reset({
					imageUrl: res.data?.imageUrl,
					firstName: res.data?.firstName,
					lastName: res.data?.lastName,
					dateOfBirth: !_.isEmpty(res.data?.dateOfBirth)
						? moment(res.data?.dateOfBirth).format('MM/DD/YYYY')
						: ('' as unknown as string),
					email: res.data?.email,
					phone: res.data?.phone,
					hireDate: res.data?.hireDate as string,
					userName: res.data?.userName,
					code: res.data?.code,
					employeeTypeCode: res.data?.employeeTypeCode,
					color: res.data?.color,
					salaryType: res.data?.salaryType,
					salary: res.data?.salary,
					incomeType: res.data?.incomeType,
					checkPercent: res.data?.checkPercent,
					tipCardChargePercent: res.data?.tipCardChargePercent,
					cardChargePercent: res.data?.cardChargePercent,
					servicesCommissionPercent: res.data?.servicesCommissionPercent,
					giftCardCommissionPercent: res.data?.giftCardCommissionPercent,
					salaryEffectiveStartDate: res.data
						?.salaryEffectiveStartDate as unknown as string,
					employeeWorkingTimes: res.data?.employeeWorkingTimes,
					employeeOnlineBookingTimes: res.data?.employeeOnlineBookingTimes,
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const getWorkingTimes = (
		formData: IStaffDetailForm,
		online = false
	): WorkingHour[] | OnlineWorkingHour[] => {
		//clone the current working times
		const currentWorkingTimes = Object.assign(
			[],
			online ? staff?.employeeOnlineBookingTimes : staff?.employeeWorkingTimes
		) as WorkingHour[];

		//Get the new "switch on" working times
		const newWorkingTimes: Partial<WorkingHour>[] = [];
		for (let i = 0; i < 7; ++i) {
			const day = `${getDayOfWeek(i).replaceAll(' ', '').toLowerCase()}${
				online ? 'Online' : ''
			}`;

			if (formData[`${day}Check` as keyof IStaffDetailForm]) {
				const workingTime = formData[
					`${day}` as keyof IStaffDetailForm
				] as string[];
				// const nextDate = this.isNextDate(workingTime);
				const data = {
					workingTimeStartAt: online
						? undefined
						: momentTz
								.tz(workingTime[0], currentBranch!.localTimeZone!)
								.utc()
								.format('YYYY-MM-DDTHH:mm'),
					onlineBookingTimeStartAt: !online
						? undefined
						: momentTz
								.tz(workingTime[0], currentBranch!.localTimeZone!)
								.utc()
								.format('YYYY-MM-DDTHH:mm'),

					workingTimeEndAt: online
						? undefined
						: momentTz
								.tz(workingTime[1], currentBranch!.localTimeZone!)
								.utc()
								.format('YYYY-MM-DDTHH:mm'),
					onlineBookingTimeEndAt: !online
						? undefined
						: momentTz
								.tz(workingTime[1], currentBranch!.localTimeZone!)
								.utc()
								.format('YYYY-MM-DDTHH:mm'),
					breakingTimeEndAt: workingTime[3]
						? momentTz
								.tz(workingTime[3], currentBranch!.localTimeZone!)
								.utc()
								.format('YYYY-MM-DDTHH:mm')
						: undefined,
					breakingTimeStartAt: workingTime[2]
						? momentTz
								.tz(workingTime[2], currentBranch!.localTimeZone!)
								.utc()
								.format('YYYY-MM-DDTHH:mm')
						: undefined,
				};

				newWorkingTimes.push({
					days: i.toString(),
					...data,
				});
			}
		}
		//remove the "switch off" times from current
		const cleanCurrentWorkingTimes = currentWorkingTimes.filter((element) => {
			const day = `${getDayOfWeek(Number.parseInt(element.days!))
				.replaceAll(' ', '')
				.toLowerCase()}${online ? 'Online' : ''}`;

			if (formData[`${day}Check` as keyof IStaffDetailForm]) {
				return true;
			}
			return false;
		});
		//update or add to current working times
		for (let i = 0; i < 7; ++i) {
			//if the day exist
			const currentIndex = cleanCurrentWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			const newIndex = newWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			if (currentIndex !== -1) {
				//update the working time;
				cleanCurrentWorkingTimes[currentIndex] = {
					...cleanCurrentWorkingTimes[currentIndex],
					...newWorkingTimes[newIndex],
				};
			} else if (newIndex !== -1) {
				//add new working time;
				cleanCurrentWorkingTimes.push(newWorkingTimes[newIndex]);
			}
		}
		return cleanCurrentWorkingTimes;
	};

	const submitForm = async (formData: IStaffDetailForm) => {
		let imageUrl = staff?.imageUrl || '';
		if (img) {
			const uploadResult = (await UploadBaseService.uploadImage([
				img,
			])) as IApiResponse<IFileUploadResponse[]>;
			if (uploadResult.succeeded) {
				imageUrl = uploadResult.data![0].imageUrl;
			} else {
				showError(uploadResult);
				return;
			}
		}

		const data: Partial<IAddEmployeeRequest> & Partial<IEmployee> = {
			id: staff?.id,
			userId: staff?.userId,
			employeeWorkingTimes: getWorkingTimes(formData),
			employeeOnlineBookingTimes: getWorkingTimes(formData, true),
			imageUrl: staff?.imageUrl && !img ? '' : imageUrl, // case delete img
			code: `${moment().unix()}`,
			color: formData.color,
			hireDate: formData.hireDate || moment().format('MM/DD/YYYY'),
			// employeeTypeId: this.state.employeeTypeId, //need to check
			employeeTypeIds: currentEmployeeTypeIds,
			departmentId: undefined,
			gender: 0,
			employeeTypeCodes: _.map(
				allEmployeeTypes.filter((x) => currentEmployeeTypeIds.includes(x.id)),
				(x) => x.code || ''
			),
			dateOfBirth: formData?.dateOfBirth,
			commissionPercent: formData.commissionPercent || 0,
			branchId: currentBranch?.id!,
			firstName: formData.firstName,
			lastName: formData.lastName,
			shopId: currentBranch?.shopId!,
			userName: formData.userName,
			email: formData.email,
			password: DefaultPassword.PASSWORD,
			confirmPassword: DefaultPassword.PASSWORD,
			phone: StringHelper.convertToRawPhone(formData?.phone || ''),
			additionalName: '',
			address: {
				street: '',
				city: '',
				state: '',
				zipCode: '',
				countryCode: '',
			},
			status: isAdd ? 1 : staff?.status,
			// serviceIds: servicesOnTable?.map((x) => x.id),
			serviceDurations: _.uniqBy(
				servicesOnTable?.map((x) => {
					return {
						serviceId: x.id || '',
						duration: x.duration || 0,
					};
				}),
				(y) => y.serviceId
			),
			incomeType: formData.incomeType || 1,
			salaryType: formData.salaryType || 0,
			salary: formData.salary || 0,
			servicesCommissionPercent: formData.servicesCommissionPercent || 0,
			productCommissionPercent: 0,
			giftCardCommissionPercent: formData.giftCardCommissionPercent || 0,
			checkPercent: formData.checkPercent || 0,
			cardChargePercent: formData.cardChargePercent || 0,
			tipCardChargePercent: formData.tipCardChargePercent || 0,
			salaryEffectiveStartDate: isAdd
				? formData.hireDate || moment().format('MM/DD/YYYY')
				: formData.salaryEffectiveStartDate!,
			canBookedOnline: isAdd ? true : staff?.canBookedOnline,
		};
		console.log('data',data);
		
		changeStaff(data);
	};

	const changeStaff = async (
		data: Partial<IAddEmployeeRequest> & Partial<IEmployee>
	) => {
		let res;
		if (_.isEmpty(data.id)) {
			res = await EmployeeApiService.addEmployee(data as any);
			if (res.succeeded) {
				showSuccess('Add new staff successfully');
				dispatch(EmployeeActions.addEmployee.success(res.data!));
				history.goBack();
			} else {
				showError(res);
			}
		} else {
			res = await EmployeeApiService.editEmployee(data as any);
			if (res.succeeded) {
				showSuccess('Edit staff successfully');
				dispatch(EmployeeActions.editEmployee.success(res.data!));

				history.goBack();
			} else {
				showError(res);
			}
		}
	};

	const onDeleteStaff = async () => {
		const res = await EmployeeApiService.deleteEmployee(staffId || '');
		if (res.succeeded) {
			showSuccess('Delete staff successfully');
			history.goBack();
		} else {
			showError(res);
		}
	};

	const value: IStaffDetailContext = {
		form,
		staff,
		loading: useMemo(() => loading, [loading]),
		isAdd,
		submitForm,
		currentEmployeeTypeIds,
		setCurrentEmployeeTypeIds,
		servicesOnTable,
		setServicesOnTable,
		setImg,
		onDeleteStaff,
	};

	return (
		<StaffDetailContext.Provider value={value}>
			{children}
		</StaffDetailContext.Provider>
	);
};

export { StaffDetailProvider, useStaffDetailContext };
