import { all } from 'redux-saga/effects';
import {
	User,
	Service,
	Category,
	Authentication,
	Employee,
	Branch,
	Booking,
	Customer,
	Shop,
	Search,
	CheckOut,
	Holiday,
	AdminDashboard,
	Rating,
	AppConfig,
	Gallery,
	Role,
	EmployeeType,
	PaymentMethod,
	Currency,
	Theme,
	Today,
} from '../sagas';

export default function* watch() {
	yield all([
		User.UserWatcher(),
		Service.ServiceWatcher(),
		Category.CategoryWatcher(),
		Authentication.AuthenticateWatcher(),
		Employee.EmployeeWatcher(),
		Branch.BranchWatcher(),
		Booking.BookingWatcher(),
		Customer.CustomerWatcher(),
		Shop.ShopWatcher(),
		Search.SearchWatcher(),
		CheckOut.CheckOutWatcher(),
		Holiday.HolidayWatcher(),
		AdminDashboard.AdminDashboardWatcher(),
		Rating.RatingWatcher(),
		AppConfig.AppConfigWatcher(),
		Gallery.GalleryWatcher(),
		Role.RoleWatcher(),
		EmployeeType.EmployeeTypeWatcher(),
		PaymentMethod.CategoryWatcher(),
		Currency.CurrencyWatcher(),
		Theme.ThemeWatcher(),
		Today.TodayWatcher(),
	]);
}
