import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from './SortableItem';
import React, { ReactNode } from 'react';

interface ISortableList<T> {
	onDragEnd: (event: DragEndEvent) => void;
	renderItem: (item: T, index: number) => ReactNode;
	data: T[];
}

export const SortableList = <T extends { id: string }>(
	props: ISortableList<T>
) => {
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 1,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);
	return (
		<div style={{ border: '1px solid #d9d9d9', borderRadius: 10 }}>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={props.onDragEnd}
			>
				<SortableContext
					items={props.data}
					strategy={verticalListSortingStrategy}
				>
					{props.data.map((item, index) => (
						<SortableItem key={item.id} id={item.id}>
							{props.renderItem(item, index)}
						</SortableItem>
					))}
				</SortableContext>
			</DndContext>
		</div>
	);
};
