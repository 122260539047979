import React from 'react';
import clsxm from 'clsxs/clsxm';
import { Card } from 'components/atoms';
import { CurrencyHelper } from 'helpers';
import { Pie } from 'react-chartjs-2';
import { t, translations } from 'utils';
import { useReportContext } from './context';
import { ReportFieldValue } from './ReportFieldValue';
import { SkeletonChartLoading, SkeletonLoading } from './SkeletonLoading';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export const IncomeReport = () => {
	const { report, loading } = useReportContext();
	return (
		<div className={clsxm('h-fit')}>
			<Card title={t(translations.ownerFinancialSummaryReport.payment)}>
				{loading ? (
					<SkeletonLoading />
				) : (
					<>
						<ReportFieldValue
							title={t(translations.ownerFinancialSummaryReport.gross)}
							value={CurrencyHelper.formatPrice(report?.revenue)}
							bold
							big
						/>
						{report?.incomeBreakdown.map((element, index) => {
							return (
								<ReportFieldValue
									title={INCOME_BREAKDOWN_TITLE[index]}
									value={CurrencyHelper.formatPrice(element.amount)}
									bold
								/>
							);
						})}
					</>
				)}
				<IncomeChart />
			</Card>
		</div>
	);
};

const IncomeChart = () => {
	const { report, loading } = useReportContext();
	if (loading) {
		return <SkeletonChartLoading />;
	}
	return (
		<div className="w-full flex justify-center">
			<div className="h-50 w-50">
				<Pie
					data={{
						labels: INCOME_BREAKDOWN_TITLE,
						datasets: [
							{
								data: report?.incomeBreakdown.map((income) => income.amount),
								backgroundColor: [
									'#3347ff80',
									'#d6000080',
									'#4dff0080',
									'#ff00fb80',
									'#7b00ff80',
									'#ffaa0080',
								],
							},
						],
					}}
				/>
			</div>
		</div>
	);
};

export const INCOME_BREAKDOWN_TITLE = [
	t(translations.ownerFinancialSummaryReport.cash),
	t(translations.ownerFinancialSummaryReport.giftCard),
	t(translations.ownerFinancialSummaryReport.card),
	t(translations.ownerFinancialSummaryReport.check),
	t(translations.ownerFinancialSummaryReport.payByApp),
	t(translations.ownerFinancialSummaryReport.wallet),
	t(translations.ownerFinancialSummaryReport.other),
];
