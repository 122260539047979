import {
	AlertHelper,
	BookingHelper,
	checkIsMobile,
	CurrencyHelper,
	TimeHelper,
} from 'helpers';
import { delay, find, isEmpty, sumBy } from 'lodash';
import {
	EBookingInputFrom,
	IApiResponse,
	IBooking,
	IErrorResponse,
	IService,
} from 'models';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BranchActions, CustomerBookingActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import BookingApiService from 'services/BookingApiService';
import qs from 'qs';
import { useAppSelector } from 'helpers/hookHelpers';
import chroma from 'chroma-js';
import { Icon } from 'components/atoms/Icon';
import { mdiArrowLeft } from '@mdi/js';
import { Button } from 'components/atoms/Button';
import { SelectBranch } from './components/SelectBranch';
import { SelectService } from './components/SelectServices';
import clsxm from 'clsxs/clsxm';
import { Const, I18n, translations, _t } from 'utils';
import SelectStaff from './components/SelectStaff';
import { SelectStaffCalendar } from './components/SelectStaffCalendar';
import { CustomerInfo } from './components/CustomerInfo';
import { BookingSuccess } from './components/BookingSuccess';
import _ from 'lodash';
import { mdiDelete, mdiPhone } from '@mdi/js';
import { BookingDetailModal } from 'pages/CustomerBooking/components/BookingDetailModal';
import { LoadingProvider } from 'contexts/LoadingContext';
import { Card } from 'components/atoms';

interface IConfirm {
	onConfirm: () => void;
	minutes?: number;
	seconds?: number;
}

const useConfirmBooking = () => {
	const [loading, setLoading] = useState(false);
	const [newBooking, setNewBooking] = useState<IBooking | undefined>(undefined);
	const bookingId = useGetBookingId();
	const history = useHistory();
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const customer = useSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	const note = useSelector(
		(state: RootState) => state.CustomerBookingReducer.note
	);
	const shopId = useSelector((state: RootState) => state.ShopReducer.shop?.id);
	const branchId = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.id
	);
	const employees = useAppSelector(
		(state) => state.EmployeeReducer.employees || []
	);

	const confirmBooking = async () => {
		setLoading(true);
		const totalDuration = sumBy(booking?.bookingDetails, (bookingDetail) => {
			const employee = employees.find((x) => x.id === bookingDetail.stylistId);
			let duration = bookingDetail.item?.duration!;
			if (employee) {
				const serviceDuration = employee.serviceDurations?.find(
					(x) => x.serviceId === bookingDetail.itemId
				);
				if (serviceDuration && serviceDuration.duration !== 0) {
					duration = serviceDuration.duration;
				}
			}
			return duration;
		});
		const endTimeExpected = moment(booking?.startTimeExpected).add(
			totalDuration,
			'minute'
		);
		const convertedBookingDetails = BookingHelper.convertBookingDetails(
			booking?.bookingDetails!,
			moment.utc(booking?.startTimeExpected),
			employees
		);
		const bookingRequest: Partial<IBooking> = {
			date: booking?.date,
			customerId: customer?.id,
			startTimeExpected: booking?.startTimeExpected,
			endTimeExpected: endTimeExpected.toDate(),
			inputFrom: EBookingInputFrom.OnlineSite,
			stylistId:
				booking?.bookingDetails![0].id !== 'Anyone'
					? booking?.bookingDetails![0].id
					: undefined,
			shopId: shopId,
			branchId: branchId,
			note: note,
			discount: 0,
			bookingDetails: convertedBookingDetails,
			// totalAmount: sumBy(
			// 	convertedBookingDetails,
			// 	(bookingDetail) => bookingDetail.amount!
			// ),
			// realAmount: sumBy(
			// 	convertedBookingDetails,
			// 	(bookingDetail) => bookingDetail.amount! - (bookingDetail.discount || 0)
			// ),                                                                                // BE tinh
			totalQuantity: convertedBookingDetails.length,
			couponCodes: booking?.couponCodes,
			promotionIds: booking?.promotionIds,
		};
		const bookingUpdateRequest: Partial<IBooking> = {
			...bookingRequest,
			stylistId: booking?.stylistId,
			id: bookingId,
		};
		const bookingResponse = (
			_.isEmpty(bookingId)
				? await BookingApiService.createBooking(bookingRequest)
				: await BookingApiService.updatePendingBooking(bookingUpdateRequest)
		) as IApiResponse<IBooking>;
		setLoading(false);

		if (bookingResponse.succeeded && !isEmpty(bookingResponse.data)) {
			setNewBooking(bookingResponse.data);
		} else {
			const error = bookingResponse as IErrorResponse;
			AlertHelper.showError(error);
			if (
				error.message &&
				error.message[0].Code === 'TheTechnicianHasBeenBooked'
			) {
				history.goBack();
			}
		}
	};
	return {
		confirmBooking,
		loading,
		newBooking,
	};
};
const useCheckMultipleBranches = () => {
	const branches = useSelector(
		(state: RootState) => state.BranchReducer.branches
	);
	const bookingId = useGetBookingId();
	return branches.length > 1 && _.isEmpty(bookingId);
};

const getMainByStep = (step: number, isMultiple = false) => {
	if (isMultiple) {
		switch (step) {
			case 1:
				return <SelectBranch />;
			case 2:
				return <SelectService />;
			case 3:
				return <SelectStaff />;
			case 4:
				return <SelectStaffCalendar />;
			case 5:
				return <CustomerInfo />;
			default:
				break;
		}
	} else {
		switch (step) {
			case 1:
				return <SelectService />;
			case 2:
				return <SelectStaff />;
			case 3:
				return <SelectStaffCalendar />;
			case 4:
				return <CustomerInfo />;
			default:
				break;
		}
	}
};
const useGetStepName = (step: number, isMultipleBranches = false) => {
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const stylists = useSelector(
		(state: RootState) => state.CustomerBookingReducer.stylists
	);
	const customer = useSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	if (isMultipleBranches) {
		switch (step) {
			case 1:
				return 'Select Branch';
			case 2:
				return 'Select Services';
			case 3:
				return 'Select Staff';
			case 4:
				const stylistName =
					booking?.bookingDetails![0].stylistId === 'Anyone'
						? ''
						: `${
								find(
									stylists,
									(x) => x.id === booking?.bookingDetails![0].stylistId
								)?.firstName
						  } ${
								find(
									stylists,
									(x) => x.id === booking?.bookingDetails![0].stylistId
								)?.lastName
						  }`;
				return isEmpty(stylistName)
					? 'Select Time'
					: `Select Time with ${stylistName}`;
			case 5:
				const customerName = `${customer?.firstName} ${customer?.lastName}`;
				return !isEmpty(customer)
					? `Welcome, ${customerName}`
					: 'Customer Info';
			default:
				break;
		}
	} else {
		switch (step) {
			case 1:
				return 'Select Services';
			case 2:
				return 'Select Staff';
			case 3:
				const stylistName =
					booking?.bookingDetails![0].stylistId === 'Anyone'
						? ''
						: `${
								find(
									stylists,
									(x) => x.id === booking?.bookingDetails![0].stylistId
								)?.firstName
						  } ${
								find(
									stylists,
									(x) => x.id === booking?.bookingDetails![0].stylistId
								)?.lastName
						  }`;
				return isEmpty(stylistName)
					? 'Select Time'
					: `Select Time with ${stylistName}`;
			case 4:
				const customerName = `${customer?.firstName} ${customer?.lastName}`;
				return !isEmpty(customer)
					? `Welcome, ${customerName}`
					: 'Customer Info';
			default:
				break;
		}
	}
};
const useCheckValidStep = () => {
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const branches = useSelector(
		(state: RootState) => state.BranchReducer.branches
	);
	const currentBranch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const isMultipleBranches = useCheckMultipleBranches();
	const step = useGetStep();
	if (isMultipleBranches) {
		if (branches && isEmpty(currentBranch) && step !== 1) {
			window.location.href = '/customer-booking';
		}
	}
	if (isEmpty(booking?.bookingDetails) && step > (isMultipleBranches ? 2 : 1)) {
		window.location.href = isMultipleBranches
			? '/customer-booking?step=2'
			: '/customer-booking';
		return false;
	}
	return true;
};
const useGetStep = () => {
	const history = useHistory();
	const location = history.location;
	const params = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	});
	let step = 1;
	if (params.step) {
		step = Number.parseInt(params.step as string);
	}
	return step;
};
const useGetBookingId = () => {
	const history = useHistory();
	const path = history.location.pathname;
	const bookingId = path.split('/')[2];
	return bookingId as string;
};
const useCheckCanNext = () => {
	const step = useGetStep();
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const customer = useSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	const isRequireStylist = useSelector(
		(state: RootState) =>
			state.BranchReducer.currentBranch?.technicianSelectionWhenBookingOnline
	);
	const stylists = useSelector(
		(state: RootState) => state.CustomerBookingReducer.stylists
	)!;
	let listStylistAvailable: any = [];
	const isMultiple = useCheckMultipleBranches();

	booking?.bookingDetails?.forEach((x) => {
		const stylistsCanDoService = stylists.filter((stylist) =>
			stylist.serviceIds?.includes(x.item?.id || '')
		);
		listStylistAvailable.push(stylistsCanDoService);
	});
	const isEmptyStylist = listStylistAvailable.map((x: any) => {
		if (isEmpty(x)) {
			return true;
		}
		return false;
	});
	if (isMultiple) {
		switch (step) {
			case 2:
				if (isEmpty(booking?.bookingDetails)) {
					return false;
				}
				break;
			case 3:
				if (
					(!isRequireStylist &&
						!isEmpty(
							booking?.bookingDetails?.filter((x) => isEmpty(x.stylistId))
						)) ||
					(isRequireStylist &&
						isEmptyStylist.includes(false) &&
						isEmpty(
							booking?.bookingDetails?.filter((x) => !isEmpty(x.stylistId))
						))
				) {
					return false;
				}

				break;
			case 4:
				if (isEmpty(booking?.startTimeExpected)) {
					return false;
				}
				break;
			case 5:
				if (isEmpty(customer)) {
					return false;
				}
				break;
			default:
				break;
		}
	} else {
		switch (step) {
			case 1:
				if (isEmpty(booking?.bookingDetails)) {
					return false;
				}
				break;
			case 2:
				if (
					(!isRequireStylist &&
						!isEmpty(
							booking?.bookingDetails?.filter((x) => isEmpty(x.stylistId))
						)) ||
					(isRequireStylist &&
						isEmptyStylist.includes(false) &&
						isEmpty(
							booking?.bookingDetails?.filter((x) => !isEmpty(x.stylistId))
						))
				) {
					return false;
				}

				break;
			case 3:
				if (isEmpty(booking?.startTimeExpected)) {
					return false;
				}
				break;
			case 4:
				if (isEmpty(customer)) {
					return false;
				}
				break;
			default:
				break;
		}
	}

	return true;
};
const NextButton = (props: IConfirm) => {
	const history = useHistory();
	const step = useGetStep();
	const canNext = useCheckCanNext();
	const isMultipleBranches = useCheckMultipleBranches();
	const bookingId = useGetBookingId();
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const booking = useAppSelector(
		(state) => state.CustomerBookingReducer.booking
	);
	const dispatch = useDispatch();
	const onNext = () => {
		const pathname = !_.isEmpty(bookingId)
			? `/customer-booking/${bookingId}`
			: `/customer-booking`;
		if (step === (isMultipleBranches ? 5 : 4)) {
			props.onConfirm();
		} else {
			if (
				!branch?.displayEmployeesOnPortal &&
				step === (isMultipleBranches ? 2 : 1)
			) {
				dispatch(
					CustomerBookingActions.updateBooking.request({
						...booking,
						bookingDetails: booking?.bookingDetails?.map((x) => {
							return { ...x, stylistId: 'Anyone' };
						}),
					})
				);
				delay(() => {
					history.push({
						pathname: pathname,
						search: `?step=${step + 2}`,
					});
				}, 100);

				return;
			}
			history.push({
				pathname: pathname,
				search: `?step=${step + 1}`,
			});
		}
	};
	const getTimer = (minutes: number, seconds: number) => {
		const timer = `(${minutes}:${seconds < 10 ? `0${seconds}` : seconds})`;
		if (step >= (isMultipleBranches ? 3 : 2)) {
			if (minutes <= 0 && seconds <= 0) {
				return '';
			} else {
				return timer;
			}
		}
		return '';
	};

	return (
		<Button
			onClick={(e) => {
				e.stopPropagation();
				onNext();
			}}
			primary
			disabled={!canNext}
			className={clsxm(
				'customer-booking-background border-0',
				!canNext && 'opacity-40'
			)}
			small
		>
			{`${
				step === (isMultipleBranches ? 5 : 4) ? 'Confirm' : 'Next'
			} ${getTimer(props.minutes!, props.seconds!)}`}
		</Button>
	);
};

const CustomerHeader = () => {
	const history = useHistory();
	const step = useGetStep();
	const isMultipleBranches = useCheckMultipleBranches();
	const stepName = useGetStepName(step, isMultipleBranches);
	const dispatch = useDispatch();
	const booking = useAppSelector(
		(state) => state.CustomerBookingReducer.booking
	);
	const onGoBack = () => {
		if (step === (isMultipleBranches ? 3 : 2)) {
			dispatch(
				CustomerBookingActions.updateBooking.request({
					startTimeExpected: undefined,
					date: undefined,
				})
			);
		}
		if (step === (isMultipleBranches ? 5 : 4)) {
			dispatch(
				CustomerBookingActions.updateBooking.request({
					couponCodes: '',
					bookingDetails: booking!.bookingDetails!.map((element) => ({
						...element,
						promotionDiscount: 0,
					})),
				})
			);
		}
		if (isMultipleBranches && step === 2) {
			history.push({
				pathname: `/customer-booking`,
			});
			return;
		}

		history.goBack();
	};
	return (
		<>
			<div className="w-full h-[100px] flex py-4 sticky customer-booking-background text-white z-50 top-0">
				<div className="flex justify-end w-[15%] pr-3">
					{step !== 1 && (
						<div className="cursor-pointer" onClick={() => onGoBack()}>
							<Icon path={mdiArrowLeft} />
						</div>
					)}
				</div>
				<div className="w-[90%] lg:w-[70%] flex flex-col">
					<span>{`Step ${step} of ${isMultipleBranches ? 5 : 4}`}</span>
					<h3 className="m-0 text-white">{stepName}</h3>
				</div>
			</div>
			<div className="h-0 md:h-[20px] customer-booking-background z-0 sticky top-[100px]"></div>
		</>
	);
};

const WebFooter = (props: IConfirm) => {
	return (
		<div className="fixed bg-white w-full bottom-0 h-[80px] z-[100]  border-t-2 border-base-200">
			<div className="flex w-[90%] lg:w-[70%] items-center justify-end h-full mx-auto">
				<div>
					<NextButton
						onConfirm={() => props.onConfirm()}
						minutes={props.minutes}
						seconds={props.seconds}
					/>
				</div>
			</div>
		</div>
	);
};
const MobileFooter = (props: IConfirm) => {
	const [showModal, setShowModal] = useState(false);
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const dispatch = useDispatch();
	const newBookingDetails = Array.from(booking?.bookingDetails || []);
	const removeService = (service: IService) => {
		_.remove(
			newBookingDetails,
			(bookingDetail) => bookingDetail.itemId === service.id
		);
		dispatch(
			CustomerBookingActions.updateBooking.request({
				...booking,
				bookingDetails: newBookingDetails,
			})
		);
	};
	const tax = _.sumBy(
		booking?.bookingDetails,
		(bookingDetail) =>
			(((bookingDetail.item?.price || 0) +
				(bookingDetail.extraAmount || 0) -
				(branch?.discountBeforeTax ? bookingDetail.discount || 0 : 0) -
				(branch?.discountBeforeTax
					? bookingDetail.promotionDiscount || 0
					: 0)) *
				(branch?.taxPercent || 0)) /
			100
	);
	const isMultipleBranches = useCheckMultipleBranches();

	let shopName = `${shop?.name}`;
	if (isMultipleBranches) {
		shopName += ` - ${branch?.name}`;
	}
	return (
		<>
			<div
				className="fixed bg-white w-full bottom-0 z-50 rounded-t-lg border-t-2 border-base-200"
				onClick={() => {
					console.log('ssets');

					setShowModal(true);
				}}
			>
				<div className="p-4 flex flex-col divide-y divide-background">
					<div className="py-2 flex items-center justify-center">
						<div className="basis-3/4 flex flex-col">
							<span className="font-semibold text-black/80">{`${shopName}`}</span>
							<span className="text-black/40 text-sm">{`Hotline: ${branch?.phone}`}</span>
							<span className="text-black/40 text-sm">{`${branch?.address?.street}, ${branch?.address?.city}, ${branch?.address?.state}`}</span>
						</div>
						<div className="basis-1/4 flex justify-center">
							<img
								src={!isEmpty(shop?.logoUrl) ? shop?.logoUrl : '/app_logo.png'}
								alt="shop-logo"
								className="w-[40px] h-[40px] m-0 rounded-md self-center"
							/>
						</div>
					</div>
					<BookingTimeInfo />
					{!isEmpty(booking?.bookingDetails) ? (
						<div className="py-2">
							<div className="flex">
								<div className="flex flex-col basis-7/12">
									<span className=" text-black/40 font-semibold">{`${booking?.bookingDetails?.length} Services`}</span>
									{branch?.showServicePriceWhenBookingOnline && (
										<h5 className="mt-0 font-semibold text-black">
											{CurrencyHelper.formatPrice(
												_.sumBy(
													booking?.bookingDetails,
													(x) =>
														x.item?.price! -
														(x.promotionDiscount || 0) -
														(x.discount || 0)
												) + tax
											)}
										</h5>
									)}
								</div>
								<div className="basis-5/12 flex flex-col justify-end">
									<NextButton
										onConfirm={() => props.onConfirm()}
										minutes={props.minutes}
										seconds={props.seconds}
									/>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<BookingDetailModal
				visible={showModal}
				onCancel={() => setShowModal(false)}
			>
				{booking?.bookingDetails?.map((bookingDetail, index) => {
					const discount =
						(bookingDetail.item?.price || 0) -
						(bookingDetail.discount || 0) -
						(bookingDetail.promotionDiscount || 0);
					return (
						<div key={index}>
							<div className="flex flex-row w-full px-4 text-sm">
								<div className="basis-4/6 flex flex-col">
									<span className="text-black">{bookingDetail.item?.name}</span>
									{branch?.displayDurationOnPortal && (
										<span className="text-black/40">
											{TimeHelper.convertDurationToStringDetail(
												bookingDetail.item?.duration || 0
											)}
										</span>
									)}
								</div>
								{branch?.showServicePriceWhenBookingOnline && (
									<div className="flex flex-col justify-end basis-1/6">
										{discount < bookingDetail.item?.price! && (
											<span className="text-black">
												{CurrencyHelper.formatPrice(discount)}
											</span>
										)}
										<span
											className={clsxm(
												discount < bookingDetail.item?.price! &&
													'text-sm line-through',
												'text-black/40'
											)}
										>
											{CurrencyHelper.formatPrice(bookingDetail.item?.price)}
										</span>
									</div>
								)}
								<div
									className="flex basis-1/6 items-end justify-center cursor-pointer"
									onClick={() => {
										removeService(bookingDetail.item!);
									}}
								>
									<Icon path={mdiDelete} color="red" size={0.9} />
								</div>
							</div>
							<div className="divider m-0" />
						</div>
					);
				})}
			</BookingDetailModal>
		</>
	);
};
const BookingTimeInfo = () => {
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);

	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	if (!booking?.startTimeExpected) {
		return null;
	}
	const time = TimeHelper.toTimeZone(booking.startTimeExpected);
	const duration = sumBy(booking.bookingDetails, (x) => x.item?.duration!);
	const endTimeExpected = time.add(duration, 'minute');
	return (
		<div className="flex flex-col w-full md:px-4 py-4">
			<span className="font-semibold text-black">{`${time.format(
				'D MMM YYYY'
			)} at ${TimeHelper.toTimeZone(booking.startTimeExpected).format(
				'hh:mma'
			)} `}</span>
			{branch?.displayDurationOnPortal && (
				<span className="text-black/40 text-sm">{`${TimeHelper.convertDurationToStringDetail(
					duration
				)} duration, ends at ${endTimeExpected.format('hh:mma')} `}</span>
			)}
		</div>
	);
};

const WebBillInfo = () => {
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const dispatch = useDispatch();
	const newBookingDetails = Array.from(booking?.bookingDetails || []);
	const removeService = (service: IService) => {
		_.remove(
			newBookingDetails,
			(bookingDetail) => bookingDetail.itemId === service.id
		);
		dispatch(
			CustomerBookingActions.updateBooking.request({
				...booking,
				bookingDetails: newBookingDetails,
			})
		);
	};
	const isMultipleBranches = useCheckMultipleBranches();

	let shopName = `${shop?.name}`;
	if (isMultipleBranches) {
		shopName += ` - ${branch?.name}`;
	}
	const tax = sumBy(
		booking?.bookingDetails,
		(bookingDetail) =>
			(((bookingDetail.item?.price || 0) +
				(bookingDetail.extraAmount || 0) -
				(branch?.discountBeforeTax ? bookingDetail.discount || 0 : 0) -
				(branch?.discountBeforeTax
					? bookingDetail.promotionDiscount || 0
					: 0)) *
				(branch?.taxPercent || 0)) /
			100
	);
	return (
		<div className="sticky top-[100px] bg-white rounded-md">
			<div className="flex justify-center mb-4">
				<div className="avatar -mt-12 mx-auto">
					<div className="w-24 rounded-lg">
						<img
							className="m-0"
							src={!isEmpty(shop?.logoUrl) ? shop?.logoUrl : '/app_logo.png'}
							alt="shop_logo"
						/>
					</div>
				</div>
			</div>
			<div className="flex flex-col divide-y divide-background">
				{branch && (
					<div className="text-center pb-4">
						<h5 className="font-semibold text-black">{`${shopName}`}</h5>
						<h5 className="text-sm text-black/40">{`Hotline: ${
							branch?.phone || ''
						}`}</h5>
						<span className="text-black/40 text-sm">{`${
							branch?.address?.street || ''
						}, ${branch?.address?.city || ''}, ${
							branch?.address?.state || ''
						}`}</span>
					</div>
				)}
				<BookingTimeInfo />
				{!isEmpty(booking?.bookingDetails) ? (
					<div className="flex flex-col divide-y divide-background">
						<div className="py-4">
							{booking?.bookingDetails?.map((bookingDetail, index) => {
								const discount =
									(bookingDetail.item?.price || 0) -
									(bookingDetail.discount || 0) -
									(bookingDetail.promotionDiscount || 0);
								return (
									<div
										className="flex flex-row w-full px-4 text-sm"
										key={index}
									>
										<div className="basis-3/4 flex flex-col">
											<span className="text-black">
												{bookingDetail.item?.name}
											</span>
											{branch?.displayDurationOnPortal && (
												<span className="text-black/40">
													{TimeHelper.convertDurationToStringDetail(
														bookingDetail.item?.duration || 0
													)}
												</span>
											)}
										</div>
										{branch?.showServicePriceWhenBookingOnline && (
											<div className="flex flex-col justify-end basis-1/6">
												{discount < bookingDetail.item?.price! && (
													<span className="text-black">
														{CurrencyHelper.formatPrice(discount)}
													</span>
												)}
												<span
													className={clsxm(
														discount < bookingDetail.item?.price! &&
															'text-sm line-through',
														'text-black/40'
													)}
												>
													{CurrencyHelper.formatPrice(
														bookingDetail.item?.price
													)}
												</span>
											</div>
										)}
										<div
											className="flex basis-1/8 items-end cursor-pointer"
											onClick={() => {
												removeService(bookingDetail.item!);
											}}
										>
											<Icon path={mdiDelete} color="red" />
										</div>
									</div>
								);
							})}
						</div>
						<div className="flex flex-col py-4">
							{!isEmpty(booking?.couponCodes) && (
								<div className="flex flex-row px-4 w-full font-semibold">
									<div className="basis-3/4">
										<h5 className="text-black/40">
											{I18n.t(_t(translations.customerBooking.couponCode))}
										</h5>
									</div>
									<div className="basis-1/4 flex flex-row justify-end">
										<h5 className="text-black/40">{booking?.couponCodes}</h5>
									</div>
								</div>
							)}
							{branch?.showServicePriceWhenBookingOnline && (
								<div className="flex flex-row px-4 w-full font-semibold">
									<div className="basis-3/4">
										<h5 className="text-black/40">Taxes</h5>
									</div>
									<div className="basis-1/4 flex flex-row justify-end">
										<h5 className="text-black/40">
											{CurrencyHelper.formatPrice(tax)}
										</h5>
									</div>
								</div>
							)}
							{branch?.showServicePriceWhenBookingOnline && (
								<div className="flex flex-row px-4 w-full text-black font-semibold">
									<div className="basis-3/4">
										<h5>Total</h5>
									</div>
									<div className="basis-1/4 flex flex-row justify-end">
										<h5>
											{CurrencyHelper.formatPrice(
												sumBy(
													booking?.bookingDetails,
													(x) =>
														x.item?.price! -
														(x.promotionDiscount || 0) -
														(x.discount || 0)
												) + tax
											)}
										</h5>
									</div>
								</div>
							)}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

const Layout = (props: IConfirm) => {
	const step = useGetStep();
	const isMultipleBranches = useCheckMultipleBranches();
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const history = useHistory();
	const [minutes, setMinutes] = useState(15);
	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (step >= (isMultipleBranches ? 3 : 2)) {
				if (seconds > 0) {
					setSeconds(seconds - 1);
				}
				if (seconds <= 0) {
					if (minutes <= 0) {
						clearInterval(myInterval);
					} else {
						setMinutes(minutes - 1);
						setSeconds(59);
					}
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	});

	useEffect(() => {
		const timer = setTimeout(() => {
			if (
				minutes <= 0 &&
				seconds <= 0 &&
				step >= (isMultipleBranches ? 3 : 2)
			) {
				AlertHelper.showAlert('Overtime to choose stylist.', 'warning');

				history.replace(`/customer-booking?step=${isMultipleBranches ? 2 : 1}`);
				// dispatch(
				// 	CustomerBookingActions.updateBooking.request({
				// 		...booking,
				// 		stylistId: undefined,
				// 		stylist: undefined,
				// 		bookingDetails: booking.bookingDetails?.map((x) => {
				// 			return { ...x, stylistId: undefined };
				// 		}),
				// 	})
				// );
			}
		}, 10);
		return () => clearTimeout(timer);
	}, [minutes, seconds, step]);

	useEffect(() => {
		if (step < (isMultipleBranches ? 3 : 2)) {
			setMinutes(15);
			setSeconds(0);
		}
	}, [step]);

	const getMainControl = useMemo(() => {
		return getMainByStep(step, isMultipleBranches);
	}, [step, isMultipleBranches]);

	return (
		<div className="min-h-screen bg-background">
			<CustomerHeader />

			<div className="h-full flex flex-row gap-4 md:w-[90%] lg:w-[70%] md:mx-auto">
				<div className="basis-full max-w-[100%] p-2 z-10 md:basis-2/3 md:p-0 md:mt-[-20px] md:max-w-[66%]">
					{/* {getMainByStep(step, isMultipleBranches)} */}
					{<div>{getMainControl}</div>}
				</div>
				<div className="hidden z-[100] md:block md:basis-1/3 md:mt-[-20px]">
					{step > (isMultipleBranches ? 1 : 0) && <WebBillInfo />}
				</div>
			</div>
			{step > (isMultipleBranches ? 1 : 0) && (
				<a
					href={`tel:${branch?.phone}`}
					className={clsxm(
						'bg-[#00d40b] fixed rounded-full call-button-animation',
						checkIsMobile()
							? 'top-6 right-4 z-[10000] p-2'
							: 'bottom-28 right-8 p-4'
					)}
				>
					<Icon
						path={mdiPhone}
						color="white"
						size={checkIsMobile() ? 1 : 1.2}
					/>
				</a>
			)}
			{!checkIsMobile() && (
				<WebFooter
					onConfirm={() => props.onConfirm()}
					minutes={minutes}
					seconds={seconds}
				/>
			)}
			{checkIsMobile() && step > (isMultipleBranches ? 1 : 0) && (
				<MobileFooter
					onConfirm={() => props.onConfirm()}
					minutes={minutes}
					seconds={seconds}
				/>
			)}
		</div>
	);
};

const DisabledOnlineBooking = () => {
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);

	return (
		<div className="p-4">
			<Card>
				<h5 className="text-error font-bold">
					You has been disabled for Online booking
				</h5>
				<p>
					Please contact <strong>{branch?.phone || shop?.phone}</strong> to
					re-enable online booking or to booking new an appointment{' '}
				</p>
			</Card>{' '}
		</div>
	);
};

const CustomerBooking = () => {
	const dispatch = useDispatch();
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branches = useSelector(
		(state: RootState) => state.BranchReducer.branches
	);

	const customer = useSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	const bookingId = useGetBookingId();
	const getBooking = async (bookingId: string) => {
		const bookingResponse = await BookingApiService.getBookingById(bookingId);
		if (bookingResponse.succeeded && bookingResponse.data) {
			const booking = bookingResponse.data;
			const branch = _.find(branches, (x) => x.id === booking?.branchId);
			if (branch) {
				dispatch(BranchActions.selectBranch.request(branch));
			}
			dispatch(CustomerBookingActions.updateCustomer.request(booking.customer));
			dispatch(CustomerBookingActions.updateBooking.request(booking));
		}
	};
	useEffect(() => {
		dispatch(BranchActions.getBranchesByShopId.request(shop?.id!));
	}, []);
	useEffect(() => {
		if (bookingId && !_.isEmpty(branches)) {
			getBooking(bookingId);
		}
	}, [bookingId, branches]);
	useEffect(() => {
		if (!isEmpty(shop)) {
			document.documentElement.style.setProperty(
				'--base-gradient-color',
				`${chroma(shop?.themeShopConfig?.defaultColor || 'black')}`
			);
			document.documentElement.style.setProperty(
				'--second-gradient-color',
				`${chroma(shop?.themeShopConfig?.defaultColor || 'black').darken(1.4)}`
			);
		}
	}, [shop]);

	const validStep = useCheckValidStep();
	const createBooking = useConfirmBooking();

	if (!validStep) {
		return null;
	}
	if (createBooking.newBooking) {
		return <BookingSuccess booking={createBooking.newBooking} />;
	}

	return (
		<LoadingProvider loading={createBooking.loading}>
			{customer && customer.disableBookingOnline === true ? (
				<DisabledOnlineBooking />
			) : (
				<Layout onConfirm={() => createBooking.confirmBooking()} />
			)}
		</LoadingProvider>
	);
};

export default CustomerBooking;
