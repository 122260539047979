import clsxm from 'clsxs/clsxm';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { useAppSelector } from 'helpers/hookHelpers';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	title?: string;
	loading?: boolean;
	iconBefore?: ReactNode;
	iconAfter?: ReactNode;
	primary?: boolean;
	className?: string;
	fullWidth?: boolean;
	small?: boolean;
	ghost?: boolean;
	white?: boolean;
	col?: boolean;
	labelSpacing?: boolean;
	track?: boolean;
}

export const Button = (props: IButtonProps) => {
	const shopName = useAppSelector((state) => state.ShopReducer.shop?.adminUrl);
	const onClick = (e: any) => {
		// Sentry.captureEvent({
		// 	message: `Button_${props.title}`,
		// 	level: 'info',
		// 	environment: `${process.env.NODE_ENV}`,
		// });

		if (props.track) {
			Sentry.captureMessage(`Button_${props.title}`, {
				level: 'info',
				tags: {
					shopName,
				},
			});
		}
		if (props.onClick) {
			props.onClick(e);
		}
	};
	return (
		<>
			{props.labelSpacing && (
				<label className="label font-medium ">
					<span className="label-text text-md">ㅤ</span>
				</label>
			)}
			<button
				{...props}
				onClick={onClick}
				className={clsxm(
					'btn',
					'gap-0.5',
					'rounded-lg',
					'min-w-[100px]',
					'text-sm',
					'leading-tight',
					!props.small && 'btn-lg',
					props.fullWidth ? 'w-full' : undefined,
					props.loading ? 'loading' : undefined,
					props.primary ? 'main-gradient-background' : undefined,
					props.ghost && 'bg-transparent border-none',
					props.white && 'bg-white border-none text-black shadow-sm',
					props.col && 'flex-col text-center items-center',
					props.disabled && 'btn-disabled',
					props.className
				)}
			>
				{props.iconBefore && props.iconBefore}
				{props.title}
				{props.children && props.children}
				{props.iconAfter && props.iconAfter}
			</button>
		</>
	);
};
