import _ from 'lodash';
import {
	EBookingInputFrom,
	IBillDetail,
	IBillingDetail,
	IBooking,
	IBookingDetail,
	ICustomer,
	IEmployee,
	IService,
} from 'models';
import moment, { Moment } from 'moment';
import { history } from 'routers';
import { BookingStatus, PaymentType } from 'utils/Consts';
import QRCode from 'qrcode';
import { toTimeZone } from './timeHelper';
import { useDispatch } from 'react-redux';
import {
	IGetBookingsRequest,
	IStylistAndBlockTimeRequest,
} from 'models/RequestModels';
import { BookingActions } from 'redux/actions';
import { useAppSelector } from 'helpers/hookHelpers';
import { availableTimeBlock } from 'redux/selectors/booking';
import { TimeHelper } from 'helpers';
import { useState } from 'react';
import BookingApiService from 'services/BookingApiService';
import { showAlert } from 'helpers/alertHelper';
import I18n from 'i18n-js';
import { translations, _t } from 'utils';
import configureStore from 'redux/configuration/configureStore';
import { IBilling } from 'models/IBilling';
import BillingApiService from 'services/BillingApiService';

const getCurrentBranch = () => {
	const store = configureStore().store;
	return store.getState().BranchReducer.currentBranch;
};

export const calculateTotalTax = (
	amountBillDetails: number,
	discountAmount: number
): number => {
	const currentBranch = getCurrentBranch();

	const tax = currentBranch?.discountBeforeTax
		? ((amountBillDetails - discountAmount) * (currentBranch.taxPercent || 0)) /
		  100
		: (amountBillDetails * (currentBranch?.taxPercent || 0)) / 100;
	return tax;
};

export const calculateTotalAmountBillDetails = (
	billDetails: Partial<IBillingDetail>[]
) => {
	const currentBranch = getCurrentBranch();
	return _.sumBy(billDetails, (x) => {
		if (x.giftCardId) {
			return 0;
		}
		if (currentBranch?.discountBeforeTax) {
			const amount = (x.amount || 0) + (x.extraAmount || 0);
			return _.toNumber(amount.toFixed(2));
		} else {
			const amount = (x.amount || 0) + (x.extraAmount || 0);
			return _.toNumber(amount.toFixed(2));
		}
	});
};

export const getPaymentName = (paymentType: PaymentType, appName?: string) => {
	switch (paymentType) {
		case PaymentType.CASH:
			return I18n.t(_t(translations.checkout.cash));
		case PaymentType.GIFT_CARD:
			return I18n.t(_t(translations.checkout.giftCard));
		case PaymentType.CREDIT_CARD:
			return I18n.t(_t(translations.checkout.creditCard));
		case PaymentType.SEC:
			return I18n.t(_t(translations.checkout.check));
		case PaymentType.APP:
			return `App ${appName}`;
		case PaymentType.OTHER:
			return appName || '';
		default:
			return '';
	}
};

export const useBookingHook = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const timeBlocks = useAppSelector((state) => availableTimeBlock(state));
	const bookings = useAppSelector((state) => state.BookingReducer.bookings);
	const employees = useAppSelector((state) => state.EmployeeReducer.employees);
	const date = useAppSelector((state) => state.BookingReducer.date) || moment();
	const currentIndex = useAppSelector(
		(state) => state.BookingReducer.currentIndex
	);
	const availableStylists = useAppSelector(
		(state) => state.BookingReducer.availableStylist
	);
	const bookingDetails = useAppSelector(
		(state) => state.BookingReducer.bookingDetails
	);
	const timeActiveId = useAppSelector(
		(state) => state.BookingReducer.timeActiveId
	);
	const currentBooking = useAppSelector(
		(state) => state.BookingReducer.currentBooking
	);
	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const customer = useAppSelector(
		(state) => state.BookingReducer.selectedCustomer
	);
	const editBookingDetail = useAppSelector(
		(state) => state.BookingReducer.editBookingDetail
	);
	const editing = useAppSelector((state) => state.BookingReducer.editing);
	const resetIndex = () => dispatch(BookingActions.resetIndex.request());
	const deleteIndex = (index: number) =>
		dispatch(BookingActions.deleteIndex.request(index));
	const getBookingById = (id: string) =>
		dispatch(BookingActions.getBookingById.request(id));
	const getBookings = (request: IGetBookingsRequest) =>
		dispatch(BookingActions.getBookingsByBranch.request(request));
	const selectStartTime = (timeId: string) =>
		dispatch(BookingActions.selectTimeStart.request(timeId));
	const removeCustomer = () =>
		dispatch(BookingActions.removeCustomer.request());
	const onCheckIn = () =>
		dispatch(
			BookingActions.updateStatus.request({
				id: `${currentBooking?.id}`,
				status: BookingStatus.CHECKED_IN,
			})
		);
	const onWorking = () =>
		dispatch(
			BookingActions.updateStatus.request({
				id: `${currentBooking?.id}`,
				status: BookingStatus.PROCESSING,
			})
		);
	const changeBookingDetail = (
		bookingDetail: Partial<IBookingDetail>,
		index = 0
	) =>
		dispatch(
			BookingActions.changeBookingDetail.request({
				bookingDetail,
				index,
			})
		);
	const getAvailableStylistAndTimeBlocks = (
		stylistAndTimeBlockRequest?: Partial<IStylistAndBlockTimeRequest>,
		index = 0,
		noStylist = false,
		noTime = false
	) => {
		let time = timeActiveId;
		if (index !== 0 && timeActiveId) {
			const employee = _.find(
				employees || [],
				(x) => x.id === bookingDetails[index - 1].stylistId
			);
			let duration = bookingDetails[index - 1].item?.duration;
			if (employee) {
				const serviceDuration = _.find(
					employee.serviceDurations,
					(x) => x.serviceId === bookingDetails[index - 1].itemId
				);
				if (serviceDuration && serviceDuration.duration !== 0) {
					duration = serviceDuration.duration;
				}
			}
			const newTime = moment(timeActiveId, 'YYYY-MM-DDTHH:mm')
				.add(duration, 'minute')
				.format('YYYY-MM-DDTHH:mm');
			time = newTime;
		}
		const bookingDetail: IStylistAndBlockTimeRequest = {
			date: date.format('MM-DD-YYYY'),
			serviceId:
				bookingDetails[index] && !_.isEmpty(bookingDetails[index].itemId)
					? bookingDetails[index].itemId
					: bookingDetails[0].itemId,
			branchId: currentBranch?.id,
			shopId: currentBranch?.shopId,
			stylistId: noStylist
				? undefined
				: bookingDetails[0].stylistId || undefined,
			time: noTime ? undefined : time,
			bookingId: currentBooking?.id,
			...stylistAndTimeBlockRequest,
		};
		dispatch(
			BookingActions.getAvailableStylistAndTimeBlocks.request({
				bookingDetail,
				index,
			})
		);
	};
	const onSelectService = (service: IService) =>
		dispatch(BookingActions.selectService.request(service));
	const selectStylist = (stylist?: IEmployee) =>
		dispatch(BookingActions.selectStylist.request(stylist));
	const selectIndex = (index: number) =>
		dispatch(BookingActions.selectIndex.request(index));
	const selectCustomer = (customer: Partial<ICustomer>) =>
		dispatch(BookingActions.selectCustomer.request(customer));
	const changeDate = (date: Moment) =>
		dispatch(BookingActions.changeDate.request(date));
	const disabledEdit =
		currentBooking?.status === BookingStatus.CANCELED ||
		currentBooking?.status === BookingStatus.SUSPENDED;
	const createBooking = async (
		booking: Partial<IBooking>,
		onSuccess: (bookingId: string, booking?: IBooking) => void,
		onFailed?: () => void
	) => {
		setLoading(true);
		const response = await BookingApiService.createBooking(booking);
		setLoading(false);
		if (response.succeeded && response.data) {
			onSuccess(response.data?.id, response.data);
			dispatch(BookingActions.getBookingById.success(response.data));
		} else {
			onFailed && onFailed();
			showAlert(
				response.message[0].Text || I18n.t(_t(translations.text.criticalError)),
				'error'
			);
		}
	};
	const updateBooking = async (
		booking: Partial<IBooking>,
		onSuccess: () => void,
		onFailed?: () => void
	) => {
		setLoading(true);
		const newBooking: Partial<IBooking> = {
			...currentBooking,
			...booking,
			stylist: undefined,
			customer: undefined,
			inputFrom: EBookingInputFrom.POS,
		};
		delete newBooking.stylist;
		delete newBooking.customer;
		delete newBooking.totalAmount;
		delete newBooking.realAmount;
		delete newBooking.totalTax;

		const response = await BookingApiService.editBooking(newBooking);
		setLoading(false);
		if (response.succeeded && response.data) {
			onSuccess();
			dispatch(BookingActions.getBookingById.success(response.data));
		} else {
			onFailed && onFailed();
			showAlert(
				response.message[0].Text || I18n.t(_t(translations.text.criticalError)),
				'error'
			);
		}
	};
	const reset = () => dispatch(BookingActions.resetBooking.request());
	const setNote = (note: string) =>
		dispatch(BookingActions.setNote.request(note));
	return {
		getBookings,
		selectStartTime,
		changeBookingDetail,
		getAvailableStylistAndTimeBlocks,
		bookingDetails,
		customer,
		timeActiveId,
		currentBooking,
		timeBlocks,
		availableStylists,
		currentIndex,
		editBookingDetail,
		onSelectService,
		bookings,
		selectStylist,
		changeDate,
		resetIndex,
		selectIndex,
		onWorking,
		selectCustomer,
		deleteIndex,
		getBookingById,
		disabledEdit,
		editing,
		createBooking,
		loading,
		updateBooking,
		reset,
		onCheckIn,
		removeCustomer,
		date,
		setNote,
	};
};

export const calculateBookingDetailAmount = (
	bookingDetail: Partial<IBookingDetail>
) => {
	const price = bookingDetail.item?.price!;
	const discount = bookingDetail.discount!;
	const promotionDiscount = bookingDetail.promotionDiscount!;
	return _.toNumber(price - discount - promotionDiscount);
};
export const calculateBookingDetailTax = (
	bookingDetail: Partial<IBookingDetail>,
	taxPercent: number = 0
) => {
	const amount =
		(bookingDetail.item?.price || 0) + (bookingDetail.extraAmount || 0);
	const tax = (amount * taxPercent) / 100;
	return _.toNumber(tax);
};

// export const getInitialWorkingTime = (time: string) => {
// 	const splited = time.split('T');
// 	const spitedTime = splited[1];
// 	const date = splited[0];

// 	const niceTime = toTimeZone(`2021-01-01T${spitedTime}`);
// 	if (date.includes('2')) {
// 		niceTime.add(1, 'day');
// 	}
// 	return niceTime;
// };

export const convertDateRequest = (localDate: moment.Moment) => {
	return localDate.format('YYYY-MM-DDT00:00:00');
};

export const convertTimeRequest = (
	localDate: moment.Moment,
	nextDay = false
) => {
	return localDate
		.clone()
		.utc()
		.format(`YYYY-MM-DDTHH:mm:00`);
};

export const openBooking = async (booking: IBooking) => {
	// 	if (
	// 		// booking.status === BookingStatus.PROCESSING ||
	// 		// booking.status === BookingStatus.FINISHED
	// ) {
	// 		// history.push(`/checkout?bookingId=${booking.id}`);
	// 		history.push({
	// 			pathname: `/checkout`,
	// 			search: `bookingId=${booking.id}`,
	// 			state: { status: booking.status },
	// 		});
	// 	} else
	if (booking.status === BookingStatus.DONE) {
		const billResponse = await BillingApiService.getBillByBookingId(booking.id);
		if (billResponse.succeeded && billResponse.data && billResponse.data[0]) {
			history.push(`/bills/${billResponse.data[0].id}`);
		}
		// history.push({ pathname: '/billing', search: `bookingId=${booking.id}` });
	} else {
		const store = configureStore().store;
		store.dispatch(BookingActions.resetBooking.request());

		setTimeout(() => {
			history.push(`/booking/${booking.id}`);
		}, 200);
	}
};

export const createQrCodeBookingId = async (
	bookingId: string
): Promise<string> => {
	const bookingQRcode = {
		bookingId,
	};
	const qrCode = await QRCode.toDataURL(JSON.stringify(bookingQRcode), {});
	return qrCode;
};

export const isDateRange = (
	firstDate: Date | string,
	secondDate: Date | string
) => {
	return !moment(firstDate).isSame(moment(secondDate));
};
export const generateQrCode = (bookingId: string) => {
	const data = {
		booking: {
			id: bookingId,
		},
	};
	return JSON.stringify(data);
};

export const createBillFromBooking = (booking: IBooking) => {
	return {
		bookingId: booking?.id,
		date: booking?.date,
		customerId: booking?.customerId,
		branchId: booking?.branchId,
		shopId: booking?.shopId,
		billDetails: booking?.bookingDetails.map((x) => {
			return {
				bookingDetailId: x.id,
				serviceId: x.itemId,
				itemId: x.itemId,
				discount: x.discount,
				discounterType: x.discounterType,
				stylistId: x.stylistId,
				price: x.item?.price,
				add: x.added,
				quantity: 1,
			};
		}),
		promotionIds: booking?.promotionIds,
		couponCodes: booking?.couponCodes,
	};
};
export const createBillFromBookingDeep = (
	booking: IBooking
): Partial<IBilling> => {
	return {
		bookingId: booking?.id,
		date: booking?.date,
		customerId: booking?.customerId,
		customer: booking.customer,
		branchId: booking?.branchId,
		shopId: booking?.shopId,
		billDetails: booking?.bookingDetails.map((x) => {
			return {
				...x,
				quantity: 1,
				bookingDetailId: x.id,
			};
		}),
		promotionIds: booking?.promotionIds,
		couponCodes: booking?.couponCodes,
	};
};

export const getServiceDuration = (service: IService, employee?: IEmployee) => {
	let duration = service.duration;
	if (employee) {
		const serviceDuration = employee.serviceDurations?.find(
			(x) => x.serviceId === service.id
		);
		if (serviceDuration && serviceDuration.duration !== 0) {
			duration = serviceDuration.duration;
		}
	}
	return duration;
};

export const convertBookingDetails = (
	bookingDetails: Partial<IBookingDetail>[],
	startDateTime: moment.Moment,
	employees?: IEmployee[]
): Partial<IBookingDetail>[] => {
	return bookingDetails.reduce(
		(newBookingDetails: Partial<IBookingDetail>[], bookingDetail, index) => {
			if (!_.isEmpty(bookingDetail.itemId)) {
				const lastBookingDetail = _.last(newBookingDetails);
				const employee = employees?.find((x) => x.id === bookingDetail.id);
				let duration = bookingDetail.item?.duration;
				if (employee) {
					duration = getServiceDuration(bookingDetail.item!, employee);
				}
				const startAtExpected =
					index === 0
						? startDateTime
						: moment(lastBookingDetail!.endAtExpected);
				const endAtExpected = moment(startAtExpected).add(duration, 'minutes');
				const bookingDetailRequest: Partial<IBookingDetail> = {
					...bookingDetail!,
					stylistId:
						_.isEmpty(bookingDetail.stylistId) ||
						bookingDetail.stylistId === 'Anyone'
							? undefined
							: bookingDetail.stylistId,
					stylist: undefined,
					itemId: bookingDetail.itemId,
					item: undefined,
					startAtExpected: startAtExpected.toDate(),
					endAtExpected: endAtExpected.toDate(),
					added: false,
					status: 1,
					price: bookingDetail.item!.price,
					quantity: 1,
					amount: bookingDetail.item!.price,
				};
				delete bookingDetailRequest.stylist;
				delete bookingDetailRequest.item;
				newBookingDetails.push(bookingDetailRequest);
			}
			return newBookingDetails;
		},
		[]
	);
};
