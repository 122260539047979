import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IRole } from 'models';
import { RoleActions } from '../actions';

export interface IRoleReducer {
	roles?: IRole[];
}
const initialState: IRoleReducer = {
	roles: [],
};

//Get Role
const getAllRolesSuccess = (
	state: IRoleReducer,
	action: PayloadAction<IRole[]>
) => {
	state.roles = action.payload;
};

const RoleReducer = createReducer(initialState, (builder) => {
	builder.addCase(RoleActions.getAllRoles.success, getAllRolesSuccess);
});

export default RoleReducer;
