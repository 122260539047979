import { Avatar, ButtonGroup, Tag } from 'components/atoms';
import Table, { EColumnType, IColumnProps } from 'components/atoms/Table';
import { BookingHelper, CurrencyHelper } from 'helpers';
import { getShortName } from 'helpers/StringHelper';
import { IBooking } from 'models';
import { I18n, _t, translations } from 'utils';
import { generateTimeFormat, toTimeZone } from 'helpers/timeHelper';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import clsxm from 'clsxs/clsxm';
import { getNameColorFromStatus } from 'helpers/colorHelper';
import './styles.css';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import moment from 'moment';
import { TDateRange } from 'components/molecules/ModalCalendar';
import { t } from 'i18n-js';
import { BookingTypeIcon } from 'components/molecules/BookingTypeIcon';

const ListBooking = () => {
	const listBookingStatus = useMemo(() => [-1, 0, 1, 4, 5, 7], []);

	const BookingStatuses = _.map(listBookingStatus, (x) => ({
		id: x,
		title: ` ${
			x === -1 ? 'All' : I18n.t(_t(translations.bookingStatus[`status_${x}`]))
		}`,
	}));
	const [status, setStatus] = useState(BookingStatuses[0]);
	const [dateFilter, setDateFilter] = useState<TDateRange>({
		fromDate: moment().subtract(1, 'month'),
		toDate: moment().add(1, 'month'),
	});

	const columns = useMemo<IColumnProps<IBooking>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.listBooking.customerName)),
				key: 'id',
				type: EColumnType.TEXT,
				renderCell: (data) => {
					const customerName = `${data.customer.firstName} ${data.customer.lastName}`;
					return (
						<div className="flex align-middle justify-between pl-2 ">
							<div>
								<Avatar small name={getShortName(customerName)} />
							</div>
							<span className="m-auto ml-4">{customerName}</span>
						</div>
					);
				},
				sortable: true,
			},
			{
				key: 'id',
				header: t(translations.bookingCustomerInfo.bookingType),
				headerClassName: 'text-center',
				renderCell: (row) => (
					<p className="text-center">
						<BookingTypeIcon
							type={row.bookingType}
							inputFrom={row.inputFrom}
							className="inline"
						/>
					</p>
				),
			},
			{
				header: I18n.t(_t(translations.listBooking.date)),
				key: 'startTimeExpected',
				renderCell: (data) => {
					return (
						<div className="align-middle flex">
							<div>
								{toTimeZone(data!.startTimeExpected).format(
									generateTimeFormat('longdate')
								)}
							</div>
						</div>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listBooking.time)),
				key: 'startTimeExpected',
				renderCell: (data) => {
					return (
						<div className="align-middle flex">
							<div>
								{toTimeZone(data!.startTimeExpected).format(
									generateTimeFormat('week_date_time')
								)}
							</div>
						</div>
					);
				},
			},
			{
				key: 'id',
				header: I18n.t(_t(translations.listBooking.services)),
				renderCell: (data) => {
					return (
						<div className="align-middle flex flex-col">
							{data.bookingDetails.map((bookingDetail) => {
								return (
									<>
										<p className="font-bold m-0 p-0">
											{bookingDetail.item?.name}
										</p>
										<p className="m-0 p-0">
											{_.isEmpty(bookingDetail.stylist)
												? `${bookingDetail.item?.duration} minutes`
												: `${bookingDetail.item?.duration} minutes with ${
														bookingDetail.stylist!.firstName
												  } ${bookingDetail.stylist!.lastName}`}
										</p>
									</>
								);
							})}
						</div>
					);
				},
			},
			{
				key: 'id',
				header: I18n.t(_t(translations.listBooking.technician)),
				renderCell: (data) => {
					const technician = _.uniqBy(
						data!.bookingDetails,
						(element) => element.stylist?.id
					).map(
						(techincian) =>
							`${techincian.stylist?.firstName || ''} ${
								techincian.stylist?.lastName || ''
							}`
					);
					return <p>{technician.join(', ')}</p>;
				},
			},
			{
				key: 'id',
				header: `${I18n.t(_t(translations.price))}($)`,
				renderCell: (data) => {
					return (
						<p className="text-center font-bold">
							{CurrencyHelper.formatPrice(
								data!.totalAmount + data!.totalExtraAmount,
								false
							)}
						</p>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listBooking.createdDate)),
				key: 'createdDate',
				type: EColumnType.DATE,
				renderCell: (data) => {
					return (
						<div className="align-middle flex">
							<div>{toTimeZone(data!.createdDate).format('MMM Do, YYYY')}</div>
						</div>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listBooking.status)),
				key: 'status',
				renderCell: (data) => {
					return (
						<div className="">
							<Tag
								label={I18n.t(
									_t(translations.bookingStatus[`status_${data.status}`])
								)}
								className={clsxm(`bg-${getNameColorFromStatus(data.status)} `)}
							/>
						</div>
					);
				},
			},
		];
	}, []);

	return (
		<Table<IBooking>
			queryUrl={'/Booking/get-all-bookings'}
			renderHeader={
				<div className="flex flex-row gap-4">
					<DateRangePicker
						selectedDateRange={dateFilter}
						onSelectDateRange={setDateFilter}
					/>
					<ButtonGroup<{ id: number; title: string }>
						buttons={BookingStatuses}
						valueField={`id`}
						titleField="title"
						value={status}
						onChange={(x) => setStatus(x)}
						buttonClassNames={listBookingStatus.map((x) => {
							if (x === -1) {
								return '';
							}
							return clsxm(
								`text-${getNameColorFromStatus(
									x
								)} border-${getNameColorFromStatus(x)}`
							);
						})}
					/>
				</div>
			}
			columns={columns}
			showSearch
			onClickRow={(data) => BookingHelper.openBooking(data)}
			additionalParams={{
				fromDate: dateFilter.fromDate!.format('YYYY-MM-DD'),
				toDate: dateFilter.toDate!.format('YYYY-MM-DD'),
				orderBy: 'date desc, startTimeExpected desc',
				bookingStatus: `${status.id === -1 ? null : status.id}`,
			}}
			// className="max-h-[calc(100vh-27.3rem)]"
		/>
	);
};

export default ListBooking;
