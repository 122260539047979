/* eslint-disable react-hooks/exhaustive-deps */
import clsxm from 'clsxs/clsxm';
import { Switch, TimeRangePicker } from 'components/atoms';
import { getDayOfWeek } from 'helpers/StringHelper';
import { getInitialWorkingTime, toTimeZone } from 'helpers/timeHelper';
import _ from 'lodash';
import { IBranch, OnlineWorkingHour, WorkingHour } from 'models/IBranch';
import moment, { Moment } from 'moment';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { WeekDays } from 'utils/Consts';
import { UpdateBranchFormField } from './IConfigBaseProps';

interface IWorkingTimeOfDayProps {
	day: WeekDays;
	online?: boolean;
	isLoading?: boolean;
	// onDateSelect: (date: Moment) => void;
	currentBranch?: IBranch;
}
export const WorkingTimeOfDay = ({
	day,
	online,
	isLoading,
	currentBranch,
}: IWorkingTimeOfDayProps) => {
	const { setValue, watch } = useFormContext<UpdateBranchFormField>();
	// const { currentBranch } = useAppSelector((state) => state.BranchReducer);
	const dayOfWeek = getDayOfWeek(day);
	const fieldName = `${dayOfWeek.toLowerCase().replace(' ', '')}${
		online ? 'Online' : ''
	}` as keyof UpdateBranchFormField;
	const isWholeWeek = day === WeekDays.WHOLEWEEK;
	const workingHourCheck = watch(
		(fieldName + 'Check') as keyof UpdateBranchFormField
	) as boolean;
	const timeBlockInterval = watch('timeBlockInterval');

	const [defaultCheck, defaultValue] = useMemo(() => {
		return initialWorkingHour(
			day,
			online
				? currentBranch?.onlineBookingTimes!
				: currentBranch?.workingHours!,
			isWholeWeek,
			currentBranch?.localTimeZone,
			online
		);
	}, [currentBranch]);

	const workingTimes = watch(fieldName) as string[];
	const displayValue = useMemo(() => {
		return _.isEmpty(workingTimes)
			? []
			: [
					getInitialWorkingTime(
						workingTimes[0],
						undefined,
						true,
						currentBranch?.localTimeZone
					),
					getInitialWorkingTime(
						workingTimes[1],
						undefined,
						true,
						currentBranch?.localTimeZone
					),
			  ];
	}, [workingTimes]);

	useEffect(() => {
		if (currentBranch) {
			setValue(
				(fieldName + 'Check') as keyof UpdateBranchFormField,
				defaultCheck
			);
			setValue(fieldName, [defaultValue[0], defaultValue[1]]);
		}
	}, [currentBranch]);

	const onChooseTime = (value: Moment[]) => {
		const convertedValue = [
			moment(
				value[0]
					.local()
					.format('YYYY-MM-DDTHH:mm:ss' + toTimeZone(undefined, currentBranch?.localTimeZone).format('Z'))
			)
				.utc()
				.format('YYYY-MM-DDTHH:mm'),
			moment(
				value[1]
					.local()
					.format('YYYY-MM-DDTHH:mm:ss' + toTimeZone(undefined, currentBranch?.localTimeZone).format('Z'))
			)
				.utc()
				.format('YYYY-MM-DDTHH:mm'),
		];
		if (isWholeWeek) {
			setValue(
				('sunday' + (online ? 'Online' : '')) as keyof UpdateBranchFormField,
				convertedValue
			);
			setValue(
				('monday' + (online ? 'Online' : '')) as keyof UpdateBranchFormField,
				convertedValue
			);
			setValue(
				('tuesday' + (online ? 'Online' : '')) as keyof UpdateBranchFormField,
				convertedValue
			);
			setValue(
				('wednesday' + (online ? 'Online' : '')) as keyof UpdateBranchFormField,
				convertedValue
			);
			setValue(
				('thursday' + (online ? 'Online' : '')) as keyof UpdateBranchFormField,
				convertedValue
			);
			setValue(
				('friday' + (online ? 'Online' : '')) as keyof UpdateBranchFormField,
				convertedValue
			);
			setValue(
				('saturday' + (online ? 'Online' : '')) as keyof UpdateBranchFormField,
				convertedValue
			);
			setValue(
				('wholeweek' + (online ? 'Online' : '')) as keyof UpdateBranchFormField,
				convertedValue
			);
		} else {
			setValue(fieldName, convertedValue);
		}
	};

	return (
		<div className={clsxm('grid grid-cols-6')}>
			<div className={clsxm('my-auto col-span-4')}>
				<p className={clsxm('text-base m-0')}>{getDayOfWeek(day)}</p>
			</div>
			{/* <div className={clsxm("flex flex-row")}></div> */}
			<div
				className={clsxm(
					'col-span-2 flex flex-row justify-end items-center gap-x-8'
				)}
			>
				<TimeRangePicker
					startProps={{
						value: displayValue[0],
					}}
					endProps={{
						value: displayValue[1],
					}}
					defaultValue={[
						getInitialWorkingTime(defaultValue[0], undefined, true),
						getInitialWorkingTime(defaultValue[1], undefined, true),
					]}
					onChooseTime={onChooseTime}
					disabled={(!isWholeWeek && !workingHourCheck) || isLoading}
					minuteStep={Number.parseInt((timeBlockInterval || 0).toString())}
				/>
				<Switch
					defaultChecked={defaultCheck}
					onChange={(value) => {
						if (isWholeWeek) {
							setValue(`sunday${online ? 'Online' : ''}Check`, value);
							setValue(`monday${online ? 'Online' : ''}Check`, value);
							setValue(`tuesday${online ? 'Online' : ''}Check`, value);
							setValue(`wednesday${online ? 'Online' : ''}Check`, value);
							setValue(`thursday${online ? 'Online' : ''}Check`, value);
							setValue(`friday${online ? 'Online' : ''}Check`, value);
							setValue(`saturday${online ? 'Online' : ''}Check`, value);
							setValue(`wholeweek${online ? 'Online' : ''}Check`, value);
						} else {
							setValue(
								(fieldName + 'Check') as keyof UpdateBranchFormField,
								value
							);
						}
					}}
					value={workingHourCheck}
					disabled={isLoading}
					className={isWholeWeek ? 'invisible' : undefined}
				/>
			</div>
		</div>
	);
};

const initialWorkingHour = (
	day: WeekDays,
	workingHours: (WorkingHour & OnlineWorkingHour)[],
	isWholeWeek: boolean,
	timeZone?: string,
	online?: boolean
): [defaultActive: boolean, workingHour: string[]] => {
	const defaultWorkingTime = [
		toTimeZone(undefined, timeZone)
			.startOf('date')
			.hour(8)
			.utc()
			.format('YYYY-MM-DDTHH:mm'),
		toTimeZone(undefined, timeZone)
			.startOf('date')
			.hour(22)
			.utc()
			.format('YYYY-MM-DDTHH:mm'),
	];
	if (isWholeWeek) {
		return workingHours?.length === 7 &&
			workingHours.every(
				(element: WorkingHour & OnlineWorkingHour) =>
					(online
						? element.onlineBookingTimeStartAt
						: element.workingTimeStartAt) ===
						(online
							? workingHours![0].onlineBookingTimeStartAt
							: workingHours![0].workingTimeStartAt) &&
					(online
						? element.onlineBookingTimeEndAt
						: element.workingTimeEndAt) ===
						(online
							? workingHours![0].onlineBookingTimeEndAt
							: workingHours![0].workingTimeEndAt)
			)
			? [
					true,
					[
						online
							? workingHours![0].onlineBookingTimeStartAt!
							: workingHours![0].workingTimeStartAt!,
						online
							? workingHours![0].onlineBookingTimeEndAt!
							: workingHours![0].workingTimeEndAt!,
					],
			  ]
			: [false, defaultWorkingTime];
	} else {
		const workingHour = _.find(workingHours, (element) => {
			return element.days! === day.toString();
		});
		if (workingHour) {
			return [
				true,
				[
					online
						? workingHour!.onlineBookingTimeStartAt!
						: workingHour!.workingTimeStartAt!,
					online
						? workingHour!.onlineBookingTimeEndAt!
						: workingHour!.workingTimeEndAt!,
				],
			];
		} else {
			return [false, defaultWorkingTime];
		}
	}
};
