import { Card, Input, TimePicker } from 'components/atoms';
import { I18n, _t, translations } from 'utils';
import React, { useEffect } from 'react';
import clsxm from 'clsxs/clsxm';
import { useFormContext } from 'react-hook-form';
import { IService } from 'models';
import { UpdateServiceField } from '..';
import moment from 'moment';
import { TimeHelper } from 'helpers';
import { useLocation } from 'react-router-dom';
import { toNumber } from 'lodash';

const PricingAndDuration = (props: { service?: IService }) => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<UpdateServiceField>();

	const location = useLocation();
	const { service } = props;

	useEffect(() => {
		register('itemCategoryId');
	}, [register]);

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	return (
		<Card
			title={I18n.t(_t(translations.servicesDetail.pricingDuration))}
			description={I18n.t(_t(translations.servicesDetail.desPricingDuration))}
		>
			<div className="grid gap-y-8">
				<div className="grid grid-cols-5 pt-8 gap-x-8">
					<div className="col-span-1">
						<label className="label font-medium ">
							<span className="label-text text-md">
								{I18n.t(_t(translations.servicesDetail.duration))} (HH:mm)
							</span>
						</label>
						<TimePicker
							minuteStep={5}
							use12Hours={false}
							format={'HH:mm'}
							defaultValue={
								!isAdd()
									? moment(
											TimeHelper.convertDurationToString(service?.duration!),
											'HH:mm'
									  )
									: moment('01:00', 'HH:mm')
							}
							hideDisabledOptions
							onChange={(e) =>
								setValue(
									'duration',
									TimeHelper.convertDurationToNumber(
										moment(e.toString()).format('HH:mm')
									)
								)
							}
							hideAmPm
							// leftPopUp
						/>
					</div>
					<div className="col-span-1">
						<Input
							type="number"
							label={I18n.t(_t(translations.price))}
							renderBefore={'$'}
							alignRight
							error={
								errors.price?.type === 'pattern'
									? 'Services price can only enter 2 decimal places'
									: errors.price?.type === 'required'
									? I18n.t(_t(translations.servicesDetail.inputPrice))
									: ''
							}
							{...register('price', {
								required: true,
								pattern: /^\s*-?\d+(\.\d{1,2})?\s*$/,
							})}
						/>
					</div>
					<div className="col-span-1">
						<Input
							type="number"
							label={I18n.t(_t(translations.servicesDetail.supplyPrice))}
							renderBefore={'$'}
							alignRight
							error={errors.supplyPrice?.message}
							{...register('supplyPrice', {
								validate: (value?: number) => {
									if (toNumber(value) >= toNumber(watch('price'))) {
										return I18n.t(
											_t(
												translations.servicesDetail
													.supplyPriceSmallerThanService
											)
										);
									}
								},
								pattern: {
									value: /^\s*-?\d+(\.\d{1,2})?\s*$/,
									message: 'Supply price can only enter 2 decimal places',
								},
							})}
						/>
					</div>
				</div>
			</div>
		</Card>
	);
};

export default PricingAndDuration;
