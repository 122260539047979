import { ConfirmModal, Modal } from 'components/atoms';
import { PickService } from 'components/molecules/PickService';
import { IApiResponse, IService } from 'models';
import React, { useEffect, useState } from 'react';
import ServiceApi from 'services/ServiceApi';
import { I18n, _t, translations } from 'utils';
import { PromotionServiceContent } from './AppliedToServices';

interface IListServicesProps {
	isAdd: boolean;
	setServices: (value: string[]) => void;
	data?: string;
}
export const ListServices = (props: IListServicesProps) => {
	const [showModal, setShowModal] = useState(false);
	const [promotionServices, setPromotionServices] = useState<IService[]>([]);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [serviceToDelete, setServiceToDelete] = useState<IService>();

	const onSave = (selectedRows: IService[]) => {
		setPromotionServices([...selectedRows]);
		setShowModal(false);
	};
	const onDelete = async (value: IService) => {
		const newServices = promotionServices.filter(
			(service) => service.id !== value.id
		);
		setPromotionServices(newServices);
	};

	const getServiceById = async (id: string) => {
		const result = (await ServiceApi.getServiceById(
			id
		)) as IApiResponse<IService>;
		if (result.succeeded) {
			if (result.data) {
				return result.data;
			}
		}
		return undefined;
	};

	const fetchAllServicesSelected = async () => {
		if (props.data) {
			const servicesId = props.data.split(',');
			const services = await Promise.all(
				servicesId.map(async (id) => await getServiceById(id))
			);
			const rejectUn = services.filter(
				(service) => service !== undefined
			) as IService[];
			setPromotionServices(rejectUn);
		}
	};

	useEffect(() => {
		fetchAllServicesSelected();
	}, []);
	useEffect(() => {
		const serviceKeys = promotionServices.map((item) => item.id);
		props.setServices(serviceKeys);
	}, [promotionServices]);

	return (
		<div>
			<PromotionServiceContent
				data={promotionServices}
				setServiceToDelete={(value) => setServiceToDelete(value)}
				setShowModalDelete={(value) => setShowModalDelete(value)}
				setShowModal={(value) => setShowModal(value)}
			/>
			<PickService
				title={I18n.t(_t(translations.promotionDetail.modalTitle))}
				visible={showModal}
				selected={promotionServices}
				onSave={(data) => onSave(data)}
				onCancel={() => setShowModal(false)}
			/>
			<ConfirmModal
				visible={showModalDelete}
				title={I18n.t(_t(translations.delete))}
				subTitle={I18n.t(_t(translations.promotionDetail.deleteConfirmContent))}
				onPositiveClick={() => {
					onDelete(serviceToDelete!);
					setShowModalDelete(false);
				}}
				onNegativeClick={() => setShowModalDelete(false)}
				type="error"
			/>
		</div>
	);
};
