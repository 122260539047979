import { DetailPageLayout } from 'components/molecules';
import { translations, t } from 'utils';
import React, { useEffect, useState } from 'react';
import { IBooking, ICustomer } from 'models';
import { useHistory, useParams } from 'react-router-dom';
import CustomerApiService from 'services/CustomerApiService';
import { AlertHelper, CurrencyHelper, StringHelper } from 'helpers';
import moment from 'moment';
import BookingApiService from 'services/BookingApiService';
import { Avatar, Button, Card, Switch, Tag } from 'components/atoms';
import { isEmpty } from 'lodash';
import { BookingStatus } from 'utils/Consts';
import { TabView } from 'components/molecules/Tab';
import { toTimeZone } from 'helpers/timeHelper';
import { StatusTag } from 'components/molecules/StatusTag';
import { useDispatch } from 'react-redux';
import { BookingActions } from 'redux/actions';
import { ILoyaltyRank } from 'models/ILoyalRank';
import LoyaltyApiService from 'services/LoyaltyApiService';
import clsxm from 'clsxs/clsxm';

interface IStatContentProps {
	customer: ICustomer;
	bookings: IBooking[];
}
const StatContent = (props: IStatContentProps) => {
	return (
		<Card>
			<div className="text-center font-semibold">
				<h4 className="text-xl mt-4">
					{CurrencyHelper.formatPrice(props.customer.totalExpense)}
				</h4>
				<h5>{t(translations.customerProfile.totalExpense)}</h5>
			</div>
			<div className="divider m-0" />
			<div className="grid grid-cols-4 font-semibold mb-2">
				<div className="text-center">
					<h4 className="text-xl mt-4">{props.bookings.length}</h4>
					<h5>{t(translations.customerProfile.allBooking)}</h5>
				</div>
				<div className="text-center">
					<h4 className="text-xl mt-4">
						{
							props.bookings.filter(
								(booking) => booking.status === BookingStatus.DONE
							).length
						}
					</h4>
					<h5>{t(translations.customerProfile.completed)}</h5>
				</div>
				<div className="text-center">
					<h4 className="text-xl mt-4">{props.customer.notCome}</h4>
					<h5>{t(translations.customerProfile.noShow)}</h5>
				</div>
				<div className="text-center">
					<h4 className="text-xl mt-4">
						{
							props.bookings.filter(
								(booking) =>
									booking.status === BookingStatus.PENDING ||
									booking.status === BookingStatus.CHECKED_IN ||
									booking.status === BookingStatus.PROCESSING ||
									booking.status === BookingStatus.FINISHED
							).length
						}
					</h4>
					<h5>{t(translations.customerProfile.upComing)}</h5>
				</div>
			</div>
		</Card>
	);
};

interface ILoyaltyInfoProps {
	customer: ICustomer;
}
const LoyaltyInfo = (props: ILoyaltyInfoProps) => {
	return (
		<Card>
			<div className="grid grid-cols-2 font-semibold">
				<div className="text-center">
					<h4 className="text-xl mt-4">{props.customer.earnPoint}</h4>
					<h5>Total point</h5>
				</div>
				<div className="text-center">
					<h4 className="text-xl mt-4">
						{props.customer.loyaltyRankName || 'Not in rank'}
					</h4>
					<h5>Current loyalty rank</h5>
				</div>
			</div>
		</Card>
	);
};
interface ILoyaltyProgressProps {
	customer: ICustomer;
	ranks: ILoyaltyRank[];
}
const LoyaltyProgress = (props: ILoyaltyProgressProps) => {
	const [currentRankIdx, setCurrentRankIdx] = useState(-1);
	useEffect(() => {
		setCurrentRankIdx(
			props.ranks.findIndex((x) => x.id === props.customer.loyaltyRankId)
		);
	}, [props]);
	return (
		<Card>
			<div className="flex justify-between">
				<span>
					{currentRankIdx === -1 ? 0 : props.ranks[currentRankIdx].pointToReach}
				</span>
				<span>
					{currentRankIdx === props.ranks.length - 1
						? ''
						: props.ranks[currentRankIdx + 1].pointToReach}
				</span>
			</div>
			<progress
				className="progress progress-primary w-full"
				value={
					(props.customer.earnPoint || 0) /
					(props.ranks[currentRankIdx + 1].pointToReach || 1)
				}
				max="100"
			></progress>
			<div className="flex justify-between">
				<span>
					{currentRankIdx === -1 ? 'No rank' : props.ranks[currentRankIdx].name}
				</span>
				<span>
					{currentRankIdx === props.ranks.length - 1
						? ''
						: props.ranks[currentRankIdx + 1].name}
				</span>
			</div>
		</Card>
	);
};

const TabListTitle = [
	t(translations.customerProfile.appointments),
	t(translations.customerProfile.products),
	t(translations.customerProfile.invoices),
];
const ListContent = (props: { bookings: IBooking[] }) => {
	return (
		<Card>
			<TabView
				className="bg-white"
				tabs={TabListTitle}
				tabPanels={[
					<ListBooking bookings={props.bookings} />,
					<div></div>,
					<div></div>,
				]}
			/>
		</Card>
	);
};

const ListBooking = (props: { bookings: IBooking[] }) => {
	return (
		<div className="h-[300px] overflow-auto">
			{props.bookings.map((booking) => {
				return (
					<>
						<div className="flex flex-row py-2">
							<div className="basis-1/6 text-center">
								<div className="flex flex-col">
									<span className="font-bold">
										{toTimeZone(booking.startTimeExpected).date()}
									</span>
									<span>
										{toTimeZone(booking.startTimeExpected).format('MMM')}
									</span>
								</div>
							</div>
							<div className="basis-4/6">
								<div>
									{toTimeZone(booking.startTimeExpected).format('ddd h:mmA')}
									<StatusTag status={booking.status} className="ml-2" />
								</div>
								<div>
									{booking.bookingDetails.map((bookingDetail) => {
										const stylistName = bookingDetail.stylist
											? `${bookingDetail.stylist.firstName || ''} ${
													bookingDetail.stylist.lastName || ''
											  }`
											: undefined;
										return (
											<div key={bookingDetail.id} className="flex flex-col">
												<span className="font-bold">
													{bookingDetail.item?.name}
												</span>
												{isEmpty(bookingDetail.stylist) ? (
													<span>{`${bookingDetail.item?.duration} minutes`}</span>
												) : (
													<span>
														{`${bookingDetail.item?.duration} minutes with `}
														<strong>{stylistName}</strong>
													</span>
												)}
											</div>
										);
									})}
								</div>
							</div>
							<div className="basis-1/6 flex justify-end">
								<span className="font-bold">
									{CurrencyHelper.formatPrice(booking.totalAmount)}
								</span>
							</div>
						</div>
						<div className="divider m-0" />
					</>
				);
			})}
		</div>
	);
};

const SideContent = (props: { customer: ICustomer }) => {
	const [disableOnlineBooking, setDisableOnlineBooking] = useState(
		props.customer.disableBookingOnline || false
	);
	const onDisableOnlineBooking = async (value: boolean) => {
		setDisableOnlineBooking(value);
		const data = {
			id: props.customer.id,
			disableBookingOnline: value,
		};
		const response = await CustomerApiService.editBookingOnlinePermission(data);
		if (!response.succeeded) {
			setDisableOnlineBooking(!value);
		}
	};
	return (
		<Card>
			<div className="w-full rounded-l-lg mb-2 py-2 flex">
				<div>
					<Avatar
						small
						name={StringHelper.getShortName(
							`${props.customer.firstName || ''} ${
								props.customer.lastName || ''
							}`
						)}
					/>
				</div>
				<div className="ml-4 flex flex-col flex-1 ">
					<span className="p-0 font-semibold text-sm ">
						{`${props.customer.firstName || ''} ${
							props.customer.lastName || ''
						}`}
						{props.customer.newCustomer && (
							<Tag label="New" className="p-2 bg-[#9CD892] text-xs" />
						)}
					</span>
					<span className="p-0 text-sm ">{`${StringHelper.formatPhoneNumber(
						props.customer.phone
					)}`}</span>
				</div>
				<Switch
					onChange={(value) => onDisableOnlineBooking(!value)}
					className={'flex-1 '}
					checkBoxClassName={disableOnlineBooking ? 'bg-DELETED' : 'bg-primary'}
					fullWidth
					value={!disableOnlineBooking}
					label={'Allow online booking'}
				/>
			</div>
			<MoreInfo customer={props.customer} />
		</Card>
	);
};

const MoreInfo = (props: { customer: ICustomer }) => {
	const stylistName = props.customer.lastStylist
		? `${props.customer.lastStylist.firstName || ''} ${
				props.customer.lastStylist.lastName || ''
		  }`
		: undefined;
	return (
		<TabView
			className="bg-white"
			tabs={['Info']}
			tabPanels={[
				<div className="grid grid-cols-2">
					<div className="grid gap-y-2">
						{!isEmpty(props.customer.phone) && (
							<div className="flex flex-col">
								<span className="font-semibold">
									{t(translations.bookingCustomerInfo.mobile)}
								</span>
								<span className="text-sm">
									{StringHelper.formatPhoneNumber(props.customer.phone)}
								</span>
							</div>
						)}
						{!isEmpty(props.customer.email) && (
							<div className="flex flex-col">
								<span className="font-semibold">{t(translations.email)}</span>
								<span className="text-sm">{props.customer.email}</span>
							</div>
						)}
						{!isEmpty(props.customer.dateOfBirth) && (
							<div className="flex flex-col">
								<span className="font-semibold">
									{t(translations.bookingCustomerInfo.birthDate)}
								</span>
								<span className="text-sm">
									{moment(props.customer.dateOfBirth).format('LL')}
								</span>
							</div>
						)}
					</div>
					<div className="grid gap-y-2">
						{!isEmpty(props.customer.numberOfVisit) && (
							<div className="flex flex-col">
								<span className="font-semibold">
									{t(translations.customerList.numberOfVisit)}
								</span>
								<span className="text-sm">{props.customer.numberOfVisit}</span>
							</div>
						)}
						{!isEmpty(props.customer.lastVisit) && (
							<div className="flex flex-col">
								<span className="font-semibold">
									{t(translations.bookingCustomerInfo.lastVisit)}
								</span>
								<span className="text-sm">
									{moment(props.customer.lastVisit).format('LL')}
								</span>
							</div>
						)}
						{!isEmpty(props.customer.lastStylist) && (
							<div className="flex flex-col">
								<span className="font-semibold">
									{t(translations.bookingCustomerInfo.lastStylist)}
								</span>
								<span className="text-sm">{stylistName}</span>
							</div>
						)}
					</div>
				</div>,
			]}
		/>
	);
};

export const CustomerInfoPage = () => {
	const { id } = useParams() as { id: string };
	const [currentCustomer, setCurrentCustomer] = useState<ICustomer>();
	const [bookings, setBookings] = useState<IBooking[]>([]);
	const [ranks, setRanks] = useState<ILoyaltyRank[]>([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const getCustomerById = async () => {
		setLoading(true);
		try {
			const res = await CustomerApiService.getCustomerById(id);
			if (res.succeeded) {
				setCurrentCustomer(res.data);
			} else {
				AlertHelper.showError(res);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};
	const getBookings = async () => {
		setLoading(true);
		const params = {
			customerId: id,
			fromDate: moment().subtract(6, 'month').toDate(),
			toDate: moment().add(6, 'month').toDate(),
			orderBy: 'date desc',
		};
		try {
			const res = await BookingApiService.getBookingByBranch(params);
			if (res.succeeded) {
				setBookings(res.data || []);
			} else {
				AlertHelper.showError(res);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};
	const getAllRanks = async () => {
		setLoading(true);
		try {
			const res = await LoyaltyApiService.getAllLoyaltyRanks();
			if (res.succeeded && res.data) {
				setRanks(res.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getCustomerById();
		getBookings();
		getAllRanks();
	}, []);
	return (
		<DetailPageLayout
			title={t(translations.customerProfile.profile)}
			loading={loading}
			noFooter
		>
			{!isEmpty(currentCustomer) ? (
				<>
					<div className="grid grid-cols-3 gap-4">
						<div className="col-span-2 grid gap-4">
							<StatContent customer={currentCustomer!} bookings={bookings} />
							<LoyaltyInfo customer={currentCustomer!} />
							{!isEmpty(ranks) && (
								<LoyaltyProgress customer={currentCustomer!} ranks={ranks} />
							)}
							<ListContent bookings={bookings} />
						</div>
						<div>
							<SideContent customer={currentCustomer!} />
							<div className="grid grid-cols-2 gap-2 mt-4">
								<Button
									title={t(translations.customerProfile.newBooking)}
									fullWidth
									onClick={() => {
										dispatch(
											BookingActions.selectCustomer.request(currentCustomer)
										);
										history.push(`/booking/new`, {
											customer: currentCustomer,
										});
									}}
									primary
								/>
								<Button
									title={t(translations.edit)}
									fullWidth
									className="btn-outline btn-primary"
									onClick={() => {
										history.push(`/customer/edit/${currentCustomer.id}`);
									}}
								/>
							</div>
						</div>
					</div>
				</>
			) : null}
		</DetailPageLayout>
	);
};
