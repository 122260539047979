import { Button, IColumnProps, Icon, Switch } from 'components/atoms';
import { IEmployee, IEmployeeType } from 'models';
import React, { useMemo, useRef } from 'react';
import { I18n, _t, translations } from 'utils';
import { useHistory } from 'react-router-dom';
import Table, { ITableRef } from 'components/atoms/Table';
import { mdiPencilOutline, mdiPlus } from '@mdi/js';

export const StaffTypes = () => {
	const history = useHistory();

	const tableRef = useRef<ITableRef>();

	const columns = useMemo<IColumnProps<IEmployeeType>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.employeeType.employeeType)),
				key: 'name',
			},
			{
				header: I18n.t(_t(translations.employeeType.rolePermission)),
				key: 'roleName',
			},
			{
				header: I18n.t(_t(translations.employeeType.description)),
				key: 'description',
			},
			{
				header: I18n.t(_t(translations.action)),
				key: 'id',
				headerClassName: 'w-44',
				cellClassName: 'w-44',
				renderCell: (data) => {
					return (
						<div className="flex gap-4 items-center">
							<div
								className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
								onClick={(e) => {
									e.stopPropagation();
									history.push(`/staff-type/edit/${data.id}`);
								}}
							>
								<Icon path={mdiPencilOutline} className="w-6 h-6" />
							</div>
						</div>
					);
				},
			},
		];
	}, []);

	return (
		<div className="px-2">
			<Table<IEmployee>
				queryUrl={'/EmployeeType/get-all-employee-types'}
				columns={columns}
				additionalParams={{ orderBy: 'code' }}
				showSearch
				renderHeader={
					<Button
						small
						primary
						title={I18n.t(_t(translations.addNew))}
						iconBefore={<Icon path={mdiPlus} />}
						onClick={() => history.push('/staff-type/add-new')}
					/>
				}
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				onClickRow={(data) => history.push(`/staff-type/edit/${data.id}`)}
				// className="max-h-[calc(100vh-21.5rem)]"
			/>
		</div>
	);
};
