import { ITransaction } from 'models/ITransaction';
import { BaseApiService } from 'services/BaseApiService';

const url = `/Transaction`;
class TransactionApiService extends BaseApiService {
	public createTransaction = (params: Partial<ITransaction>) =>
		this.post<Partial<ITransaction>, ITransaction>(
			`${url}/create-transaction`,
			params
		);
	public getTransactionById = (id: string) =>
		this.get<ITransaction>(`${url}/get-transaction/${id}`);
}

export default new TransactionApiService();
