import { mdiAlert } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Icon } from 'components/atoms';
import { ButtonGroup } from 'components/atoms/ButtonGroup';
import { BookingTypeIcon } from 'components/molecules/BookingTypeIcon';
import { SearchInput } from 'components/molecules/SearchInput';
import { StatusTag } from 'components/molecules/StatusTag';
import {
	StringHelper,
	ColorHelper,
	CurrencyHelper,
	TimeHelper,
	BookingHelper,
} from 'helpers';
import { getNameColorFromStatus } from 'helpers/colorHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import I18n from 'i18n-js';
import _ from 'lodash';
import { IBooking, EBookingType } from 'models';
import moment from 'moment';
import { useTodayContext } from 'pages/Today/context';
import { useMemo, useState } from 'react';
import {
	getTodayBookingByStatus,
	searchTodayBooking,
} from 'redux/selectors/employee';
import { _t, translations } from 'utils';
import { BookingStatus } from 'utils/Consts';

const getStatusOrder = (status: number) => {
	switch (status) {
		case BookingStatus.CHECKED_IN:
			return 0;
		case BookingStatus.PROCESSING:
			return 1;
		case BookingStatus.PENDING:
			return 2;
		default:
			return status;
	}
};

const BookingItem = (props: { booking: IBooking; isWalkIn?: boolean }) => {
	const { booking } = props;
	const isRequested = !_.isEmpty(
		booking.bookingDetails.find((x) => x.isStylistRequested === true)
	);
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const blinking = () => {
		if (
			booking.status === BookingStatus.CANCELED ||
			booking.status === BookingStatus.SUSPENDED
		) {
			return false;
		}
		const endTime = moment.utc(
			_.last(booking.bookingDetails)?.endAt ||
				_.last(booking.bookingDetails)?.endAtExpected
		);
		if (booking.status === BookingStatus.FINISHED) {
			return endTime.isBefore(moment()) && moment().diff(endTime, 'minute') > 5;
		} else {
			return (
				endTime.isBefore(moment()) &&
				moment().diff(endTime, 'minute') > (branch?.timeToAlertCheckOut || 30)
			);
		}
	};

	const bookingDetails = useMemo(() => {
		return _.sortBy(booking.bookingDetails, (x) =>
			moment.utc(x.startAt || x.startAtExpected).unix()
		);
	}, [booking]);

	const startTime = useMemo(() => {
		return TimeHelper.toTimeZone(
			bookingDetails[0].startAt || bookingDetails[0].startAtExpected
		).format('h:mmA');
	}, [bookingDetails]);

	return (
		<div
			style={{
				backgroundColor: booking?.bookingDetails[0]?.stylist
					? booking?.bookingDetails[0]?.stylist?.color || '#cfcfcf'
					: '#cfcfcf',
			}}
			onClick={() => {
				BookingHelper.openBooking(booking);
			}}
			className={clsxm(
				'col-span-1 flex relative flex-col border-primary rounded-lg border-2 active:animate-pop cursor-pointer duration-300 ease-in ',
				`border-${getNameColorFromStatus(booking.status)}`,
				blinking() && 'blinking'
			)}
		>
			<div
				className={clsxm(
					'w-full bg-white rounded-br-[60px] rounded-t-lg relative p-2 '
				)}
			>
				<div className="flex justify-between align-middle">
					<div className="flex flex-col">
						<p className="font-bold m-0 w-full break-all leading-5">{`${
							booking.customer.firstName || ''
						} ${booking.customer.lastName || ''}`}</p>
						<p className="text-xs invisible lg:visible m-0 mt-[-2px]">
							{StringHelper.formatPhoneNumber(booking.customer.phone)}
						</p>
					</div>

					<div
						className={`text-${ColorHelper.getNameColorFromStatus(
							booking.status
						)}`}
					>
						<BookingTypeIcon
							type={booking.bookingType}
							inputFrom={booking.inputFrom}
						/>
					</div>
				</div>
				<p className="font-semibold text-xs mt-0">
					{CurrencyHelper.formatPrice(booking.totalAmount)}
					<span className=" font-semibold m-0 pb-2 rounded-md">
						{` (${startTime})`}
					</span>
				</p>
				<StatusTag
					status={booking.status}
					className={clsxm(
						'absolute bottom-0 left-0 rounded-l-none p-2 px-3 rounded-b-none rounded-tr-md'
					)}
				/>
			</div>
			<div className={clsxm('grid grid-flow-row auto-rows-fr h-full')}>
				{_.take(bookingDetails, 3).map((x, index) => {
					return (
						<div
							className={clsxm(
								'grid grid-col grid-cols-7 mb-0 border-b-[1px] border-[#949599]',
								index === booking.bookingDetails.length - 1 &&
									'rounded-b-lg border-b-0',
								blinking() && 'blinking'
							)}
							style={{
								backgroundColor: x.stylist ? x.stylist?.color : undefined,
							}}
						>
							<div
								className={clsxm(
									'col-span-2 overflow-hidden border-r-[1px] border-[#949599]',
									index === booking.bookingDetails.length - 1 && 'rounded-bl-lg'
								)}
							>
								{!_.isEmpty(x.stylist?.imageUrl) ? (
									<div className=" h-full overflow-hidden">
										<div className={clsxm('w-full h-full ')}>
											<img
												alt=""
												src={x.stylist?.imageUrl}
												className="m-0 object-cover h-full "
											/>
										</div>
									</div>
								) : (
									<div
										className={clsxm(
											'text-neutral-content h-full flex items-center justify-center '
										)}
									>
										<div className="text-[#000] font-semibold text-[11px] text-center break-all">
											{`${x.stylist?.firstName || ''}`.toLocaleUpperCase()}
										</div>
									</div>
								)}
							</div>

							<div className="pl-[0.5px] pr-[0.5px] pb-[0.8px] col-span-5 text-[11px] font-semibold m-0 pt-[0.3px] rounded-md">
								{x ? x.item?.name : ''}
							</div>
						</div>
					);
				})}
			</div>
			{booking.bookingDetails.length > 3 && (
				<div className="p-1 bg-white rounded-b-lg">
					<p className="text-xs font-semibold text-center m-0 pl-1 pt-1 pb-1">
						{`+ ${booking.bookingDetails.length - 3} more services`}
					</p>
				</div>
			)}
			{props.isWalkIn && (
				<div
					className={clsxm(
						'ribbon rounded-sm px-1 py-0 active:animate-pop duration-500 ease-in cursor-pointer ',
						`bg-${ColorHelper.getNameColorFromStatus(booking.status)}`
					)}
				>
					<span className="text-white font-bold h-full flex align-middle justify-center items-center ">
						{`${booking.bookingNumber}`}
					</span>
					<div className="ribbon-corner"></div>
				</div>
			)}
			{isRequested && (
				<div
					className={clsxm(
						'ribbon-left rounded-sm px-1 py-0 active:animate-pop duration-500 ease-in cursor-pointer bg-warning '
					)}
				>
					<span className="text-black font-bold gap-1 text-xs h-full flex align-middle justify-center items-center ">
						<Icon path={mdiAlert} size={0.7} />
						{`Requested`}
					</span>
					<div className="ribbon-left-corner"></div>
				</div>
			)}
		</div>
	);
};

const ListBooking = (props: {
	title: string;
	bookings: IBooking[];
	isWalkIn?: boolean;
}) => {
	return (
		<div
			id={props.isWalkIn ? 'walk-in' : 'appointment'}
			className="bg-white px-4 rounded-lg flex-1 overflow-y-auto hide-scrollbar"
		>
			<h2 className="mb-0 mt-2 uppercase">{props.title}</h2>
			<div className="gap-2 py-4 w-full grid grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
				{props.bookings.map((x) => (
					<BookingItem booking={x} isWalkIn={props.isWalkIn} />
				))}
			</div>
		</div>
	);
};

export const RealTimeBooking = () => {
	const { staff } = useTodayContext();
	const todayBookings = useAppSelector((state) =>
		getTodayBookingByStatus(state, -1)
	).filter((x) => x.status !== BookingStatus.DONE);
	const countBooking = (type: number) => {
		if (type === -1) {
			return todayBookings.length;
		} else {
			return todayBookings.filter((x) => x.status === type).length;
		}
	};

	const listBookingStatus = useMemo(() => [-1, 0, 1, 4, 5], []);
	const BookingStatuses = _.map(listBookingStatus, (x) => ({
		id: x,
		title: ` ${
			x === -1 ? 'All' : I18n.t(_t(translations.bookingStatus[`status_${x}`]))
		} (${countBooking(x)})`,
	}));
	const [status, setStatus] = useState(BookingStatuses[0]);
	const [searchText, setSearchText] = useState('');
	const filteredBookings = todayBookings.filter((x) => {
		if (staff !== 'all') {
			const listStaffs = x.bookingDetails
				.filter((x) => !_.isEmpty(x.stylistId))
				.map((x) => x.stylistId);
			if (!listStaffs.includes(staff)) {
				return false;
			}
		}
		return status.id === -1 || x.status === status.id;
	});
	const searchBookings = useAppSelector((state) =>
		searchTodayBooking(filteredBookings, searchText)
	);
	const bookings = _.orderBy(
		searchBookings,
		[(x) => getStatusOrder(x.status), (x) => x.startTimeExpected],
		['asc', 'asc']
	);
	return (
		<div className="w-full h-full flex flex-col overflow-hidden">
			<div className="flex flex-col xl:flex-row w-full gap-2 justify-between">
				<div id="booking-status">
					<ButtonGroup<{ id: number; title: string }>
						buttons={BookingStatuses}
						valueField={`id`}
						titleField="title"
						value={status}
						className={'flex xl:flex-none'}
						onChange={(x) => setStatus(x)}
						buttonClassNames={listBookingStatus.map((x) => {
							if (x === -1) {
								return 'flex-auto xl:flex-none px-3';
							}
							return clsxm(
								'flex-auto xl:flex-none px-3',
								`text-${getNameColorFromStatus(
									x
								)} border-${getNameColorFromStatus(x)}`
							);
						})}
					/>
				</div>

				<div id="search-input" className="xl:w-1/3 w-full">
					<SearchInput onChange={(e) => setSearchText(e.target.value)} />
				</div>
			</div>
			<div className="flex flex-1 flex-col gap-4 py-2 overflow-hidden">
				<ListBooking
					title="Appointment"
					bookings={bookings.filter(
						(booking) => booking.bookingType !== EBookingType.WalkIn
					)}
				/>

				<ListBooking
					title="Walk In"
					isWalkIn
					bookings={bookings.filter(
						(booking) => booking.bookingType === EBookingType.WalkIn
					)}
				/>
			</div>
		</div>
	);
};
