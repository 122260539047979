import { ReduxHelper } from 'helpers';
import { ICloverDevice } from 'models/CloverResponse';

export const prefix = 'CLOVER';

const setCloverConfig = ReduxHelper.generateLocalAction<{
	merchantId: string;
	code: string;
}>(prefix + '/SET_CLOVER_CONFIG');
const setCloverAccessToken = ReduxHelper.generateLocalAction<string>(
	prefix + '/SET_CLOVER_ACCESS_TOKEN'
);
const setCloverDeviceId = ReduxHelper.generateLocalAction<string>(
	prefix + '/SET_CLOVER_DEVICE_ID'
);
const setCloverDevices = ReduxHelper.generateLocalAction<ICloverDevice[]>(
	prefix + '/SET_CLOVER_DEVICES'
);

const reset = ReduxHelper.generateLocalAction(prefix + '/RESET');
export {
	setCloverConfig,
	setCloverAccessToken,
	setCloverDeviceId,
	setCloverDevices,
	reset,
};
