import clsxm from 'clsxs/clsxm';
import React, { forwardRef, Ref, TextareaHTMLAttributes } from 'react';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	error?: string;
	label?: string;
	full?: boolean;
}

export const TextArea = forwardRef(
	(props: ITextAreaProps, ref: Ref<HTMLTextAreaElement>) => {
		delete props.className;
		return (
			<div className={clsxm('form-control', 'w-full', props.full && 'h-full')}>
				{props.label && (
					<label className="label font-medium">
						<span className="label-text text-md">{props.label}</span>
					</label>
				)}

				<textarea
					{...props}
					ref={ref}
					className={clsxm(
						'textarea textarea-bordered w-full',
						props.error && 'textarea-error',
						props.full && 'h-full'
					)}
				/>

				{props.error && (
					<p className="ml-2 mt-2 text-left w-full text-error">{props.error}</p>
				)}
			</div>
		);
	}
);
