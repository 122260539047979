import { RootState } from 'redux/configuration/rootReducer';
import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

const getSearchBooking = (state: RootState) => state.SearchReducer.bookings;
export const getOrderedBookings = createSelector(
	[getSearchBooking],
	(bookings) => {
		return _.sortBy(bookings, [
			(booking) => {
				const diff = moment
					.utc()
					.diff(moment.utc(booking.startTimeExpected), 'minute');
				return Math.abs(diff);
			},
			(booking) => booking.status,
		]);
	}
);

const paymentMethods = (state: RootState) =>
	state.PaymentMethodReducer.paymentMethods;
const paymentMethodSearchText = (__: RootState, searchText: string) =>
	searchText;
export const searchPaymentMethods = createSelector(
	[paymentMethods, paymentMethodSearchText],
	(paymentMethods, searchText) =>
		paymentMethods.filter(
			(element) =>
				element.name.toLowerCase().includes(searchText.toLowerCase()) ||
				element.code.toLowerCase().includes(searchText.toLowerCase())
		)
);
