import {
	HubConnectionBuilder,
	HubConnection,
	HttpTransportType,
	LogLevel,
} from '@microsoft/signalr';
import configureStore from 'redux/configuration/configureStore';
// SkipNegotiation = true;
// Transports = HttpTransportType.WebSockets;
class RemoteSocketService {
	private instance: HubConnection;
	private onRemoteCallBack: Function | undefined;
	constructor(uuid: string) {
		this.instance = this.setUpService(uuid);
		// this.onListenRemote = onRemote;
	}
	public setUpService(uuid: string) {
		const hubConnectionBuilder = new HubConnectionBuilder()
			.configureLogging(LogLevel.Information)
			.withUrl(
				`${process.env.REACT_APP_SOCKET_STAGING_URL}/remotely/?uuid=${uuid}`,
				{
					withCredentials: false,
				}
			)
			.withAutomaticReconnect();

		var connection = hubConnectionBuilder.build();
		connection.serverTimeoutInMilliseconds = 120000;
		connection.keepAliveIntervalInMilliseconds = 60000;

		return connection;
	}
	public runService() {
		try {
			this.instance.start().then((a) => {
				if (this.instance.connectionId) {
				}
			});
			this.instance.onreconnected((connectionString) =>
				console.log('Connection String', connectionString)
			);
		} catch (error) {
			setTimeout(() => {
				this.runService();
			}, 5000);
		}
	}
	public onClose() {
		this.instance.onclose(() => {
			console.log('HUB CLOSED');
			// this.runService();
		});
	}
	public setCallBack = (onRemote: () => void) => {
		this.onRemoteCallBack = onRemote;
	};
	public onListenRemote() {
		this.instance.on('RemotelyConnected', (data) => {
			console.log('REMOTE', data);
			if (this.onRemoteCallBack) {
				this.onRemoteCallBack();
			}
		});
	}
	public remove() {
		this.instance.stop();
	}
}

export default RemoteSocketService;
