import { RootState } from 'redux/configuration/rootReducer';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { TimeHelper } from 'helpers';
import moment from 'moment';

const getTimeBlocks = (state: RootState) => state.BookingReducer.timeBlocks;
const getActiveTimeId = (state: RootState) => state.BookingReducer.timeActiveId;

export const availableTimeBlock = createSelector(
	[getTimeBlocks, getActiveTimeId],
	(timeBlocks, activeTimeId) => {
		const times = _.filter(timeBlocks, (timeBlock) => {
			if (
				activeTimeId &&
				TimeHelper.getTimesFromBlock(activeTimeId!) ===
					moment(timeBlock.time).format('HH:mm')
			) {
				return true;
			} else {
				return !timeBlock.noStylistsAvailable && !timeBlock.unavailable;
			}
		});
		return times;
	}
);
