import React, { useEffect, useState } from 'react';
import { useFileUpload, FileUpload } from 'use-file-upload';
import { mdiPlus } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Icon } from 'components/atoms/Icon';

export interface IBannerUpload {
	source?: string;
	onChange: (file: File, source?: string) => void;
}

export const BannerUpload = (props: IBannerUpload) => {
	const [files, selectFiles] = useFileUpload();
	const [src, setSrc] = useState(props.source);
	useEffect(() => {
		setSrc(props.source);
	}, [props.source]);
	return (
		<div className={clsxm('avatar', !src && 'placeholder')}>
			<div
				onClick={() => {
					selectFiles({ accept: 'image/*', multiple: false }, (data) => {
						const fileUpload = data as FileUpload;
						setSrc(`${fileUpload.source}`);
						props.onChange(fileUpload.file, `${fileUpload.source}`);
					});
				}}
				className={clsxm(
					'btn w-24 h-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 p-0',
					!src && 'bg-base-300',
					src && 'w-[64rem] h-[36rem] rounded-md'
				)}
			>
				{src ? (
					<img alt="" className="m-0 object-cover w-full h-full" src={src} />
				) : (
					<span className="text-black text-opacity-50">
						<Icon path={mdiPlus} />
					</span>
				)}
			</div>
		</div>
	);
};
