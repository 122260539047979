import clsxm from 'clsxs/clsxm';
import { Button } from 'components/atoms';
import { ReactNode } from 'react';
import Modal from 'react-modal';
import { t, translations } from 'utils';

interface IBookingDetailModalProps {
	visible?: boolean;
	onCancel: () => void;
	loading?: boolean;
	children?: ReactNode;
}
export const BookingDetailModal = ({
	visible,
	onCancel,
	loading,
	children,
}: IBookingDetailModalProps) => {
	return (
		<Modal
			isOpen={visible || false}
			style={{
				content: {
					width: '80%',
					margin: '40% auto',
					padding: 0,
					borderWidth: 0,
				},
				overlay: {
					zIndex: 51,
				},
			}}
			className={clsxm(
				'shadow-md rounded-lg flex flex-col overflow-hidden bg-white'
			)}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
		>
			<div
				className={clsxm(
					'p-4 w-full flex justify-between customer-booking-background rounded-tl-lg'
				)}
			>
				<article className="prose">
					<h3 className="mb-0 text-white">Booking Detail</h3>
				</article>
			</div>
			<div className={clsxm('flex-1 flex flex-col h-full p-4')}>
				<div className={clsxm('flex-1 py-4 max-h-[40vh] overflow-auto')}>
					<div>{children}</div>
				</div>
				<div
					className={clsxm(
						'h-[48px] flex flex-row-reverse items-end content-end gap-4 '
					)}
				>
					<Button
						small
						title={t(translations.cancel)}
						className="btn btn-outline"
						onClick={onCancel}
						loading={loading}
					/>
				</div>
			</div>
		</Modal>
	);
};
