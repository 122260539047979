import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IBranch, IPrinter } from 'models/IBranch';
import { BranchActions, SettingActions } from 'redux/actions';
import { Const } from 'utils';

export interface ISettingsReducer {
	printerIp?: string;
	printerPort: string;
	printerConnectionType?: Const.PRINTER_CONNECTION;
	receiptPrintType: Const.RECEIPT_PRINT_TYPE;
	isEnableTicketPrinting: boolean;
}

const initialState: ISettingsReducer = {
	printerPort: '9100',
	printerConnectionType: Const.PRINTER_CONNECTION.USB,
	receiptPrintType: Const.RECEIPT_PRINT_TYPE.ONE,
	isEnableTicketPrinting: false,
};

const setPrinterSetting = (
	state: ISettingsReducer,
	action: PayloadAction<IPrinter>
) => {
	state.printerIp = action.payload.printerIp;
	state.printerConnectionType = action.payload.printerConnectionType;
	state.isEnableTicketPrinting = action.payload.isEnableTicketPrinting;
	state.receiptPrintType = action.payload.receiptPrintType;
};

const setPrinterByBranch = (
	state: ISettingsReducer,
	action: PayloadAction<IBranch>
) => {
	if (action.payload.printerSettings && action.payload.printerSettings[0]) {
		const printerSetting = action.payload.printerSettings[0];
		state.printerIp = printerSetting.printerIp;
		state.printerConnectionType = printerSetting.printerConnectionType;
		state.isEnableTicketPrinting = printerSetting.isEnableTicketPrinting;
		state.receiptPrintType = printerSetting.receiptPrintType;
	}
};

const SettingReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(BranchActions.getBranchById.success, setPrinterByBranch)
		.addCase(SettingActions.savePrinterSetting.request, setPrinterSetting)
);

export default SettingReducer;
