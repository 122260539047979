import clsxm from 'clsxs/clsxm';
import { Avatar, Modal } from 'components/atoms';
import { SearchInput } from 'components/molecules';
import { StatusTag } from 'components/molecules/StatusTag';
import { LoadingProvider } from 'contexts/LoadingContext';
import { CurrencyHelper, StringHelper } from 'helpers';
import { showAlert } from 'helpers/alertHelper';
import {
	convertDateRequest,
	createBillFromBookingDeep,
} from 'helpers/bookingHelper';
import { getNameColorFromStatus } from 'helpers/colorHelper';
import _ from 'lodash';
import { debounce } from 'lodash';
import { IBooking } from 'models';
import { IGetBookingsRequest } from 'models/RequestModels';
import moment from 'moment';
import { useCheckOutContext } from 'pages/CheckOut/context';
import { useCallback, useEffect, useState } from 'react';
import BookingApiService from 'services/BookingApiService';
import { t, translations } from 'utils';
import { BookingStatus } from 'utils/Consts';

const checkNoStylist = (booking: IBooking) => {
	let noStylist = false;
	for (const bookingDetail of booking.bookingDetails) {
		if (_.isEmpty(bookingDetail.stylistId)) {
			noStylist = true;
			break;
		}
	}
	return noStylist;
};

export const CombineModal = (props: { onCancel: () => void }) => {
	const [loading, setLoading] = useState(false);
	const { bill, setChildrenBills, childrenBills, deletePromotion } =
		useCheckOutContext();
	const [searchText, setSearchText] = useState('');
	const [bookings, setBookings] = useState<IBooking[]>([]);
	const fetchBill = async (text: string) => {
		setLoading(true);
		const params: IGetBookingsRequest = {
			bookingStatus: `${BookingStatus.CHECKED_IN},${BookingStatus.PROCESSING},${BookingStatus.FINISHED}`,
			searchText: text,
			fromDate: convertDateRequest(moment()),
			toDate: convertDateRequest(moment()),
			orderBy: 'date desc',
		};
		const response = await BookingApiService.getBookingByBranch(params);
		setLoading(false);
		if (response.succeeded && response.data) {
			setBookings(
				_.filter(
					response.data,
					(x) =>
						x.id !== bill?.bookingId &&
						!childrenBills?.map((b) => b.bookingId).includes(x.id) &&
						!bill?.childBills.map((cb) => cb.bookingId).includes(x.id)
				)
			);
		}
	};
	const debounceSearch = useCallback(
		debounce((text: string) => {
			setSearchText(text);
			fetchBill(text);
		}, 1000),
		[]
	);
	const onSelectBooking = async (booking: IBooking) => {
		if (checkNoStylist(booking)) {
			showAlert(
				t(translations.checkout.mustAssignStaffBeforeCheckout),
				'error'
			);
			return;
		}
		const responseBooking = await BookingApiService.getBookingById(booking.id);
		if (responseBooking.succeeded && responseBooking.data) {
			const newBill = createBillFromBookingDeep(responseBooking.data);
			setChildrenBills(
				childrenBills!.concat([{ ...newBill, id: newBill.bookingId }])
			);
			// deletePromotion();
			props.onCancel();
		}
	};
	useEffect(() => {
		fetchBill('');
	}, []);
	return (
		<Modal
			isOpen={true}
			onCancel={() => props.onCancel && props.onCancel()}
			title={t(translations.checkout.combineBill)}
			className={'w-[600px]'}
			content={
				<div className="p-4 overflow-y-auto w-full h-full overflow-hidden ">
					<div className="w-full flex justify-end">
						<div className="flex-1 mr-4">
							<SearchInput
								onChange={(e) => debounceSearch(e.target.value)}
								autoFocus
							/>
						</div>
					</div>
					<LoadingProvider loading={loading}>
						<ul className="bg-white mt-4 rounded-lg  border border-base-200 text-gray-900 list-none pl-0 ">
							{bookings.map((x, index) => {
								return (
									<li
										onClick={() => onSelectBooking(x)}
										className={clsxm(
											'px-4 py-3 m-0 my-1 relative active:animate-pop duration-500 ease-in cursor-pointer border-2 rounded-lg flex justify-between w-full',
											`border-${getNameColorFromStatus(x.status)}`
										)}
										style={{ backgroundColor: x.stylist?.color || 'white' }}
									>
										<div className="flex flex-1">
											<div>
												<Avatar
													small
													name={StringHelper.getShortName(
														`${x.customer.firstName || ''} ${
															x.customer.lastName || ''
														}`
													)}
												/>
											</div>
											<div className="ml-4 flex flex-col flex-1 ">
												<span className="p-0 font-semibold text-sm ">
													{`${x.customer.firstName || ''} ${
														x.customer.lastName || ''
													}`}
													{'  '}
													<StatusTag
														className="p-2 text-xs"
														status={x.status}
													/>
												</span>
												<span className="p-0 text-sm ">{`${StringHelper.formatPhoneNumber(
													x.customer.phone
												)}`}</span>
												<div className="divider mt-0 mb-1" />
												<div className="flex flex-col">
													{x.bookingDetails.map((bd) => {
														return (
															<span>
																<strong>{bd.item?.name}</strong>{' '}
																<span className="p-0 text-sm ">{` - ${
																	bd.stylist?.firstName || ''
																} ${bd.stylist?.lastName || ''}`}</span>{' '}
																<span className="p-0 text-sm ">{`(${bd.item?.duration} mins)`}</span>{' '}
															</span>
														);
													})}
												</div>
											</div>
											<div>
												<span className="font-bold ">
													{CurrencyHelper.formatPrice(x.totalAmount)}
												</span>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</LoadingProvider>
				</div>
			}
		/>
	);
};
