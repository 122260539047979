import clsxm from 'clsxs/clsxm';
import React from 'react';
interface ITagProps {
	className?: string;
	label: string;
}

export const Tag = (props: ITagProps) => {
	return (
		<div
			className={clsxm(
				'badge badge-primary p-4 border-0 bg-white',
				props.className
			)}
		>
			{props.label}
		</div>
	);
};
