import { DetailPageLayout } from 'components/molecules';
import { TabView } from 'components/molecules/Tab';
import { I18n, _t, translations } from 'utils';
import React, { useEffect, useMemo, useState } from 'react';
import { PromotionInfo } from './components/PromotionInfo';
import { useForm } from 'react-hook-form';
import {
	IPromotion,
	IPromotionCoupon,
	IPromotionCouponRequest,
	IPromotionItemCategoryRequest,
	IPromotionItemRequest,
	IPromotionType,
	IRequirement,
	IRequirementsRequest,
} from 'models/IPromotion';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PromotionApiService from 'services/PromotionApiService';
import { IApiResponse, IErrorResponse, IItemCategory, IService } from 'models';
import { isEmpty } from 'lodash';
import { Requirements } from './components/Requirements';
import { AppliedToServices } from './components/AppliedToServices';
import { AppliedToCategories } from './components/AppliedToCategories';
import { CouponCode } from './components/CouponCode';
import { useAppSelector } from 'helpers/hookHelpers';
import { AlertHelper } from 'helpers';
import UploadBaseService from 'services/UploadBaseService';
import { IFileUploadResponse } from 'models/ResponseModels';
import moment from 'moment';
import { toTimeZone } from 'helpers/timeHelper';

export interface IPromotionField {
	id: string;
	name: string;
	description: string;
	promotionTypeId: string;
	usePercentage: boolean;
	discountPercentage: number;
	discountAmount: number;
	maximumDiscountAmount?: number;
	startDateUtc: string;
	endDateUtc?: string;
	requiresCouponCode: boolean;
	isCumulative: boolean;
	limitationType: number;
	limitationTimes?: number;
	maximumDiscountedQuantity: number;
	isEnabled: boolean;
	branchId?: string;
	shopId: string;
	requirements: IRequirement[];
	bannerUrl?: string;
	file?: File;
	note: string;
	code: string;
	promotionTypeCode: string;
	coupons: IPromotionCoupon[];
	promotionIds: string;
	couponCodes: string;
	AssignedToCustomerGroup?: boolean;
	HadSpentAnAmount?: boolean;
	isReturnMoreThan?: boolean;
	isAllOfTheseServices?: boolean;
	isOneOfTheseServices?: boolean;
	requiredSpentAmount?: number;
	nTimesReturn?: number;
	requirementHaveNotVisitedIn?: number;
	requirementAllOfServices?: string;
	requirementOneOfServices?: string;
	requirementHaveBirthdayIn?: string;
	requirementReviewGroup?: string;
	requirementCustomerRank?: string;
}

export const PromotionDetailPage = () => {
	const location = useLocation();
	const history = useHistory();
	const { id } = useParams() as { id: string };
	const [loading, setLoading] = useState(false);
	const [promotion, setPromotion] = useState<IPromotion>();
	const [requirement, setRequirement] = useState<IRequirement[]>([]);
	const [description, setDescription] = useState<string>('');
	const [discountTypes, setDiscountTypes] = useState<IPromotionType[]>([]);
	const [isVisitRequirement, setIsVisitRequirement] = useState<boolean>(false);

	const [categories, setCategories] = useState<IItemCategory[]>([]);
	const [services, setServices] = useState<IService[]>([]);
	const [coupons, setCoupons] = useState<IPromotionCoupon[]>([]);
	const [newBanner, setNewBanner] = useState<File>();

	const form = useForm<IPromotionField>({
		reValidateMode: 'onChange',
		mode: 'onChange',
		defaultValues: {
			limitationType: 0,
			limitationTimes: 0,
		},
	});
	const discountType = form.watch('promotionTypeId');
	const requiresCouponCode = form.watch('requiresCouponCode');
	const requirementAllOfServices = form.watch('requirementAllOfServices');
	const requirementOneOfServices = form.watch('requirementOneOfServices');

	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/promotions');
	};

	const getCodeOfPromotionType = (id: string) => {
		const type = discountTypes!.find((e) => e.id === id);
		return type?.code;
	};

	const fetchDiscountTypes = async () => {
		try {
			const result =
				(await PromotionApiService.getPromotionType()) as IApiResponse<
					IPromotionType[]
				>;
			setLoading(false);
			if (result.succeeded) {
				setDiscountTypes(result.data || []);
			}
		} catch (error) {}
	};
	const fetchPromotionDetail = async (id: string) => {
		try {
			const result = await PromotionApiService.getPromotionById(id);
			if (result.succeeded) {
				setPromotion(result.data);
				if (!isEmpty(result.data)) {
					setDescription(result.data?.description || '');
					form.reset({
						id: result.data?.id,
						bannerUrl: result.data?.bannerUrl,
						branchId: result.data?.branchId,
						description: result.data?.description,
						discountAmount: result.data?.discountAmount,
						discountPercentage: result.data?.discountPercentage,
						endDateUtc: result.data?.endDateUtc
							? toTimeZone(result.data?.endDateUtc).format('MM/DD/YYYY')
							: '',
						isCumulative: result.data?.isCumulative,
						isEnabled: result.data?.isEnabled,
						limitationTimes: result.data?.limitationTimes,
						limitationType: result.data?.limitationType,
						maximumDiscountAmount: result.data?.maximumDiscountAmount,
						maximumDiscountedQuantity: result.data?.maximumDiscountedQuantity,
						name: result.data?.name,
						note: result.data?.note,
						promotionTypeCode: result.data?.promotionTypeCode,
						promotionTypeId: result.data?.promotionTypeId,
						requiresCouponCode: result.data?.requiresCouponCode,
						shopId: result.data?.shopId,
						startDateUtc: toTimeZone(result.data?.startDateUtc).format(
							'MM/DD/YYYY'
						),
						usePercentage: result.data?.usePercentage,
					});
				}
			}
			const resultRequirement =
				(await PromotionApiService.getPromotionRequirement({
					promotionId: id,
				})) as IApiResponse<IRequirement[]>;
			if (resultRequirement.succeeded) {
				setRequirement(resultRequirement.data || []);
			}
		} catch (error) {}
	};
	const onDeletePromotion = async () => {
		setLoading(true);
		try {
			await PromotionApiService.updatePromotionRequirement({
				promotionId: id,
				requirements: [],
			});
			await PromotionApiService.updatePromotionItemCategory({
				promotionId: id,
				itemCategoryIds: [],
			});
			await PromotionApiService.updatePromotionItem({
				promotionId: id,
				itemIds: [],
			});
			await PromotionApiService.updatePromotionCoupon({
				promotionId: id,
				couponCodes: [],
			});

			const result = await PromotionApiService.deletePromotion(id);
			if (result.succeeded) {
				setLoading(false);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.promotionDetail.messageDeletePromotionSuccess))
				);
				goBack();
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getRequirementId = (type: number) => {
		let id = requirement.find((element) => element.type === type)?.id;
		return id;
	};

	const submitForm = async (formData: IPromotionField) => {
		setLoading(true);
		if (newBanner) {
			const uploadResult = (await UploadBaseService.uploadImage([
				newBanner,
			])) as IApiResponse<IFileUploadResponse[]>;
			if (uploadResult.succeeded) {
				form.setValue('bannerUrl', uploadResult.data![0].imageUrl);
			} else {
				AlertHelper.showError(uploadResult);
				return;
			}
		}
		const data: Partial<IPromotion> = {
			id: isAdd() ? undefined : id,
			name: formData.name,
			description: description || '',
			promotionTypeId: formData.promotionTypeId,
			usePercentage: formData.usePercentage,
			discountPercentage: formData.usePercentage
				? formData.discountPercentage
				: undefined,
			discountAmount: formData.usePercentage
				? undefined
				: formData.discountAmount,
			maximumDiscountAmount: formData.usePercentage
				? formData.maximumDiscountAmount
				: undefined,
			startDateUtc: moment(form.watch('startDateUtc')).toDate(),
			endDateUtc: form.watch('endDateUtc')
				? moment(form.watch('endDateUtc')).toDate()
				: undefined,
			requiresCouponCode: formData.requiresCouponCode,
			isCumulative: formData.isCumulative,
			limitationType: formData.limitationType,
			limitationTimes:
				formData.limitationType === 0 ? undefined : formData.limitationTimes,
			isEnabled: formData.isEnabled,
			bannerUrl: form.watch('bannerUrl'),
			branchId: currentBranch?.id,
		};
		const requirementData: IRequirementsRequest = {
			promotionId: isAdd() ? undefined : id,
			requirements: isAdd() ? [] : isVisitRequirement ? [] : requirement,
		};
		if (formData.AssignedToCustomerGroup) {
			formData.requirementHaveNotVisitedIn &&
				requirementData.requirements.push({
					type: 1,
					value: formData.requirementHaveNotVisitedIn.toString(),
					id: getRequirementId(1),
				});
			formData.requirementHaveBirthdayIn &&
				requirementData.requirements.push({
					type: 2,
					value: formData.requirementHaveBirthdayIn,
					id: getRequirementId(2),
				});
			formData.requirementReviewGroup &&
				requirementData.requirements.push({
					type: 3,
					value: formData.requirementReviewGroup,
					id: getRequirementId(3),
				});
			formData.requirementCustomerRank &&
				requirementData.requirements.push({
					type: 4,
					value: formData.requirementCustomerRank,
					id: getRequirementId(4),
				});
		}
		if (formData.HadSpentAnAmount) {
			requirementData.requirements.push({
				type: 5,
				value: formData.requiredSpentAmount?.toString() || '',
				id: getRequirementId(5),
			});
		}
		if (formData.isReturnMoreThan) {
			requirementData.requirements.push({
				type: 6,
				value: formData.nTimesReturn?.toString() || '',
				id: getRequirementId(6),
			});
		}
		if (formData.isAllOfTheseServices && !isEmpty(requirementAllOfServices)) {
			requirementData.requirements.push({
				type: 10,
				value: requirementAllOfServices!,
				id: getRequirementId(10),
			});
		}
		if (formData.isOneOfTheseServices && !isEmpty(requirementOneOfServices)) {
			requirementData.requirements.push({
				type: 11,
				value: requirementOneOfServices!,
				id: getRequirementId(11),
			});
		}
		let response = isAdd()
			? ((await PromotionApiService.addPromotion(
					data
			  )) as IApiResponse<IPromotion>)
			: ((await PromotionApiService.editPromotion(
					data
			  )) as IApiResponse<IPromotion>);

		if (response?.succeeded) {
			let response2 = await PromotionApiService.updatePromotionRequirement({
				...requirementData,
				promotionId: response.data!.id,
			});
			let couponRes;
			let itemRes;
			let itemCategoryRes;
			if (isAdd()) {
				const promotionCoupon: Partial<IPromotionCouponRequest> = {
					promotionId: response.data!.id,
					couponCodes: coupons.map((e) => e.couponCode),
				};
				const promotionItem: Partial<IPromotionItemRequest> = {
					promotionId: response.data!.id,
					itemIds: services.map((service) => service.id),
				};
				const promotionItemCategory: Partial<IPromotionItemCategoryRequest> = {
					promotionId: response.data!.id,
					itemCategoryIds: categories.map((category) => category.id),
				};
				if (!isEmpty(promotionCoupon.couponCodes)) {
					couponRes = await PromotionApiService.updatePromotionCoupon(
						promotionCoupon
					);
				}
				if (!isEmpty(promotionItem.itemIds)) {
					itemRes = await PromotionApiService.updatePromotionItem(
						promotionItem
					);
				}
				if (!isEmpty(promotionItemCategory.itemCategoryIds)) {
					itemCategoryRes =
						await PromotionApiService.updatePromotionItemCategory(
							promotionItemCategory
						);
				}
			}
			if (response2.succeeded) {
				AlertHelper.showSuccess(
					isAdd()
						? I18n.t(
								_t(translations.promotionDetail.messageAddPromotionSuccess)
						  )
						: I18n.t(
								_t(translations.promotionDetail.messageUpdatePromotionSuccess)
						  )
				);
				setLoading(false);
				goBack();
			} else {
				setLoading(false);
				const error = response2 as IErrorResponse;
				AlertHelper.showError(error);
				history.push(`/promotions/edit/${response.data!.id}`);
			}
			if (couponRes && !couponRes?.succeeded) {
				const error = couponRes as IErrorResponse;
				AlertHelper.showError(error);
				history.push(`/promotions/edit/${response.data!.id}`);
			}
			if (itemRes && !itemRes?.succeeded) {
				const error = itemRes as IErrorResponse;
				AlertHelper.showError(error);
				history.push(`/promotions/edit/${response.data!.id}`);
			}
			if (itemCategoryRes && !itemCategoryRes?.succeeded) {
				const error = itemCategoryRes as IErrorResponse;
				AlertHelper.showError(error);
				history.push(`/promotions/edit/${response.data!.id}`);
			}
		} else {
			setLoading(false);
			const error = response as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	useEffect(() => {
		setLoading(true);
		const ac = new AbortController();
		fetchDiscountTypes();
		if (!isAdd()) {
			fetchPromotionDetail(id);
		}
		return () => {
			setLoading(false);
			ac.abort();
		};
	}, []);

	const tabList = useMemo(() => {
		let list = [
			I18n.t(_t(translations.promotionDetail.info)),
			I18n.t(_t(translations.promotionDetail.requirements)),
		];
		if (getCodeOfPromotionType(discountType) === 'AssignedToServices') {
			list = [
				I18n.t(_t(translations.promotionDetail.info)),
				I18n.t(_t(translations.promotionDetail.requirements)),
				I18n.t(_t(translations.promotionDetail.appliedToServices)),
			];
		}
		if (getCodeOfPromotionType(discountType) === 'AssignedToCategories') {
			list = [
				I18n.t(_t(translations.promotionDetail.info)),
				I18n.t(_t(translations.promotionDetail.requirements)),
				I18n.t(_t(translations.promotionDetail.appliedToCategories)),
			];
		}
		if (requiresCouponCode) {
			list.push(I18n.t(_t(translations.promotionDetail.coupon)));
		}
		return list;
	}, [discountType, requiresCouponCode]);

	const tabPanels = useMemo(() => {
		let list = [
			<PromotionInfo
				currentPromotion={promotion}
				discountTypes={discountTypes}
				description={description}
				setDescription={(value) => setDescription(value)}
				setNewBanner={(value) => setNewBanner(value)}
			/>,
			<Requirements
				isAdd={isAdd()}
				requirements={isAdd() ? undefined : requirement}
				isVisitRequirement={isVisitRequirement}
				setIsVisitRequirement={(value) => setIsVisitRequirement(value)}
			/>,
		];
		if (getCodeOfPromotionType(discountType) === 'AssignedToServices') {
			list = [
				<PromotionInfo
					currentPromotion={promotion}
					discountTypes={discountTypes}
					description={description}
					setDescription={(value) => setDescription(value)}
					setNewBanner={(value) => setNewBanner(value)}
				/>,
				<Requirements
					isAdd={isAdd()}
					requirements={isAdd() ? undefined : requirement}
					isVisitRequirement={isVisitRequirement}
					setIsVisitRequirement={(value) => setIsVisitRequirement(value)}
				/>,
				<AppliedToServices
					isAdd={isAdd()}
					services={services}
					setServices={(value) => setServices(value)}
				/>,
			];
		}
		if (getCodeOfPromotionType(discountType) === 'AssignedToCategories') {
			list = [
				<PromotionInfo
					currentPromotion={promotion}
					discountTypes={discountTypes}
					description={description}
					setDescription={(value) => setDescription(value)}
					setNewBanner={(value) => setNewBanner(value)}
				/>,
				<Requirements
					isAdd={isAdd()}
					requirements={isAdd() ? undefined : requirement}
					isVisitRequirement={isVisitRequirement}
					setIsVisitRequirement={(value) => setIsVisitRequirement(value)}
				/>,
				<AppliedToCategories
					isAdd={isAdd()}
					categories={categories}
					setCategories={(value) => setCategories(value)}
				/>,
			];
		}
		if (requiresCouponCode) {
			list.push(
				<CouponCode
					isAdd={isAdd()}
					couponCode={coupons}
					setCoupons={(value) => setCoupons(value)}
				/>
			);
		}
		return list;
	}, [
		discountTypes,
		discountType,
		requiresCouponCode,
		promotion,
		requirement,
		services,
		categories,
		coupons,
		isVisitRequirement,
	]);

	return (
		<DetailPageLayout<IPromotionField>
			title={I18n.t(
				_t(
					isAdd()
						? translations.promotionDetail.add
						: translations.promotionDetail.edit
				)
			)}
			loading={loading}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			showDelete={!isAdd()}
			onDelete={onDeletePromotion}
			deleteTitle={I18n.t(_t(translations.delete))}
			deleteSubTitle={I18n.t(
				_t(translations.promotionDetail.deleteConfirmPromotion)
			)}
			onClose={goBack}
		>
			{(isAdd() || !isEmpty(promotion)) && (
				<TabView tabs={tabList} tabPanels={tabPanels}></TabView>
			)}
		</DetailPageLayout>
	);
};
