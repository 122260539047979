import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ICloverDevice } from 'models/CloverResponse';
import { CloverActions } from '../actions';

export interface ICloverReducer {
	merchantId?: string;
	code?: string;
	accessToken?: string;
	deviceId?: string;
	devices: ICloverDevice[];
}

const initialState: ICloverReducer = {
	devices: [],
};

const CloverReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(
			CloverActions.setCloverConfig.request,
			(
				state,
				action: PayloadAction<{
					merchantId: string;
					code: string;
				}>
			) => {
				state.merchantId = action.payload.merchantId;
				state.code = action.payload.code;
			}
		)
		.addCase(
			CloverActions.setCloverAccessToken.request,
			(state, action: PayloadAction<string>) => {
				state.accessToken = action.payload;
			}
		)
		.addCase(
			CloverActions.setCloverDeviceId.request,
			(state, action: PayloadAction<string>) => {
				state.deviceId = action.payload;
			}
		)
		.addCase(
			CloverActions.setCloverDevices.request,
			(state, action: PayloadAction<ICloverDevice[]>) => {
				state.devices = action.payload;
				state.deviceId = action.payload[0].serial;
			}
		)
		.addCase(CloverActions.reset.request, (state) => {
			state.devices = [];
			state.deviceId = undefined;
			state.merchantId = undefined;
			state.code = undefined;
			state.accessToken = undefined;
		})
);

export default CloverReducer;
