/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { DetailPageFooter, DetailPageLayout } from 'components/molecules';
import { AlertHelper } from 'helpers';
import { getDayOfWeek } from 'helpers/StringHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import { getInitialWorkingTime } from 'helpers/timeHelper';
import { IApiResponse } from 'models';
import { IBranch, OnlineWorkingHour, WorkingHour } from 'models/IBranch';
import { CloverConfigs } from 'pages/SettingStack/BranchSetting/components/Clover';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { BranchActions } from 'redux/actions';
import { history } from 'routers';
import BranchApiService from 'services/BranchApiService';
import { t, translations } from 'utils';
import {
	BranchConfigs,
	OnlineWorkingTimeConfigs,
	PaymentConfigs,
	TaxConfigs,
	WorkingTimeConfigs,
} from './components';
import { UpdateBranchFormField } from './components/IConfigBaseProps';
import OnlineBooking from './components/OnlineBooking';
import PayrollConfiguration from './components/PayrollConfiguration';
import { validationSchema } from './utils';
import { useDispatch } from 'react-redux';

export const BranchSetting = () => {
	// const { currentBranch } = useAppSelector((state) => state.BranchReducer);
	const [currentBranch, setCurrentBranch] = useState<IBranch>();
	const [getByIdLoading, setGetByIdLoading] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isInitial, setIsInitial] = useState<boolean>(true);

	const dispatch = useDispatch()

	const branchAction = useAppSelector(
		(state) => state.ReduxActionReducer['BRANCH']
	);
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as {
		merchantId?: string;
		code?: string;
	};
	const { id }: { id: string } = useParams();

	const error = useAppSelector((state) => state.ErrorReducer.error);
	const form = useForm<UpdateBranchFormField>({
		defaultValues: currentBranch as DefaultValues<UpdateBranchFormField>,
		resolver: yupResolver(validationSchema),
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	useEffect(() => {
		// Handle Call API Get Branch By Id
		getDataBranchById();
	}, [id]);

	// useEffect(() => {
	// 	dispatch(BranchActions.getBranchById.request(id));
	// }, []);

	useEffect(() => {
		if (branchAction === BranchActions.getBranchById.requestName) {
			setGetByIdLoading(true);
			return;
		} else if (
			branchAction === BranchActions.getBranchById.successName ||
			branchAction === BranchActions.getBranchById.failedName
		) {
			setGetByIdLoading(false);
			setIsInitial(false);
			return;
		}
		if (!isInitial) {
			if (branchAction === BranchActions.updateBranch.requestName) {
				setIsLoading(true);
				return;
			} else {
				setIsLoading(false);
				if (branchAction === BranchActions.updateBranch.successName) {
					AlertHelper.showSuccess(
						t(translations.branchSetting.updateBranchSuccess)
					);
					if (queries.merchantId) {
						history.replace('/menu');
					} else {
						history.goBack();
					}
				} else if (branchAction === BranchActions.updateBranch.failedName) {
					AlertHelper.showError(error!);
				}
			}
		}
	}, [branchAction]);

	const getDataBranchById = async () => {
		try {
			// Start loading
			setGetByIdLoading(true);

			const res: IApiResponse<IBranch> = await BranchApiService.getBranchById(
				id
			);

			if (res.succeeded && res.data) {
				// Update the form and context data
				setCurrentBranch(res.data);
				form.reset(res.data as DefaultValues<UpdateBranchFormField>);
				setGetByIdLoading(false);
			} else {
				setGetByIdLoading(false);
				AlertHelper.showError(res);
			}
		} catch (error) {
			console.error('Error fetching branch data:', error);
			setGetByIdLoading(false);
		}
	};

	const updateBranch = async (data: IBranch) => {
		setGetByIdLoading(true);
		try {
			const res: IApiResponse<IBranch> = await BranchApiService.updateBranch(
				data
			);
			if (res.succeeded && res.data) {
				dispatch(BranchActions.updateBranch.success(res.data));
				setGetByIdLoading(false);
				AlertHelper.showSuccess(
					t(translations.branchSetting.updateBranchSuccess)
				);
				getDataBranchById();
			} else {
				AlertHelper.showError(res);
				setGetByIdLoading(false);
			}
		} catch (error) {
			console.error('Error fetching branch data:', error);
			setGetByIdLoading(false);
		}
	};

	const submitForm = async (data: UpdateBranchFormField) => {
		const workingHours = getWorkingTimes(data);
		const onlineBookingTimes = getWorkingTimes(data, true);
		const branchData: IBranch = {
			...currentBranch!,
			name: data.name,
			address: data.address,
			phone: data.phone,
			taxCode: data.taxCode,
			taxPercent: data.taxPercent,
			timeBlockInterval: data.timeBlockInterval,
			defaultEmployeeCommission: data.defaultEmployeeCommission,
			location: currentBranch?.location || {
				longitude: 0,
				latitude: 0,
			},
			timeToAlertCheckOut: data.timeToAlertCheckOut,
			lateArrivalTimeAllowed: data.lateArrivalTimeAllowed,
			cardChargeFlatRate: Number.parseFloat(data.cardChargeFlatRate),
			cardChargePercent: Number.parseFloat(data.cardChargePercent),
			googleReviewUrl: data.googleReviewUrl,
			yelpReviewlUrl: data.yelpReviewlUrl,
			workingHours: workingHours,
			onlineBookingTimes: onlineBookingTimes,
			technicianSelectionWhenBookingOnline:
				data.technicianSelectionWhenBookingOnline,
			technicianSelectionWhenCheckInByTablet:
				data.technicianSelectionWhenCheckInByTablet,
			showServicePriceWhenBookingOnline: data.showServicePriceWhenBookingOnline,
			discountBeforeTax: data.discountBeforeTax,
			assignTechnicianToBooking: data.assignTechnicianToBooking,
			paymentReferenceCodeRequiring: data.paymentReferenceCodeRequiring,
			allowTimeBeforeBookingOnline: data.allowTimeBeforeBookingOnline,
			calculateTurnMethod: data.calculateTurnMethod,
			valuePerTurn: data.valuePerTurn,
			displayDurationOnPortal: data.displayDurationOnPortal,
			displayEmployeesOnPortal: data.displayEmployeesOnPortal,
			checkEmployeeCodeWhenCheckout: data.checkEmployeeCodeWhenCheckout,
		};
		await updateBranch(branchData);
	};

	const getWorkingTimes = (
		formData: UpdateBranchFormField,
		online = false
	): (WorkingHour & OnlineWorkingHour)[] => {
		//clone the current working times
		const currentWorkingTimes = Object.assign(
			[],
			online ? currentBranch?.onlineBookingTimes : currentBranch?.workingHours
		) as (WorkingHour & OnlineWorkingHour)[];

		//Get the new "switch on" working times
		const newWorkingTimes: Partial<WorkingHour & OnlineWorkingHour>[] = [];
		for (let i = 0; i < 7; ++i) {
			const day = getDayOfWeek(i)
				.replaceAll(' ', '')
				.toLowerCase() as keyof UpdateBranchFormField;
			if (
				formData[
					`${day}${online ? 'Online' : ''}Check` as keyof UpdateBranchFormField
				]
			) {
				const workingTime = formData[
					`${day}${online ? 'Online' : ''}` as keyof UpdateBranchFormField
				] as string[];
				// const nextDate = this.isNextDate(workingTime);
				const data = online
					? {
							// onlineBookingTimeStartAt: momentTz
							// 	.tz(
							// 		moment(workingTime[0]).format('YYYY-MM-DDTHH:mm'),
							// 		currentBranch!.localTimeZone!
							// 	)
							// 	.utc()
							// 	.format('YYYY-MM-DDTHH:mm'),
							// onlineBookingTimeEndAt: momentTz
							// 	.tz(
							// 		moment(workingTime[1]).format('YYYY-MM-DDTHH:mm'),
							// 		currentBranch!.localTimeZone!
							// 	)
							// 	.utc()
							// 	.format('YYYY-MM-DDTHH:mm'),\
							onlineBookingTimeStartAt: getInitialWorkingTime(workingTime[0])
								.utc()
								.format('YYYY-MM-DDTHH:mm'),
							onlineBookingTimeEndAt: getInitialWorkingTime(workingTime[1])
								.utc()
								.format('YYYY-MM-DDTHH:mm'),
					  }
					: {
							workingTimeStartAt: getInitialWorkingTime(workingTime[0])
								.utc()
								.format('YYYY-MM-DDTHH:mm'),
							workingTimeEndAt: getInitialWorkingTime(workingTime[1])
								.utc()
								.format('YYYY-MM-DDTHH:mm'),
					  };

				newWorkingTimes.push({
					days: i.toString(),
					...data,
					breakingTimeEndAt: undefined,
					breakingTimeStartAt: undefined,
				});
			}
		}
		//remove the "switch off" times from current
		const cleanCurrentWorkingTimes = currentWorkingTimes.filter((element) => {
			const day = getDayOfWeek(Number.parseInt(element.days!))
				.replaceAll(' ', '')
				.toLowerCase();
			if (
				formData[
					`${day}${online ? 'Online' : ''}Check` as keyof UpdateBranchFormField
				]
			) {
				return true;
			}
			return false;
		});
		//update or add to current working times
		for (let i = 0; i < 7; ++i) {
			//if the day exist
			const currentIndex = cleanCurrentWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			const newIndex = newWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			if (currentIndex !== -1) {
				//update the working time;
				cleanCurrentWorkingTimes[currentIndex] = {
					...cleanCurrentWorkingTimes[currentIndex],
					...newWorkingTimes[newIndex],
				};
			} else if (newIndex !== -1) {
				//add new working time;
				cleanCurrentWorkingTimes.push(newWorkingTimes[newIndex]);
			}
		}
		return cleanCurrentWorkingTimes;
	};

	return (
		// <LoadingProvider loading={getByIdLoading}>
		//   <div className={clsxm("w-full flex flex-col h-full overflow-hidden")}>
		//     <div className={clsxm("py-16 pt-8 my-16 w-full px-16 grid gap-y-8")}>
		//     <Header title={t(translations.setting.branchSetting)} />
		//       {!getByIdLoading && (
		//         <FormProvider {...form}>
		//           <BranchConfigs isLoading={isLoading} />
		//           <TaxConfigs isLoading={isLoading} />
		//           <PaymentConfigs isLoading={isLoading} />
		//           <WorkingTimeConfigs isLoading={isLoading} />
		//           <OnlineWorkingTimeConfigs isLoading={isLoading} />
		//           <DetailPageFooter onSave={form.handleSubmit(submitForm)} loading={isLoading} />
		//         </FormProvider>
		//       )}
		//     </div>
		//   </div>
		// </LoadingProvider>
		<DetailPageLayout<UpdateBranchFormField>
			form={form}
			loading={getByIdLoading}
			onSave={form.handleSubmit(submitForm)}
			title={t(translations.setting.branchSetting)}
			onClose={() => {
				if (queries.merchantId) {
					history.replace('/menu');
				} else {
					history.goBack();
				}
			}}
		>
			{!getByIdLoading && (
				<>
					<BranchConfigs isLoading={isLoading} />
					<OnlineBooking isLoading={isLoading} />
					<div className="grid grid-cols-8 gap-2">
						<TaxConfigs isLoading={isLoading} />
						<PayrollConfiguration isLoading={isLoading} />
					</div>
					<CloverConfigs isLoading={isLoading} />
					<PaymentConfigs isLoading={isLoading} />
					<WorkingTimeConfigs
						isLoading={isLoading}
						currentBranch={currentBranch}
					/>
					<OnlineWorkingTimeConfigs
						isLoading={isLoading}
						currentBranch={currentBranch}
					/>
					<DetailPageFooter
						onSave={form.handleSubmit(submitForm)}
						loading={isLoading}
					/>
				</>
			)}
		</DetailPageLayout>
	);
};
