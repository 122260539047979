import _, { delay, isEmpty } from 'lodash';
import { IBookingDetail, IService } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import {
	CategoryActions,
	CustomerBookingActions,
	ServiceActions,
} from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { CurrencyHelper, TimeHelper, checkIsMobile } from 'helpers';
import React, { createRef, useEffect, useState } from 'react';
import { Icon } from 'components/atoms/Icon';
import { mdiChevronLeft, mdiChevronRight, mdiCheck } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { useAppSelector } from 'helpers/hookHelpers';

enum button {
	LEFT,
	RIGHT,
}

interface ICategoryComponent {
	activeKey: number;
	onChangeTabCategory: (id: number) => void;
	handleChangeButtonCategory: (type: button) => void;
}
interface IServiceComponent {
	activeKey: number;
	onChangeCheckedService: (checked: boolean, service: IService) => void;
}
interface ILayout {
	activeKey: number;
	onChangeTabCategory: (id: number) => void;
	onChangeCheckedService: (checked: boolean, service: IService) => void;
	handleChangeCategoryIndex: (type: button) => void;
}
const useHandleCategory = () => {
	const [startIndex, setStartIndex] = useState<number>(0);
	const [endIndex, setEndIndex] = useState<number>(checkIsMobile() ? 2 : 4);
	const categories = useSelector(
		(state: RootState) => state.CategoryReducer.categories
	);
	const handleLeftButtonItemCategory = () => {
		let endIndexUpdate = endIndex - 1;
		let startIndexUpdate = startIndex - 1;
		if (startIndex !== 0) {
			setEndIndex(endIndexUpdate);
			setStartIndex(startIndexUpdate);
		}
	};
	const handleRightButtonItemCategory = () => {
		let endIndexUpdate = endIndex + 1;
		let startIndexUpdate = startIndex + 1;
		if (endIndex < categories.length) {
			setEndIndex(endIndexUpdate);
			setStartIndex(startIndexUpdate);
		}
	};
	return {
		endIndex,
		startIndex,
		handleLeftButtonItemCategory,
		handleRightButtonItemCategory,
	};
};
const getDimensions = (ele: HTMLDivElement) => {
	const { height } = ele.getBoundingClientRect();
	const offsetTop = ele.offsetTop;
	const offsetBottom = offsetTop + height;

	return {
		height,
		offsetTop,
		offsetBottom,
	};
};
const scrollTo = (ele: Element) => {
	ele.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	});
};

const Layout = (props: ILayout) => {
	const { activeKey, onChangeTabCategory } = props;
	const categories = useSelector(
		(state: RootState) => state.CategoryReducer.categories
	);

	const [categoriesRef, setCategoriesRef] = useState<
		React.RefObject<HTMLDivElement>[]
	>([]);

	useEffect(() => {
		setCategoriesRef((categoriesRef) =>
			Array(categories.length)
				.fill(null)
				.map((_, i) => categoriesRef[i] || createRef())
		);
		onChangeTabCategory(0);
	}, [categories.length]);

	const handleScroll = () => {
		const scrollPosition = window.scrollY + 202;
		const selected = categoriesRef.findIndex((ref) => {
			const ele = ref.current;
			if (ele) {
				const { offsetBottom, offsetTop } = getDimensions(ele);
				return scrollPosition > offsetTop && scrollPosition < offsetBottom;
			}
		});
		if (selected !== -1 && selected !== activeKey) {
			const category = categories[selected];
			const categoryElement = document.getElementById(category.id);
			const categoryTab = document.getElementById('categoryTab');
			if (categoryTab && categoryElement) {
				const leftPos = categoryElement?.offsetLeft;
				setTimeout(() => {
					categoryTab.scrollLeft = leftPos - (checkIsMobile() ? 50 : 100);
				}, 700);
			}
			onChangeTabCategory(selected);
		}
	};
	useEffect(() => {
		handleScroll();
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [activeKey, categoriesRef]);

	return (
		<div className="mb-[500px]">
			{!_.isEmpty(categories) && (
				<CategoryLayout
					activeKey={activeKey}
					onChangeTabCategory={(id) => {
						scrollTo(categoriesRef[id].current!);
					}}
					handleChangeButtonCategory={(buttonType) => {
						if (buttonType === button.LEFT) {
							scrollTo(
								categoriesRef[activeKey === 0 ? 0 : activeKey - 1].current!
							);
						} else {
							scrollTo(
								categoriesRef[
									categories.length > activeKey + 1 ? activeKey + 1 : 0
								].current!
							);
						}
					}}
				/>
			)}
			<div className="mb-4">
				{categories.map((item, index) => {
					return (
						<div
							ref={categoriesRef[index]}
							id={`${index}`}
							className="scroll-m-[200px]"
							key={index}
						>
							<div className="py-4">
								<h4 className="m-0 mb-1 font-semibold text-xl">{item.name}</h4>
							</div>
							<ServiceLayout
								activeKey={index}
								onChangeCheckedService={props.onChangeCheckedService}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};
const CategoryLayout = (props: ICategoryComponent) => {
	const categories = useSelector((state: RootState) =>
		state.CategoryReducer.categories
			.slice()
			.sort((cateA, cateB) => cateA.indexNumber! - cateB.indexNumber!)
	);
	const [tabsRef, setTabsRef] = useState<React.RefObject<HTMLDivElement>[]>([]);

	useEffect(() => {
		setTabsRef((categoriesRef) =>
			Array(categories.length)
				.fill(null)
				.map((_, i) => categoriesRef[i] || createRef())
		);
	}, [categories.length]);

	// useEffect(() => {
	//   if (!isEmpty(tabsRef)) {
	//     delay(() => {
	//       scrollToHorizontal(tabsRef[props.activeKey].current!);
	//     }, 1000);
	//   }
	// }, [props.activeKey]);

	const handleCategories = useHandleCategory();
	return (
		<div className="h-[100px] bg-white sticky z-20 top-[100px] rounded-md shadow-lg">
			<div className="py-7">
				<div className="grid grid-cols-12 relative overflow-hidden w-[calc(100vw-1rem)] md:w-full">
					<div className="flex items-center justify-center col-span-1">
						<div
							className="cursor-pointer"
							onClick={() => {
								props.handleChangeButtonCategory(button.LEFT);
							}}
						>
							<Icon path={mdiChevronLeft} />
						</div>
					</div>
					<div className="col-span-10">
						<div
							id={'categoryTab'}
							className={clsxm(
								'flex items-center overflow-x-auto space-x-8 no-scrollbar'
							)}
						>
							{[
								...Array.from(
									categories
										.slice()
										.sort(
											(categoryA, categoryB) =>
												categoryA.indexNumber! - categoryB.indexNumber!
										)
								),
							].map((i, index) => (
								<div
									ref={tabsRef[index]}
									key={index}
									id={i.id}
									className={clsxm(
										'whitespace-nowrap cursor-pointer items-center',
										index === props.activeKey &&
											'customer-booking-background text-white p-2 rounded-lg'
									)}
									onClick={() => {
										props.onChangeTabCategory(_.toNumber(index));
									}}
								>
									{i.name}
								</div>
							))}
						</div>
					</div>
					<div className="flex items-center justify-center col-span-1">
						<div
							className="cursor-pointer"
							onClick={() => {
								props.handleChangeButtonCategory(button.RIGHT);
							}}
						>
							<Icon path={mdiChevronRight} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const ServiceLayout = (props: IServiceComponent) => {
	const services = useSelector((state: RootState) =>
		state.ServiceReducer.services
			.slice()
			.sort(
				(serviceA, serviceB) => serviceA.indexNumber! - serviceB.indexNumber!
			)
	);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	)!;
	const categories = useSelector((state: RootState) =>
		state.CategoryReducer.categories
			.slice()
			.sort(
				(categoryA, categoryB) =>
					categoryA.indexNumber! - categoryB.indexNumber!
			)
	);
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);

	return (
		<div className="bg-white rounded-md">
			<div className="px-4 py-[35px] md:px-8">
				<div>
					<div className="flex flex-col divide-y divide-background">
						{isEmpty(
							services.filter(
								(e: IService, index: number) =>
									e.itemCategoryId === categories[props.activeKey].id
							)
						) ? (
							<h5 className="m-0 text-center">No data</h5>
						) : (
							services
								.filter(
									(e: IService, index: number) =>
										e.itemCategoryId === categories[props.activeKey].id
								)
								.map((service) => {
									return (
										<div className="flex flex-row gap-2 py-3" key={service.id}>
											<div className="basis-1/6 flex justify-center items-center md:basis-1/12">
												<label className="relative">
													<input
														type="checkbox"
														checked={booking?.bookingDetails
															?.map((x) => x.itemId)
															.includes(service.id)}
														className={clsxm(
															'appearance-none h-10 w-10 rounded-full border-[1px] border-base-300 checked:customer-booking-background cb-input'
														)}
														onChange={(e) =>
															props.onChangeCheckedService!(
																e.target.checked,
																service
															)
														}
													/>
													<Icon
														path={mdiCheck}
														className="absolute top-2 left-2 text-white text-opacity-0 transition cb-mask"
													/>
												</label>
											</div>
											<div className="basis-2/3 md:basis-2/3">
												<h5 className="font-bold text-black break-all -mb-[3px]">
													{service.name}
												</h5>
												{branch?.displayDurationOnPortal && (
													<span className="text-black/40 text-sm">
														{TimeHelper.convertDurationToStringDetail(
															service.duration
														)}
													</span>
												)}
												<div className="flex flex-row">
													<span className="text-black/80 text-sm">
														{service.description}
													</span>
												</div>
											</div>
											{branch?.showServicePriceWhenBookingOnline && (
												<div className="basis-1/12 flex justify-end md:basis-1/4">
													<h5 className="text-black font-semibold">
														{CurrencyHelper.formatPrice(service.price)}
													</h5>
												</div>
											)}
										</div>
									);
								})
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export const SelectService = () => {
	const categories = useSelector((state: RootState) =>
		state.CategoryReducer.categories
			.slice()
			.sort((cateA, cateB) => cateA.indexNumber! - cateB.indexNumber!)
	);
	const services = useAppSelector((state) => state.ServiceReducer.services);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	)!;
	const bookingDetails = booking?.bookingDetails || [];
	const [activeKey, setActiveKey] = useState<number>(0);
	const branchId = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.id
	);
	// useEffect(() => {
	// 	setActiveKey(!_.isEmpty(categories) ? activeKey : '');
	// }, [categories]);

	useEffect(() => {
		dispatch(CategoryActions.getAllCategory.request());
		dispatch(ServiceActions.getAllServices.request());
	}, [branchId]);

	const onChangeTabCategory = (key: number) => {
		setActiveKey(key);
	};

	const updateBookingDetails = (checked: boolean, service: IService) => {
		// check if booking.BookingDetail includes service
		if (checked) {
			const newBookingDetail: Partial<IBookingDetail> = {
				itemId: service.id,
				item: service,
				amount: service.price,
				branchId: branchId,
			};
			newBookingDetails.push(newBookingDetail);
		} else {
			_.remove(
				newBookingDetails,
				(bookingDetail) => bookingDetail.itemId === service.id
			);
		}

		dispatch(
			CustomerBookingActions.updateBooking.request({
				...booking,
				bookingDetails: newBookingDetails,
			})
		);
	};

	const onChangeCheckedService = (checked: boolean, service: IService) => {
		updateBookingDetails(checked, service);
	};

	const handleChangeCategoryIndex = (buttonType: button) => {
		if (buttonType === button.LEFT) {
			setActiveKey(activeKey === 0 ? 0 : activeKey - 1);
		} else {
			setActiveKey(categories.length > activeKey + 1 ? activeKey + 1 : 0);
		}
	};

	const dispatch = useDispatch();
	const newBookingDetails = Array.from(bookingDetails || []);
	if (_.isEmpty(categories) || _.isEmpty(services)) {
		return null;
	}
	return (
		<Layout
			activeKey={activeKey}
			onChangeTabCategory={onChangeTabCategory}
			onChangeCheckedService={onChangeCheckedService}
			handleChangeCategoryIndex={handleChangeCategoryIndex}
		/>
	);
};
