import clsxm from 'clsxs/clsxm';
import { Card, Input, TextArea } from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { DatePicker } from 'components/molecules/DatePicker';
import { AlertHelper, BookingHelper } from 'helpers';
import { isEmpty } from 'lodash';
import { IApiResponse, IErrorResponse, IExpense, IExpenseType } from 'models';
import moment from 'moment';
import { CustomInput } from 'pages/SettingStack/ShopSetting';
import React, { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ExpenseApiService, ExpenseTypeApiService } from 'services';
import { translations, t } from 'utils';
import { ExpenseTypePickerModal } from '../components/ExpenseTypePickerModal';

interface formData {
	date: string;
	type: string;
	description: string;
	amount: number;
	note?: string;
}

export const ExpensesDetailPage = () => {
	const location = useLocation();
	const { id } = useParams() as { id: string };
	const [expense, setExpense] = useState<IExpense>();
	const [selectedExpenseType, setSelectedExpenseType] =
		useState<IExpenseType>();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/report/expenses');
	};

	const form = useForm<formData>({
		reValidateMode: 'onChange',
		mode: 'onChange',
		defaultValues: {
			date: BookingHelper.convertDateRequest(moment().startOf('date')),
		},
	});

	const fetchExpenseDetail = async () => {
		setIsLoading(true);
		try {
			const res = (await ExpenseApiService.getExpenseById(
				id
			)) as IApiResponse<IExpense>;
			if (res.succeeded) {
				setExpense(res.data);
				setSelectedExpenseType(res.data?.expenseType);
				form.reset({
					date: res.data
						? BookingHelper.convertDateRequest(moment(res.data.date))
						: BookingHelper.convertDateRequest(moment().startOf('date')),
					description: res.data?.description,
					amount: res.data?.amount,
					type: res.data?.expenseType?.code,
					note: res.data?.note,
				});
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	};
	const fetchExpenseTypes = async () => {
		setIsLoading(true);
		try {
			const result = (await ExpenseTypeApiService.getAllExpenseTypes({
				pageNumber: 1,
				pageSize: 100,
			})) as IApiResponse<IExpenseType[]>;
			if (result.succeeded) {
				if (!isEmpty(result.data) && isAdd()) {
					setSelectedExpenseType(result.data![0]);
					form.setValue('type', result.data![0].code);
					form.setValue('description', result.data![0].description);
				}
			}
			setIsLoading(false);
		} catch (error) {}
	};

	const submitForm = async (formData: formData) => {
		setIsLoading(true);
		const data: Partial<IExpense> = {
			amount: formData.amount,
			date: formData.date,
			description: formData.description,
			type: formData.type,
			note: formData.note,
		};

		const res = isAdd()
			? await ExpenseApiService.createExpense(data)
			: await ExpenseApiService.updateExpense({ ...expense!, ...data });
		if (res.succeeded) {
			AlertHelper.showSuccess(
				isAdd()
					? t(translations.expenses.createExpenseSuccess)
					: t(translations.expenses.updateExpenseSuccess)
			);
			setIsLoading(false);
			goBack();
		} else {
			setIsLoading(false);
			const error = res as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	const deleteExpense = async () => {
		setIsLoading(true);
		try {
			const result = (await ExpenseApiService.deleteExpense(
				id
			)) as IApiResponse<string>;
			if (result.succeeded) {
				AlertHelper.showSuccess(t(translations.expenses.deleteExpenseSuccess));
				goBack();
			} else {
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error('Network error', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchExpenseTypes();
		if (!isAdd()) {
			fetchExpenseDetail();
		}
	}, []);

	useEffect(() => {
		if (!isEmpty(expense)) {
			form.setValue('type', expense!.expenseType!.code);
			form.setValue('description', expense!.description);
		}
	}, [expense]);

	return (
		<DetailPageLayout<formData>
			title={t(
				isAdd()
					? translations.expenses.addNewExpense
					: translations.expenses.editExpense
			)}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			loading={isLoading}
			showDelete={!isAdd()}
			onDelete={deleteExpense}
			deleteTitle={t(translations.delete)}
			deleteSubTitle={t(translations.confirmation)}
		>
			{isEmpty(selectedExpenseType) ? (
				<></>
			) : (
				(isAdd() || !isEmpty(expense)) && (
					<ExpenseForm selectedExpenseType={selectedExpenseType!} />
				)
			)}
		</DetailPageLayout>
	);
};

const ExpenseForm = (props: { selectedExpenseType: IExpenseType }) => {
	const [isShowTypePicker, setIsShowTypePicker] = useState(false);
	const [selectedExpenseType, setSelectedExpenseType] = useState<IExpenseType>(
		props.selectedExpenseType
	);
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<formData>();
	const selectedDate = watch('date');
	const type = watch('type');
	const description = watch('description');

	const checkIsOther = (type: IExpenseType) => {
		return type.code.toLowerCase().includes('other');
	};
	const [isOthers, setIsOthers] = useState(
		checkIsOther(props.selectedExpenseType)
	);

	useEffect(() => {
		register('type', {
			required: t(translations.expenses.expenseTypeRequired),
		});
	}, [register]);

	useEffect(() => {
		setSelectedExpenseType(props.selectedExpenseType);
	}, [props.selectedExpenseType]);

	return (
		<Card title={t(translations.expenses.title)}>
			<div className="grid gap-y-4">
				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{t(translations.expenses.paymentDate)}
						</span>
					</label>
					<DatePicker
						selectedDate={moment(selectedDate)}
						onSelectDate={(value) =>
							setValue('date', BookingHelper.convertDateRequest(value))
						}
					/>
				</div>
				<div className="grid grid-cols-5 gap-x-4">
					<div>
						<label className="label font-medium ">
							<span className="label-text text-md">
								{t(translations.expenses.expenseType)}
							</span>
						</label>
						<CustomInput
							className={clsxm(
								'caret-transparent cursor-pointer font-bold uppercase border-primary h-[48px]'
							)}
							onClick={() => setIsShowTypePicker(true)}
							value={checkIsOther(selectedExpenseType) ? type : description}
							error={errors.type?.message}
						/>
					</div>
					{isOthers && (
						<Input
							label={t(translations.expenses.otherExpenseType)}
							{...register('description', {
								required: t(translations.expenses.otherExpenseTypeRequired),
							})}
							error={errors.description?.message}
						/>
					)}
					<Input
						label={t(translations.expenses.paymentAmount)}
						{...register('amount', {
							required: t(translations.expenses.paymentAmountRequired),
							min: {
								value: 0,
								message: t(translations.validate.lessThanZero),
							},
						})}
						alignRight
						renderAfter={<>$</>}
						type="number"
						error={errors.amount?.message}
					/>
				</div>
				<TextArea
					label={t(translations.expenses.note)}
					error={errors.note?.message}
					{...register('note')}
				/>
			</div>
			<ExpenseTypePickerModal
				isShowModal={isShowTypePicker}
				onCancel={() => setIsShowTypePicker(false)}
				onChooseType={(type) => {
					setIsOthers(checkIsOther(type));
					setSelectedExpenseType(type);
					setValue('type', type.code);
					setValue('description', type.description);
					setIsShowTypePicker(false);
				}}
				selectedType={selectedExpenseType}
			/>
		</Card>
	);
};
