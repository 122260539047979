import clsxm from 'clsxs/clsxm';
import React from 'react';

interface IReportFieldValueProps {
	title: string;
	value?: string;
	bold?: boolean;
	sub?: boolean;
	middleValue?: string;
	big?: boolean;
	className?: string;
}
export const ReportFieldValue = ({
	title,
	bold,
	sub,
	value,
	middleValue,
	big,
	className,
}: IReportFieldValueProps) => {
	return (
		<div
			className={clsxm(
				`grid grid-cols-${sub || middleValue ? '5' : '2'} my-1 ${
					className || ''
				}`
			)}
		>
			{sub && <div className={clsxm('col-span-1')} />}
			<div
				className={clsxm(
					`${sub ? 'col-span-2' : middleValue ? 'col-span-3' : ''} 
          text-left 
          ${bold ? 'font-bold' : ''}
          `
				)}
			>
				<p
					className={clsxm(`label-text ${big ? 'text-[1rem]' : 'text-md'} m-0`)}
				>
					{title}
				</p>
			</div>
			{middleValue && (
				<div
					className={clsxm(
						`col-span-1 text-center 
          ${bold ? 'font-bold' : ''}  m-0
          `
					)}
				>
					<p
						className={clsxm(
							`label-text ${big ? 'text-[1rem]' : 'text-md'} m-0`
						)}
					>
						{middleValue}
					</p>
				</div>
			)}
			<div
				className={clsxm(
					`${sub ? 'col-span-2' : middleValue ? 'col-span-1' : ''} 
          text-right 
          ${bold ? 'font-bold' : ''}  m-0
          `
				)}
			>
				<p
					className={clsxm(`label-text ${big ? 'text-[1rem]' : 'text-md'} m-0`)}
				>
					{value}
				</p>
			</div>
		</div>
	);
};
