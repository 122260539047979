import { mdiPlus } from '@mdi/js';
import { Button, Container, Icon } from 'components/atoms';
import Table, { ITableRef, IColumnProps } from 'components/atoms/Table';
import { Header } from 'components/molecules/Header';
import { IBranch } from 'models/IBranch';
import React, { useRef } from 'react';
// import { useDispatch } from 'react-redux';
// import { BranchActions } from 'redux/actions';
import { history } from 'routers';
import { t, translations } from 'utils';

export const BranchesManagement = () => {
	const tableRef = useRef<ITableRef>(null);
	// const dispatch = useDispatch();

	const branchNameCol: IColumnProps<IBranch> = {
		header: t(translations.branches.nameCol),
		key: 'name',
	};
	const addressCol: IColumnProps<IBranch> = {
		header: t(translations.branches.addressCol),
		key: 'address',
		renderCell: ({ address }) =>
			`${address?.street || ''} ${address?.city || ''}, ${
				address?.state || ''
			}`,
	};

	const emailCol: IColumnProps<IBranch> = {
		header: t(translations.branches.emailCol),
		key: 'email',
	};
	const phoneCol: IColumnProps<IBranch> = {
		header: t(translations.branches.phoneCol),
		key: 'phone',
	};
	return (
		<Container>
			<Header />
			<Table<IBranch>
				ref={tableRef}
				columns={[branchNameCol, addressCol, emailCol, phoneCol]}
				noDefaultPadding
				queryUrl="/Branch/get-all-branches"
				showSearch
				additionalParams={{
					orderBy: 'name',
				}}
				renderHeader={
					<Button
						title={t(translations.addNew)}
						small
						iconBefore={<Icon path={mdiPlus} />}
						primary
						onClick={() => history.push('/branches-management-detail/add')}
					/>
				}
				onClickRow={(data) => {
					// dispatch(BranchActions.selectBranchManagement.request(data));
					history.push(`/branches-management-detail/edit/${data.id}`);
				}}
			/>
		</Container>
	);
};

export * from './BranchesManagementDetail';
