import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { I18n, _t, translations } from 'utils';
import { IService } from 'models/IServices';

import { ServiceActions, CategoryActions } from 'redux/actions';
import { IItemCategory } from 'models/IItemCategory';
import { AlertHelper, CurrencyHelper, TimeHelper } from 'helpers';
import _, { toNumber } from 'lodash';
import { useHistory } from 'react-router';
import CategoryApiService from 'services/CategoryApiService';
import { IApiResponse, IErrorResponse } from 'models';
import { useAppSelector } from 'helpers/hookHelpers';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';
import { SortableList } from './SortableList';
import ServiceApi from 'services/ServiceApi';
import {
	Button,
	ConfirmModal,
	Container,
	Input,
	Modal,
	Select,
	TimePicker,
} from 'components/atoms';
import { SearchInput } from 'components/molecules';
import { Icon } from 'components/atoms/Icon';
import { mdiMinus, mdiPlus } from '@mdi/js';
import { mdiPencilOutline } from '@mdi/js';
import { mdiDeleteOutline } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { useRef } from 'react';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { IServiceRequest } from 'models/RequestModels';
import moment, { duration } from 'moment';
import { MaskInput } from 'components/atoms/MaskInput';
import { price } from 'redux/selectors/checkout';
import { showAlert, showError, showSuccess } from 'helpers/alertHelper';

interface ISearchBarProps {
	searchTerm?: string;
	onSearch: (text: string) => void;
}
interface ICategoriesProps {
	onSelect: (category: IItemCategory) => void;
	currentCategory?: IItemCategory;
}
interface IListServiceProps {
	currentCategory?: IItemCategory;
}

const ListButton = (props: {
	onEdit: () => void;
	onDelete: () => void;
	isSelected?: boolean;
}) => {
	return (
		<div className="flex justify-between gap-2 items-center">
			<div
				className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
				onClick={() => props.onEdit()}
			>
				<Icon path={mdiPencilOutline} />
			</div>
			<div
				className={clsxm(props.isSelected ? 'text-white' : 'text-[#f0f0f0]')}
			>
				|
			</div>
			<div
				className="cursor-pointer rounded border border-DELETED p-2 bg-white"
				onClick={() => props.onDelete()}
			>
				<Icon path={mdiDeleteOutline} className="text-DELETED" />
			</div>
		</div>
	);
};
const SearchBar = (props: ISearchBarProps) => {
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	return (
		<></>
		// <Row gutter={[16, 16]} style={{ alignItems: 'center', paddingBottom: 20 }}>
		// 	<Col md={12}>
		// 		<Input
		// 			allowClear
		// 			prefix={<SearchOutlined />}
		// 			placeholder={I18n.t(_t(translations.placeHolder.searchService))}
		// 			style={styles.maxWidth}
		// 			value={props.searchTerm}
		// 			onChange={(e) => props.onSearch(`${e.target.value}`)}
		// 		/>
		// 	</Col>
		// 	<Col md={12}>
		// 		<Space
		// 			direction="horizontal"
		// 			size="small"
		// 			style={{ justifyContent: 'flex-end', width: '100%' }}
		// 		>
		// 			<Dropdown
		// 				trigger={['click']}
		// 				overlay={
		// 					<Menu>
		// 						{!_.isEmpty(categories) ? (
		// 							<Menu.Item key="menuNewService">
		// 								<Link to={`/services/add-new`}>
		// 									{I18n.t(_t(translations.services.menuNewService))}
		// 								</Link>
		// 							</Menu.Item>
		// 						) : null}
		// 						<Menu.Item key="menuNewCategory">
		// 							<Link to={`/services/category/add-new`}>
		// 								{I18n.t(_t(translations.services.menuNewCategory))}
		// 							</Link>
		// 						</Menu.Item>
		// 					</Menu>
		// 				}
		// 			>
		// 				<Button icon={<PlusOutlined />} type="primary">
		// 					{I18n.t(_t(translations.addNew))}
		// 				</Button>
		// 			</Dropdown>
		// 		</Space>
		// 	</Col>
		// </Row>
	);
};

const QuickCreateService = () => {
	const selectedCategory = useAppSelector(
		(state) => state.CategoryReducer.selectedCategory
	);
	const [service, setService] = useState<Partial<IServiceRequest>>({
		itemCategoryId: selectedCategory?.id,
	});
	const shopId = useAppSelector((state) => state.ShopReducer.shopId);
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const staffs = useAppSelector((state) => state.EmployeeReducer.employees);

	const dispatch = useDispatch();
	const addServiceToState = (service: IService) =>
		dispatch(ServiceActions.addServiceToState.request(service));

	const createService = async () => {
		if (_.isEmpty(service.name)) {
			showAlert(
				I18n.t(_t(translations.servicesDetail.inputServiceName)),
				'error'
			);
			return;
		}
		if (!service.price) {
			showAlert(I18n.t(_t(translations.servicesDetail.inputPrice)), 'error');
			return;
		}
		if (
			_.toNumber(service.price) !== Math.round(service.price! * 1e2) / 1e2 ||
			(service.supplyPrice &&
				_.toNumber(service.supplyPrice) !==
					Math.round(service.supplyPrice! * 1e2) / 1e2)
		) {
			showAlert(
				'Services price/ supply price can only enter 2 decimal places',
				'error'
			);
			return;
		}
		if (_.toNumber(service.price) < _.toNumber(service.supplyPrice)) {
			showAlert(
				I18n.t(_t(translations.servicesDetail.supplyPriceSmallerThanService)),
				'error'
			);
			return;
		}
		const res = await ServiceApi.addNewService({
			name: `${service?.name}`,
			duration: service.duration || TimeHelper.convertDurationToNumber('01:00'),
			price: service.price || 0,
			supplyPrice: service.supplyPrice,
			itemCategoryId: selectedCategory?.id || '',
			status: 1,
			shopId: shopId!,
			branchIds: [branch?.id!],
			employeeIds: staffs?.map((x) => x.id || '')!,
		});
		if (res.succeeded && res.data) {
			addServiceToState(res.data);
			showSuccess('Create service successfully');
			setService({
				...service,
				name: '',
				itemCategoryId: selectedCategory?.id,
			});
		} else {
			showError(res);
		}
	};
	return (
		<div>
			<div
				className="flex flex-col "
				style={{ width: '-webkit-fill-available' }}
			>
				<div className="flex flex-1 gap-2 ">
					<Input
						onChange={(e) => setService({ ...service, name: e.target.value })}
						defaultValue=""
						label={I18n.t(_t(translations.servicesDetail.serviceName))}
						value={service.name}
					/>
					<div>
						<label className="label font-medium ">
							<span className="label-text text-md">
								{I18n.t(_t(translations.servicesDetail.duration))} (HH:mm)
							</span>
						</label>
						<TimePicker
							minuteStep={5}
							use12Hours={false}
							format={'HH:mm'}
							defaultValue={moment('01:00', 'HH:mm')}
							hideDisabledOptions
							onChange={(e) =>
								setService({
									...service,
									duration: TimeHelper.convertDurationToNumber(
										moment(e.toString()).format('HH:mm')
									),
								})
							}
							leftPopUp
							hideAmPm
						/>
					</div>
				</div>
				<div className="flex gap-2 ">
					<Input
						type="number"
						onChange={(e) =>
							setService({
								...service,
								price: toNumber(e.target.value) || undefined,
							})
						}
						value={service.price}
						label={I18n.t(_t(translations.price))}
						renderBefore={'$'}
						alignRight
					/>
					<Input
						type="number"
						onChange={(e) =>
							setService({
								...service,
								supplyPrice: toNumber(e.target.value) || undefined,
							})
						}
						value={service.supplyPrice}
						label={I18n.t(_t(translations.servicesDetail.supplyPrice))}
						renderBefore={'$'}
						alignRight
					/>
					<div className="pt-8">
						<Button
							title="Save"
							primary
							small
							onClick={createService}
							// className="h-[43px]"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const ListServices = (props: IListServiceProps & { searchTerm?: string }) => {
	const allServices = useAppSelector((state) => state.ServiceReducer.services);
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);

	const selectedCategory = useAppSelector(
		(state) => state.CategoryReducer.selectedCategory
	);
	const [services, setServices] = useState<IService[]>([]);
	const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showQuickService, setShowQuickService] = useState(false);
	const [currentServiceId, setCurrentServiceId] = useState('');

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (props.currentCategory) {
			setServices(
				_.orderBy(
					_.filter(
						allServices,
						(x) => x.itemCategoryId === props.currentCategory?.id
					),
					['indexNumber', 'createdDate']
				)
			);
		}
	}, [allServices, props.currentCategory]);

	useEffect(() => {
		if (!props.searchTerm) {
			setServices(
				_.orderBy(
					_.filter(
						allServices,
						(x) => x.itemCategoryId === props.currentCategory?.id
					),
					['indexNumber', 'createdDate']
				)
			);
		} else {
			const resultByCategory = _.filter(
				allServices,
				(x) =>
					x.itemCategoryId === props.currentCategory?.id &&
					x.name.toLowerCase().includes(props.searchTerm!.toLowerCase())
			);
			const result = _.filter(allServices, (x) =>
				x.name.toLowerCase().includes(props.searchTerm!.toLowerCase())
			);

			if (!_.isEmpty(resultByCategory)) {
				setServices(resultByCategory);
			} else if (!_.isEmpty(result)) {
				const filteredResult = result.filter(
					(x) => categories.map((y) => y.id).includes(x.itemCategoryId) // includes do 1 so category bi xoa
				);
				if (!_.isEmpty(filteredResult)) {
					setServices(result);
					const itemCategoryId = filteredResult[0].itemCategoryId;
					const category = categories.find((x) => x.id === itemCategoryId)!;
					dispatch(CategoryActions.selectCategory.request(category));
				}
			}
		}
	}, [props.searchTerm]);

	const handleDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event;
		if (over) {
			if (active.id !== over.id) {
				const oldIndex = services.map((x) => x.id).indexOf(active.id as string);
				const newIndex = services.map((x) => x.id).indexOf(over.id as string);
				const newListServices = arrayMove(services, oldIndex, newIndex);
				const newListServicesWithIndex = newListServices.map((x, i) => {
					return {
						...x,
						indexNumber: i,
					};
				});
				setServices(newListServicesWithIndex);
				const response = (await ServiceApi.updateServicesIndexes(
					newListServicesWithIndex.map((x) => {
						return {
							id: x.id,
							indexNumber: x.indexNumber,
						};
					})
				)) as IApiResponse<IService[]>;
				if (response.data && !response.failed) {
					dispatch(ServiceActions.updateServices.request(response.data));
				}
			}
		}
	};
	const onDeleteService = async () => {
		setLoading(true);
		try {
			const result = (await ServiceApi.deleteService(
				currentServiceId
			)) as IApiResponse<string>;
			setShowServiceDeleteModal(false);
			setLoading(false);

			if (result.succeeded) {
				let newServices = Array.from(services);
				newServices = newServices.filter((x) => x.id !== result.data);
				setServices(newServices);
				dispatch(ServiceActions.deleteServiceLocal.request(currentServiceId));
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.error(error);
			setShowServiceDeleteModal(false);
		}
	};

	return (
		<div className="flex flex-col gap-3 w-3/5 h-[calc(100vh-250px)] overflow-y-auto">
			<div className="flex justify-between items-center">
				<h3 className="m-0">{props.currentCategory?.name}</h3>
				<div className="flex gap-2">
					<Button
						title={
							!showQuickService ? 'Quick Create Service' : 'Hide Quick Create'
						}
						onClick={() => setShowQuickService(!showQuickService)}
						small
						className="btn-outline btn-primary bg-white"
						iconBefore={<Icon path={!showQuickService ? mdiPlus : mdiMinus} />}
					/>
					{/* <Button
						onClick={() => {
							history.push({
								pathname: `/services/add-new`,
								state: { itemCategoryId: props.currentCategory?.id },
							});
						}}
						small
						className="btn-outline btn-primary bg-white"
						iconBefore={<Icon path={mdiPlus} />}
					>
						{I18n.t(_t(translations.addService))}
					</Button> */}
				</div>
			</div>

			{!_.isEmpty(selectedCategory) && showQuickService && (
				<QuickCreateService />
			)}

			<SortableList<IService>
				data={services}
				onDragEnd={handleDragEnd}
				renderItem={(item, index) => {
					return (
						<div
							onClick={() => {}}
							style={{
								cursor: 'pointer',
								padding: 8,
								borderBottom: '1px solid #f0f0f0',
							}}
							className={
								props.currentCategory && item.id === props.currentCategory.id
									? `list-primary-background`
									: 'list-white-background'
							}
						>
							<div
								className="flex justify-between items-center"
								style={{ width: '-webkit-fill-available' }}
							>
								<div className="flex flex-1 flex-col ">
									<h5 className="m-0 text-black">{item.name}</h5>
									<p className="m-0 font-light text-sm">
										{TimeHelper.convertDurationToStringDetail(item.duration)}
									</p>
								</div>
								<div className="flex justify-end align-middle mr-2 text-black">
									<div>{CurrencyHelper.formatPrice(item.price)}</div>
								</div>
								<ListButton
									onDelete={() => {
										setCurrentServiceId(item.id);
										setShowServiceDeleteModal(true);
									}}
									onEdit={() => history.push(`/services/edit/${item.id}`)}
								/>
							</div>
						</div>
					);
				}}
			/>
			<ConfirmModal
				visible={showServiceDeleteModal}
				title={I18n.t(_t(translations.delete))}
				onPositiveClick={() => {
					onDeleteService();
				}}
				onNegativeClick={() => setShowServiceDeleteModal(false)}
				loading={loading}
				subTitle={I18n.t(_t(translations.servicesDetail.deleteConfirmContent))}
			/>
		</div>
	);
};

const ListCategories = (props: ICategoriesProps & { searchTerm?: string }) => {
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const [listCategories, setListCategories] = useState(categories);

	const history = useHistory();
	const dispatch = useDispatch();

	const handleDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event;
		if (over) {
			if (active.id !== over.id) {
				const oldIndex = listCategories
					.map((x) => x.id)
					.indexOf(active.id as string);
				const newIndex = listCategories
					.map((x) => x.id)
					.indexOf(over.id as string);

				const newCategories = arrayMove(listCategories, oldIndex, newIndex);
				const newCategoriesWithIndex = newCategories.map((x, i) => ({
					...x,
					indexNumber: i,
				}));
				setListCategories(newCategoriesWithIndex);
				const response = (await CategoryApiService.updateServicesIndexes(
					newCategoriesWithIndex.map((x) => {
						return {
							id: x.id,
							indexNumber: x.indexNumber,
						};
					})
				)) as IApiResponse<IItemCategory[]>;
				if (response.data && !response.failed) {
					dispatch(CategoryActions.updateCategories.request(response.data));
				}
			}
		}
	};
	const onEditCategory = (category: IItemCategory) => {
		history.push(`/services/category/edit/${category.id}`);
	};
	useEffect(() => {
		setListCategories(categories);
	}, [categories]);
	const onDeleteCategory = async (category: IItemCategory) => {
		setLoading(true);
		try {
			const result = (await CategoryApiService.deleteCategory(
				category?.id!
			)) as IApiResponse<string>;
			setShowDeleteModal(false);
			if (result.succeeded) {
				const newCategoryList = listCategories.filter(
					(element) => element.id !== result.data!
				);
				setLoading(false);
				dispatch(CategoryActions.getAllCategory.success(newCategoryList));
				props.onSelect && props.onSelect(newCategoryList[0]);
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<div className="w-2/5 h-fit h-[calc(100vh-250px)] overflow-y-auto">
			<SortableList<IItemCategory>
				data={listCategories}
				onDragEnd={handleDragEnd}
				renderItem={(item, index) => {
					return (
						<div
							onClick={() => {
								props.onSelect(item);
								dispatch(CategoryActions.selectCategory.request(item));
							}}
							className={clsxm(
								'cursor-pointer px-3 py-2 rounded-lg border-b border-[#f0f0f0] flex justify-between items-center ',
								props.currentCategory && item.id === props.currentCategory.id
									? `bg-primary`
									: 'bg-white'
							)}
						>
							<div
								className={clsxm(
									props.currentCategory && item.id === props.currentCategory.id
										? 'text-white'
										: 'text-black'
								)}
							>
								{item.name}
							</div>
							<ListButton
								onEdit={() => onEditCategory(item)}
								onDelete={() => setShowDeleteModal(true)}
								isSelected={
									props.currentCategory && item.id === props.currentCategory.id
								}
							/>
						</div>
					);
				}}
			/>
			<ConfirmModal
				visible={showDeleteModal}
				title={I18n.t(_t(translations.delete))}
				onNegativeClick={() => setShowDeleteModal(false)}
				onPositiveClick={() => onDeleteCategory(props.currentCategory!)}
				loading={loading}
				subTitle={I18n.t(_t(translations.categoryDetail.deleteWaringContent))}
			/>
		</div>
	);
};

export const Services = () => {
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);

	const [loading, setLoading] = useState(false);

	const [searchTerm, setSearchTerm] = useState<string>();

	const selectedCategory = useAppSelector(
		(state) => state.CategoryReducer.selectedCategory
	);

	const history = useHistory();

	const dispatch = useDispatch();

	useEffect(() => {
		if (_.isEmpty(selectedCategory) && !_.isEmpty(categories)) {
			dispatch(CategoryActions.selectCategory.request(categories[0]));
		}
	}, [categories]);

	return (
		<Container className="px-2 overflow-hidden bg-white flex flex-col gap-5">
			<div className="flex gap-2 justify-between">
				<div className="w-2/5">
					<SearchInput
						placeholder={I18n.t(_t(translations.placeHolder.searchService))}
						onChange={(e) => setSearchTerm(e.target.value)}
						value={searchTerm}
					/>
				</div>

				<div className=" flex dropdown dropdown-end gap-2">
					{/* <Select<{ id: string; name: string }>
						options={[
							{ id: '1', name: 'New Service' },
							{ id: '2', name: 'New Category' },
						]}
						titleField={'name'}
						keyField={'id'}
						title={I18n.t(_t(translations.addNew))}
						fullWidth
						valueId={'1'}
						onChange={(value) => {
							console.log(value);
						}}
					/> */}
					<Button
						tabIndex={0}
						primary
						title={I18n.t(_t(translations.addNew))}
						small
						iconBefore={<Icon path={mdiPlus} />}
					/>

					<ul
						tabIndex={0}
						className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
					>
						<li
							className="cursor-pointer"
							onClick={() => history.push('/services/add-new')}
						>
							New Service
						</li>
						<div className="divider m-0" />
						<li
							className="cursor-pointer"
							onClick={() => history.push('/services/category/add-new')}
						>
							New Category
						</li>
					</ul>
				</div>
			</div>
			<div className="flex gap-5 justify-between h-[calc(100vh-250px)]">
				<ListCategories
					currentCategory={selectedCategory || categories[0]}
					onSelect={(category) => {}}
					searchTerm={searchTerm}
				/>
				<ListServices
					currentCategory={selectedCategory || categories[0]}
					searchTerm={searchTerm}
				/>
			</div>
		</Container>
	);
};
