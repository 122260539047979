import clsxm from 'clsxs/clsxm';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { Status } from 'models/Base.model';
import { useState } from 'react';
import { t, translations } from 'utils';
import { CATEGORY_COLORS } from 'utils/Colors';

interface IListCategoriesProps {
	onSelect: (id: string) => void;
}

export const Category = (props: {
	active?: boolean;
	label: string;
	index?: number;
	onSelect: () => void;
}) => {
	const color =
		!_.isUndefined(props.index) || props.index
			? CATEGORY_COLORS[props.index] || _.last(CATEGORY_COLORS)
			: 'white';

	return (
		<div
			onClick={() => props.onSelect()}
			style={{ backgroundColor: color }}
			className={clsxm(
				'flex justify-center items-center font-bold cursor-pointer uppercase p-4 min-h-[50px] mx-1 rounded-lg shadow-lg mb-3 ',
				props.active && 'main-gradient-background'
			)}
		>
			{props.label}
		</div>
	);
};

export const ListCategories = (props: IListCategoriesProps) => {
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	const [selectedCategory, setSelectedCategory] = useState('all');

	return (
		<div
			id="categories"
			className="w-full row-span-1 rounded-r-lg pl-4 grid grid-flow-col auto-cols-max overflow-x-auto pt-0 overflow-y-hidden "
		>
			<Category
				active={selectedCategory === 'all'}
				label={t(translations.all)}
				onSelect={() => {
					setSelectedCategory('all');
					props.onSelect('all');
				}}
			/>
			{categories
				.filter((x) => x.status === Status.ACTIVE)
				.map((x, index) => {
					return (
						<Category
							active={selectedCategory === x.id}
							label={x.name}
							index={index}
							onSelect={() => {
								setSelectedCategory(x.id);
								props.onSelect(x.id);
							}}
						/>
					);
				})}
		</div>
	);
};
