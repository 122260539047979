import { ITextMessage, ITextMessageSendRequest } from 'models/ITextMessage';
import { BaseApiService } from 'services/BaseApiService';

class TextMessageApiService extends BaseApiService {
	public getTextMessages = (query?: any) =>
		this.get<ITextMessage[]>(
			`/MarketingTextMessage/get-all-marketing-text-messages`,
			{
				params: query,
			}
		);
	public getTextMessageById = (id: string) =>
		this.get<ITextMessage>(
			`/MarketingTextMessage/get-marketing-text-message/${id}`
		);
	public addTextMessage = (addTextMessageRequest: Partial<ITextMessage>) =>
		this.post<Partial<ITextMessage>, string | ITextMessage>(
			`/MarketingTextMessage/create-marketing-text-message`,
			addTextMessageRequest
		);
	public editTextMessage = (editTextMessageRequest: Partial<ITextMessage>) =>
		this.put<Partial<ITextMessage>, string>(
			`/MarketingTextMessage/update-marketing-text-message`,
			editTextMessageRequest
		);
	public deleteTextMessage = (id: string) =>
		this.delete<string>(
			`/MarketingTextMessage/delete-marketing-text-message/${id}`
		);
	public sendTextMessage = (
		sendTextMessageRequest: Partial<ITextMessageSendRequest>
	) =>
		this.post<Partial<ITextMessageSendRequest>, string>(
			`/MarketingTextMessage/send-marketing-text-messages`,
			sendTextMessageRequest
		);
}

export default new TextMessageApiService();
