import { mdiTrendingDown, mdiTrendingUp } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Icon } from 'components/atoms';
import { CurrencyHelper } from 'helpers';
import { IDashBoardSummary } from 'models';
import { useDashboardContext } from 'pages/Dashboard/context';
import React, { useEffect, useState } from 'react';
import { AdminDashBoardApiService } from 'services';

interface IDataCardProps {
	title: string;
	value: number;
	isMoney?: boolean;
	subValue?: React.ReactNode;
	changePercent: number;
	color?: string;
}

const DataCard = (props: IDataCardProps) => {
	return (
		<div
			className={clsxm('shadow-md rounded-xl')}
			style={{ backgroundColor: props.color || 'white' }}
		>
			<div
				className={clsxm(
					'w-full rounded-xl rounded-br-[300px] py-2 px-4 flex flex-col bg-white'
				)}
			>
				<span>{props.title}</span>
				<span className="font-bold text-lg">
					{props.isMoney
						? CurrencyHelper.formatPrice(props.value)
						: props.value}
					{props.subValue && props.subValue}
				</span>
				<span
					className={clsxm(
						'text-xs flex gap-1',
						props.changePercent < 0 ? 'text-error' : 'text-success'
					)}
				>
					<Icon
						size={0.6}
						path={props.changePercent < 0 ? mdiTrendingDown : mdiTrendingUp}
					/>
					{`${props.changePercent}% previous period`}
				</span>
			</div>
		</div>
	);
};

export const Summary = () => {
	const { timeRange, branch } = useDashboardContext();
	const [data, setData] = useState<IDashBoardSummary>();
	useEffect(() => {
		fetchData();
	}, [timeRange, branch]);
	const fetchData = async () => {
		const response = await AdminDashBoardApiService.getDashboardSummary({
			fromDate: timeRange.fromDate.format('YYYY-MM-DD'),
			toDate: timeRange.toDate.format('YYYY-MM-DD'),
			branchId: branch,
		});
		if (response.succeeded && response.data) {
			setData(response.data);
		}
	};
	return (
		<div className={clsxm('grid gap-2 grid-cols-2 lg:grid-cols-4')}>
			<DataCard
				title="Total Sales"
				isMoney
				value={data?.totalSales.thisPeriod || 0}
				color={'#CFFDBC'}
				changePercent={data?.totalSales.percentChange || 0}
			/>
			<DataCard
				title="Total Appointments"
				value={data?.totalBooking.thisPeriod || 0}
				color={'#FEAF7B'}
				changePercent={data?.totalBooking.percentChange || 0}
			/>
			<DataCard
				title="Avg. Sale"
				value={data?.averageSales.thisPeriod || 0}
				color={'#87CEFA'}
				isMoney
				changePercent={data?.averageSales.percentChange || 0}
			/>
			<DataCard
				title="Customers"
				value={data?.customers.thisPeriod || 0}
				color={'#3240AF'}
				subValue={
					(data?.customers.newCustomers || 0) > 0 && (
						<span className="text-PENDING text-sm font-semibold">{` (${data?.customers.newCustomers} new customers)`}</span>
					)
				}
				changePercent={data?.customers.percentChange || 0}
			/>
		</div>
	);
};
