/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import clsxm from 'clsxs/clsxm';
import {
	Button,
	Card,
	ConfirmModal,
	Icon,
	Switch,
} from 'components/atoms';
import { t, translations } from 'utils';
import { UpdateBranchFormField, IConfigBaseProps } from './IConfigBaseProps';
import Table, { IColumnProps } from 'components/atoms/Table';
import { IPaymentMethod } from 'models';
import { searchPaymentMethods } from 'redux/selectors/search';
import { useAppSelector } from 'helpers/hookHelpers';
import { SearchInput } from 'components/molecules';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { PaymentMethodModal } from './PaymentMethodModal';
import { mdiDeleteOutline, mdiPencilOutline } from '@mdi/js';
import { useDispatch } from 'react-redux';
import { PaymentMethodActions } from 'redux/actions';

interface IPaymentConfigsProps extends IConfigBaseProps {}
export const PaymentConfigs = ({ isLoading }: IPaymentConfigsProps) => {
	const { watch, setValue, getValues } =
		useFormContext<UpdateBranchFormField>();
	const [searchText, setSearchText] = useState<string>('');
	const [showModal, setShowModal] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [selectedPaymentMethod, setSelectedPaymentMethod] =
		useState<IPaymentMethod>();

	const paymentMethods = useAppSelector(
		(state) => (searchText: string) => searchPaymentMethods(state, searchText)
	);

	const onSearchTextChange = useCallback<(text: string) => void>(
		_.debounce<(text: string) => void>((text: string) => {
			setSearchText(text);
		}, 100),
		[]
	);

	const paymentReferenceCodeRequiring = watch('paymentReferenceCodeRequiring');

	const paymentMethodNameCol: IColumnProps<IPaymentMethod> = {
		key: 'id',
		header: t(translations.branchSetting.paymentMethod),
		renderCell: (data) => (
			<p className={clsxm('text-sm px-4 m-0')}>{data.name || ''}</p>
		),
		renderHeader: (
			<div className={clsxm('label px-4')}>
				<p className={clsxm('text-md')}>
					{t(translations.branchSetting.paymentMethod)}
				</p>
			</div>
		),
	};
	const paymentMethodCodeCol: IColumnProps<IPaymentMethod> = {
		key: 'id',
		header: t(translations.branchSetting.paymentMethodCode),
		renderHeader: (
			<div className={clsxm('label px-4')}>
				<p className={clsxm('text-md')}>
					{t(translations.branchSetting.paymentMethodCode)}
				</p>
			</div>
		),
		renderCell: (data) => (
			<p className={clsxm('text-sm px-4 m-0')}>{data.code || ''}</p>
		),
	};
	const paymentMethodDesCol: IColumnProps<IPaymentMethod> = {
		key: 'id',
		header: t(translations.description),
		renderHeader: (
			<div className={clsxm('label px-4')}>
				<p className={clsxm('text-md')}>{t(translations.description)}</p>
			</div>
		),
		renderCell: (data) => (
			<p className={clsxm('text-sm px-4 m-0')}>{data.description || ''}</p>
		),
	};
	const paymentMethodStatusCol: IColumnProps<IPaymentMethod> = {
		key: 'id',
		header: t(translations.status),
		renderHeader: (
			<div className={clsxm('label px-4')}>
				<p className={clsxm('text-md')}>{t(translations.status)}</p>
			</div>
		),
		renderCell: (data) => (
			<p className={clsxm('text-sm px-4 m-0')}>
				{data.status ? t(translations.active) : t(translations.unActive)}
			</p>
		),
	};
	const paymentMethodActionsCol: IColumnProps<IPaymentMethod> = {
		key: 'id',
		header: t(translations.action),
		renderHeader: (
			<div className={clsxm('label px-4')}>
				<p className={clsxm('text-md')}>{t(translations.action)}</p>
			</div>
		),
		headerClassName: 'w-44',
		cellClassName: 'w-44',
		renderCell: (data) => (
			<div className="flex px-4 gap-4 items-center">
				<div
					className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
					onClick={(e) => {
						e.stopPropagation();
						setSelectedPaymentMethod(data);
						setShowModal(true);
					}}
				>
					<Icon path={mdiPencilOutline} className="w-6 h-6" />
				</div>
				<div className="text-[#f0f0f0]">|</div>
				<div
					className="cursor-pointer rounded border border-DELETED p-2 bg-white"
					onClick={(e) => {
						e.stopPropagation();
						setSelectedPaymentMethod(data);
						setShowConfirmModal(true);
					}}
				>
					<Icon path={mdiDeleteOutline} className="text-DELETED w-6 h-6" />
				</div>
			</div>
		),
	};
	const dispatch = useDispatch();
	const onDeletePaymentMethod = () => {
		dispatch(
			PaymentMethodActions.deletePaymentMethod.request(
				selectedPaymentMethod!.id
			)
		);
		setShowConfirmModal(false);
		setSelectedPaymentMethod(undefined);
	};

	return (
		<Card
			title={t(translations.branchSetting.paymentConfig)}
			description={t(translations.branchSetting.subPaymentConfig)}
			accordion={true}		
		>
			<div className={clsxm('grid gap-y-8')}>
				<div className={clsxm('grid grid-cols-4 gap-x-8')}>
					<div className={clsxm('col-span-3')}></div>
					<div className={clsxm('col-span-1 grid grid-cols-1')}>
						<Switch
							defaultChecked={getValues().paymentReferenceCodeRequiring}
							label={t(translations.branchSetting.referenceCodeRequiring)}
							onChange={(value) => {
								setValue('paymentReferenceCodeRequiring', value);
							}}
							value={paymentReferenceCodeRequiring}
							disabled={isLoading}
							fullWidth
						/>
						{!paymentReferenceCodeRequiring && (
							<div>
								<p className={clsxm('text-PENDING')}>
									{t(translations.branchSetting.referenceCodeWarning)}
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={clsxm('grid grid-cols-5 gap-x-8')}>
				<div className={clsxm('col-span-4')}>
					<SearchInput
						onChange={(e) => onSearchTextChange(e.target.value)}
						disabled={isLoading}
					/>
				</div>
				<Button
					title={t(translations.addNew)}
					small
					primary
					onClick={() => {
						setShowModal(true);
					}}
					loading={isLoading}
				/>
			</div>
			<div className={clsxm('h-96')}>
				<Table<IPaymentMethod>
					data={paymentMethods(searchText)}
					columns={[
						paymentMethodNameCol,
						paymentMethodCodeCol,
						paymentMethodDesCol,
						paymentMethodStatusCol,
						paymentMethodActionsCol,
					]}
					// onClickRow={(x) => {
					// 	setSelectedPaymentMethod(x);
					// 	setShowModal(true);
					// }}
					noDefaultPadding
				/>
			</div>
			{showModal && (
				<PaymentMethodModal
					isOpen={showModal}
					onCancel={() => {
						setShowModal(false);
						setSelectedPaymentMethod(undefined);
					}}
					dataPaymentMethod={selectedPaymentMethod}
				/>
			)}
			<ConfirmModal
				visible={showConfirmModal}
				title={t(translations.delete)}
				subTitle={t(translations.branchSetting.confirmDeletePaymentMethod)}
				onPositiveClick={() => {
					onDeletePaymentMethod();
				}}
				onNegativeClick={() => {
					setShowConfirmModal(false);
					setSelectedPaymentMethod(undefined);
				}}
				type="error"
			/>
		</Card>
	);
};
