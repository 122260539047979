import { IApiResponse, IPoolStaff } from 'models';
import { put, takeEvery, call } from 'redux-saga/effects';
import { TodayActions } from 'redux/actions';
import EmployeeApiService from 'services/EmployeeApiService';
import { IErrorResponse } from '../../models/IErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import { IPoolStaffsRequest } from 'models/RequestModels';
import { AlertHelper } from 'helpers';

function* getPoolStaffs(action: PayloadAction<IPoolStaffsRequest>) {
	try {
		const result: IApiResponse<IPoolStaff[]> = yield call(
			EmployeeApiService.getPoolStaffs,
			action.payload
		);
		yield put(TodayActions.getPoolStaffs.success(result.data!));
	} catch (error) {
		yield put(TodayActions.getPoolStaffs.failed(error));
		AlertHelper.showError(error as IErrorResponse);
	}
}
export function* TodayWatcher() {
	yield takeEvery(TodayActions.getPoolStaffs.requestName, getPoolStaffs);
}
