import { EEmployeeTypeCode } from 'models';
import { Login } from 'pages/AuthenticationStack/Login';
import React, { ReactNode } from 'react';
import {
	mdiViewDashboard,
	mdiChartArc,
	mdiCalendar,
	mdiStar,
	mdiChartBar,
	mdiBriefcaseAccountOutline,
	mdiMenu,
	mdiFinance,
	mdiCashClock,
	mdiCardAccountDetails,
	mdiApplicationExport,
	mdiAccountSupervisorCircle,
	mdiBriefcaseSearchOutline,
	mdiCurrencyUsd,
	mdiAirplane,
	mdiCog,
	mdiContactlessPayment,
	mdiBrightnessPercent,
	mdiMessage,
	mdiEmail,
	mdiAlertCircleCheckOutline,
	mdiSourceBranch,
	mdiReceipt,
	mdiAppsBox,
	mdiEqualizer,
	mdiViewList,
	mdiClockOut,
	mdiReceiptTextCheck,
} from '@mdi/js';
import { Icon } from 'components/atoms/Icon';
import _ from 'lodash';
import { Today } from 'pages/Today';
import { MainCalendar } from 'pages/Calendar';
import {
	BranchSetting,
	FinancialReport,
	Holiday,
	HolidayDetail,
	PayrollSummaryReport,
	ReviewManagement,
	ShopSetting,
	Staffs,
	StaffTypeDetailPage,
	StaffTypes,
	CategoryDetail,
	Promotion,
	ClosedOnlineBooking,
	ClosedOnlineBookingDetail,
	BranchesManagementDetail,
	BranchesManagement,
	CloseOutPeriod,
} from 'pages';
import { CheckOut } from 'pages/CheckOut';
import Customer from 'pages/Customer';
import CustomerDetailPage from 'pages/Customer/CustomerDetailPage';
import Sales from 'pages/Sales';
import { Services } from 'pages/Services';
import { ServiceDetail } from 'pages/Services/ServiceDetail';
import { StaffDetail } from 'pages/Staffs/StaffDetail';

import { PayrollDetailReport } from 'pages/PayrollDetailReport';
import { Menu } from 'pages/Menu';
import { Expenses } from 'pages/Expenses';
import { ExpensesDetailPage } from 'pages/Expenses/ExpensesDetailPage';
import { DashBoard } from 'pages/Dashboard';
import { PromotionDetailPage } from 'pages/Promotion/PromotionDetailPage';
import { Settings } from 'pages/SettingStack/Settings';
import { MiniCMSSetting } from 'pages/CMSSetting';
import { GallerySetup } from 'pages/GallerySetup';
import { ContentFeatureServices } from 'pages/CMSSetting/components/ContentFeatureServices';
import { TextMessageDetailPage } from 'pages/TextMessage/TextMessageDetailPage';
import { TextTemplateDetailPage } from 'pages/TextMessage/TextTemplateDetailPage';

import { TemplateDetailPage } from 'pages/EmailBlast/TemplateDetailPage';
import { EmailBlastDetailPage } from 'pages/EmailBlast/EmailBlastDetailPage';
import { EmailBlast } from 'pages/EmailBlast';
import { CustomerInfoPage } from 'pages/Customer/CustomerInfoPage';
import { TextMessage } from 'pages/TextMessage';
import { BillDetail } from 'pages/BillDetail';

import { BillsManagement } from 'pages/BillsManagement';
import { CloseOutDetail } from 'pages/CloseOutStack/CloseOutDetail';
import { LoyaltySetting } from 'pages/LoyaltySetting';
import { LoyaltyRank } from 'pages/LoyaltyRank';
import { LoyaltyRankDetailPage } from 'pages/LoyaltyRank/LoyaltyRankDetailPage';
import { PriceList } from 'pages/PriceList';

// const iconStyle = { fontSize: "20px" };
export interface IRoute {
	name: string;
	path: string;
	component?: React.ComponentType<any>;
	exact?: boolean;
	feature?: string;
	key: string;
	icon?: ReactNode;
	roles?: string[];
	showInMenu?: boolean;
	subMenu?: IRoute[];
	mainMobile?: boolean;
	isDetail?: boolean;
}
const HomeRoute: Partial<IRoute>[] = [
	{
		key: 'today-booking',
		name: 'Today',
		path: '/today',
		showInMenu: true,
		icon: <Icon path={mdiViewDashboard} />,
		component: Today,
		mainMobile: true,
	},
	{
		key: 'Staffs',
		name: 'Staff',
		path: '/staff',
		showInMenu: true,
		icon: <Icon path={mdiBriefcaseAccountOutline} />,
		feature: 'Employee',
		subMenu: [
			{
				key: 'Staff-list',
				name: 'Staff List',
				path: '/staffs',
				component: Staffs,
				icon: <Icon path={mdiBriefcaseAccountOutline} />,
			},
			{
				key: 'staffType',
				name: 'Staff Type',
				path: '/staff-type',
				component: StaffTypes,
				showInMenu: true,
				icon: <Icon path={mdiBriefcaseAccountOutline} />,
				feature: 'StaffType',
				roles: ['Admin', EEmployeeTypeCode.MANAGER],
			},
		],
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
	},
	{
		key: 'Services',
		name: 'Services',
		feature: 'Services',
		path: '/services',
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		showInMenu: true,
		icon: <Icon path={mdiAppsBox} />,
		component: Services,
	},
	{
		key: 'Invoice-Management',
		name: 'Invoice Management',
		feature: 'Invoice Management',
		path: '/bills-management',
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		showInMenu: true,
		icon: <Icon path={mdiReceiptTextCheck} />,
		component: BillsManagement,
	},
	{
		key: 'Dashboard',
		name: 'Dashboard',
		path: '/dashboard',
		showInMenu: false,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		icon: <Icon path={mdiChartArc} />,
		component: DashBoard,
		mainMobile: false,
	},
	{
		key: 'calendar',
		name: 'Calendar',
		path: '/calendar',
		showInMenu: true,
		icon: <Icon path={mdiCalendar} />,
		component: MainCalendar,
		mainMobile: true,
	},
	{
		key: 'priceList',
		name: 'PriceList',
		feature: 'PriceList',
		path: '/price-list',
		showInMenu: false,
		mainMobile: true,
		icon: <Icon path={mdiViewList} />,
		component: PriceList,
	},
	{
		key: 'customer',
		name: 'Customer',
		path: '/customer',
		showInMenu: true,
		mainMobile: true,
		icon: <Icon path={mdiAccountSupervisorCircle} />,
		component: Customer,
	},
	{
		key: 'clockInOut',
		name: 'Clock In/Out',
		feature: 'clockInOut',
		path: '/clockInOut',
		showInMenu: false,
		mainMobile: true,
		icon: <Icon path={mdiClockOut} />,
	},

	{
		key: 'close-out',
		name: 'New Close Out',
		feature: 'New Close Out',
		path: '/close-out',
		component: CloseOutPeriod,
	},
	{
		key: 'sales',
		name: 'Sales',
		path: '/sales',
		showInMenu: true,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		icon: <Icon path={mdiBriefcaseSearchOutline} />,
		component: Sales,
		mainMobile: false,
	},
	{
		key: 'report',
		name: 'Report',
		path: '/report',
		showInMenu: true,
		roles: ['Admin'],
		icon: <Icon path={mdiChartBar} />,
		subMenu: [
			{
				key: 'financial-report',
				name: 'Financial Report',
				path: '/report/financial',
				showInMenu: true,
				roles: ['Admin'],
				component: FinancialReport,
				icon: <Icon path={mdiFinance} />,
			},
			{
				key: 'expenses',
				name: 'Expenses Report',
				path: '/report/expenses',
				showInMenu: true,
				roles: ['Admin'],
				component: Expenses,
				icon: <Icon path={mdiApplicationExport} />,
			},
		],
	},
	{
		key: 'payroll',
		name: 'Pay Roll',
		path: '/payroll',
		showInMenu: true,
		roles: ['Admin'],
		icon: <Icon path={mdiCashClock} />,
		subMenu: [
			{
				key: 'payroll-summary-report',
				name: 'Payroll Summary Report',
				path: '/payroll/payroll-summary',
				showInMenu: true,
				roles: ['Admin'],
				icon: <Icon path={mdiCurrencyUsd} />,
				component: PayrollSummaryReport,
			},
			{
				key: 'payroll-detail-report',
				name: 'Payroll Detail',
				path: '/payroll/payroll-detail',
				showInMenu: true,
				roles: ['Admin'],
				icon: <Icon path={mdiCardAccountDetails} />,
				component: PayrollDetailReport,
			},
		],
	},
	{
		key: 'Marketing',
		name: 'Marketing',
		path: '/marketing',
		showInMenu: true,
		icon: <Icon path={mdiContactlessPayment} />,
		feature: 'Marketing',
		subMenu: [
			{
				key: 'Promotions',
				name: 'Promotions',
				path: '/promotions',
				component: Promotion,

				icon: <Icon path={mdiBrightnessPercent} />,
			},
			{
				key: 'TextMessage',
				name: 'SMS',
				path: '/text-message',
				component: TextMessage,
				icon: <Icon path={mdiMessage} />,
			},
			{
				key: 'Email',
				name: 'Mail Blast',
				path: '/email-blast',
				component: EmailBlast,
				icon: <Icon path={mdiEmail} />,
			},
			{
				key: 'loyalty-rank',
				name: 'Loyalty Rank',
				path: '/loyalty-rank',
				icon: <Icon path={mdiEqualizer} />,
				component: LoyaltyRank,
			},
		],
	},

	{
		key: 'holiday',
		name: 'Holiday',
		path: '/holiday',
		showInMenu: true,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		component: Holiday,
		feature: 'Holiday',
		icon: <Icon path={mdiAirplane} />,
		mainMobile: false,
	},
	{
		key: 'ReviewManagement',
		name: 'Review Management',
		feature: 'Review',
		path: '/review-management',
		component: ReviewManagement,
		showInMenu: true,
		icon: <Icon path={mdiStar} />,
		mainMobile: false,
	},
	{
		key: 'Settings',
		name: 'Settings',
		feature: 'Settings',
		path: '/settings',
		component: Settings,
		showInMenu: true,
		icon: <Icon path={mdiCog} />,
		mainMobile: false,
		subMenu: [
			// {
			// 	key: 'branch-setting',
			// 	name: 'Shop Setting',
			// 	path: '/settings/branch',
			// 	roles: ['Admin'],
			// 	component: BranchSetting,
			// },
			{
				key: 'shop-setting',
				name: 'Company Setting',
				path: '/settings/shop',
				roles: ['Admin'],
				component: ShopSetting,
			},
			{
				key: 'mini-cms',
				name: 'Mini CMS',
				path: '/settings/mini-cms',
				roles: ['Admin'],
				component: MiniCMSSetting,
			},
			{
				key: 'mini-cms',
				name: 'Mini CMS',
				path: '/settings/mini-cms/featured-services',
				roles: ['Admin'],
				component: ContentFeatureServices,
			},

			{
				key: 'branches-management',
				name: 'Salons Management',
				path: '/settings/branches-management',
				roles: ['Admin', EEmployeeTypeCode.MANAGER],
				icon: <Icon path={mdiSourceBranch} />,
				showInMenu: false,
				component: BranchesManagement,
			},
			
			{
				key: 'close-online-booking',
				name: 'Close Online Appointments',
				path: '/settings/closed-online-appointments',
				roles: ['Admin', EEmployeeTypeCode.MANAGER],
				icon: <Icon path={mdiAlertCircleCheckOutline} />,
				showInMenu: false,
				component: ClosedOnlineBooking,
			},
			{
				key: 'online-booking-detail',
				name: 'Online Appointments Detail',
				path: '/settings/closed-online-appointments/add',
				roles: ['Admin', EEmployeeTypeCode.MANAGER],
				component: ClosedOnlineBookingDetail,
			},
			{
				key: 'online-booking-detail',
				name: 'Online Appointments Detail',
				path: '/settings/closed-online-appointments/edit/:id',
				roles: ['Admin', EEmployeeTypeCode.MANAGER],
				component: ClosedOnlineBookingDetail,
			},
		],
	},
	{
		key: 'menu',
		name: 'Menu',
		path: '/menu',
		showInMenu: false,
		icon: <Icon path={mdiMenu} />,
		component: Menu,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		mainMobile: false,
	},
];

export function getSubRoutes() {
	const subRoutes: IRoute[] = [];
	for (const route of HomeRoute) {
		if (route.subMenu) {
			for (const subRoute of route.subMenu) {
				subRoutes.push(subRoute);
			}
		}
	}
	return subRoutes;
}

const DetailRoute: IRoute[] = [
	// {
	// 	key: 'branch-setting',
	// 	name: 'Branch Setting',
	// 	path: '/settings/branch',
	// 	roles: ['Admin'],
	// 	component: BranchSetting,
	// },
	// {
	// 	key: 'Search',
	// 	name: 'Search',
	// 	feature: 'Search',
	// 	path: '/search',
	// 	component: Search,
	// },
	{
		key: 'branchs-management-detail-add',
		name: 'Salons Management Detail',
		path: '/branches-management-detail/add',
		// roles: ['Admin', EEmployeeTypeCode.MANAGER],
		component: BranchesManagementDetail,
	},
	{
		key: 'branchs-management-detail-edit',
		name: 'Salons Management Detail',
		path: '/branches-management-detail/edit/:id',
		// roles: ['Admin', EEmployeeTypeCode.MANAGER],
		component: BranchSetting,
	},
	{
		key: 'CategoryDetailAddNew',
		name: 'CategoryDetail',
		feature: 'Category',
		path: '/services/category/add-new',
		component: CategoryDetail,
	},
	{
		key: 'CategoryDetailEdit',
		name: 'CategoryDetail',
		feature: 'Category',
		path: '/services/category/edit/:id',
		component: CategoryDetail,
	},
	{
		key: 'ServiceDetailEdit',
		name: 'ServiceDetail',
		feature: 'Services',
		path: '/services/edit/:id',
		component: ServiceDetail,
	},
	{
		key: 'ServiceDetailAddNew',
		name: 'ServiceDetail',
		feature: 'Services',
		path: '/services/add-new',
		component: ServiceDetail,
	},
	{
		key: 'StaffDetailAddNew',
		name: 'StaffDetail',
		feature: 'Staffs',
		path: '/staffs/add-new',
		component: StaffDetail,
	},
	{
		key: 'StaffDetailEdit',
		name: 'StaffDetail',
		feature: 'Staffs',
		path: '/staffs/edit/:id',
		component: StaffDetail,
	},
	{
		key: 'CheckOut',
		name: 'CheckOut',
		feature: 'CheckOut',
		path: '/check-out/:id',
		component: CheckOut,
	},
	{
		key: 'StaffTypeDetailAddNew',
		name: 'StaffTypeDetail',
		feature: 'Staffs',
		path: '/staff-type/add-new',
		component: StaffTypeDetailPage,
	},
	{
		key: 'StaffTypeDetailEdit',
		name: 'StaffTypeDetail',
		feature: 'Staffs',
		path: '/staff-type/edit/:id',
		component: StaffTypeDetailPage,
	},
	{
		key: 'CustomerDetailAddNew',
		name: 'CustomerDetail',
		feature: 'Customer',
		path: '/customer/add-new',
		component: CustomerDetailPage,
	},
	{
		key: 'CustomerDetailEdit',
		name: 'CustomerDetail',
		feature: 'Customer',
		path: '/customer/edit/:id',
		component: CustomerDetailPage,
	},

	{
		key: 'CustomerInformationDetail',
		name: 'CustomerDetail',
		feature: 'Customer',
		path: '/customer/details/:id',
		component: CustomerInfoPage,
	},
	{
		key: 'BillDetail',
		name: 'Invoice Detail',
		feature: 'Bill Detail',
		path: '/bills/:id',
		component: BillDetail,
	},
	{
		key: 'setting',
		name: 'Setting',
		path: '/setting/gallery-setup',
		roles: ['Admin'],
		component: GallerySetup,
	},

	{
		key: 'TextTemplateDetailAddNew',
		name: 'TextTemplateDetail',
		feature: 'Template',
		path: '/text-template/add-new',
		component: TextTemplateDetailPage,
	},
	{
		key: 'TextTemplateDetailEdit',
		name: 'TextTemplateDetail',
		feature: 'Template',
		path: '/text-template/edit/:id',
		component: TextTemplateDetailPage,
	},
	{
		key: 'TextMessageDetailAddNew',
		name: 'TextMessageDetail',
		feature: 'Message',
		path: '/text-message/add-new',
		component: TextMessageDetailPage,
	},
	{
		key: 'TextMessageDetailEdit',
		name: 'TextMessageDetail',
		feature: 'Message',
		path: '/text-message/edit/:id',
		component: TextMessageDetailPage,
	},
	{
		key: 'TemplateDetailAddNew',
		name: 'TemplateDetail',
		feature: 'Template',
		path: '/template/add-new',
		component: TemplateDetailPage,
	},
	{
		key: 'TemplateDetailEdit',
		name: 'TemplateDetail',
		feature: 'Template',
		path: '/template/edit/:id',
		component: TemplateDetailPage,
	},
	{
		key: 'EmailBlastDetailAddNew',
		name: 'EmailBlastDetail',
		feature: 'EmailBlast',
		path: '/email-blast/add-new',
		component: EmailBlastDetailPage,
	},
	{
		key: 'EmailBlastDetailEdit',
		name: 'EmailBlastDetail',
		feature: 'EmailBlast',
		path: '/email-blast/edit/:id',
		component: EmailBlastDetailPage,
	},
	{
		key: 'PromotionDetailAddNew',
		name: 'PromotionDetail',
		feature: 'Promotion',
		path: '/promotions/add-new',
		component: PromotionDetailPage,
	},
	{
		key: 'PromotionDetailEdit',
		name: 'PromotionDetail',
		feature: 'Promotion',
		path: '/promotions/edit/:id',
		component: PromotionDetailPage,
	},
	{
		key: 'ExpenseDetailAddNew',
		name: 'ExpenseDetail',
		feature: 'Expense',
		path: '/report/expenses/add-new',
		component: ExpensesDetailPage,
	},
	{
		key: 'ExpenseDetailEdit',
		name: 'ExpenseDetail',
		feature: 'Expense',
		path: '/report/expenses/edit/:id',
		component: ExpensesDetailPage,
	},
	{
		key: 'holiday-edit',
		name: 'Holiday Edit',
		path: '/holiday/edit/:id',
		showInMenu: true,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		component: HolidayDetail,
	},
	{
		key: 'holiday',
		name: 'New Holiday',
		path: '/holiday/new/:type',
		showInMenu: true,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		component: HolidayDetail,
	},

	{
		key: 'close-out-detail',
		name: 'New Close Out',
		feature: 'New Close Out',
		path: '/close-out/:type(edit|new)',
		component: CloseOutDetail,
	},

	{
		key: 'loyalty-setting',
		name: 'Loyalty Setting',
		path: '/settings/loyalty',
		roles: ['Admin'],
		component: LoyaltySetting,
	},
	{
		key: 'LoyaltyRankDetailAddNew',
		name: 'LoyaltyRankDetail',
		feature: 'Loyalty Rank',
		path: '/loyalty-rank/add-new',
		component: LoyaltyRankDetailPage,
	},
	{
		key: 'LoyaltyRankDetailEdit',
		name: 'LoyaltyRankDetail',
		feature: 'Loyalty Rank',
		path: '/loyalty-rank/edit/:id',
		component: LoyaltyRankDetailPage,
	},
];
export const getNameByPath = (
	path: string,
	routes = _.concat(HomeRoute, DetailRoute)
): string | undefined => {
	for (let i = 0; i < routes.length; i++) {
		if (routes[i].path === path) {
			return routes[i].name;
		}
		if (routes[i].subMenu) {
			const name = getNameByPath(path, routes[i].subMenu);
			if (name) {
				return name;
			}
		}
	}
	return undefined;
};

export const canShowBack = (path: string): boolean => {
	const routes = HomeRoute.filter((r) => r.showInMenu && !r.mainMobile);
	for (let i = 0; i < routes.length; i++) {
		if (routes[i].path === path) {
			return true;
		}
		const route = routes[i];
		if (route.subMenu && _.find(route.subMenu, (x) => x.path === path)) {
			return true;
		}
	}
	return false;
};
const AuthRoute: IRoute[] = [
	{
		key: 'Login',
		name: 'Login',
		path: '/login',
		component: Login,
	},
];

export { HomeRoute, AuthRoute, DetailRoute };
