import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IItemCategory } from 'models';
import { CategoryActions } from '../actions';

export interface ICategoryReducer {
	categories: IItemCategory[];
	selectedCategory?: IItemCategory;
	openedCategory: string[];
}

const initialState: ICategoryReducer = {
	categories: [],
	openedCategory: [],
};

function getCategorySuccess(
	state: ICategoryReducer,
	action: PayloadAction<IItemCategory[]>
) {
	state.categories = action.payload;
}
function selectCategory(
	state: ICategoryReducer,
	action: PayloadAction<IItemCategory>
) {
	state.selectedCategory = action.payload;
}
function removeCurrentCategory(state: ICategoryReducer) {
	state.selectedCategory = undefined;
}
function getCategoryByIdSuccess(
	state: ICategoryReducer,
	action: PayloadAction<IItemCategory>
) {
	state.selectedCategory = action.payload;
}
function selectOpenedCategorySuccess(
	state: ICategoryReducer,
	action: PayloadAction<string[]>
) {
	state.openedCategory = action.payload;
}
const createCategorySuccess = (
	state: ICategoryReducer,
	action: PayloadAction<IItemCategory>
) => {
	state.categories.push(action.payload);
};
const deleteCategorySuccess = (
	state: ICategoryReducer,
	action: PayloadAction<string>
) => {
	_.remove(state.categories, (element) => element.id === action.payload);
};
const updateCategorySuccess = (
	state: ICategoryReducer,
	action: PayloadAction<IItemCategory>
) => {
	state.categories = state.categories.map((element) => {
		if (element.id === action.payload.id) {
			return action.payload;
		}
		return element;
	});
};
const updateCategories = (
	state: ICategoryReducer,
	action: PayloadAction<IItemCategory[]>
) => {
	state.categories = action.payload;
};

const CategoryReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(CategoryActions.getAllCategory.success, getCategorySuccess)
		.addCase(CategoryActions.selectCategory.request, selectCategory)
		.addCase(
			CategoryActions.removeCurrentCategory.request,
			removeCurrentCategory
		)
		.addCase(CategoryActions.getCategoryById.success, getCategoryByIdSuccess)
		.addCase(
			CategoryActions.selectOpenedCategory.request,
			selectOpenedCategorySuccess
		)
		.addCase(CategoryActions.addCategory.success, createCategorySuccess)
		.addCase(CategoryActions.deleteCategory.success, deleteCategorySuccess)
		.addCase(CategoryActions.editCategory.success, updateCategorySuccess)
		.addCase(CategoryActions.updateCategories.request, updateCategories)
		.addCase(CategoryActions.addCategoryLocal.request, createCategorySuccess)
		.addCase(CategoryActions.updateCategoryLocal.request, updateCategorySuccess)
);

export default CategoryReducer;
