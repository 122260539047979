import { Modal } from 'components/atoms';
import { IApiResponse, IExpenseType } from 'models';
import { translations, t } from 'utils';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'helpers/hookHelpers';
import clsxm from 'clsxs/clsxm';
import { ExpenseTypeApiService } from 'services';

interface IExpenseTypePickerModalProps {
	isShowModal: boolean;
	onCancel: () => void;
	onChooseType: (type: IExpenseType) => void;
	selectedType?: IExpenseType;
	showAll?: boolean;
}
export const ExpenseTypePickerModal = ({
	isShowModal,
	onCancel,
	onChooseType,
	selectedType,
	showAll,
}: IExpenseTypePickerModalProps) => {
	const [expenseTypes, setExpenseTypes] = useState<IExpenseType[]>([]);

	const fetchExpenseTypes = async () => {
		try {
			const result = (await ExpenseTypeApiService.getAllExpenseTypes({
				pageNumber: 1,
				pageSize: 100,
			})) as IApiResponse<IExpenseType[]>;
			if (result.succeeded) {
				setExpenseTypes(result.data || []);
			}
		} catch (error) {}
	};
	useEffect(() => {
		fetchExpenseTypes();
	}, []);

	const expenseTypeWithAll = [...expenseTypes, ALL];
	return (
		<Modal
			isOpen={isShowModal}
			title={t(translations.expenses.chooseType)}
			onCancel={onCancel}
			content={
				<div className="h-full w-full">
					<div className={clsxm('grid grid-cols-3 gap-4 p-4')}>
						{(showAll ? expenseTypeWithAll : expenseTypes).map((e) => (
							<div
								key={e.id}
								onClick={() => onChooseType(e)}
								className={clsxm(
									'align-middle border-none shadow-md w-full overflow-hidden border-2 active:animate-pop duration-200 hover:text-black bg-white items-center justify-center inline-flex flex-col font-semibold text-center text-xs p-1 rounded-lg text-[#000] gap-1',
									selectedType?.id === e.id && 'main-gradient-background',
									'h-36'
								)}
							>
								<span className="hover:text-base-100 text-xl">
									{e.description}
								</span>
							</div>
						))}
					</div>
				</div>
			}
		/>
	);
};
export const ALL = {
	id: '',
	code: '',
	description: t(translations.expenses.all),
};
