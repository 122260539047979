import { PayloadAction } from "@reduxjs/toolkit";
import { AlertHelper } from "helpers";
import {
  IApiResponse,
  IBookingActivity,
  IErrorResponse,
  IRecentSalesChartDataSource,
  ITopServices,
  ITopStaffs,
  IUpComingBookingsChartDataSource,
} from "models";
import {
  IGetRequest,
  IPaginateRequest,
  ITodayNextBookingActivityRequest,
  ITopServicesRequest,
} from "models/RequestModels";
import { IPaginateResponse } from "models/ResponseModels";
import { call, put, takeEvery } from "redux-saga/effects";
import { AdminDashboardActions } from "redux/actions";
import { AdminDashBoardApiService } from "services";

function* getRecentSalesChart(action: PayloadAction<IGetRequest>) {
  try {
    const result: IApiResponse<IRecentSalesChartDataSource> = yield call(
      AdminDashBoardApiService.getRecentSalesChart,
      action.payload
    );
    if (result.succeeded) {
      yield put(
        AdminDashboardActions.getRecentSalesChart.success(result.data!)
      );
    } else {
      const error = result as IErrorResponse;
      AlertHelper.showError(error);
      yield put(AdminDashboardActions.getRecentSalesChart.failed(error));
    }
  } catch (error) {
    yield put(AdminDashboardActions.getRecentSalesChart.failed(error));
  }
}

function* getUpComingBookingsChart(action: PayloadAction<IGetRequest>) {
  try {
    const result: IApiResponse<IUpComingBookingsChartDataSource> = yield call(
      AdminDashBoardApiService.getUpComingBookingsChart,
      action.payload
    );
    if (result.succeeded) {
      yield put(
        AdminDashboardActions.getUpComingBookingsChart.success(result.data!)
      );
    } else {
      const error = result as IErrorResponse;
      AlertHelper.showError(error);
      yield put(AdminDashboardActions.getUpComingBookingsChart.failed(error));
    }
  } catch (error) {
    yield put(AdminDashboardActions.getUpComingBookingsChart.failed(error));
  }
}

function* getBookingActivity(action: PayloadAction<IPaginateRequest>) {
  try {
    const result: IPaginateResponse<IBookingActivity[]> = yield call(
      AdminDashBoardApiService.getBookingActivity,
      action.payload
    );
    if (result.succeeded) {
      yield put(AdminDashboardActions.getBookingActivity.success(result));
    } else {
      const error = result as IErrorResponse;
      AlertHelper.showError(error);
      yield put(AdminDashboardActions.getBookingActivity.failed(error));
    }
  } catch (error) {
    yield put(AdminDashboardActions.getBookingActivity.failed(error));
  }
}

function* getTodayNextBookingActivity(
  action: PayloadAction<ITodayNextBookingActivityRequest>
) {
  try {
    const result: IPaginateResponse<IBookingActivity[]> = yield call(
      AdminDashBoardApiService.getTodayNextBookingActivity,
      action.payload
    );
    if (result.succeeded) {
      yield put(
        AdminDashboardActions.getTodayNextBookingActivity.success(result)
      );
    } else {
      const error = result as IErrorResponse;
      AlertHelper.showError(error);
      yield put(
        AdminDashboardActions.getTodayNextBookingActivity.failed(error)
      );
    }
  } catch (error) {
    yield put(AdminDashboardActions.getTodayNextBookingActivity.failed(error));
  }
}

function* getTopServices(action: PayloadAction<ITopServicesRequest>) {
  try {
    const result: IApiResponse<ITopServices[]> = yield call(
      AdminDashBoardApiService.getTopServices,
      action.payload
    );
    if (result.succeeded) {
      yield put(AdminDashboardActions.getTopServices.success(result.data!));
    } else {
      const error = result as IErrorResponse;
      AlertHelper.showError(error);
      yield put(AdminDashboardActions.getTopServices.failed(error));
    }
  } catch (error) {
    yield put(AdminDashboardActions.getTopServices.failed(error));
  }
}

function* getTopStaffs(action: PayloadAction<ITopServicesRequest>) {
  try {
    const result: IApiResponse<ITopStaffs[]> = yield call(
      AdminDashBoardApiService.getTopStaffs,
      action.payload
    );
    if (result.succeeded) {
      yield put(AdminDashboardActions.getTopStaffs.success(result.data!));
    } else {
      const error = result as IErrorResponse;
      AlertHelper.showError(error);
      yield put(AdminDashboardActions.getTopStaffs.failed(error));
    }
  } catch (error) {
    yield put(AdminDashboardActions.getTopStaffs.failed(error));
  }
}

export function* AdminDashboardWatcher() {
  yield takeEvery(
    AdminDashboardActions.getRecentSalesChart.requestName,
    getRecentSalesChart
  );
  yield takeEvery(
    AdminDashboardActions.getUpComingBookingsChart.requestName,
    getUpComingBookingsChart
  );
  yield takeEvery(
    AdminDashboardActions.getBookingActivity.requestName,
    getBookingActivity
  );
  yield takeEvery(
    AdminDashboardActions.getTodayNextBookingActivity.requestName,
    getTodayNextBookingActivity
  );
  yield takeEvery(
    AdminDashboardActions.getTopServices.requestName,
    getTopServices
  );
  yield takeEvery(AdminDashboardActions.getTopStaffs.requestName, getTopStaffs);
}
