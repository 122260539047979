import { showError } from 'helpers/alertHelper';
import _ from 'lodash';
import { IApiResponse, IBooking, IBookingDetail, IEmployee } from 'models';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerBookingActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import EmployeeApiService from 'services/EmployeeApiService';
import { Avatar } from 'components/atoms/Avatar';
import clsxm from 'clsxs/clsxm';
import { getShortName } from 'helpers/StringHelper';
import { LoadingProvider } from 'contexts/LoadingContext';

type SelectStaffProps = {};

const primaryColor = '#87CEFA';
const secondaryColor = '#EEEDED';

export const noStylist: Partial<IEmployee> = {
	firstName: 'No preference',
	id: 'Anyone',
	employeeTypes: [
		{
			id: '',
			roleName: 'Maximum available',
		},
	],
};

const SelectStaff = (props: SelectStaffProps) => {
	const dispatch = useDispatch();
	const stylists = useSelector(
		(state: RootState) => state.CustomerBookingReducer.stylists
	)!;
	useEffect(() => {
		getAllStylists();
	}, []);

	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	)!;

	const isRequireStylist = useSelector(
		(state: RootState) =>
			state.BranchReducer.currentBranch?.technicianSelectionWhenBookingOnline
	)!;

	const bookingDetails = booking?.bookingDetails!;

	const [activeCollapse, setActiveCollapse] = useState<string[]>([
		bookingDetails![0].itemId!,
	]);

	const [firstChoose, setFirstChoose] = useState<boolean>(true);
	const [loading, setLoading] = useState(false);
	const onChangeCollapse = (key: string[]) => {
		setActiveCollapse(key);
	};
	const onChangeOneCollapse = (key: string) => {
		let index = activeCollapse.findIndex((element) => element === key);
		if (index === -1) {
			let temp = activeCollapse;
			temp.push(key);
			setActiveCollapse([...temp]);
		} else {
			setActiveCollapse(activeCollapse.filter((ele) => ele !== key));
		}
	};
	useEffect(() => console.log('aaaaaa'), [isRequireStylist]);

	const getAllStylists = async () => {
		setLoading(true);
		const response = await EmployeeApiService.getAllStylists();
		setLoading(false);
		if (!_.isEmpty(response.errors)) {
			showError(response.errors);
		}
		const responseData = response as IApiResponse<IEmployee[]>;
		if (responseData?.succeeded && !_.isEmpty(responseData.data)) {
			dispatch(
				CustomerBookingActions.getAllStylists.request(responseData.data!)
			);
		}
		setLoading(false);
	};

	const onChooseStylist = (
		stylist: Partial<IEmployee>,
		currentBookingDetail: Partial<IBookingDetail>,
		listStylistByIndex: Partial<IEmployee>[][],
		isRequireStylist?: boolean
	) => {
		let element: any = document.getElementById(`collapse`);
		let newBookingDetails = Array.from(bookingDetails);
		let elementId: string[] = [];
		let activeCollapseTemp: string[] = [];

		newBookingDetails = newBookingDetails?.map((bookingDetail) => {
			if (bookingDetail.itemId === currentBookingDetail.itemId) {
				bookingDetail = {
					...bookingDetail,
					stylistId: stylist.id,
				};
			}
			return bookingDetail;
		});

		if (firstChoose) {
			newBookingDetails = newBookingDetails?.map((bookingDetail, index) => {
				if (
					listStylistByIndex[index]
						.map((stylist) => stylist.id)
						.includes(stylist.id)
				) {
					const newBookingDetail = {
						...bookingDetail,
						stylistId: stylist.id,
					};
					return newBookingDetail;
				} else {
					const newBookingDetail = {
						...bookingDetail,
						stylistId: isRequireStylist ? undefined : 'Anyone',
					};
					activeCollapseTemp.push(bookingDetail.itemId!);
					elementId.push(bookingDetail.itemId!);
					return newBookingDetail;
				}
			});
			_.delay(() => {
				element = document.getElementById(`bookingDetail ${elementId[0]}`);
				if (element) {
					const topPos = element.getBoundingClientRect().top + window.scrollY;
					window.scrollTo({ left: 0, top: topPos, behavior: 'smooth' });
				}
			}, 1000);

			setFirstChoose(false);
			dispatch(
				CustomerBookingActions.updateBooking.request({
					...booking,
					bookingDetails: newBookingDetails,
				})
			);
			onChangeCollapse(activeCollapseTemp);
			return;
		}
		dispatch(
			CustomerBookingActions.updateBooking.request({
				...booking,
				bookingDetails: newBookingDetails,
			})
		);
		onChangeCollapse(
			activeCollapse.filter((key) => key !== currentBookingDetail.itemId)
		);
	};
	return (
		<div id="collapse" className="pb-[420px]">
			{booking.bookingDetails?.map(
				(bookingDetail: Partial<IBookingDetail>, index: number) => {
					return renderPanelService(
						bookingDetail,
						activeCollapse,
						onChooseStylist,
						stylists,
						booking,
						loading,
						isRequireStylist,
						onChangeOneCollapse
					);
				}
			)}
		</div>
	);
};

const renderPanelService = (
	bookingDetail: Partial<IBookingDetail>,
	activeCollapse: string[],
	onChooseStylist: (
		stylist: Partial<IEmployee>,
		bookingDetail: Partial<IBookingDetail>,
		listStylistByIndex: Partial<IEmployee>[][],
		isRequireStylist: boolean
	) => void,
	stylists: Partial<IEmployee>[],
	booking: Partial<IBooking>,
	loading: boolean,
	isRequireStylist: boolean,
	onChangeOneCollapse: (collapseKey: string) => void
) => {
	// const { bookingDetail, activeCollapse, onChooseStylist } = props;
	const stylistsCanDoService = isRequireStylist
		? stylists.filter(
				(stylist) =>
					stylist.serviceIds?.includes(bookingDetail.item?.id || '') &&
					stylist.canBookedOnline === true &&
					stylist.status !== 0
		  )
		: [noStylist].concat(
				stylists.filter(
					(stylist) =>
						stylist.serviceIds?.includes(bookingDetail.item?.id || '') &&
						stylist.canBookedOnline === true &&
						stylist.status !== 0
				)
		  );
	const listStylistByIndex: Partial<IEmployee>[][] = [];
	for (let index = 0; index < booking?.bookingDetails!.length; index++) {
		const stylistCanDoService = [noStylist].concat(
			stylists.filter((stylist) =>
				stylist.serviceIds?.includes(
					booking?.bookingDetails![index].item?.id || ''
				)
			)
		);
		listStylistByIndex.push(stylistCanDoService);
	}
	console.log('stylistsCanDoService', stylistsCanDoService);

	return (
		<div
			className={clsxm(
				'collapse collapse-arrow visible bg-base-100 rounded-md mb-4',
				activeCollapse.includes(bookingDetail?.itemId!) && 'collapse-open'
			)}
			id={`bookingDetail ${bookingDetail.itemId}`}
			key={bookingDetail?.itemId!}
		>
			<div
				className="collapse-title text-xl font-medium"
				onClick={() => {
					onChangeOneCollapse(bookingDetail.itemId!);
				}}
			>
				<PanelHeader
					activeCollapse={activeCollapse}
					bookingDetail={bookingDetail}
					dataList={stylistsCanDoService}
				/>
			</div>
			<LoadingProvider loading={loading}>
				<div className="collapse-content">
					<div className="flex flex-col divide-y divide-background">
						{stylistsCanDoService.map((stylist) => {
							return (
								<StaffItem
									stylist={stylist}
									onChoose={(stylist) =>
										onChooseStylist(
											stylist,
											bookingDetail,
											listStylistByIndex,
											isRequireStylist
										)
									}
								/>
							);
						})}
						{_.isEmpty(stylistsCanDoService) && <h5>No staffs available</h5>}
					</div>
				</div>
			</LoadingProvider>
		</div>
	);
};
export default SelectStaff;

export const StaffItem = (props: {
	onChoose: (stylist: Partial<IEmployee>) => void;
	stylist: Partial<IEmployee>;
}) => {
	const stylistName = `${props.stylist.firstName || ''} ${
		props.stylist.lastName || ''
	}`;

	const avatarSrc = props.stylist.id !== 'Anyone' ? props.stylist.imageUrl : '';

	const avatarBGColor =
		props.stylist.id !== 'Anyone' ? primaryColor : secondaryColor;

	return (
		<div
			className="cursor-pointer"
			onClick={() => props.onChoose(props.stylist)}
		>
			<div className="flex flex-row align-middle space-x-4 py-3">
				<div>
					<Avatar
						small
						color={avatarBGColor}
						source={avatarSrc}
						name={getShortName(stylistName)}
					></Avatar>
				</div>
				<div className="flex flex-col">
					<h5 className="text-black font-semibold">{stylistName}</h5>
					<span className="text-black/40 text-sm">
						{props.stylist?.employeeTypes![0].roleName}
					</span>
				</div>
			</div>
		</div>
	);
};
const PanelHeader = (props: {
	activeCollapse: string[];
	bookingDetail: Partial<IBookingDetail>;
	dataList: Partial<IEmployee>[];
}) => {
	let isActive = props.activeCollapse.includes(props.bookingDetail?.itemId!);
	const isAnyone = props.bookingDetail.stylistId === 'Anyone';
	const stylist = props.dataList.find(
		(e) => e.id === props.bookingDetail.stylistId
	);
	const stylistName = `${stylist?.firstName || ''} ${stylist?.lastName || ''}`;

	const avatarSrc =
		!isAnyone && !_.isEmpty(props.bookingDetail.stylistId)
			? stylist?.imageUrl
			: '';
	const avatarBGColor =
		!isAnyone && !_.isEmpty(props.bookingDetail.stylistId)
			? primaryColor
			: secondaryColor;

	return (
		<div className="space-y-1 w-full">
			<div className="flex flex-col">
				<h4 className="m-0 text-xl font-semibold">
					{props.bookingDetail.item?.name}
				</h4>
			</div>
			{!isActive ? (
				<div className="flex flex-col">
					<div className="flex flex-row align-middle space-x-2">
						<div className="space-x-2">
							<Avatar
								color={avatarBGColor}
								source={avatarSrc}
								name={getShortName(stylistName)}
								className="w-8 text-xs"
							></Avatar>
						</div>
						<div>
							<span className="text-sm text-black/40">{stylistName}</span>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};
