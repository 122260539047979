import { IPaymentMethod } from 'models';
import { BaseApiService } from './BaseApiService';

const url = '/PaymentMethods';

class PaymentMethodApiService extends BaseApiService {
	public createPaymentMethod = (request: Partial<IPaymentMethod>) =>
		this.post<Partial<IPaymentMethod>, IPaymentMethod>(
			`${url}/create-paymentMethod`,
			request
		);
	public getAllPaymentMethods = () =>
		this.get<IPaymentMethod[]>(`${url}/get-all-paymentMethods`);
	public updatePaymentMethod = (request: IPaymentMethod) =>
		this.put<IPaymentMethod, IPaymentMethod>(
			`${url}/update-paymentMethod`,
			request
		);
	public deletePaymentMethod = (id: string) =>
		this.delete<string>(`${url}/delete-paymentMethod/${id}`);
}

export default new PaymentMethodApiService(true);
