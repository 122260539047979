import { ITextTemplate } from 'models/ITextTemplate';
import { BaseApiService } from 'services/BaseApiService';

class TextTemplateApiService extends BaseApiService {
	public getTemplates = (query?: any) =>
		this.get<ITextTemplate[]>(
			`/MarketingTextMessageTemplate/get-all-marketing-text-message-templates`,
			{
				params: query,
			}
		);
	public getTemplateById = (id: string) =>
		this.get<ITextTemplate>(
			`/MarketingTextMessageTemplate/get-marketing-text-message-template/${id}`
		);
	public addTemplate = (addTemplateRequest: Partial<ITextTemplate>) =>
		this.post<Partial<ITextTemplate>, string>(
			`/MarketingTextMessageTemplate/create-marketing-text-message-template`,
			addTemplateRequest
		);
	public editTemplate = (editTemplateRequest: Partial<ITextTemplate>) =>
		this.put<Partial<ITextTemplate>, string>(
			`/MarketingTextMessageTemplate/update-marketing-text-message-template`,
			editTemplateRequest
		);
	public deleteTemplate = (id: string) =>
		this.delete<string>(
			`/MarketingTextMessageTemplate/delete-marketing-text-message-template/${id}`
		);
}

export default new TextTemplateApiService();
