import _ from "lodash";
import { ILocation } from "models/IBranch";

export const getCoordsDistance = (coords1: ILocation, coords2: ILocation) => {
  // earth
  var R = 6371, // km
    lat1 = !_.isEmpty(coords1) ? coords1.latitude || 0 : 0,
    lat2 = !_.isEmpty(coords2) ? coords2.latitude || 0 : 0,
    lon1 = !_.isEmpty(coords1) ? coords1.longitude || 0 : 0,
    lon2 = !_.isEmpty(coords2) ? coords2.longitude || 0 : 0;

  // deg2rad
  lat1 = (lat1 / 180) * Math.PI;
  lat2 = (lat2 / 180) * Math.PI;
  lon1 = (lon1 / 180) * Math.PI;
  lon2 = (lon2 / 180) * Math.PI;

  // Equirectangular approximation
  // lower accuracy, higher performance
  var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
  var y = lat2 - lat1;
  var d = Math.sqrt(x * x + y * y) * R;
  return Math.round(d * 1000);
};
