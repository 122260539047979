import { lazy } from 'react';
import tz_lookup from 'tz-lookup';
var zipcodes = require('zipcodes');
interface IZipCodeLocation {
	zip: string;
	latitude: number;
	longitude: number;
	city: string;
	state: string;
	country: string;
}
export interface IState {
	name: string;
	isoCode: string;
	latitude?: string | null;
	longitude?: string | null;
}
export interface ICity {
	name: string;
	stateCode: string;
	latitude?: string | null;
	longitude?: string | null;
}

export const getAllStates = (): Promise<IState[]> => {
	return new Promise((resolve) => {
		import('country-state-city/lib/state').then((State) => {
			return resolve(State.getStatesOfCountry('US'));
		});
	});
};

export const getAllCity = (state: string): Promise<ICity[]> => {
	// return City.getCitiesOfState('US', state);
	return new Promise((resolve) => {
		import('country-state-city').then((value) => {
			const { City } = value;
			return resolve(City.getCitiesOfState('US', state));
		});
	});
};

export const getLocationFromZip = (
	zipCode: string
): IZipCodeLocation | undefined => {
	const location = zipcodes.lookup(zipCode);
	if (location) {
		return location as IZipCodeLocation;
	}
	return undefined;
};
export const getZipFromLocation = (stateCode?: string, city?: string) => {
	if (!stateCode || !city) {
		return null;
	}
	const zipCode = zipcodes.lookupByName(city, stateCode);
	return zipCode;
};
export const getTimeZoneIdByState = (state: IState) => {
	return tz_lookup(
		Number.parseFloat(state.latitude || '0'),
		Number.parseFloat(state.longitude || '0')
	);
};

export const getTimeZoneIdByCity = (city: ICity) => {
	return tz_lookup(
		Number.parseFloat(city.latitude || '0'),
		Number.parseFloat(city.longitude || '0')
	);
};
