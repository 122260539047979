import { PayloadAction } from "@reduxjs/toolkit";
import { AlertHelper, BookingHelper } from "helpers";
import _, { isEmpty } from "lodash";
import { IApiResponse, IBillingDetail, IBooking, IBookingDetail, IErrorResponse } from "models";
import { IBilling } from "models/IBilling";
import { IBranch } from "models/IBranch";
import { IGiftCard } from "models/IGiftCard";
import { ITip } from "models/ITip";
import { ITransaction } from "models/ITransaction";
import { ICheckOutRequest, ICombineBillRequest, IUpdateChildBookingAndBill } from "models/RequestModels";
import { put, takeEvery, all, call, select } from "redux-saga/effects";
import { CheckoutActions } from "redux/actions";
import { RootState } from "redux/configuration/rootReducer";
import BillingApiService from "services/BillingApiService";
import BookingApiService from "services/BookingApiService";
import GiftCardApiService from "services/GiftCardApiService";
import TipApiService from "services/TipApiService";
import TransactionApiService from "services/TransactionApiService";
import { translations, _t, I18n } from "utils";
import { priceFixed } from "helpers/currencyHelper";
import { TipType, DiscounterType } from "utils/Consts";
import { showAlert, showError, showSuccess } from "helpers/alertHelper";

const getBranch = (state: RootState) => state.BranchReducer.currentBranch;
const getCurrentBooking = (state: RootState) => state.BookingReducer.currentBooking!;
const listBilling = (state: RootState) => state.CheckoutReducer.listBilling;
const getCurrentTipType = (state: RootState) => state.CheckoutReducer.tipType!;

function* createBill(bill: Partial<IBilling>) {
  try {
    if (!_.isEmpty(bill.bookingId)) {
      const billResults: IApiResponse<IBilling[]> = yield call(BillingApiService.getBillByBookingId, bill?.bookingId!);
      if (billResults.succeeded) {
        const billNotPay = _.find(billResults.data, (x) => x.paymentStatus !== 3);
        if (billNotPay) {
          return billNotPay;
        }
      }
    }
    const newBillResults: IApiResponse<IBilling> = yield call(BillingApiService.createBill, bill);
    if (newBillResults.succeeded) {
      return newBillResults.data!;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
}

function* combineBill(action: {
  originalBillId: string;
  listBill: Partial<IBilling>[];
  discount: number;
  discounterType: DiscounterType;
}) {
  try {
    const params: ICombineBillRequest = {
      originalBillId: action.originalBillId,
      toCombineBillIds: action.listBill.map((bill) => bill.id!),
      discount: action.discount,
      discounterType: action.discounterType,
    };
    const billCombineResult: IApiResponse<IBilling> = yield call(BillingApiService.combineBill, params);
    if (billCombineResult.succeeded && !_.isEmpty(billCombineResult.data)) {
      return billCombineResult.data?.id!;
    } else {
      const error = billCombineResult as IErrorResponse;
      yield put(CheckoutActions.checkOut.failed(error));
      AlertHelper.showError(error);
      return "";
    }
  } catch (error) {
    console.log(error);
    return "";
  }
}
function* createBillAndTransaction(action: PayloadAction<ICheckOutRequest>) {
  try {
    const listBill: Partial<IBilling>[] = yield select(listBilling);
    const billRequest = {
      ...action.payload.bill,
      discount: _.isEmpty(listBill) ? action.payload.bill.discount : 0,
    };
    const bill: Partial<IBilling> | undefined = yield call(createBill, billRequest);

    if (bill) {
      let billId = bill.id!;
      if (!_.isEmpty(listBill)) {
        billId = yield call(combineBill, {
          originalBillId: billId,
          listBill,
          discount: action.payload.booking.discount!,
          discounterType: action.payload.booking.discounterType!,
        });
      }
      //=================>>>>refactor to combineBill function

      // const listBill: Partial<IBilling>[] = yield select(listBilling);
      // let billId = bill.id!;
      // if (!_.isEmpty(listBill)) {
      // 	const params: ICombineBillRequest = {
      // 		originalBillId: billId,
      // 		toCombineBillIds: listBill.map((bill) => bill.id!),
      // 	};
      // 	const billCombineResult: IApiResponse<IBilling> = yield call(
      // 		BillingApiService.combineBill,
      // 		params
      // 	);
      // 	if (billCombineResult.succeeded && !_.isEmpty(billCombineResult.data)) {
      // 		billId = billCombineResult.data?.id!;
      // 	} else {
      // 		const error = billCombineResult as IErrorResponse;
      // 		yield put(CheckoutActions.checkOut.failed(error));
      // 		AlertHelper.showError(error);
      // 		return;
      // 	}
      // }

      if (action.payload.tip) {
        const tipParams = {
          ...action.payload.tip,
          billId,
        };
        const currentTipType: TipType = yield select(getCurrentTipType);
        switch (currentTipType) {
          case TipType.MANUALLY:
            yield call(TipApiService.addBillTipsManually, tipParams!);
            break;
          case TipType.AUTO:
            yield call(TipApiService.addTips, tipParams!);
            break;
          case TipType.SPLIT_EVEN:
            yield call(TipApiService.addBillTipsBalance, tipParams!);
            break;
        }
      }
      // const [resultTip]: [IApiResponse<ITip>, IApiResponse<IBooking>] = yield all(
      // 	[
      // 		!_.isEmpty(tipParams)
      // 			? !_.isEmpty(tipParams?.tips)
      // 				? call(TipApiService.addBillTipsManually, tipParams!)
      // 				: call(TipApiService.addTips, tipParams!)
      // 			: undefined,
      // 	]
      // );

      if (_.isEmpty(billId)) {
        return;
      }

      const transactionResult: IApiResponse<ITransaction> = yield call(TransactionApiService.createTransaction, {
        ...action.payload.transactions,
        billId,
      });
      if (transactionResult.succeeded && transactionResult.data) {
        yield put(CheckoutActions.checkOut.success());
        // notification['success']({
        // 	message: 'Success',
        // 	description: I18n.t(_t(translations.checkout.checkoutSuccess)),
        // });
      } else {
        const error = transactionResult as IErrorResponse;
        yield put(CheckoutActions.checkOut.failed(error));
        AlertHelper.showError(error);
      }
    } else {
      const error: IErrorResponse = {
        message: [
          {
            Text: "System Error",
          },
        ],
      };
      yield put(CheckoutActions.checkOut.failed(error));
      AlertHelper.showError(error);
    }
  } catch (error) {}
}

function* updateBooking(action: PayloadAction<ICheckOutRequest>) {
  try {
    const currentBooking: IBooking = yield select(getCurrentBooking);
    const currentBranch: IBranch = yield select(getBranch);
    const listBillCombine: IBilling[] = yield select(listBilling);

    // const bookingResponse: IApiResponse<IBooking> = yield call(
    // 	BookingApiService.getBookingById,
    // 	action.payload.booking.id!
    // );
    // if (bookingResponse.data && bookingResponse.succeeded) {
    let newBooking: Partial<IBooking> = {
      ...action.payload.booking,
      discountPercent: _.isEmpty(listBillCombine) ? action.payload.booking.discountPercent! : 0,
      discount: _.isEmpty(listBillCombine) ? action.payload.booking.discount! : 0,
    };
    newBooking.bookingDetails = newBooking.bookingDetails!.map((bookingDetail) => {
      const newBookingDetail = {
        ...bookingDetail,
        // tax: priceFixed(
        // 	BookingHelper.calculateBookingDetailTax(
        // 		bookingDetail,
        // 		currentBranch.taxPercent
        // 	)
        // ),
      };
      return newBookingDetail;
    });
    newBooking.totalTax = undefined;
    newBooking.bookingHistories = undefined;
    const updateBookingResponse: IApiResponse<IBooking> = yield call(BookingApiService.editBooking, newBooking);
    if (updateBookingResponse.data && updateBookingResponse.succeeded) {
      return true;
    } else {
      return false;
    }
    // } else {
    // 	return false;
    // }
  } catch (error) {
    console.log(error, "ERROR");

    return false;
  }
}

function* updateChildBookingAndBill(action: PayloadAction<IUpdateChildBookingAndBill>) {
  try {
    const currentBranch: IBranch = yield select(getBranch);
    let newBooking = {
      ...action.payload.booking,
    };
    const bookingResponse: IApiResponse<IBooking> = yield call(BookingApiService.getBookingById, newBooking.id!);
    if (bookingResponse.data && bookingResponse.succeeded) {
      newBooking = { ...newBooking, status: bookingResponse.data.status };
      newBooking.bookingDetails = newBooking.bookingDetails!.map((bookingDetail) => {
        const newBookingDetail = {
          ...bookingDetail,
          tax: priceFixed(BookingHelper.calculateBookingDetailTax(bookingDetail, currentBranch.taxPercent)),
        };

        return newBookingDetail;
      });
      newBooking.totalTax = undefined;
      newBooking.bookingHistories = undefined;

      const updateBookingResponse: IApiResponse<IBooking> = yield call(BookingApiService.editBooking, newBooking);
      if (updateBookingResponse.data && updateBookingResponse.succeeded) {
        yield put(CheckoutActions.updateBookingToListCombine.request(updateBookingResponse.data));
        let newBill = {
          ...action.payload.billing,
        };

        newBill.billDetails = newBill.billDetails?.map((billDetail) => {
          const bookingDetail = _.find(
            action.payload.booking.bookingDetails,
            (x) => x.id === billDetail.bookingDetailId
          )! as IBookingDetail;

          const newBillDetail: Partial<IBillingDetail> = {
            ...billDetail,
            tax: priceFixed(BookingHelper.calculateBookingDetailTax(bookingDetail, currentBranch.taxPercent)),
          };

          return newBillDetail;
        });
        newBill.totalTax = undefined;
        const updateBillingResponse: IApiResponse<IBilling> = yield call(BillingApiService.updateBill, newBill);
        if (updateBillingResponse.succeeded) {
          yield put(CheckoutActions.updateChildBookingAndBill.success());
          showSuccess("Update successfully");
        } else {
          yield put(CheckoutActions.updateChildBookingAndBill.failed());
          AlertHelper.showError(updateBillingResponse.errors as IErrorResponse);
        }
      } else {
        yield put(CheckoutActions.updateChildBookingAndBill.failed());
        AlertHelper.showError(updateBookingResponse.errors as IErrorResponse);
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log("ERRORR=>>>>>>>>>.", error);
  }
}

function* checkOut(action: PayloadAction<ICheckOutRequest>) {
  try {
    if (action.payload.isSaleGiftCardOnly) {
      yield call(createBillAndTransaction, action);
      return;
    }
    const updateBookingSuccess: boolean = yield call(updateBooking, action);
    if (updateBookingSuccess) {
      yield call(createBillAndTransaction, action);
    } else {
      yield put(CheckoutActions.checkOut.failed("System Error"));
      const error: IErrorResponse = {
        message: [
          {
            Text: "System Error",
          },
        ],
      };
      AlertHelper.showError(error);
    }
  } catch (error) {
    yield put(CheckoutActions.checkOut.failed(error));
    AlertHelper.showError(error as IErrorResponse);
  }
}

function* deleteGiftCard(action: PayloadAction<string>) {
  try {
    const result: IApiResponse<string> = yield call(GiftCardApiService.deleteGiftCard, action.payload);
    if (result.succeeded) {
      yield put(CheckoutActions.deleteGiftCard.success(result.data!));
    } else {
      const error = result as IErrorResponse;
      showError(error);
      yield put(CheckoutActions.deleteGiftCard.failed(error));
    }
  } catch (error) {
    yield put(CheckoutActions.deleteGiftCard.failed(error));
  }
}

function* createChildBill(action: PayloadAction<IBilling>) {
  try {
    const bill: Partial<IBilling> | undefined = yield call(createBill, action.payload);
    if (bill) {
      yield put(CheckoutActions.createBill.success(bill));
    } else {
      const error: IErrorResponse = {
        message: [
          {
            Text: "System Error",
          },
        ],
      };
      yield put(CheckoutActions.createBill.failed(error));
      AlertHelper.showError(error);
    }
  } catch (error) {
    yield put(CheckoutActions.createBill.failed(error));
  }
}

export function* CheckOutWatcher() {
  yield takeEvery(CheckoutActions.checkOut.requestName, checkOut);

  // yield takeEvery(CheckoutActions.addGiftCard.requestName, addGiftCard);
  yield takeEvery(CheckoutActions.deleteGiftCard.requestName, deleteGiftCard);
  yield takeEvery(CheckoutActions.createBill.requestName, createChildBill);
  yield takeEvery(CheckoutActions.updateChildBookingAndBill.requestName, updateChildBookingAndBill);
}
