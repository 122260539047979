import { Card } from 'components/atoms';
import {
	ITotalBookingSummary,
	SummaryTotalAppointmentResponseDetail,
} from 'models';
import { useDashboardContext } from 'pages/Dashboard/context';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { AdminDashBoardApiService } from 'services';
import moment from 'moment';
import _ from 'lodash';

export const TotalAppointment = () => {
	const { timeRange, branch } = useDashboardContext();
	const [data, setData] = useState<ITotalBookingSummary>();
	const fetchData = async () => {
		const response = await AdminDashBoardApiService.getTotalBookingSummary({
			fromDate: timeRange.fromDate.format('YYYY-MM-DD'),
			toDate: timeRange.toDate.format('YYYY-MM-DD'),
			branchId: branch,
		});
		if (response.succeeded && response.data) {
			setData(response.data);
		}
	};
	useEffect(() => {
		fetchData();
	}, [timeRange, branch]);
	const totalDays = Math.abs(timeRange.toDate.diff(timeRange.fromDate, 'day'));
	const dataChart = useMemo(() => {
		if (!data) {
			return [];
		}
		if (totalDays < 20) {
			return data.summaryTotalAppointmentResponseDetails;
		}
		let result: SummaryTotalAppointmentResponseDetail[] = [];
		for (
			let i = 0;
			i < data.summaryTotalAppointmentResponseDetails.length;
			i++
		) {
			const value = data.summaryTotalAppointmentResponseDetails[i];
			const existIndex = _.findIndex(
				result,
				(x) =>
					moment(x.date).unix() === moment(value.date).startOf('week').unix()
			);
			if (existIndex >= 0) {
				result[existIndex] = {
					...result[existIndex],
					onlineBooking: result[existIndex].onlineBooking + value.onlineBooking,
					walkInBooking: result[existIndex].walkInBooking + value.walkInBooking,
					appointmentBooking:
						result[existIndex].appointmentBooking + value.appointmentBooking,
				};
			} else {
				result.push({
					date: moment(value.date).startOf('week').toDate(),
					onlineBooking: value.onlineBooking,
					walkInBooking: value.walkInBooking,
					appointmentBooking: value.appointmentBooking,
				});
			}
		}
		return result;
	}, [data, totalDays]);
	return (
		<Card title="Total Appointments" className="col-span-2">
			<Line
				options={{
					plugins: {
						datalabels: {
							display: false,
						},

						legend: {
							position: 'bottom',
							labels: {
								usePointStyle: true,
								boxWidth: 10,
							},
						},
					},
					scales: {
						x: {
							type: 'time',
							time: {
								unit: totalDays > 20 ? 'week' : 'day',
							},
							ticks: {
								autoSkip: true,
								maxTicksLimit: 10,
							},
							grid: {
								display: false,
							},
						},
						y: {
							ticks: {
								precision: 0,
							},
						},
					},
				}}
				data={{
					labels: dataChart?.map((x) => moment(x.date)),
					datasets: [
						{
							label: 'Online',
							data: dataChart.map((x) => x.onlineBooking),
							backgroundColor: '#323faf',
							borderColor: '#323faf',
							tension: 0.4,
							// fill: {
							// 	target: 'origin',
							// 	above: 'rgba(50,63,175,0.8)',
							// },
						},

						{
							label: 'Appointment',
							data: dataChart.map((x) => x.appointmentBooking),
							backgroundColor: '#c000bb',
							borderColor: '#c000bb',
							// fill: {
							// 	target: 'origin',
							// 	above: 'rgba(192,0,187,0.5)',
							// },
							tension: 0.4,
						},
						{
							label: 'WalkIn',
							data: dataChart.map((x) => x.walkInBooking),
							backgroundColor: '#7e0',
							borderColor: '#7e0',
							// fill: {
							// 	target: 'origin',
							// 	above: 'rgba(119,238,0,0.3)',
							// },
							tension: 0.4,
							// tension: 1,
						},
					],
				}}
			/>
		</Card>
	);
};
