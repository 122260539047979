import { mdiChevronDown } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Icon } from 'components/atoms/Icon';
import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
export interface ISelectProps<T> {
	options: T[];
	titleField: keyof T;
	keyField: keyof T;
	value?: T;
	valueId?: string | number;
	icon?: ReactNode;
	icons?: ReactNode[];
	onChange?: (value: T) => void;
	onClick?: (value: T, index?: number) => void;
	className?: string;
	optionClassName?: string[];
	title?: string;
	isDropDown?: boolean;
	size?: 'small' | 'medium' | 'large';
	primary?: boolean;
	fullWidth?: boolean;
	label?: string;
	subLabel?: string;
	error?: string;
	hint?: string;
	iconAfter?: ReactNode;
	left?: boolean;
	disabled?: boolean;
	placeHolder?: string;
	renderOptions?: (item: T) => ReactNode;
	renderValueTitle?: (item?: T) => string;
}

export const Select = <T,>(props: ISelectProps<T>) => {
	const [active, setActive] = useState(props.value);
	const [show, setShow] = useState(false);
	useEffect(() => {
		setActive(props.value);
	}, [props.value]);
	const valueTitle = () => {
		if (props.isDropDown) {
			return props.title;
		}
		if (active) {
			return active[props.titleField];
		}
		if (props.valueId !== undefined) {
			const value = _.find(
				props.options,
				(x) => `${x[props.keyField]}` === `${props.valueId}`
			);
			return value && value[props.titleField];
		}
		return props.title;
	};
	return (
		<div className={clsxm('form-control')}>
			{props.label && (
				<label className="label font-medium ">
					<div className="flex flex-col">
						<span className="label-text text-md">{props.label}</span>
						{props.subLabel && (
							<span className="text-sm font-normal">{props.subLabel}</span>
						)}
					</div>
				</label>
			)}
			<label>
				<div
					className={clsxm(
						'dropdown',
						props.left && 'dropdown-left',
						props.fullWidth && 'w-full'
					)}
				>
					<div
						onClick={() => !props.disabled && setShow(true)}
						tabIndex={0}
						className={clsxm(
							'relative btn btn-outline gap-2 justify-start bg-white border-primary',
							props.fullWidth && 'w-full',
							props.primary && 'main-gradient-background',
							props.className
						)}
					>
						{props.icon}
						{`${
							props.renderValueTitle
								? props.renderValueTitle(props.value)
								: valueTitle() || props.placeHolder
						}`}
						{!props.isDropDown && (
							<div className="flex justify-center items-center absolute right-2 top-0 bottom-0">
								<Icon path={mdiChevronDown} />
							</div>
						)}
					</div>
					{!props.disabled && show && (
						<ul
							tabIndex={0}
							className={clsxm(
								'dropdown-content menu p-2 shadow bg-base-100 rounded-box w-60  max-h-[20rem] overflow-y-scroll',
								props.fullWidth && 'w-full'
							)}
						>
							{props.options.map((item, index) => {
								return (
									<li
										onClick={() => {
											setShow(false);
											props.onChange && props.onChange(item);
											props.onClick && props.onClick(item, index);
										}}
										className={clsxm(
											'active:main-gradient-background rounded-lg border-t border-base-200',
											index === 0 && 'border-none',
											active &&
												active[props.keyField] === item[props.keyField] &&
												'main-gradient-background',
											props.optionClassName &&
												props.optionClassName[index] &&
												props.optionClassName[index]
										)}
									>
										<div
											className={clsxm(
												'flex',
												'gap-2',
												props.size === 'small' && 'p-2',
												props.size === 'medium' && 'p-4',
												props.size === 'large' && 'p-6'
											)}
										>
											{props.icons && props.icons[index] && props.icons[index]}
											{`${
												props.renderOptions
													? props.renderOptions(item)
													: item[props.titleField]
											}`}
										</div>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			</label>
			{props.error && (
				<p className="ml-2 mt-2 text-left w-full text-error">{props.error}</p>
			)}
			{props.hint && !props.error && (
				<p className="ml-2 mt-2 text-left w-full italic text-base-300">
					{props.hint}
				</p>
			)}
		</div>
	);
};
