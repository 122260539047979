import { IShortly } from 'models/IShortly';
import { BaseApiService } from 'services/BaseApiService';

class ShortlyApi extends BaseApiService {
	public getById = (id: string) =>
		this.get<IShortly>(`/ShortenUrlData/get-shorten-url-data/${id}`);
	public getUrlByCode = (code: string) =>
		this.get<IShortly>(`/ShortenUrlData/get-shorten-url-by-code/${code}`);
}

export default new ShortlyApi();
