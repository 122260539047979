import React from 'react';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { AdminRouter, AuthenticationRouter, history } from 'routers';
import { RootState } from 'redux/configuration/rootReducer';
import _ from 'lodash';
import { AuthRoute } from 'routers/routes';
import SocketService from 'services/SocketService';
import { BranchActions, AppConfigActions } from 'redux/actions';
import { Dispatch } from 'redux';
import RemoteSocketService from 'services/RemoteSocketService';
import { Booking } from 'pages/Booking';
import CustomerBooking from 'pages/CustomerBooking';
import Rating from 'pages/Rating';
import { CloverIntegration } from 'pages/CloverIntegration';
import { Partner } from 'pages/Partner';
import { PartnerRegister } from 'pages/Partner/Register';
import { PartnerConfirmSuccess } from 'pages/Partner/ConfirmSuccess';
import { LoadingProvider } from 'contexts/LoadingContext';
import { FacebookIntegration } from 'pages/FacebookIntegration';
import { Shortly } from 'pages/Shortly';
import { RedirectShortly } from 'routers/Redirect';
import CustomerAppointment from 'pages/CustomerAppointment';

interface IAppProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}

class App extends React.Component<IAppProps> {
	socketService: SocketService | null = null;
	remoteSocketService: RemoteSocketService | null = null;

	state = {
		reload: false,
		remoteAsk: false,
	};
	componentDidMount() {
		if (!_.isEmpty(this.props.currentBranch)) {
			this.socketInit();
			if (this.props.uuid) {
				this.remoteSocketInit(this.props.uuid);
			}
		}
	}

	componentWillUnmount() {
		this.removeSocket();
	}
	componentDidUpdate(prev: IAppProps) {
		if (
			!_.isEmpty(this.props.currentBranch) &&
			prev.currentBranch?.id !== this.props.currentBranch?.id
		) {
			if (this.props.uuid) {
				this.removeRemoteSocket();
				setTimeout(() => {
					this.remoteSocketInit(this.props.uuid!);
				}, 500);
			}
			this.removeSocket();
			setTimeout(() => {
				this.socketInit();
			}, 500);
		}
	}
	socketInit() {
		if (_.isEmpty(this.socketService)) {
			this.socketService = new SocketService();
			this.socketService.onListenPoolStaffs();
			this.socketService.onListenBooking();
			this.socketService.onListenCheckedIn();
			this.socketService.onListenPoolStaffs();
			this.socketService.runService();
			this.socketService.onClose();
		}
	}
	removeSocket() {
		if (this.socketService !== null) {
			this.socketService.remove();
			this.socketService = null;
		}
	}

	remoteSocketInit(uuid: string) {
		if (_.isEmpty(this.remoteSocketService)) {
			this.remoteSocketService = new RemoteSocketService(uuid);
			this.remoteSocketService.setCallBack(() => this.onRemote());
			this.remoteSocketService.onListenRemote();
			this.remoteSocketService.runService();
			this.remoteSocketService.onClose();
		}
	}
	removeRemoteSocket() {
		if (this.remoteSocketService !== null) {
			this.remoteSocketService.remove();
			this.remoteSocketService = null;
		}
	}
	onRemote = () => {
		this.setState({ remoteAsk: true });
	};
	render() {
		if (window.location.href.includes('partners.')) {
			const shopUrl = window.localStorage.getItem('shopUrl');
			if (
				!_.isEmpty(shopUrl) &&
				shopUrl?.includes(process.env.REACT_APP_PRODUCTION_URL!)
			) {
				window.location.href = shopUrl;
				return null;
			}
			return (
				<Router history={history}>
					<Route exact path={['/', '/login']} component={Partner} />
					<Route exact path={['/register']} component={PartnerRegister} />
					<Route
						exact
						path={['/confirm-success']}
						component={PartnerConfirmSuccess}
					/>
				</Router>
			);
		}
		if (window.location.pathname.includes('/integration')) {
			return (
				<Router history={history}>
					{/* <Route exact path={['/', '/login']} component={Partner} /> */}
					{/* <Route exact path={['/register']} component={PartnerRegister} /> */}
					{/* <Route
						exact
						path={['/confirm-success']}
						component={PartnerConfirmSuccess}
					/> */}
					<Route
						exact
						path={['/clover', '/clover/integration/:shopId']}
						component={CloverIntegration}
					/>
					<Route
						exact
						path={['/clover', '/clover/integration/:shopId/:branchId']}
						component={CloverIntegration}
					/>
					<Route
						exact
						path={['/facebook', '/facebook/integration/:shopId']}
						component={FacebookIntegration}
					/>
				</Router>
			);
		}
		return (
			<>
				<Router history={history}>
					<HelmetProvider>
						<Helmet
							titleTemplate="%s - SmartSalon - SCSSolutions"
							defaultTitle="SmartSalon - SCSSolutions"
						>
							<meta name="description" content="Powered by SCSSolution" />
							<meta
								name="viewport"
								content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
							/>
						</Helmet>
					</HelmetProvider>
					{!_.isEmpty(this.props.shop)
						? this.renderMain()
						: this.renderLoading()}
				</Router>
				{/* <ConfirmModal
					onOk={() => this.setState({ remoteAsk: false })}
					onCancel={() => this.setState({ remoteAsk: false })}
					visible={this.state.remoteAsk}
					title={'Remote Support'}
					description={
						'Allow Smart Salon Staff can remote to your POS and support'
					}
					notDanger
				/> */}
			</>
		);
	}
	renderMain() {
		const { isAuthenticated } = this.props;
		return (
			<div className="h-screen">
				{/* <CallProcess /> */}
				<Switch>
					<Redirect exact from="/" to="/today" />
					{this.renderAuthRoute()}
					{this.renderCustomerBookingRoute()}
					{this.renderRatingRoute()}
					{this.renderShortlyRoute()}
					{this.renderCustomerAppointmentRoute()}
					<AdminRouter isAuthenticated={isAuthenticated} />
				</Switch>
			</div>
		);
	}
	renderLoading() {
		const domain = window.location.href;
		if (domain.includes('sshub.link')) {
			return (
				<RedirectShortly
					isAppointment={window.location.pathname.includes('/a/')}
				/>
			);
		}
		return (
			<LoadingProvider loading>
				<div className="h-screen w-screen bg-white " />
			</LoadingProvider>
		);
	}

	renderAuthRoute() {
		return (
			<Route
				exact
				path={AuthRoute.map((route) => route.path)}
				component={AuthenticationRouter}
			/>
		);
	}
	renderBookingRoute() {
		return (
			<Route
				exact
				path={['/booking', '/booking/new', '/booking/:id']}
				component={Booking}
			/>
		);
	}
	renderCustomerBookingRoute() {
		return (
			<Route
				path={['/customer-booking', '/customer-booking/:id']}
				component={CustomerBooking}
			/>
		);
	}
	renderRatingRoute() {
		return (
			<Route
				exact
				path={['/rating', '/rating/:branchId/:bookingId']}
				component={Rating}
			/>
		);
	}
	renderShortlyRoute() {
		return (
			<Route exact path={['/shortly', '/shortly/:id']} component={Shortly} />
		);
	}
	renderConfirmBookingRoute() {
		// return (
		// 	<Route
		// 		exact
		// 		path={['/confirm-booking', '/confirm-booking/:branchId/:bookingId']}
		// 		component={ConfirmBookingPage}
		// 	/>
		// );
	}
	renderHelpCenterRoute() {
		// return <Route exact path={'/help-center'} component={HelpCenterPage} />;
	}
	renderUserGuide() {
		// return <Route exact path={'/user-guide'} component={UserGuide} />;
	}
	renderCustomerAppointmentRoute() {
		return <Route path={['/appointment']} component={CustomerAppointment} />;
	}
}
const mapStateToProps = (state: RootState) => ({
	user: state.UserReducer.user,
	isAuthenticated: !_.isEmpty(state.AuthenticationReducer.token),
	shop: state.ShopReducer.shop,
	currentBranch: state.BranchReducer.currentBranch,
	uuid: state.AppConfigReducer.uuid,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setBranchId: (branchId: string) =>
		dispatch(BranchActions.setBranchId.request(branchId)),
	setUuid: (uuid: string) => dispatch(AppConfigActions.setUuid.request(uuid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
