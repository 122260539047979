import { Container, Input, Modal, TextArea } from 'components/atoms';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IThemeContent } from '..';
import { translations, t } from 'utils';

export const FormContentWhyChoose = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<IThemeContent>();
	return (
		<Container>
			<div className="grid gap-y-2">
				<Input
					label={t(translations.miniCMSSetting.titleWhyChoose)}
					placeholder={t(translations.miniCMSSetting.titleWhyChoosePlaceholder)}
					maxLength={256}
					error={errors.title?.message}
					{...register('title', {
						required: t(translations.miniCMSSetting.messageTitleRequired),
					})}
				/>
				<TextArea
					label={t(translations.miniCMSSetting.subTitleWhyChoose)}
					placeholder={t(
						translations.miniCMSSetting.subTitleWhyChoosePlaceholder
					)}
					error={errors.subTitle?.message}
					{...register('subTitle')}
				/>
				<TextArea
					label={t(translations.miniCMSSetting.descriptionWhyChoose)}
					placeholder={t(
						translations.miniCMSSetting.descriptionWhyChoosePlaceholder
					)}
					error={errors.description?.message}
					{...register('description')}
				/>
			</div>
		</Container>
	);
};
