import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { ICurrency } from 'models/ICurrency';
import { CurrencyActions } from '../actions';

export interface ICurrencyReducer {
	currency: ICurrency[];
}

const initialState: ICurrencyReducer = {
	currency: [],
};

const getAllCurrency = (
	state: ICurrencyReducer,
	action: PayloadAction<ICurrency[]>
) => {
	state.currency = action.payload;
};

const CurrencyReducer = createReducer(initialState, (builder) => {
	builder.addCase(CurrencyActions.getAllCurrency.success, getAllCurrency);
});
export default CurrencyReducer;
