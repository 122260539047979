import clsxm from 'clsxs/clsxm';
import { Modal } from 'components/atoms';
import { StaffCard } from 'components/molecules/StaffCard';
import { useAppSelector } from 'helpers/hookHelpers';
import { IEmployee } from 'models';
import React from 'react';
import { t, translations } from 'utils';

interface IStaffPickerModal {
	visible?: boolean;
	selectedStaff?: Partial<IEmployee>;
	onSelectStaff?: (staff: Partial<IEmployee>) => void;
	chooseLater?: boolean;
	all?: boolean;
	onCancel?: () => void;
}
export const StaffPickerModal = ({
	selectedStaff,
	visible,
	onSelectStaff,
	all,
	onCancel,
}: IStaffPickerModal) => {
	const { employees } = useAppSelector((state) => state.EmployeeReducer);
	return (
		<Modal
			isOpen={visible || false}
			title={t(translations.mainBooking.selectStylist)}
			content={
				<div className="h-full w-full">
					<div className={clsxm('grid grid-cols-3 gap-4 bg-base-200 p-4')}>
						{all && (
							<StaffCard
								className={clsxm('h-36')}
								onSelect={() =>
									onSelectStaff &&
									onSelectStaff({ firstName: t(translations.all) })
								}
								label={t(translations.all)}
								isSelected={!selectedStaff?.id}
							/>
						)}
						{employees?.map((e) => (
							<StaffCard
								className={clsxm('h-36')}
								staff={e}
								onSelect={() => {
									onSelectStaff && onSelectStaff(e);
								}}
								isSelected={selectedStaff?.id === e.id}
							/>
						))}
					</div>
				</div>
			}
			onCancel={onCancel}
		/>
	);
};
