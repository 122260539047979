import { mdiCheckCircle } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Icon } from 'components/atoms';
import React from 'react';
import { translations, t } from 'utils';

export const PartnerConfirmSuccess = () => {
	return (
		<>
			<div className={clsxm('flex flex-col items-center w-full bg-[#F4F3F8]')}>
				<img
					alt=""
					className={clsxm('w-48 h-48')}
					src="./logo-square.png"
				></img>
				<h4 className={clsxm('mt-[-30px]')}>{t(translations.login.slogan)}</h4>
				<div className="text-center my-24">
					<div className="w-full flex justify-center">
						<Icon path={mdiCheckCircle} size={3} color="#52c41a" />
					</div>
					<div className="font-semibold text-2xl">
						<strong>{t(translations.partner.thanks)}</strong>
					</div>
					<Button
						small
						primary
						onClick={() =>
							(window.location.href = `http://partners.${process.env
								.REACT_APP_PRODUCTION_URL!}/login`)
						}
						title={t(translations.partner.goToShop)}
						className="w-full mt-6"
					/>
				</div>
			</div>
			<footer className="footer footer-center p-4  text-base-content bg-[#F4F3F8]">
				<div>
					<p>Copyright © 2022 - SCSSolutions.io</p>
				</div>
			</footer>
		</>
	);
};
