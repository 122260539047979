import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery, call } from 'redux-saga/effects';
import { PaymentMethodActions } from '../actions';
import { IApiResponse, IErrorResponse, IPaymentMethod } from 'models';
import { AlertHelper } from 'helpers';
import { PaymentMethodApiService } from 'services';

function* getAllPaymentMethods() {
	try {
		const result: IApiResponse<IPaymentMethod[]> = yield call(
			PaymentMethodApiService.getAllPaymentMethods
		);
		if (result.succeeded) {
			yield put(
				PaymentMethodActions.getAllPaymentMethods.success(result.data!)
			);
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
			yield put(PaymentMethodActions.getAllPaymentMethods.failed(error));
		}
	} catch (error) {
		yield put(PaymentMethodActions.getAllPaymentMethods.failed(error));
	}
}

function* createPaymentMethod(action: PayloadAction<Partial<IPaymentMethod>>) {
	try {
		const result: IApiResponse<IPaymentMethod> = yield call(
			PaymentMethodApiService.createPaymentMethod,
			action.payload
		);
		if (result.succeeded) {
			yield put(PaymentMethodActions.createPaymentMethod.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			AlertHelper.showError(errorResult);
			yield put(PaymentMethodActions.createPaymentMethod.failed(errorResult));
		}
	} catch (error) {
		yield put(PaymentMethodActions.createPaymentMethod.failed(error));
	}
}

function* updatePaymentMethod(action: PayloadAction<IPaymentMethod>) {
	try {
		const result: IApiResponse<IPaymentMethod> = yield call(
			PaymentMethodApiService.updatePaymentMethod,
			action.payload
		);
		if (result.succeeded) {
			yield put(PaymentMethodActions.updatePaymentMethod.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			AlertHelper.showError(errorResult);
			yield put(PaymentMethodActions.updatePaymentMethod.failed(errorResult));
		}
	} catch (error) {
		yield put(PaymentMethodActions.updatePaymentMethod.failed(error));
	}
}

function* deletePaymentMethod(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<string> = yield call(
			PaymentMethodApiService.deletePaymentMethod,
			action.payload
		);
		if (result.succeeded) {
			yield put(PaymentMethodActions.deletePaymentMethod.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			AlertHelper.showError(errorResult);
			yield put(PaymentMethodActions.deletePaymentMethod.failed(errorResult));
		}
	} catch (error) {
		yield put(PaymentMethodActions.deletePaymentMethod.failed(error));
	}
}

export function* CategoryWatcher() {
	yield takeEvery(
		PaymentMethodActions.getAllPaymentMethods.requestName,
		getAllPaymentMethods
	);
	yield takeEvery(
		PaymentMethodActions.createPaymentMethod.requestName,
		createPaymentMethod
	);
	yield takeEvery(
		PaymentMethodActions.updatePaymentMethod.requestName,
		updatePaymentMethod
	);
	yield takeEvery(
		PaymentMethodActions.deletePaymentMethod.requestName,
		deletePaymentMethod
	);
}
