import { mdiPencilOutline } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Container, EColumnType, Icon, Modal } from 'components/atoms';
import Table, { IColumnProps } from 'components/atoms/Table';
import { Header } from 'components/molecules/Header';
import { PickService } from 'components/molecules/PickService';
import { LoadingProvider } from 'contexts/LoadingContext';
import { AlertHelper, CurrencyHelper, TimeHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import { IErrorResponse, IItemCategory, IService } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import GalleryApiService from 'services/GalleryApiService';
import { translations, t } from 'utils';

interface IThemeShopService {
	id: string;
	item: IService;
	itemCategory: IItemCategory;
	priority: number;
	status: number;
	shopId: string;
}

export const ContentFeatureServices = () => {
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [selectedServices, setSelectedServices] = useState<IThemeShopService[]>(
		[]
	);
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);

	const getThemeShopServices = async () => {
		setLoading(true);
		try {
			const res = await GalleryApiService.getThemeShopService({
				orderBy: 'itemCategory.name',
			});
			if (res.succeeded) {
				const { data } = res;
				let dataSelectedServices: IThemeShopService[] = [];
				data.forEach((service: IThemeShopService) => {
					dataSelectedServices.push(service);
				});
				setSelectedServices(dataSelectedServices);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const isEmptyCategory = (categoryId: string) => {
		let index = selectedServices.findIndex(
			(service) => service.itemCategory.id === categoryId
		);
		return index === -1 ? true : false;
	};

	const updateThemeService = async (services: IService[]) => {
		setLoading(true);
		let params: any = [];
		services.forEach((element) => {
			params.push({
				itemId: element.id,
				itemCategoryId: element.itemCategoryId,
			});
		});
		try {
			const res = await GalleryApiService.updateThemeShopService(params);
			if (res.succeeded) {
				AlertHelper.showSuccess(
					t(translations.miniCMSSetting.updateServiceSuccess)
				);
				getThemeShopServices();
				setShowModal(false);
			} else {
				const error = res as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		getThemeShopServices();
	}, []);

	return (
		<LoadingProvider loading={loading} className="h-full">
			<div className={clsxm('w-full flex flex-col h-full overflow-y-auto')}>
				<div className={clsxm('my-16 w-full px-16 grid gap-y-8')}>
					<Header
						title={t(translations.miniCMSSetting.headerServices)}
						showClose
					/>
					<Container>
						<div className="flex w-full justify-end">
							<Button
								small
								primary
								title={t(translations.edit)}
								iconBefore={<Icon path={mdiPencilOutline} />}
								onClick={() => {
									setShowModal(true);
								}}
							/>
						</div>
						<ul className="list-none p-0">
							{categories.map((category) => {
								return isEmptyCategory(category.id) ? null : (
									<li className="border-1 shadow-md my-4 w-full rounded-md px-0">
										<div className="bg-base-200 h-12 text-lg font-semibold p-2 rounded-t-md flex">
											{category.name}
										</div>
										<ul className="p-0 pb-2">
											{selectedServices
												.filter(
													(service) => service.itemCategory.id === category.id
												)
												.map((x, index) => {
													return (
														<div key={index}>
															{index !== 0 && <div className="divider my-0" />}
															<li className="px-12 flex">
																<div className="ml-2 flex basis-11/12">
																	<span className="basis-1/2">
																		{x.item.name}
																	</span>
																	<span className="basis-1/4">
																		{TimeHelper.convertDurationToStringDetail(
																			x.item.duration
																		)}
																	</span>
																	<span className="basis-1/4">
																		{CurrencyHelper.formatPrice(x.item.price)}
																	</span>
																</div>
															</li>
														</div>
													);
												})}
										</ul>
									</li>
								);
							})}
						</ul>
					</Container>

					<PickService
						title={t(translations.promotionDetail.modalTitle)}
						visible={showModal}
						selected={selectedServices.map((service) => service.item)}
						onSave={(data) => updateThemeService(data)}
						onCancel={() => setShowModal(false)}
					/>
				</div>
			</div>
		</LoadingProvider>
	);
};
