import { Button, Container, Icon, Input, Switch } from 'components/atoms';
import React, { useState } from 'react';
import { translations, t } from 'utils';
import { mdiClose, mdiDelete, mdiTrayArrowUp } from '@mdi/js';
import { isEmpty } from 'lodash';
import { FileUpload, useFileUpload } from 'use-file-upload';
import { IImageGallery } from '.';
import { useFormContext } from 'react-hook-form';
import { IAddGalleryRequest } from 'models/RequestModels';
import clsxm from 'clsxs/clsxm';
interface IContentSliderProps {
	images: IImageGallery[];
	setImages: (images: IImageGallery[]) => void;
}
export const ContentGallery = (props: IContentSliderProps) => {
	const [files, selectFiles] = useFileUpload();
	const [preview, setPreview] = useState<IImageGallery>();
	const [isPreview, setIsPrevew] = useState(false);

	const {
		formState: { errors },
		watch,
		setValue,
		getValues,
		register,
	} = useFormContext<IAddGalleryRequest>();
	const status = watch('status');

	const handleRemoveImage = (image: IImageGallery) => {
		const newImages = props.images.map((e) => e);
		let index = props.images.findIndex(
			(element) => element.imageUrl === image.imageUrl
		);
		if (index !== -1) {
			newImages.splice(index, 1);
		}

		props.setImages(newImages);
	};

	return (
		<Container>
			<div className="grid gap-y-4">
				<Input
					label={t(translations.miniCMSSetting.galleryName)}
					placeholder={t(translations.placeHolder.enterGalleryName)}
					maxLength={256}
					error={errors.title?.message}
					{...register('title', {
						required: t(translations.miniCMSSetting.messageGalleryName),
					})}
					disabled={!isEmpty(getValues('description'))}
				/>
				<div className="grid grid-cols-2">
					<Switch
						label={t(translations.status)}
						value={status === 1 ? true : false}
						onChange={(value) => {
							setValue('status', value ? 1 : 0);
						}}
						primary
						fullWidth
					/>
				</div>
				<div>
					<span className="text-md font-medium px-1">
						{t(translations.miniCMSSetting.textImage)}
					</span>
					<div className="grid grid-cols-2 my-4">
						<div>
							<Button
								title={t(translations.upload)}
								iconBefore={<Icon path={mdiTrayArrowUp} />}
								small
								primary
								onClick={() => {
									selectFiles({ accept: 'image/*', multiple: true }, (data) => {
										const fileUploads = data as FileUpload[];
										const imageUpload: IImageGallery[] = fileUploads.map(
											(file) => {
												return {
													imageUrl: `${file.source}`,
													file: file.file,
												};
											}
										);
										const newImages = props.images.concat(imageUpload);
										props.setImages(newImages);
									});
								}}
							/>
						</div>
						<div className="font-semibold">
							<span>{t(translations.miniCMSSetting.bestViewImage)}</span>
							<br />
							<span>{t(translations.miniCMSSetting.allowImage)}</span>
						</div>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-4">
				{!isEmpty(props.images) &&
					props.images.map((x, index) => {
						return (
							<div key={index} className="shadow-md relative">
								<img
									src={x.imageUrl}
									alt="imageSlider"
									onClick={() => {
										setPreview(x);
										setIsPrevew(true);
									}}
								/>
								<div
									className="text-ERROR absolute -right-3 -top-3 bg-opacity-20 bg-black rounded-full p-2"
									onClick={() => handleRemoveImage(x)}
								>
									<Icon path={mdiDelete} className="cursor-pointer" />
								</div>
							</div>
						);
					})}
			</div>
			<div
				className={clsxm(
					'hidden absolute w-screen h-screen bg-black bg-opacity-40 top-0 -left-[200%]',
					isPreview && 'flex justify-center items-center'
				)}
			>
				{preview && (
					<div className="relative">
						<img src={preview.imageUrl} alt="imageSlider" className="" />
						<div
							className="text-ERROR absolute bg-opacity-60 bg-black rounded-full p-2 -right-5 -top-5"
							onClick={() => {
								setIsPrevew(false);
								setPreview(undefined);
							}}
						>
							<Icon path={mdiClose} className="cursor-pointer" />
						</div>
					</div>
				)}
			</div>
		</Container>
	);
};
