import { Modal } from 'components/atoms';
import { SearchInput } from 'components/molecules';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { IService } from 'models';
import { Service } from 'pages/Booking/components/Service';
import React, { useState } from 'react';
import { t, translations } from 'utils';

interface IServicePickerModal {
	visible?: boolean;
	onSelectService?: (service: IService) => void;
	onCancel?: () => void;
}
export const ServicePickerModal = ({
	visible,
	onSelectService,
	onCancel,
}: IServicePickerModal) => {
	const services = useAppSelector((state) => state.ServiceReducer.services);
	const [searchText, setSearchText] = useState<string>();
	const listServices = services
		.slice()
		.sort((sA, sB) =>
			(sA.indexNumber || 0) > (sB.indexNumber || 0)
				? 1
				: sA.indexNumber === sB.indexNumber
				? 0
				: -1
		)
		.filter(
			(x) =>
				!_.isEmpty(x.itemCategoryId) &&
				(_.isEmpty(searchText) ||
					x.name
						.toLocaleLowerCase()
						.includes(`${searchText || ''}`.toLocaleLowerCase()))
		);
	return (
		<Modal
			isOpen={visible || false}
			title={`Select Service`}
			content={
				<div className="h-full col-span-5 flex flex-col bg-white rounded-r-lg pl-4 p-4 overflow-hidden ">
					<div className="flex justify-between align-middle mb-2 ">
						<h3 className="mt-1">{t(translations.mainBooking.services)}</h3>
						<div className="w-1/2">
							<SearchInput
								placeholder={t(translations.placeHolder.searchService)}
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
							/>
						</div>
					</div>
					<div className="flex-1 overflow-y-auto pb-2">
						<div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3  gap-2 w-full  ">
							{_.orderBy(listServices, 'itemCategoryId').map((x) => {
								return (
									<Service
										onSelect={() => {
											onSelectService && onSelectService(x);
										}}
										service={x}
									/>
								);
							})}
						</div>
					</div>
				</div>
			}
			onCancel={onCancel}
		/>
	);
};
