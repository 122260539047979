import { ButtonGroup, Card, Input, Select, Switch } from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import React, { useEffect, useMemo, useState } from 'react';
import {
	EarningPointMethod,
	ExtraPointType,
	PointExpirationType,
} from 'utils/Consts';
import { t, translations } from 'utils';
import clsxm from 'clsxs/clsxm';
import { useForm, useFormContext } from 'react-hook-form';
import { DatePicker } from 'components/molecules/DatePicker';
import { IExtraPointSetting, ILoyaltySetting } from 'models/ILoyalty';
import { AlertHelper, BookingHelper, StringHelper } from 'helpers';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import LoyaltyApiService from 'services/LoyaltyApiService';
import { useHistory } from 'react-router-dom';
interface IEarningPointType {
	id: EarningPointMethod;
	label: string;
}
const EarningPointTypes: IEarningPointType[] = [
	{
		id: EarningPointMethod.SALE,
		label: t(translations.loyaltySetting.sale),
	},
	{
		id: EarningPointMethod.INVOICE,
		label: t(translations.loyaltySetting.invoice),
	},
];
interface ILoyaltySetupForm {
	pointSystem: number;
	pointExchangeRate: number;
	enableExpiry: boolean;
	expirationType: number;
	expirationPeriod: number;
	resetType: number;
	periodType: number;
	fromDate: string;
	daysOfWeek: string;
	daysOfMonth: string;
	monthsOfYear: string;
	extraPointReferring?: IExtraPointSetting;
	extraPointMakeOnlineBooking?: IExtraPointSetting;
	extraPointCustomerBirthday?: IExtraPointSetting;
}
const Criteria = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<ILoyaltySetupForm>();
	const pointSystem = watch('pointSystem');
	return (
		<Card
			title={t(translations.loyaltySetting.pointCriteria)}
			className="basis-1/3"
		>
			<ButtonGroup<IEarningPointType>
				buttons={EarningPointTypes}
				value={EarningPointTypes.find((x) => x.id === pointSystem)}
				onChange={(t) => {
					setValue('pointSystem', t.id);
				}}
				valueField={'id'}
				titleField="label"
				buttonClassNames={['flex-1', 'flex-1']}
				activeClassName={'flex-1'}
			/>
			<div className="grid grid-cols-2 text-sm">
				<span className="mx-2">
					<b>{t(translations.loyaltySetting.sale)}: </b>
					{t(translations.loyaltySetting.descriptionSale)}
				</span>
				<span className="mx-2">
					<b>{t(translations.loyaltySetting.invoice)}: </b>
					{t(translations.loyaltySetting.descriptionInvoice)}
				</span>
			</div>
			<div className="my-4">
				<p className={clsxm('card-title m-0')}>
					{t(translations.loyaltySetting.generalRate)}
				</p>
				<p className={clsxm('m-0 text-sm')}>
					{t(translations.loyaltySetting.subGeneralRate)}
				</p>
			</div>
			<div className="md:flex">
				<div className="flex md:basis-2/3">
					{pointSystem === EarningPointMethod.SALE ? (
						<Input
							label={t(translations.loyaltySetting.spendingAmount)}
							alignRight
							renderAfter={<>$</>}
							error={errors.pointExchangeRate?.message}
							type="number"
							{...register('pointExchangeRate', {
								required: t(
									translations.loyaltySetting.messageSpendingAmountRequired
								),
								min: {
									value: 0,
									message: t(translations.validate.lessThanZero),
								},
							})}
						/>
					) : (
						<Input
							label={t(translations.loyaltySetting.usedInvoice)}
							alignRight
							error={errors.pointExchangeRate?.message}
							{...register('pointExchangeRate', {
								required: t(
									translations.loyaltySetting.messageUsedInvoiceRequired
								),
								min: {
									value: 0,
									message: t(translations.validate.lessThanZero),
								},
							})}
						/>
					)}
					<div className="basis-1/2 relative">
						<span className="font-semibold text-xl top-12 left-2 absolute">
							= 1 point
						</span>
					</div>
				</div>
			</div>
		</Card>
	);
};

const ExtraCriteria = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<ILoyaltySetupForm>();
	const extraPointReferring = watch('extraPointReferring');
	const extraPointMakeOnlineBooking = watch('extraPointMakeOnlineBooking');
	const extraPointCustomerBirthday = watch('extraPointCustomerBirthday');

	const [usePercentageNewCustomer, setUsePercentageNewCustomer] =
		useState(false);
	const [usePercentageMakeBookingOnline, setUsePercentageMakeBookingOnline] =
		useState(false);
	const [usePercentageCustomerBirthday, setUsePercentageCustomerBirthday] =
		useState(false);
	useEffect(() => {
		setUsePercentageNewCustomer(
			_.isEmpty(extraPointReferring)
				? false
				: extraPointReferring?.extraPointRate !== 0
		);
		setUsePercentageMakeBookingOnline(
			_.isEmpty(extraPointMakeOnlineBooking)
				? false
				: extraPointMakeOnlineBooking?.extraPointRate !== 0
		);
		setUsePercentageCustomerBirthday(
			_.isEmpty(extraPointCustomerBirthday)
				? false
				: extraPointCustomerBirthday?.extraPointRate !== 0
		);
	}, [
		extraPointReferring,
		extraPointMakeOnlineBooking,
		extraPointCustomerBirthday,
	]);
	return (
		<Card title={t(translations.loyaltySetting.extraPointRate)}>
			<div className="grid md:grid-cols-3 gap-4">
				<div>
					<Switch
						label={t(translations.loyaltySetting.newCustomerLabel)}
						value={!_.isEmpty(extraPointReferring)}
						onChange={(value) => {
							setValue(
								'extraPointReferring',
								value
									? {
											extraPoint: 0,
											extraPointRate: 0,
											extraPointType: ExtraPointType.ReferringNewCustomer,
									  }
									: undefined
							);
							setUsePercentageNewCustomer(false);
						}}
						primary
						subLabel={t(translations.loyaltySetting.newCustomerSubLabel)}
					/>
					{_.isEmpty(extraPointReferring) ? null : (
						<>
							<Switch
								label={t(translations.loyaltySetting.giftPointPercentage)}
								value={usePercentageNewCustomer}
								onChange={(value) => {
									setUsePercentageNewCustomer(value);
								}}
								primary
							/>
							<div className="grid grid-cols-2">
								{usePercentageNewCustomer ? (
									<Input
										alignRight
										renderAfter={<>%</>}
										type="number"
										error={errors.extraPointReferring?.extraPointRate?.message}
										{...register('extraPointReferring.extraPointRate', {
											required: t(
												translations.loyaltySetting.messagePointRateRequire
											),
											min: {
												value: 0,
												message: t(translations.validate.lessThanZero),
											},
											max: {
												value: 100,
												message: t(
													translations.validate.greaterThanHundredPercent
												),
											},
										})}
									/>
								) : (
									<Input
										alignRight
										renderAfter={<>P</>}
										disabled={_.isEmpty(extraPointReferring)}
										type="number"
										error={errors.extraPointReferring?.extraPoint?.message}
										{...register('extraPointReferring.extraPoint', {
											required: t(
												translations.loyaltySetting.messagePointRequire
											),
											min: {
												value: 0,
												message: t(translations.validate.lessThanZero),
											},
										})}
									/>
								)}
							</div>
						</>
					)}
				</div>
				<div>
					<Switch
						label={t(translations.loyaltySetting.makeOnlineBookingLabel)}
						subLabel={t(translations.loyaltySetting.makeOnlineBookingSubLabel)}
						value={!_.isEmpty(extraPointMakeOnlineBooking)}
						onChange={(value) => {
							setValue(
								'extraPointMakeOnlineBooking',
								value
									? {
											extraPoint: 0,
											extraPointRate: 0,
											extraPointType: ExtraPointType.MakeBookingOnline,
									  }
									: undefined
							);
						}}
						primary
					/>
					{_.isEmpty(extraPointMakeOnlineBooking) ? null : (
						<>
							<Switch
								label={t(translations.loyaltySetting.giftPointPercentage)}
								value={usePercentageMakeBookingOnline}
								onChange={(value) => {
									setUsePercentageMakeBookingOnline(value);
								}}
								primary
							/>
							<div className="grid grid-cols-2">
								{usePercentageMakeBookingOnline ? (
									<Input
										alignRight
										renderAfter={<>%</>}
										type="number"
										error={
											errors.extraPointMakeOnlineBooking?.extraPointRate
												?.message
										}
										{...register('extraPointMakeOnlineBooking.extraPointRate', {
											required: t(
												translations.loyaltySetting.messagePointRateRequire
											),
											min: {
												value: 0,
												message: t(translations.validate.lessThanZero),
											},
											max: {
												value: 100,
												message: t(
													translations.validate.greaterThanHundredPercent
												),
											},
										})}
									/>
								) : (
									<Input
										alignRight
										renderAfter={<>P</>}
										disabled={_.isEmpty(extraPointMakeOnlineBooking)}
										type="number"
										error={
											errors.extraPointMakeOnlineBooking?.extraPoint?.message
										}
										{...register('extraPointMakeOnlineBooking.extraPoint', {
											required: t(
												translations.loyaltySetting.messagePointRequire
											),
											min: {
												value: 0,
												message: t(translations.validate.lessThanZero),
											},
										})}
									/>
								)}
							</div>
						</>
					)}
				</div>
				<div className="flex flex-col justify-between">
					<Switch
						label={t(translations.loyaltySetting.customerBirthdayLabel)}
						subLabel={t(translations.loyaltySetting.customerBirthdaySubLabel)}
						value={!_.isEmpty(extraPointCustomerBirthday)}
						onChange={(value) => {
							setValue(
								'extraPointCustomerBirthday',
								value
									? {
											extraPoint: 0,
											extraPointRate: 0,
											extraPointType: ExtraPointType.CustomerBirthday,
									  }
									: undefined
							);
						}}
						primary
					/>
					{isEmpty(extraPointCustomerBirthday) ? null : (
						<div>
							<Switch
								label={t(translations.loyaltySetting.giftPointPercentage)}
								value={usePercentageCustomerBirthday}
								onChange={(value) => {
									setUsePercentageCustomerBirthday(value);
								}}
								primary
							/>
							<div className="grid grid-cols-2">
								{usePercentageCustomerBirthday ? (
									<Input
										alignRight
										renderAfter={<>%</>}
										type="number"
										error={
											errors.extraPointCustomerBirthday?.extraPointRate?.message
										}
										{...register('extraPointCustomerBirthday.extraPointRate', {
											required: t(
												translations.loyaltySetting.messagePointRateRequire
											),
											min: {
												value: 0,
												message: t(translations.validate.lessThanZero),
											},
											max: {
												value: 100,
												message: t(
													translations.validate.greaterThanHundredPercent
												),
											},
										})}
									/>
								) : (
									<Input
										alignRight
										renderAfter={<>P</>}
										disabled={_.isEmpty(extraPointCustomerBirthday)}
										type="number"
										error={
											errors.extraPointCustomerBirthday?.extraPoint?.message
										}
										{...register('extraPointCustomerBirthday.extraPoint', {
											required: t(
												translations.loyaltySetting.messagePointRequire
											),
											min: {
												value: 0,
												message: t(translations.validate.lessThanZero),
											},
										})}
									/>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</Card>
	);
};
const ExpiredType = [
	{
		id: PointExpirationType.Period,
		name: t(translations.loyaltySetting.periodExpired),
	},
	{
		id: PointExpirationType.Frequency,
		name: t(translations.loyaltySetting.directExpiredDate),
	},
];
enum PeriodType {
	Yearly = 1,
	SemiYearly,
	Quarterly,
	Monthly,
	SemiMonthly,
	Weekly,
	Daily,
}
const REPEAT_PERIOD_TYPE = [
	{
		id: PeriodType.Weekly,
		label: t(translations.holiday.weekly),
	},
	{
		id: PeriodType.Monthly,
		label: t(translations.holiday.monthly),
	},
	{
		id: PeriodType.Yearly,
		label: t(translations.loyaltySetting.yearly),
	},
];
const DAYS_OF_WEEKS = Array.from(new Array(7).keys()).map((__, index) => ({
	id: index,
	day: StringHelper.getDayOfWeek(index),
}));
const DAYS_OF_MONTH = Array.from(new Array(31).keys()).map((__, index) => ({
	id: index + 1,
	day: `${index + 1}`,
}));
const MONTH_OF_YEAR = Array.from(new Array(12).keys()).map((__, index) => ({
	id: index + 1,
	day: StringHelper.getMonthOfYear(index + 1),
}));

const Expiry = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<ILoyaltySetupForm>();
	const enableExpiry = watch('enableExpiry');
	const expirationType = watch('expirationType');
	const periodType = watch('periodType');
	const selectedDate = watch('fromDate');
	const resetType = watch('resetType');
	const daysOfWeek = watch('daysOfWeek');
	const daysOfMonth = watch('daysOfMonth');
	const monthsOfYear = watch('monthsOfYear');
	const REPEAT_VALUE = useMemo(() => {
		switch (periodType) {
			case PeriodType.Weekly:
				return DAYS_OF_WEEKS;
			case PeriodType.Monthly:
				return DAYS_OF_MONTH;
			default:
				return MONTH_OF_YEAR;
		}
	}, [periodType]);
	return (
		<Card
			className="basis-2/3"
			title={t(translations.loyaltySetting.pointExpiry)}
		>
			<div className="flex">
				<Switch
					className="basis-1/3"
					label={t(translations.loyaltySetting.applyExpiry)}
					value={enableExpiry}
					onChange={(value) => {
						setValue('enableExpiry', value);
					}}
					fullWidth
					primary
				/>
			</div>
			{enableExpiry && (
				<>
					<div className="grid md:grid-cols-3 gap-4">
						<Select<{ id: number; name: string }>
							options={ExpiredType}
							titleField="name"
							keyField="id"
							label={t(translations.loyaltySetting.expiredType)}
							onChange={(value) => {
								setValue('expirationType', value.id);
							}}
							valueId={expirationType}
							fullWidth
						/>
						{expirationType === PointExpirationType.Period ? (
							<Input
								label={t(translations.loyaltySetting.expirationPeriodLabel)}
								alignRight
								renderAfter={<>month(s)</>}
								type="number"
								error={errors.expirationPeriod?.message}
								{...register('expirationPeriod', {
									required: t(
										translations.loyaltySetting.messageExpirationPeriodLabel
									),
									min: {
										value: 0,
										message: t(translations.validate.lessThanZero),
									},
								})}
							/>
						) : (
							<div>
								<label className="label font-medium ">
									<span className="label-text text-md">
										{t(translations.listGiftCard.expiredDate)}
									</span>
									<span className="text-sm font-normal">
										{t(translations.loyaltySetting.expiredDateSubLabel)}
									</span>
								</label>
								<DatePicker
									onSelectDate={(value) =>
										setValue(
											'fromDate',
											BookingHelper.convertDateRequest(value)
										)
									}
									selectedDate={moment(selectedDate)}
									className="w-full"
								/>
							</div>
						)}
					</div>
					{expirationType === PointExpirationType.Frequency && (
						<>
							<div className="grid md:grid-cols-3 gap-4">
								<Select<{ id: number; label: string }>
									options={REPEAT_PERIOD_TYPE}
									titleField="label"
									keyField="id"
									label={t(translations.holiday.repeat)}
									subLabel={t(translations.loyaltySetting.repeatSubLabel)}
									onChange={(value) => {
										setValue('periodType', value.id);
										switch (value.id) {
											case PeriodType.Weekly:
												setValue('daysOfWeek', '1');
												break;
											case PeriodType.Monthly:
												setValue('daysOfMonth', '1');
												break;
											case PeriodType.Yearly:
												setValue('monthsOfYear', '1');
												break;
											default:
												break;
										}
									}}
									valueId={periodType}
									fullWidth
								/>
							</div>
							<div className="mt-4">
								<ButtonGroup<{ id: number; day: string }>
									buttons={REPEAT_VALUE}
									value={REPEAT_VALUE.find(
										(x) =>
											x.id ===
											(periodType === PeriodType.Weekly
												? parseInt(daysOfWeek)
												: periodType === PeriodType.Monthly
												? parseInt(daysOfMonth)
												: parseInt(monthsOfYear))
									)}
									onChange={(t) => {
										switch (periodType) {
											case PeriodType.Weekly:
												setValue('daysOfWeek', t.id.toString());
												break;
											case PeriodType.Monthly:
												setValue('daysOfMonth', t.id.toString());
												break;
											default:
												setValue('monthsOfYear', t.id.toString());
												break;
										}
									}}
									valueField={'id'}
									titleField="day"
									className="gap-y-2"
								/>
							</div>
							<div className="mt-8 grid grid-cols-2">
								<label className="label font-medium col-span-2">
									<span className="label-text text-md">
										{t(translations.loyaltySetting.resetType)}
									</span>
								</label>
								<div>
									<ButtonGroup<{ id: number; label: string }>
										buttons={[
											{ id: 1, label: t(translations.loyaltySetting.resetAll) },
											{
												id: 2,
												label: t(translations.loyaltySetting.resetToCurrent),
											},
										]}
										value={[
											{ id: 1, label: t(translations.loyaltySetting.resetAll) },
											{
												id: 2,
												label: t(translations.loyaltySetting.resetToCurrent),
											},
										].find((x) => x.id === resetType)}
										onChange={(t) => {
											setValue('resetType', t.id);
										}}
										valueField={'id'}
										titleField="label"
										buttonClassNames={['flex-1', 'flex-1']}
										activeClassName="flex-1"
									/>
									<div className="grid grid-cols-2 text-sm">
										<span className="mx-2">
											{t(translations.loyaltySetting.resetAllSub)}
										</span>
										<span className="mx-2">
											{t(translations.loyaltySetting.resetToCurrentSub)}
										</span>
									</div>
								</div>
							</div>
						</>
					)}
				</>
			)}
		</Card>
	);
};

export const LoyaltySetting = () => {
	const [loyaltySetting, setLoyaltySetting] = useState<ILoyaltySetting>();
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const form = useForm<ILoyaltySetupForm>({
		reValidateMode: 'onChange',
		mode: 'onChange',
		defaultValues: {
			pointSystem: EarningPointMethod.SALE,
			expirationType: PointExpirationType.Period,
			fromDate: BookingHelper.convertDateRequest(moment().startOf('date')),
			periodType: PeriodType.Yearly,
			daysOfMonth: '1',
			daysOfWeek: '1',
			monthsOfYear: '1',
			resetType: 1,
		},
	});
	const getLoyaltySetting = async () => {
		setLoading(true);
		try {
			const res = await LoyaltyApiService.getLoyaltySetting();
			if (res.succeeded && res.data) {
				setLoyaltySetting(res.data);
				form.reset(res.data);
				res.data.extraPointSettings.forEach((extra) => {
					switch (extra.extraPointType) {
						case ExtraPointType.ReferringNewCustomer:
							form.setValue('extraPointReferring', extra);
							break;
						case ExtraPointType.MakeBookingOnline:
							form.setValue('extraPointMakeOnlineBooking', extra);
							break;
						case ExtraPointType.CustomerBirthday:
							form.setValue('extraPointCustomerBirthday', extra);
							break;
						default:
							break;
					}
				});
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const getExtraSettingId = (type: number) => {
		return loyaltySetting?.extraPointSettings.find(
			(element) => element.extraPointType === type
		)?.id;
	};
	const submitForm = async (formData: ILoyaltySetupForm) => {
		let extraPointSettings: IExtraPointSetting[] = [];
		if (!_.isEmpty(formData.extraPointReferring)) {
			extraPointSettings.push({
				id: loyaltySetting
					? getExtraSettingId(formData.extraPointReferring!.extraPointType)
					: undefined,
				...formData.extraPointReferring!,
			});
		}
		if (!_.isEmpty(formData.extraPointMakeOnlineBooking)) {
			extraPointSettings.push({
				id: loyaltySetting
					? getExtraSettingId(
							formData.extraPointMakeOnlineBooking!.extraPointType
					  )
					: undefined,
				...formData.extraPointMakeOnlineBooking!,
			});
		}
		if (!_.isEmpty(formData.extraPointCustomerBirthday)) {
			extraPointSettings.push({
				id: loyaltySetting
					? getExtraSettingId(
							formData.extraPointCustomerBirthday!.extraPointType
					  )
					: undefined,
				...formData.extraPointCustomerBirthday!,
			});
		}
		const data = {
			id: loyaltySetting && loyaltySetting.id,
			pointSystem: formData.pointSystem,
			pointExchangeRate: formData.pointExchangeRate,
			enableExpiry: formData.enableExpiry,
			expirationType: formData.expirationType,
			expirationPeriod: formData.expirationPeriod,
			resetType: formData.resetType,
			periodType: formData.periodType,
			fromDate: formData.fromDate,
			daysOfWeek:
				formData.periodType === PeriodType.Weekly
					? formData.daysOfWeek
					: undefined,
			daysOfMonth:
				formData.periodType === PeriodType.Monthly
					? formData.daysOfMonth
					: undefined,
			monthsOfYear:
				formData.periodType === PeriodType.Yearly
					? formData.monthsOfYear
					: undefined,
			extraPointSettings,
		};
		setLoading(true);
		try {
			const res = loyaltySetting
				? await LoyaltyApiService.updateLoyaltySetting(data)
				: await LoyaltyApiService.createLoyaltySetting(data);
			if (res.succeeded && res.data) {
				AlertHelper.showSuccess(
					t(translations.loyaltySetting.messageUpdateSettingSuccess)
				);
				history.push('/loyalty-rank');
			} else {
				AlertHelper.showError(res);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getLoyaltySetting();
	}, []);

	return (
		<DetailPageLayout<ILoyaltySetupForm>
			form={form}
			onSave={form.handleSubmit(submitForm)}
			loading={loading}
		>
			<div className="flex gap-4">
				<Criteria />
				<Expiry />
			</div>
			<ExtraCriteria />
		</DetailPageLayout>
	);
};
