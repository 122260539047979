import { mdiCash, mdiPercent } from '@mdi/js';
import { Button, ButtonGroup, Icon } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { useAppSelector } from 'helpers/hookHelpers';
import I18n from 'i18n-js';
import { useCheckOutContext } from 'pages/CheckOut/context';
import React, { useRef, useState } from 'react';
import { t, translations, _t } from 'utils';
import { DiscounterType } from 'utils/Consts';

interface ISharingMethod {
	id: DiscounterType;
	label: string;
}

const DiscountMethods: ISharingMethod[] = [
	{
		id: DiscounterType.BOTH,
		label: I18n.t(_t(translations.checkout.both)),
	},
	{
		id: DiscounterType.OWNER_ONLY,
		label: I18n.t(_t(translations.checkout.ownerOnly)),
	},
	{
		id: DiscounterType.EMPLOYEE_ONLY,
		label: I18n.t(_t(translations.checkout.employeeOnly)),
	},
];

const DiscountType = [
	{
		id: 'percent',
		label: t(translations.percent),
	},
	{
		id: 'money',
		label: t(translations.money),
	},
];

export const DiscountModal = (props: { onCancel: () => void }) => {
	const { setDiscount, setDiscounterType, totalAmountBillDetails, totalTax } =
		useCheckOutContext();
	const discountBeforeTax = useAppSelector(
		(state) => state.BranchReducer.currentBranch?.discountBeforeTax
	);
	const numpadRef = useRef<INumPadRef>();
	const [discounterType, setDiscounter] = useState(DiscountMethods[0]);
	const [type, setType] = useState(DiscountType[0]);
	const [discountValue, setDiscountValue] = useState(0);
	const onUpdateText = (text: string) => {
		if (numpadRef) {
			numpadRef.current?.setText(text);
		}
	};
	const tax = discountBeforeTax ? 0 : totalTax();

	const onConfirm = () => {
		setDiscount(
			type.id === 'money'
				? discountValue
				: (totalAmountBillDetails() + tax) * (discountValue / 100)
		);
		setDiscounterType(discounterType.id);
	};
	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			isMoney={type.id === 'money'}
			onChange={(x) => {
				if (
					(type.id === 'money' &&
						parseFloat(x) > totalAmountBillDetails() + tax) ||
					(type.id === 'percent' && parseFloat(x) > 100)
				) {
					const maxValue =
						type.id === 'money' ? totalAmountBillDetails() + tax : 100;
					setDiscountValue(maxValue);
					onUpdateText(`${maxValue}`);
				} else {
					setDiscountValue(parseFloat(x) || 0);
				}
			}}
			showNumpad
			unit={type.id === 'money' ? 'Usd' : '%'}
			title={t(translations.checkout.discount)}
			onConfirm={(x) => {
				onConfirm();
				props.onCancel();
			}}
		>
			<div className="flex flex-col gap-2 h-full flex-1 p-4">
				<ButtonGroup<{ id: string; label: string }>
					buttons={DiscountType}
					icons={[<Icon path={mdiPercent} />, <Icon path={mdiCash} />]}
					buttonClassNames={['percent flex-1', 'money flex-1']}
					activeClassName={'flex-1'}
					value={type}
					onChange={(t) => {
						setDiscountValue(0);
						onUpdateText('');
						setType(t);
					}}
					valueField={'id'}
					titleField="label"
				/>
				<h5 className="font-bold">Sharing Method</h5>
				<ButtonGroup<ISharingMethod>
					buttons={DiscountMethods}
					buttonClassNames={[
						'both flex-1',
						'only-owner flex-1',
						'only-staff flex-1',
					]}
					activeClassName={'flex-1'}
					small
					value={discounterType}
					onChange={(t) => {
						setDiscounter(t);
					}}
					valueField={'id'}
					titleField="label"
				/>
				<div className="flex flex-1 items-end justify-end">
					<Button
						id="clear"
						onClick={() => {
							if (numpadRef) {
								numpadRef.current?.setText('');
							}
						}}
						small
						title={t(translations.clear)}
						className="btn-outline btn-error"
					/>
				</div>
			</div>
		</ModalNumPad>
	);
};
