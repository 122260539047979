import { ReportFieldValue } from 'pages/FinancialReport/components/ReportFieldValue';
import React from 'react';
import { t, translations } from 'utils';
import { usePayrollDetailContext } from './payrollDetailContext';

export const ServedServices = () => {
	const { report } = usePayrollDetailContext();
	return (
		<div className="h-full overflow-y-auto">
			<ReportFieldValue
				title={t(translations.payrollDetailReport.name)}
				value={t(translations.payrollDetailReport.times)}
				bold
				big
        className='bg-base-200 p-4'
			/>
			{report![0].servedServices?.map((e) => (
				<ReportFieldValue
					title={e.serviceName || ''}
					value={e.quantity?.toString() || ''}
          className='p-4'
          />
			))}
		</div>
	);
};
