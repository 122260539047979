import { IBaseModel } from 'models/Base.model';
import { IBookingDetail } from 'models/IBookingDetail';
import { ICustomer } from 'models/ICustomer';
import { IEmployee } from 'models/IEmployee';
import { DiscounterType } from 'utils/Consts';

export interface IBooking extends IBaseModel {
	date: Date | string;
	startTime: Date;
	endTime: Date;
	startTimeExpected: Date;
	endTimeExpected: Date;
	duration: number;
	stylist?: IEmployee;
	stylistId?: string;
	bookingDetails: Partial<IBookingDetail>[];
	customer: ICustomer;
	totalAmount: number;
	realAmount: number;
	paymentStatus: number;
	discount: number;
	discountPercent: number;
	discounterType?: DiscounterType;
	voucherCode: string;
	note: string;
	status: number;
	branchId?: string;
	shopId?: string;
	customerId?: string;
	qrCode?: string;
	totalQuantity?: number;
	totalTax?: number;
	inputFrom?: EBookingInputFrom;
	bookingHistories: any;
	totalExtraAmount: number;
	bookingNumber?: number;
	bookingType?: EBookingType;
	promotionIds?: string;
	couponCodes?: string;
}

export enum EBookingInputFrom {
	OnlineSite,
	AdminWebsite,
	POS,
	StylistApp,
	CustomerApp,
	Other,
}

export enum EBookingType {
	WalkIn,
	Appointment,
}
