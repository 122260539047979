import {
	Card,
	Input,
	TextArea,
	Switch,
	ButtonGroup,
	Select,
	Button,
	Modal,
} from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { AlertHelper } from 'helpers';
import { showError } from 'helpers/alertHelper';
import _ from 'lodash';
import { IApiResponse } from 'models';
import { ITextMessage, ITextMessageSendRequest } from 'models/ITextMessage';
import { ITextTemplate } from 'models/ITextTemplate';
import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TextMessageApiService from 'services/TextMessageApiService';
import TextTemplateApiService from 'services/TextTemplateApiService';
import { _t, translations, I18n, t } from 'utils';

type ITextMessageForm = ITextMessage & {};

const getMonth = (value: number) => {
	switch (value) {
		case 1:
			return I18n.t(_t(translations.month.january));
		case 2:
			return I18n.t(_t(translations.month.february));
		case 3:
			return I18n.t(_t(translations.month.march));
		case 4:
			return I18n.t(_t(translations.month.april));
		case 5:
			return I18n.t(_t(translations.month.may));
		case 6:
			return I18n.t(_t(translations.month.june));
		case 7:
			return I18n.t(_t(translations.month.july));
		case 8:
			return I18n.t(_t(translations.month.august));
		case 9:
			return I18n.t(_t(translations.month.september));
		case 10:
			return I18n.t(_t(translations.month.october));
		case 11:
			return I18n.t(_t(translations.month.november));
		case 12:
			return I18n.t(_t(translations.month.december));
		default:
			return '';
	}
};

const NOT_VISITED_LIST = [14, 21, 30, 60, 180, 360].map((x) => {
	return {
		value: x,
		title: `${x} ${I18n.t(_t(translations.textMessageDetail.days))}`,
	};
});

const MONTH_LIST = Array.from({ length: 12 }, (_, i) => i + 1).map((x) => {
	return {
		value: x,
		title: getMonth(x),
	};
});

const REVIEW_LIST = [
	{
		value: 0,
		title: '1 - 3 star',
	},
	{
		value: 1,
		title: '4 - 5 star',
	},
];

export const TextMessageDetailPage = () => {
	const location = useLocation();
	const history = useHistory();
	const { id } = useParams() as { id: string };

	const isAdd = location.pathname.includes('add');
	const [loading, setLoading] = useState(false);
	const [templates, setTemplates] = useState<ITextTemplate[]>([]);
	const [isPreview, setIsPreview] = useState(false);
	const [status, setStatus] = useState<number | undefined>(0);
	const form = useForm<ITextMessageForm>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	useEffect(() => {
		if (!isAdd) {
			fetchTextMessageDetail();
		}
		fetchTextMessageTemplates();
	}, []);

	const fetchTextMessageDetail = async () => {
		setLoading(true);
		try {
			const result = (await TextMessageApiService.getTextMessageById(
				id
			)) as IApiResponse<ITextMessage>;
			if (result.succeeded) {
				const data = result.data;
				setStatus(data?.status);
				form.reset({
					birthdayMonth: data?.birthdayMonth,
					content: data?.content,
					marketingTextMessageTemplateId: data?.marketingTextMessageTemplateId,
					reviewGroup: data?.reviewGroup,
					haveNotVisitedIn: data?.haveNotVisitedIn,
					title: data?.title,
					sendToAllCustomers: data?.sendToAllCustomers,
					id: data?.id,
				});
			}
			setLoading(false);
		} catch (error) {}
	};
	const fetchTextMessageTemplates = async () => {
		setLoading(true);

		const result =
			(await TextTemplateApiService.getTemplates()) as IApiResponse<
				ITextTemplate[]
			>;
		setLoading(false);

		if (result.succeeded) {
			setTemplates(result.data || []);
		} else {
			showError(result);
		}
	};

	const handleSend = async () => {
		const data: Partial<ITextMessageSendRequest> = {
			marketingTextMessageIds: [`${id}`],
		};
		setLoading(true);
		const result = await TextMessageApiService.sendTextMessage(data);
		setLoading(false);

		if (result.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.textMessageDetail.messageSendSuccess))
			);
			//history.goBack();
		} else {
			setLoading(false);
			AlertHelper.showError(result);
		}
		setLoading(false);
	};

	const submitForm = async (formData: ITextMessageForm) => {
		setLoading(true);
		const data = {
			id: formData.id,
			title: formData.title,
			marketingTextMessageTemplateId: formData.marketingTextMessageTemplateId,
			content: formData.content,
			sendToAllCustomers: formData?.sendToAllCustomers,
			haveNotVisitedIn: formData?.haveNotVisitedIn,
			birthdayMonth: formData?.birthdayMonth,
			reviewGroup: formData?.reviewGroup,
		};

		const res = isAdd
			? await TextMessageApiService.addTextMessage(data)
			: await TextMessageApiService.editTextMessage(data);
		setLoading(false);

		if (res.succeeded) {
			AlertHelper.showSuccess(
				isAdd
					? I18n.t(
							_t(translations.textMessageDetail.messageAddTextMessageSuccess)
					  )
					: I18n.t(
							_t(translations.textMessageDetail.messageUpdateTextMessageSuccess)
					  )
			);

			//history.goBack();
		} else {
			AlertHelper.showError(res);
		}
	};

	return (
		<DetailPageLayout<ITextMessageForm>
			title={I18n.t(
				_t(
					isAdd
						? translations.textMessageDetail.addNew
						: translations.textMessageDetail.editTextMessage
				)
			)}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			loading={loading}
			hideSave={status === 0 ? false : true}
			extraRight={
				isAdd ? (
					<Button
						className="btn btn-outline"
						small
						title={t(translations.templateDetail.preview)}
						onClick={(e) => {
							setIsPreview(true);
						}}
					/>
				) : (
					<>
						<Button
							className="btn btn-outline"
							small
							title={t(translations.templateDetail.preview)}
							onClick={(e) => {
								setIsPreview(true);
							}}
						/>
						{status === 0 ? (
							<Button
								className="btn btn-outline"
								small
								primary
								title={t(translations.textMessageDetail.send)}
								onClick={handleSend}
							/>
						) : (
							<></>
						)}
					</>
				)
			}
		>
			<Body
				templates={templates}
				isPreview={isPreview}
				setIsPreview={setIsPreview}
			/>
		</DetailPageLayout>
	);
};

const Body = (props: {
	templates: ITextTemplate[];
	isPreview: boolean;
	setIsPreview: (a: boolean) => void;
}) => {
	const {
		formState: { errors },
		watch,
		setValue,
		control,
		register,
	} = useFormContext<ITextMessageForm>();

	const location = useLocation();

	const isAdd = location.pathname.includes('add');

	useEffect(() => {
		if (!_.isEmpty(props.templates)) {
			setValue('marketingTextMessageTemplateId', props.templates[0].id);
		}
	}, [props.templates]);

	return (
		<>
			<Card
				title={I18n.t(_t(translations.textMessageDetail.metaGroupCustomer))}
				className="mx-48 flex flex-col gap-3"
			>
				<Switch
					value={watch('sendToAllCustomers')}
					onChange={(checked) => {
						setValue('sendToAllCustomers', checked);
					}}
					label="All customer"
					labelClassName="w-fit"
					checkBoxClassName="ml-2"
				/>
				{/* haveNotVisited ------------------------------------------------------------- */}

				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{I18n.t(_t(translations.textMessageDetail.haveNotVisited))}
						</span>
					</label>
					<div>
						<ButtonGroup<{ title: string; value: number }>
							buttons={NOT_VISITED_LIST}
							selectedId={watch('haveNotVisitedIn')}
							valueField="value"
							titleField="title"
							onChange={(e) => {
								setValue('haveNotVisitedIn', e.value);
							}}
							disabled={watch('sendToAllCustomers')}
						/>
					</div>
				</div>
				{/* birthdayMonth ------------------------------------------------------------- */}

				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{I18n.t(_t(translations.textMessageDetail.birthdayMonth))}
						</span>
					</label>
					<div>
						<ButtonGroup<{ title: string; value: number }>
							buttons={MONTH_LIST}
							selectedId={watch('birthdayMonth')}
							valueField="value"
							titleField="title"
							onChange={(e) => {
								setValue('birthdayMonth', e.value);
							}}
							disabled={watch('sendToAllCustomers')}
						/>
					</div>
				</div>

				{/* reviewGroup ------------------------------------------------------------- */}

				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{I18n.t(_t(translations.textMessageDetail.reviewGroup))}
						</span>
					</label>
					<div>
						<ButtonGroup<{ title: string; value: number }>
							buttons={REVIEW_LIST}
							selectedId={watch('reviewGroup')}
							valueField="value"
							titleField="title"
							onChange={(e) => {
								setValue('reviewGroup', e.value);
							}}
							disabled={watch('sendToAllCustomers')}
						/>
					</div>
				</div>
			</Card>

			{/* -------------------------------------------------------------------------------------------------------------------------- */}

			<Card className="mx-48 flex flex-col gap-3">
				<Input
					label={I18n.t(_t(translations.textMessageDetail.messageInputTitle))}
					maxLength={256}
					error={errors.title?.message}
					{...register('title', {
						required: I18n.t(
							_t(translations.textMessageDetail.textMessageTitle)
						),
					})}
					placeholder={I18n.t(_t(translations.textMessageDetail.inputTitle))}
				/>
				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{I18n.t(_t(translations.textMessageDetail.textMessageTemplate))}
						</span>
					</label>
					<div>
						<Select<ITextTemplate>
							options={props.templates}
							keyField={'id'}
							fullWidth
							valueId={
								isAdd
									? !_.isEmpty(props.templates)
										? props.templates[0]?.id
										: ''
									: watch('marketingTextMessageTemplateId')
							}
							onChange={(v) => setValue('marketingTextMessageTemplateId', v.id)}
							className="w-full"
							titleField="name"
							placeHolder={I18n.t(
								_t(translations.textMessageDetail.selectTemplate)
							)}
						/>
					</div>
				</div>

				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{I18n.t(_t(translations.textMessageDetail.messageContent))}
							<span className="text-DELETED">
								{' '}
								Character count: {(watch('content') || '').length}/
								{Math.ceil((watch('content') || '').length / 160)} SMS
							</span>
						</span>
					</label>
					<div>
						<TextArea
							placeholder={I18n.t(
								_t(translations.textMessageDetail.inputContent)
							)}
							error={errors.content?.message}
							{...register('content', {
								required: I18n.t(
									_t(translations.textMessageDetail.messageInputContent)
								),
							})}
							rows={10}
						/>
					</div>
				</div>
			</Card>
			<Modal
				title={t(translations.templateDetail.preview)}
				isOpen={props.isPreview}
				content={
					<div className="p-8">
						<h3 className="text-lg font-semibold my-2">Content</h3>
						<div>{watch('content')}</div>
					</div>
				}
				onCancel={() => props.setIsPreview(false)}
			/>
		</>
	);
};
