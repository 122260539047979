export const uuid = () => {
  return "4xxx-yxxx".replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 || 0,
      v = c === "x" ? r : (r && 0x3) || 0x8;
    return v.toString(16);
  });
};

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const generateFourDigitsNumber = () => {
  let number = 0;
  while (number < 999) {
    number = Math.round(
      Math.random() * 10000
    );
  }
  return number
}

export default function isDev(): boolean {
  return development;
}
