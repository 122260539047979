/* eslint-disable react-hooks/exhaustive-deps */
import clsxm from 'clsxs/clsxm';
import { Card, Input, Select, TextArea } from 'components/atoms';
import {
	ICity,
	IState,
	getAllCity,
	getAllStates,
	getLocationFromZip,
} from 'helpers/geolocation';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { t, translations } from 'utils';
import { IConfigBaseProps, UpdateBranchFormField } from './IConfigBaseProps';

interface IBranchConfigsProps extends IConfigBaseProps {}
export const BranchConfigs = ({ isLoading }: IBranchConfigsProps) => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<UpdateBranchFormField>();
	const [states, setStates] = useState<IState[]>([]);
	const [cities, setCities] = useState<ICity[]>([]);

	const selectedState = watch('address.state');
	const selectedCity = watch('address.city');

	const getStates = async () => {
		const _states = await getAllStates();
		setStates(_states);
	};
	useEffect(() => {
		getStates()
	}, []);

	useEffect(() => {
		if (selectedState) {
			const selectedStateCode = states.find(
				(state) => state.name === selectedState
			)?.isoCode;
			selectedStateCode && onSelectState(selectedStateCode);
		}
	}, [states]);

	useEffect(() => {
		if (selectedState) {
			const selectedStateCode = states.find(
				(state) => state.name === selectedState
			)?.isoCode;
			selectedStateCode && onSelectState(selectedStateCode);
		}
	}, [selectedState]);

	const onChangeZipCode = useCallback<(zipCode: string) => void>(
		_.debounce<(zipCode: string) => void>((zipCode: string) => {
			if (!_.isEmpty(zipCode)) {
				const location = getLocationFromZip(zipCode);
				if (!_.isEmpty(location)) {
					setValue('address.city', location?.city || '');
					setValue(
						'address.state',
						states.find((state) => state.isoCode === location?.state)?.name ||
							''
					);
					onSelectState(location?.state || '');
				}
			}
			// this.onTableChange(1, 'ascend');
		}, 200),
		[states]
	);

	const onSelectState = async (code: string) => {
		const cities = await getAllCity(code);
		const currentState = states?.find((x) => x.isoCode === code);
		if (currentState) {
			setValue('address.state', currentState!.name);
		}
		if (cities) {
			setCities(cities);
			const currentCity = cities.find((x) => x.name === selectedCity);
			if (currentCity) {
				setValue('address.city', currentCity!.name);
			}
		}
	};

	return (
		<Card
			title={t(translations.branchSetting.branchInformation)}
			description={t(translations.branchSetting.subBranch)}
			accordion={true}
			isDefaultAccordion={true}
		>
			<div className={clsxm('grid gap-y-8')}>
				<div className="grid grid-cols-6 gap-x-8">
					<Input
						label={t(translations.branchSetting.branchName)}
						error={errors.name?.message}
						{...register('name', { disabled: isLoading })}
					/>
					<Input
						label={t(translations.branchSetting.phone)}
						error={errors.phone?.message}
						{...register('phone', { disabled: isLoading })}
					/>
				</div>
				<div className="grid grid-cols-1 gap-8">
					<TextArea
						label={t(translations.branchSetting.address)}
						error={errors.address?.street?.message}
						placeholder={t(translations.branchSetting.street)}
						{...register('address.street', { disabled: isLoading })}
					/>

					<div className={clsxm('grid grid-cols-3 gap-x-8 justify-between')}>
						<Select<ICity>
							label={t(translations.branchSetting.city)}
							titleField={'name'}
							keyField={'name'}
							options={cities}
							value={
								cities.find((city) => city.name === selectedCity) || {
									name: 'Select City',
									stateCode: '',
								}
							}
							onClick={(value) => setValue('address.city', value.name)}
							fullWidth
							disabled={isLoading}
						/>
						<Select<IState>
							label={t(translations.branchSetting.state)}
							titleField={'name'}
							keyField={'isoCode'}
							options={states}
							value={
								states.find((state) => state.name === selectedState) || {
									name: 'Select State',
									isoCode: '',
								}
							}
							onClick={(value) => {
								setValue('address.state', value.name);
								onSelectState(value.isoCode!);
							}}
							fullWidth
							disabled={isLoading}
						/>
						<Input
							label={t(translations.partner.zipCode)}
							placeholder={t(translations.partner.zipCode)}
							error={errors.address?.zipCode?.message}
							{...register('address.zipCode', {
								onChange: (e) => {
									onChangeZipCode(e.target.value);
								},
								disabled: isLoading,
							})}
						/>
					</div>
				</div>
				<div className="grid grid-cols-2 gap-x-8">
					<Input
						label={t(translations.branchSetting.googleUrl)}
						error={errors.googleReviewUrl?.message}
						{...register('googleReviewUrl', { disabled: isLoading })}
					/>
					<Input
						label={t(translations.branchSetting.yelpUrl)}
						error={errors.yelpReviewlUrl?.message}
						{...register('yelpReviewlUrl', { disabled: isLoading })}
					/>
				</div>
			</div>
		</Card>
	);
};
