const appId = '446010480900607';
declare global {
	interface Window {
		fbAsyncInit: () => void;
		FB: any;
	}
}

// let FB = window.FB; // ok now
export function initFacebookSdk() {
	return new Promise((resolve) => {
		// wait for facebook sdk to initialize before starting the react app
		console.log('load sdk');
		window.fbAsyncInit = function () {
			window.FB.init({
				appId: appId,
				cookie: true,
				xfbml: true,
				version: 'v14.0',
			});
			// auto authenticate with the api if already logged in with facebook
			// window.FB.getLoginStatus(({ authResponse }: any) => {
			//     if (authResponse) {
			//         // accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
			// 		resolve('s');
			//     } else {
			//         resolve('s');
			//     }
			// });
			resolve('');
		};
		// load facebook sdk script
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s) as any;
			js.id = id;
			js.src = 'https://connect.facebook.net/en_US/sdk.js';
			fjs.parentNode!.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	});
}
