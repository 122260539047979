import { ButtonGroup, Card, Input, Switch } from 'components/atoms';
import { IRequirement } from 'models/IPromotion';
import { I18n, _t, translations } from 'utils';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IPromotionField } from '..';
import clsxm from 'clsxs/clsxm';
import PromotionApiService from 'services/PromotionApiService';
import { IApiResponse } from 'models';
import { isEmpty } from 'lodash';
import { ListServices } from './ListServices';
import { ILoyaltyRank } from 'models/ILoyalRank';

enum PromotionRequirementType {
	CustomerNotVisitedIn = 1,
	CustomerBirthdayIn = 2,
	CustomerGiveReview = 3,
	CustomerLoyaltyRank = 4,
	CustomerSpentAmount = 5,
	CustomerReturnedTimes = 6,
	BookingHasAllOfServices = 10,
	BookingHasOneOfServices = 11,
}

const HaveNotVisited = (props: { data?: string }) => {
	const numberDays = [14, 21, 30, 60, 90, 180, 365];
	const buttons = numberDays.map((number) => {
		return {
			label: `${number} ` + I18n.t(_t(translations.textMessageDetail.days)),
			value: number,
		};
	});

	const { watch, setValue } = useFormContext<IPromotionField>();

	const value = watch('requirementHaveNotVisitedIn');

	useEffect(() => {
		if (props.data) {
			setValue('requirementHaveNotVisitedIn', parseInt(props.data));
		}
	}, []);

	return (
		<div>
			<label className="label font-medium ">
				<span className="label-text text-md">
					{I18n.t(_t(translations.textMessageDetail.haveNotVisited))}
				</span>
			</label>
			<ButtonGroup<{ label: string; value: number }>
				buttons={buttons}
				valueField="value"
				titleField="label"
				selectedId={value}
				onChange={(value) =>
					setValue('requirementHaveNotVisitedIn', value.value)
				}
			/>
		</div>
	);
};

const BirthdayMonth = (props: { data?: string }) => {
	const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const [selected, setSelected] = useState<string[]>([]);
	const { setValue, getValues } = useFormContext<IPromotionField>();

	const onClickButton = (value: string) => {
		let selectedValue = selected;
		let index = selected.findIndex((x: string) => x === value);

		if (index !== -1) {
			selectedValue.splice(index, 1);
		} else {
			selectedValue.push(value);
		}
		setSelected([...selectedValue]);
		setValue('requirementHaveBirthdayIn', selected.toString());
	};

	const renderMonthLabel = (value: number) => {
		switch (value) {
			case 1:
				return I18n.t(_t(translations.month.january));
			case 2:
				return I18n.t(_t(translations.month.february));
			case 3:
				return I18n.t(_t(translations.month.march));
			case 4:
				return I18n.t(_t(translations.month.april));
			case 5:
				return I18n.t(_t(translations.month.may));
			case 6:
				return I18n.t(_t(translations.month.june));
			case 7:
				return I18n.t(_t(translations.month.july));
			case 8:
				return I18n.t(_t(translations.month.august));
			case 9:
				return I18n.t(_t(translations.month.september));
			case 10:
				return I18n.t(_t(translations.month.october));
			case 11:
				return I18n.t(_t(translations.month.november));
			default:
				return I18n.t(_t(translations.month.december));
		}
	};

	useEffect(() => {
		if (props.data) {
			setSelected(props.data?.split(','));
			setValue('requirementHaveBirthdayIn', props.data);
		}
		let data = getValues('requirementHaveBirthdayIn');
		!isEmpty(data) && setSelected(data!.split(','));
	}, []);

	return (
		<div>
			<label className="label font-medium ">
				<span className="label-text text-md">
					{I18n.t(_t(translations.textMessageDetail.birthdayMonth))}
				</span>
			</label>
			<div className="flex flex-wrap gap-x-4">
				{month.map((x) => (
					<button
						key={x}
						onClick={() => onClickButton(x.toString())}
						value={x.toString()}
						className={clsxm(
							'btn gap-2',
							'bg-white',
							'mt-0 mb-0',
							selected.includes(x.toString())
								? 'btn-active main-gradient-background'
								: 'btn-outline'
						)}
					>
						{renderMonthLabel(x)}
					</button>
				))}
			</div>
		</div>
	);
};

export const ReviewGroup = (props: { data?: string }) => {
	const [reviewGroup, setReviewGroup] = useState<string[]>([]);
	const { setValue, getValues } = useFormContext<IPromotionField>();
	const onClickButton = (value: string) => {
		let reviewGroupValue = reviewGroup;
		let index = reviewGroup.findIndex((x: string) => x === value);

		if (index !== -1) {
			reviewGroupValue.splice(index, 1);
		} else {
			reviewGroupValue.push(value);
		}
		setReviewGroup([...reviewGroupValue]);
		setValue('requirementReviewGroup', reviewGroup.toString());
	};
	useEffect(() => {
		if (props.data) {
			setReviewGroup(props.data?.split(','));
			setValue('requirementReviewGroup', props.data);
		}
		let data = getValues('requirementReviewGroup');
		!isEmpty(data) && setReviewGroup(data!.split(','));
	}, []);
	return (
		<div>
			<label className="label font-medium ">
				<span className="label-text text-md">
					{I18n.t(_t(translations.textMessageDetail.reviewGroup))}
				</span>
			</label>
			<div className="flex flex-wrap gap-x-4">
				<button
					key={1}
					onClick={() => onClickButton('0')}
					value={'0'}
					className={clsxm(
						'btn gap-2',
						'bg-white',
						'mt-0 mb-0',
						reviewGroup.includes('0')
							? 'btn-active main-gradient-background'
							: 'btn-outline'
					)}
				>
					1 - 3 stars
				</button>
				<button
					key={2}
					onClick={() => onClickButton('1')}
					value={'1'}
					className={clsxm(
						'btn gap-2',
						'bg-white',
						'mt-0 mb-0',
						reviewGroup.includes('1')
							? 'btn-active main-gradient-background'
							: 'btn-outline'
					)}
				>
					4 - 5 stars
				</button>
			</div>
		</div>
	);
};
const CustomerRank = (props: { data?: string }) => {
	const [ranks, setRanks] = useState<ILoyaltyRank[]>([]);
	const [selected, setSelected] = useState<string[]>([]);
	const { setValue, getValues } = useFormContext<IPromotionField>();

	const onClickButton = (value: string) => {
		let selectedValue = selected;
		let index = selected.findIndex((x: string) => x === value);

		if (index !== -1) {
			selectedValue.splice(index, 1);
		} else {
			selectedValue.push(value);
		}
		setSelected([...selectedValue]);
		setValue('requirementCustomerRank', selected.toString());
	};

	const fetchLoyaltyRank = async () => {
		try {
			const result =
				(await PromotionApiService.getLoyaltyRank()) as IApiResponse<
					ILoyaltyRank[]
				>;
			if (result.succeeded) {
				if (result.data) setRanks([...result.data]);
			}
		} catch (error) {}
	};
	useEffect(() => {
		fetchLoyaltyRank();
		if (props.data) {
			setSelected(props.data?.split(','));
			setValue('requirementCustomerRank', props.data);
		}
		let data = getValues('requirementCustomerRank');
		!isEmpty(data) && setSelected(data!.split(','));
	}, []);
	return !isEmpty(ranks) ? (
		<div>
			<label className="label font-medium ">
				<span className="label-text text-md">
					{I18n.t(_t(translations.textMessageDetail.customerLoyaltyRank))}
				</span>
			</label>

			<div className="flex flex-wrap gap-x-4">
				{ranks.map((x) => (
					<button
						key={x.id}
						onClick={() => onClickButton(x.id)}
						value={x.id}
						className={clsxm(
							'btn gap-2',
							'bg-white',
							'mt-0 mb-0',
							selected.includes(x.id)
								? 'btn-active main-gradient-background'
								: 'btn-outline'
						)}
					>
						{x.name ? x.name : ''}
					</button>
				))}
			</div>
		</div>
	) : (
		<></>
	);
};

interface RequirementsProps {
	isAdd: boolean;
	requirements?: IRequirement[];
	isVisitRequirement: boolean;
	setIsVisitRequirement: (value: boolean) => void;
}

export const Requirements = (props: RequirementsProps) => {
	const [requirements, setRequirements] = useState<IRequirement[]>(
		props.requirements || []
	);
	const isCheckRequirement = (value: number[]) => {
		let flag = false;
		for (let i = 0; i < value.length; i++) {
			let index = requirements.findIndex(
				(requirement) => requirement.type === value[i]
			);
			if (index !== -1) return true;
		}
		return flag;
	};
	const getValueFromRequirement = (type: number) => {
		for (let i = 0; i < requirements.length; i++) {
			if (requirements[i].type === type) return requirements[i].value;
		}
		return undefined;
	};
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<IPromotionField>();

	const AssignedToCustomerGroup = watch('AssignedToCustomerGroup');
	const HadSpentAnAmount = watch('HadSpentAnAmount');
	const isAllOfTheseServices = watch('isAllOfTheseServices');
	const isOneOfTheseServices = watch('isOneOfTheseServices');
	const isReturnMoreThan = watch('isReturnMoreThan');
	const requirementAllOfServices = watch('requirementAllOfServices');
	const requirementOneOfServices = watch('requirementOneOfServices');
	const requiredSpentAmount = watch('requiredSpentAmount');
	const nTimesReturn = watch('nTimesReturn');

	useEffect(() => {
		if (!props.isAdd && !props.isVisitRequirement) {
			setValue(
				'AssignedToCustomerGroup',
				isCheckRequirement([
					PromotionRequirementType.CustomerBirthdayIn,
					PromotionRequirementType.CustomerNotVisitedIn,
					PromotionRequirementType.CustomerGiveReview,
					PromotionRequirementType.CustomerLoyaltyRank,
				])
			);
			setValue(
				'HadSpentAnAmount',
				isCheckRequirement([PromotionRequirementType.CustomerSpentAmount])
			);
			setValue(
				'isAllOfTheseServices',
				isCheckRequirement([PromotionRequirementType.BookingHasAllOfServices])
			);
			setValue(
				'isOneOfTheseServices',
				isCheckRequirement([PromotionRequirementType.BookingHasOneOfServices])
			);
			setValue(
				'isReturnMoreThan',
				isCheckRequirement([PromotionRequirementType.CustomerReturnedTimes])
			);
			setValue(
				'requiredSpentAmount',
				isCheckRequirement([PromotionRequirementType.CustomerSpentAmount])
					? parseInt(
							getValueFromRequirement(
								PromotionRequirementType.CustomerSpentAmount
							)!
					  )
					: 0
			);
			setValue(
				'nTimesReturn',
				isCheckRequirement([PromotionRequirementType.CustomerReturnedTimes])
					? parseInt(
							getValueFromRequirement(
								PromotionRequirementType.CustomerReturnedTimes
							)!
					  )
					: 0
			);
		}
		props.setIsVisitRequirement(true);
	}, []);

	return (
		<div className="grid gap-y-8">
			<Card>
				<Switch
					label={I18n.t(
						_t(translations.promotionDetail.assignedToCustomerGroup)
					)}
					value={AssignedToCustomerGroup}
					onChange={(value) => {
						setValue('AssignedToCustomerGroup', value);
					}}
					fullWidth
					primary
					className="grid grid-cols-3"
				/>
				{AssignedToCustomerGroup && (
					<>
						<HaveNotVisited
							data={
								props.isAdd
									? undefined
									: getValueFromRequirement(
											PromotionRequirementType.CustomerNotVisitedIn
									  )
							}
						/>
						<BirthdayMonth
							data={
								props.isAdd
									? undefined
									: getValueFromRequirement(
											PromotionRequirementType.CustomerBirthdayIn
									  )
							}
						/>
						<ReviewGroup
							data={
								props.isAdd
									? undefined
									: getValueFromRequirement(
											PromotionRequirementType.CustomerGiveReview
									  )
							}
						/>
						<CustomerRank
							data={
								props.isAdd
									? undefined
									: getValueFromRequirement(
											PromotionRequirementType.CustomerLoyaltyRank
									  )
							}
						/>
					</>
				)}
			</Card>
			<Card>
				<div className="flex items-center gap-x-8">
					<Switch
						label={I18n.t(_t(translations.promotionDetail.hadSpentAnAmount))}
						value={HadSpentAnAmount}
						onChange={(value) => {
							setValue('HadSpentAnAmount', value);
						}}
						fullWidth
						primary
						className="basis-1/3"
					/>
					{HadSpentAnAmount && (
						<div className="basis-1/4">
							<Input
								label={I18n.t(_t(translations.promotionDetail.requiredSpent))}
								placeholder={I18n.t(
									_t(translations.promotionDetail.placeholderDiscountAmount)
								)}
								error={errors.requiredSpentAmount?.message}
								renderAfter={<>$</>}
								type="number"
								alignRight
								{...register('requiredSpentAmount', {
									required: I18n.t(
										_t(translations.promotionDetail.messageRequiredSpentAmount)
									),
									min: {
										value: 0,
										message: I18n.t(_t(translations.validate.lessThanZero)),
									},
								})}
							/>
						</div>
					)}
				</div>
			</Card>
			<Card>
				<Switch
					label={I18n.t(
						_t(translations.promotionDetail.titleAllOfTheseServices)
					)}
					value={isAllOfTheseServices}
					onChange={(value) => {
						setValue('isAllOfTheseServices', value);
					}}
					fullWidth
					primary
					className="grid grid-cols-3"
				/>
				{isAllOfTheseServices && (
					<ListServices
						isAdd={props.isAdd}
						setServices={(value) =>
							setValue('requirementAllOfServices', value.join(','))
						}
						data={
							!isEmpty(requirementAllOfServices)
								? requirementAllOfServices
								: getValueFromRequirement(
										PromotionRequirementType.BookingHasAllOfServices
								  )
						}
					/>
				)}
			</Card>
			<Card>
				<Switch
					label={I18n.t(
						_t(translations.promotionDetail.titleOneOfTheseServices)
					)}
					value={isOneOfTheseServices}
					onChange={(value) => {
						setValue('isOneOfTheseServices', value);
					}}
					fullWidth
					primary
					className="grid grid-cols-3"
				/>
				{isOneOfTheseServices && (
					<ListServices
						isAdd={props.isAdd}
						setServices={(value) =>
							setValue('requirementOneOfServices', value.join(','))
						}
						data={
							!isEmpty(requirementOneOfServices)
								? requirementOneOfServices
								: getValueFromRequirement(
										PromotionRequirementType.BookingHasOneOfServices
								  )
						}
					/>
				)}
			</Card>
			<Card>
				<div className="flex items-center gap-x-8">
					<Switch
						label={I18n.t(_t(translations.promotionDetail.titleHasReturnTimes))}
						value={isReturnMoreThan}
						onChange={(value) => {
							setValue('isReturnMoreThan', value);
						}}
						fullWidth
						primary
						className="basis-1/3"
					/>
					{isReturnMoreThan && (
						<div className="basis-1/4">
							<Input
								label={I18n.t(
									_t(translations.promotionDetail.descriptionReturnTimes)
								)}
								placeholder={I18n.t(_t(translations.promotionDetail.nTimes))}
								error={errors.nTimesReturn?.message}
								type="number"
								alignRight
								{...register('nTimesReturn', {
									required: I18n.t(
										_t(translations.promotionDetail.messageRequiredReturnTimes)
									),
									min: {
										value: 0,
										message: I18n.t(_t(translations.validate.lessThanZero)),
									},
								})}
							/>
						</div>
					)}
				</div>
			</Card>
		</div>
	);
};
