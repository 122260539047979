import { IItemCategory } from 'models';
import {
	IAddCategoryRequest,
	IEditCategoryRequest,
} from 'models/RequestModels';
import { BaseApiService } from './BaseApiService';

const url = '/ItemCategory';

class CategoryApiService extends BaseApiService {
	public addCategory = (addCategoryRequest: IAddCategoryRequest) =>
		this.post<IAddCategoryRequest, string>(
			`${url}/create-item-category`,
			addCategoryRequest
		);
	public getAllCategory = () =>
		this.get<IItemCategory[]>(`/ItemCategory/get-all-item-categories`);
	public editCategory = (editCategoryRequest: IEditCategoryRequest) =>
		this.put<IEditCategoryRequest, string>(
			`${url}/update-item-category`,
			editCategoryRequest
		);
	public getCategoryById = (id: string) =>
		this.get<IItemCategory>(`${url}/get-item-category/${id}`);
	public deleteCategory = (id: string) =>
		this.delete(`${url}/delete-item-category/${id}`);

	public updateServicesIndexes = (
		services: { id: string; indexNumber: number }[]
	) =>
		this.put<
			{ indexNumbersRequest: { id: string; indexNumber: number }[] },
			IItemCategory[]
		>('/ItemCategory/update-list-indexnumber-item-category', {
			indexNumbersRequest: services,
		});
}

export default new CategoryApiService(true);
