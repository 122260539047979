import { Card } from 'components/atoms';
import { I18n, _t, translations } from 'utils';
import clsxm from 'clsxs/clsxm';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import './styles.css';
import { useStaffDetailContext } from '../context';

const Role = (props: {}) => {
	const employeeTypes = useAppSelector(
		(state) => state.EmployeeTypeReducer.employeeTypes
	);

	const { currentEmployeeTypeIds, setCurrentEmployeeTypeIds } =
		useStaffDetailContext();

	return (
		<Card
			title={I18n.t(_t(translations.staffDetail.employeeType))}
			id="staff-role"
		>
			<div className="flex gap-4 flex-wrap mt-4">
				{employeeTypes.map((x) => {
					return (
						<div
							className={clsxm(
								'px-3 py-2 flex justify-center items-center align-middle border rounded-lg cursor-pointer',
								currentEmployeeTypeIds?.includes(x.id || '')
									? 'bg-primary text-white'
									: ''
							)}
							key={x.id}
							onClick={() => {
								if (currentEmployeeTypeIds?.includes(x.id || '')) {
									let news = Array.from(currentEmployeeTypeIds || []);
									_.remove(news, (y) => y === x.id || '');

									setCurrentEmployeeTypeIds(news);
								} else {
									let news = Array.from(currentEmployeeTypeIds || []);
									news.push(x.id || '');
									setCurrentEmployeeTypeIds(news);
								}
							}}
						>
							{x.name}
						</div>
					);
				})}
			</div>
		</Card>
	);
};

export default Role;
