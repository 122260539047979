import clsxm from 'clsxs/clsxm';
import { ReactNode, useMemo } from 'react';
import Modal from 'react-modal';
import { useBillDetailContext } from '../context';
import _ from 'lodash';
import moment from 'moment';
import { t, translations } from 'utils';

const RefundType = [
	{
		id: 'partial',
		label: 'Partial Bill',
	},
	{
		id: 'total',
		label: 'Bill',
	},
];

interface IBookingDetailModalProps {
	visible?: boolean;
	onCancel: () => void;
	onChooseType: (type: string) => void;
	loading?: boolean;
	children?: ReactNode;
}
export const RefundTypeModal = ({
	visible,
	onCancel,
	onChooseType,
}: IBookingDetailModalProps) => {
	const { transactions } = useBillDetailContext().bill!;
	const hasCloverPayment = transactions?.some(
		(e) => e.paymentMethod?.code.toUpperCase() === 'CLOVER'
	);
	const shouldDisableVoid = useMemo(() => {
		const cloverTransaction =
			hasCloverPayment && !_.isEmpty(transactions)
				? transactions?.sort((a, b) =>
						a.createdDate! > b.createdDate!
							? 1
							: a.createdDate! < b.createdDate!
							? -1
							: 0
				  )[0]
				: null;
		if (
			cloverTransaction &&
			moment
				.utc(cloverTransaction.createdDate)
				.add(25, 'minute')
				.isBefore(moment().utc(), 'minute')
		) {
			return true;
		}
		return false;
	}, [transactions]);
	return (
		<Modal
			isOpen={visible || false}
			style={{
				content: {
					width: '30%',
					margin: '20vh auto',
					padding: 0,
					borderWidth: 0,
				},
				overlay: {
					zIndex: 51,
				},
			}}
			className={clsxm(
				'shadow-md rounded-lg flex flex-col overflow-hidden bg-white'
			)}
			onRequestClose={onCancel}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
		>
			<div
				className={clsxm(
					'p-4 w-full flex justify-between bg-primary rounded-tl-lg'
				)}
			>
				<article className="prose">
					<h3 className="mb-0 text-white">Choose Type Refund</h3>
				</article>
			</div>
			<div className={clsxm('flex-1 flex flex-col h-full p-4 bg-background')}>
				<div className={clsxm('grid grid-cols-2 gap-4 p-4')}>
					{RefundType.map((type) => (
						<div
							key={type.id}
							onClick={() => onChooseType(type.id)}
							className={clsxm(
								'align-middle border-none shadow-md w-full active:animate-pop bg-white items-center justify-center inline-flex font-semibold text-center p-1 rounded-lg h-24 cursor-pointer'
							)}
						>
							<span className="hover:text-base-100 text-lg">{type.label}</span>
						</div>
					))}
				</div>
			</div>
		</Modal>
	);
};
