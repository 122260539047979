import * as AppConfigActions from './appConfig';
import * as AuthenticationActions from './authentication';
import * as UserActions from './user';
import * as ServiceActions from './service';
import * as CategoryActions from './category';
import * as ErrorActions from './error';
import * as EmployeeActions from './employee';
import * as BranchActions from './branch';
import * as BookingActions from './booking';
import * as CustomerActions from './customer';
import * as ShopActions from './shop';
import * as SearchActions from './search';
import * as CheckoutActions from './checkout';
import * as HolidayActions from './holiday';
import * as AdminDashboardActions from './adminDashboard';
import * as RatingActions from './rating';
import * as SettingActions from './setting';
import * as GalleryActions from './gallery';
import * as CurrencyActions from './currency';
import * as EmployeeTypeActions from './employeeType';
import * as RoleActions from './role';
import * as PaymentMethodActions from './paymentMethod';
import * as CustomerBookingActions from './customerBooking';
import * as ThemeActions from './theme';
import * as ExpenseTypeActions from './expenseType';
import * as CloverActions from './clover';
import * as CloseOnlineBookingActions from './closeOnlineBooking';
import * as TodayActions from './today';

export const prefixes = [
	AppConfigActions.prefix,
	AuthenticationActions.prefix,
	UserActions.prefix,
	ServiceActions.prefix,
	CategoryActions.prefix,
	ErrorActions.prefix,
	EmployeeActions.prefix,
	BranchActions.prefix,
	BookingActions.prefix,
	CustomerActions.prefix,
	ShopActions.prefix,
	SearchActions.prefix,
	CheckoutActions.prefix,
	HolidayActions.prefix,
	AdminDashboardActions.prefix,
	RatingActions.prefix,
	SearchActions.prefix,
	GalleryActions.prefix,
	RoleActions.prefix,
	EmployeeTypeActions.prefix,
	PaymentMethodActions.prefix,
	CurrencyActions.prefix,
	CustomerBookingActions.prefix,
	ExpenseTypeActions.prefix,
	CloverActions.prefix,
	CloseOnlineBookingActions.prefix,
	TodayActions.prefix,
];
export {
	AppConfigActions,
	UserActions,
	ServiceActions,
	CategoryActions,
	AuthenticationActions,
	EmployeeActions,
	ErrorActions,
	BranchActions,
	BookingActions,
	CustomerActions,
	ShopActions,
	SearchActions,
	CheckoutActions,
	HolidayActions,
	AdminDashboardActions,
	RatingActions,
	SettingActions,
	GalleryActions,
	RoleActions,
	EmployeeTypeActions,
	PaymentMethodActions,
	CurrencyActions,
	CustomerBookingActions,
	ThemeActions,
	ExpenseTypeActions,
	CloverActions,
	CloseOnlineBookingActions,
	TodayActions,
};
