import {
	HubConnectionBuilder,
	HubConnection,
	HttpTransportType,
	LogLevel,
} from '@microsoft/signalr';
import { TimeHelper } from 'helpers';
import { createESCCheckIn, print } from 'helpers/printHelper';
import _ from 'lodash';
import { IBooking, IPoolStaff } from 'models';
import moment from 'moment';
import { BookingActions, TodayActions } from 'redux/actions';
import configureStore from 'redux/configuration/configureStore';
// SkipNegotiation = true;
// Transports = HttpTransportType.WebSockets;
class SignalRService {
	private instance: HubConnection;
	constructor() {
		this.instance = this.setUpService();
	}
	public setUpService() {
		const store = configureStore().store;
		const shopId = store.getState().ShopReducer.shop?.id;
		const branchId = store.getState().BranchReducer.currentBranch?.id;
		const date = TimeHelper.toTimeZone(moment().toDate()).format('YYYY-MM-DD');
		const hubConnectionBuilder = new HubConnectionBuilder()
			.configureLogging(LogLevel.Information)
			.withUrl(
				`${process.env.REACT_APP_SOCKET_STAGING_URL}/today-bookings-hub/?shopId=${shopId}&branchId=${branchId}&currentDate=${date}`,
				{
					withCredentials: false,
					skipNegotiation: true,
					transport: HttpTransportType.WebSockets,
				}
			)
			.withAutomaticReconnect();

		var connection = hubConnectionBuilder.build();
		connection.serverTimeoutInMilliseconds = 120000;
		connection.keepAliveIntervalInMilliseconds = 60000;

		return connection;
	}
	public runService() {
		try {
			this.instance.start().then((a) => {
				if (this.instance.connectionId) {
				}
			});
			this.instance.onreconnected((connectionString) =>
				console.log('Connection String', connectionString)
			);
		} catch (error) {
			setTimeout(() => {
				this.runService();
			}, 5000);
		}
	}
	public onClose() {
		this.instance.onclose((error) => {
			console.log('HUB CLOSED WITH ERROR: =>>', error);
			// this.runService();
		});
	}
	public onListenBooking() {
		const store = configureStore().store;
		this.instance.on('BookingCreated', (data) => {
			const booking = data as IBooking;
			store.dispatch(BookingActions.socketUpdateBooking.request(booking));
		});
		this.instance.on('BookingUpdated', (data) => {
			const booking = data as IBooking;
			store.dispatch(BookingActions.socketUpdateBooking.request(booking));
		});
	}
	public onListenPoolStaffs() {
		const store = configureStore().store;

		this.instance.on('AvailableStylistAdded', (data) => {
			const pollStaff = data as IPoolStaff;
			store.dispatch(TodayActions.socketAddPoolStaffs.request(pollStaff));
		});
		this.instance.on('AvailableStylistUpdated', (data) => {
			const pollStaff = data as IPoolStaff;
			store.dispatch(TodayActions.socketRemovePoolStaffs.request(pollStaff));
		});
	}
	public onListenCheckedIn() {
		const store = configureStore().store;
		this.instance.on('BookingCheckedIn', async (data) => {
			if (store.getState().SettingReducer.isEnableTicketPrinting) {
				const booking = data as IBooking;
				if (!booking.bookingNumber) {
					return;
				}
				const ESCCommands = await createESCCheckIn(
					booking.customer,
					booking.id,
					booking.bookingNumber
				);
				print(ESCCommands, undefined, true);
			}
			console.log('DATA CHECKIN', data);
		});
	}
	public remove() {
		this.instance.stop();
	}
}

export default SignalRService;
