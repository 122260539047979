import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js';
import {
	Button,
	ConfirmModal,
	IColumnProps,
	Icon,
	Select,
} from 'components/atoms';
import Table, { EColumnType, ITableRef } from 'components/atoms/Table';
import { AlertHelper } from 'helpers';
import _ from 'lodash';
import { IApiResponse, IErrorResponse } from 'models';
import { IPromotion, IPromotionType } from 'models/IPromotion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PromotionApiService from 'services/PromotionApiService';
import { I18n, _t, translations } from 'utils';

interface HeaderProps {
	promotionTypes: IPromotionType[];
	setType: (value: string) => void;
}
const Header = (props: HeaderProps) => {
	const history = useHistory();
	const { register, setValue, watch } = useForm();
	const typeId = watch('promotionTypeId');
	useEffect(() => {
		register('promotionTypeId');
	}, [register]);
	useEffect(() => {
		setValue('promotionTypeId', '');
	}, []);
	return (
		<div className="w-full flex justify-between">
			<div className="basis-1/3 flex">
				<label className="label font-semibold basis-1/2">
					<span className="label-text text-lg">
						{I18n.t(_t(translations.promotion.discountType))}
					</span>
				</label>
				<div className="basis-1/2">
					<Select<IPromotionType>
						options={[
							{ id: '', name: 'All', code: '' },
							...props.promotionTypes,
						]}
						titleField={'name'}
						keyField={'id'}
						fullWidth
						valueId={typeId}
						onChange={(value) => {
							setValue('promotionTypeId', value.id);
							props.setType(value.id);
						}}
					/>
				</div>
			</div>
			<Button
				small
				primary
				title={I18n.t(_t(translations.addNew))}
				iconBefore={<Icon path={mdiPlus} />}
				onClick={() => history.push('promotions/add-new')}
			/>
		</div>
	);
};

export const Promotion = () => {
	const history = useHistory();
	const [type, setType] = useState<string>('');
	const [showModal, setShowModal] = useState(false);
	const [promotionId, setPromotionId] = useState('');
	const tableRef = useRef<ITableRef>();

	const [promotionTypes, setPromotionTypes] = useState<IPromotionType[]>([]);
	const fetchPromotionTypes = async () => {
		try {
			const result =
				(await PromotionApiService.getPromotionType()) as IApiResponse<
					IPromotionType[]
				>;
			if (result.succeeded) {
				setPromotionTypes(result.data || []);
			}
		} catch (error) {}
	};
	const onDeletePromotion = async () => {
		try {
			await PromotionApiService.updatePromotionRequirement({
				promotionId: promotionId,
				requirements: [],
			});
			await PromotionApiService.updatePromotionItemCategory({
				promotionId: promotionId,
				itemCategoryIds: [],
			});
			await PromotionApiService.updatePromotionItem({
				promotionId: promotionId,
				itemIds: [],
			});
			await PromotionApiService.updatePromotionCoupon({
				promotionId: promotionId,
				couponCodes: [],
			});

			const result = await PromotionApiService.deletePromotion(promotionId);
			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.promotionDetail.messageDeletePromotionSuccess))
				);
				tableRef.current?.refresh();
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		fetchPromotionTypes();
	}, []);

	const columns = useMemo<IColumnProps<IPromotion>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.promotion.promotionName)),
				key: 'name',
			},
			{
				header: I18n.t(_t(translations.promotion.discountType)),
				key: 'promotionTypeId',
				renderCell: (data) => {
					const typeName =
						_.find(promotionTypes, (x) => x.id === data.promotionTypeId)
							?.name || data.promotionTypeId;
					return typeName;
				},
			},
			{
				header: I18n.t(_t(translations.promotion['discountAmount/Percentage'])),
				key: 'discountPercentage',
				renderCell: (data) => {
					return data.usePercentage
						? `${data.discountPercentage}%`
						: `${data.discountAmount}$`;
				},
			},
			{
				header: I18n.t(_t(translations.promotion.startDate)),
				key: 'startDateUtc',
				type: EColumnType.DATE,
			},
			{
				header: I18n.t(_t(translations.promotion.endDate)),
				key: 'endDateUtc',
				type: EColumnType.DATE,
			},
			{
				header: I18n.t(_t(translations.promotion.status)),
				key: 'isEnabled',
				renderCell: (data) => {
					return data.isEnabled ? 'Active' : 'Inactive';
				},
			},
			{
				header: I18n.t(_t(translations.action)),
				key: 'id',
				headerClassName: 'w-44',
				cellClassName: 'w-44',
				renderCell: (data) => {
					return (
						<div className="flex gap-4 items-center">
							<div
								className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
								onClick={(e) => {
									e.stopPropagation();
									history.push(`promotions/edit/${data.id}`);
								}}
							>
								<Icon path={mdiPencilOutline} className="w-6 h-6" />
							</div>
							<div className="text-[#f0f0f0]">|</div>
							<div
								className="cursor-pointer rounded border border-DELETED p-2 bg-white"
								onClick={() => {
									setPromotionId(data!.id);
									setShowModal(true);
								}}
							>
								<Icon
									path={mdiDeleteOutline}
									className="text-DELETED w-6 h-6"
								/>
							</div>
						</div>
					);
				},
			},
		];
	}, [promotionTypes]);
	return (
		<>
			<Table<IPromotion>
				queryUrl={'/Promotion/get-all-promotions'}
				columns={columns}
				additionalParams={{
					promotionTypeId: type,
				}}
				showSearch
				renderHeader={
					<Header
						promotionTypes={promotionTypes}
						setType={(value) => setType(value)}
					/>
				}
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				// className="max-h-[calc(100vh-21.5rem)]"
			/>
			<ConfirmModal
				visible={showModal}
				title={I18n.t(_t(translations.delete))}
				subTitle={I18n.t(
					_t(translations.promotionDetail.deleteConfirmPromotion)
				)}
				onPositiveClick={() => {
					onDeletePromotion();
					setShowModal(false);
				}}
				onNegativeClick={() => setShowModal(false)}
				type="error"
			/>
		</>
	);
};
