import clsxm from 'clsxs/clsxm';
import React, { forwardRef, InputHTMLAttributes, Ref } from 'react';

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
	error?: string;
	label?: string;
	hint?: string;
	renderBefore?: React.ReactNode;
	renderAfter?: JSX.Element;
	alignRight?: boolean;
}

export const Input = forwardRef(
	(props: IInputProps, ref: Ref<HTMLInputElement>) => {
		delete props.className;
		return (
			<div className={clsxm('form-control', 'w-full')}>
				{props.label && (
					<label className="label font-medium ">
						<span className="label-text text-md">{props.label}</span>
					</label>
				)}
				<label
					className={clsxm(
						(props.renderBefore || props.renderAfter) && 'input-group'
					)}
				>
					{props.renderBefore && <span>{props.renderBefore}</span>}
					<input
						{...props}
						ref={ref}
						className={clsxm(
							'input input-bordered w-full h-[45px] placeholder-base-300 outline-none',
							props.error && 'border-error',
							props.alignRight ? 'text-right' : ''
						)}
					/>
					{props.renderAfter && <span>{props.renderAfter}</span>}
				</label>
				{props.error && (
					<p className="ml-2 mt-2 text-left w-full text-error">{props.error}</p>
				)}
				{props.hint && !props.error && (
					<p className="ml-2 mt-2 text-left w-full italic text-base-300">
						{props.hint}
					</p>
				)}
			</div>
		);
	}
);
