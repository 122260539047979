import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { BookingActions } from '../actions';
import _ from 'lodash';
import {
	IBooking,
	IBookingDetail,
	ICustomer,
	IEmployee,
	IService,
} from 'models';
import { ITimeBlock } from 'models/ITimeBlock';
import { IStylistAndTimeBlockResponse } from 'models/ResponseModels';
import moment, { Moment } from 'moment';
export interface IBookingReducer {
	todayBookings: {
		[key: string]: IBooking;
	};
	bookings: IBooking[];
	currentBooking?: IBooking;
	bookingDetails: Partial<IBookingDetail>[];
	editBookingDetail?: boolean;
	timeBlocks: ITimeBlock[];
	timeActiveId?: string;
	availableStylist: IEmployee[][];
	selectedCustomer?: Partial<ICustomer>;
	editing: boolean;
	isNewCustomer: boolean;
	currentIndex?: number;
	date?: Moment;
	tempStylist?: IEmployee;
	note?: string;
}

const initialState: IBookingReducer = {
	bookings: [],
	todayBookings: {},
	timeBlocks: [],
	bookingDetails: [{}],
	availableStylist: [],
	editing: false,
	isNewCustomer: false,
};

// Get Booking

function getBookingsByBranchSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking[] | null>
) {
	state.bookings = action.payload!;
}
function getTodayBookingSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking[] | null>
) {
	state.todayBookings = action.payload
		?.filter((x) => !_.isEmpty(x.bookingDetails))
		.reduce((result, item) => {
			result[item.id] = item;
			return result;
		}, {} as { [key: string]: IBooking })!;
}

function socketUpdateBooking(
	state: IBookingReducer,
	action: PayloadAction<IBooking>
) {
	const booking = action.payload;
	state.todayBookings[booking.id] = booking;
}
function createBookingSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking>
) {
	state.currentBooking = action.payload;
	state.editing = false;
}
function deleteChoosingService(
	state: IBookingReducer,
	action: PayloadAction<number>
) {
	let bookingDetails = Array.from(state.bookingDetails);
	bookingDetails.splice(action.payload, 1);
	state.editing = true;
	state.bookingDetails = bookingDetails;
}
function selectTimeStart(
	state: IBookingReducer,
	action: PayloadAction<string>
) {
	state.timeActiveId = action.payload;
	state.editing = true;
}
function getBookingByIdSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking>
) {
	const newBookingDetails: Partial<IBookingDetail>[] = Array.from(
		action.payload.bookingDetails
	);
	state.currentBooking = action.payload;
	state.editing = false;
	// if (action.payload.status === Const.BookingStatus.PENDING) {
	//   newBookingDetails.push({
	//     stylistId: action.payload.bookingDetails[0].stylistId,
	//   });
	// }
	state.bookingDetails = newBookingDetails;
	state.selectedCustomer = action.payload.customer;
	state.timeActiveId = moment(action.payload.startTimeExpected).format(
		'YYYY-MM-DDTHH:mm'
	);
	state.date = moment(action.payload.date);
	state.note = action.payload.note;
}
function selectCustomer(
	state: IBookingReducer,
	action: PayloadAction<Partial<ICustomer>>
) {
	state.selectedCustomer = action.payload;
}
function removeCustomer(state: IBookingReducer, action: PayloadAction<any>) {
	state.editing = true;
	state.selectedCustomer = undefined;
}
function getCustomerByPhone(
	state: IBookingReducer,
	action: PayloadAction<ICustomer>
) {
	state.selectedCustomer = action.payload;
}

function changeBookingDetails(
	state: IBookingReducer,
	action: PayloadAction<{
		index: number;
		bookingDetail: Partial<IBookingDetail>;
	}>
) {
	let newBookingDetail = Object.assign(
		{},
		{
			...action.payload.bookingDetail,
			stylist: action.payload.bookingDetail.stylist || state.tempStylist,
			stylistId:
				action.payload.bookingDetail.stylistId || state.tempStylist
					? state.tempStylist?.id
					: undefined,
		}
	);
	// const sameServiceStylists = state.bookingDetails
	//   .filter((x) => !_.isEmpty(x.itemId) && x.itemId === action.payload.bookingDetail.itemId)
	//   .map((x) => x.stylistId);
	// if (sameServiceStylists.includes(state.bookingDetails[action.payload.index].stylistId)) {
	//   newBookingDetail.stylistId = undefined;
	// }
	state.bookingDetails[action.payload.index] = Object.assign(
		state.bookingDetails[action.payload.index],
		newBookingDetail
	);
	if (!_.isEmpty(state.bookingDetails[action.payload.index + 1])) {
		for (
			let i = action.payload.index + 1;
			i <= state.bookingDetails.length - 1;
			i++
		) {
			if (
				!_.isEmpty(action.payload.bookingDetail.stylistId) &&
				!_.isEmpty(state.bookingDetails[i]) &&
				_.isEmpty(state.bookingDetails[i].stylistId) &&
				state.bookingDetails[i].itemId !==
					state.bookingDetails[action.payload.index].itemId
			) {
				state.bookingDetails[i] = Object.assign(state.bookingDetails[i], {
					stylistId: action.payload.bookingDetail.stylistId,
				});
			}
		}
	}

	if (
		action.payload.index === state.bookingDetails.length - 1 &&
		!_.isEmpty(state.bookingDetails[action.payload.index].item)
	) {
		state.bookingDetails.push({
			stylistId: state.bookingDetails[action.payload.index].stylistId,
		});
	}
	state.editing = true;
}

const addExtraAmount = (
	state: IBookingReducer,
	action: PayloadAction<{
		index: number;
		amount: number;
		note: string;
	}>
) => {
	const currentBooking = state.currentBooking!;
	let bookingDetail = currentBooking.bookingDetails[action.payload.index];
	bookingDetail.extraAmount = action.payload.amount;
	bookingDetail.note = action.payload.note;
	currentBooking.bookingDetails[action.payload.index] = bookingDetail;
	state.currentBooking = currentBooking;
};
function getAvailableStylistAndTimeBlocks(
	state: IBookingReducer,
	action: PayloadAction<{
		index: number;
		bookingDetail: IStylistAndTimeBlockResponse;
	}>
) {
	const currentStylist = state.bookingDetails[action.payload.index].stylistId;
	// if (currentStylist) {
	// 	state.tempStylist = undefined;
	// }
	if (!state.availableStylist[action.payload.index]) {
		let newList = Array.from(state.availableStylist);
		state.bookingDetails.forEach((e, i) => {
			if (_.isEmpty(newList[i])) newList[i] = [];
		});
		newList[action.payload.index] = action.payload.bookingDetail.stylists;
		state.availableStylist = newList;
		// state.availableStylist.push(action.payload.bookingDetail.stylists);
	} else {
		const newStylistList = Array.from(action.payload.bookingDetail.stylists);
		if (
			state.currentBooking?.bookingDetails[action.payload.index] &&
			state.currentBooking?.bookingDetails[action.payload.index].stylist &&
			!_.find(
				newStylistList,
				(x) =>
					x.id ===
					state.currentBooking?.bookingDetails[action.payload.index].stylist?.id
			)
		) {
			newStylistList.push(
				state.currentBooking?.bookingDetails[action.payload.index].stylist!
			);
		}
		state.availableStylist[action.payload.index] = newStylistList;
	}
	// const canStylistDoService = !_.isEmpty(
	// 	_.find(
	// 		action.payload.bookingDetail.stylists,
	// 		(stylist) =>
	// 			stylist.id === state.bookingDetails[action.payload.index].stylistId
	// 	)
	// );
	// if (!canStylistDoService) {
	// 	state.bookingDetails[action.payload.index].stylistId = undefined;
	// 	state.bookingDetails[action.payload.index].stylist = undefined;
	// }
	if (action.payload.index === 0) {
		state.timeBlocks = action.payload.bookingDetail.timeBlocks.map(
			(timeBlock) => {
				if (state.currentBooking?.bookingDetails[0]) {
					const startTimeUnix = moment
						.utc(state.currentBooking?.bookingDetails[0].startAtExpected)
						.unix();
					const endTimeUnix = moment
						.utc(state.currentBooking?.bookingDetails[0].endAtExpected)
						.unix();
					const blockTimeUnix = moment.utc(timeBlock.time).unix();
					if (startTimeUnix <= blockTimeUnix) {
						return {
							...timeBlock,
							// unavailable: false,
							// noStylistsAvailable: false,
							time: timeBlock.time,
						};
					}
				}
				return {
					...timeBlock,
					time: timeBlock.time,
				};
			}
		);
	}
	if (
		!action.payload.bookingDetail.stylists
			.map((x) => x.id)
			.includes(`${currentStylist}`)
	) {
		// state.bookingDetails[action.payload.index].stylistId = undefined;
		// state.bookingDetails[action.payload.index].stylist = undefined;
	} else {
		if (state.tempStylist) {
			state.currentIndex = undefined;
			state.tempStylist = undefined;
		}
	}
}
function editBookingSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking>
) {
	state.currentBooking = _.mergeWith(
		{},
		state.currentBooking,
		action.payload,
		(o, s) => (_.isNull(s) ? o : s)
	);
	state.editing = false;
}

function setIsNewCustomer(
	state: IBookingReducer,
	action: PayloadAction<boolean>
) {
	state.isNewCustomer = action.payload;
}
function resetBooking(state: IBookingReducer, action: PayloadAction<any>) {
	state.currentBooking = undefined;
	state.bookingDetails = [{}];
	state.selectedCustomer = undefined;
	state.timeActiveId = undefined;
	state.availableStylist = [];
	state.timeBlocks = [];
	state.editing = false;
	state.currentIndex = undefined;
	state.editBookingDetail = undefined;
	state.date = undefined;
	state.tempStylist = undefined;
	state.note = undefined;
}

const updatePromotionDiscount = (
	state: IBookingReducer,
	action: PayloadAction<{ id: string; promotionDiscount: number }[]>
) => {
	//current booking

	let newBooking = Object.assign({}, state.currentBooking);
	newBooking.bookingDetails.forEach((x) =>
		action.payload.forEach((y) => {
			if (x.id === y.id) {
				x.promotionDiscount = y.promotionDiscount;
			}
		})
	);
	state.bookingDetails = newBooking.bookingDetails;
	state.currentBooking = newBooking;
};

const onSelectService = (
	state: IBookingReducer,
	action: PayloadAction<IService>
) => {
	const validBookingDetails = state.bookingDetails.filter(
		(x) => !_.isEmpty(x.itemId)
	);

	const currentIndex = _.isEmpty(validBookingDetails)
		? state.tempStylist
			? undefined
			: 0
		: validBookingDetails.length;
	state.currentIndex = currentIndex;
	let newBookingDetail: Partial<IBookingDetail> = {
		item: action.payload,
		itemId: action.payload.id,
		stylist: state.tempStylist,
		stylistId: state.tempStylist ? state.tempStylist.id : undefined,
	};
	if (!_.isEmpty(validBookingDetails)) {
		if (
			!_.isEmpty(_.last(validBookingDetails)?.stylistId) &&
			_.last(validBookingDetails)?.itemId !== action.payload.id
		) {
			newBookingDetail = {
				...newBookingDetail,
				stylist: state.tempStylist || _.last(validBookingDetails)?.stylist,
				stylistId: state.tempStylist
					? state.tempStylist.id
					: _.last(validBookingDetails)?.stylistId,
			};
		}
		state.bookingDetails.push(newBookingDetail);
	} else {
		state.bookingDetails[0] = {
			...state.bookingDetails[0],
			...newBookingDetail,
		};
	}
	state.editBookingDetail = false;
	state.editing = true;
	if (currentIndex === 0 || currentIndex === undefined) {
		state.tempStylist = undefined;
	}
	// state.tempStylist = undefined;
};
const onSelectStylist = (
	state: IBookingReducer,
	action: PayloadAction<IEmployee | undefined>
) => {
	let newBookingDetail: Partial<IBookingDetail> = {
		stylist: action.payload,
		stylistId: action.payload && action.payload.id,
	};
	if (state.currentIndex !== undefined) {
		state.tempStylist = undefined;
		state.bookingDetails[state.currentIndex] = {
			...state.bookingDetails[state.currentIndex],
			...newBookingDetail,
		};
		state.editing = true;
	} else {
		state.tempStylist = action.payload;
	}
};
const onDeleteIndex = (
	state: IBookingReducer,
	action: PayloadAction<number>
) => {
	if (state.bookingDetails.length === 1) {
		state.bookingDetails = [
			{
				startAtExpected: state.bookingDetails[0].startAtExpected,
			},
		];
	} else {
		state.bookingDetails = state.bookingDetails.filter(
			(x, index) => index !== action.payload
		);
	}
	state.currentIndex = undefined;
	state.editing = true;
	state.tempStylist = undefined;
};

const BookingReducer = createReducer(
	initialState,
	(builder) =>
		builder
			.addCase(BookingActions.selectCustomer.request, selectCustomer)
			.addCase(
				BookingActions.getBookingsByBranch.success,
				getBookingsByBranchSuccess
			)
			.addCase(BookingActions.changeBookingDetail.request, changeBookingDetails)
			.addCase(
				BookingActions.deleteChoosingService.request,
				deleteChoosingService
			)
			.addCase(
				BookingActions.getAvailableStylistAndTimeBlocks.success,
				getAvailableStylistAndTimeBlocks
			)
			.addCase(BookingActions.getBookingById.success, getBookingByIdSuccess)
			.addCase(BookingActions.selectTimeStart.request, selectTimeStart)
			.addCase(BookingActions.removeCustomer.request, removeCustomer)
			.addCase(BookingActions.createBooking.success, createBookingSuccess)
			.addCase(BookingActions.resetBooking.request, resetBooking)
			.addCase(BookingActions.getCustomerByPhone.success, getCustomerByPhone)
			.addCase(BookingActions.getCustomerByPhone.request, removeCustomer)
			.addCase(BookingActions.editBooking.success, editBookingSuccess)
			.addCase(BookingActions.updateStatus.success, editBookingSuccess)
			.addCase(BookingActions.getTodayBookings.success, getTodayBookingSuccess)
			.addCase(BookingActions.socketUpdateBooking.request, socketUpdateBooking)
			.addCase(
				BookingActions.addBookingDetail.request,
				(state: IBookingReducer) => {
					state.bookingDetails.push({
						stylistId: state.bookingDetails[0].stylistId,
					});
				}
			)
			.addCase(BookingActions.addExtraAmount.request, addExtraAmount)
			.addCase(BookingActions.setIsNewCustomer.request, setIsNewCustomer)
			.addCase(
				BookingActions.updatePromotionDiscount.request,
				updatePromotionDiscount
			)
			.addCase(BookingActions.selectService.request, onSelectService)
			.addCase(BookingActions.selectStylist.request, onSelectStylist)
			.addCase(BookingActions.deleteIndex.request, onDeleteIndex)

			.addCase(BookingActions.resetIndex.request, (state: IBookingReducer) => {
				state.currentIndex = undefined;
				state.editBookingDetail = false;
			})
			.addCase(
				BookingActions.selectIndex.request,
				(state: IBookingReducer, action: PayloadAction<number>) => {
					state.currentIndex = action.payload;
					state.editBookingDetail = true;
				}
			)
			.addCase(
				BookingActions.changeDate.request,
				(state: IBookingReducer, action: PayloadAction<Moment>) => {
					state.date = action.payload;
				}
			)
			.addCase(
				BookingActions.unselectBookingDetail.request,
				(state: IBookingReducer, action: PayloadAction<number>) => {
					state.currentIndex = undefined;
				}
			)
			.addCase(
				BookingActions.setNote.request,
				(state: IBookingReducer, action: PayloadAction<string>) => {
					state.note = action.payload;
					state.editing = true;
				}
			)
	// .addCase(BookingActions.updateCurrentBooking.request, (state: IBookingReducer, action: PayloadAction<IBooking>) => {
	//   state.editing = false;
	//   state.currentBooking = action.payload;
	// })
);

export default BookingReducer;
