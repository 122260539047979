import { mdiBlockHelper } from '@mdi/js';
import { Avatar, Container, Icon, Tag } from 'components/atoms';
import { Header } from 'components/molecules/Header';
import { PaymentTypes } from 'components/molecules/PaymentTypes';
import { LoadingProvider } from 'contexts/LoadingContext';
import { StringHelper } from 'helpers';
import { t } from 'i18n-js';
import { IPaymentType } from 'models/ITransaction';
import { BillGeneral } from 'pages/BillDetail/BillGeneral';
import {
	BillDetailProvider,
	useBillDetailContext,
} from 'pages/BillDetail/context';
import { Details } from 'pages/BillDetail/Details';
import React from 'react';
import { translations } from 'utils';

const BillHeader = () => {
	const { bill } = useBillDetailContext();
	return <Header title={`#Invoice: ${bill?.billNumber}`} showClose />;
};
const CustomerInfo = () => {
	const { bill } = useBillDetailContext();

	return (
		<div className="bg-white rounded-lg px-4 py-2 flex">
			<div>
				<Avatar
					small
					name={StringHelper.getShortName(`${bill?.customerName}`)}
				/>
				{/* <Icon path={mdiDelete} className="text-DELETED" /> */}
			</div>
			<div className="ml-4 flex flex-col flex-1 ">
				<span className="p-0 font-semibold text-sm ">
					{`${bill?.customerName}`}
				</span>
				<span className="p-0 text-sm ">{`${StringHelper.formatPhoneNumber(
					bill?.customerPhone
				)}`}</span>
			</div>
		</div>
	);
};
const Body = () => {
	const { bill } = useBillDetailContext();
	return (
		<LoadingProvider
			// loading={loading}
			className="grid h-full grid-cols-5 gap-4 mt-16 overflow-hidden "
		>
			<Details />
			<div className="col-span-2 flex flex-col">
				<CustomerInfo />
				<div className="grid grid-cols-2 mt-4 gap-2 flex-1  pb-16">
					{bill?.status === -1 ? (
						<div className="flex-1 flex flex-col items-center justify-center gap-2 text-DELETED">
							<Icon
								className="w-20 h-20 mx-auto text-DELETED"
								path={mdiBlockHelper}
							/>
							<h3 className="mt-0 text-DELETED">
								{t(translations.billing.voidSuccess)}
							</h3>
						</div>
					) : (
						<PaymentTypes
							hideRemove
							onRemove={() => {}}
							payments={
								bill?.transactions?.map((x) => {
									const payment: Partial<IPaymentType> = {
										paymentType: x.paymentType,
										amount: x.amount,
										transactionReferenceNumber: x.transactionReferenceNumber,
										giftCode: x.giftCode,
										appName: x.appName,
									};
									return payment;
								}) || []
							}
						/>
					)}
					<BillGeneral />
				</div>
			</div>
		</LoadingProvider>
	);
};

export const BillDetail = () => {
	return (
		<BillDetailProvider>
			<Container className="px-0 h-screen overflow-hidden">
				<BillHeader />
				<Body />
			</Container>
		</BillDetailProvider>
	);
};
