import clsxm from 'clsxs/clsxm';
import { SearchInput } from 'components/molecules/SearchInput';
import { IPaginateResponse } from 'models/ResponseModels';
import React, {
	forwardRef,
	Ref,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	mdiSortDescending,
	mdiSortAscending,
	mdiChevronLeft,
	mdiChevronRight,
} from '@mdi/js';
import { Icon } from 'components/atoms/Icon';
import { debounce } from 'lodash';
import { BaseApiService } from 'services/BaseApiService';
import _ from 'lodash';
import { LoadingProvider } from 'contexts/LoadingContext';
import Pagination from 'rc-pagination';
import moment from 'moment';
import { CurrencyHelper, StringHelper, TimeHelper, AlertHelper } from 'helpers';
export enum EColumnType {
	TEXT,
	NUMBER,
	PHONE_NUMBER,
	MONEY,
	DATE,
	TIME,
	STATUS,
	SWITCH,
	OTHERS,
}
export interface IColumnProps<T> {
	header: string;
	renderHeader?: React.ReactNode;
	headerClassName?: string;
	cellClassName?: string;
	key: keyof T;
	type?: EColumnType;
	renderCell?: (data: T, index: number) => React.ReactNode;
	sortable?: boolean;
}
interface ITableProps<T> {
	queryUrl?: string;
	additionalParams?: object;
	columns: IColumnProps<T>[];
	onClickRow?: (data: T) => void;
	searchKey?: string;
	showSearch?: boolean;
	renderHeader?: React.ReactNode;
	renderSubHeader?: React.ReactNode;
	searchPlaceHolder?: string;
	searchIcon?: Element;
	selectedRows?: T[];
	data?: T[];
	showIndex?: boolean;
	keySelected?: keyof T;
	className?: string;
	noDefaultPadding?: boolean;
	extraRow?: React.ReactNode[];
	noPaging?: boolean;
	pagination?: {
		totalItem: number;
		totalPage: number;
		page: number;
		onPageChange: (page: number, searchText: string) => void;
		pageSize?: number;
	};
	onSearchChange?: (searchText: string) => void;
	containerClassName?: string;
	compact?: boolean;
	pageSize?: number;
}
export interface ITableRef {
	refresh: () => void;
}

interface IHeaderProps {
	searchPlaceHolder?: string;
	searchIcon?: Element;
	searchString?: string;
	onChange: (text: string) => void;
	loading?: boolean;
	showSearch?: boolean;
	renderHeader?: React.ReactNode;
	renderSubHeader?: React.ReactNode;
}
class TableService extends BaseApiService {}

const tableServices = new TableService(true);

const Header = (props: IHeaderProps) => {
	return (
		<>
			<div
				className={clsxm(
					'justify-between grid gap-4 grid-cols-1 mb-4',
					props.showSearch && 'grid-cols-4'
				)}
			>
				{props.showSearch && (
					<div className="col-span-1">
						<SearchInput
							disabled={props.loading}
							placeholder={props.searchPlaceHolder}
							onChange={(e) => props.onChange && props.onChange(e.target.value)}
						/>
						{/* <Icon path="mdiAccount" /> */}
					</div>
				)}
				{props.renderHeader && (
					<div
						className={clsxm(
							'flex justify-end',
							props.showSearch ? 'col-span-3' : 'col-span-1'
						)}
					>
						{props.renderHeader}
					</div>
				)}
			</div>
			{props.renderSubHeader && (
				<div className={clsxm('w-full mb-4')}>{props.renderSubHeader}</div>
			)}
		</>
	);
};

const Table = forwardRef(<T,>(props: ITableProps<T>, ref?: Ref<ITableRef>) => {
	const [sort, setSort] = useState<{ [key in keyof T]?: 'asc' | 'desc' }>({});
	const [searchText, setSearchText] = useState('');
	const didMountRef = useRef(false);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [pageSize, setPageSize] = useState(props.pagination?.pageSize || 12);
	const [data, setData] = useState<Partial<IPaginateResponse<T[]>>>();
	const columns = useMemo(() => props.columns, [props.columns]);
	const additionalParams = useMemo(
		() => props.additionalParams,
		[props.additionalParams]
	);
	useImperativeHandle(ref, () => ({
		refresh() {
			fetchData(page, searchText);
		},
	}));
	useEffect(() => {
		if (props.data) {
			setData({
				pageSize: pageSize,
				pageNumber: 1,
				data: props.data || [],
				totalPages: Math.ceil((props.data ? props.data.length : 0) / pageSize),
				totalRecords: props.data ? props.data.length : 0,
			});
		}
	}, [props.data]);
	useEffect(() => {
		convertToString();
		fetchData(page, searchText);
	}, [page, pageSize, sort]);

	useEffect(() => {
		if (didMountRef.current) {
			if (page !== 1) {
				setPage(1);
			} else {
				fetchData(1, searchText);
			}
		}
		didMountRef.current = true;
	}, [additionalParams]);

	const debounceSearch = useCallback(
		debounce((text: string) => {
			setSearchText(text);
			fetchData(1, text);
		}, 1000),
		[additionalParams]
	);
	const convertToString = () => {
		const array = Object.entries(sort);
		const a = array.map((e) => {
			return e.join(' ');
		});
		return a.join(', ');
	};
	const onSearch = (text: string) => {
		props.onSearchChange ? props.onSearchChange(text) : debounceSearch(text);
	};

	const fetchData = async (p = page, searchText?: string) => {
		if (props.data) {
			return;
		}
		setLoading(true);
		let params: any = {
			pageNumber: p,
			pageSize: pageSize,
			...additionalParams,
			searchText: !_.isEmpty(searchText) ? searchText : undefined,
		};
		if (!_.isEmpty(sort)) {
			params.orderBy = convertToString();
		}
		const response = (await tableServices.get(`${props.queryUrl}`, {
			params,
		})) as IPaginateResponse<T[]>;
		setLoading(false);
		if (response.succeeded) {
			setData(response);
			setPage(p);
		} else {
			AlertHelper.showError(response);
		}
	};

	const isSelected = (data: T) => {
		if (props.keySelected && props.selectedRows) {
			let index = props.selectedRows.findIndex(
				(x) => `${x[props.keySelected!]}` === `${data[props.keySelected!]}`
			);
			if (index !== -1) return true;
		}
		return false;
	};
	const onClickSort = (key: keyof T) => {
		if (!sort[key] || sort[key] === 'asc') {
			setSort({
				...sort,
				[key]: 'desc',
			});
		} else {
			setSort({
				...sort,
				[key]: 'asc',
			});
		}
	};
	return (
		<LoadingProvider
			loading={loading}
			className={`w-full h-full overflow-hidden flex flex-col
			${props.containerClassName || ''} ${props.noDefaultPadding ? '' : 'p-4'}`}
		>
			{(props.showSearch || props.renderHeader) && (
				<Header onChange={(text) => onSearch(text)} {...props} />
			)}
			<div
				className={clsxm(
					'overflow-x-auto w-full flex-1',
					// ' max-h-[calc(100vh-450px)]',
					props.className
				)}
			>
				{/* <div
				className={clsxm(
					'overflow-x-auto w-full max-h-[calc(100vh-450px)]',
					props.className
				)}
			> */}
				{/* <LoadingProvider loading={loading}> */}
				<table className="table table-zebra w-full my-0 ">
					<thead>
						<tr>
							{props.showIndex && <th className="w-10"></th>}
							{columns.map((x) => {
								const sortDirection = sort && sort[x.key];
								return (
									<th
										className={clsxm(
											'relative',
											(x.type === EColumnType.MONEY ||
												x.type === EColumnType.NUMBER ||
												x.type === EColumnType.OTHERS) &&
												'text-right',
											x.sortable && 'pr-8',
											x.headerClassName && x.headerClassName
										)}
									>
										{x.renderHeader ? x.renderHeader : `${x.header}`}
										<div
											onClick={() => onClickSort(x.key)}
											className="absolute top-4 right-2 text-black text-opacity-50 cursor-pointer"
										>
											{x.sortable && (
												<Icon
													size={0.8}
													path={
														sortDirection === 'desc'
															? mdiSortDescending
															: mdiSortAscending
													}
												/>
											)}
										</div>
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{data?.data &&
							data?.data?.map((row, index) => {
								return (
									<tr
										className={
											props.onClickRow &&
											'text cursor-pointer active:animate-pop duration-200 ease-in'
										}
										onClick={() => props.onClickRow && props.onClickRow(row)}
									>
										{props.showIndex && <th className="w-10">{index + 1}</th>}
										{columns.map((cell, index) => {
											if (cell.renderCell) {
												return (
													<td
														key={index}
														className={clsxm(
															cell.type === EColumnType.OTHERS && 'text-right',
															cell.cellClassName && cell.cellClassName
														)}
													>
														{cell.renderCell(row, index)}
													</td>
												);
											}

											return (
												<>
													{cell.type === EColumnType.TEXT && (
														<td
															key={index}
															className={clsxm(
																cell.cellClassName && cell.cellClassName
															)}
														>{`${row[cell.key] || ''}`}</td>
													)}
													{cell.type === EColumnType.NUMBER && (
														<td
															key={index}
															className={clsxm(
																'text-right',
																cell.cellClassName && cell.cellClassName
															)}
														>
															{`${row[cell.key] || 0}`}
														</td>
													)}
													{cell.type === EColumnType.PHONE_NUMBER && (
														<td
															key={index}
															className={clsxm(
																cell.cellClassName && cell.cellClassName
															)}
														>
															{StringHelper.formatPhoneNumber(
																`${row[cell.key] || ''}`
															)}
														</td>
													)}
													{cell.type === EColumnType.DATE && (
														<td
															key={index}
															className={clsxm(
																cell.cellClassName && cell.cellClassName
															)}
														>
															{row[cell.key] &&
																TimeHelper.toTimeZone(
																	moment(`${row[cell.key]}`).toString()
																).format('MM/DD/YYYY')}
														</td>
													)}
													{cell.type === EColumnType.MONEY && (
														<td
															key={index}
															className={clsxm(
																'text-right',
																cell.cellClassName && cell.cellClassName
															)}
														>
															{CurrencyHelper.formatPrice(
																Number(`${row[cell.key]}`),
																false
															)}
														</td>
													)}
													{!cell.type && (
														<td
															key={index}
															className={clsxm(
																// 'py-4',
																cell.cellClassName && cell.cellClassName
															)}
														>{`${row[cell.key] || ''}`}</td>
													)}
												</>
											);
										})}
									</tr>
								);
							})}
						{props.extraRow}
					</tbody>
				</table>
			</div>
			<div className="justify-end flex">
				{!props.noPaging && (
					<Pagination
						itemRender={(current, type, element) => {
							if (type === 'page') {
								return (
									<button
										className={clsxm(
											'btn btn-outline rounded aspect-square border-primary text-primary list-none',
											current === page &&
												'main-gradient-background bg-primary rounded text-white'
										)}
									>
										{current}
									</button>
								);
							}
							if (type === 'jump-next' || type === 'jump-prev') {
								return <button className={clsxm('btn btn-ghost')}>...</button>;
							}

							// if (type === 'prev') {
							// 	return (
							// 		<button
							// 			className={clsxm(
							// 				'btn btn-outline btn-sm aspect-square mr-2 list-none'
							// 			)}
							// 		>
							// 			<Icon path={mdiChevronLeft} />
							// 		</button>
							// 	);
							// }
							// if (type === 'next') {
							// 	return (
							// 		<button
							// 			className={clsxm(
							// 				'btn btn-outline btn-sm aspect-square ml-2 list-none'
							// 			)}
							// 		>
							// 			<Icon path={mdiChevronRight} />
							// 		</button>
							// 	);
							// }

							return element;
						}}
						className="btn-group list-none m-0"
						current={page}
						defaultCurrent={1}
						onChange={(current, pagesize) => {
							props.pagination?.onPageChange &&
								props.pagination.onPageChange(current, searchText);
							setPage(current);
						}}
						total={props.pagination?.totalItem || data?.totalRecords || 0}
					/>
				)}
			</div>
			{/* </div> */}
		</LoadingProvider>
	);
});

export default Table as <T extends object>(
	props: ITableProps<T> & { ref?: Ref<ITableRef> }
) => JSX.Element;
