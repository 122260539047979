import { mdiPlus } from '@mdi/js';
import { Button, Container, Icon } from 'components/atoms';
import Table, { ITableRef, IColumnProps } from 'components/atoms/Table';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { Header } from 'components/molecules/Header';
import { TDateRange } from 'components/molecules/ModalCalendar';
import { TimeHelper } from 'helpers';
import { ICloseOnlineBooking } from 'models/ICloseOnlinebooking';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { history } from 'routers';
import { t, translations } from 'utils';

export const ClosedOnlineBooking = () => {
	const [dateFilter, setDateFilter] = useState<TDateRange>({
		fromDate: moment().startOf('date'),
		toDate: moment().add(1, 'month').startOf('date'),
	});
	const tableRef = useRef<ITableRef>(null);

	const descriptionCol: IColumnProps<ICloseOnlineBooking> = {
		header: t(translations.closedOnlineBooking.description),
		key: 'description',
	};

	const dateCol: IColumnProps<ICloseOnlineBooking> = {
		header: t(translations.closedOnlineBooking.date),
		key: 'date',
		renderCell: (value) => moment(value.date).format('MMM Do, YYYY'),
	};

	const fromCol: IColumnProps<ICloseOnlineBooking> = {
		header: t(translations.closedOnlineBooking.from),
		key: 'startTime',
		renderCell: (value) =>
			TimeHelper.getInitialWorkingTime(value.startTime as string).format(
				'hh:mm A'
			),
	};

	const toCol: IColumnProps<ICloseOnlineBooking> = {
		header: t(translations.closedOnlineBooking.to),
		key: 'endTime',
		renderCell: (value) =>
			TimeHelper.getInitialWorkingTime(value.endTime as string).format(
				'hh:mm A'
			),
	};

	return (
		<Container>
			<div className="grid grid-cols-6 mb-4">
				<DateRangePicker
					// disableBefore={true}
					onSelectDateRange={setDateFilter}
					selectedDateRange={dateFilter}
				/>
			</div>
			<Table<ICloseOnlineBooking>
				ref={tableRef}
				columns={[descriptionCol, dateCol, fromCol, toCol]}
				noDefaultPadding
				queryUrl="/CloseOnlineBookingTime/get-all-close-online-booking-times"
				showSearch
				additionalParams={{
					orderBy: 'date asc',
					fromDate: dateFilter.fromDate!.format('YYYY-MM-DD'),
					toDate: dateFilter.toDate!.format('YYYY-MM-DD'),
				}}
				renderHeader={
					<Button
						title={t(translations.addNew)}
						small
						iconBefore={<Icon path={mdiPlus} />}
						primary
						onClick={() => history.push('/closed-online-appointments/add')}
					/>
				}
				onClickRow={(data) => {
					history.push(`/closed-online-appointments/edit/${data.id}`);
				}}
			/>
		</Container>
	);
};

export * from './ClosedOnlineBookingDetail';
