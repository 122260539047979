import Table, { EColumnType, IColumnProps } from 'components/atoms/Table';
import { IGiftCard } from 'models';
import { I18n, _t, translations } from 'utils';
import { useMemo, useState } from 'react';
import './styles.css';
import moment from 'moment';
import { CurrencyHelper } from 'helpers';
import { mdiPlus } from '@mdi/js';
import { Button, Icon, Input } from 'components/atoms';
import { useHistory } from 'react-router-dom';
import { SaleGiftCardModal } from 'components/molecules/SaleGiftCard';

const ListGiftCard = () => {
	const history = useHistory();
	const [sellGiftCardModal, setSellGiftCardModal] = useState(false);

	const columns = useMemo<IColumnProps<IGiftCard>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.listGiftCard.giftCode)),
				key: 'giftCode',
				renderCell: (data) => {
					return (
						<div className="align-middle flex font-bold pl-3">
							{data.giftCode}
						</div>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listGiftCard.customername)),
				key: 'name',
				renderCell: (data) => {
					return <p className=" font-bold ">{data.name}</p>;
				},
			},
			{
				header: I18n.t(_t(translations.listGiftCard.expiredDate)),
				key: 'sellDate',
				renderCell: (data) => {
					return (
						<div className="align-middle flex">
							<div>{data.toDate ? moment(data.toDate).format('LL') : ''}</div>
						</div>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listBooking.createdDate)),
				key: 'toDate',
				type: EColumnType.DATE,
				renderCell: (data) => {
					return (
						<div className="align-middle flex">
							<div>
								{data.sellDate
									? moment(data.sellDate).format('LL')
									: moment(data.fromDate).format('LL')}
							</div>
						</div>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listGiftCard.lastedUse)),
				key: 'lastUsed',
				type: EColumnType.DATE,
				renderCell: (data) => {
					return (
						<div className="align-middle flex">
							<div>
								{data.lastUsed
									? moment(data.lastUsed).format('LL')
									: moment(data.fromDate).format('LL')}
							</div>
						</div>
					);
				},
			},
			{
				key: 'title',
				header: I18n.t(_t(translations.listGiftCard.description)),
				renderCell: (data) => {
					return (
						<div>
							<p className=" font-bold ">{data.title}</p>
							<p>{data.description}</p>
						</div>
					);
				},
			},
			{
				key: 'amount',
				header: `${I18n.t(_t(translations.listGiftCard.balance))} ($)`,
				renderCell: (data) => {
					return (
						<p className=" font-bold text-center">
							{CurrencyHelper.formatPrice(data.balance, false)}
						</p>
					);
				},
			},
			{
				key: 'amount',
				header: `${I18n.t(_t(translations.amount))} ($)`,
				renderCell: (data) => {
					return (
						<p className=" font-bold text-center">
							{CurrencyHelper.formatPrice(data.amount, false)}
						</p>
					);
				},
			},
		];
	}, []);

	return (
		<>
			<Table<IGiftCard>
				queryUrl={'/GiftCard/get-all-giftcards'}
				renderHeader={
					<div className="justify-center flex flex-col md:flex-row gap-4">
						<Button
							small
							title={I18n.t(_t(translations.addVoucher))}
							iconBefore={<Icon path={mdiPlus} />}
							// white
							onClick={() => setSellGiftCardModal(true)}
							className="btn-outline btn-primary bg-white"
						/>
						<Button
							onClick={() => history.push('check-out/sale-giftcard')}
							small
							title={I18n.t(_t(translations.sellGiftCard))}
							iconBefore={<Icon path={mdiPlus} />}
							primary
						/>
					</div>
				}
				columns={columns}
				showSearch
				additionalParams={{
					status: 1,
					orderBy: 'fromDate desc',
				}}
				className="max-h-[calc(100vh-27.3rem)]"
			/>
			{sellGiftCardModal && (
				<SaleGiftCardModal
					isVoucher
					onCancel={() => setSellGiftCardModal(false)}
					// onConfirm={() => setSellGiftCardModal(false)}
				/>
			)}
		</>
	);
};

export default ListGiftCard;
