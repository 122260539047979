import clsxm from "clsxs/clsxm";
import { TimeHelper } from "helpers";
import React from "react";

interface ITimeProps {
  id: string;
  label: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export const TimeBlock = (props: ITimeProps) => {
  return (
    <div
      id={props.id}
      key={props.id}
      onClick={() => {
        if (!props.disabled) {
          props.onClick && props.onClick();
        }
      }}
      className={clsxm(
        "btn btn-outline p-4 rounded-md w-24 h-10 border-primary border-2 text-primary  hover:main-gradient-background",
        props.disabled && "btn-disabled border-none text-base-300 bg-base-200 ",
        props.active && "main-gradient-background text-white border-none",
        props.className
      )}
    >
      {TimeHelper.toTimeZone(props.label).format("hh:mmA")}
    </div>
  );
};
