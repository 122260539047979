import { mdiPencilOutline } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Card, Icon, Modal } from 'components/atoms';
import Table, {
	EColumnType,
	IColumnProps,
	ITableRef,
} from 'components/atoms/Table';
import { Header } from 'components/molecules/Header';
import { LoadingProvider } from 'contexts/LoadingContext';
import { AlertHelper } from 'helpers';
import { isEmpty } from 'lodash';
import { IApiResponse, IErrorResponse, IGallery } from 'models';
import { IAddGalleryRequest } from 'models/RequestModels';
import { IFileUploadResponse } from 'models/ResponseModels';
import React, { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import GalleryApiService from 'services/GalleryApiService';
import UploadBaseService from 'services/UploadBaseService';
import { translations, t } from 'utils';
import { ContentGallery } from './ContentGalleryModal';

interface IGalleryTable extends IGallery {
	includeCatetogy?: boolean;
	numberOfImages?: number;
	status?: number;
}
export interface IImageGallery {
	id?: string;
	imageUrl: string;
	file?: File;
}

export const GallerySetup = () => {
	const [loading, setLoading] = useState(false);
	const [selectedGallery, setSelectedGallery] = useState<IGallery>();
	const [images, setImages] = useState<IImageGallery[]>([]);
	const [showModal, setShowModal] = useState(false);
	const tableRef = useRef<ITableRef>();
	const form = useForm<IAddGalleryRequest>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	const columns = useMemo<IColumnProps<IGalleryTable>[]>(() => {
		return [
			{
				header: t(translations.miniCMSSetting.columnGalleryName),
				key: 'title',
			},
			{
				header: t(translations.miniCMSSetting.columnType),
				key: 'includeCatetogy',
				renderCell: (data) => {
					return data.description
						? t(translations.miniCMSSetting.Category)
						: t(translations.miniCMSSetting.Gallery);
				},
			},
			{
				header: t(translations.miniCMSSetting.columnNumberOfImages),
				key: 'numberOfImages',
				type: EColumnType.NUMBER,
				renderCell: (data) => {
					return (
						<div className="text-right">{data.galleryPictures.length}</div>
					);
				},
			},
			{
				header: t(translations.status),
				key: 'status',
				renderCell: (data) => {
					return (
						<div
							className={data.status === 0 ? 'text-ERROR' : 'text-CHECKED_IN'}
						>
							{data.status === 0
								? t(translations.unActive)
								: t(translations.active)}
						</div>
					);
				},
			},
			{
				header: t(translations.action),
				key: 'id',
				headerClassName: 'w-44',
				cellClassName: 'w-44',
				renderCell: (data) => {
					return (
						<div className="flex gap-4 items-center">
							<div
								className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
								onClick={() => handleShowModalEdit(data)}
							>
								<Icon path={mdiPencilOutline} className="w-6 h-6" />
							</div>
						</div>
					);
				},
			},
		];
	}, []);

	const handleShowModalEdit = (gallery: IGalleryTable) => {
		setSelectedGallery(gallery);
		setImages(gallery.galleryPictures.map((x) => x.picture));
		form.setValue('title', gallery.title);
		form.setValue('status', gallery.status);
		form.setValue('description', gallery.description);
		setShowModal(true);
	};
	const handleCancelModal = () => {
		setSelectedGallery(undefined);
		setImages([]);
		form.reset({
			title: '',
			description: '',
			status: 0,
		});
		setShowModal(false);
	};

	const handleUploadRemoveImages = async () => {
		let pictureIds: string[] = [];
		let error = null;
		const dataImageNeedUpload = images.filter((x) => !x.id);
		const dataPost = images.filter((x) => x.id);
		if (dataImageNeedUpload.length > 0) {
			const files = dataImageNeedUpload.map((x) => x.file);
			try {
				const res = (await UploadBaseService.uploadImage(
					files
				)) as IApiResponse<IFileUploadResponse[]>;
				if (res.succeeded) {
					const { data } = res;
					if (!isEmpty(data)) {
						data!.map((x) => {
							dataPost.push({
								id: x.id,
								imageUrl: x.imageUrl,
							});
						});
					}
				} else {
					error = res as IErrorResponse;
				}
			} catch (err) {
				console.log(err);
			}
		}
		pictureIds = dataPost.map((x) => x.id || '');
		return { pictureIds, error };
	};

	const onSubmit = async (formData: IAddGalleryRequest) => {
		setLoading(true);
		const { pictureIds, error } = await handleUploadRemoveImages();
		if (error) {
			AlertHelper.showError(error);
			return;
		}
		const params = {
			...selectedGallery,
			title: formData.title.trim(),
			status: formData.status,
			description: formData.description,
			pictureIds,
		};

		try {
			const res = isEmpty(selectedGallery)
				? await GalleryApiService.addGallery(params)
				: await GalleryApiService.updateGallery(params);
			if (res.succeeded) {
				isEmpty(selectedGallery)
					? AlertHelper.showSuccess(
							t(translations.miniCMSSetting.addGallerySuccess)
					  )
					: AlertHelper.showSuccess(
							t(translations.miniCMSSetting.updateGallerySuccess)
					  );
				tableRef.current?.refresh();
				handleCancelModal();
			} else {
				const error = res as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const onDeleteGallery = async () => {
		const res: any = await GalleryApiService.deleteGallery(selectedGallery?.id);
		if (res.succeeded) {
			AlertHelper.showSuccess(
				t(translations.miniCMSSetting.deleteGallerySuccess)
			);
			handleCancelModal();
			tableRef.current?.refresh();
		} else {
			const error = res as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	return (
		<LoadingProvider loading={loading} className="h-full">
			<div className={clsxm('w-full flex flex-col h-full overflow-y-auto')}>
				<div className={clsxm('pt-8 my-16 w-full px-16 grid gap-y-8')}>
					<Header title={t(translations.miniCMSSetting.title)} showClose />
					<Card
						title={t(translations.miniCMSSetting.gallery)}
						description={t(translations.miniCMSSetting.subGallery)}
					>
						<Table
							ref={(ref) => {
								if (ref) {
									tableRef.current = ref;
								}
							}}
							showSearch
							queryUrl="/Gallery/get-all-galleries/"
							columns={columns}
							renderHeader={
								<Button
									small
									primary
									title={t(translations.miniCMSSetting.addGallery)}
									onClick={() => {
										setSelectedGallery(undefined);
										setShowModal(true);
									}}
								/>
							}
							className="max-h-[calc(100vh-28rem)]"
						/>
					</Card>
					<FormProvider {...form}>
						<Modal
							title={
								selectedGallery
									? t(translations.miniCMSSetting.editGallery)
									: t(translations.miniCMSSetting.addGallery)
							}
							isOpen={showModal}
							canConfirm
							onConfirm={form.handleSubmit(onSubmit)}
							onCancel={() => {
								handleCancelModal();
							}}
							content={
								<ContentGallery
									images={images}
									setImages={(images) => setImages(images)}
								/>
							}
							buttons={
								selectedGallery && [
									<Button
										small
										title={t(translations.delete)}
										onClick={() => {
											onDeleteGallery();
										}}
										disabled={!isEmpty(selectedGallery?.description)}
										className="btn-outline btn-error bg-white ml-4"
									/>,
								]
							}
						/>
					</FormProvider>
				</div>
			</div>
		</LoadingProvider>
	);
};
