import clsxm from 'clsxs/clsxm';
import { Container } from 'components/atoms/Container';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BookingActions, TodayActions } from 'redux/actions';
import {
	getMoneyOfEmployee,
	getTodayBookingByStatus,
	numberOfBookingOfEmployee,
} from 'redux/selectors/employee';
import {
	mdiAlignVerticalBottom,
	mdiLightningBoltCircle,
	mdiCalendarPlus,
} from '@mdi/js';
import { Icon } from 'components/atoms/Icon';
import { BookingStatus } from 'utils/Consts';
import { ButtonGroup } from 'components/atoms/ButtonGroup';
import { _t } from 'utils';
import { TodayProvider, useTodayContext } from 'pages/Today/context';
import { Button } from 'components/atoms/Button';
import { RealTimeBooking } from './RealTimeBooking';
import { TurnChart } from 'pages/Today/TurnChart';
import { useHistory } from 'react-router-dom';
import { Modal } from 'components/atoms';
import { formatPrice } from 'helpers/currencyHelper';
import moment from 'moment';
import { getEmployeesSortByTurn } from 'redux/selectors/employee';
import qs from 'qs';
import { EEmployeeTypeCode, EStaffStatus } from 'models';
import { CheckGiftCardModal } from './components/CheckGiftCardModal';

const ChartType = () => {
	const { chartType, setChartType } = useTodayContext();
	return (
		<div
			id="btn-group-chart-type"
			className="w-full mt-2 bg-white rounded-lg mb-4 "
		>
			<ButtonGroup<{ id: number; name: string }>
				buttons={[
					{ id: 1, name: 'Turns' },
					{ id: 2, name: 'Money' },
				]}
				className="justify-center"
				selectedId={chartType}
				onChange={(value) => setChartType(value.id)}
				valueField={'id'}
				titleField="name"
				// small
			/>
		</div>
	);
};

const StaffInfo = (props: {
	color?: string;
	name: string;
	earned: number;
	id: string;
	onSelectStaff?: () => void;
	img?: string;
}) => {
	const styles = {
		backgroundColor: props.color || 'white',
		borderColor: props.color || 'black',
	};
	const todayBookings = useAppSelector((state) =>
		getTodayBookingByStatus(state, -1)
	);
	const numberOfTurns = useAppSelector((state) =>
		numberOfBookingOfEmployee(todayBookings, props.id)
	);
	const amount = useAppSelector((state) =>
		getMoneyOfEmployee(todayBookings, props.id)
	);
	const roles = useAppSelector((state) => state.UserReducer.user?.userRoles);
	const employeeRoles = useAppSelector(
		(state) => state.UserReducer.employee?.employeeTypes
	);
	const rolePermission = ['Admin', EEmployeeTypeCode.MANAGER];

	const hideMoney =
		!_.isEmpty(rolePermission) &&
		_.isEmpty(_.intersection(rolePermission, roles)) &&
		_.isEmpty(
			_.intersection(
				rolePermission?.map((x) => x.toLowerCase()),
				employeeRoles?.map((x) => x.code?.toLowerCase())
			)
		);

	const history = useHistory();
	const onSelectStaff = () => {
		const query = qs.stringify({
			quickSale: 'true',
			stylistId: props.id,
		});

		history.push(`/booking/new?${query}`);
	};
	return (
		<div
			className={clsxm(
				'flex rounded-md shadow-md active:animate-pop duration-500 ease-in cursor-pointer h-[70px] border-primary border-2',
				props.color
			)}
			style={styles}
			onClick={() => onSelectStaff()}
		>
			<div className="col-span-1/2 rounded-md w-16">
				{!_.isEmpty(props.img) ? (
					<div className=" h-full overflow-hidden bg-base-300 rounded-l-md w-16">
						<div className={clsxm('w-full h-full ')}>
							<img
								alt=""
								src={props.img}
								className="m-0 object-cover h-full "
							/>
						</div>
					</div>
				) : (
					<div
						className={clsxm(
							'text-neutral-content h-full flex items-center justify-center bg-base-300 rounded-l-md w-16 '
						)}
					>
						<div className="text-[#000] font-semibold text-[11px] text-center break-all">
							{`${props.name.split(' ')[0] || ''}`.toLocaleUpperCase()}
						</div>
					</div>
				)}
			</div>

			<div className="ml-2 w-full self-center">
				<p className="font-semibold text-black m-0">{props.name}</p>
				<p className="flex m-0 text-black">
					<p className="m-0 pr-4">
						<b>T: </b>
						{numberOfTurns}
					</p>
					{!hideMoney && (
						<p className="m-0">
							<b> M: </b>
							{formatPrice(amount)}
						</p>
					)}
				</p>
			</div>
		</div>
	);
};
const SideBarButtons = () => {
	const history = useHistory();
	const todayBookings = useAppSelector(
		(state) => state.BookingReducer.todayBookings
	);
	const [isShowTurnDetail, setIsShowTurnDetail] = useState(false);
	const [showCheckGiftCard, setShowCheckGiftCard] = useState(false);
	return (
		<div className="grid grid-cols-7 grid-flow-row gap-1 text-center">
			<div className="col-span-3 grid grid-cols-2 grid-flow-row gap-1 text-center">
				<div className="col-span-1 flex justify-center items-center p-[2px] bg-white rounded-lg">
					{/* <h6 className="text-PENDING pb-0 ">TOTAL</h6> */}
					<h4 className="m-0 p-0 text-primary leading-tight">
						{<strong>{`${Object.keys(todayBookings).length || 0}`} </strong>}{' '}
						appointments
					</h4>
				</div>
				<Button
					small
					className="col-span-1 btn-outline bg-white w-full h-full shadow-lg "
					title="Check Gift Card"
					id="newGiftCard"
					onClick={() => setShowCheckGiftCard(true)}
				/>
			</div>

			<Button
				small
				className="btn-outline col-span-2 bg-white w-full h-full shadow-lg "
				title="Sale Gift Card"
				id="newGiftCard"
				onClick={() => history.push('check-out/sale-giftcard')}
			/>
			<Button
				track
				onClick={() => history.push('/booking')}
				title="New Appointment"
				id="newBooking"
				small
				className="bg-PENDING col-span-2 text-black border-none w-full h-full p-0.5 shadow-lg gap-0"
				iconBefore={<Icon path={mdiCalendarPlus} />}
			/>
			<Button
				onClick={() => setIsShowTurnDetail(true)}
				title="TURN DETAIL"
				small
				iconBefore={<Icon path={mdiAlignVerticalBottom} />}
				className="btn-outline col-span-3 bg-CHECKED_IN bg-opacity-70 text-white w-full h-full shadow-lg"
			/>

			<Button
				track
				onClick={() => history.push('/booking?quickSale=true')}
				primary
				title="Quick Sale"
				iconBefore={<Icon path={mdiLightningBoltCircle} />}
				className="col-span-4"
			/>
			{showCheckGiftCard && (
				<CheckGiftCardModal onCancel={() => setShowCheckGiftCard(false)} />
			)}
			<Modal
				className="booking-date-picker-modal w-3/4 z-[999999] calendar-modal "
				title="Turn Detail"
				isOpen={isShowTurnDetail}
				onCancel={() => {
					setIsShowTurnDetail(false);
				}}
				content={
					<div className="flex flex-col w-full px-4 pt-4">
						<TurnChart />
						<ChartType />
					</div>
				}
			/>
		</div>
	);
};

const SideBarStaffs = () => {
	const todayBookings = useAppSelector((state) =>
		getTodayBookingByStatus(state, -1)
	);
	const poolStaffs = useAppSelector((state) =>
		getEmployeesSortByTurn(
			todayBookings,
			state.TodayReducer.poolStaffs
				.filter((x) => x.availableStylistStatus === EStaffStatus.AVAILABLE)
				.map((x) => x.employee)
		)
	);
	return (
		<div className="bg-white rounded-md gap-2 p-2 h-[74vh]">
			<div className="font-bold text-black mb-2">AVAILABLE STAFFS</div>
			<div className="grid 2xl:grid-cols-3 xl:grid-cols-2 grid-cols-1 grid-flow-row gap-2 max-h-[69vh] overflow-y-auto hide-scrollbar pb-2">
				{poolStaffs.map((x) => {
					return (
						<StaffInfo
							color={x.color}
							earned={0}
							name={`${x.firstName || ''} ${x.lastName || ''}`}
							id={x.id}
							img={x.imageUrl}
							onSelectStaff={() => {}}
						/>
					);
				})}
			</div>
		</div>
	);
};
const NewSideBar = () => {
	return (
		<div className="col-span-4 flex flex-col gap-2">
			<SideBarButtons />
			<SideBarStaffs />
		</div>
	);
};

const Body = () => {
	const { tab } = useTodayContext();
	return (
		<div className="col-span-6 overflow-hidden">
			{tab === 'bookings' && <RealTimeBooking />}
		</div>
	);
};

export const Today = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(BookingActions.getTodayBookings.request());
		dispatch(
			TodayActions.getPoolStaffs.request({
				date: moment().format('MM-DD-YYYY'),
			})
		);
	}, []);

	return (
		<TodayProvider>
			<Container>
				<div className="grid h-full grid-cols-10 gap-2">
					<Body />
					<NewSideBar />
				</div>
			</Container>
		</TodayProvider>
	);
};
