import {
	IBookingActivity,
	IBookingHourSummary,
	IDashBoardSummary,
	IRecentSalesChartDataSource,
	IReviewSummary,
	ITopServices,
	ITopStaffs,
	ITotalBookingSummary,
	IUpComingBookingsChartDataSource,
} from 'models';
import {
	IGetRequest,
	IPaginateRequest,
	ITodayNextBookingActivityRequest,
	ITopServicesRequest,
} from 'models/RequestModels';
import { IPaginateResponse } from 'models/ResponseModels';
import { BaseApiService } from './BaseApiService';

const url = 'AdminDashboard';

class AdminDashboardApiService extends BaseApiService {
	public getDashboardSummary = (request: IGetRequest) => {
		return this.get<IDashBoardSummary>(`/Report/dashboard-summary`, {
			params: {
				...request,
				periodType: -1,
			},
		});
	};
	public getHourBookingReport = (request: IGetRequest) => {
		return this.get<IBookingHourSummary>(`/Report/booking-report-by-hour`, {
			params: {
				...request,
				periodType: -1,
			},
		});
	};
	public getTotalBookingSummary = (request: IGetRequest) => {
		return this.get<ITotalBookingSummary>(`/Report/summary-total-appointment`, {
			params: {
				...request,
				periodType: -1,
			},
		});
	};
	public getReviewSummary = (request: IGetRequest) => {
		return this.get<IReviewSummary>(`/Report/customer-review-report`, {
			params: {
				...request,
				periodType: -1,
			},
		});
	};
	public getRecentSalesChart = (request: IGetRequest) =>
		this.get<IRecentSalesChartDataSource>(`${url}/recent-sales`, {
			params: {
				...request,
				periodType: -1,
			},
		});
	public getUpComingBookingsChart = (request: IGetRequest) =>
		this.get<IUpComingBookingsChartDataSource>(`${url}/upcoming-appointments`, {
			params: request,
		});
	public getBookingActivity = (request: IPaginateRequest) =>
		this.get<IPaginateResponse<IBookingActivity[]>>(
			`${url}/appointments-activity`,
			{
				params: request,
			}
		);
	public getTodayNextBookingActivity = (
		request: ITodayNextBookingActivityRequest
	) =>
		this.get<IPaginateResponse<IBookingActivity[]>>(
			`${url}/today-appointments`,
			{
				params: request,
			}
		);
	public getTopServices = (request: ITopServicesRequest) =>
		this.get<ITopServices[]>(`${url}/top-services`, {
			params: request,
		});
	public getTopStaffs = (request: ITopServicesRequest) =>
		this.get<ITopStaffs[]>(`${url}/top-staffs`, {
			params: {
				...request,
			},
		});
}

export default new AdminDashboardApiService(true);
