import { useEffect, useState } from 'react';

import { Button } from 'components/atoms/Button';
import { CustomerModal } from 'components/molecules/CustomerModal';
import { ListBookingDetail } from 'pages/Booking/components/ListBookingDetail';
import { createBillFromBooking, useBookingHook } from 'helpers/bookingHelper';
import {
	BookingHelper,
	ColorHelper,
	CurrencyHelper,
	TimeHelper,
} from 'helpers';
import { Description } from 'components/atoms/Description';
import _ from 'lodash';
import clsxm from 'clsxs/clsxm';
import { CustomerInfo } from 'pages/Booking/components/CustomerInfo';
import { StatusTag } from 'components/molecules/StatusTag';
import { Icon } from 'components/atoms/Icon';
import { BookingTypeIcon } from 'components/molecules/BookingTypeIcon';
import { EBookingInputFrom, EBookingType, IBooking, ICustomer } from 'models';
import { ButtonGroup } from 'components/atoms/ButtonGroup';
import { BookingStatus, WALKIN_CUSTOMER } from 'utils/Consts';
import {
	mdiContentSaveCheck,
	mdiCashCheck,
	mdiCloudSync,
	mdiHomeAccount,
	mdiTimerPlay,
	mdiWalk,
} from '@mdi/js';
import moment from 'moment';
import { useAppSelector } from 'helpers/hookHelpers';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { showAlert, showError, showSuccess } from 'helpers/alertHelper';
import { AppConfigActions, BookingActions } from 'redux/actions';
import { t, translations, _t } from 'utils';
import I18n from 'i18n-js';
import { IBilling } from 'models/IBilling';
import BillingApiService from 'services/BillingApiService';
import { useDispatch } from 'react-redux';
import { bookingTours } from 'models/TourGuide';
import { Actions } from './Actions';
import qs from 'qs';
import { IBookingSearchQuery } from 'models/RequestModels';

type ConfirmType = 'save' | 'update' | 'checkIn' | 'checkOut' | 'working';

const Footer = (props: { onConfirm: (type: ConfirmType) => void }) => {
	const { customer, bookingDetails, currentBooking, editing } =
		useBookingHook();
	const employees = useAppSelector(
		(state) => state.EmployeeReducer.employees || []
	);
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const quickSale = queries.quickSale;

	const totalPrice = CurrencyHelper.formatPrice(
		_.sumBy(
			bookingDetails,
			(bookingDetail) => bookingDetail?.price! || bookingDetail.item?.price || 0
		)
	);
	const totalDuration = TimeHelper.convertDurationToStringDetail(
		_.sumBy(bookingDetails, (bookingDetail) => {
			const employee = employees.find((x) => x.id === bookingDetail.stylistId);
			let duration = bookingDetail.item?.duration!;
			if (employee) {
				const serviceDuration = employee.serviceDurations?.find(
					(x) => x.serviceId === bookingDetail.itemId
				);
				if (serviceDuration && serviceDuration.duration !== 0) {
					duration = serviceDuration.duration;
				}
			}
			return duration;
		})
	);
	const [loading, setLoading] = useState(false);

	const getButtonTitleAndIcon = (): {
		title: string;
		icon: string;
		type: ConfirmType;
	} => {
		if (quickSale) {
			return {
				title: t(translations.bookingCustomerInfo.expressCheckout),
				icon: mdiCashCheck,
				type: 'save',
			};
		}
		if (!currentBooking) {
			return {
				title: t(translations.bookingCustomerInfo.saveBooking),
				icon: mdiContentSaveCheck,
				type: 'save',
			};
		}
		if (editing) {
			return {
				title: t(translations.bookingCustomerInfo.updateBooking),
				icon: mdiCloudSync,
				type: 'update',
			};
		}
		if (
			currentBooking.status === BookingStatus.PENDING ||
			currentBooking.status === BookingStatus.CONFIRMED
		) {
			return {
				title: t(translations.bookingCustomerInfo.expressCheckIn),
				icon: mdiHomeAccount,
				type: 'checkIn',
			};
		}
		return {
			title: t(translations.bookingCustomerInfo.expressCheckout),
			icon: mdiCashCheck,
			type: 'checkOut',
		};
	};
	const disabled = () => {
		if (loading) {
			return true;
		}

		if (getButtonTitleAndIcon().type === 'checkOut' || quickSale) {
			return (
				!_.isEmpty(_.find(bookingDetails, (x) => _.isEmpty(x.itemId))) ||
				!_.isEmpty(
					_.find(
						!_.isEmpty(currentBooking)
							? currentBooking?.bookingDetails
							: bookingDetails,
						(detail) => _.isEmpty(detail.stylistId)
					)
				)
			);
		}
		return _.isEmpty(customer) || _.isEmpty(bookingDetails[0].itemId);
	};

	const showButtonConfirm = () => {
		if (
			currentBooking?.status === BookingStatus.CANCELED ||
			(currentBooking?.status || 0) >= BookingStatus.EXPIRED
		) {
			return false;
		}
		return true;
	};

	return (
		<div
			id="side-footer"
			className="pt-2 flex flex-col justify-between mt-2 border-t pb-0 bg-white rounded-lg border-t-base-200"
		>
			<div className={'px-4'}>
				<Description
					titleClassName="font-bold"
					value={`${totalPrice} (${totalDuration})`}
					title={t(translations.bookingCustomerInfo.total)}
				/>
			</div>
			{showButtonConfirm() ? (
				<div className="flex w-full gap-1">
					{currentBooking?.status === BookingStatus.CHECKED_IN &&
						getButtonTitleAndIcon().type !== 'update' && (
							<Button
								onClick={() => {
									setLoading(true);
									props.onConfirm('working');
									setTimeout(() => {
										setLoading(false);
									}, 1000);
								}}
								iconBefore={<Icon path={mdiTimerPlay} />}
								title={'Working'}
								className={clsxm(
									'rounded-none flex-1 mb-0 rounded-b-lg border-none text-white bg-[#ff7802]',
									loading && 'loading'
								)}
							/>
						)}
					<Button
						disabled={disabled()}
						primary={
							!disabled() &&
							getButtonTitleAndIcon().type !== 'update' &&
							getButtonTitleAndIcon().type !== 'checkIn'
						}
						onClick={() => {
							setLoading(true);
							props.onConfirm(getButtonTitleAndIcon().type);
							setTimeout(() => {
								setLoading(false);
							}, 1000);
						}}
						iconBefore={<Icon path={getButtonTitleAndIcon().icon} />}
						title={getButtonTitleAndIcon().title}
						className={clsxm(
							'rounded-none flex-1 mb-0 rounded-b-lg border-none text-white',
							disabled() && 'btn-disabled',
							getButtonTitleAndIcon().type === 'update' && 'btn-warning',
							getButtonTitleAndIcon().type === 'checkIn' && 'bg-CHECKED_IN',
							loading && 'loading'
						)}
					/>
				</div>
			) : (
				<div className="btn bg-white border-none" />
			)}
		</div>
	);
};

const BookingInformation = (props: {
	onChangeBookingType: (type: EBookingType) => void;
	currentType: EBookingType;
}) => {
	const { currentBooking, bookingDetails } = useBookingHook();
	if (!currentBooking) {
		return (
			<div
				id="booking-type"
				className="w-full bg-white align-middle items-center rounded-l-lg mb-2 px-4 py-2 flex"
			>
				<h5 className="text-black">
					{' '}
					{t(translations.bookingCustomerInfo.bookingType)}
				</h5>

				<ButtonGroup
					buttons={[
						{
							id: EBookingType.WalkIn,
							label: t(
								translations.bookingCustomerInfo.bookingTypeWalkin
							).toUpperCase(),
						},
						{
							id: EBookingType.Appointment,
							label: t(
								translations.bookingCustomerInfo.bookingTypeAppointment
							).toUpperCase(),
						},
					]}
					className={'ml-4'}
					buttonClassNames={[
						` hover:bg-[#969696] ${
							moment(bookingDetails[0].startAtExpected)
								.startOf('date')
								.isAfter(moment().startOf('date'))
								? 'btn-disabled'
								: ''
						}`,
						'hover:bg-[#969696] _',
					]}
					icons={[
						<BookingTypeIcon type={EBookingType.WalkIn} />,
						<BookingTypeIcon type={EBookingType.Appointment} />,
					]}
					valueField={'id'}
					titleField={'label'}
					value={{
						id: props.currentType,
					}}
					onChange={(value) => props.onChangeBookingType(value.id)}
					small
				/>
			</div>
		);
	}
	return (
		<div className="w-full btn-disabled bg-white align-middle items-center rounded-l-lg mb-2 px-4 py-2 flex">
			<StatusTag className="p-3" status={currentBooking?.status || 0} />
			<div
				className={clsxm(
					'btn btn-sm bg-base-200 border-none mx-4 gap-2',
					`text-${ColorHelper.getNameColorFromStatus(
						currentBooking?.status || 0
					)}`
				)}
			>
				<BookingTypeIcon
					status={currentBooking?.status}
					type={currentBooking?.bookingType}
				/>
				<span>
					{currentBooking?.bookingType === EBookingType.WalkIn
						? t(
								translations.bookingCustomerInfo.bookingTypeWalkin
						  ).toUpperCase()
						: t(
								translations.bookingCustomerInfo.bookingTypeAppointment
						  ).toUpperCase()}
				</span>
			</div>
		</div>
	);
};

export const SideBooking = () => {
	const {
		customer,
		bookingDetails,
		timeActiveId,
		createBooking,
		loading,
		updateBooking,
		onCheckIn,
		onWorking,
		selectCustomer,
		currentBooking,
	} = useBookingHook();
	const history = useHistory();
	const bookingAction = useAppSelector(
		(state) => state.ReduxActionReducer['BOOKING']
	);

	const { currentBranch } = useAppSelector((state) => state.BranchReducer);
	const [showCustomerModal, setShowCustomerModal] = useState(false);
	const employees = useAppSelector((state) => state.EmployeeReducer.employees);
	const note = useAppSelector((state) => state.BookingReducer.note);
	const [editCustomer, setEditCustomer] = useState(false);
	// const [run, setRun] = useState(false);
	// const [steps, setSteps] = useState<Step[]>([]);
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const quickSale = queries.quickSale;
	const [bookingType, setBookingType] = useState(
		quickSale ? EBookingType.WalkIn : EBookingType.Appointment
	);

	const onShowCustomerModal = () => {
		setShowCustomerModal(true);
	};
	useEffect(() => {
		if (quickSale) {
			selectCustomer(WALKIN_CUSTOMER);
		}
	}, []);

	const onSaveBooking = async (update = false) => {
		const duration = _.sumBy(bookingDetails, (x) => x.item?.duration || 0);
		const startDateTime = quickSale
			? moment().utc()
			: moment.utc(`${timeActiveId}`);
		const endDateTime = moment.utc(startDateTime).add(duration, 'minutes');
		const convertedBookingDetails = BookingHelper.convertBookingDetails(
			bookingDetails,
			startDateTime,
			employees
		);
		const booking: Partial<IBooking> = {
			date: TimeHelper.toTimeZone(moment.utc(startDateTime).toDate()).format(
				'YYYY-MM-DDT00:00:00'
			),
			inputFrom: update ? currentBooking?.inputFrom : EBookingInputFrom.POS,
			startTimeExpected: startDateTime.toDate(),
			endTimeExpected: endDateTime.toDate(),
			stylistId:
				_.isEmpty(bookingDetails[0].stylistId) ||
				bookingDetails[0].stylistId === 'Anyone'
					? undefined
					: bookingDetails[0].stylistId,
			note: note || currentBooking?.note,
			branchId: currentBranch?.id,
			shopId: currentBranch?.shopId,
			bookingDetails: convertedBookingDetails,
			totalQuantity: convertedBookingDetails.length,
			bookingType: update ? currentBooking?.bookingType : bookingType,
			customerId: customer?.id,
			status: quickSale ? BookingStatus.FINISHED : currentBooking?.status,
		};
		if (!update) {
			createBooking(booking, (bookingId, newBooking) => {
				if (quickSale) {
					onCheckOut(newBooking);
				} else {
					history.replace(`/booking/${bookingId}`);
				}
			});
		} else {
			updateBooking(booking, () => {
				showSuccess('Update Booking Successfully');
			});
		}
	};
	const onCheckOut = async (booking?: IBooking) => {
		const billResult = await BillingApiService.getBillByBookingId(
			`${booking ? booking.id : currentBooking?.id}`
		);
		const newBill: Partial<IBilling> = createBillFromBooking(
			booking || currentBooking!
		);
		if (billResult.succeeded && billResult.data) {
			const bill = billResult.data[0];
			if (bill) {
				const response = await BillingApiService.updateBill({
					...newBill,
					discount: bill.discounterType === null ? undefined : bill.discount,
					discounterType:
						bill.discounterType === null ? undefined : bill.discounterType,
					id: bill.id,
					billDetails: (newBill.billDetails || [])
						.concat(
							bill.billDetails.filter(
								(x) =>
									(!!x.giftCardId || x.bookingId !== currentBooking?.id) &&
									!newBill.billDetails
										?.map((b) => b.bookingDetailId)
										.includes(x.bookingDetailId)
							) || []
						)
						.map((x) => {
							const billDetail = bill.billDetails.find(
								(b) => b.bookingDetailId === x.bookingDetailId
							);
							let newBillDetail = {
								...x,
								id: x.id || billDetail?.id,
							};
							if (!!billDetail) {
								newBillDetail = {
									...newBillDetail,
									discount: billDetail?.discount || 0,
									discounterType: billDetail?.discounterType,
									extraAmount: billDetail.extraAmount,
									note: billDetail.note,
								};
							}
							return newBillDetail;
						}),
				});
				if (response.succeeded && response.data) {
					history.replace(`/check-out/${bill.parentBillId || bill.id}`);
				}
				return;
			}
		}

		const response = await BillingApiService.createBill(newBill);
		if (response.succeeded && response.data) {
			history.replace(`/check-out/${response.data.id}`, {
				bill: response.data,
			});
		} else {
			showError(response.errors);
		}
	};

	const onConfirm = (type: ConfirmType) => {
		switch (type) {
			case 'save':
				onSaveBooking();
				break;
			case 'update':
				onSaveBooking(true);
				break;
			case 'working':
				onWorking();
				break;
			case 'checkIn':
				if (
					moment
						.utc(currentBooking?.startTimeExpected)
						.diff(moment(), 'minute') > 30
				) {
					showAlert(
						I18n.t(_t(translations.bookingCustomerInfo.alertEarlyCheckInTitle)),
						'error'
					);
				} else {
					onCheckIn();
				}
				break;
			case 'checkOut':
				onCheckOut();
				break;
			default:
				break;
		}
	};
	const checkLoading = () => {
		return loading || bookingAction === BookingActions.updateStatus.requestName;
	};
	const showChooseCustomer = () => {
		if (quickSale) {
			return customer?.id !== WALKIN_CUSTOMER.id;
		} else {
			return !!customer;
		}
	};

	return (
		<LoadingOverlay
			className="rounded-lg"
			active={checkLoading()}
			spinner
			text="Loading"
		>
			<div className="col-span-1  flex flex-col overflow-hidden h-full w-full pb-12">
				{showChooseCustomer() ? (
					<CustomerInfo />
				) : (
					<div className="mb-2 mx-4 grid grid-cols-5 gap-1">
						<Button
							onClick={onShowCustomerModal}
							small
							primary
							className="text-white col-span-5"
							title={t(translations.bookingCustomerInfo.chooseCustomer)}
						/>
					</div>
				)}
				{showChooseCustomer() && (
					<Actions editCustomer={() => setEditCustomer(true)} />
				)}
				{!quickSale && (
					<BookingInformation
						onChangeBookingType={(type) => setBookingType(type)}
						currentType={bookingType}
					/>
				)}
				<div
					id="booking-details"
					className="p-4 pb-6 bg-white rounded-l-lg flex-1 flex flex-col overflow-y-auto"
				>
					<ListBookingDetail />
				</div>
				<Footer onConfirm={onConfirm} />
				{(showCustomerModal || editCustomer) && (
					<CustomerModal
						onSelectCustomer={selectCustomer}
						hideWalkIn
						customer={customer as ICustomer}
						edit={editCustomer}
						onCancel={() => {
							setShowCustomerModal(false);
							setEditCustomer(false);
						}}
					/>
				)}
			</div>
		</LoadingOverlay>
	);
};
