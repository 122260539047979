import { CurrencyHelper } from 'helpers';
import { Divider } from 'pages/FinancialReport/components/Divider';
import { ReportFieldValue } from 'pages/FinancialReport/components/ReportFieldValue';
import React from 'react';
import { t, translations } from 'utils';
import { usePayrollDetailContext } from './payrollDetailContext';

export const FeeAndPayment = () => {
	const { report } = usePayrollDetailContext();
	return (
		<div className="py-4">
			<ReportFieldValue
				title={t(translations.payrollDetailReport.supplyFee)}
				value={CurrencyHelper.formatBalance(report![0].totalSupplyFee)}
				bold
				big
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.paymentTypes)}
				bold
				big
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalCash)}
				value={CurrencyHelper.formatBalance(report![0].billPaymentTypes?.cash)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalCreditCard)}
				value={CurrencyHelper.formatBalance(report![0].billPaymentTypes?.card)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalByApp)}
				value={CurrencyHelper.formatBalance(
					report![0].billPaymentTypes?.payByApps
				)}
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalGiftcard)}
				value={CurrencyHelper.formatBalance(
					report![0].billPaymentTypes?.giftCard
				)}
			/>
			<Divider />
			<ReportFieldValue
				title={t(translations.payrollDetailReport.total)}
				value={CurrencyHelper.formatBalance(report![0].billPaymentTypes?.total)}
				bold
				big
			/>
			<ReportFieldValue
				title={t(translations.payrollDetailReport.totalTips)}
				value={CurrencyHelper.formatBalance(report![0].totalTip)}
				bold
				big
			/>
		</div>
	);
};
