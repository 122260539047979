import React from 'react';
import { translations, _t, I18n } from 'utils';
import QRCode from 'react-qr-code';
import { IBooking } from 'models/IBooking';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { BookingHelper, TimeHelper } from 'helpers';
import _ from 'lodash';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { mdiCheckCircle } from '@mdi/js';
import { ICalLink } from 'helpers/ICS';

interface IBookingSuccess {
	booking: IBooking;
}
const b64toBlob = (dataURI: any) => {
	var byteString = atob(dataURI.split(',')[1]);
	var ab = new ArrayBuffer(byteString.length);
	var ia = new Uint8Array(ab);

	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: 'image/jpeg' });
};
const BookingInfo = (props: IBookingSuccess) => {
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);

	return (
		<div className="m-4">
			<div className="flex justify-between">
				<strong style={{ color: '#9F9F9F' }}>
					{I18n.t(_t(translations.bookingPage.shop))}
				</strong>
				<div>
					<span>{shop?.name}</span>
				</div>
			</div>
			<div className="flex justify-between">
				<strong style={{ color: '#9F9F9F' }}>
					{I18n.t(_t(translations.bookingPage.startTimeAt))}
				</strong>
				<div>
					<span>
						{TimeHelper.toTimeZone(props.booking.startTimeExpected).format(
							'hh:mmA'
						)}
					</span>
				</div>
			</div>{' '}
			<div className="flex justify-between">
				<strong style={{ color: '#9F9F9F' }}>
					{I18n.t(_t(translations.address))}
				</strong>
				<div>
					<span>{`${branch?.address?.street}, ${branch?.address?.city}, ${branch?.address?.state}`}</span>
				</div>
			</div>
		</div>
	);
};

export const BookingSuccess = (props: IBookingSuccess) => {
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const onSaveQRCode = () => {
		const imageSrc = `data:image/jpeg;base64,${BookingHelper.generateQrCode(
			props.booking.id
		)}`;
		const blob = b64toBlob(imageSrc);
		const url = URL.createObjectURL(blob);
		window.open(url);
		// DownloadHelper.downloadFile(
		//   imageSrc,
		//   `booking_${moment(currentBooking?.startTimeExpected).format("MM/DD/YYYY-HHmm")}.png`
		// );
	};
	const onBookOther = () => {
		if (window.self !== window.top) {
			if (_.isEmpty(shop?.portalUrl)) {
				window.parent.location.href = `https://${shop?.adminUrl}.website.smartsalon.live/booking`;
			} else {
				window.parent.location.href = `https://${shop?.portalUrl}/booking`;
			}
		} else {
			window.location.href = `https://${shop?.adminUrl}.v2.smartsalon.live/customer-booking`;
		}
	};
	const QRCodeButton = () => {
		return (
			<Button onClick={() => onSaveQRCode()} key="buy">
				{I18n.t(_t(translations.bookingPage.saveQRCode))}
			</Button>
		);
	};
	const getServicesName = (booking: IBooking) => {
		let name = booking.bookingDetails.map((detail) => {
			return detail.item?.name;
		});
		return name.join(', ');
	};
	const event = {
		title: branch?.name || '',
		description: getServicesName(props.booking),
		startTime: TimeHelper.toTimeZone(
			props.booking.startTimeExpected
		).toString(),
		endTime: TimeHelper.toTimeZone(props.booking.endTimeExpected).toString(),
		location: `${branch?.address?.street}, ${branch?.address?.city}, ${branch?.address?.state}`,
	};

	return (
		<div className="flex align-middle justify-center">
			<div>
				<div className="px-8 py-12 text-center">
					<div className="w-full flex justify-center">
						<Icon path={mdiCheckCircle} size={3} color="#52c41a" />
					</div>
					<div className="font-semibold text-2xl">
						<strong>{I18n.t(_t(translations.bookingPage.successTitle))}</strong>
					</div>
					<div className="mt-2">
						<strong>
							{I18n.t(_t(translations.bookingPage.successSubTitle))}
						</strong>
					</div>
					<Button
						onClick={() => onBookOther()}
						key="console"
						small
						className="btn-outline btn-primary mt-6 "
					>
						{I18n.t(_t(translations.bookingPage.bookOther))}
					</Button>
					<ICalLink
						event={event}
						className="btn customer-booking-background border-0 mt-6 ml-8 no-underline"
					>
						Add to Calendar
					</ICalLink>
				</div>
				<BookingInfo booking={props.booking} />
				<div className="flex justify-center mb-4">
					<QRCode
						value={BookingHelper.generateQrCode(props.booking.id)}
						size={200}
					/>
				</div>
			</div>
		</div>
	);
};
