import clsxm from 'clsxs/clsxm';
import { Modal } from 'components/atoms';
import { StaffCard } from 'components/molecules/StaffCard';
import { useAppSelector } from 'helpers/hookHelpers';
import { IEmployee } from 'models';
import React from 'react';
import { t, translations } from 'utils';

interface IStaffPickerModal {
	visible?: boolean;
	selectedStaff?: Partial<IEmployee>;
	serviceId?: string;
	onSelectStaff?: (staff: IEmployee) => void;
	onCancel?: () => void;
}
export const SelectStaffModal = ({
	selectedStaff,
	visible,
	serviceId,
	onSelectStaff,
	onCancel,
}: IStaffPickerModal) => {
	const { employees } = useAppSelector((state) => state.EmployeeReducer);
	return (
		<Modal
			isOpen={visible || false}
			title={t(translations.mainBooking.selectStylist)}
			content={
				<div className="h-full w-full">
					<div className={clsxm('grid grid-cols-3 gap-4 p-4')}>
						{employees
							?.filter((e) => e.serviceIds?.includes(serviceId || ''))
							.map((e) => (
								<StaffCard
									className={clsxm('h-36')}
									staff={e}
									onSelect={() => {
										onSelectStaff && onSelectStaff(e);
									}}
									isSelected={selectedStaff?.id === e.id}
								/>
							))}
					</div>
				</div>
			}
			onCancel={onCancel}
		/>
	);
};
