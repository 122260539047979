import { mdiPencilOutline } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Card, Icon, Modal } from 'components/atoms';
import Table, { EColumnType, IColumnProps } from 'components/atoms/Table';
import { Header } from 'components/molecules/Header';
import { LoadingProvider } from 'contexts/LoadingContext';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import GalleryApiService from 'services/GalleryApiService';
import { translations, t } from 'utils';
import { FormContentAboutUs } from './components/FormContentAboutUs';
import { ThemeType } from 'utils/Consts';
import { FormContentWelcomeText } from './components/FormContentWelcomeText';
import { FormContentWhyChoose } from './components/FormContentWhyChoose';
import { AlertHelper } from 'helpers';
import { isEmpty } from 'lodash';
import { IApiResponse, IErrorResponse, IItemCategory, IService } from 'models';
import { CategoryApiService } from 'services';
import ServiceApi from 'services/ServiceApi';
import { useHistory } from 'react-router-dom';
import { ContentSlider } from './components/ContentSlider';
import UploadBaseService from 'services/UploadBaseService';
import { IFileUploadResponse } from 'models/ResponseModels';
export interface IThemeContent {
	id: string;
	title?: string;
	subTitle?: string;
	type: string;
	priority: number;
	description: string;
	status: number;
	url?: string;
}
export interface IImageSlider {
	id?: string;
	imageUrl: string;
	file?: File;
}
export const MiniCMSSetting = () => {
	const [loading, setLoading] = useState(false);
	const [dataThemes, setDataThemes] = useState<any[]>([]);
	const [showModal, setShowModal] = useState({
		slider: false,
		aboutUs: false,
		welcomeText: false,
		whyChoose: false,
	});
	const [selectedContent, setSelectedContent] = useState<IThemeContent>();
	const [imageSlider, setImageSlider] = useState<IImageSlider[]>([]);
	const [categories, setCategories] = useState<IItemCategory[]>([]);
	const [services, setServices] = useState<IService[]>([]);
	const history = useHistory();

	const form = useForm<IThemeContent>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	const getThemeShop = async () => {
		setLoading(true);
		try {
			const res = await GalleryApiService.getThemeShop();
			if (res.succeeded) {
				setDataThemes(
					res.data.sort(
						(n1: { priority: number }, n2: { priority: number }) =>
							n1.priority - n2.priority
					)
				);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const getAllCategories = async () => {
		try {
			const res = (await CategoryApiService.getAllCategory()) as IApiResponse<
				IItemCategory[]
			>;
			if (res.succeeded) {
				setCategories(res.data || []);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getAllServices = async () => {
		try {
			const res = (await ServiceApi.getAllService()) as IApiResponse<
				IService[]
			>;
			if (res.succeeded) {
				setServices(res.data || []);
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getAllCategories();
		getAllServices();
	}, []);

	const handleOpenByType = (type: string, record: IThemeContent) => {
		switch (type) {
			case ThemeType.SLIDER:
				const dataImage = (
					record.url ? JSON.parse(record.url || '') : []
				) as IImageSlider[];
				setImageSlider(dataImage);
				setSelectedContent(record);
				setShowModal({
					slider: true,
					aboutUs: false,
					welcomeText: false,
					whyChoose: false,
				});
				break;
			case ThemeType.ABOUT_US:
				form.setValue('title', record.title);
				form.setValue('subTitle', record.subTitle);
				form.setValue('description', record.description);
				setSelectedContent(record);
				setShowModal({
					slider: false,
					aboutUs: true,
					welcomeText: false,
					whyChoose: false,
				});
				break;
			case ThemeType.WELCOME_TEXT:
				form.setValue('title', record.title);
				form.setValue('subTitle', record.subTitle);
				form.setValue('description', record.description);
				setSelectedContent(record);
				setShowModal({
					slider: false,
					aboutUs: false,
					welcomeText: true,
					whyChoose: false,
				});
				break;
			case ThemeType.WHY_CHOOSE:
				form.setValue('title', record.title);
				form.setValue('subTitle', record.subTitle);
				form.setValue('description', record.description);
				setSelectedContent(record);
				setShowModal({
					slider: false,
					aboutUs: false,
					welcomeText: false,
					whyChoose: true,
				});
				break;
			case ThemeType.GALLERY:
				history.push('/setting/gallery-setup');
				break;
			case ThemeType.SERVICES:
				history.push('/settings/mini-cms/featured-services');
				break;
			default:
				break;
		}
	};

	const handleSliderUploadRemove = async () => {
		let url = null;
		let error = null;
		if (selectedContent?.type === ThemeType.SLIDER) {
			const dataImageNeedUpload = imageSlider.filter((x) => !x.id);
			const dataPost = imageSlider.filter((x) => x.id);

			if (dataImageNeedUpload.length > 0) {
				const files = dataImageNeedUpload.map((x) => x.file);
				try {
					const res = (await UploadBaseService.uploadImage(
						files
					)) as IApiResponse<IFileUploadResponse[]>;
					if (res.succeeded) {
						const { data } = res;
						if (!isEmpty(data)) {
							data!.map((x) => {
								dataPost.push({
									id: x.id,
									imageUrl: x.imageUrl,
								});
							});
						}
					} else {
						error = res as IErrorResponse;
					}
				} catch (err) {
					console.log(err);
				}
			}
			const jsonStringUrl = dataPost.map((x) => ({
				id: x.id,
				imageUrl: x.imageUrl,
			}));
			url = jsonStringUrl.length > 0 ? JSON.stringify(jsonStringUrl) : null;
		}
		return { url, error };
	};

	const onSubmit = async (formData: IThemeContent) => {
		setLoading(true);
		const { url, error } = await handleSliderUploadRemove();
		if (error) {
			AlertHelper.showError(error);
			return;
		}
		const params = {
			...selectedContent,
			title: formData.title || null,
			subTitle: formData.subTitle || null,
			description: formData.description || null,
			url: url || null,
		};
		try {
			const res = (await GalleryApiService.updateThemeShopContent(
				params
			)) as IApiResponse<IThemeContent[]>;
			if (res.succeeded) {
				const { data } = res;
				const dataType = !isEmpty(data) ? data![0].type : '';
				AlertHelper.showSuccess(
					`${
						dataType + ' ' + t(translations.miniCMSSetting.updateThemeSuccess)
					}`
				);
				getThemeShop();
				setShowModal({
					slider: false,
					aboutUs: false,
					welcomeText: false,
					whyChoose: false,
				});
			} else {
				const error = res as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const columns = useMemo<IColumnProps<IThemeContent>[]>(() => {
		return [
			{
				header: t(translations.miniCMSSetting.columnType),
				key: 'type',
			},
			{
				header: t(translations.action),
				key: 'id',
				headerClassName: 'w-28',
				cellClassName: 'w-28',
				renderCell: (data) => {
					return (
						<div className="flex gap-4 items-center">
							<div
								className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
								onClick={() => handleOpenByType(data.type, data)}
							>
								<Icon path={mdiPencilOutline} className="w-6 h-6" />
							</div>
						</div>
					);
				},
			},
		];
	}, []);

	useEffect(() => {
		getThemeShop();
	}, []);

	return (
		<LoadingProvider loading={loading} className="h-full">
			<div className={clsxm('w-full flex flex-col h-full overflow-y-auto')}>
				<div className={clsxm('py-16 pt-8 my-16 w-full px-16 grid gap-y-8')}>
					<Header title={t(translations.setting.miniCMSSetting)} showClose />
					<FormProvider {...form}>
						<Card
							title={t(translations.miniCMSSetting.theme)}
							description={t(translations.miniCMSSetting.subTheme)}
						>
							<Table
								columns={columns}
								data={dataThemes}
								className="max-h-[100vh]"
								noPaging
							/>
							<Modal
								isOpen={showModal.slider}
								title={t(translations.miniCMSSetting.headerSlider)}
								onCancel={() => {
									setShowModal({
										slider: false,
										aboutUs: false,
										welcomeText: false,
										whyChoose: false,
									});
								}}
								content={
									<ContentSlider
										images={imageSlider}
										setImages={(images) => setImageSlider(images)}
									/>
								}
								canConfirm
								onConfirm={form.handleSubmit(onSubmit)}
							/>
							<Modal
								isOpen={showModal.aboutUs}
								title={t(translations.miniCMSSetting.headerAboutUs)}
								onCancel={() => {
									form.resetField('title');
									form.resetField('subTitle');
									form.resetField('description');
									setShowModal({
										slider: false,
										aboutUs: false,
										welcomeText: false,
										whyChoose: false,
									});
								}}
								content={<FormContentAboutUs />}
								canConfirm
								onConfirm={form.handleSubmit(onSubmit)}
							/>
							<Modal
								isOpen={showModal.welcomeText}
								title={t(translations.miniCMSSetting.headerWelcomeText)}
								onCancel={() => {
									form.resetField('title');
									form.resetField('subTitle');
									setShowModal({
										slider: false,
										aboutUs: false,
										welcomeText: false,
										whyChoose: false,
									});
								}}
								content={<FormContentWelcomeText />}
								canConfirm
								onConfirm={form.handleSubmit(onSubmit)}
							/>
							<Modal
								isOpen={showModal.whyChoose}
								title={t(translations.miniCMSSetting.headerWhyChoose)}
								onCancel={() => {
									form.resetField('title');
									form.resetField('subTitle');
									form.resetField('description');
									setShowModal({
										slider: false,
										aboutUs: false,
										welcomeText: false,
										whyChoose: false,
									});
								}}
								content={<FormContentWhyChoose />}
								canConfirm
								onConfirm={form.handleSubmit(onSubmit)}
							/>
						</Card>
					</FormProvider>
				</div>
			</div>
		</LoadingProvider>
	);
};
