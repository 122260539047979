import {
	Button,
	Container,
	IColumnProps,
	Icon,
	Select,
} from 'components/atoms';
import Table, { EColumnType } from 'components/atoms/Table';
import { LoadingProvider } from 'contexts/LoadingContext';
import { IExpense, IExpenseType } from 'models';
import { translations, t } from 'utils';
import React, { useMemo, useState } from 'react';
import { mdiPlus } from '@mdi/js';
import { CustomInput } from 'pages/SettingStack/ShopSetting';
import clsxm from 'clsxs/clsxm';
import {
	ALL,
	ExpenseTypePickerModal,
} from './components/ExpenseTypePickerModal';
import { TDateRange } from 'components/molecules/ModalCalendar';
import moment from 'moment';
import { DateRangeType, DATE_RANGE_TYPES } from 'utils/Consts';
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from 'components/molecules/DateRangePicker';

export const Expenses = () => {
	const [loading, setLoading] = useState(false);
	const [selectedExpenseType, setSelectedExpenseType] =
		useState<IExpenseType>(ALL);
	const [selectedDateRangeType, setSelectedDateRangeType] = useState<
		typeof DATE_RANGE_TYPES[0]
	>(DATE_RANGE_TYPES[0]);
	const [isShowTypePicker, setIsShowTypePicker] = useState(false);
	const [dateFilter, setDateFilter] = useState<TDateRange>({
		fromDate: moment().startOf('date'),
		toDate: moment().startOf('date'),
	});
	const history = useHistory();

	const columns = useMemo<IColumnProps<IExpense>[]>(() => {
		return [
			{
				header: t(translations.expenses.paymentDate),
				key: 'date',
				type: EColumnType.DATE,
			},
			{
				header: t(translations.expenses.expenseType),
				key: 'description',
			},
			{
				header: t(translations.expenses.paymentAmount),
				key: 'amount',
				type: EColumnType.MONEY,
			},
			{
				header: t(translations.expenses.paymentResponsibility),
				key: 'id',
				renderCell: (record) => {
					return (
						(record.employee?.firstName || '') +
						' ' +
						(record.employee?.lastName || '')
					);
				},
			},
			{
				header: t(translations.expenses.note),
				key: 'note',
			},
		];
	}, []);

	const handleChangeRangeType = (type: DateRangeType) => {
		switch (type) {
			case DateRangeType.TODAY:
				setDateFilter({ fromDate: moment(), toDate: moment() });
				break;
			case DateRangeType.YESTERDAY:
				setDateFilter({
					fromDate: moment().add(-1, 'day'),
					toDate: moment().add(-1, 'day'),
				});
				break;
			case DateRangeType.WEEKLY:
				setDateFilter({
					fromDate: moment().startOf('week'),
					toDate: moment().endOf('week'),
				});
				break;
			case DateRangeType.SEMI_MONTH_1st:
				setDateFilter({
					fromDate: moment().startOf('month'),
					toDate: moment().startOf('month').add(14, 'day'),
				});
				break;
			case DateRangeType.SEMI_MONTH_2nd:
				setDateFilter({
					fromDate: moment().startOf('month').add(15, 'day'),
					toDate: moment().endOf('month'),
				});
				break;
			case DateRangeType.MONTHLY:
				setDateFilter({
					fromDate: moment().startOf('month'),
					toDate: moment().endOf('month'),
				});
				break;
			default:
				break;
		}
	};

	return (
		<LoadingProvider loading={loading} className="h-full">
			<Table<IExpense>
				columns={columns}
				queryUrl="/Expense/get-all-expenses"
				additionalParams={{
					fromDate: dateFilter.fromDate!.format('YYYY-MM-DD'),
					toDate: dateFilter.toDate!.format('YYYY-MM-DD'),
					expenseTypeId: selectedExpenseType.id,
					orderBy: 'date desc',
				}}
				onClickRow={(data) => history.push(`/report/expenses/edit/${data.id}`)}
				renderHeader={
					<div className="flex w-full justify-between pb-0">
						<div className="basis-1/2 flex gap-x-4">
							<div className="basis-1/3">
								<CustomInput
									className={clsxm(
										'caret-transparent cursor-pointer font-bold uppercase border-primary h-[48px]'
									)}
									onClick={() => setIsShowTypePicker(true)}
									value={selectedExpenseType.description}
								/>
							</div>
							<div className="basis-1/3">
								<Select<typeof DATE_RANGE_TYPES[0]>
									options={DATE_RANGE_TYPES}
									keyField="id"
									titleField="title"
									fullWidth
									onChange={(type) => {
										handleChangeRangeType(type.value);
										setSelectedDateRangeType(type);
									}}
									value={selectedDateRangeType}
								/>
							</div>
							{(selectedDateRangeType.value === DateRangeType.TODAY ||
								selectedDateRangeType.value === DateRangeType.YESTERDAY) && (
								<div className="flex items-center text-xl font-semibold">
									{dateFilter.fromDate?.format('MMM Do, YYYY')}
								</div>
							)}
							{selectedDateRangeType.value !== DateRangeType.DATE_RANGE &&
								selectedDateRangeType.value !== DateRangeType.TODAY &&
								selectedDateRangeType.value !== DateRangeType.YESTERDAY && (
									<div className="flex items-center text-xl font-semibold">
										{dateFilter.fromDate?.format('MMM Do, YYYY')} -{' '}
										{dateFilter.toDate?.format('MMM Do, YYYY')}
									</div>
								)}
							{selectedDateRangeType.value === DateRangeType.DATE_RANGE && (
								<div className="basis-1/3">
									<DateRangePicker
										onSelectDateRange={setDateFilter}
										format={'MMM Do, YYYY'}
										selectedDateRange={dateFilter}
									/>
								</div>
							)}
						</div>
						<Button
							small
							primary
							title={t(translations.addNew)}
							iconBefore={<Icon path={mdiPlus} />}
							onClick={() => {
								history.push('/report/expenses/add-new');
							}}
						/>
					</div>
				}
			/>
			<ExpenseTypePickerModal
				isShowModal={isShowTypePicker}
				onCancel={() => setIsShowTypePicker(false)}
				onChooseType={(type) => {
					setSelectedExpenseType(type);
					setIsShowTypePicker(false);
				}}
				selectedType={selectedExpenseType}
				showAll
			/>
		</LoadingProvider>
	);
};
