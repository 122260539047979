import {
	mdiAlertCircleCheckOutline,
	mdiArrowLeft,
	mdiCloud,
	mdiCloudAlert,
	mdiPrinterSettings,
	mdiSourceBranch,
	mdiStoreCog,
	mdiTuneVertical,
	mdiWebBox,
} from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Icon } from 'components/atoms';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrintModal } from './components/PrintModal';

const CardSetting = (props: {
	title: string;
	icon: string;
	onClick: () => void;
}) => {
	return (
		<div
			onClick={() => props.onClick()}
			className={clsxm(
				' flex flex-col bg-white uppercase cursor-pointer active:animate-pop duration-200 ease-in text-primary border border-primary shadow-md items-center rounded-lg gap-2 justify-center p-4'
			)}
		>
			<Icon path={props.icon} />
			<strong className={'text-primary'}>{props.title}</strong>
		</div>
	);
};

export const Settings = () => {
	const history = useHistory();
	const [showPrintModal, setShowPrintModal] = useState(false);
	return (
		<div className="grid  grid-cols-6 gap-2 p-4  pb-16">
			<div
				onClick={() => {
					history.goBack();
				}}
				className={clsxm(
					' flex flex-col bg-white uppercase cursor-pointer active:animate-pop duration-200 ease-in text-primary shadow-md items-center rounded-lg gap-2 justify-center p-4'
				)}
			>
				<Icon path={mdiArrowLeft} />
				<strong className={'text-primary'}>Go Back</strong>
			</div>
			{/* <CardSetting
				title="Shop Setting"
				icon={mdiTuneVertical}
				onClick={() => history.push('/settings/branch')}
			/> */}
			<CardSetting
				title="Salons Management"
				icon={mdiSourceBranch}
				onClick={() => history.push('/settings/branches-management')}
			/>
			<CardSetting
				title="Company Settings"
				icon={mdiStoreCog}
				onClick={() => history.push('/settings/shop')}
			/>
			<CardSetting
				title="Website Settings"
				icon={mdiWebBox}
				onClick={() => history.push('/settings/mini-cms')}
			/>
			<CardSetting
				title="Print Settings"
				icon={mdiPrinterSettings}
				onClick={() => setShowPrintModal(true)}
			/>
			<CardSetting
				title="Close Online Appointments"
				icon={mdiCloudAlert}
				onClick={() => history.push('/settings/closed-online-appointments')}
			/>

			{showPrintModal && (
				<PrintModal onCancel={() => setShowPrintModal(false)} />
			)}
		</div>
	);
};
