import clsxm from 'clsxs/clsxm';
import React from 'react';

interface IDescriptionProps {
	title: string;
	value: string;
	subValue?: string;
	titleClassName?: string;
	valueClassName?: string;
	subValueClassName?: string;
	className?: string;
	isHtml?: boolean;
}
export const Description = (props: IDescriptionProps) => {
	return (
		<div
			className={clsxm(
				'stat p-0 w-full flex align-bottom items-baseline justify-between',
				props.className
			)}
		>
			<p
				className={clsxm(
					'text-[#000] m-0 p-0 font-light',
					props.titleClassName
				)}
			>
				{props.title}:
			</p>
			{props.subValue && (
				<div className={clsxm('font-medium', props.subValueClassName)}>
					{props.subValue}
				</div>
			)}
			{props.isHtml ? (
				<div dangerouslySetInnerHTML={{ __html: props.value || '' }} />
			) : (
				<div className={clsxm('font-bold ', props.valueClassName)}>
					{props.value}
				</div>
			)}
		</div>
	);
};
