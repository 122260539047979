import { mdiClose, mdiCheck } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Description, Icon, Input, Modal } from 'components/atoms';
import { LoadingProvider } from 'contexts/LoadingContext';
import { formatBalance } from 'helpers/currencyHelper';
import _ from 'lodash';
import { IApiResponse } from 'models';
import { IGetPromotionByCouponCode, IPromotion } from 'models/IPromotion';
import moment from 'moment';
import { useCheckOutContext } from 'pages/CheckOut/context';
import { useState } from 'react';
import PromotionApiService from 'services/PromotionApiService';
import { t, translations } from 'utils';

export const ApplyCouponModal = (props: {
	onCancel: () => void;
	onOk: (promotion: IPromotion) => void;
}) => {
	const { bill, billDetails = [], childrenBills = [] } = useCheckOutContext();

	const [loading, setLoading] = useState(false);
	const [promotion, setPromotion] = useState<IPromotion>();
	const [couponCode, setCouponCode] = useState<string>('');
	const [error, setError] = useState<string>();

	const childBillDetails = _.flatMapDeep(
		childrenBills.map((x) => x.billDetails!)
	);
	const allBillDetails = billDetails?.concat(childBillDetails);

	const getPromotionByCouponCode = async () => {
		setLoading(true);
		setError(undefined);
		setPromotion(undefined);

		const request: IGetPromotionByCouponCode = {
			couponCode,
			date: moment.utc(moment()).format('MM-DD-YYYY HH:mm'),
			customerId: bill?.customerId || '',
			serviceIds: allBillDetails.map((x) => x.item?.id!),
			branchId: bill?.branchId!,
		};
		const res = await PromotionApiService.getPromotionByCouponCode(request);
		setLoading(false);
		if (!_.isEmpty(res.errors)) {
			setError(res.message[0]?.Text);
			return;
		}

		const resData = res as IApiResponse<IPromotion>;
		if (!_.isEmpty(resData.message)) {
			setError(resData.message[0]?.text);
			return;
		}
		if (resData.succeeded && !_.isEmpty(resData.data)) {
			setPromotion(resData?.data!);
		}
	};

	const endDate = (promotion: IPromotion) => {
		return !_.isEmpty(promotion.endDateUtc)
			? `- ${moment(promotion.endDateUtc).format('MM/DD/YYYY')}`
			: `- ${t(translations.checkout.promotionInfinity)}`;
	};
	const applyPromotion = async (promotion: IPromotion) => {
		props.onOk(promotion);
	};
	return (
		<Modal
			isOpen={true}
			onCancel={() => props.onCancel && props.onCancel()}
			title={t(translations.checkout.inputCoupon)}
			className={'w-[600px]'}
			content={
				<div className="p-4 overflow-y-auto w-full h-full overflow-hidden ">
					<LoadingProvider loading={loading}>
						<div className="flex gap-2 items-end">
							<Input
								id="input-coupon"
								label={t(translations.checkout.couponCode)}
								value={couponCode}
								onChange={(e) => setCouponCode(e.target.value)}
								placeholder={t(translations.checkout.scanQrOrSwipe)}
								autoFocus
								renderAfter={
									<div className="flex">
										<Button
											id="clear"
											onClick={() => {
												setCouponCode('');
												setError('');
											}}
											title={t(translations.clear)}
											className="text-error h-[45px] min-h-[45px]"
											ghost
											small
											iconBefore={<Icon path={mdiClose} />}
										/>
									</div>
								}
							/>
							<Button
								id="check"
								onClick={getPromotionByCouponCode}
								title={t(translations.checkout.checkCoupon)}
								primary={!_.isEmpty(couponCode)}
								disabled={_.isEmpty(couponCode)}
								small
								className=" h-[45px] min-h-[45px]"
								iconBefore={<Icon path={mdiCheck} />}
							/>
						</div>

						{!_.isEmpty(error) && (
							<div className="text-ERROR mt-2">{error}</div>
						)}

						{!_.isEmpty(promotion) && (
							<ul className="bg-white mt-4 rounded-lg text-gray-900 list-none pl-0 ">
								<li
									onClick={() => {
										applyPromotion(promotion!);
									}}
									className={clsxm(
										'px-4 py-3 m-0 my-4 relative active:animate-pop',
										'duration-500 ease-in cursor-pointer border rounded-lg flex flex-col justify-between w-full'
									)}
								>
									<Description
										title={t(translations.promotion.promotionName)}
										value={promotion!.name}
										valueClassName="text-PROCESSING"
									/>
									<Description
										title={t(translations.promotion.promotionType)}
										value={`${promotion?.promotionTypeCode}`}
									/>
									<Description
										title={t(translations.promotionDetail.discountAmount)}
										value={
											!promotion?.usePercentage
												? formatBalance(promotion?.discountAmount)
												: `${promotion?.discountPercentage}%`
										}
									/>
									<Description
										title={t(translations.checkout.promotionTime)}
										value={`${moment(promotion?.startDateUtc).format(
											'MM/DD/YYYY'
										)} ${endDate(promotion!)}`}
									/>
									<Description
										title={t(translations.description)}
										value={promotion?.description || ''}
										isHtml
									/>
								</li>
							</ul>
						)}
					</LoadingProvider>
				</div>
			}
		/>
	);
};
