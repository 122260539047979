/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	ButtonGroup,
	Card,
	Icon,
	Input,
	Modal,
	Switch,
} from 'components/atoms';
import { useAppSelector } from 'helpers/hookHelpers';
import React, { useCallback, useState } from 'react';
import { Const, translations, _t } from 'utils';
import { electron } from 'ipc';
import { mdiCog } from '@mdi/js';
import I18n from 'i18n-js';
import { useDispatch } from 'react-redux';
import { SettingActions } from 'redux/actions';
import { IPrinter } from 'models/IBranch';
import { PRINTER_CONNECTION } from 'utils/Consts';
import BranchApiService from 'services/BranchApiService';

const connection_options = [
	{ label: 'Network Connection', value: Const.PRINTER_CONNECTION.NETWORK },
	{ label: 'USB Connection', value: Const.PRINTER_CONNECTION.USB },
];
const receipt_options = [
	{ label: '1 Receipt(Salon)', value: Const.RECEIPT_PRINT_TYPE.ONE },
	{
		label: '1 Receipt(Customer)',
		value: Const.RECEIPT_PRINT_TYPE.ONE_CUSTOMER,
	},
	{
		label: '2 Receipts(Salon - Customer)',
		value: Const.RECEIPT_PRINT_TYPE.TWO,
	},
	{ label: 'No Receipt', value: Const.RECEIPT_PRINT_TYPE.NONE },
];
export const PrintModal = (props: { onCancel: () => void }) => {
	const settings = useAppSelector((state) => state.SettingReducer);
	const branchId = useAppSelector(
		(state) => state.BranchReducer.currentBranch?.id
	);
	const [connectionType, setConnectionType] = useState(
		settings.printerConnectionType
	);
	const [receiptType, setReceiptType] = useState(settings.receiptPrintType);
	const [printerIp, setPrinterIp] = useState(settings.printerIp || '');
	const [printerPort, setPrinterPort] = useState(settings.printerPort);
	const [printTicket, setPrintTicket] = useState(
		settings.isEnableTicketPrinting
	);
	const dispatch = useDispatch();
	const onConfirm = useCallback(async () => {
		console.log('@ASDSDDSA', connectionType);
		const printerSetting: IPrinter = {
			branchId,
			printerIp,
			printerConnectionType: connectionType!,
			receiptPrintType: receiptType,
			isEnableTicketPrinting: printTicket,
		};
		const response = await BranchApiService.setPrinter(printerSetting);
		dispatch(SettingActions.savePrinterSetting.request(printerSetting));
		if (response.data) {
			props.onCancel();
		}
	}, [
		connectionType,
		receiptType,
		printerIp,
		printerPort,
		printTicket,
		branchId,
	]);
	return (
		<Modal
			isOpen={true}
			className="w-fit"
			title="Print Settings"
			canConfirm
			onConfirm={() => onConfirm()}
			onCancel={() => props.onCancel()}
			content={
				<div className="h-full flex flex-col gap-2 w-full bg-[#f4f3f8] p-4">
					<Card title="Printer">
						<span className="flex gap-2">
							<strong>Printer connection:</strong>
							<ButtonGroup<(typeof connection_options)[0]>
								small
								titleField="label"
								onChange={(x) => {
									setConnectionType(x.value);
								}}
								valueField="value"
								buttons={connection_options}
								selectedId={connectionType}
							/>
						</span>
						{connectionType === Const.PRINTER_CONNECTION.NETWORK ? (
							<div className="grid grid-cols-2 gap-2">
								<Input
									label="Printer Ip"
									placeholder="Eg 192.168.123.100"
									value={printerIp}
									onChange={(e) => setPrinterIp(e.target.value)}
								/>
								<Input
									label="Printer port"
									placeholder="Eg 9100"
									value={printerPort}
									onChange={(e) => setPrinterPort(e.target.value)}
								/>
							</div>
						) : (
							electron && (
								<div className=" mt-4">
									<Button
										small
										className={' h-10 min-h-[20px] btn-outline btn-primary'}
										title="Configure Printer"
										onClick={() => {
											if (electron) {
												electron.ipcRenderer.configurePrinter();
											}
										}}
										iconBefore={<Icon path={mdiCog} />}
									/>
								</div>
							)
						)}
					</Card>
					<Card title="Receipt">
						<span className="flex flex-col gap-2">
							<strong>Receipts Type:</strong>
							<ButtonGroup<(typeof receipt_options)[0]>
								small
								titleField="label"
								onChange={(x) => setReceiptType(x.value)}
								valueField="value"
								buttons={receipt_options}
								selectedId={receiptType}
							/>
						</span>
						<div className="mt-2 items-center flex gap-2 justify-between ">
							<strong>
								{I18n.t(_t(translations.setting.enableTicketPrinting))}
							</strong>

							<Switch
								value={printTicket}
								onChange={(x) => setPrintTicket(x)}
								label={''}
							/>
						</div>
					</Card>
				</div>
			}
		/>
	);
};
