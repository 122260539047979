import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { showAlert } from 'helpers/alertHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { t, translations } from 'utils';

export const EmployeeCodeModal = (props: {
	onCancel: () => void;
	onConfirm: (employeeId: string) => void;
}) => {
	const employees = useAppSelector((state) => state.EmployeeReducer.employees);

	const onConfirm = (code: string) => {
		const employee = _.find(employees, (x) => x.code === code);
		if (!employee) {
			showAlert(t(translations.checkout.cantFindStaff), 'error');
		} else {
			setTimeout(() => {
				props.onCancel();
				props.onConfirm(employee.id);
			}, 200);
		}
	};

	return (
		<ModalNumPad
			{...props}
			showNumpad
			unit=""
			title={t(translations.checkout.employeeCode)}
			onConfirm={(x) => onConfirm(x)}
		>
			<div className="flex h-full flex-1 items-end p-4"></div>
		</ModalNumPad>
	);
};
