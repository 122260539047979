import { createReducer, PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { IApiResponse, IErrorResponse } from "models";
import { AuthenticationActions, ErrorActions } from "redux/actions";

export interface IErrorReducer {
  error?: IErrorResponse;
}

const initialState: IErrorReducer = {};

function isError(action: AnyAction) {
  return action.type.endsWith("_FAILED");
}
function isRequest(action: AnyAction) {
  return action.type.endsWith("_REQUEST");
}
const ErrorReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(ErrorActions.clearError.request, (state: IErrorReducer) => {
      state.error = undefined;
    })
    .addCase(AuthenticationActions.logOut.request, (state: IErrorReducer) => {
      state.error = undefined;
    })
    .addMatcher(isRequest, (state) => {
      state.error = undefined;
    })
    .addMatcher(isError, (state, action: PayloadAction<IApiResponse>) => {
      state.error = action.payload;
    })
);

export default ErrorReducer;
