/* eslint-disable react-hooks/exhaustive-deps */
import React, { DetailedHTMLProps, useEffect, useState } from 'react';
import RCTimePicker, { TimePickerProps } from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './styles.css';
import clsxm from 'clsxs/clsxm';
import moment, { Moment } from 'moment';
import { Icon } from './Icon';
import { mdiChevronRight } from '@mdi/js';

interface ITimePicker extends TimePickerProps {
	leftPopUp?: boolean;
	hideAmPm?: boolean;
}

export const TimePicker = (props: ITimePicker) => {
	return (
		<RCTimePicker
			showSecond={false}
			use12Hours
			format="hh:mm A"
			{...props}
			className={clsxm(`w-full rounded-lg rc-picker ${props.className || ''}`)}
			popupClassName={clsxm(
				`rc-pop-up ${props.popupClassName || ''} ${
					props.hideAmPm ? 'rc-disabled-am-pm ' : ''
				} `,
				props.leftPopUp ? 'left' : ''
			)}
		/>
	);
};

export interface ITimeRangePickerProps
	extends Omit<
		DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
		'defaultValue'
	> {
	onChooseTime?: (time: Moment[]) => void;
	defaultValue?: Moment[];
	startProps?: TimePickerProps;
	endProps?: TimePickerProps;
	disabled?: boolean;
	minuteStep?: number;
	hideAmPm?: boolean;
}
export const TimeRangePicker = (props: ITimeRangePickerProps) => {
	const { defaultValue, startProps, endProps, onChooseTime, ...divProps } =
		props;
	const initialValue = [
		moment().startOf('date').hour(10),
		moment().startOf('date').hour(20),
	];
	const [choseTime, setChoseTime] = useState<Moment[]>(
		defaultValue || initialValue
	);
	const [isInitial, setIsInitial] = useState<boolean>(true);

	useEffect(() => {
		if (isInitial) {
			setIsInitial(false);
		} else {
			startProps?.value &&
				endProps?.value &&
				setChoseTime([startProps?.value, endProps?.value]);
		}
	}, [startProps?.value, endProps?.value]);

	const onChangeTime = (value: Moment, from = true) => {
		setChoseTime((prev) => {
			if (from) {
				if (value.isAfter(prev[1])) {
					return [prev[1], value];
				}
				return [value, prev[1]];
			} else {
				if (value.isBefore(prev[0])) {
					return [value, prev[0]];
				}
				return [prev[0], value];
			}
		});
	};

	return (
		<div
			{...divProps}
			className={clsxm('w-full grid grid-cols-11 items-center')}
		>
			<div className={clsxm('col-span-5')}>
				<RCTimePicker
					showSecond={false}
					use12Hours
					format="hh:mm A"
					value={choseTime[0]}
					{...props.startProps}
					className={clsxm(`w-full rc-picker ${props.className || ''}`)}
					popupClassName={clsxm(
						`rc-pop-up ${props.startProps?.popupClassName || ''} ${
							props.hideAmPm ? 'rc-disabled-am-pm ' : ''
						}`
					)}
					onChange={(value) => {
						onChooseTime?.([value, choseTime[1]]);
						onChangeTime(value as Moment);
					}}
					disabled={
						props.startProps?.disabled
							? props.startProps?.disabled
							: props.disabled
					}
					minuteStep={
						props.startProps?.minuteStep
							? props.startProps.minuteStep
							: props.minuteStep
					}
				/>
			</div>
			<Icon path={mdiChevronRight} className="m-0 self-center mx-auto" />
			<div className={clsxm('col-span-5')}>
				<RCTimePicker
					showSecond={false}
					use12Hours
					format="hh:mm A"
					value={choseTime[1]}
					{...props.endProps}
					className={clsxm(`w-full rc-picker ${props.className}`)}
					popupClassName={clsxm(
						`rc-pop-up ${props.endProps?.popupClassName || ''}`,
						props.hideAmPm ? 'rc-disabled-am-pm ' : ''
					)}
					onChange={(value) => {
						onChooseTime?.([choseTime[0], value]);
						onChangeTime(value as Moment, false);
					}}
					disabled={
						props.endProps?.disabled ? props.endProps?.disabled : props.disabled
					}
					minuteStep={
						props.endProps?.minuteStep
							? props.endProps.minuteStep
							: props.minuteStep
					}
				/>
			</div>
		</div>
	);
};
