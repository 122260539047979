import { Select } from 'components/atoms/Select';
import { AlertHelper, GenerateHelper, TimeHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
	mdiAccountCog,
	mdiAccountConvert,
	mdiAccountSupervisorCircle,
	mdiBriefcaseSearchOutline,
	mdiChartArc,
	mdiChevronDown,
	mdiChevronLeft,
	mdiChevronRight,
	mdiClockIn,
	mdiClockOut,
	mdiClose,
	mdiCog,
	mdiCounter,
	mdiFaceAgent,
	mdiLogout,
	mdiReload,
	mdiSourceBranch,
} from '@mdi/js';
import { Icon } from 'components/atoms/Icon';
import { Button } from 'components/atoms/Button';
import _ from 'lodash';
import {
	DetailRoute,
	getNameByPath,
	canShowBack,
	HomeRoute,
} from 'routers/routes';
import clsxm from 'clsxs/clsxm';
import { t, translations } from 'utils';
import WebModal from 'react-modal';
import { Modal } from 'components/atoms';
import { useDispatch } from 'react-redux';
import { PinCode } from 'components/molecules/PinCode';
import JSEncrypt from 'jsencrypt';
import { IClockInOutRequest, ILoginByPinRequest } from 'models/RequestModels';
import {
	AuthenticationActions,
	BranchActions,
	TodayActions,
} from 'redux/actions';
import moment from 'moment';
import ClockInOutService from 'services/ClockInOutService';
import { encryptedString } from 'helpers/StringHelper';
import { EEmployeeTypeCode } from 'models';

interface IHeaderProps {
	extraRight?: React.ReactNode;
	extraLeft?: React.ReactNode;
	showBack?: boolean;
	showClose?: boolean;
	onClose?: () => void;
	title?: string;
}
enum EFunctionsType {
	CLOCK_IN,
	CLOCK_OUT,
	CHANGE_ROLE,
}

export const Clock = () => {
	const [date, setDate] = useState(new Date());
	useEffect(() => {
		const interval = setInterval(() => setDate(new Date()), 1000);
		return () => clearInterval(interval);
	}, []);
	return (
		<div
			className="flex flex-col  rounded-lg bg-transparent text-primary p-1 px-2"
			style={{ minWidth: 150 }}
		>
			<span className="font-bold text-sm text-right ">
				{TimeHelper.toTimeZone(date).format('h:mm A')}
			</span>
			<span className="text-xs text-right">
				{' '}
				{TimeHelper.toTimeZone(date).format('dddd, ll')}
			</span>
		</div>
	);
};

export const Support = () => {
	return (
		<div
			className="flex flex-1 flex-col rounded-lg bg-white border border-primary text-primary p-1 px-2"
			style={{ minWidth: 150 }}
		>
			<span className="text-xs text-right">
				{t(translations.profileMenu.contactSupport)}
			</span>
			<span className="font-bold text-sm">+1 (202) 733 6544</span>
		</div>
	);
};
export const Reload = () => {
	return (
		<div
			onClick={() => window.location.reload()}
			className="flex cursor-pointer flex-col rounded-lg items-center bg-white text-primary p-2 justify-center aspect-square"
		>
			<Icon path={mdiReload} />
		</div>
	);
};
const BranchModal = (props: { onCancel: () => void }) => {
	const branches = useAppSelector((state) => state.BranchReducer.branches);
	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const dispatch = useDispatch();

	return (
		<Modal
			isOpen={true}
			onCancel={() => props.onCancel && props.onCancel()}
			title={'Select Branch'}
			className={'w-fit min-w-[650px]'}
			content={
				<div className={clsxm('w-full p-4')}>
					{branches.map((x) => {
						return (
							<div
								onClick={() => {
									if (x.id !== currentBranch?.id) {
										dispatch(BranchActions.selectBranch.request(x));
									}
									props.onCancel();
								}}
								className={clsxm(
									'flex cursor-pointer justify-between p-4 my-4 border-b border-b-base-200 rounded-lg ',
									x.id === currentBranch?.id && 'main-gradient-background'
								)}
							>
								<h6>{x.name}</h6>
								<Icon path={mdiChevronRight} />
							</div>
						);
					})}
				</div>
			}
		/>
	);
};

export const Functions = () => {
	const [showFunction, setShowFunction] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const [showPin, setShowPin] = useState(false);
	const [showBranches, setShowBranches] = useState(false);
	const [functionsType, setFunctionsType] = useState<EFunctionsType>();
	const roles = useAppSelector((state) => state.UserReducer.user?.userRoles);
	const employeeRoles = useAppSelector(
		(state) => state.UserReducer.employee?.employeeTypes
	);
	const functionItem = (
		title: string,
		icon: string,
		classNames?: string,
		callBack?: () => void,
		rolePermission?: string[]
	) => {
		if (
			!_.isEmpty(rolePermission) &&
			_.isEmpty(_.intersection(rolePermission, roles)) &&
			_.isEmpty(
				_.intersection(
					rolePermission?.map((x) => x.toLowerCase()),
					employeeRoles?.map((x) => x.code?.toLowerCase())
				)
			)
		) {
			return null;
		}
		return (
			<div
				onClick={() => {
					setShowFunction(false);
					setTimeout(() => {
						callBack && callBack();
					}, 200);
				}}
				className={clsxm('function-item text-primary', classNames)}
			>
				<Icon path={icon} />
				<strong>{title}</strong>
			</div>
		);
	};
	return (
		<>
			<div
				className="flex flex-1 cursor-pointer justify-center items-center gap-2 border-2 border-white  rounded-lg main-gradient-background text-primary p-1 px-2"
				style={{ minWidth: 120 }}
				onClick={() => {
					setShowFunction(true);
				}}
			>
				<Icon size={1.3} path={mdiCog} />
				<span className="font-bold text-sm uppercase">{'Managements'}</span>
			</div>
			{showPin && (
				<PinCodeModal
					onCancel={() => {
						setFunctionsType(undefined);
						setShowPin(false);
					}}
					type={functionsType}
				/>
			)}
			{showBranches && <BranchModal onCancel={() => setShowBranches(false)} />}
			{showFunction && (
				<WebModal
					closeTimeoutMS={200}
					isOpen={true}
					appElement={document.getElementById('root') || undefined}
					onRequestClose={() => setShowFunction(false)}
					shouldCloseOnOverlayClick={true}
					overlayClassName={
						'flex justify-center z-[51]  items-center  align-middle fixed top-0 bottom-0 right-0 left-0 w-full h-full bg-[#000]  bg-opacity-50 '
					}
					className={clsxm(
						'bg-primary-content absolute rounded-xl flex flex-col shadow-lg m-auto'
					)}
				>
					<div className="flex items-center justify-between text-white bg-primary p-4 rounded-t-xl ">
						<h1 className={clsxm('uppercase ml-0 mr-4 flex font-bold')}>
							Functions
						</h1>
						<div
							onClick={() => setShowFunction(false)}
							className="p-1 bg-white border border-ERROR rounded-lg cursor-pointer"
						>
							<Icon color={'red'} path={mdiClose} />
						</div>
					</div>
					<div className="grid grid-cols-5 gap-2 p-4">
						{/* {functionItem('Clock In', mdiClockIn, '', () => {
							setFunctionsType(EFunctionsType.CLOCK_IN);
							setShowPin(true);
						})}
						{functionItem('Clock Out', mdiClockOut, '', () => {
							setFunctionsType(EFunctionsType.CLOCK_OUT);
							setShowPin(true);
						})} */}

						{/* {functionItem('Close Out', mdiCounter, '', undefined, [
							'Admin',
							EEmployeeTypeCode.MANAGER,
						])} */}
						{functionItem('Change Role', mdiAccountConvert, '', () => {
							setFunctionsType(EFunctionsType.CHANGE_ROLE);
							setShowPin(true);
						})}
						{functionItem(
							'Change Branch',
							mdiSourceBranch,
							'',
							() => {
								setShowBranches(true);
							},
							['Admin']
						)}
						{functionItem(
							'Dashboard',
							mdiChartArc,
							'col-start-1',
							() => history.push('/dashboard'),
							['Admin', EEmployeeTypeCode.MANAGER]
						)}
						{/* {functionItem('Customers', mdiAccountSupervisorCircle, '', () =>
							history.push('/customer')
						)} */}
						{functionItem(
							'Sales',
							mdiBriefcaseSearchOutline,
							'',
							() => history.push('/sales'),
							['Admin', EEmployeeTypeCode.MANAGER]
						)}
						{functionItem(
							'Manager',
							mdiAccountCog,
							'',
							() => history.push('/menu'),
							['Admin', EEmployeeTypeCode.MANAGER]
						)}
						{functionItem('Help Center', mdiFaceAgent, 'col-start-1')}
						{/* {functionItem('Settings', mdiCog, '')} */}
						{functionItem(
							'Logout',
							mdiLogout,
							'border-ERROR border col-end-6 text-error',
							() => {
								dispatch(AuthenticationActions.logOut.request());
							}
						)}
					</div>
				</WebModal>
			)}
		</>
	);
};

const ClockInOut = (props: { onCancel: () => void; open: boolean }) => {
	const [selectModal, setSelectModal] = useState(false);
	const [clockType, setClockType] = useState<EFunctionsType | undefined>(
		undefined
	);
	useEffect(() => {
		setSelectModal(props.open);
	}, [props.open]);
	return (
		<>
			{selectModal && (
				<WebModal
					closeTimeoutMS={200}
					isOpen={true}
					appElement={document.getElementById('root') || undefined}
					onRequestClose={() => {
						setSelectModal(false);
						props.onCancel();
					}}
					shouldCloseOnOverlayClick={true}
					overlayClassName={
						'flex justify-center z-[51]  items-center  align-middle fixed top-0 bottom-0 right-0 left-0 w-full h-full bg-[#000]  bg-opacity-50 '
					}
					className={clsxm(
						'bg-primary-content absolute rounded-xl flex flex-col shadow-lg m-auto'
					)}
				>
					<div className="flex items-center justify-between text-white bg-primary p-4 rounded-t-xl ">
						<h1 className={clsxm('uppercase ml-0 mr-4 flex font-bold')}>
							Clock In/Out
						</h1>
						<div
							onClick={() => {
								setSelectModal(false);
								props.onCancel();
							}}
							className="p-1 bg-white border border-ERROR rounded-lg cursor-pointer"
						>
							<Icon color={'red'} path={mdiClose} />
						</div>
					</div>
					<div className="grid grid-cols-5 gap-2 p-4">
						<div
							onClick={() => {
								setSelectModal(false);
								setTimeout(() => {
									setClockType(EFunctionsType.CLOCK_IN);
								}, 300);
							}}
							className={clsxm('function-item text-primary')}
						>
							<Icon path={mdiClockIn} />
							<strong>{'Clock In'}</strong>
						</div>
						<div
							onClick={() => {
								setSelectModal(false);
								setTimeout(() => {
									setClockType(EFunctionsType.CLOCK_OUT);
								}, 300);
							}}
							className={clsxm('function-item text-primary')}
						>
							<Icon path={mdiClockOut} />
							<strong>{'Clock Out'}</strong>
						</div>
					</div>
				</WebModal>
			)}
			{clockType !== undefined ? (
				<PinCodeModal
					onCancel={() => {
						setClockType(undefined);
						props.onCancel();
					}}
					type={clockType}
				/>
			) : undefined}
		</>
	);
};

const PinCodeModal = (props: {
	onCancel: () => void;
	type?: EFunctionsType;
}) => {
	const dispatch = useDispatch();
	const [clicked, setClicked] = useState(false);
	const shop = useAppSelector((state) => state.ShopReducer.shop);
	const branchId = useAppSelector(
		(state) => state.BranchReducer.currentBranch?.id || ''
	);
	const action = useAppSelector(
		(state) => state.ReduxActionReducer['AUTHENTICATION']
	);

	const clockInOutRequest = (pinCode: string): IClockInOutRequest => {
		return {
			branchId,
			shopId: `${shop?.id}`,
			date: moment().format('MM-DD-YYYY'),
			pinCode,
		};
	};

	const onComplete = (value: string) => {
		const encryptedValue = encryptedString(value);
		switch (props.type) {
			case EFunctionsType.CHANGE_ROLE:
				return onChangeRole(encryptedValue);
			case EFunctionsType.CLOCK_IN:
				return onClockIn(encryptedValue);
			case EFunctionsType.CLOCK_OUT:
				return onClockOut(encryptedValue);
			default:
				break;
		}
	};
	const onClockIn = async (pinCode: string) => {
		const response = await ClockInOutService.clockIn(
			clockInOutRequest(pinCode)
		);
		if (!response.succeeded) {
			AlertHelper.showError(response);
		}
		props.onCancel();
	};
	const onClockOut = async (pinCode: string) => {
		const response = await ClockInOutService.clockOut(
			clockInOutRequest(pinCode)
		);
		if (!response.succeeded) {
			AlertHelper.showError(response);
		}
		props.onCancel();
	};
	const onChangeRole = (pinCode: string) => {
		const data: ILoginByPinRequest = {
			pinCode,
			shopId: `${shop?.id}`,
			rememberMe: true,
			deviceId: GenerateHelper.uuid(),
		};
		setClicked(true);
		dispatch(AuthenticationActions.authenticateWithPin.request(data));
	};
	useEffect(() => {
		if (clicked && action === AuthenticationActions.authenticate.successName) {
			props.onCancel();
		}
	}, [action, clicked]);
	const getOkTitle = () => {
		switch (props.type) {
			case EFunctionsType.CLOCK_IN:
				return 'CLOCK IN';
			case EFunctionsType.CLOCK_OUT:
				return 'CLOCK OUT';
		}
	};
	return (
		<Modal
			isOpen={true}
			onCancel={() => props.onCancel()}
			title={'Enter your Pin code'}
			content={
				<div className="flex flex-col justify-between mt-64 gap-4 items-center w-full">
					<PinCode
						onComplete={(value) => onComplete(value)}
						okTitle={getOkTitle()}
					/>
				</div>
			}
		/>
	);
};
export const Header = (props: IHeaderProps) => {
	const location = useLocation();
	const history = useHistory();
	const isDetail = _.includes(
		DetailRoute.map((x) => x.path),
		location.pathname
	);
	const [showClockInOut, setShowClockInOut] = useState(false);
	const showBack = props.showBack || canShowBack(location.pathname);
	const onClickHeaderLink = (name: string) => {
		if (name === 'clockInOut') {
			setShowClockInOut(true);
		}
	};
	return (
		<div className="flex px-0 bg-[#d0edfb]  fixed align-middle z-50 text-white justify-between h-16 top-0 w-screen inset-x-0 ">
			<div className="flex h-full pl-4 w-4/5 align-middle rounded-r-lg">
				<h4
					onClick={() => {
						if (showBack) {
							history.goBack();
						}
					}}
					className={clsxm(
						'text-primary font-bold uppercase my-auto ml-0 mr-4 flex ',
						showBack && 'cursor-pointer'
					)}
				>
					{(showBack || isDetail) && (
						<Icon path={mdiChevronLeft} className="mr-2" />
					)}
					{_.truncate(
						props.title ? props.title : getNameByPath(location.pathname),
						{ length: 10 }
					)}
				</h4>
				{props.extraLeft && props.extraLeft}
			</div>
			{!location.pathname.includes('booking') &&
				HomeRoute.filter((r) => r.mainMobile === true).map((route) => {
					if (route.mainMobile) {
						if (route.path && route.component) {
							return (
								<Link
									key={route.path}
									to={route.path!}
									className={clsxm(
										' w-full no-underline flex shadow-xl items-center bg-[#f4f3f8] text-primary gap-2 py-3 align-middle justify-center px-0.5 my-1 text-center rounded-lg  border border-primary mx-1  first:transition duration-300',
										location.pathname === route.path &&
											'main-gradient-background'
									)}
								>
									{route.icon}
									<h6
										className={clsxm(
											'text-md leading-tight',
											location.pathname === route.path && 'font-bold'
										)}
									>
										{route.name}
									</h6>
								</Link>
							);
						} else {
							return (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									key={route.key}
									onClick={() => onClickHeaderLink(`${route.key}`)}
									className={clsxm(
										' w-full no-underline flex shadow-xl cursor-pointer items-center bg-[#f4f3f8] text-primary gap-2 py-3 align-middle justify-center px-1 my-1 text-center rounded-lg  border border-primary mx-1  first:transition duration-300'
									)}
								>
									{route.icon}
									<h6 className={clsxm('text-md leading-tight')}>{route.name}</h6>
								</a>
							);
						}
					}
					return false;
				})}

			<div className="flex w-fit gap-2 pr-4 justify-end align-middle py-2 rounded-l-lg min-w-fit ">
				<Clock />
				<Support />
				<Functions />

				{props.extraRight && props.extraRight}
				{(isDetail || props.showClose) && (
					<Button
						small
						title="Close"
						onClick={() => {
							if (props.onClose) {
								props.onClose();
							} else {
								history.goBack();
							}
							// props.onClose && props.onClose();
						}}
						iconBefore={<Icon path={mdiClose} />}
						className="btn-outline btn-error bg-white"
					/>
				)}
			</div>
			<ClockInOut
				onCancel={() => setShowClockInOut(false)}
				open={showClockInOut}
			/>
		</div>
	);
};
