// import _ from "lodash";
import { translations, _t, I18n } from 'utils';

export function priceFixed(value?: number, precision: number = 2) {
	return parseFloat(
		Math.round(+(value?.toFixed(precision + 1) + 'e' + precision)) +
			'e-' +
			precision
	);
}
export const formatPrice = (value: number | undefined, showCurrency = true) => {
	const price =
		value !== undefined
			? `${value >= 0 ? '' : '-'}${
					showCurrency === true ? I18n.t(_t(translations.currency)) : ''
			  }${priceFixed(Math.abs(value), 2).toFixed(2)}`.replace(
					/\B(?=(\d{3})+(?!\d))/g,
					','
			  )
			: `$0.00`;

	return price;
};
export const parserPrice = (value: any) => value.replace(/\$\s?|(,*)/g, '');
export const formatBalance = (value: number | undefined, showCurrency = true) =>
	`${showCurrency === true ? I18n.t(_t(translations.currency)) : ''}${
		priceFixed(value, 2).toFixed(2) || 0
	}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// export const formatPhoneNumber = (phoneNumberString: string | undefined) => {
//   var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
//   var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     return  '(' + match[1] + ') ' + match[2] + '-' + match[3];
//   }
//   return null;
// }

export const isNumeric = (value: string) => {
	return /^-?\d+$/.test(value);
};
