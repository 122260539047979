import { BookingHelper, CalendarHelper, TimeHelper } from 'helpers';
import { showAlert, showError, showSuccess } from 'helpers/alertHelper';
import {
	convertDateRequest,
	convertTimeRequest,
	getServiceDuration,
} from 'helpers/bookingHelper';
import { IEventCalendar } from 'helpers/calendarHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import {
	EBookingInputFrom,
	IApiResponse,
	IBooking,
	IEmployee,
	IHolidayDetail,
	IHolidayModel,
} from 'models';
import { IBookingSearchQuery, IGetBookingsRequest } from 'models/RequestModels';
import moment, { Moment } from 'moment';
import qs from 'qs';
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BookingActions, HolidayActions } from 'redux/actions';
import { showLoading } from 'redux/actions/appConfig';
import {
	getEmployeesSortByTurn,
	getAllStylist,
} from 'redux/selectors/employee';
import { start } from 'repl';
import { HolidayApiService } from 'services';
import BookingApiService from 'services/BookingApiService';

interface ICalendarContext {
	stylist?: string;
	stylists: IEmployee[];
	stylistsByTurn: IEmployee[];
	date: Moment;
	events: IEventCalendar[];
	loading?: boolean;
	view?: 'day' | 'week';
	setStylist?: (stylist: string) => void;
	setDate?: (date: Moment) => void;
	setView?: (view: 'day' | 'week') => void;
	onChangeEvent: (data: {
		event: IEventCalendar;
		start: Date;
		end: Date;
		resourceId: string;
		isResize?: boolean;
	}) => void;
}
const CalendarContext = createContext<ICalendarContext>({
	date: moment(),
	stylists: [],
	stylistsByTurn: [],
	events: [],
	onChangeEvent: () => {},
});
export const useCalendarContext = () => {
	return useContext(CalendarContext);
};

export const CalendarProvider: React.FC<{
	children?: React.ReactNode;
}> = ({ children }) => {
	const [date, setDate] = useState<Moment>(moment());
	const [stylist, setStylist] = useState('all-employee');
	const [view, setView] = useState<'day' | 'week'>('day');
	const [stylists, setStylists] = useState<IEmployee[]>([]);
	const allStylists = useAppSelector((state) => getAllStylist(state));
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const stylistsByTurn = useAppSelector((state) =>
		getEmployeesSortByTurn(
			state.BookingReducer.bookings,
			stylists.filter((x) => x.status !== 0 && x.status !== -1)
		)
	);
	const [loading, setLoading] = useState(false);
	const holidays = useAppSelector(
		(state) => state.HolidayReducer.paginateHoliday?.data
	);
	const bookings = useAppSelector((state) => state.BookingReducer.bookings);

	const [events, setEvents] = useState<IEventCalendar[]>([]);
	const dispatch = useDispatch();
	const getListStylist = async () => {
		const realDate = !_.isEmpty(queries?.date)
			? TimeHelper.toTimeZone(moment(queries?.date, 'MM-DD-YYYY').toDate())
			: date;
		const response = await BookingApiService.getAvailableStylistAndTimeBlocks({
			date: realDate.format('MM-DD-YYYY'),
			inputFrom: EBookingInputFrom.POS,
		});
		if (response.succeeded && response.data && response.data.stylists) {
			setStylists(
				allStylists.filter(
					(x) =>
						!_.isEmpty(_.find(response.data?.stylists, (st) => st.id === x.id))
				)
			);
		}
	};

	useEffect(() => {
		setEvents(
			_.concat(
				CalendarHelper.convertBookingToEvent(bookings),
				CalendarHelper.convertHolidayToEvent(
					_.uniqBy(holidays || [], (holiday) => holiday.id)
				),
				CalendarHelper.convertBreakToEvent(stylists, date!)
			)
		);
	}, [bookings, holidays, stylists]);
	const getBookings = () => {
		let request: IGetBookingsRequest;
		const realDate = !_.isEmpty(queries?.date)
			? TimeHelper.toTimeZone(
					moment(queries?.date, 'MM-DD-YYYY').toDate()
			  )
			: date;
		const requestDate = moment(realDate);

		if (view === 'day') {
			request = {
				// branchId: this.props.currentBranch?.id!,
				fromDate: BookingHelper.convertDateRequest(requestDate),
				toDate: BookingHelper.convertDateRequest(requestDate),
			};
		}
		if (view === 'week') {
			request = {
				// branchId: this.props.currentBranch?.id!,
				fromDate: requestDate.startOf('week').utc().toDate(),
				toDate: requestDate.add(1, 'week').subtract(1, 'minute').utc().toDate(),
				stylistId: stylist,
			};
		}

		setTimeout(() => {
			dispatch(BookingActions.getBookingsByBranch.request(request));
			dispatch(
				HolidayActions.getAllHoliday.request({
					...request,
					pageNumber: 1,
					pageSize: 100,
				})
			);
		}, 100);
	};
	useEffect(() => {
		if (moment(date) === moment()) {
			console.log('123');

			getListStylist();
			getBookings();
		}
	}, [date, view]);
	useEffect(() => {
		if (moment(date) !== moment()) {
			console.log('456');

			getListStylist();
			getBookings();
		}
	}, [date, view]);

	const onChangeEvent = async (data: {
		event: IEventCalendar;
		start: Date;
		end: Date;
		resourceId: string;
		isResize?: boolean;
	}) => {
		let newEvents = _.clone(events);
		const oldEvents = _.clone(events);
		const eventIndex = _.findIndex(newEvents, (x) => x.id === data.event.id);
		if (!data.isResize && moment(data.start).isBefore(moment())) {
			return;
		}
		if (data.resourceId !== 'Anyone') {
			const staffEvents = _.filter(
				events,
				(x) => x.resourceId === data.resourceId && x.id !== data.event.id
			);
			const overlapEvent = staffEvents.find(
				(x) =>
					moment(data.start).isBetween(moment(x.start), moment(x.end)) ||
					moment(data.end).isBetween(moment(x.start), moment(x.end)) ||
					moment(x.end).isBetween(moment(data.start), moment(data.end)) ||
					moment(x.start).isBetween(moment(data.start), moment(data.end))
			);
			if (!_.isEmpty(overlapEvent)) {
				return;
			}
		}

		setLoading(true);
		if (eventIndex >= 0) {
			const newStaff = allStylists.find((x) => x.id === data.resourceId);
			newEvents[eventIndex] = {
				...newEvents[eventIndex],
				resourceId: data.resourceId,
				resource: newStaff,
				start: data.start,
				end: data.end,
				startAtExpected: data.start,
				endAtExpected: data.end,
			};
		}
		setEvents(newEvents);
		if (_.isEmpty(data.event.booking)) {
			const holiday = _.find(holidays, (x) => x.id === data.event.holidayId);
			if (holiday) {
				const holidayDetailIndex = _.findIndex(
					holiday.holidayDetails,
					(x) => x.id === data.event.id
				);
				let response: IApiResponse;
				if (holidayDetailIndex >= 0) {
					let newHolidayDetails = _.clone(holiday.holidayDetails);
					if (
						newHolidayDetails[holidayDetailIndex].employeeId !== data.resourceId
					) {
						setEvents(oldEvents);
						setLoading(false);
						showAlert("Can't update holiday to other staff", 'error');
						return;
					}
					const newHolidayDetail = {
						...newHolidayDetails[holidayDetailIndex],
						startTime: moment(data.start).toDate(),
						endTime: moment(data.end).toDate(),
					};
					if (holiday.frequencyType === 0) {
						newHolidayDetails[holidayDetailIndex] = newHolidayDetail;
						const newHoliday: Partial<IHolidayModel> = {
							...holiday,
							startTime: moment(data.start).toDate(),
							endTime: moment(data.end).toDate(),
							daysOfMonth: !_.isEmpty(holiday.daysOfMonth)
								? (holiday.daysOfMonth as string)
										.split(',')
										.map((x) => parseInt(x))
								: '',
							daysOfWeek: !_.isEmpty(holiday.daysOfWeek)
								? (holiday.daysOfWeek as string)
										.split(',')
										.map((x) => parseInt(x))
								: '',
							holidayDetails: newHolidayDetails,
						};
						response = await HolidayApiService.updateHoliday(newHoliday);
					} else {
						response = await HolidayApiService.updateHolidayDetail(
							newHolidayDetail
						);
					}
					setLoading(false);
					if (response.succeeded) {
						showSuccess('Update Holiday Successfully');
					} else {
						showError(response);
						setEvents(oldEvents);
					}
				}
			}
			return;
		}
		const booking = bookings.find((x) => x.id === data.event.booking?.id);
		if (booking) {
			const bookingDetailIndex = _.findIndex(
				booking.bookingDetails,
				(x) => x.id === data.event.id
			);
			if (bookingDetailIndex >= 0) {
				let newBookingDetails = _.clone(booking.bookingDetails);
				const employee = allStylists.find((x) => x.id === data.resourceId);
				const duration =
					newBookingDetails[bookingDetailIndex].stylistId !== data.resourceId
						? getServiceDuration(
								newBookingDetails[bookingDetailIndex].item!,
								employee
						  )
						: undefined;
				newBookingDetails[bookingDetailIndex] = {
					...newBookingDetails[bookingDetailIndex],
					stylistId: data.resourceId === 'Anyone' ? undefined : data.resourceId,
					item: undefined,
					stylist: undefined,
					startAtExpected: TimeHelper.toTimeZone(data.start).toDate(),
					endAtExpected: duration
						? TimeHelper.toTimeZone(data.start).add(duration, 'minute').toDate()
						: data.end,
					startAt: newBookingDetails[bookingDetailIndex].startAt
						? TimeHelper.toTimeZone(data.start).toDate()
						: undefined,
					endAt: newBookingDetails[bookingDetailIndex].startAt
						? TimeHelper.toTimeZone(data.end).toDate()
						: undefined,
				};
				const newSortBookingDetails = _.sortBy(newBookingDetails, (x) =>
					TimeHelper.toTimeZone(x.startAtExpected).unix()
				);

				const newBooking: Partial<IBooking> = {
					..._.clone(booking),
					totalTax: undefined,
					stylist: undefined,
					customer: undefined,
					stylistId:
						bookingDetailIndex === 0 ? data.resourceId : booking.stylistId,
					endTimeExpected: _.last(newSortBookingDetails)?.endAtExpected,
					startTimeExpected: _.first(newSortBookingDetails)?.startAtExpected,
					bookingDetails: newSortBookingDetails,
				};
				const response = await BookingApiService.editBooking(newBooking);
				setLoading(false);
				if (response.succeeded) {
					showSuccess('Update Booking Successfully');
				} else {
					showError(response);
					setEvents(oldEvents);
				}
			}
		}
	};
	const value = React.useMemo(
		() => ({
			date: date || moment(),
			stylist,
			view,
			setDate,
			setStylist,
			setView,
			stylists,
			stylistsByTurn: stylistsByTurn.filter(
				(x) => x.status !== 0 && x.status !== -1
			),
			events,
			onChangeEvent,
			loading,
		}),
		[date, stylist, view, stylists, stylistsByTurn, events, loading]
	);
	return (
		<CalendarContext.Provider value={value}>
			{children}
		</CalendarContext.Provider>
	);
};
