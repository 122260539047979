import { t, translations } from 'utils';
import { WeekDays } from 'utils/Consts';
import _ from 'lodash';
import JSEncrypt from 'jsencrypt';

export function getShortName(text: string) {
	var matches = text.match(/\b(\w)/g); // ['J','S','O','N']
	return _.take(matches, 2).join('');
}

export const removeSpecialCharacter = (text?: string) => {
	if (text) {
		return text.replace(/[^\w\s]/gi, '');
	}
	return '';
};

export const formatPercent = (value: number | undefined) => `${value}%`;

export const parserCommission = (value: any) => value.replace('%', '');

export const formatPhoneNumber = (str?: string) => {
	//Filter only numbers from the input
	let cleaned = ('' + str).replace(/\D/g, '');

	//Check if the input is of correct length
	let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		return match[1] + '-' + match[2] + '-' + match[3];
	}

	return '';
};

export const convertToRawPhone = (value: string) => {
	if (value) {
		return value.match(/\d/g)?.join('');
	}
	return '';
};

export const checkPhoneNumber = (value: string) => {
	// if (value.match(/\d/g)?.length === 10) {
	//   return true;
	// }
	// return false
	return value.match(/\d/g)?.length === 10;
};

export const getDayOfWeek = (day: number, ignoreLanguage = false) => {
	switch (day) {
		case WeekDays.SUNDAY:
			return ignoreLanguage ? 'sunday' : t(translations.daysOfWeek.sunday);
		case WeekDays.MONDAY:
			return ignoreLanguage ? 'monday' : t(translations.daysOfWeek.monday);
		case WeekDays.TUESDAY:
			return ignoreLanguage ? 'tuesday' : t(translations.daysOfWeek.tuesday);
		case WeekDays.WEDNESDAY:
			return ignoreLanguage
				? 'wednesday'
				: t(translations.daysOfWeek.wednesday);
		case WeekDays.THURSDAY:
			return ignoreLanguage ? 'thursday' : t(translations.daysOfWeek.thursday);
		case WeekDays.FRIDAY:
			return ignoreLanguage ? 'friday' : t(translations.daysOfWeek.friday);
		case WeekDays.SATURDAY:
			return ignoreLanguage ? 'saturday' : t(translations.daysOfWeek.saturday);
		default:
			return ignoreLanguage
				? 'wholeWeek'
				: t(translations.daysOfWeek.wholeWeek);
	}
};

export const convertStringToUpperCaseFirstChar = (str: string) => {
	const arr = str.toLowerCase().split(' ');
	for (let i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
	}
	const str2 = arr.join(' ');
	return str2;
};

export const getBase64Image = (
	img: Blob,
	callback: (arg0: string | ArrayBuffer | null) => any
) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
};
export const uuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const getMonthOfYear = (month: number, ignoreLanguage = false) => {
	switch (month) {
		case 1:
			return ignoreLanguage ? 'january' : t(translations.month.january);
		case 2:
			return ignoreLanguage ? 'february' : t(translations.month.february);
		case 3:
			return ignoreLanguage ? 'march' : t(translations.month.march);
		case 4:
			return ignoreLanguage ? 'april' : t(translations.month.april);
		case 5:
			return ignoreLanguage ? 'may' : t(translations.month.may);
		case 6:
			return ignoreLanguage ? 'june' : t(translations.month.june);
		case 7:
			return ignoreLanguage ? 'july' : t(translations.month.july);
		case 8:
			return ignoreLanguage ? 'august' : t(translations.month.august);
		case 9:
			return ignoreLanguage ? 'september' : t(translations.month.september);
		case 10:
			return ignoreLanguage ? 'october' : t(translations.month.october);
		case 11:
			return ignoreLanguage ? 'november' : t(translations.month.november);
		default:
			return ignoreLanguage ? 'december' : t(translations.month.december);
	}
};

export const encryptedString = (value: string) => {
	const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDh98tkn38BECET3a8PVkhOZ2/t
96YS7FBgqI8WGkztuemSK/6qfiPLjQbM5/4ZbckbJVHjirU+yp2ozxG0s7FxS+VI
+b7loP+DUPzojDoKCoQ/lJmMDizfK2FXq4UWmNg7pZ5mH8uhlmivI9aOXizhcpen
zyWi8p+SjdSRh4as5QIDAQAB
-----END PUBLIC KEY-----`;
	const key = new JSEncrypt({ default_key_size: '1024' });
	key.setPublicKey(PUBLIC_KEY);
	const encryptedValue = `${key.encrypt(value)}`;
	return encryptedValue;
};
