import { Avatar, Button, Icon } from 'components/atoms';
import Table, { EColumnType, IColumnProps } from 'components/atoms/Table';
import { getShortName } from 'helpers/StringHelper';
import { ICustomer } from 'models';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { I18n, _t, translations } from 'utils';
import { mdiPlus } from '@mdi/js';

const Customer = () => {
	const history = useHistory();
	const columns = useMemo<IColumnProps<ICustomer>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.customerList.name)),
				key: 'firstName',
				renderCell: (data) => {
					const customer = data;
					const customerName = `${customer.firstName || ''} ${
						customer.lastName || ''
					}`;
					return (
						<div className="align-middle flex">
							<div>
								<Avatar small name={getShortName(customerName)} />
							</div>
							<span className="m-auto ml-4">{customerName}</span>
						</div>
					);
				},
				sortable: true,
			},
			{
				header: I18n.t(_t(translations.customerList.mobile)),
				key: 'phone',
				type: EColumnType.PHONE_NUMBER,
			},
			{
				header: I18n.t(_t(translations.email)),
				key: 'email',
			},
			// {
			// 	header: I18n.t(_t(translations.customerList.address)),
			// 	key: 'id',
			// 	renderCell: (data) => {
			// 		const customer = data;
			// 		let address = '';
			// 		!isEmpty(customer.address)
			// 			? !isEmpty(customer.address.street) ||
			// 			  !isEmpty(customer.address.city) ||
			// 			  !isEmpty(customer.address.state)
			// 				? (address = `${customer.address.street}, ${customer.address.city}, ${customer.address.state}`)
			// 				: (address = '')
			// 			: (address = '');
			// 		return address;
			// 	},
			// },
			{
				header: I18n.t(_t(translations.customerList.dateOfBirth)),
				key: 'dateOfBirth',
				renderCell: (data) => {
					const dateOfBirth = data.dateOfBirth || '';
					return data.dateOfBirth && moment(dateOfBirth).format('LL');
				},
			},
			{
				header: I18n.t(_t(translations.loyaltyRank.earnedPoint)),
				key: 'earnPoint',
				type: EColumnType.NUMBER,
			},
			{
				header: I18n.t(_t(translations.loyaltyRank.loyaltyRank)),
				key: 'loyaltyRankName',
			},
			{
				header: `${I18n.t(_t(translations.customerList.totalExpense))}($)`,
				key: 'totalExpense',
				type: EColumnType.MONEY,
			},
			{
				header: `${I18n.t(_t(translations.customerList.lastExpense))}($)`,
				key: 'lastExpense',
				type: EColumnType.MONEY,
			},
			{
				header: `${I18n.t(_t(translations.customerList.numberOfVisit))}`,
				key: 'numberOfVisit',
				type: EColumnType.NUMBER,
			},
			// {
			// 	header: I18n.t(_t(translations.action)),
			// 	key: 'id',
			// 	renderCell: (data) => {
			// 		return (
			// 			<div className="flex gap-4 items-center">
			// 				<div
			// 					className="cursor-pointer rounded border border-[#aeaeae] p-2 bg-white"
			// 					onClick={(e) => {
			// 						e.stopPropagation();
			// 						history.push(`customer/edit/${data.id}`);
			// 					}}
			// 				>
			// 					<Icon path={mdiPencilOutline} className="w-6 h-6" />
			// 				</div>
			// 			</div>
			// 		);
			// 	},
			// },
		];
	}, []);

	return (
		<div className="px-2 h-full">
			<Table<ICustomer>
				queryUrl={'/Customer/get-all-customers'}
				renderHeader={
					<Button
						small
						primary
						title={I18n.t(_t(translations.addNew))}
						iconBefore={<Icon path={mdiPlus} />}
						onClick={() => history.push('/customer/add-new')}
					/>
				}
				columns={columns}
				showSearch
				searchPlaceHolder={'Search customer by Name or Phone...'}
				onClickRow={(data) => history.push(`customer/details/${data.id}`)}
				additionalParams={{ orderBy: 'firstName,lastName' }}
				// className="max-h-[calc(100vh-21.5rem)]"
			/>
		</div>
	);
};

export default Customer;
