/* eslint-disable react-hooks/exhaustive-deps */
import React, { DetailedHTMLProps, useEffect, useState } from 'react';
import RCTimePicker, { TimePickerProps } from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './styles.css';
import clsxm from 'clsxs/clsxm';
import moment, { Moment } from 'moment';
import { Icon } from './Icon';
import { mdiChevronRight } from '@mdi/js';

interface ITimePicker extends TimePickerProps {
	leftPopUp?: boolean;
	hideAmPm?: boolean;
}

export const TimePickerV2 = (props: ITimePicker) => {
	return (
		<RCTimePicker
			showSecond={false}
			use12Hours
			format="hh:mm A"
			{...props}
			className={clsxm(`w-full rounded-lg rc-picker ${props.className || ''}`)}
			popupClassName={clsxm(
				`rc-pop-up ${props.popupClassName || ''} ${
					props.hideAmPm ? 'rc-disabled-am-pm ' : ''
				} `,
				props.leftPopUp ? 'left' : ''
			)}
		/>
	);
};

export interface ITimeRangePickerProps
	extends Omit<
		DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
		'defaultValue'
	> {
	onChooseTime?: (time: Moment[]) => void;
	defaultValue?: Moment[];
	startProps?: TimePickerProps;
	endProps?: TimePickerProps;
	disabled?: boolean;
	minuteStep?: number;
	hideAmPm?: boolean;
}
export const TimeRangePickerV2 = (props: ITimeRangePickerProps) => {
	const { defaultValue, ...divProps } = props;
	const initialValue = [
		moment().startOf('date').hour(8),
		moment().startOf('date').hour(22),
	];
	const [choseTime, setChoseTime] = useState<Moment[]>(
		defaultValue || [
			props.startProps?.value || initialValue[0],
			props.endProps?.value || initialValue[1],
		]
	);
	const [isInitial, setIsInitial] = useState<boolean>(true);

	useEffect(() => {
		if (isInitial) {
			setIsInitial(false);
		} else {
			props.onChooseTime && props.onChooseTime(choseTime);
		}
	}, [choseTime]);

	const onChangeTime = (value: Moment, from = true) => {
		setChoseTime((prev) => {
			if (from) {
				// if (value.isAfter(prev[1])) {
				// 	return [prev[1], value];
				// }
				return [value, prev[1]];
			} else {
				// if (value.isBefore(prev[0])) {
				// 	return [value, prev[0]];
				// }
				return [prev[0], value];
			}
		});
	};

	return (
		<div {...divProps} className={clsxm('w-full flex gap-0 items-center')}>
			<RCTimePicker
				defaultValue={choseTime[0]}
				showSecond={false}
				use12Hours
				format="hh:mm A"
				{...props.startProps}
				className={clsxm(
					`w-full font-bold rc-picker m-0 ${props.className || ''}`
				)}
				popupClassName={clsxm(
					`rc-pop-up ${props.startProps?.popupClassName || ''} ${
						props.hideAmPm ? 'rc-disabled-am-pm ' : ''
					}`
				)}
				onChange={(value) => onChangeTime(value as Moment)}
				disabled={
					props.startProps?.disabled
						? props.startProps?.disabled
						: props.disabled
				}
				minuteStep={
					props.startProps?.minuteStep
						? props.startProps.minuteStep
						: props.minuteStep
				}
			/>
			<Icon path={mdiChevronRight} className="m-0 " />
			<RCTimePicker
				defaultValue={choseTime[1]}
				showSecond={false}
				use12Hours
				format="hh:mm A"
				{...props.endProps}
				className={clsxm(
					`w-full font-bold rc-picker m-0 ${props.className || ''}`
				)}
				popupClassName={clsxm(
					`rc-pop-up ${props.endProps?.popupClassName || ''}`,
					props.hideAmPm ? 'rc-disabled-am-pm ' : ''
				)}
				onChange={(value) => onChangeTime(value as Moment, false)}
				disabled={
					props.endProps?.disabled ? props.endProps?.disabled : props.disabled
				}
				minuteStep={
					props.endProps?.minuteStep
						? props.endProps.minuteStep
						: props.minuteStep
				}
			/>
		</div>
	);
};
