import { ReduxHelper } from 'helpers';
import { IEmployeeType } from 'models';
import {
	IEditEmployeeTypeRequest,
	IQueryEmployeeTypeRequest,
} from 'models/RequestModels';

export const prefix = 'EMPLOYEE_TYPE';

const getAllEmployeeTypes = ReduxHelper.generateActions<
	IQueryEmployeeTypeRequest | undefined,
	IEmployeeType[]
>(`${prefix}/GET_ALL_EMPLOYEE_TYPE`);

const addNewEmployeeType = ReduxHelper.generateLocalAction<IEmployeeType>(
	`${prefix}/ADD_NEW_EMPLOYEE_TYPE`
);

const selectCurrentEmployeeType =
	ReduxHelper.generateLocalAction<IEmployeeType>(
		`${prefix}/SELECT_CURRENT_EMPLOYEE_TYPE`
	);

const editEmployeeType = ReduxHelper.generateLocalAction<IEmployeeType>(
	`${prefix}/EDIT_EMPLOYEE_TYPE`
);

const deleteEmployeeType = ReduxHelper.generateActions<string, string>(
	`${prefix}/DELETE_EMPLOYEE_TYPE`
);

const getEmployeeTypeById = ReduxHelper.generateActions<
	string,
	IEmployeeType | null
>(`${prefix}/GET_SERVICE_BY_ID`);

const reset = ReduxHelper.generateLocalAction(`${prefix}/RESET`);

export {
	getAllEmployeeTypes,
	addNewEmployeeType,
	selectCurrentEmployeeType,
	editEmployeeType,
	deleteEmployeeType,
	getEmployeeTypeById,
	reset,
};
