import React, { createContext, FC, useContext, useState } from "react";
type TTab = "bookings" | "turns";

interface ITodayContext {
  tab?: TTab;
  staff: string;
  setTab: (tab: TTab) => void;
  setStaff: (staff: string) => void;
  setChartType: (type: number) => void;
  chartType: number;
}
const TodayContext = createContext<ITodayContext>({
  tab: "bookings",
  staff: "all",
  chartType: 1,
  setTab(tab) {
    return undefined;
  },
  setChartType(tab) {
    return undefined;
  },
  setStaff(staff) {
    return undefined;
  },
});
export const useTodayContext = () => {
  return useContext(TodayContext);
};

export const TodayProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [tab, setTab] = useState<TTab>("bookings");
  const [staff, setStaff] = useState("all");
  const [chartType, setChartType] = useState(1);

  const value = React.useMemo(
    () => ({
      tab,
      staff,
      setTab,
      setStaff,
      chartType,
      setChartType,
    }),
    [tab, staff, chartType]
  );
  return <TodayContext.Provider value={value}>{children}</TodayContext.Provider>;
};
