import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery, call } from 'redux-saga/effects';
import {
	IAddCategoryRequest,
	IEditCategoryRequest,
} from 'models/RequestModels';
import { CategoryActions } from '../actions';
import { IApiResponse, IErrorResponse, IItemCategory } from 'models';
import CategoryApiService from 'services/CategoryApiService';
import { AlertHelper } from 'helpers';

function* getAllCategory() {
	try {
		const result: IApiResponse<IItemCategory[]> = yield call(
			CategoryApiService.getAllCategory
		);
		if (result.succeeded) {
			yield put(CategoryActions.getAllCategory.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
			yield put(CategoryActions.getAllCategory.failed(error));
		}
	} catch (error) {
		yield put(CategoryActions.getAllCategory.failed(error));
	}
}

function* addCategory(action: PayloadAction<IAddCategoryRequest>) {
	try {
		const result: IApiResponse<IItemCategory> = yield call(
			CategoryApiService.addCategory,
			action.payload
		);
		if (result.succeeded) {
			yield put(CategoryActions.addCategory.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			AlertHelper.showError(errorResult);
			yield put(CategoryActions.addCategory.failed(errorResult));
		}
	} catch (error) {
		yield put(CategoryActions.addCategory.failed(error));
	}
}

function* editCategory(action: PayloadAction<IEditCategoryRequest>) {
	try {
		const result: IApiResponse<IItemCategory> = yield call(
			CategoryApiService.editCategory,
			action.payload
		);
		if (result.succeeded) {
			yield put(CategoryActions.editCategory.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			AlertHelper.showError(errorResult);
			yield put(CategoryActions.editCategory.failed(errorResult));
		}
	} catch (error) {
		yield put(CategoryActions.editCategory.failed(error));
	}
}

function* getCategoryById(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<IItemCategory> = yield call(
			CategoryApiService.getCategoryById,
			action.payload
		);
		if (result.succeeded) {
			yield put(CategoryActions.getCategoryById.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			AlertHelper.showError(errorResult);
			yield put(CategoryActions.getCategoryById.failed(errorResult));
		}
	} catch (error) {
		yield put(CategoryActions.getCategoryById.failed(error));
	}
}

function* deleteCategory(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<string> = yield call(
			CategoryApiService.deleteCategory,
			action.payload
		);
		if (result.succeeded) {
			yield put(CategoryActions.deleteCategory.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			AlertHelper.showError(errorResult);
			yield put(CategoryActions.deleteCategory.failed(errorResult));
		}
	} catch (error) {
		yield put(CategoryActions.deleteCategory.failed(error));
	}
}

export function* CategoryWatcher() {
	yield takeEvery(CategoryActions.getAllCategory.requestName, getAllCategory);
	yield takeEvery(CategoryActions.addCategory.requestName, addCategory);
	yield takeEvery(CategoryActions.editCategory.requestName, editCategory);
	yield takeEvery(CategoryActions.getCategoryById.requestName, getCategoryById);
	yield takeEvery(CategoryActions.deleteCategory.requestName, deleteCategory);
}
