import { SearchInput } from 'components/molecules/SearchInput';
import { useBookingHook } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { IService } from 'models';
import { Status } from 'models/Base.model';
import { IBookingSearchQuery } from 'models/RequestModels';
import qs from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { t, translations } from 'utils';
import { Service } from './Service';

interface IListServicesProps {
	selectedCategory: string;
}

export const ListServices = (props: IListServicesProps) => {
	const {
		bookingDetails,
		onSelectService,
		getAvailableStylistAndTimeBlocks,
		editBookingDetail,
		currentIndex,
		changeBookingDetail,
	} = useBookingHook();
	const services = useAppSelector((state) => state.ServiceReducer.services);
	const tempStylist = useAppSelector(
		(state) => state.BookingReducer.tempStylist
	);
	const listCategories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	const [lastEditService, setLastEditService] = useState(false);
	const [searchText, setSearchText] = useState<string>();
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const quickSale = queries.quickSale;
	let listServices = services
		.slice()
		.sort((sA, sB) =>
			(sA.indexNumber || 0) > (sB.indexNumber || 0)
				? 1
				: sA.indexNumber === sB.indexNumber
				? 0
				: -1
		)
		.filter(
			(x) =>
				!_.isEmpty(x.itemCategoryId) &&
				listCategories
					.filter((x) => x.status === Status.ACTIVE)
					.map((x) => x.id)
					.includes(x.itemCategoryId) &&
				(props.selectedCategory === 'all' ||
					x.itemCategoryId === props.selectedCategory) &&
				(_.isEmpty(searchText) ||
					x.name
						.toLocaleLowerCase()
						.includes(`${searchText || ''}`.toLocaleLowerCase()))
		);
	if (tempStylist) {
		listServices = listServices.filter((x) =>
			tempStylist.serviceIds?.includes(x.id)
		);
	}
	if (props.selectedCategory === 'all') {
		listServices = _.orderBy(listServices, (x) => x.name);
	} else {
		listServices = _.orderBy(listServices, 'itemCategoryId');
	}
	const onSelect = (service: IService) => {
		if (currentIndex !== undefined) {
			if (bookingDetails[currentIndex].itemId === service.id) {
				setLastEditService(false);
				return;
			}
		}
		if (editBookingDetail && !lastEditService && !quickSale) {
			getAvailableStylistAndTimeBlocks(
				{
					serviceId: service.id,
				},
				currentIndex,
				true
			);
			changeBookingDetail(
				{
					item: service,
					itemId: service.id,
				},
				currentIndex
			);
			setLastEditService(true);
		} else {
			onSelectService(service);
			setLastEditService(false);
			setTimeout(() => {
				if (!_.isEmpty(bookingDetails[0].item)) {
					getAvailableStylistAndTimeBlocks(
						{
							serviceId: service.id,
						},
						bookingDetails.length,
						true
					);
				}
			}, 200);
		}
	};
	return (
		<div className="col-span-5 flex flex-1 flex-col pl-4 p-4 bg-white rounded-r-lg overflow-hidden ">
			<div className="flex justify-between align-middle mb-2 ">
				<h3 className="mt-1">{t(translations.mainBooking.services)}</h3>
				<div className="w-1/2">
					<SearchInput
						placeholder={t(translations.placeHolder.searchService)}
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
					/>
				</div>
			</div>
			<div className="flex-1 overflow-y-auto pb-2">
				<div className="grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5  gap-2 w-full  ">
					{listServices.map((x) => {
						return <Service onSelect={() => onSelect(x)} service={x} />;
					})}
				</div>
			</div>
		</div>
	);
};
