import { IUser } from 'models';
import { BaseApiService } from 'services/BaseApiService';

class UserApiService extends BaseApiService {
	public getCurrentUserInfo = () => this.get<IUser>(`/User/get-current-user/`);
	public resetUserPassword = (request: {userId: string, adminPassword: string}) =>
		this.put<any, string>(`/User/reset-password-user`, request);
}

export default new UserApiService();
