/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from 'components/atoms/Container';
import { LoadingProvider } from 'contexts/LoadingContext';
import { TimeHelper } from 'helpers';
import { useBookingHook } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { IBookingDetail } from 'models';
import { IBookingSearchQuery } from 'models/RequestModels';
import moment from 'moment';
import { BookingHeader } from 'pages/Booking/Header';

import { MainBooking } from 'pages/Booking/MainBooking';
import { SideBooking } from 'pages/Booking/SideBooking';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { BookingActions } from 'redux/actions';

export const Booking = () => {
	const params = useParams<{ id: string }>();
	const employees = useAppSelector((state) => state.EmployeeReducer.employees);
	const dispatch = useDispatch();

	const {
		getBookingById,
		currentBooking,
		getAvailableStylistAndTimeBlocks,
		changeBookingDetail,
		selectStartTime,
		changeDate,
		selectStylist,
	} = useBookingHook();
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const bookingAction = useAppSelector(
		(state) => state.ReduxActionReducer['BOOKING']
	);
	useEffect(() => {
		if (!_.isEmpty(params.id)) {
			getBookingById(params.id);
		} else {
			// dispatch(BookingActions.resetBooking.request());
		}
	}, []);
	useEffect(() => {
		if (currentBooking && !_.isEmpty(currentBooking)) {
			setTimeout(() => {
				getAvailableStylistAndTimeBlocks({}, 0, false, true);
			}, 200);
		}
	}, [currentBooking]);
	useEffect(() => {
		if (!_.isEmpty(queries)) {
			const bookingDetail: Partial<IBookingDetail> = {
				startAtExpected: !_.isEmpty(queries?.date)
					? TimeHelper.toTimeZone(
							moment(queries?.date, 'MM-DD-YYYY').toDate()
					  ).toDate()
					: TimeHelper.toTimeZone(moment().toDate()).toDate(),
				stylistId: queries?.stylistId,
			};
			if (!_.isEmpty(queries.date)) {
				changeDate(moment(queries?.date, 'MM-DD-YYYY'));
			}
			if (!_.isEmpty(queries.stylistId)) {
				selectStylist(employees?.find((x) => x.id === queries.stylistId));
			}
			changeBookingDetail(bookingDetail);
			if (!_.isEmpty(queries?.time)) {
				const timeInTimeZone = moment(queries?.time, 'HHmm').format(
					`${moment(queries?.date, 'MM-DD-YYYY').format('YYYY-MM-DD')}THH:mm`
				);

				const timeInUtc = TimeHelper.fromTimeZoneStringToUtc(timeInTimeZone);
				selectStartTime(timeInUtc);
			}
		}
	}, []);

	return (
		<Container className="px-0 h-screen overflow-hidden">
			<BookingHeader />
			<LoadingProvider
				loading={bookingAction === BookingActions.getBookingById.requestName}
				className="grid h-full grid-cols-4 gap-4 mt-16 overflow-hidden "
			>
				<MainBooking />
				<SideBooking />
			</LoadingProvider>
		</Container>
	);
};
