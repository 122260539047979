/* eslint-disable react-hooks/exhaustive-deps */
import {
	mdiBrightnessPercent,
	mdiChevronTripleDown,
	mdiClose,
	mdiPlus,
	mdiTagPlus,
	mdiTicketPercent,
	mdiWalletGiftcard,
} from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Icon, TextArea } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { SaleGiftCardModal } from 'components/molecules/SaleGiftCard';
import { CurrencyHelper, StringHelper } from 'helpers';
import _ from 'lodash';
import { IBillingDetail, IGiftCard } from 'models';
import { DiscountModal } from 'pages/CheckOut/components/Modals/DiscountModal';
import { TipModal } from 'pages/CheckOut/components/Modals/TipModal';
import { useCheckOutContext } from 'pages/CheckOut/context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GiftCardApiService from 'services/GiftCardApiService';
import { t, translations } from 'utils';
import { ApplyCouponModal } from './components/Modals/ApplyCouponModal';
import { PromotionModal } from './components/Modals/PromotionModal';
import Joyride, {
	ACTIONS,
	CallBackProps,
	EVENTS,
	STATUS,
	Step,
} from 'react-joyride';
import { useAppSelector } from 'helpers/hookHelpers';
import { useDispatch } from 'react-redux';
import { PRIMARY_COLOR } from 'utils/Colors';
import { checkoutTour } from 'models/TourGuide';
import { AppConfigActions } from 'redux/actions';
import { TipType, tourName, TourSteps } from 'utils/Consts';

type modalType =
	| 'tip'
	| 'sale_gift_card'
	| 'discount'
	| 'promotion'
	| 'apply_coupon';

const GiftCardDetail = (props: {
	giftCard: Partial<IGiftCard>;
	index: number;
	isLast?: boolean;
}) => {
	const { giftCard, index, isLast } = props;
	const { giftCards = [], setGiftCards, done } = useCheckOutContext();
	return (
		<li
			data-label={`${index + 1}`}
			className={clsxm(
				'relative border-base-200 flex gap-2 justify-between m-0 w-full ',
				isLast && 'rounded-b-lg',
				index === 0 && 'rounded-t-lg'
			)}
		>
			<div
				className={clsxm(
					'flex flex-1 flex-col border border-t-0 border-base-200 bg-white px-4 py-1 items-center',
					isLast && 'rounded-b-lg',
					index === 0 && 'rounded-t-lg border-t'
				)}
			>
				<div className={'w-full flex justify-between items-center '}>
					<div className="flex gap-2 ">
						<span className="p-0 font-semibold text-sm ">
							{`${t(translations.checkout.giftCard)}/`}{' '}
							<span className="text-base-300">{`${t(
								translations.checkout.balance
							)}: ${CurrencyHelper.formatPrice(giftCard?.balance)}`}</span>
						</span>
					</div>
					<div className="flex flex-col items-end">
						<span className="p-0 font-semibold text-sm ">{`${CurrencyHelper.formatPrice(
							giftCard?.amount
						)}`}</span>
						{/* <Icon path={mdiDelete} className="text-DELETED" /> */}
					</div>
				</div>
			</div>
			{!done && (
				<div
					onClick={() => {
						setGiftCards(giftCards?.filter((x) => x.id !== giftCard.id));
						GiftCardApiService.deleteGiftCard(`${giftCard.id}`);
					}}
					className="text-xs w-[120px] cursor-pointer justify-center text-error bg-white flex gap-1 items-center my-1 border rounded-md p-1 active:animate-pop"
				>
					{<Icon size={0.8} path={mdiClose} />}
					{t(translations.remove)}
				</div>
			)}
		</li>
	);
};

const BillDetail = (props: {
	billDetail: Partial<IBillingDetail>;
	index: number;
	isLast?: boolean;
	billCombineId?: string;
}) => {
	const { editExtra, done } = useCheckOutContext();
	const { billDetail, index, isLast } = props;
	const [showAddExtra, setShowAddExtra] = useState(false);
	const [showNumpad, setShowNumpad] = useState(false);
	const [reason, setReason] = useState<string>();
	const onConfirm = (value: string) => {
		editExtra &&
			editExtra(
				index,
				{
					amount: parseFloat(value),
					note: reason,
				},
				props.billCombineId
			);
		setShowAddExtra(false);
	};
	console.log('@INDEX', index);
	const onDeleteExtra = () => {
		editExtra && editExtra(index, undefined, props.billCombineId);
	};

	return (
		<>
			<li
				data-label={`${index + 1}`}
				className={clsxm(
					'relative border-base-200 flex gap-2 justify-between m-0 w-full  ',
					isLast && 'rounded-b-lg',
					index === 0 && 'rounded-t-lg'
				)}
			>
				<div
					className={clsxm(
						'flex flex-1 flex-col border border-t-0 border-base-200 bg-white px-4 py-1 items-center',
						isLast && 'rounded-b-lg',
						index === 0 && 'rounded-t-lg border-t'
					)}
				>
					<div className={'w-full flex justify-between items-center'}>
						<div className="flex gap-2 ">
							<span className="p-0 font-semibold text-sm ">
								{billDetail.item?.name}
							</span>
							<span className="p-0 text-sm ">{`(${t(
								translations.checkout.stylist
							)} ${
								billDetail.stylist
									? billDetail.stylist.firstName
									: t(translations.mainBooking.notChoose)
							})`}</span>
						</div>
						<div className="flex gap-2">
							{(billDetail.discount ||
								billDetail.promotionDiscount ||
								billDetail.discount !== 0 ||
								billDetail.promotionDiscount !== 0) && (
								<span className="p-0 font-semibold line-through text-base-300 text-sm">{`${CurrencyHelper.formatPrice(
									billDetail.amount
								)}`}</span>
							)}
							<span className="p-0 font-semibold text-sm ">{`${CurrencyHelper.formatPrice(
								(billDetail.amount || 0) -
									((billDetail.discount || 0) +
										(billDetail.promotionDiscount || 0))
							)}`}</span>
						</div>
					</div>
					{billDetail.extraAmount && billDetail.extraAmount !== 0 ? (
						<div className="w-full flex justify-between items-center">
							<div className="flex gap-2 items-center ">
								<div className="flex gap-2 pl-10">
									<span className="p-0 font-light text-sm uppercase ">
										{billDetail.note}
									</span>
								</div>
							</div>
							<div className="flex flex-col items-end">
								<span className="p-0 font-semibold text-sm ">{`${CurrencyHelper.formatPrice(
									billDetail.extraAmount
								)}`}</span>
							</div>
						</div>
					) : undefined}
				</div>
				{!done && (
					<>
						{!billDetail.extraAmount || billDetail.extraAmount === 0 ? (
							<div className="flex justify-start">
								<div
									id="add-extra-btn"
									onClick={() => {
										setShowAddExtra(true);
									}}
									className="text-xs w-[120px] justify-center cursor-pointer text-black bg-[#99FFB8] flex gap-1 items-center my-1 border border-CHECKED_IN rounded-md p-1 active:animate-pop"
								>
									{<Icon size={0.8} path={mdiPlus} />}
									{t(translations.checkout.addExtra)}
								</div>
							</div>
						) : (
							<div
								onClick={() => onDeleteExtra()}
								className="text-xs  w-[120px] justify-center cursor-pointer text-error bg-white flex gap-1 items-center my-1 border rounded-md p-1 active:animate-pop"
							>
								{<Icon size={0.8} path={mdiClose} />}
								{`${t(translations.remove)} ${t(
									translations.checkout.extraAmount
								)}`}
							</div>
						)}
					</>
				)}
			</li>
			{showAddExtra && (
				<ModalNumPad
					onCancel={() => {
						setShowAddExtra(false);
					}}
					unit="Usd"
					isMoney
					title={t(translations.checkout.addExtra)}
					showNumpad={showNumpad}
					disabled={_.isEmpty(reason)}
					key={`${props.billDetail.id}`}
					onConfirm={onConfirm}
				>
					<div className="p-4">
						<TextArea
							id="reason"
							onChange={(e) => setReason(e.target.value)}
							onFocus={() => setShowNumpad(false)}
							// onBlur={() => {
							// 	if (!_.isEmpty(reason)) {
							// 		setShowNumpad(true);
							// 	}
							// }}
							autoFocus
							label={t(translations.checkout.addExtraReason)}
						/>
						<div className="flex justify-end mt-4">
							<Button
								id="enter_amount"
								disabled={_.isEmpty(reason)}
								onClick={() => {
									if (!_.isEmpty(reason)) {
										setShowNumpad(true);
									}
								}}
								className="text-white"
								small
								title={t(translations.checkout.enterAmount)}
							/>
						</div>
					</div>
				</ModalNumPad>
			)}
		</>
	);
};

const ListDetails = (props: {
	type: 'booking' | 'giftCard';
	billCombineId?: string;
	childBillDetails?: Partial<IBillingDetail>[];
}) => {
	const { billDetails = [], giftCards = [] } = useCheckOutContext();

	// if (!billDetails) {
	// 	return null;
	// }
	let list =
		props.billCombineId && props.childBillDetails
			? props.childBillDetails
			: props.type === 'booking'
			? billDetails
			: giftCards;
	return (
		<ul className="rounded-lg  text-gray-900 list-none pl-0 mt-2 ">
			{list.map((x, i) => {
				return props.type === 'booking' ? (
					<BillDetail
						billDetail={x}
						index={i}
						billCombineId={props.billCombineId}
						isLast={i === list.length - 1}
					/>
				) : (
					<GiftCardDetail
						index={i}
						isLast={i === list.length - 1}
						giftCard={x}
					/>
				);
			})}
		</ul>
	);
};

const ListButtons = () => {
	const [modal, setModal] = useState<modalType>();
	const {
		tip,
		disableDiscount,
		setGiftCards,
		giftCards,
		bill,
		applyPromotion,
		done,
		tipType,
	} = useCheckOutContext();
	const params = useParams<{ id: string }>();
	useEffect(() => {
		if (params.id === 'sale-giftcard') {
			setModal('sale_gift_card');
		}
	}, []);

	return (
		<div className="list-top-checkout-btn w-full flex flex-wrap gap-2 justify-between">
			<Button
				id="sale-gift-card-btn"
				title={t(translations.checkout.saleGiftCard)}
				onClick={() => setModal('sale_gift_card')}
				white={!done}
				small
				disabled={done}
				iconBefore={<Icon path={mdiWalletGiftcard} />}
			/>

			<div className="divider divider-horizontal mx-0.5" />
			<Button
				id="add-tip-btn"
				onClick={() => setModal('tip')}
				title={
					tip
						? t(translations.checkout.changeTip)
						: t(translations.checkout.addTip)
				}
				white={!done || _.isEmpty(tip) || tipType === TipType.MANUALLY}
				small
				className={clsxm(!bill && 'invisible')}
				iconBefore={<Icon path={mdiTagPlus} />}
				disabled={done && !_.isEmpty(tip) && tipType !== TipType.MANUALLY}
			/>
			<Button
				id="discount-btn"
				title={t(translations.checkout.discount)}
				disabled={disableDiscount('discount') || done}
				onClick={() => setModal('discount')}
				white={!disableDiscount('discount') && !done}
				small
				className={clsxm(!bill && 'invisible')}
				iconBefore={<Icon path={mdiChevronTripleDown} />}
			/>
			<Button
				id="promotions-btn"
				title={t(translations.checkout.promotion)}
				white={!done && !disableDiscount('discount')}
				small
				className={clsxm(!bill && 'invisible')}
				onClick={() => setModal('promotion')}
				disabled={done || disableDiscount('discount')}
				iconBefore={<Icon path={mdiBrightnessPercent} />}
			/>
			<Button
				id="apply-coupon-btn"
				title={t(translations.customerBooking.applyCoupon)}
				white={!done}
				small
				onClick={() => setModal('apply_coupon')}
				className={clsxm(!bill && 'invisible')}
				disabled={done}
				iconBefore={<Icon path={mdiTicketPercent} />}
			/>
			{modal === 'tip' && <TipModal onCancel={() => setModal(undefined)} />}
			{modal === 'discount' && (
				<DiscountModal onCancel={() => setModal(undefined)} />
			)}
			{modal === 'sale_gift_card' && (
				<SaleGiftCardModal
					onConfirm={(giftCard) => {
						if (giftCard) {
							const newGiftCards = giftCards?.concat([giftCard]) || [];
							setGiftCards(newGiftCards);
							setModal(undefined);
						}
					}}
					onCancel={() => setModal(undefined)}
					chooseType
				/>
			)}
			{modal === 'promotion' && (
				<PromotionModal
					onCancel={() => setModal(undefined)}
					onOk={(promotion) => {
						applyPromotion(promotion);
						setModal(undefined);
					}}
				/>
			)}
			{modal === 'apply_coupon' && (
				<ApplyCouponModal
					onCancel={() => setModal(undefined)}
					onOk={(promotion) => {
						applyPromotion(promotion);
						setModal(undefined);
					}}
				/>
			)}
		</div>
	);
};

export const BillDetails = () => {
	const { childrenBills, done, setChildrenBills } = useCheckOutContext();
	return (
		<div className="col-span-1 overflow-hidden pb-16 pl-4 ">
			<ListButtons />
			<div className="divider mx-4 my-1" />
			<div id="list-bookings-and-gift-cards">
				<ListDetails type="booking" />
				<ListDetails type="giftCard" />
				{
					<>
						{childrenBills?.map((x) => {
							return (
								<div key={x.id}>
									<div className="w-full flex justify-between">
										<span>
											<strong>{`${x.customer?.firstName || ''} ${
												x.customer?.lastName || ''
											}`}</strong>{' '}
											<span className="text-xs">
												{`(${StringHelper.formatPhoneNumber(
													x.customer?.phone
												)})`}
											</span>
										</span>
										{!done && (
											<div
												onClick={() => {
													setChildrenBills(
														childrenBills.filter((bill) => bill.id !== x.id)
													);
												}}
												className="text-xs  w-[120px] justify-center cursor-pointer text-error bg-white flex gap-1 items-center border rounded-md p-1 active:animate-pop"
											>
												{<Icon size={0.8} path={mdiClose} />}
												{`${t(translations.checkout.deleteBill)}`}
											</div>
										)}
									</div>
									<ListDetails
										childBillDetails={x.billDetails || []}
										type="booking"
										billCombineId={x.id}
									/>
								</div>
							);
						})}
					</>
				}
			</div>
		</div>
	);
};
