import { IRole } from 'models';
import { BaseApiService } from 'services/BaseApiService';
import { IRoleRequest } from 'models/RequestModels';

class RoleApiService extends BaseApiService {
	public getAllRoles = (query?: IRoleRequest) =>
		this.get<IRole[]>(`/Role/get-all-roles`, {
			params: query,
		});
}

export default new RoleApiService();
