import { Avatar } from 'components/atoms';
import Table, { IColumnProps, EColumnType } from 'components/atoms/Table';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { TDateRange } from 'components/molecules/ModalCalendar';
import { t, translations } from 'utils';
import React, { useState } from 'react';
import moment from 'moment';
import { IBilling } from 'models/IBilling';
import { CurrencyHelper, StringHelper } from 'helpers';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export const BillsManagement = () => {
	const history = useHistory();
	const [dateFilter, setDateFilter] = useState<Required<TDateRange>>({
		fromDate: moment().startOf('date').subtract(7, 'day'),
		toDate: moment().startOf('date'),
	});

	const billNumberCol: IColumnProps<IBilling> = {
		header: t(translations.billManagement.billNumber),
		key: 'billNumber',
		sortable: true,
	};

	const customerCol: IColumnProps<IBilling> = {
		header: t(translations.listBooking.customerName),
		key: 'customer',
		renderCell: (data) => {
			return (
				<div className="flex align-middle justify-between pl-2 ">
					<div>
						<Avatar small name={StringHelper.getShortName(data.customerName)} />
					</div>
					<span className="m-auto ml-4">{data.customerName}</span>
				</div>
			);
		},
	};

	const dateCol: IColumnProps<IBilling> = {
		header: t(translations.listBooking.date),
		key: 'date',
		renderCell: (data) => {
			return moment(data.date).format('MMMM Do, YYYY');
		},
		sortable: true,
	};

	const servicesCol: IColumnProps<IBilling> = {
		key: 'billDetails',
		header: t(translations.listBooking.services),
		renderCell: (data) => {
			return (
				<div className="align-middle flex flex-col">
					{data.billDetails.map((billDetail) => {
						return (
							<>
								<p className="font-bold m-0 p-0">{billDetail.item?.name}</p>
								{/* <p className="m-0 p-0">
									{_.isEmpty(billDetail.item)
										? ''
										: _.isEmpty(billDetail.stylist)
										? `${billDetail.item?.duration} minutes`
										: `${billDetail.item?.duration} minutes with ${
												billDetail.stylist!.firstName
										  } ${billDetail.stylist!.lastName}`}
								</p> */}
							</>
						);
					})}
				</div>
			);
		},
	};

	const technicianCol: IColumnProps<IBilling> = {
		key: 'stylistId',
		header: t(translations.listBooking.technician),
		renderCell: (data) => {
			const technician = _.uniqBy(
				data!.billDetails,
				(element) => element.stylist?.id
			).map(
				(techincian) =>
					`${techincian.stylist?.firstName || ''} ${
						techincian.stylist?.lastName || ''
					}`
			);
			return <p>{technician.join(', ')}</p>;
		},
	};

	const totalPayAmountCol: IColumnProps<IBilling> = {
		header: t(translations.checkout.totalPay) + `(${t(translations.currency)})`,
		key: 'realAmount',
		renderCell: (data) => (
			<p className="text-right font-bold">
				{CurrencyHelper.formatPrice(data.realAmount)}
			</p>
		),
		sortable: true,
		type: EColumnType.MONEY,
	};

	return (
		<div className="overflow-hidden h-full flex flex-col p-4 bg-white">
			<Table<IBilling>
				columns={[
					billNumberCol,
					customerCol,
					dateCol,
					servicesCol,
					technicianCol,
					totalPayAmountCol,
				]}
				onClickRow={(bill) => {
					if (bill.paymentStatus === 3) {
						history.push(`/bills/${bill.id}`);
					} else {
						history.push(`/check-out/${bill.id}`);
					}
				}}
				queryUrl={'Billing/get-all-bills'}
				additionalParams={{
					fromDate: dateFilter.fromDate.format('YYYY-MM-DD'),
					toDate: dateFilter.toDate.format('YYYY-MM-DD'),
					orderBy: 'date desc, billNumber desc',
				}}
				containerClassName={'h-full flex flex-col overflow-hidden'}
				// className={'overflow-y-auto max-h-[calc(100vh-21.5rem)]'}
				className={'overflow-y-auto'}
				showSearch
				renderHeader={
					<DateRangePicker
						onSelectDateRange={(value) =>
							setDateFilter(value as Required<TDateRange>)
						}
						selectedDateRange={dateFilter}
					/>
				}
			/>
		</div>
	);
};
