import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { TodayActions } from '../actions';
import { IEmployee, IPoolStaff } from 'models';
import _ from 'lodash';

export interface ITodayReducer {
	poolStaffs: IPoolStaff[];
}

const initialState: ITodayReducer = {
	poolStaffs: [],
};

const socketAddPoolStaffs = (
	state: ITodayReducer,
	action: PayloadAction<IPoolStaff>
) => {
	if (
		_.isEmpty(
			_.find(
				state.poolStaffs,
				(x) => x.employee.id === action.payload.employee.id
			)
		)
	) {
		state.poolStaffs.unshift(action.payload);
	}
};

const socketRemovePoolStaffs = (
	state: ITodayReducer,
	action: PayloadAction<IPoolStaff>
) => {
	const newPoolStaffs = _.clone(state.poolStaffs);
	_.remove(newPoolStaffs, (x) => x.employee.id === action.payload.employee.id);
	state.poolStaffs = newPoolStaffs;
};

const getPoolStaffs = (
	state: ITodayReducer,
	action: PayloadAction<IPoolStaff[]>
) => {
	state.poolStaffs = action.payload;
};

const TodayReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(TodayActions.getPoolStaffs.success, getPoolStaffs)
		.addCase(TodayActions.socketAddPoolStaffs.request, socketAddPoolStaffs)
		.addCase(
			TodayActions.socketRemovePoolStaffs.request,
			socketRemovePoolStaffs
		)
);

export default TodayReducer;
