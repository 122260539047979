import React, { useMemo } from 'react';
import { t, translations } from 'utils';
import { IPayrollOverviewDetail, IPayrollDetailDetail } from 'models';
import moment from 'moment';
import { usePayrollDetailContext } from './payrollDetailContext';
import _ from 'lodash';
import { LoadingProvider } from 'contexts/LoadingContext';
import { CurrencyHelper } from 'helpers';

export const DetailTable = () => {
	const { loading, report, isDetail } = usePayrollDetailContext();
	const data = useMemo<[IPayrollOverviewDetail | IPayrollDetailDetail]>(() => {
		if (!_.isEmpty(report)) {
			if (isDetail) {
				return (report![1].payrollDetailDetails || []) as [
					IPayrollOverviewDetail | IPayrollDetailDetail
				];
			}
			const ordered = _.orderBy(
				report![0].payrollOverviewDetails,
				[
					(x) => {
						console.log('!@#!@#!@#@', moment(x.date).unix(), x.date);
						return moment(x.date).unix();
					},
					(x) => x.turn,
				],
				['asc', 'asc']
			);
			return (ordered || []) as [IPayrollOverviewDetail | IPayrollDetailDetail];
		} else {
			return [] as unknown as [IPayrollOverviewDetail | IPayrollDetailDetail];
		}
	}, [report, isDetail]);

	return (
		<div className="overflow-x-auto w-full">
			<LoadingProvider loading={loading}>
				<table className="table table-zebra w-full">
					<thead>
						<tr>
							<th className={'relative'}>
								{isDetail ? (
									<th></th>
								) : (
									t(translations.payrollDetailReport.date)
								)}
							</th>
							<th className={'relative'}>
								{isDetail
									? t(translations.payrollDetailReport.turn)
									: t(translations.payrollDetailReport.weekday)}
							</th>
							<th className={'relative'}>
								{isDetail
									? t(translations.payrollDetailReport.weekday)
									: t(translations.payrollDetailReport.turn)}
							</th>
							<th className={'relative text-right'}>
								{t(translations.payrollDetailReport.income)}
							</th>
							<th className={'relative text-right'}>
								{t(translations.payrollDetailReport.salary)}
							</th>
							<th className={'relative text-right'}>
								{t(translations.payrollDetailReport.supplyFee)}
							</th>
							<th className={'relative text-right'}>
								{t(translations.payrollDetailReport.commision)}
							</th>
							<th className={'relative text-right'}>
								{t(translations.payrollDetailReport.tips)}
							</th>
							<th className={'relative text-right'}>
								{t(translations.payrollDetailReport.discount)}
							</th>
							<th className={'relative text-right'}>
								{t(translations.payrollDetailReport.chargeFee)}
							</th>
							<th className={'relative text-right'}>
								{t(translations.payrollDetailReport.refund)}
							</th>
							<th className={'relative text-right'}>
								{t(translations.payrollDetailReport.totalCommSalary)}
							</th>
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) => {
							return (
								<tr
									className={
										'cursor-pointer active:animate-pop duration-200 ease-in'
									}
								>
									<td>
										{isDetail ? index + 1 : moment(row.date).format('MMM Do')}
									</td>
									<td>
										{isDetail ? row.turn || 0 : moment(row.date).format('dddd')}
									</td>
									<td>
										{isDetail ? moment(row.date).format('dddd') : row.turn}
									</td>
									<td className="text-right">
										{CurrencyHelper.formatBalance(row.income)}
									</td>
									<td className="text-right">
										{CurrencyHelper.formatBalance(row.salary)}
									</td>
									<td className="text-right">
										{CurrencyHelper.formatBalance(row.supplyFee)}
									</td>
									<td className="text-right">
										{CurrencyHelper.formatBalance(row.commission)}
									</td>
									<td className="text-right">
										{CurrencyHelper.formatBalance(row.tip)}
									</td>
									<td className="text-right">
										{CurrencyHelper.formatBalance(row.discount)}
									</td>
									<td className="text-right">
										{CurrencyHelper.formatBalance(row.chargeFee)}
									</td>
									<td className="text-right">
										{CurrencyHelper.formatBalance(row.refund)}
									</td>
									<td className="text-right">
										{CurrencyHelper.formatBalance(row.salaryPay)}
									</td>
								</tr>
							);
						})}
						<td key={0} />
						<td key={1} className={'label-text text-sm font-bold'}>
							{t(translations.payrollDetailReport.total)}
						</td>
						<td key={2} className={'label-text text-sm font-bold'}>
							{isDetail ? '' : report![0].totalTurn}
						</td>
						<td key={3} className={'label-text text-sm font-bold text-right'}>
							{CurrencyHelper.formatPrice(report![0].totalIncome)}
						</td>
						<td key={4} className={'label-text text-sm font-bold text-right'}>
							{CurrencyHelper.formatPrice(report![0].totalSalary)}
						</td>
						<td key={4} className={'label-text text-sm font-bold text-right'}>
							{CurrencyHelper.formatPrice(report![0].totalSupplyFee)}
						</td>
						<td key={5} className={'label-text text-sm font-bold text-right'}>
							{CurrencyHelper.formatPrice(report![0].totalCommission)}
						</td>
						<td key={6} className={'label-text text-sm font-bold text-right '}>
							{CurrencyHelper.formatPrice(report![0].totalTip)}
						</td>
						<td key={7} className={'label-text text-sm font-bold text-right'}>
							{CurrencyHelper.formatPrice(report![0].totalDiscount)}
						</td>
						<td key={8} className={'label-text text-sm font-bold text-right'}>
							{CurrencyHelper.formatPrice(report![0].totalChargeFee)}
						</td>
						<td key={9} className={'label-text text-sm font-bold text-right'}>
							{CurrencyHelper.formatPrice(report![0].totalRefund)}
						</td>
						<td key={10} className={'label-text text-sm font-bold text-right'}>
							{CurrencyHelper.formatPrice(report![0].totalSalaryPay)}
						</td>
					</tbody>
				</table>
			</LoadingProvider>
		</div>
	);
};
