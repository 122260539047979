import clsxm from "clsxs/clsxm";
import { Card, Input } from "components/atoms";
import React from "react";
import { useFormContext } from "react-hook-form";
import { t, translations } from "utils";
import { UpdateBranchFormField, IConfigBaseProps } from "./IConfigBaseProps";

interface ITaxConfigProps extends IConfigBaseProps {}
export const TaxConfigs = ({ isLoading }: ITaxConfigProps) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<UpdateBranchFormField>();
  return (
    <Card
      title={t(translations.branchSetting.tax)}
      description={t(translations.branchSetting.subTax)}
      accordion={true}		
      isDefaultAccordion={true}
      className="col-span-3"
    >
      <div className={clsxm("flex gap-5")}>
        {/* <div className={clsxm("grid grid-cols-4 gap-x-8")}> */}
          <div>
            <Input
              label={t(translations.branchSetting.taxRate)}
              error={errors.taxPercent?.message}
              renderAfter={<>%</>}
              {...register("taxPercent", { disabled: isLoading })}
            />
          </div>
          <div>
            <Input
              label={t(translations.branchSetting.taxCode)}
              error={errors.taxCode?.message}
              {...register("taxCode", { disabled: isLoading })}
            />
          </div>
        {/* </div> */}
      </div>
    </Card>
  );
};
