import { EBookingInputFrom, EBookingType } from 'models';
import React from 'react';
import { mdiWalk, mdiCalendar, mdiCloud } from '@mdi/js';
import { Icon } from 'components/atoms/Icon';
import { ColorHelper } from 'helpers';

export const BookingTypeIcon = (props: {
	type?: EBookingType;
	inputFrom?: EBookingInputFrom;
	status?: number;
	color?: string;
	size?: number;
	className?: string;
}) => {
	const icon = () => {
		if (props.type === EBookingType.WalkIn) {
			return mdiWalk;
		}
		switch (props.inputFrom) {
			case EBookingInputFrom.OnlineSite:
				return mdiCloud;
			case (EBookingInputFrom.StylistApp,
			EBookingInputFrom.AdminWebsite,
			EBookingInputFrom.POS):
				return mdiCalendar;
			case EBookingInputFrom.Other:
				return mdiWalk;
			default:
				return mdiCalendar;
		}
	};
	return (
		<Icon
			color={
				props.status
					? `${ColorHelper.getStatusColor(props.status)}`
					: props.color && props.color
			}
			size={props.size}
			path={icon()}
			className={props.className}
		/>
	);
};
