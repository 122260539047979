import { mdiContentSave, mdiDelete } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, ConfirmModal, Icon } from 'components/atoms';
import { LoadingProvider } from 'contexts/LoadingContext';
import React, { useState } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { t, translations } from 'utils';
import { Header } from './Header';

interface IDetailPageLayoutProps<FormField extends FieldValues = any> {
	loading?: boolean;
	title?: string;
	form?: UseFormReturn<FormField>;
	children?: React.ReactNode;
	onSave?: () => void;
	onDelete?: () => void;
	showDelete?: boolean;
	deleteTitle?: string;
	deleteSubTitle?: string;
	onClose?: () => void;
	extraRight?: React.ReactNode;
	extraLeft?: React.ReactNode;
	extraRightHeader?: React.ReactNode;
	extraLeftHeader?: React.ReactNode;
	noFooter?: boolean;
	saveButtonTitle?: string;
	hideSave?: boolean;
}
export const DetailPageLayout = <FormField extends object>({
	form,
	children,
	loading,
	title,
	onSave,
	onDelete,
	showDelete,
	deleteTitle,
	deleteSubTitle,
	onClose,
	extraRight,
	extraLeft,
	extraRightHeader,
	extraLeftHeader,
	noFooter,
	saveButtonTitle,
	hideSave,
}: IDetailPageLayoutProps<FormField>) => {
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const history = useHistory();
	return (
		<LoadingProvider loading={loading} className="h-full">
			<div className={clsxm('w-full flex flex-col h-full overflow-y-auto')}>
				<div className={clsxm('py-16 pt-8 my-16 w-full px-16 grid gap-y-4')}>
					<Header
						onClose={() => {
							if (onClose) {
								onClose();
							} else {
								history.goBack();
							}
						}}
						title={title}
						showClose
						extraLeft={extraLeftHeader}
						extraRight={extraRightHeader}
					/>
					{form ? (
						<FormProvider {...form}>
							{children}
							{!noFooter && (
								<DetailPageFooter
									onSave={onSave}
									onDelete={() => {
										setShowAlert(true);
									}}
									showDelete={showDelete}
									loading={loading}
									extraRight={extraRight}
									extraLeft={extraLeft}
									saveButtonTitle={saveButtonTitle}
									hideSave={hideSave}
								/>
							)}
						</FormProvider>
					) : (
						<>
							{children}
							{!noFooter && (
								<DetailPageFooter
									onSave={onSave}
									onDelete={() => {
										setShowAlert(true);
									}}
									showDelete={showDelete}
									loading={loading}
									extraRight={extraRight}
									extraLeft={extraLeft}
									saveButtonTitle={saveButtonTitle}
								/>
							)}
						</>
					)}
				</div>
			</div>
			{showDelete && (
				<ConfirmModal
					visible={showAlert}
					title={deleteTitle || t(translations.text.doYouWantToDelete)}
					subTitle={deleteSubTitle}
					onPositiveClick={() => {
						onDelete && onDelete();
						setShowAlert(false);
					}}
					onNegativeClick={() => setShowAlert(false)}
					type="error"
				/>
			)}
		</LoadingProvider>
	);
};

interface IDetailPageFooterProps {
	extraRight?: React.ReactNode;
	extraLeft?: React.ReactNode;
	onSave?: () => void;
	onDelete?: () => void;
	showDelete?: boolean;
	loading?: boolean;
	saveButtonTitle?: string;
	hideSave?: boolean;
}
export const DetailPageFooter = (props: IDetailPageFooterProps) => {
	return (
		<div className="px-6 fixed grid grid-cols-3 align-middle z-50 text-white justify-center  h-16 bottom-0 w-screen inset-x-0 bg-white shadow-[-10px_0px_10px_5px_#f0f0f0]">
			<div className="flex justify-start align-middle py-2">
				{props.extraLeft}
			</div>
			<div className="flex h-full items-center justify-center"></div>
			<div className={clsxm('flex justify-end align-middle py-2 gap-x-2')}>
				{props.extraRight}
				{props.showDelete && (
					<Button
						small
						title={t(translations.delete)}
						iconBefore={<Icon path={mdiDelete} />}
						className="bg-error text-white"
						onClick={props.onDelete}
						loading={props.loading}
					/>
				)}
				{!props.hideSave && (
					<Button
						small
						title={props.saveButtonTitle || t(translations.save)}
						iconBefore={<Icon path={mdiContentSave} />}
						className="btn-outline btn-error bg-white"
						primary
						onClick={props.onSave}
						loading={props.loading}
					/>
				)}
			</div>
		</div>
	);
};
