import { mdiArrowLeft } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Icon } from 'components/atoms';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { HomeRoute, IRoute } from 'routers/routes';
import { Const } from 'utils';

export const Menu = () => {
	const [listRoute, setListRoute] = useState<Partial<IRoute>[]>(
		HomeRoute.filter((r) => r.showInMenu && !r.mainMobile)
	);
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as { key?: string };
	const key = queries.key;
	const history = useHistory();
	const [selectedKey, setSelectedKey] = useState('');
	const user = useAppSelector((state) => state.UserReducer.user);
	const employee = useAppSelector((state) => state.UserReducer.employee);
	const routes = listRoute.filter(
		(route) =>
			!(
				!_.isEmpty(route.roles) &&
				_.isEmpty(_.intersection(route.roles, user?.userRoles)) &&
				_.isEmpty(
					_.intersection(
						route.roles?.map((x) => x.toLowerCase()),
						employee?.employeeTypes?.map((x) => x.code?.toLowerCase())
					)
				)
			)
	);
	const onClickMenu = (route: Partial<IRoute>) => {
		if (route.component || _.isEmpty(route.subMenu)) {
			history.push(route.path!);
		} else {
			// setSelectedKey(route.key!);
			history.replace({ search: `?key=${route.key}` });
		}
	};
	useEffect(() => {
		if (key) {
			const route = _.find(
				HomeRoute.filter((r) => r.showInMenu && !r.mainMobile),
				(x) => x.key === key
			);
			if (route) {
				setSelectedKey(key);
				setListRoute(route.subMenu!);
			} else {
				setListRoute(HomeRoute.filter((r) => r.showInMenu && !r.mainMobile));
				setSelectedKey('');
			}
		} else {
			setListRoute(HomeRoute.filter((r) => r.showInMenu && !r.mainMobile));
			setSelectedKey('');
		}
	}, [key]);
	return (
		<div className="relative h-full pb-16">
			<div className={clsxm('grid  grid-cols-6 gap-2 p-4')}>
				{!_.isEmpty(selectedKey) && (
					<div
						onClick={() => {
							history.replace({ search: `` });
						}}
						className={clsxm(
							' flex flex-col bg-white uppercase cursor-pointer active:animate-pop duration-200 ease-in text-primary shadow-md items-center rounded-lg gap-2 justify-center p-4'
						)}
					>
						<Icon path={mdiArrowLeft} />
						<strong className={'text-primary'}>Go Back</strong>
					</div>
				)}
				{routes.map((x) => {
					return (
						<div
							onClick={() => onClickMenu(x)}
							className={clsxm(
								' flex flex-col bg-white uppercase cursor-pointer active:animate-pop duration-200 ease-in text-primary border border-primary shadow-md items-center rounded-lg gap-2 justify-center p-4'
							)}
						>
							{x.icon}
							<strong className={'text-primary'}>{x.name}</strong>
						</div>
					);
				})}
			</div>

			<span className="absolute bottom-2 right-4">version {Const.version}</span>
		</div>
	);
};
