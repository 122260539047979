/* eslint-disable react-hooks/exhaustive-deps */
import clsxm from 'clsxs/clsxm';
import { Container, Select } from 'components/atoms';
import { BranchSelect } from 'components/molecules/BranchSelect';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { TDateRange } from 'components/molecules/ModalCalendar';
import { AlertHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import { EPeriodType, IApiResponse, IFinancialReport } from 'models';
import { IFinancialReportRequest } from 'models/RequestModels';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ReportApiService } from 'services';
import { t, translations } from 'utils';
import {
	GeneralFinanceReport,
	GiftCardAndPromotionsReport,
	IncomeReport,
	ReportContext,
} from './components';

const PERIOD_TYPES = [
	{
		id: 'date-range',
		title: translations.dateRangeFrame.dateRange,
		value: EPeriodType.DATE_RANGE,
	},
	{
		id: 'today',
		title: translations.dateRangeFrame.today,
		value: EPeriodType.DAY,
	},
	{
		id: 'week',
		title: translations.dateRangeFrame.week,
		value: EPeriodType.WEEK,
	},
	{
		id: 'semi-month',
		title: translations.dateRangeFrame.semiMonth,
		value: EPeriodType.SEMI_MONTH,
	},
	{
		id: 'month',
		title: translations.dateRangeFrame.month,
		value: EPeriodType.MONTH,
	},
	{
		id: 'quarter',
		title: translations.dateRangeFrame.quarter,
		value: EPeriodType.QUARTER,
	},
	{
		id: 'semi-year',
		title: translations.dateRangeFrame.semiYear,
		value: EPeriodType.SEMI_YEAR,
	},
	{
		id: 'year',
		title: translations.dateRangeFrame.year,
		value: EPeriodType.YEAR,
	},
];

export const FinancialReport = () => {
	const [selectedPeriod, setSelectedPeriod] = useState<typeof PERIOD_TYPES[0]>(
		PERIOD_TYPES[0]
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { currentBranch } = useAppSelector((state) => state.BranchReducer);
	const [report, setReport] = useState<IFinancialReport>();
	const [dateFilter, setDateFilter] = useState<Required<TDateRange>>({
		fromDate: moment().startOf('date'),
		toDate: moment().startOf('date'),
	});
	const [showDatePicker, setShowDatePicker] = useState<boolean>();
	const [branch, setBranch] = useState(currentBranch?.id);

	useEffect(() => {
		getFinancialReport({
			periodType: EPeriodType.DATE_RANGE,
			fromDate: dateFilter.fromDate.format('YYYY-MM-DD'),
			toDate: dateFilter.toDate.format('YYYY-MM-DD'),
			branchId: branch,
		});
	}, [dateFilter, branch]);

	useEffect(() => {
		if (selectedPeriod.value === EPeriodType.DATE_RANGE && !showDatePicker) {
			setShowDatePicker(true);
		} else if (showDatePicker) {
			setShowDatePicker(false);
		}
		switch (selectedPeriod.value) {
			case EPeriodType.WEEK:
				setDateFilter({
					fromDate: moment().add(-7, 'day'),
					toDate: moment(),
				});
				break;
			case EPeriodType.SEMI_MONTH:
				setDateFilter({
					fromDate: moment().add(-15, 'day'),
					toDate: moment(),
				});
				break;
			case EPeriodType.MONTH:
				setDateFilter({
					fromDate: moment().add(-30, 'day'),
					toDate: moment(),
				});
				break;
			case EPeriodType.QUARTER:
				setDateFilter({
					fromDate: moment().add(-3, 'month'),
					toDate: moment(),
				});
				break;
			case EPeriodType.SEMI_YEAR:
				setDateFilter({
					fromDate: moment().add(-6, 'month'),
					toDate: moment(),
				});
				break;
			case EPeriodType.YEAR:
				setDateFilter({
					fromDate: moment().startOf('year'),
					toDate: moment(),
				});
				break;
			case EPeriodType.DATE_RANGE:
				setDateFilter({
					fromDate: moment(),
					toDate: moment(),
				});
				break;
			case EPeriodType.DAY:
				setDateFilter({
					fromDate: moment(),
					toDate: moment(),
				});
				break;
			default:
				break;
		}
	}, [selectedPeriod]);

	const getFinancialReport = async (request: IFinancialReportRequest) => {
		setIsLoading(true);
		try {
			const result = (await ReportApiService.getOwnerFinancialSummaryReport(
				request
			)) as IApiResponse<IFinancialReport>;
			if (result.succeeded) {
				setReport(result.data);
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network error', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Container>
			<ReportContext.Provider value={{ report: report, loading: isLoading }}>
				<div className={clsxm('grid gap-4')}>
					<div className={clsxm('grid grid-cols-6 gap-x-4')}>
						<Select<typeof PERIOD_TYPES[0]>
							options={PERIOD_TYPES}
							value={selectedPeriod}
							titleField={'title'}
							keyField={'value'}
							fullWidth
							onChange={setSelectedPeriod}
							renderOptions={(item) => t(item.title)}
							renderValueTitle={(value) => t(value?.title || '')}
							disabled={isLoading}
						/>
						{showDatePicker && (
							<DateRangePicker
								disabled={isLoading}
								onSelectDateRange={(dateRange) => {
									setDateFilter({
										fromDate: dateRange.fromDate!,
										toDate: dateRange.toDate!,
									});
								}}
								selectedDateRange={dateFilter}
							/>
						)}
						<div
							className={clsxm(
								'flex justify-end',
								showDatePicker ? 'col-span-4' : 'col-span-5'
							)}
						>
							<BranchSelect
								value={branch}
								onSelect={(branchId) => {
									setBranch && setBranch(branchId);
								}}
							/>
						</div>
					</div>
					<div>
						<h2 className={clsxm('text-3xl my-4 text-ERROR')}>
							{t(translations.ownerFinancialSummaryReport.report)}
						</h2>
						<h4 className={clsxm('my-4')}>
							{!isLoading &&
								getDateString(moment(report?.fromDate), moment(report?.toDate))}
						</h4>
					</div>
					<div className={clsxm('grid grid-cols-3 gap-x-4')}>
						<GeneralFinanceReport />
						<IncomeReport />
						<GiftCardAndPromotionsReport />
					</div>
					<div className={clsxm('grid grid-cols-3 gap-x-4')}></div>
				</div>
			</ReportContext.Provider>
		</Container>
	);
};

export const getDateString = (
	fromDate: moment.Moment,
	toDate: moment.Moment
) => {
	if (fromDate.isSame(toDate)) {
		return toDate.format('MMM DD, YYYY');
	} else {
		return (
			fromDate.format('MMM DD, YYYY') + ' - ' + toDate.format('MMM DD, YYYY')
		);
	}
};
