import { ITemplate } from 'models/ITemplate';
import { BaseApiService } from 'services/BaseApiService';

class TemplateApiService extends BaseApiService {
	public getTemplates = (query?: any) =>
		this.get<ITemplate[]>(`/EmailTemplate/get-all-emailtemplates`, {
			params: query,
		});
	public getTemplateById = (id: string) =>
		this.get<ITemplate>(`/EmailTemplate/get-emailtemplate-by-id/${id}`);
	public addTemplate = (addTemplateRequest: Partial<ITemplate>) =>
		this.post<Partial<ITemplate>, string>(
			`EmailTemplate/create-emailtemplate`,
			addTemplateRequest
		);
	public editTemplate = (editTemplateRequest: Partial<ITemplate>) =>
		this.put<Partial<ITemplate>, string>(
			`/EmailTemplate/update-emailtemplate`,
			editTemplateRequest
		);
	public deleteTemplate = (id: string) =>
		this.delete<string>(`/EmailTemplate/delete-emailtemplate/${id}`);
}

export default new TemplateApiService();
