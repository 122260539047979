import { Button } from 'components/atoms/Button';
import { Modal } from 'components/atoms/Modal';
import { SearchInput } from 'components/molecules/SearchInput';
import { mdiPlus, mdiChevronLeft } from '@mdi/js';
import React, { useCallback, useEffect, useState } from 'react';
import { Icon } from 'components/atoms/Icon';
import { ICustomer } from 'models';
import CustomerApiService from 'services/CustomerApiService';
import { debounce } from 'lodash';
import { LoadingProvider } from 'contexts/LoadingContext';
import { Input } from 'components/atoms/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MaskInput } from 'components/atoms/MaskInput';
import { DateFormInput } from 'components/molecules/DateFormInput';
import { Select } from 'components/atoms/Select';
import { EGender } from 'models/EGender';
import { showAlert } from 'helpers/alertHelper';
import I18n from 'i18n-js';
import { _t } from 'utils/locales';
import { translations } from 'utils';
import clsxm from 'clsxs/clsxm';
import { Avatar } from 'components/atoms/Avatar';
import { StringHelper } from 'helpers';

interface ICustomerCreate {
	firstName: string;
	lastName?: string;
	phone: string;
	email?: string;
	gender?: number;
	dateOfBirth?: string;
}

const AddNewCustomer = (props: {
	customer?: ICustomer;
	onSubmit: (customer: ICustomerCreate) => void;
	phoneNumber?: string;
}) => {
	const { customer } = props;
	const {
		register,
		handleSubmit,
		setValue,
		control,
		watch,
		formState: { errors },
	} = useForm<ICustomerCreate>({
		defaultValues: {
			firstName: customer ? customer.firstName : undefined,
			lastName: customer ? customer.lastName : undefined,
			email: customer ? customer.email : undefined,
			dateOfBirth: customer ? (customer.dateOfBirth as string) : undefined,
			gender: customer ? customer.gender : undefined,
			phone: customer ? customer.phone : props.phoneNumber,
		},
	});
	useEffect(() => {
		register('gender');
	}, [register]);
	const onSubmit: SubmitHandler<ICustomerCreate> = async (data) =>
		props.onSubmit(data);
	const gender = watch('gender'); // you can also target specific fields by their names

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="bg-white mt-4 grid grid-cols-2 gap-4 relative"
		>
			<Input
				{...register('firstName', {
					required: "Please enter your customer's first name",
				})}
				label="First name *"
				placeholder="First name"
				error={errors.firstName?.message}
			/>
			<Input
				{...register('lastName')}
				label="Last name"
				placeholder="Last name"
				error={errors.lastName?.message}
			/>
			<Controller
				render={(inputProps) => (
					<MaskInput
						error={errors.phone?.message}
						label="Phone number *"
						placeholder="Phone number"
						{...inputProps.field}
						autoFocus
						mask="999-999-9999"
					/>
				)}
				rules={{
					required: "Please enter customer's phone number",
				}}
				control={control}
				name={'phone'}
			/>

			<Input
				{...register('email', {
					pattern: {
						value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
						message: 'Invalid email address',
					},
				})}
				error={errors.email?.message}
				label="Email"
				placeholder="Email"
			/>
			<DateFormInput
				error={errors.dateOfBirth?.message}
				label="Date of birth"
				rules={{ required: false }}
				placeholder="Date of birth"
				control={control as any}
				name={'dateOfBirth'}
			/>
			<Select<{ id: EGender; label: string }>
				options={[
					{
						id: EGender.Male,
						label: 'Male',
					},
					{
						id: EGender.Female,
						label: 'Female',
					},
					{
						id: EGender.NoInformation,
						label: 'No Information',
					},
				]}
				titleField={'label'}
				keyField={'id'}
				label={'Gender'}
				title={'Select Gender'}
				fullWidth
				valueId={gender}
				onChange={(value) => setValue('gender', value.id)}
			/>
			<Button small title="Submit" type="submit" className="col-span-2 mt-8" />
		</form>
	);
};

interface ICustomerModalProps {
	onCancel?: () => void;
	onSelectCustomer?: (customer: Partial<ICustomer>) => void;
	hideWalkIn?: boolean;
	edit?: boolean;
	customer?: ICustomer;
}

export const CustomerModal = (props: ICustomerModalProps) => {
	const [customers, setCustomers] = useState<ICustomer[]>([]);
	const [addNew, setAddNew] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');

	const fetchCustomer = async (text = '') => {
		setLoading(true);
		const response = await CustomerApiService.getCustomers({
			searchText: text,
		});
		setLoading(false);
		if (response.succeeded) {
			setCustomers(
				response.data?.filter(
					(x) => !props.hideWalkIn || (props.hideWalkIn && x.code !== 'WALKIN')
				) || []
			);
		}
	};
	const debounceSearch = useCallback(
		debounce((text: string) => {
			setSearchText(text);
			fetchCustomer(text);
		}, 1000),
		[]
	);
	useEffect(() => {
		fetchCustomer();
	}, []);
	const onSubmit = async (data: ICustomerCreate) => {
		setLoading(true);
		const customerData = {
			...props.customer,
			...data,
			phone: data.phone.replaceAll('-', ''),
			gender: data.gender || -1,
		};
		const response = props.edit
			? await CustomerApiService.editCustomer(customerData)
			: await CustomerApiService.addCustomer(customerData);
		setLoading(false);
		if (response.succeeded && response.data) {
			onSelectCustomer(response.data);
		} else {
			showAlert(
				response.message[0].Text || I18n.t(_t(translations.text.criticalError)),
				'error'
			);
		}
	};
	const onSelectCustomer = (customer: ICustomer) => {
		props.onSelectCustomer && props.onSelectCustomer(customer);
		props.onCancel && props.onCancel();
	};
	return (
		<Modal
			isOpen={true}
			onCancel={() => props.onCancel && props.onCancel()}
			title={'Select Customer'}
			className={'w-fit min-w-[650px]'}
			content={
				<div className="p-4 overflow-y-auto w-full h-full overflow-hidden ">
					<div className="w-full flex justify-end">
						<div className="search-customer flex-1 mr-4">
							{!addNew && !props.edit && (
								<SearchInput
									onChange={(e) => debounceSearch(e.target.value)}
									autoFocus
								/>
							)}
						</div>
						<Button
							onClick={() => setAddNew(!addNew)}
							className={clsxm(
								'add-new-customer',
								'text-white',
								addNew && 'btn-outline btn-primary text-primary'
							)}
							iconBefore={<Icon path={addNew ? mdiChevronLeft : mdiPlus} />}
							title={addNew ? 'Back' : 'Add New'}
							small
						/>
					</div>
					<LoadingProvider loading={loading}>
						{addNew || props.edit ? (
							<AddNewCustomer
								customer={props.customer}
								phoneNumber={searchText}
								onSubmit={onSubmit}
							/>
						) : (
							<ul className="bg-white mt-4 rounded-lg  border border-base-200 text-gray-900 list-none pl-0 ">
								{customers.map((x, index) => {
									return (
										<li
											onClick={() => onSelectCustomer(x)}
											className={clsxm(
												`customer-${index}`,
												'px-4 py-3 relative  active:animate-pop duration-500 ease-in cursor-pointer border-b border-base-200 flex justify-between  m-0 w-full  '
											)}
										>
											<div className={'w-full flex justify-between'}>
												<div>
													<Avatar
														small
														name={StringHelper.getShortName(
															`${x.firstName || ''} ${x.lastName || ''}`
														)}
													/>
												</div>
												<div className="ml-4 flex flex-col flex-1 ">
													<span className="p-0 font-semibold text-sm ">{`${
														x.firstName || ''
													} ${x.lastName || ''}`}</span>
													<span className="p-0 text-sm ">{`${StringHelper.formatPhoneNumber(
														x.phone || ''
													)}`}</span>
												</div>
											</div>
										</li>
									);
								})}
							</ul>
						)}
					</LoadingProvider>
				</div>
			}
		/>
	);
};
