import { Modal } from 'components/atoms/Modal';
import { Moment } from 'moment';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import clsxm from 'clsxs/clsxm';
import { Icon } from 'components/atoms/Icon';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { Select } from 'components/atoms/Select';
import { Children, cloneElement, useEffect, useState } from 'react';
import _ from 'lodash';
import React from 'react';

export type TDateRange = {
	fromDate?: Moment;
	toDate?: Moment;
};
interface ICalendarModalProps {
	rangeSelect?: boolean;
	selectedDate?: Moment;
	selectedDateRange?: TDateRange;
	selectedTo?: Moment;
	onSelectDateRange?: (dateRange: TDateRange) => void;
	onSelectDate?: (date: Moment) => void;
	onCancel: () => void;
	disabledBefore?: boolean;
	disabledAfter?: boolean;
	isOpen?: boolean;
}
const globalizeLocalizer = momentLocalizer(moment);
const Months = moment.months().map((x, index) => {
	return {
		id: index,
		label: x,
	};
});
const Years = _.range(moment().year() - 5, moment().year() + 5).map((x) => {
	return {
		id: `${x}`,
	};
});
const checkSameMonth = (date: Moment, current: Moment) => {
	return date.isSame(current, 'month') && date.isSame(current, 'year');
};
const checkSameDate = (date: Moment, current?: Moment) => {
	if (!current) {
		return false;
	}
	return date.isSame(current, 'date');
};

const checkInRage = (date: Moment, dateRange?: TDateRange) => {
	if (!dateRange || !dateRange.fromDate || !dateRange.toDate) {
		return false;
	}
	return (
		date.isAfter(dateRange.fromDate, 'date') &&
		date.isBefore(dateRange.toDate, 'date')
	);
};

export const CalendarModal = (props: ICalendarModalProps) => {
	const [currentMonth, setCurrentMonth] = useState<{
		id: number;
		label: string;
	}>(
		_.find(Months, (x) => {
			if (props.selectedDate) {
				return x.id === props.selectedDate.month();
			}
			if (props.selectedDateRange?.fromDate) {
				return x.id === props.selectedDateRange.fromDate.month();
			}
			return x.id === moment().month();
		})!
	);
	const [currentYear, setCurrentYear] = useState<{ id: string }>({
		id: `${moment(
			props.selectedDate || props.selectedDateRange?.fromDate || moment()
		).year()}`,
	});
	const [currentView, setCurrentView] = useState(
		props.selectedDate || props.selectedDateRange?.fromDate
	);
	const [dateRage, setDateRage] = useState(props.selectedDateRange);
	useEffect(() => {
		setTimeout(() => {
			const date = moment(
				`01/${currentMonth.label}/${currentYear.id}`,
				'DD/MMMM/yyyy'
			);
			setCurrentView(date);
		}, 200);
	}, [currentYear, currentMonth]);
	const onNavigate = (action: 'next' | 'prev') => {
		const date = moment(
			`01/${currentMonth.label}/${currentYear.id}`,
			'DD/MMMM/yyyy'
		).add(action === 'next' ? 1 : -1, 'M');
		setCurrentMonth(_.find(Months, (x) => x.id === date.month())!);
	};

	const onSelectDate = (date: Moment) => {
		if (props.disabledBefore && date.isBefore(moment().startOf('date'))) {
			return;
		}
		if (props.disabledAfter && date.isAfter(moment().startOf('date'))) {
			return;
		}
		if (props.rangeSelect) {
			if (
				!dateRage?.fromDate ||
				dateRage?.fromDate.isAfter(date) ||
				dateRage.toDate
			) {
				setDateRage({ fromDate: date });
			} else {
				setDateRage({ fromDate: dateRage?.fromDate!, toDate: date });
				setTimeout(() => {
					props.onSelectDateRange &&
						props.onSelectDateRange({
							fromDate: dateRage?.fromDate!,
							toDate: date,
						});
				}, 500);
			}
		} else {
			props.onSelectDate && props.onSelectDate(date);
		}
	};
	const checkSelectedDate = (date: Moment) => {
		return (
			checkSameDate(date, props.selectedDate) ||
			checkSameDate(date, dateRage?.fromDate) ||
			checkSameDate(date, dateRage?.toDate)
		);
	};
	return (
		<Modal
			className="booking-date-picker-modal w-2/3 z-[999999] calendar-modal "
			title="Select Date"
			isOpen={true}
			onCancel={() => props.onCancel()}
			content={
				<div className=" w-full px-4 ">
					<div className="navigation-booking-bar flex justify-center align-middle py-4 w-full">
						<button
							onClick={() => onNavigate('prev')}
							className={clsxm('btn btn-outline aspect-square mr-4 list-none')}
						>
							<Icon path={mdiChevronLeft} />
						</button>
						<Select<{ id: string }>
							options={Years}
							keyField="id"
							title="ABC"
							titleField="id"
							value={currentYear}
							className="mr-1 min-w-[100px]"
							onClick={(t) => {
								setCurrentYear(t);
							}}
						/>
						<Select<{ id: number; label: string }>
							options={Months}
							keyField="id"
							title="ABC"
							titleField="label"
							className="min-w-[150px]"
							value={currentMonth}
							onClick={(t) => {
								setCurrentMonth(t);
							}}
						/>
						<button
							onClick={() => onNavigate('next')}
							className={clsxm('btn btn-outline aspect-square ml-4 list-none')}
						>
							<Icon path={mdiChevronRight} />
						</button>
					</div>
					<Calendar
						className="booking-calendar h-5/6 border-none"
						selectable={true}
						components={{
							toolbar: (data) => {
								return null;
							},
							dateCellWrapper: (props: any) => {
								const { children, value } = props;
								const date = moment(value);
								return React.cloneElement(Children.only(children), {
									onTouchEnd: () => onSelectDate(date),
									style: {
										...children.style,
										borderRight: '1px solid #e5e9f0',
										borderBottom: '1px solid #e5e9f0',
									},
								});
							},
							month: {
								dateHeader: (d) => {
									const date = moment(d.date);
									const isSelected = checkSelectedDate(date);
									const sameMonth = checkSameMonth(
										date,
										currentView ? currentView : moment()
									);
									const disabled =
										(props.disabledBefore && date.isBefore(moment(), 'date')) ||
										(props.disabledAfter && date.isAfter(moment(), 'date'));
									return (
										<div
											className={clsxm(
												'cursor-pointer',
												isSelected && 'font-bold'
											)}
											style={{
												color: isSelected
													? 'white'
													: !sameMonth || disabled
													? 'rgba(0, 0, 0, 0.25)'
													: 'black',
											}}
										>
											{d.label}
										</div>
									);
								},
							},

							// eventWrapper: (event) => this.renderEventWrapper(event),
						}}
						view="month"
						slotPropGetter={(d) => {
							return {
								className: 'border-none',
							};
						}}
						onSelectSlot={(slot) => onSelectDate(moment(slot.start))}
						dayPropGetter={(d) => {
							const date = moment(d);
							const isToday = date.isSame(moment(), 'date');
							const isSelected = checkSelectedDate(date);

							return {
								className: clsxm(
									'cursor-pointer',
									isSelected && 'main-gradient-background',
									checkInRage(date, dateRage) && 'bg-[#d0edfb]',
									isToday && 'bg-[rgba(0,76,196,0.1)] '
								),
								onClick: () => {
									alert(1);
									onSelectDate(date);
								},
								style: { border: isToday ? '2px solid #004cc4' : undefined },
							};
						}}
						date={currentView && currentView.toDate()}
						localizer={globalizeLocalizer}
					/>
				</div>
			}
		/>
	);
};
