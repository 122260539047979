import { BaseApiService } from 'services/BaseApiService';
import { IClockInOutRequest } from 'models/RequestModels';
class EmployeeApiService extends BaseApiService {
	public clockIn = (request: IClockInOutRequest) =>
		this.post<IClockInOutRequest, any>(`/ClockInOut/clock-in`, request);
	public clockOut = (request: IClockInOutRequest) =>
		this.post<IClockInOutRequest, any>(`/ClockInOut/clock-out`, request);
}

export default new EmployeeApiService();
