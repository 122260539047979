/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Button,
	Select,
	Switch,
	TextArea,
	TimeRangePicker,
	Input,
	ButtonGroupMulti,
} from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { StaffPickerModal } from 'components/molecules/StaffPickerModal';
import { AlertHelper, BookingHelper, StringHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import { toTimeZone } from 'helpers/timeHelper';
import _ from 'lodash';
import moment from 'moment';
import { useForm, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { HolidayActions } from 'redux/actions';
import { history } from 'routers';
import { HolidayApiService } from 'services';
import { t, translations } from 'utils';
import { THolidayFormField, holidayValidateSchema } from './components';
import { useRouteMatch } from 'react-router-dom';
import { EFrequencyType, IEmployee, IHolidayModel } from 'models';
import { IAddHolidayRequest } from 'models/RequestModels';
import { convertStartEndTime, FREQUENCY_TYPES } from './helper';
import { yupResolver } from '@hookform/resolvers/yup';

export const HolidayDetail = () => {
	const { currentHoliday } = useAppSelector((state) => state.HolidayReducer);
	const { employees } = useAppSelector((state) => state.EmployeeReducer);
	const isEdit = useMemo<boolean>(
		() => history.location.pathname.toLowerCase().includes('edit'),
		[]
	);
	const [showStaffPickerModal, setShowStaffPickerModal] = useState<boolean>();
	const isStaffHoliday = useMemo<boolean>(
		() =>
			isEdit
				? currentHoliday?.employeeId
					? true
					: false
				: history.location.pathname.toLowerCase().includes('staff'),
		[currentHoliday]
	);
	const [isGetByIdLoading, setGetByIdLoading] = useState<boolean>();
	const [isLoading, setIsLoading] = useState<boolean>();
	const { currentBranch } = useAppSelector((state) => state.BranchReducer);
	const { params } = useRouteMatch<{ id: string }>();
	const dispatch = useDispatch();

	const form = useForm<THolidayFormField>({
		defaultValues: getDefaultValue(currentHoliday, employees, isEdit),
		mode: 'onChange',
		resolver: yupResolver(holidayValidateSchema),
	});
	const { register, watch, setValue } = form;

	const staffName = watch('staffName');
	const staffId = watch('staffId');
	const fromDate = watch('fromDate');
	const toDate = watch('toDate');
	const isEntireDay = watch('isEntireDay');
	const repeat = watch('repeat');
	const startTime = watch('startTime');
	const endTime = watch('endTime');
	const frequencyType = watch('frequencyType');

	const selectedFrequencyType = useMemo(() => {
		return FREQUENCY_TYPES.find((e) => e.id === frequencyType)!;
	}, [frequencyType]);
	const disableSave = useMemo(() => {
		if (isEdit) {
			if (isStaffHoliday && !staffId) {
				return true;
			}
		}
		if (isStaffHoliday && !staffId) {
			return true;
		}
		return false;
	}, [isEdit, isStaffHoliday, staffId, fromDate, toDate, startTime, endTime]);

	useEffect(() => {
		if (_.isEmpty(currentHoliday) && isEdit) {
			getHolidayById(params.id);
		}
		return () => {
			dispatch(HolidayActions.clearHoliday.request());
		};
	}, []);

	// useEffect(() => {
	// 	console.log('changed', isEntireDay);
	// 	if (isEntireDay) {
	// 		form.setValue('startTime', undefined);
	// 		form.setValue('endTime', undefined);
	// 	} else {
	// 		form.setValue(
	// 			'startTime',
	// 			moment().hour(8).startOf('hour').format('YYYY-MM-DDTHH:mm')
	// 		);
	// 		form.setValue(
	// 			'endTime',
	// 			moment().hour(22).startOf('hour').format('YYYY-MM-DDTHH:mm')
	// 		);
	// 	}
	// }, [isEntireDay]);

	const getHolidayById = async (id: string) => {
		setGetByIdLoading(true);
		try {
			const result = await HolidayApiService.getHolidayById(id);
			if (result.succeeded && !_.isEmpty(result.data)) {
				dispatch(HolidayActions.selectHoliday.request(result.data!));
				form.reset(getDefaultValue(result.data, employees, isEdit));
				// form.setValue('description', result.data!);
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Nerwork Error', error);
		} finally {
			setGetByIdLoading(false);
		}
	};

	const addHoliday = async (holiday: IAddHolidayRequest) => {
		setIsLoading(true);
		try {
			const result = await HolidayApiService.addHoliday(holiday);
			if (result.succeeded && !_.isEmpty(result.data)) {
				AlertHelper.showSuccess(t(translations.addHoliday.addSuccess));
				history.goBack();
			} else {
				AlertHelper.showError(result);
				setIsLoading(false);
			}
		} catch (error) {
			console.error('Nerwork Error', error);
			setIsLoading(false);
		}
	};

	const updateHoliday = async (holiday: IHolidayModel) => {
		setIsLoading(true);
		try {
			const result = await HolidayApiService.updateHoliday(holiday);
			if (result.succeeded && !_.isEmpty(result.data)) {
				AlertHelper.showSuccess(t(translations.holiday.updateSuccess));
				history.goBack();
			} else {
				AlertHelper.showError(result);
				setIsLoading(false);
			}
		} catch (error) {
			console.error('Nerwork Error', error);
			setIsLoading(false);
		}
	};

	const deleteHoliday = async (id: string) => {
		setIsLoading(true);
		try {
			const result = await HolidayApiService.deleteHoliday(id);
			if (result.succeeded && !_.isEmpty(result.data)) {
				AlertHelper.showSuccess(t(translations.holiday.deleteSuccess));
				history.goBack();
			} else {
				AlertHelper.showError(result);
				setIsLoading(false);
			}
		} catch (error) {
			console.error('Nerwork Error', error);
			setIsLoading(false);
		}
	};

	const onSubmitHoliday = (holiday: THolidayFormField) => {
		if (isStaffHoliday && !staffId) {
			AlertHelper.showAlert(t(translations.holiday.staffRequired), 'error');
			return;
		}
		console.log('why? ', holiday);
		if (!disableSave) {
			if (isEdit) {
				if (moment(toDate).isBefore(moment().startOf('date'))) {
					AlertHelper.showAlert(t(translations.holiday.cantEdit), 'error');
					return;
				}
				updateHoliday({
					...currentHoliday!,
					fromDate: BookingHelper.convertDateRequest(moment(holiday.fromDate)),
					toDate: holiday.maxNumberOfOccurrences
						? undefined
						: BookingHelper.convertDateRequest(moment(holiday.toDate)),
					startTime: holiday.startTime
						? convertStartEndTime(
								moment(holiday.startTime),
								moment(holiday.fromDate)
						  )
						: undefined,
					endTime: holiday.endTime
						? convertStartEndTime(
								moment(holiday.endTime),
								moment(holiday.toDate)
						  )
						: undefined,

					description: holiday.description,
					employeeId: holiday.staffId,

					maxNumberOfOccurrences: holiday.maxNumberOfOccurrences || undefined,
					frequencyType: holiday.frequencyType,
					separationCount:
						holiday.frequencyType !== EFrequencyType.ONCE ? 1 : undefined,
					daysOfMonth: holiday.daysOfMonth,
					daysOfWeek: holiday.daysOfWeek,
				});
			} else {
				addHoliday({
					fromDate: BookingHelper.convertDateRequest(moment(holiday.fromDate)),
					toDate: holiday.maxNumberOfOccurrences
						? undefined
						: BookingHelper.convertDateRequest(moment(holiday.toDate)),
					startTime: holiday.startTime
						? convertStartEndTime(
								moment(holiday.startTime),
								moment(holiday.fromDate)
						  )
						: undefined,
					endTime: holiday.endTime
						? convertStartEndTime(
								moment(holiday.endTime),
								moment(holiday.toDate)
						  )
						: undefined,
					holidayName: `${holiday.description}_${toTimeZone().format(
						'YYYY-MM-DDTHH:mm:ss'
					)}`,
					description: holiday.description,
					employeeId: holiday.staffId,
					status: 1,
					branchId: currentBranch?.id!,
					maxNumberOfOccurrences: holiday.maxNumberOfOccurrences || undefined,
					frequencyType: holiday.frequencyType,
					separationCount:
						holiday.frequencyType !== EFrequencyType.ONCE ? 1 : undefined,
					daysOfMonth: holiday.daysOfMonth,
					daysOfWeek: holiday.daysOfWeek,
				});
			}
		}
	};

	return (
		<DetailPageLayout<THolidayFormField>
			form={form}
			title={
				isEdit
					? t(translations.holiday.editHoliday)
					: t(translations.holiday.newHoliday)
			}
			showDelete={isEdit}
			deleteTitle={t(translations.holiday.deleteWarning)}
			// deleteSubTitle={t(translations.holiday.deleteMessaeg)}
			onDelete={() => deleteHoliday(currentHoliday?.id!)}
			onSave={form.handleSubmit(onSubmitHoliday, (error) => {
				console.log('here', error);
			})}
			loading={isGetByIdLoading || isLoading}
		>
			{!isGetByIdLoading && (
				<>
					<div className="grid grid-cols-3">
						<div />
						<div className="grid grid-cols-2 gap-4">
							<DateRangePicker
								selectedDateRange={{
									fromDate: moment(fromDate),
									toDate: moment(toDate),
								}}
								onSelectDateRange={(date) => {
									setValue('fromDate', date.fromDate?.format('YYYY-MM-DD')!);
									setValue('toDate', date.toDate?.format('YYYY-MM-DD')!);
								}}
								disabled={isLoading}
								disableBefore
							/>
							{isStaffHoliday && (
								<Button
									title={staffName || t(translations.mainBooking.selectStylist)}
									className={
										'caret-transparent cursor-pointer font-bold text-primary bg-white'
									}
									onClick={() => setShowStaffPickerModal(true)}
									small
									disabled={isLoading}
								/>
							)}
							<Switch
								label={t(translations.holiday.entireDay)}
								onChange={(value) => {
									if (value) {
										setValue('startTime', undefined);
										setValue('endTime', undefined);
									}
									setValue('isEntireDay', value);
								}}
								fullWidth
								className="col-span-2"
								value={isEntireDay}
								disabled={isLoading}
							/>
							{!isEntireDay && (
								<div className="col-span-2">
									<TimeRangePicker
										defaultValue={[
											moment(startTime || moment().startOf('date').hour(8)),
											moment(endTime || moment().startOf('date').hour(22)),
										]}
										onChooseTime={(time) => {
											setValue('startTime', time[0].format('YYYY-MM-DDTHH:mm'));
											setValue('endTime', time[1].format('YYYY-MM-DDTHH:mm'));
										}}
										disabled={isLoading}
									/>
								</div>
							)}
							<Switch
								label={t(translations.holiday.repeat)}
								onChange={(value) => {
									if (!value) {
										setValue('frequencyType', EFrequencyType.ONCE);
										setValue('maxNumberOfOccurrences', undefined);
										setValue('daysOfMonth', undefined);
										setValue('daysOfWeek', undefined);
									} else {
										setValue('daysOfWeek', [DAYS_OF_WEEKS[0].id]);
										setValue('frequencyType', EFrequencyType.WEEKLY);
										setValue('maxNumberOfOccurrences', 0);
									}
									setValue('repeat', value);
								}}
								fullWidth
								className="col-span-2"
								value={repeat}
								disabled={isLoading}
							/>
							{repeat && (
								<>
									<FrequencySelect isLoading={isLoading} />
									{selectedFrequencyType?.id !== EFrequencyType.ONCE && (
										<DateRepeat isLoading={isLoading} />
									)}
								</>
							)}
							<div className="col-span-2">
								<TextArea
									label={t(translations.description)}
									{...register('description', {
										disabled: isLoading,
									})}
								/>
							</div>
						</div>
						<div />
					</div>
					<StaffPickerModal
						onSelectStaff={(staff) => {
							setValue('staffId', staff.id);
							setValue(
								'staffName',
								`${staff.firstName || ''} ${staff.lastName || ''}`
							);
							setShowStaffPickerModal(false);
						}}
						visible={showStaffPickerModal}
						selectedStaff={{ id: staffId }}
						onCancel={() => setShowStaffPickerModal(false)}
					/>
				</>
			)}
		</DetailPageLayout>
	);
};

const getDefaultValue = (
	holiday?: IHolidayModel,
	employees?: IEmployee[],
	isEdit?: boolean
): THolidayFormField => {
	const staff = isEdit
		? employees?.find((e) => e.id === holiday?.employeeId)
		: undefined;
	const defaultValue: THolidayFormField = {
		description: isEdit ? holiday?.description || '' : '',
		fromDate: isEdit
			? moment(holiday?.fromDate).format('YYYY-MM-DD')
			: moment().format('YYYY-MM-DD'),
		toDate: isEdit
			? moment(holiday?.toDate).format('YYYY-MM-DD')
			: moment().format('YYYY-MM-DD'),
		staffId: staff?.id || '',
		staffName: staff ? `${staff?.firstName} ${staff?.lastName}` : '',
		startTime: !isEdit
			? undefined
			: !holiday?.startTime || !holiday.endTime
			? moment().hour(8).startOf('hour').format('YYYY-MM-DDTHH:mm')
			: toTimeZone(holiday?.startTime).format('YYYY-MM-DDTHH:mm'),
		endTime: !isEdit
			? undefined
			: !holiday?.startTime || !holiday.endTime
			? moment().hour(22).startOf('hour').format('YYYY-MM-DDTHH:mm')
			: toTimeZone(holiday?.endTime).format('YYYY-MM-DDTHH:mm'),
		isEntireDay: !isEdit || (!holiday?.startTime && !holiday?.endTime),
		repeat: isEdit
			? holiday?.frequencyType !== undefined &&
			  holiday.frequencyType !== EFrequencyType.ONCE
				? true
				: false
			: false,
		frequencyType: isEdit
			? holiday?.frequencyType || EFrequencyType.ONCE
			: EFrequencyType.ONCE,
		maxNumberOfOccurrences: holiday?.maxNumberOfOccurrences,
		daysOfMonth: isEdit
			? holiday?.daysOfMonth
				? (holiday?.daysOfMonth as string)
						.split(', ')
						.map((e) => Number.parseInt(e))
				: []
			: undefined,
		daysOfWeek: isEdit
			? holiday?.daysOfWeek
				? (holiday?.daysOfWeek as string)
						.split(', ')
						.map((e) => Number.parseInt(e))
				: undefined
			: undefined,
	};
	return defaultValue;
};

const FrequencySelect = (props: { isLoading?: boolean }) => {
	const { setValue, watch } = useFormContext<THolidayFormField>();
	const frequencyType = watch('frequencyType');
	const selectedFrequencyType = useMemo(() => {
		return FREQUENCY_TYPES.find((e) => e.id === frequencyType)!;
	}, [frequencyType]);
	return (
		<div className="col-span-2">
			<Select<typeof FREQUENCY_TYPES[0]>
				keyField="id"
				titleField="title"
				options={FREQUENCY_TYPES.slice(1)}
				value={selectedFrequencyType}
				// title={selectedFrequencyType}
				disabled={props.isLoading}
				onChange={(value) => {
					setValue('frequencyType', value.id);
					if (value.id === EFrequencyType.WEEKLY) {
						setValue('daysOfWeek', [DAYS_OF_WEEKS[0].id]);
						setValue('daysOfMonth', undefined);
					} else {
						setValue('daysOfWeek', undefined);
						setValue('daysOfMonth', [DAYS_OF_MONTH[0].id]);
					}
				}}
				fullWidth
			/>
		</div>
	);
};

const DateRepeat = (props: { isLoading?: boolean }) => {
	const { setValue, watch, register, formState, unregister } =
		useFormContext<THolidayFormField>();
	const frequencyType = watch('frequencyType');
	const daysOfMonth = watch('daysOfMonth');
	const daysOfWeek = watch('daysOfWeek');
	const maxNumberOfOccurrences = watch('maxNumberOfOccurrences');
	const fromDate = watch('fromDate');
	const toDate = watch('toDate');
	const selectedFrequencyType = useMemo(() => {
		return FREQUENCY_TYPES.find((e) => e.id === frequencyType)!;
	}, [frequencyType]);
	const DAYS_OF = useMemo(() => {
		return selectedFrequencyType.id === EFrequencyType.WEEKLY
			? DAYS_OF_WEEKS
			: DAYS_OF_MONTH;
	}, [selectedFrequencyType]);
	const selectedDays = useMemo(() => {
		const result =
			selectedFrequencyType.id === EFrequencyType.WEEKLY
				? daysOfWeek || []
				: daysOfMonth || [];
		result.sort((a, b) => (a > b ? 1 : a === b ? 0 : -1));
		return result;
	}, [selectedFrequencyType, daysOfWeek, daysOfMonth]);

	const repeatHint = useMemo(() => {
		return selectedFrequencyType.id === EFrequencyType.ONCE
			? undefined
			: t(translations.holiday.maxNumberOccurHint, {
					days:
						selectedDays
							?.map((e) =>
								selectedFrequencyType.id === EFrequencyType.WEEKLY
									? StringHelper.getDayOfWeek(e)
									: moment().month(1).date(e).format('Do')
							)
							.join(', ') || '',
					in: maxNumberOfOccurrences ? t(translations.holiday.in) : '',
					limit:
						Number.parseInt(maxNumberOfOccurrences?.toString() || '') > 0
							? `${maxNumberOfOccurrences?.toString()} `
							: '',
					type: `${
						maxNumberOfOccurrences
							? selectedFrequencyType!.id === EFrequencyType.MONTHLY
								? `${t(translations.dashboard.month).toLowerCase()}`
								: `${t(translations.dashboard.week).toLowerCase()}`
							: ''
					}${maxNumberOfOccurrences! > 1 ? t(translations.plural) : ''} `,
					fromDate: moment(fromDate).format('MMM Do, YYYY'),
					to: maxNumberOfOccurrences ? t(translations.holiday.to) : '',
					toDate: maxNumberOfOccurrences
						? moment(toDate).format('MMM Do, YYYY')
						: '',
			  });
	}, [selectedFrequencyType, maxNumberOfOccurrences, selectedDays]);

	return (
		<div className="col-span-2">
			<ButtonGroupMulti<typeof DAYS_OF_WEEKS[0]>
				buttons={DAYS_OF}
				titleField="day"
				keyField="id"
				onChange={(activeValues) => {
					setValue(
						selectedFrequencyType.id === EFrequencyType.WEEKLY
							? 'daysOfWeek'
							: 'daysOfMonth',
						activeValues.map((e) => e.id)
					);
				}}
				atLeastOneActive
				active={selectedDays?.map((e) => ({ id: e, day: '' }))}
			/>
			<Input
				label={t(translations.holiday.repeatLimit)}
				{...register('maxNumberOfOccurrences', {
					disabled: props.isLoading,
					valueAsNumber: true,
					min: { message: t(translations.validate.lessThanZero), value: 0 },
					required: {
						value: selectedFrequencyType.id !== EFrequencyType.ONCE,
						message: t(translations.validate.integer),
					},
					validate: (value) => {
						if (Number.isInteger(value)) {
							return t(translations.validate.integer);
						}
						return undefined;
					},
				})}
				type="number"
				hint={repeatHint}
				error={formState.errors.maxNumberOfOccurrences?.message}
			/>
		</div>
	);
};

const DAYS_OF_MONTH = Array.from(new Array(31).keys()).map((__, index) => ({
	id: index + 1,
	day: `${index + 1}`,
}));
const DAYS_OF_WEEKS = Array.from(new Array(7).keys()).map((__, index) => ({
	id: index,
	day: StringHelper.getDayOfWeek(index),
}));
