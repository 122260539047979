import { Button, Description } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { CurrencyHelper } from 'helpers';
import { useCheckOutContext } from 'pages/CheckOut/context';
import React, { useRef } from 'react';
import { t, translations } from 'utils';
import { PaymentType } from 'utils/Consts';

export const CashModal = (props: { onCancel: () => void }) => {
	const { calculateAmountNeedToPay, addPayment } = useCheckOutContext();
	const numpadRef = useRef<INumPadRef>();
	const onUpdateText = (text: string) => {
		if (numpadRef) {
			numpadRef.current?.setText(text);
		}
	};
	const onExact = () => {
		onUpdateText(`${calculateAmountNeedToPay()}`);
	};
	const onClear = () => {
		onUpdateText('');
	};
	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			isMoney
			showNumpad
			unit="Usd"
			title="Cash"
			onConfirm={(x) => {
				addPayment({
					paymentType: PaymentType.CASH,
					amount: parseFloat(x),
				});
				props.onCancel();
			}}
		>
			<div className="flex h-full flex-1 items-end p-4">
				<div className="flex w-full items-center justify-between ">
					<div>
						<Description
							title={t(translations.checkout.amountNeedToPay)}
							value={`${CurrencyHelper.formatPrice(
								calculateAmountNeedToPay()
							)}`}
						/>
					</div>

					<div className="flex gap-2">
						<Button
							onClick={() => onExact()}
							small
							title={t(translations.checkout.exact)}
							className="btn-outline btn-primary"
						/>
						<Button
							onClick={() => onClear()}
							small
							title={t(translations.clear)}
							className="btn-outline btn-error"
						/>
					</div>
				</div>
			</div>
		</ModalNumPad>
	);
};
