import { IReview } from 'models';
import { ICreateRatingRequest, IGetReviewsRequest } from 'models/RequestModels';
import { BaseApiService } from 'services/BaseApiService';

class RatingApi extends BaseApiService {
	public createRating = (addNewRequest: ICreateRatingRequest) =>
		this.post<ICreateRatingRequest, string>(
			`/Rating/create-rating`,
			addNewRequest
		);

	public getAllRating = (params: IGetReviewsRequest) =>
		this.get<IReview[]>(`/Rating/get-all-ratings`, { params });
}

export default new RatingApi();
