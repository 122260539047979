import { StaffCard } from 'components/molecules/StaffCard';
import { LoadingProvider } from 'contexts/LoadingContext';
import { useBookingHook } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import { toTimeZone } from 'helpers/timeHelper';
import _ from 'lodash';
import { IEmployee } from 'models';
import { IBookingSearchQuery } from 'models/RequestModels';
import moment from 'moment';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import {
	getAvailableEmployeeSortByTurn,
	numberOfBookingOfEmployee,
} from 'redux/selectors/employee';
import { t, translations } from 'utils';
import { BookingStatus } from 'utils/Consts';

export const ListStaff = () => {
	const {
		bookingDetails,
		currentIndex,
		selectStylist,
		disabledEdit,
		resetIndex,
		getAvailableStylistAndTimeBlocks,
	} = useBookingHook();
	const bookings = useAppSelector((state) => state.BookingReducer.bookings);
	const tempStylist = useAppSelector(
		(state) => state.BookingReducer.tempStylist
	);
	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const currentBooking = useAppSelector(
		(state) => state.BookingReducer.currentBooking
	);
	const allStaff = useAppSelector((state) => state.EmployeeReducer.employees);
	const listAvailableEmployeeSortByTurn = useAppSelector((state) =>
		getAvailableEmployeeSortByTurn(state)
	);
	const currentBookingDetail = bookingDetails[currentIndex || 0];
	const location = useLocation();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as IBookingSearchQuery;
	const quickSale = queries.quickSale;
	const overTime = currentBookingDetail
		? moment(
				toTimeZone(
					currentBookingDetail.startAt || currentBookingDetail.startAtExpected
				),
				''
		  )
				.add(currentBranch?.lateArrivalTimeAllowed, 'minute')
				.isBefore(moment())
		: false;
		const showAll =
		currentBooking?.status === BookingStatus.CHECKED_IN ||
		currentBooking?.status === BookingStatus.PROCESSING ||
		currentBooking?.status === BookingStatus.FINISHED ||
		overTime ||
		!_.isEmpty(quickSale);

	const availablePool = _.filter(
		useAppSelector((state) => state.TodayReducer.poolStaffs),
		(x) => x.availableStylistStatus === 1
	).map((x) => x.employee.id);
	const staffs = _.map(
		showAll
			? allStaff?.filter((x) => {
					if (!quickSale) {
						return true;
					}
					if (!availablePool.includes(x.id)) {
						return false;
					}
					if (!_.isEmpty(currentBookingDetail.itemId)) {
						return (
							x.status === 1 &&
							x.serviceIds?.includes(`${currentBookingDetail.itemId}`)
						);
					}
					return x.status === 1;
			  })
			: listAvailableEmployeeSortByTurn.filter((x) => {
					if (currentBookingDetail) {
						return (
							x.status === 1 &&
							x.serviceIds?.includes(`${currentBookingDetail.itemId}`)
						);
					}
					if (disabledEdit) {
						return (
							currentIndex !== undefined &&
							bookingDetails[currentIndex].stylistId === x.id
						);
					}
					return true;
			  }),
		(stylist) => {
			return {
				...stylist,
				turn: numberOfBookingOfEmployee(bookings, stylist.id) || 0,
			};
		}
	);

	const onSelect = (stylist?: IEmployee) => {
		selectStylist(stylist);
		if (currentIndex !== undefined) {
			if (currentIndex === 0 && !overTime) {
				getAvailableStylistAndTimeBlocks({
					stylistId: stylist?.id,
				});
			}
			setTimeout(() => {
				resetIndex();
			}, 100);
		} else {
		}
	};
	return (
		<div className="col-span-3 h-full flex-1 flex flex-col w-full bg-white rounded-lg pl-4 p-4  overflow-hidden ">
			<div className="flex justify-between align-middle mb-2 ">
				<h3 className="mt-1">{t(translations.mainBooking.staffs)}</h3>
			</div>
			<LoadingProvider
				// loading={getAvailableAction === BookingActions.getAvailableStylistAndTimeBlocks.requestName}
				className="flex-1 rounded-lg overflow-y-auto pb-2"
			>
				<div
					id="staffs"
					className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3  gap-2 w-full  "
				>
					{((!disabledEdit && currentIndex !== undefined) || tempStylist) && (
						<StaffCard
							className="col-span-1 cursor-pointer aspect-[4/2.5]"
							onSelect={() => onSelect()}
							label={t(translations.chooseLater)}
							isSelected={
								currentIndex !== undefined &&
								(!bookingDetails[currentIndex] ||
									bookingDetails[currentIndex].stylistId === undefined)
							}
						/>
					)}
					{_.orderBy(
						staffs.filter((x) => x.status === 1),
						[
							(x) =>
								(currentIndex !== undefined &&
									bookingDetails[currentIndex].stylistId === x.id) ||
								tempStylist?.id === x.id,
							(x) => x.turn,
						],
						['desc', 'asc']
					).map((x) => {
						return (
							<StaffCard
								isSelected={
									(currentIndex !== undefined &&
										bookingDetails[currentIndex].stylistId === x.id) ||
									tempStylist?.id === x.id
								}
								className="staff-item col-span-1 aspect-[4/2.5]"
								onSelect={() => onSelect(x)}
								showTurn
								staff={x}
							/>
						);
					})}
				</div>
			</LoadingProvider>
		</div>
	);
};
