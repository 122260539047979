import { Card, Input, Switch, TextArea } from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { AlertHelper } from 'helpers';
import { IApiResponse, IItemCategory } from 'models';
import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CategoryApiService } from 'services';
import { I18n, _t, translations, t } from 'utils';
import { IEditCategoryRequest } from 'models/RequestModels';
import { useAppSelector } from 'helpers/hookHelpers';
import { showError } from 'helpers/alertHelper';
import { useDispatch } from 'react-redux';
import { CategoryActions } from 'redux/actions';
import _ from 'lodash';
type ICategoryForm = IItemCategory & {};

export const CategoryDetail = () => {
	const dispatch = useDispatch();
	const form = useForm<ICategoryForm>({
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	const location = useLocation();
	const history = useHistory();
	const { id } = useParams() as { id: string };

	const [loading, setLoading] = useState(false);
	const shopId = useAppSelector((state) => state.ShopReducer.shopId);
	const [category, setCategory] = useState<IItemCategory>();
	const isAdd = location.pathname.includes('add');

	useEffect(() => {
		if (id) {
			getCategoryById();
		}
		if (isAdd) {
			form.reset({ status: 1 });
		}
	}, []);

	const submitForm = async (formData: ICategoryForm) => {
		setLoading(true);
		const data: Partial<IEditCategoryRequest> = {
			...category,
			description: formData.description,
			name: formData.name,
			status: formData.status,
			shopId,
		};

		const res = await (isAdd && _.isEmpty(data.id)
			? CategoryApiService.addCategory(data as any)
			: CategoryApiService.editCategory(data as any));
		setLoading(false);

		if (res.succeeded) {
			setLoading(false);
			AlertHelper.showSuccess(
				isAdd && _.isEmpty(data.id)
					? I18n.t(_t(translations.categoryDetail.messageSuccess))
					: I18n.t(_t(translations.categoryDetail.messageEditSuccess))
			);
			isAdd && _.isEmpty(data.id)
				? dispatch(
						CategoryActions.addCategoryLocal.request(
							res.data as unknown as IItemCategory
						)
				  )
				: dispatch(
						CategoryActions.updateCategoryLocal.request(
							res.data as unknown as IItemCategory
						)
				  );
			history.goBack();
		} else {
			AlertHelper.showError(res);
		}
	};

	const getCategoryById = async () => {
		setLoading(true);
		const res = await CategoryApiService.getCategoryById(id);
		setLoading(false);
		if (res.succeeded) {
			const data = res.data;
			setCategory(data);
			form.reset({
				id: data?.id,
				name: data?.name,
				description: data?.description,
				status: data?.status,
			});
		} else {
			showError(res);
		}
	};

	return (
		<DetailPageLayout<ICategoryForm>
			title={I18n.t(
				_t(
					isAdd && _.isEmpty(id)
						? translations.categoryDetail.titleAddNew
						: translations.categoryDetail.titleEdit
				)
			)}
			form={form}
			onSave={form.handleSubmit(submitForm)}
			loading={loading}
		>
			<Body />
		</DetailPageLayout>
	);
};

const Body = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		control,
		register,
	} = useFormContext<ICategoryForm>();
	return (
		<Card
			title={t(translations.categoryDetail.title)}
			description={I18n.t(_t(translations.categoryDetail.subTitle))}
			className="mx-48 flex flex-col gap-3"
		>
			<Input
				label={I18n.t(_t(translations.categoryDetail.labelName))}
				maxLength={256}
				error={errors.name?.message}
				{...register('name', {
					required: I18n.t(_t(translations.categoryDetail.messageName)),
				})}
				placeholder={I18n.t(_t(translations.placeHolder.categoryName))}
			/>
			<TextArea
				label={I18n.t(_t(translations.categoryDetail.labelDescription))}
				placeholder={I18n.t(_t(translations.placeHolder.description))}
				{...register('description')}
			/>
			<Switch
				value={watch('status') === 1 ? true : false}
				onChange={(checked) => {
					setValue('status', checked === true ? 1 : 0);
				}}
				label="Status"
				labelClassName="w-fit"
				checkBoxClassName="ml-2"
			/>
		</Card>
	);
};
