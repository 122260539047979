import { TabView } from 'components/molecules/Tab';
import React from 'react';
import ListTextMessage from './components/ListTextMessage';
import ListTextTemplate from './components/ListTextTemplate';

export const TextMessage = () => {
	return (
		<div className="h-full overflow-hidden">
			<TabView
				tabs={['Text Message', 'Template']}
				tabPanels={[<ListTextMessage />, <ListTextTemplate />]}
			/>
		</div>
	);
};
