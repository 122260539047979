import { ReduxHelper } from 'helpers';
import { IRole } from 'models';
import { IRoleRequest } from 'models/RequestModels';

export const prefix = 'ROLE';

const getAllRoles = ReduxHelper.generateActions<
	IRoleRequest | undefined,
	IRole[]
>(`${prefix}/GET_ALL_ROLE`);

export { getAllRoles };
