import { ILoyaltyRank } from 'models/ILoyalRank';
import { ILoyaltySetting } from 'models/ILoyalty';
import { BaseApiService } from './BaseApiService';

class LoyaltyApiService extends BaseApiService {
	public getLoyaltySetting = () =>
		this.get<ILoyaltySetting>(`/LoyaltySetting/get-loyalty-setting`);
	public createLoyaltySetting = (request: Partial<ILoyaltySetting>) =>
		this.post<Partial<ILoyaltySetting>, ILoyaltySetting>(
			`/LoyaltySetting/create-loyalty-setting`,
			request
		);
	public updateLoyaltySetting = (request: Partial<ILoyaltySetting>) =>
		this.put<Partial<ILoyaltySetting>, ILoyaltySetting>(
			`/LoyaltySetting/update-loyalty-setting`,
			request
		);
	// public deleteExpense = (id: string) =>
	// 	this.delete<string>(`/LoyaltySetting/delete-expense/${id}`);
	public getAllLoyaltyRanks = () =>
		this.get<ILoyaltyRank[]>(`/LoyaltyRank/get-all-loyalty-ranks`, {
			params: {
				OrderBy: 'pointToReach',
			},
		});
	public getLoyaltyRankById = (id: string) =>
		this.get<ILoyaltyRank>(`/LoyaltyRank/get-loyalty-rank/${id}`);
	public createLoyaltyRank = (request: Partial<ILoyaltyRank>) =>
		this.post<Partial<ILoyaltyRank>, ILoyaltyRank>(
			`/LoyaltyRank/create-loyalty-rank`,
			request
		);
	public updateLoyaltyRank = (request: Partial<ILoyaltyRank>) =>
		this.put<Partial<ILoyaltyRank>, ILoyaltyRank>(
			`/LoyaltyRank/update-loyalty-rank`,
			request
		);
	public deleteLoyaltyRank = (id: string) =>
		this.delete<string>(`/LoyaltyRank/delete-loyalty-rank/${id}`);
}

export default new LoyaltyApiService(true);
