// import { notification } from 'antd';
import { IApiResponse, IErrorResponse } from 'models';
import { ErrorActions } from 'redux/actions';
import configureStore from 'redux/configuration/configureStore';
import { t, translations } from 'utils';
import _ from 'lodash';
import toast from 'react-hot-toast';
export function showAlert(
	title: string,
	type: 'error' | 'success' | 'info' | 'warning' | 'warn' | 'open',
	onClose?: () => void
) {
	switch (type) {
		case 'error':
			toast.error(title);
			break;
		case 'success':
			toast.success(title);
			break;
		default:
			toast(title);
			break;
	}
	// notification[type]({
	// 	message: title,
	// 	description: description,
	// 	onClose: onClose,
	// });
}

export function showError(
	error: IApiResponse<any> | IErrorResponse,
	onClose = () => {
		const store = configureStore().store;
		store.dispatch(ErrorActions.clearError.request());
	}
) {
	showAlert(
		!_.isEmpty(error)
			? error.message[0]?.Text || error.message[0]?.text! || ''
			: t(translations.text.criticalError),
		'error',
		onClose
	);
}

export function showSuccess(message: string) {
	showAlert(message || t(translations.text.success), 'success');
}
