import clsxm from 'clsxs/clsxm';
import { Button, Icon, Input, Select, Switch } from 'components/atoms';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { translations, t, COUNTRIES } from 'utils';
import {
	mdiAccount,
	mdiCheckCircle,
	mdiEmail,
	mdiHome,
	mdiLock,
	mdiPhone,
	mdiStore,
} from '@mdi/js';
import {
	Controller,
	FormProvider,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { IShopRegister } from 'models/RequestModels';
import { MaskInput } from 'components/atoms/MaskInput';
import { debounce, isEmpty } from 'lodash';
import {
	getAllCity,
	getAllStates,
	getLocationFromZip,
	getZipFromLocation,
	ICity,
	IState,
} from 'helpers/geolocation';
import { ThemeApiService } from 'services';
import { IApiResponse, IErrorResponse, ITheme } from 'models';
import { AlertHelper } from 'helpers';
import { LoadingProvider } from 'contexts/LoadingContext';
import AuthenticationApiService from 'services/AuthenticationApiService';
import { showAlert, showError } from 'helpers/alertHelper';
import tzlookup from 'tz-lookup';

const FormInFo = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
		control,
	} = useFormContext<IShopRegister>();
	const countryCode = watch('address.countryCode');
	return (
		<div>
			<h4 className="mt-0">{t(translations.partner.yourInfo)}</h4>
			<div className="grid gap-y-4">
				<div className="grid grid-cols-2 gap-x-2">
					<Input
						renderBefore={<Icon size={0.8} path={mdiAccount} />}
						placeholder={t(translations.placeHolder.firstName)}
						{...register('firstName', {
							required: t(translations.register.firstNameRequire),
						})}
						error={errors.firstName?.message}
					/>
					<Input
						renderBefore={<Icon size={0.8} path={mdiAccount} />}
						placeholder={t(translations.placeHolder.lastName)}
						{...register('lastName', {
							required: t(translations.register.lastNameRequire),
						})}
						error={errors.lastName?.message}
					/>
				</div>
				<div className="grid grid-cols-3 gap-x-2">
					<Select<{ name: string; code: string }>
						options={COUNTRIES}
						titleField="name"
						keyField="code"
						fullWidth
						valueId={countryCode}
						onChange={(value) => setValue('address.countryCode', value.code)}
					/>
					<Controller
						render={(inputProps) => (
							<MaskInput
								renderBefore={<Icon size={0.8} path={mdiPhone} />}
								error={errors.phone?.message}
								placeholder={t(translations.placeHolder.phone)}
								{...inputProps.field}
								mask="999-999-9999"
							/>
						)}
						rules={{
							required: t(translations.register.phoneRequire),
						}}
						control={control}
						name={'phone'}
					/>
					<Input
						renderBefore={<Icon size={0.8} path={mdiEmail} />}
						placeholder={t(translations.placeHolder.email)}
						{...register('email', {
							required: t(translations.register.emailRequire),
							pattern: {
								value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
								message: 'Please enter a valid email address',
							},
						})}
						error={errors.email?.message}
					/>
				</div>
			</div>
		</div>
	);
};

const FormSalon = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<IShopRegister>();
	const adminUrl = watch('adminUrl');
	return (
		<div>
			<h4 className="mt-0">{t(translations.partner.yourSalon)}</h4>
			<div className="grid gap-y-4">
				<div className="grid grid-cols-2 gap-x-2">
					<Input
						renderBefore={<Icon size={0.8} path={mdiStore} />}
						placeholder={t(translations.placeHolder.shopName)}
						{...register('shopName', {
							required: t(translations.register.shopName),
						})}
						error={errors.shopName?.message}
						onBlur={(event) => {
							if (isEmpty(adminUrl)) {
								setValue(
									'adminUrl',
									event.target.value.toLowerCase().replace(' ', '')
								);
							}
						}}
					/>
					<Input
						renderBefore={<Icon size={0.8} path={mdiStore} />}
						placeholder={t(translations.placeHolder.shopId)}
						{...register('adminUrl', {
							required: t(translations.register.shopName),
						})}
						error={errors.adminUrl?.message}
					/>
				</div>
				<FormAddress />
			</div>
		</div>
	);
};
const FormAdminAccount = () => {
	const {
		formState: { errors },
		watch,
		register,
	} = useFormContext<IShopRegister>();
	return (
		<div>
			<h4 className="mt-0">{t(translations.partner.yourAminAccount)}</h4>
			<div className="grid gap-y-4">
				<div className="grid grid-cols-3 gap-x-2">
					<Input
						renderBefore={<Icon size={0.8} path={mdiAccount} />}
						placeholder={t(translations.placeHolder.userName)}
						{...register('userName', {
							required: t(translations.register.userName),
							minLength: {
								value: 6,
								message: t(translations.register.userName),
							},
						})}
						error={errors.userName?.message}
					/>
					<Input
						renderBefore={<Icon size={0.8} path={mdiLock} />}
						placeholder={t(translations.login.loginPassword)}
						type="password"
						{...register('password', {
							required: t(translations.login.inputPasswordMessage),
							pattern: {
								value:
									/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g,
								message: t(translations.register['formatPasswordMessage=']),
							},
						})}
						error={errors.password?.message}
					/>
					<Input
						renderBefore={<Icon size={0.8} path={mdiLock} />}
						placeholder={t(translations.resetPassword.confirmPassword)}
						type="password"
						{...register('confirmPassword', {
							required: t(translations.resetPassword.confirmPasswordMessage),
							validate: (val: string) => {
								if (watch('password') !== val) {
									return t(translations.resetPassword.confirmNotMatch);
								}
							},
						})}
						error={errors.confirmPassword?.message}
					/>
				</div>
			</div>
		</div>
	);
};
const FormAddress = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<IShopRegister>();
	const [states, setStates] = useState<IState[]>([]);
	const [cities, setCities] = useState<ICity[]>([]);
	const selectedState = watch('address.state');
	const selectedCity = watch('address.city');

	useEffect(() => {
		register('address.city', {
			required: t(translations.partner.cityRequired),
		});
		register('address.state', {
			required: t(translations.partner.stateRequired),
		});
	}, [register]);

	const getStates = async () => {
		const _states = await getAllStates();
		setStates(_states);
	};
	useEffect(() => {
		getStates();
	}, []);
	useEffect(() => {
		if (selectedState) {
			const selectedStateCode = states.find(
				(state) => state.name === selectedState
			)?.isoCode;
			selectedStateCode && onSelectState(selectedStateCode);
		}
	}, [states]);
	const onChangeZipCode = useCallback<(zipCode: string) => void>(
		debounce<(zipCode: string) => void>((zipCode: string) => {
			if (!isEmpty(zipCode)) {
				const location = getLocationFromZip(zipCode);
				if (!isEmpty(location)) {
					setValue('address.city', location?.city || '');
					setValue(
						'address.state',
						states.find((state) => state.isoCode === location?.state)?.name ||
							''
					);
					onSelectState(location?.state || '');
				}
			}
		}, 200),
		[states]
	);
	const onSelectState = async (code: string) => {
		const cities = await getAllCity(code);
		const currentState = states?.find((x) => x.isoCode === code);
		if (currentState) {
			setValue('address.state', currentState!.name);
			setValue(
				'localTimeZone',
				tzlookup(currentState.latitude as any, currentState.longitude as any)
			);
		}
		if (cities) {
			setCities(cities);
			const currentCity = cities.find((x) => x.name === selectedCity);
			if (currentCity) {
				setValue('address.city', currentCity!.name);
				setValue(
					'localTimeZone',
					tzlookup(currentCity.latitude as any, currentCity.longitude as any)
				);
			}
		}
	};
	const onSelectCity = (name: string) => {
		setValue('address.city', name);
		const currentCity = cities.find((x) => x.name === name);
		if (!isEmpty(currentCity)) {
			setValue(
				'localTimeZone',
				tzlookup(currentCity?.latitude as any, currentCity?.longitude as any)
			);
		}
		const currentState = states?.find((x) => x.name === selectedState);
		const zipCodes = getZipFromLocation(currentState!.isoCode, name);
		if (!isEmpty(zipCodes)) {
			setValue('address.zipCode', zipCodes[0].zip);
		}
	};

	return (
		<div>
			<div className="grid gap-y-4">
				<Input
					renderBefore={<Icon size={0.8} path={mdiHome} />}
					placeholder={t(translations.partner.yourShopAddress)}
					{...register('address.street', {
						required: t(translations.partner.streetRequired),
					})}
					error={errors.address?.street?.message}
				/>
				<div className="grid grid-cols-3 gap-x-2">
					<Select<ICity>
						titleField={'name'}
						keyField={'name'}
						options={cities}
						value={
							cities.find((city) => city.name === selectedCity) || {
								name: 'Select City',
								stateCode: '',
							}
						}
						onClick={(value) => {
							onSelectCity(value.name);
						}}
						fullWidth
						error={errors.address?.city?.message}
					/>
					<Select<IState>
						titleField={'name'}
						keyField={'isoCode'}
						options={states}
						value={
							states.find((state) => state.name === selectedState) || {
								name: 'Select State',
								isoCode: '',
							}
						}
						onClick={(value) => {
							setValue('address.state', value.name);
							onSelectState(value.isoCode!);
						}}
						fullWidth
						error={errors.address?.state?.message}
					/>
					<Input
						placeholder={t(translations.partner.zipCode)}
						error={errors.address?.zipCode?.message}
						{...register('address.zipCode', {
							required: t(translations.partner.zipCodeRequired),
							onChange: (e) => {
								onChangeZipCode(e.target.value);
							},
						})}
					/>
				</div>
			</div>
		</div>
	);
};
const FormTheme = (props: { themes: ITheme[] }) => {
	const {
		formState: { errors },
		watch,
		setValue,
	} = useFormContext<IShopRegister>();
	const selectedTheme = watch('themeShopConfig.themeName');
	return (
		<div>
			<h4 className="mt-0">{t(translations.shopSetting.landingPageTheme)}</h4>
			<div className="grid gap-y-4">
				<div className="grid grid-cols-3 gap-x-2">
					<Select<ITheme>
						titleField={'themeName'}
						keyField={'themeName'}
						options={props.themes}
						valueId={selectedTheme}
						onClick={(value) => {
							setValue('themeShopConfig.themeName', value.themeName);
							setValue('themeShopConfig', value);
						}}
						fullWidth
						error={errors.themeShopConfig?.message}
					/>
				</div>
			</div>
		</div>
	);
};

const FormCreateSampleData = () => {
	const {
		formState: { errors },
		watch,
		setValue,
	} = useFormContext<IShopRegister>();
	const createSampleData = watch('createSampleData');
	return (
		<div className="grid grid-cols-3">
			<div className="flex text-center items-center justify-between">
				<h4 className="m-0">{t(translations.shopSetting.createSampleData)}</h4>
				<Switch
					// label={t(translations.shopSetting.createSampleData)}
					primary
					value={createSampleData}
					onChange={(value) => {
						setValue('createSampleData', value);
					}}
				/>
			</div>
		</div>
	);
};

export const PartnerRegister = () => {
	const [loading, setLoading] = useState(false);
	const [themes, setThemes] = useState<ITheme[]>([]);
	const [done, setDone] = useState(false);
	const history = useHistory();
	const form = useForm<IShopRegister>({
		defaultValues: {
			address: {
				countryCode: 'US',
			},
			createSampleData: false,
		},
		reValidateMode: 'onChange',
		mode: 'onChange',
	});

	const onSubmit = async (formData: IShopRegister) => {
		setLoading(true);
		const newShop: IShopRegister = {
			...(formData as IShopRegister),
			shopCategoryId: '0144ff7a-7159-4ac5-9c6b-323bb98a47bd',
			portalUrl: `${formData.adminUrl}.website.smartsalon.live`,
		} as any;

		const response = await AuthenticationApiService.register(newShop);
		let successResponse = response;
		setLoading(false);
		if (successResponse.succeeded) {
			showAlert(t(translations.partner.registerSuccessfully), 'success');
			showAlert(t(translations.partner.checkMail), 'success');
			setDone(true);
			history.push('/confirm-success');
		} else {
			showError(response as IErrorResponse);
		}
	};

	const getAllThemes = async () => {
		setLoading(true);
		try {
			const result = (await ThemeApiService.getAllThemes({
				pageNumber: 1,
				pageSize: 100,
			})) as IApiResponse<ITheme[]>;
			if (result.succeeded) {
				setThemes(result.data || []);
				if (!isEmpty(result.data)) {
					form.setValue(
						'themeShopConfig',
						result.data!.find((theme) => theme.themeName === 'lavi') ||
							result.data![0]
					);
				}
			} else {
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error('Network error', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getAllThemes();
	}, []);

	return (
		<>
			<div className={clsxm('flex flex-col items-center w-full bg-[#F4F3F8]')}>
				<img
					alt=""
					className={clsxm('w-48 h-48')}
					src="./logo-square.png"
				></img>
				<h4 className={clsxm('mt-[-30px]')}>{t(translations.login.slogan)}</h4>
				{done ? (
					<div className="text-center my-24">
						<div className="w-full flex justify-center">
							<Icon path={mdiCheckCircle} size={3} color="#52c41a" />
						</div>
						<div className="font-semibold text-2xl">
							<strong>{t(translations.partner.register)}</strong>
						</div>
						<div className="mt-2">
							<span>{t(translations.partner.checkMail)}</span>
						</div>
					</div>
				) : (
					<div className="rounded-lg bg-base-100 shadow-lg w-1/2 min-w-96 gap-3 mt-6">
						<LoadingProvider loading={loading}>
							<FormProvider {...form}>
								<div className="card-body">
									<FormInFo />
									<div className="divider my-2" />
									<FormSalon />
									<div className="divider my-2" />
									<FormAdminAccount />
									<div className="divider my-2" />
									<FormTheme themes={themes} />
									<div className="divider my-2" />
									<FormCreateSampleData />
									<div className="card-actions justify-end mt-4">
										<Button
											type="submit"
											primary
											small
											title={t(translations.partner.register)}
											onClick={form.handleSubmit(onSubmit)}
										/>
										<Button
											white
											small
											className="border-dashed border-1 shadow-none"
											title={t(translations.back)}
											onClick={() => history.push('/login')}
										/>
									</div>
								</div>
							</FormProvider>
						</LoadingProvider>
					</div>
				)}
			</div>
			<footer className="footer footer-center p-4  text-base-content bg-[#F4F3F8]">
				<div>
					<p>Copyright © 2022 - SCSSolutions.io</p>
				</div>
			</footer>
		</>
	);
};
