/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonGroup, TextArea, TimeRangePicker } from 'components/atoms';
import { DetailPageLayout } from 'components/molecules';
import { DatePicker } from 'components/molecules/DatePicker';
import { AlertHelper, BookingHelper, TimeHelper } from 'helpers';
import { convertTimeRequest } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import { toTimeZone } from 'helpers/timeHelper';
import _ from 'lodash';
import { IApiResponse } from 'models';
import { ICloseOnlineBooking } from 'models/ICloseOnlinebooking';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { CloseOnlineBookingActions } from 'redux/actions';
import { history } from 'routers';
import CloseOnlineBookingApiService from 'services/CloseOnlineBookingApiService';
import { t, translations } from 'utils';
import * as yup from 'yup';

export const ClosedOnlineBookingDetail = () => {
	const { params, path } = useRouteMatch<{ id?: string }>();
	const isAdd = useMemo(() => {
		if (path.includes('edit')) {
			return false;
		} else {
			return true;
		}
	}, [params]);
	const { currentClosedOnlineBooking } = useAppSelector(
		(state) => state.CloseOnlineBookingReducer
	);
	const currentBranch = useAppSelector(
		(state) => state.BranchReducer.currentBranch
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isGetByIdLoading, setIsGetByIdLoading] = useState<boolean>(false);
	const [selectedDateType, setSelectedDateType] = useState<number>(
		isAdd ? 0 : 2
	);
	const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>();

	const dispatch = useDispatch();

	const form = useForm<Partial<ICloseOnlineBooking>>({
		defaultValues: getDefaultValue(currentClosedOnlineBooking),
		resolver: yupResolver(validationSchema),
	});
	const { setValue, watch, register } = form;
	const selectedDate = watch('date');
	const selectedStartTime = watch('startTime') as string;
	const selectedEndTime = watch('endTime') as string;

	useEffect(() => {
		if (!isAdd) {
			if (!params.id) {
				history.push('/close-online-booking');
			} else if (_.isEmpty(currentClosedOnlineBooking)) {
				getClosedOnlineBookingById(params.id);
			}
		}
		return () => {
			dispatch(CloseOnlineBookingActions.selectCloseOnlineBooking.request());
		};
	}, []);

	useEffect(() => {
		switch (selectedDateType) {
			case 0: {
				setValue('date', moment().format('YYYY-MM-DD'));
				setIsShowDatePicker(false);
				break;
			}
			case 1: {
				setValue('date', moment().add(1, 'day').format('YYYY-MM-DD'));
				setIsShowDatePicker(false);
				break;
			}
			case 2: {
				setValue('date', moment().format('YYYY-MM-DD'));
				let timeout = setTimeout(() => {
					setIsShowDatePicker(true);
					clearTimeout(timeout);
				}, 100);
				break;
			}
		}
	}, [selectedDateType]);

	const onFormSubmit = (value: Partial<ICloseOnlineBooking>) => {
		if (isAdd) {
			createClosedOnlineBooking(value);
		} else {
			if (
				moment(selectedDate).startOf('date').isBefore(moment().startOf('date'))
			) {
				return AlertHelper.showAlert(
					t(translations.closedOnlineBooking.cantUpdatePast),
					'error'
				);
			}
			updateClosedOnlineBooking(value as ICloseOnlineBooking);
		}
	};

	const onDeletePress = () => {
		if (
			moment(selectedDate).startOf('date').isBefore(moment().startOf('date'))
		) {
			AlertHelper.showAlert(
				t(translations.closedOnlineBooking.cantUpdatePast),
				'error'
			);
		} else {
			deleteClosedOnlineBooking(params.id!);
		}
	};

	const getClosedOnlineBookingById = async (id: string) => {
		setIsGetByIdLoading(true);
		try {
			const result = (await CloseOnlineBookingApiService.getById(
				id
			)) as IApiResponse<ICloseOnlineBooking>;
			if (result.succeeded && !_.isEmpty(result.data)) {
				form.reset(result.data);
			} else {
				AlertHelper.showError(result);
				history.push('/close-online-booking');
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsGetByIdLoading(false);
		}
	};

	const createClosedOnlineBooking = async (
		request: Partial<ICloseOnlineBooking>
	) => {
		setIsLoading(true);
		try {
			const result = (await CloseOnlineBookingApiService.add({
				...request,
				branchId: currentBranch?.id,
			})) as IApiResponse<ICloseOnlineBooking>;
			if (result.succeeded && !_.isEmpty(result.data)) {
				AlertHelper.showSuccess(t(translations.closedOnlineBooking.addSuccess));
				history.goBack();
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsLoading(false);
		}
	};

	const updateClosedOnlineBooking = async (request: ICloseOnlineBooking) => {
		setIsLoading(true);
		try {
			const result = (await CloseOnlineBookingApiService.update(
				request
			)) as IApiResponse<ICloseOnlineBooking>;
			if (result.succeeded && !_.isEmpty(result.data)) {
				AlertHelper.showSuccess(
					t(translations.closedOnlineBooking.updateSuccess)
				);
				history.goBack();
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsLoading(false);
		}
	};

	const deleteClosedOnlineBooking = async (id: string) => {
		setIsLoading(true);
		try {
			const result = (await CloseOnlineBookingApiService.deleteById(
				id
			)) as IApiResponse<string>;
			if (result.succeeded && !_.isEmpty(result.data)) {
				AlertHelper.showSuccess(
					t(translations.closedOnlineBooking.deleteSuccess)
				);
				history.goBack();
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<DetailPageLayout
			loading={isLoading || isGetByIdLoading}
			form={form}
			deleteTitle={t(
				translations.closedOnlineBooking.deleteClosedOnlineBooking
			)}
			title={
				isAdd
					? t(translations.closedOnlineBooking.addClosedOnlineBooking)
					: t(translations.closedOnlineBooking.updateClosedOnlineBooking)
			}
			showDelete={!isAdd}
			onDelete={onDeletePress}
			onSave={form.handleSubmit(onFormSubmit)}
		>
			{!isGetByIdLoading && (
				<div className="grid grid-cols-6">
					<div className="col-span-2" />
					<div className="col-span-2 grid gap-4">
						<ButtonGroup<{ id: number; title: string }>
							buttons={[
								{ id: 0, title: t(translations.closedOnlineBooking.today) },
								{ id: 1, title: t(translations.closedOnlineBooking.tomorrow) },
								{
									id: 2,
									title: t(translations.closedOnlineBooking.selectDate),
								},
							]}
							titleField={'title'}
							valueField={'id'}
							onChange={(value) => setSelectedDateType(value.id)}
							disabled={isLoading}
							selectedId={selectedDateType}
						/>
						{isShowDatePicker && (
							<DatePicker
								selectedDate={moment(selectedDate)}
								onSelectDate={(date) =>
									setValue('date', date.format('YYYY-MM-DD'))
								}
								format={'MMMM Do, YYYY'}
								disabled={isLoading}
								disabledBefore
							/>
						)}
						<TimeRangePicker
							defaultValue={[
								TimeHelper.getInitialWorkingTime(selectedStartTime, undefined, true),
								TimeHelper.getInitialWorkingTime(selectedEndTime, undefined, true),
							]}
							onChooseTime={(time) => {
								if (
									time[1]
										.clone()
										.utc()
										.startOf('date')
										.isAfter(time[0].clone().utc().startOf('date'))
								) {
									setValue(
										'startTime',
										BookingHelper.convertTimeRequest(time[0])
									);
									setValue(
										'endTime',
										BookingHelper.convertTimeRequest(time[1], true)
									);
								} else {
									setValue('startTime', convertTimeRequest(time[0]));
									setValue('endTime', convertTimeRequest(time[1]));
								}
							}}
							// startProps={{
							// 	value: TimeHelper.getInitialWorkingTime(selectedStartTime),
							// }}
							// endProps={{
							// 	value: TimeHelper.getInitialWorkingTime(selectedEndTime),
							// }}
							disabled={isLoading}
						/>
						<TextArea
							label={t(translations.closedOnlineBooking.description)}
							{...register('description', { disabled: isLoading })}
						/>
					</div>
					<div className="col-span-2" />
				</div>
			)}
		</DetailPageLayout>
	);
};

const validationSchema = yup.object().shape({
	date: yup
		.string()
		.required(t(translations.closedOnlineBooking.dateRequired))
		.test(
			'date-in-the-past',
			t(translations.closedOnlineBooking.pastDate),
			(value) =>
				moment(value).startOf('date').isBefore(moment().startOf('date'))
					? false
					: true
		),
	startTime: yup
		.string()
		.required(t(translations.closedOnlineBooking.timeRequired)),
	endTime: yup
		.string()
		.required(t(translations.closedOnlineBooking.timeRequired)),
});

const getDefaultValue = (
	value?: Partial<ICloseOnlineBooking>
): Partial<ICloseOnlineBooking> => {
	if (value) {
		return value;
	} else {
		return {
			date: moment().startOf('date').format('YYYY-MM-DD'),
			startTime: BookingHelper.convertTimeRequest(
				toTimeZone().hour(8).startOf('hour')
			),
			endTime: BookingHelper.convertTimeRequest(
				toTimeZone().hour(22).startOf('hour')
			),
		};
	}
};
