import { ReduxHelper } from 'helpers';
import { IUser } from 'models/IUser';
import {
	IForgotPasswordRequest,
	ILoginByPinRequest,
	ILoginRequest,
	IOwnerLoginRequest,
	IResetPasswordRequest,
} from 'models/RequestModels';

export const prefix = 'AUTHENTICATION';

const authenticate = ReduxHelper.generateActions<ILoginRequest, void, any>(
	`${prefix}/AUTHENTICATE`
);
const ownerAuthenticate = ReduxHelper.generateActions<
	IOwnerLoginRequest,
	void,
	any
>(`${prefix}/OWNER_AUTHENTICATE`);
const authenticateWithPin = ReduxHelper.generateActions<
	ILoginByPinRequest,
	IUser,
	any
>(`${prefix}/AUTHENTICATE_WITH_PIN`);
const setToken = ReduxHelper.generateLocalAction<{
	token: string;
	refreshToken: string;
}>(prefix + '/SET_TOKEN');

const setDeviceId = ReduxHelper.generateLocalAction<{ deviceId: string }>(
	prefix + '/SET_DEVICE_ID'
);

const clearError = ReduxHelper.generateLocalAction(`${prefix}/CLEAR_ERROR`);
const logOut = ReduxHelper.generateLocalAction(`${prefix}/LOG_OUT`);
const forgotPassword = ReduxHelper.generateActions<IForgotPasswordRequest>(
	`${prefix}/FORGOT_PASSWORD`
);
const resetPassword = ReduxHelper.generateActions<IResetPasswordRequest>(
	`${prefix}/RESET_PASSWORD`
);
const setAdminAccount = ReduxHelper.generateLocalAction<string>(
	`${prefix}/SET_ADMIN_ACCOUNT`
);
const clearToken = ReduxHelper.generateLocalAction(`${prefix}/CLEAR_TOKEN`);

export {
	authenticate,
	setToken,
	clearError,
	logOut,
	forgotPassword,
	resetPassword,
	setDeviceId,
	setAdminAccount,
	authenticateWithPin,
	ownerAuthenticate,
	clearToken,
};
