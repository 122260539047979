import { mdiRefresh, mdiWalk } from '@mdi/js';
import { Button, Icon } from 'components/atoms';
import { Avatar } from 'components/atoms/Avatar';
import { Tag } from 'components/atoms/Tag';
import { CustomerModal } from 'components/molecules/CustomerModal';
import { StringHelper } from 'helpers';
import { ICustomer } from 'models';
import React, { useState } from 'react';
import { t, translations } from 'utils';
import { WALKIN_CUSTOMER } from 'utils/Consts';
import { useCheckOutContext } from '../context';
export const CustomerInfo = () => {
	const { customer, setCustomer, done } = useCheckOutContext();
	const [showModal, setShowModal] = useState(false);
	if (!customer) {
		return (
			<>
				<div className="grid grid-cols-5 col-span-2 w-full gap-1">
					<Button
						small
						onClick={() => setShowModal(true)}
						primary
						className="text-white  col-span-3"
						title={t(translations.bookingCustomerInfo.chooseCustomer)}
					/>
					<Button
						small
						className="btn-outline btn-primary col-span-2 bg-white"
						onClick={() => setCustomer(WALKIN_CUSTOMER)}
						title={t(translations.checkout.walkInCustomer)}
						iconBefore={<Icon path={mdiWalk} />}
					/>
					{/* <Button
						small
						onClick={() => setShowModal(true)}
						className="text-white  col-span-2"
						title={t(translations.bookingCustomerInfo.chooseCustomer)}
					/> */}
				</div>

				{showModal && (
					<CustomerModal
						onSelectCustomer={(x) => {
							setCustomer(x as ICustomer);
						}}
						onCancel={() => setShowModal(false)}
					/>
				)}
			</>
		);
	}
	return (
		<div className="col-span-2 grid grid-cols-6 gap-2">
			<div
				id="customer-card"
				className="col-span-4 bg-white rounded-lg px-4 py-2 flex"
			>
				<div>
					<Avatar
						small
						name={StringHelper.getShortName(
							`${customer.firstName || ''} ${customer.lastName || ''}`
						)}
					/>
					{/* <Icon path={mdiDelete} className="text-DELETED" /> */}
				</div>
				<div className="ml-4 flex flex-col flex-1 ">
					<span className="p-0 font-semibold text-sm ">
						{`${customer.firstName || ''} ${customer.lastName || ''}`}
						{customer.newCustomer && (
							<Tag
								label={t(translations.bookingCustomerInfo.new)}
								className="p-2 bg-[#9CD892] text-xs"
							/>
						)}
					</span>
					<span className="p-0 text-sm ">{`${StringHelper.formatPhoneNumber(
						customer.phone
					)}`}</span>
				</div>
			</div>
			{!done && (
				<Button
					small
					className="btn-outline btn-primary col-span-2 text-white bg-FINISHED"
					onClick={() => setShowModal(true)}
					title={t(translations.checkout.change)}
					iconBefore={<Icon path={mdiRefresh} />}
				/>
			)}
			{showModal && (
				<CustomerModal
					onSelectCustomer={(x) => {
						setCustomer(x as ICustomer);
					}}
					onCancel={() => setShowModal(false)}
				/>
			)}
		</div>
	);
};
