import React, { useEffect, useState } from 'react';

import { I18n, _t, translations } from 'utils';
import { useHistory } from 'react-router-dom';
import { ITextMessage } from 'models/ITextMessage';
import TextMessageApiService from 'services/TextMessageApiService';
import { IApiResponse, IErrorResponse } from 'models';
import { AlertHelper } from 'helpers';
import moment from 'moment';
import Table, { EColumnType } from 'components/atoms/Table';
import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, ConfirmModal, Modal } from 'components/atoms';
import clsxm from 'clsxs/clsxm';
import TextTemplateApiService from 'services/TextTemplateApiService';
import { ListButton } from './ListTextMessage';
import { ITextTemplate } from 'models/ITextTemplate';

const ListTextTemplate = () => {
	const history = useHistory();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState(false);
	const [templateId, setTemplateId] = useState('');

	const columns = [
		{
			header: I18n.t(_t(translations.textMessage.title)),
			key: 'name',
		},
		{
			header: '',
			key: 'name',
			renderCell: () => <div className="flex flex-1 w-40" />,
		},
		{
			header: 'Actions',
			key: 'id',
			renderCell: (data: ITextTemplate) => {
				return (
					<ListButton
						onEdit={() => history.push(`text-template/edit/${data.id}`)}
						onDelete={() => {
							setTemplateId(data.id);
							setShowModal(true);
						}}
					/>
				);
			},
		},
	];

	const onDeleteTemplate = async () => {
		setLoading(true);
		try {
			const result = (await TextTemplateApiService.deleteTemplate(
				templateId
			)) as IApiResponse<string>;
			setLoading(false);
			setShowModal(false);

			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.templateDetail.messageDeleteSuccess))
				);
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {}
	};

	return (
		<>
			<Table<ITextTemplate>
				queryUrl={
					'/MarketingTextMessageTemplate/get-all-marketing-text-message-templates'
				}
				renderHeader={
					<Button
						onClick={() => history.push('text-template/add-new')}
						small
						title={I18n.t(_t(translations.addNew))}
						iconBefore={<Icon path={mdiPlus} className="w-6 h-6" />}
						primary
					/>
				}
				columns={columns as any}
				showSearch
				additionalParams={{}}
				// className="max-h-[calc(100vh-27.3rem)]"
				searchPlaceHolder={I18n.t(_t(translations.template.search))}
			/>
			<ConfirmModal
				visible={showModal}
				title={I18n.t(_t(translations.delete))}
				onNegativeClick={() => setShowModal(false)}
				onPositiveClick={() => onDeleteTemplate()}
				loading={isLoading}
				subTitle={I18n.t(_t(translations.templateDetail.deleteConfirmContent))}
			/>
		</>
	);
};

export default ListTextTemplate;
