import { mdiClose } from '@mdi/js';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import React, { useState, useEffect, useRef } from 'react';
import WebModal from 'react-modal';
import clsxm from '../../clsxs/clsxm';
import moment from 'moment';

export interface IModalProps {
	title?: string;
	subTitle?: string;
	buttons?: React.ReactNode[];
	content?: React.ReactNode;
	canConfirm?: boolean;
	confirmTitle?: string;
	cancelTitle?: string;
	disableCancel?: boolean;
	onConfirm?: () => void;
	onCancel?: () => void;
	isOpen: boolean;
	className?: string;
	contentClassName?: string;
	contentContainerClassName?: string;
}

export const Modal = (props: IModalProps) => {
	const ref = useRef<WebModal>();
	const [open, setOpen] = useState(props.isOpen);
	const { buttons = [] } = props;

	useEffect(() => {
		setOpen(props.isOpen);
	}, [props.isOpen]);
	const onCancel = () => {
		setOpen(false);
		props.onCancel && props.onCancel();
	};
	return (
		<WebModal
			ref={(x) => {
				if (x) {
					ref.current = x;
				}
			}}
			closeTimeoutMS={200}
			isOpen={open}
			id={`${moment().unix()}`}
			appElement={document.getElementById('root') || undefined}
			onRequestClose={() => onCancel()}
			shouldCloseOnOverlayClick={false}
			overlayClassName={
				'flex justify-center z-[51]  align-middle fixed top-0 bottom-0 right-0 left-0 w-full h-full bg-[#000]  bg-opacity-50 '
			}
			className={clsxm(
				'bg-primary-content absolute rounded-tl-lg w-1/3 flex flex-col  rounded-bl-lg shadow-lg top-0 right-0 bottom-0',
				props.className
			)}
		>
			<div
				className={clsxm(
					'p-4 bg-primary w-full flex justify-between text-primary-content rounded-tl-lg',
					props.contentClassName
				)}
			>
				<article className="prose">
					<h3 className="text-primary-content mb-0">{props.title}</h3>
					{props.subTitle && (
						<p className="text-primary-content mt-0 font-light text-opacity-70">
							{props.subTitle}
						</p>
					)}
				</article>
				{!props.disableCancel && (
					<Button
						id="cancel"
						onClick={() => onCancel()}
						small
						title={props.cancelTitle || 'Close'}
						iconBefore={<Icon path={mdiClose} />}
						className="btn-outline btn-error bg-white"
					/>
				)}
			</div>
			<div
				className={`flex flex-grow overflow-y-auto ${
					props.contentContainerClassName || ''
				}`}
			>
				{props.content && props.content}
			</div>
			{props.canConfirm && (
				<div className="flex justify-end gap-2 p-4 ">
					{props.canConfirm && <div className="divider mb-0"></div>}
					{buttons.map((x) => x)}
					{props.canConfirm && (
						<Button
							small
							title={props.confirmTitle || 'Save'}
							primary
							onClick={props.onConfirm}
						/>
					)}
				</div>
			)}
		</WebModal>
	);
};
