import React from 'react';
import { Select } from 'components/atoms';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { TimeHelper } from 'helpers';
import { useDashboardContext } from 'pages/Dashboard/context';
import { DateRangeType, DATE_RANGE_TYPES, PERIOD_TYPES } from 'utils/Consts';
import { EPeriodType } from 'models';
import { BranchSelect } from 'components/molecules/BranchSelect';

export const Header = () => {
	const { period, setPeriod, timeRange, setTimeRange, branch, setBranch } =
		useDashboardContext();
	const onChangePeriod = (p: typeof PERIOD_TYPES[0]) => {
		setPeriod && setPeriod(p);
		if (p.value !== EPeriodType.DATE_RANGE) {
			const dateRange = TimeHelper.generateDateRangeFromPeriod(p.value);
			setTimeRange(dateRange!);
		}
	};
	return (
		<div className="flex p-4 py-2 justify-between  rounded-xl mb-4 bg-white">
			<div className="flex gap-2 items-center">
				<span className="font-bold text-xl">Overview</span>
				<Select<typeof PERIOD_TYPES[0]>
					options={PERIOD_TYPES}
					value={period}
					titleField={'title'}
					keyField={'value'}
					size="small"
					className=" min-w-[200px]"
					onChange={(x) => onChangePeriod(x)}
					renderOptions={(item) => item.title}
					renderValueTitle={(value) => value?.title || ''}
					// disabled={isLoading}
				/>
				<DateRangePicker
					disabled={period.value !== EPeriodType.DATE_RANGE}
					onSelectDateRange={(dateRange) => {
						setTimeRange({
							fromDate: dateRange.fromDate!,
							toDate: dateRange.toDate!,
						});
					}}
					selectedDateRange={timeRange}
				/>
			</div>
			<div>
				<BranchSelect
					value={branch}
					onSelect={(branchId) => {
						setBranch && setBranch(branchId);
					}}
				/>
			</div>
		</div>
	);
};
