import I18n from 'i18n-js';
import { EPeriodType } from 'models';
import { Step } from 'react-joyride';
import { t, _t } from './locales';
import { translations } from './locales/translations';

export const version = `2.0.16`;

export enum PAYMENT_METHOD_ID {
	CLOVER = 'e6f066b8-0fcb-4b0a-aec5-31eb0ddd59c3',
}

export const WALKIN_CUSTOMER = {
	id: 'c466cb0d-d90f-4a62-80f8-dfc5e285d77a',
	additionalName: '',
	address: {},
	avarageExpensePerVisit: 0,
	code: 'WALKIN',
	color: '',
	email: '',
	emailConfirmed: true,
	firstName: 'Walk In Customer',
	firstVisit: '',
	gender: 0,
	lastExpense: 0,
	lastName: '',
	lastService: {
		id: '',
		branchIds: [],
		code: 0,
		description: '',
		duration: 0,
		employeeIds: [],
		imageUrl: '',
		itemCategoryId: '',
		imageUrls: [],
		itemPrice: 0,
		name: '',
		price: 0,
		shopId: '',
		slugName: '',
		supplyPrice: 0,
		tags: '',
		taxId: '',
	},
	lastServiceId: '',
	lastStylist: {
		branchId: '',
		code: '',
		departmentId: '',
		employeeTypeId: '',
		firstName: '',
		hireDate: new Date(),
		id: '',
		lastName: '',
		userId: '',
	},
	lastStylistId: '',
	lastVisit: '',
	newCustomer: true,
	notCome: 0,
	numberOfVisit: 0,
	phone: '',
	phoneNumberConfirmed: true,
	shopId: '',
	status: 1,
	totalExpense: 0,
	userId: '',
};

export const BookingStatus = {
	DELETED: -1,
	PENDING: 0,
	CHECKED_IN: 1,
	CANCELED: 2,
	CONFIRMED: 3,
	PROCESSING: 4,
	FINISHED: 5,
	ERROR: 6,
	DONE: 7,
	EXPIRED: 8,
	SUSPENDED: 9,
	VOID: 10,
};

export const LANGUAGE = {
	en: 'en-US',
	vi: 'vi-VN',
};

export const Colors = {
	DELETED: '#bf1919',
	PENDING: '#eaa407',
	CHECKED_IN: '#009533',
	CANCELED: '#bf1919',
	CONFIRMED: '#009533',
	PROCESSING: '#1945bf',
	FINISHED: '#7a209f',
	ERROR: '#bf1919',
	DONE: '#000000',
	EXPIRED: '#bf1919',
	SUSPENDED: '#000000',
};

// export const HolidayType = {
// 	ONCE: 0,
// 	WEEKLY: 1,
// 	MONTHLY: 2,
// };

export const WeeksOfMonth = {
	FIRST: 1,
	SECOND: 2,
	THIRD: 3,
	FOURTH: 4,
	LAST: -1,
};

export enum ModalType {
	TIP,
	DISCOUNT,
	SALE_GIFT_CARD,
	EXTRA_AMOUNT,
	COMBINE_BILl,
	APPLIED_PROMOTION,
	INPUT_COUPON,
}

export enum DiscountType {
	DOLLAR,
	PERCENT,
}

export enum DiscounterType {
	BOTH,
	OWNER_ONLY,
	EMPLOYEE_ONLY,
}

export enum PaymentType {
	CASH,
	GIFT_CARD,
	CREDIT_CARD,
	SEC,
	APP,
	E_WALLET,
	OTHER,
}

export enum TipType {
	AUTO,
	SPLIT_EVEN,
	MANUALLY,
}

export enum AppType {
	CASH_APP,
	VENMO,
	ZELLE,
}

export enum PriceType {
	SUB_TOTAL,
	TAX,
	TOTAL,
	BALANCE,
	TIP,
	GIFT_CARD,
	MAX_DISCOUNT,
	CARD_CHARGE,
}

export enum ChartRangeType {
	WEEK,
	MONTH,
}

export enum TopServiceRequestPeriodType {
	WEEK = 1,
	SEMI_MONTH,
	MONTH,
	QUARTER,
	SEMI_YEAR,
	YEAR,
}

export enum DateRangeType {
	TODAY,
	YESTERDAY,
	WEEKLY,
	SEMI_MONTH_1st,
	SEMI_MONTH_2nd,
	MONTHLY,
	DATE_RANGE,
}

export enum IWageType {
	HOURLY = 1,
	WEEKLY,
	MONTHLY,
	SEMIMONTHLY,
}

export const DefaultPassword = {
	PASSWORD: '123456aA@',
};

export const ChargeRate = {
	CARD_CHARGE: 0,
	TIP_CARD_CHARGE: 0,
	CASH_CHECK_COMMISSION: 50,
};

export const BOOKING_RULES = {
	defaultLateTime: 60,
	defaultEarly: 60,
};

export const SOCKET_STAGING_URL = 'https://test.scssolutions.io';

export enum PRINTER_CONNECTION {
	NETWORK,
	USB,
}

export enum RECEIPT_PRINT_TYPE {
	ONE, // salon
	TWO, // salon - customer
	NONE,
	ONE_CUSTOMER, // customer
}

export const DATE_TYPE = [
	{
		id: 'dateRange',
		title: I18n.t(_t(translations.dateRangeFrame.dateRange)),
		value: DateRangeType.DATE_RANGE,
	},
	{
		id: 'today',
		title: I18n.t(_t(translations.dateRangeFrame.today)),
		value: DateRangeType.TODAY,
	},
	{
		id: 'yesterday',
		title: I18n.t(_t(translations.dateRangeFrame.yesterday)),
		value: DateRangeType.YESTERDAY,
	},
	{
		id: 'weekly',
		title: I18n.t(_t(translations.dateRangeFrame.weekly)),
		value: DateRangeType.WEEKLY,
	},
	{
		id: 'semiMonth1st',
		title: I18n.t(_t(translations.dateRangeFrame.semiMonth1st)),
		value: DateRangeType.SEMI_MONTH_1st,
	},
	{
		id: 'semiMonth2nd',
		title: I18n.t(_t(translations.dateRangeFrame.semiMonth2nd)),
		value: DateRangeType.SEMI_MONTH_2nd,
	},
	{
		id: 'monthly',
		title: I18n.t(_t(translations.dateRangeFrame.monthly)),
		value: DateRangeType.MONTHLY,
	},
];

export enum WeekDays {
	SUNDAY,
	MONDAY,
	TUESDAY,
	WEDNESDAY,
	THURSDAY,
	FRIDAY,
	SATURDAY,
	WHOLEWEEK,
}

export const ThemeType = {
	SLIDER: 'Slider',
	ABOUT_US: 'AboutUs',
	WELCOME_TEXT: 'WelcomeText',
	WHY_CHOOSE: 'WhyChoose',
	GALLERY: 'Gallery',
	SERVICES: 'Services',
};

export const OnlineBookingUrl = '/customer-booking';

export const PERIOD_TYPES = [
	{
		id: 'date-range',
		title: I18n.t(_t(translations.dateRangeFrame.dateRange)),
		value: EPeriodType.DATE_RANGE,
	},
	// {
	// 	id: 'today',
	// 	title: I18n.t(_t(translations.dateRangeFrame.day)),
	// 	value: EPeriodType.DAY,
	// },
	{
		id: 'yesterday',
		title: I18n.t(_t(translations.dateRangeFrame.yesterday)),
		value: EPeriodType.YESTERDAY,
	},
	{
		id: 'week',
		title: I18n.t(_t(translations.dateRangeFrame.week)),
		value: EPeriodType.WEEK,
	},
	{
		id: 'semi-month',
		title: I18n.t(_t(translations.dateRangeFrame.semiMonth)),
		value: EPeriodType.SEMI_MONTH,
	},
	{
		id: 'month',
		title: I18n.t(_t(translations.dateRangeFrame.month)),
		value: EPeriodType.MONTH,
	},
	{
		id: 'quarter',
		title: I18n.t(_t(translations.dateRangeFrame.quarter)),
		value: EPeriodType.QUARTER,
	},
	{
		id: 'semi-year',
		title: I18n.t(_t(translations.dateRangeFrame.semiYear)),
		value: EPeriodType.SEMI_YEAR,
	},
	{
		id: 'year',
		title: I18n.t(_t(translations.dateRangeFrame.year)),
		value: EPeriodType.YEAR,
	},
];

export enum EDatePickerType {
	FROM_DATE,
	TO_DATE,
}

// export enum EExpenseType {
// 	ALL = -2,
// 	OTHER,
// 	ELECTRICITY,
// 	WATER,
// 	CABLE,
// 	PHONE,
// 	CELL_PHONE,
// 	NATURAL_GAS,
// 	INTERNET,
// }

export const PromotionCode = {
	TOTAL_BOOKING: 'AssignedToBookingTotal',
	ALL_SERVICE: 'AssignedToAllServices',
	CATEGORIES: 'AssignedToCategories',
};
export const DATE_RANGE_TYPES = [
	{
		id: 'dateRange',
		title: t(translations.dateRangeFrame.dateRange),
		value: DateRangeType.DATE_RANGE,
	},
	{
		id: 'today',
		title: t(translations.dateRangeFrame.today),
		value: DateRangeType.TODAY,
	},
	{
		id: 'yesterday',
		title: t(translations.dateRangeFrame.yesterday),
		value: DateRangeType.YESTERDAY,
	},
	{
		id: 'weekly',
		title: t(translations.dateRangeFrame.weekly),
		value: DateRangeType.WEEKLY,
	},
	{
		id: 'semiMonth1st',
		title: t(translations.dateRangeFrame.semiMonth1st),
		value: DateRangeType.SEMI_MONTH_1st,
	},
	{
		id: 'semiMonth2nd',
		title: t(translations.dateRangeFrame.semiMonth2nd),
		value: DateRangeType.SEMI_MONTH_2nd,
	},
	{
		id: 'monthly',
		title: t(translations.dateRangeFrame.monthly),
		value: DateRangeType.MONTHLY,
	},
];

export type tourName =
	| 'saleGiftCard'
	| 'tip'
	| 'discount'
	| 'promotion'
	| 'applyCoupon'
	| 'addExtra';

export const TourSteps: { [key in tourName]: Step[] } = {
	saleGiftCard: [
		{
			target: '#input-gift-code',
			content:
				'Input or scan the code on the prepared gift card you about to sell for customer.',
			disableBeacon: true,
			disableOverlayClose: true, // crash
		},
		{
			target: '#clear',
			content: 'Click on this button to clear inputted gift card code',
			disableOverlayClose: true,
		},
		{
			target: '#list-amount',
			content:
				'Select common amount for the balance of this gift card, you can also click on "Other" button to input customize balance.',
			disableOverlayClose: true,
		},
		{
			target: '#save',
			content: 'Click on this button to save and add gift card to this bill.',
			disableOverlayClose: true,
		},
	],
	tip: [
		{
			target: '#auto-tip',
			content:
				'Choose this tab to divine tips for every staff by their service ratio with total bill.',
			disableBeacon: true,
			disableOverlayClose: true,
		},
		{
			target: '#split-even',
			content: 'Choose this tab to divine tips for every staff by even amount.',
			disableOverlayClose: true,
		},
		{
			target: '#manually',
			content:
				'Choose this tab to divine tips for each staff by specific amount.',
			disableOverlayClose: true,
		},
		{
			target: '#list-tip-for',
			content: 'List of tips for staff',
			disableOverlayClose: true,
		},
		{
			target: '#numpad',
			content:
				'Beside click number on keyboard you can also click number on this area.',
			disableOverlayClose: true,
		},
		{
			target: '#clear',
			content: 'Click on this button to clear inputted amount.',
			disableOverlayClose: true,
		},
	],
	discount: [
		{
			target: '#percent',
			content:
				'Choose this tab if you want to discount for customer by percentage of sub total bill.',
			disableBeacon: true,
			disableOverlayClose: true,
		},
		{
			target: '#money',
			content:
				'Choose this tab if you want to discount for customer by direct money',
			disableOverlayClose: true,
		},
		{
			target: '#both',
			content:
				'Choose this tab if both staff and owner will responsible for discount amount.',
			disableOverlayClose: true,
		},
		{
			target: '#only-owner',
			content:
				'Choose this tab if only owner will responsible for discount amount.',
			disableOverlayClose: true,
		},
		{
			target: '#only-staff',
			content:
				'Choose this tab if only staff will responsible for discount amount.',
			disableOverlayClose: true,
		},
		{
			target: '#numpad',
			content:
				'Beside click number on keyboard you can also click number on this area.',
			disableOverlayClose: true,
		},
		{
			target: '#clear',
			content: 'Click on this button to clear inputted amount.',
			disableOverlayClose: true,
		},
	],
	promotion: [
		{
			target: '#promotions',
			content:
				'Click on this button to open list of promotion this bill can be apply.',
			disableBeacon: true,
			disableOverlayClose: true,
		},
		{
			target: '#promotion',
			content: 'Promotion that available for this bill.',
			disableOverlayClose: true,
		},
		{
			target: '#cancel',
			content: 'Click on this button to close promotion list. ',
			disableOverlayClose: true,
		},
	],
	applyCoupon: [
		{
			target: '#input-coupon',
			content:
				'Input or scan coupon code that customer provide for the promotion.',
			disableBeacon: true,
			disableOverlayClose: true,
		},
		{
			target: '#clear',
			content: 'Click on this button to clear inputted coupon code.',
			disableOverlayClose: true,
		},
		{
			target: '#check',
			content: 'Click on this button to check if inputted coupon is available.',
			disableOverlayClose: true,
		},
	],
	addExtra: [
		{
			target: '#reason',
			content: 'Input reason why must added extra amount for this service.',
			disableBeacon: true,
			disableOverlayClose: true,
		},
		{
			target: '#enter_amount',
			content: 'Input extra amount for this service.',
			disableOverlayClose: true,
		},
	],
};
export enum EarningPointMethod {
	SALE = 1,
	INVOICE,
}
export enum ExtraPointType {
	SaleAmount = 1,
	CountInvoice = 2,
	ReferringNewCustomer = 3,
	MakeBookingOnline = 4,
	CustomerBirthday = 5,
}
export enum PointExpirationType {
	Period = 1,
	Frequency = 2,
}
