import { all, call, put, select, takeEvery } from "@redux-saga/core/effects";
import { AlertHelper } from "helpers";
import _ from "lodash";
import {
  IApiResponse,
  IEmployee,
  IEmployeeType,
  IErrorResponse,
  IItemCategory,
  IPaymentMethod,
  IRole,
  IService,
} from "models";
import { ICurrency } from "models/ICurrency";
import { CallEffect } from "redux-saga/effects";
import {
  AppConfigActions,
  CategoryActions,
  EmployeeActions,
  PaymentMethodActions,
  EmployeeTypeActions,
  RoleActions,
  ServiceActions,
  CurrencyActions,
} from "redux/actions";
import { RootState } from "redux/configuration/rootReducer";
import { PaymentMethodApiService } from "services";
import CategoryApiService from "services/CategoryApiService";
import CurrencyApiService from "services/CurrencyApiService";
import EmployeeApiService from "services/EmployeeApiService";
import EmployeeTypeApiService from "services/EmployeeTypeApiService";
import RoleApiService from "services/RoleApiService";
import ServiceApi from "services/ServiceApi";
const getToken = (state: RootState) => state.AuthenticationReducer.token;

function* getInitialData() {
  const requests: CallEffect<IApiResponse<IItemCategory | IService[] | any>>[] = [
    call(CategoryApiService.getAllCategory),
    call(ServiceApi.getAllService),
    // call(EmployeeApiService.getAllStylist,),
  ];
  const token: string = yield select(getToken);
  if (!_.isEmpty(token)) {
    requests.push(
      call(PaymentMethodApiService.getAllPaymentMethods),
      call(EmployeeTypeApiService.getAllEmployeeTypes),
      call(RoleApiService.getAllRoles),
      call(CurrencyApiService.getAllCurrency),
      call(EmployeeApiService.getAllStylist)
    );
  }
  try {
    const result: IApiResponse[] = yield all(requests);
    if (result.every((element) => element.succeeded)) {
      yield put(CategoryActions.getAllCategory.success(result[0].data! as unknown as IItemCategory[]));
      yield put(ServiceActions.getAllServices.success(result[1].data! as unknown as IService[]));
      // yield put(
      // 	EmployeeActions.getAllEmployees.success(
      // 		result[2].data! as unknown as IEmployee[]
      // 	)
      // );
      yield put(PaymentMethodActions.getAllPaymentMethods.success(result[2].data! as unknown as IPaymentMethod[]));
      yield put(EmployeeTypeActions.getAllEmployeeTypes.success(result[3].data! as unknown as IEmployeeType[]));
      yield put(RoleActions.getAllRoles.success(result[4].data! as unknown as IRole[]));
      yield put(CurrencyActions.getAllCurrency.success(result[5].data! as unknown as ICurrency[]));
      yield put(EmployeeActions.getAllEmployees.success(result[6].data! as unknown as IEmployee[]));
    } else {
      const error = result.find((element) => !element.succeeded) as IErrorResponse;
      AlertHelper.showError(error);
      yield put(AppConfigActions.getInitialData.failed(error));
    }
  } catch (error) {
    yield put(AppConfigActions.getInitialData.failed(error));
  }
}

export function* AppConfigWatcher() {
  yield takeEvery(AppConfigActions.getInitialData.requestName, getInitialData);
}
