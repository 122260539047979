import { mdiClose } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Icon } from 'components/atoms/Icon';
import { useBookingHook } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { t, translations } from 'utils';
import { BookingStatus } from 'utils/Consts';

export const ListBookingDetail = () => {
	const {
		bookingDetails,
		currentIndex,
		selectIndex,
		currentBooking,
		deleteIndex,
		getAvailableStylistAndTimeBlocks,
	} = useBookingHook();
	const validBookingDetails = bookingDetails.filter(
		(x) => !_.isEmpty(x.itemId)
	);
	const listEmployees = useAppSelector(
		(state) => state.EmployeeReducer.employees
	);
	const onSelectIndex = (index: number) => {
		getAvailableStylistAndTimeBlocks({}, index, true);
		selectIndex(index);
	};
	return (
		<ul className="bg-white rounded-lg border-0 border-base-200 mb-2 h-60 border-b-0 text-gray-900 list-none pl-0 ">
			{validBookingDetails.map((x, index) => {
				const stylist =
					x.stylist || _.find(listEmployees, (e) => e.id === x.stylistId);
				return (
					<li
						id={`booking-detail-${index}`}
						data-label={`${index + 1}`}
						className={clsxm(
							'px-4 py-3 relative border border-base-200 flex justify-between  m-0 w-full  ',
							currentIndex === index && 'main-gradient-background',
							index === validBookingDetails.length - 1 && 'rounded-b-lg',
							index === 0 && 'rounded-t-lg',
							index === validBookingDetails.length - 1 && 'mb-2'
						)}
						style={{
							backgroundColor: stylist && stylist.color,
						}}
					>
						<div
							className={
								'w-full flex justify-between active:animate-pop duration-500 ease-in cursor-pointer'
							}
							onClick={() => {
								onSelectIndex(index);
							}}
						>
							<div className="flex flex-col ">
								<span className="p-0 font-semibold text-sm ">
									{x.item?.name}
								</span>
								<span className="p-0 text-sm ">{`Staff: ${
									stylist
										? stylist.firstName
										: t(translations.mainBooking.notChoose)
								}`}</span>
							</div>
							<div className="flex flex-col items-end">
								<span className="p-0 font-semibold text-sm ">{`$${x.item?.price}`}</span>
								{/* <Icon path={mdiDelete} className="text-DELETED" /> */}
							</div>
						</div>
						{(!currentBooking ||
							currentBooking?.status === BookingStatus.PENDING ||
							currentBooking?.status === BookingStatus.CHECKED_IN ||
							currentBooking?.status === BookingStatus.FINISHED ||
							(currentBooking.status === BookingStatus.PROCESSING &&
								x.status !== BookingStatus.PROCESSING)) && (
							<div
								onClick={() => deleteIndex(index)}
								className="ribbon bg-error rounded-sm px-1 py-0 active:animate-pop duration-500 ease-in cursor-pointer "
							>
								<span className="text-white font-bold h-full flex align-middle justify-center items-center ">
									{
										<Icon
											className="m-auto font-bold"
											size={0.7}
											path={mdiClose}
										/>
									}
								</span>
								<div className="ribbon-corner"></div>
							</div>
						)}
					</li>
				);
			})}
		</ul>
	);
};
