import { mdiPlus, mdiClose, mdiAccountConvert, mdiCashRefund } from '@mdi/js';
import Icon from '@mdi/react';
import clsxm from 'clsxs/clsxm';
import { TextArea, Button, Input } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { CurrencyHelper } from 'helpers';
import _ from 'lodash';
import { IBillingDetail, IGiftCard } from 'models';
import { useBillDetailContext } from 'pages/BillDetail/context';
import React, { useState } from 'react';
import { t, translations } from 'utils';
import { RefundPartialModal } from './Modals/RefundPartialBillModal';
import { ServicePickerModal } from './Modals/SelectServiceModal';
import { SelectStaffModal } from './Modals/SelectStaffModal';

const GiftCardDetail = (props: {
	giftCard?: Partial<IGiftCard>;
	index: number;
	isLast?: boolean;
}) => {
	const { giftCard, index, isLast } = props;
	return (
		<li
			data-label={`${index + 1}`}
			className={clsxm(
				'relative border-base-200 flex gap-2 justify-between m-0 w-full ',
				isLast && 'rounded-b-lg',
				index === 0 && 'rounded-t-lg'
			)}
		>
			<div
				className={clsxm(
					'flex flex-1 flex-col border border-t-0 border-base-200 bg-white px-4 py-1 items-center',
					isLast && 'rounded-b-lg',
					index === 0 && 'rounded-t-lg border-t'
				)}
			>
				<div className={'w-full flex justify-between items-center '}>
					<div className="flex gap-2 ">
						<span className="p-0 font-semibold text-sm ">
							{`${t(translations.checkout.giftCard)}/`}{' '}
							<span className="text-base-300">{`${t(
								translations.checkout.balance
							)}: ${CurrencyHelper.formatPrice(giftCard?.balance)}`}</span>
						</span>
					</div>
					<div className="flex flex-col items-end">
						<span className="p-0 font-semibold  ">{`${CurrencyHelper.formatPrice(
							giftCard?.amount
						)}`}</span>
						{/* <Icon path={mdiDelete} className="text-DELETED" /> */}
					</div>
				</div>
			</div>
		</li>
	);
};

const Item = (props: {
	billDetail: Partial<IBillingDetail>;
	index: number;
	isLast?: boolean;
	refundDetail?: Partial<IBillingDetail>;
}) => {
	const { billDetail, index, isLast, refundDetail } = props;
	const { editing, refundPartial, removeRefundDetail, updateBillDetail } =
		useBillDetailContext();
	const [showAddModal, setShowAddModal] = useState(false);
	const [showAddExtra, setShowAddExtra] = useState(false);
	const [showNumpad, setShowNumpad] = useState(false);
	const [showChangeStaff, setShowChangeStaff] = useState(false);
	const [showServicePicker, setShowServicePicker] = useState(false);
	const [reason, setReason] = useState('');
	return (
		<>
			<li
				data-label={`${index + 1}`}
				className={clsxm(
					'relative border-base-200 flex gap-2 justify-between m-0 w-full  ',
					isLast && 'rounded-b-lg',
					index === 0 && 'rounded-t-lg'
				)}
			>
				<div
					className={clsxm(
						'flex flex-1 flex-col border border-t-0 border-base-200 bg-white px-4 py-1 items-center',
						isLast && 'rounded-b-lg',
						index === 0 && 'rounded-t-lg border-t',
						editing && 'cursor-pointer'
					)}
					onClick={() => editing && setShowServicePicker(true)}
				>
					<div className={'w-full flex justify-between items-center'}>
						<div className="flex flex-col gap-1 ">
							<span className="p-0 font-semibold text-sm ">
								{billDetail.item?.name}
							</span>
							<span className="p-0 text-sm ">{`Staff ${
								billDetail.stylist ? billDetail.stylist.firstName : 'Not choose'
							}`}</span>
						</div>
						<div className="flex gap-2">
							{(billDetail.discount ||
								billDetail.promotionDiscount ||
								billDetail.discount !== 0 ||
								billDetail.promotionDiscount !== 0) && (
								<span className="p-0 font-semibold line-through text-base-300 ">{`${CurrencyHelper.formatPrice(
									billDetail.amount
								)}`}</span>
							)}
							<span className="p-0 font-semibold  ">{`${CurrencyHelper.formatPrice(
								(billDetail.amount || 0) -
									((billDetail.discount || 0) +
										(billDetail.promotionDiscount || 0))
							)}`}</span>
						</div>
					</div>
					{billDetail.extraAmount && billDetail.extraAmount !== 0 ? (
						<div className="w-full flex justify-between items-center">
							<div className="flex gap-2 items-center ">
								<div className="flex gap-2 pl-10">
									<span className="p-0 font-light text-sm uppercase ">
										{billDetail.note}
									</span>
								</div>
							</div>
							<div className="flex flex-col items-end">
								<span className="p-0 font-semibold text-sm ">{`${CurrencyHelper.formatPrice(
									billDetail.extraAmount
								)}`}</span>
							</div>
						</div>
					) : undefined}
					{refundDetail ? (
						<>
							<div className="w-full flex justify-between items-center text-EXPIRED">
								<div className="flex gap-2 items-center ">
									<div className="flex gap-2 pl-10">
										<span className="p-0 font-light text-sm uppercase ">
											{`${t(translations.billing.refund)} SERVICES`}
										</span>
									</div>
								</div>
								<div className="flex flex-col items-end">
									<span className="p-0 font-semibold text-sm ">{`-${CurrencyHelper.formatPrice(
										Math.abs(refundDetail.amount || 0)
									)}`}</span>
								</div>
							</div>
							<div className="w-full flex justify-between items-center text-EXPIRED">
								<div className="flex gap-2 items-center ">
									<div className="flex gap-2 pl-10">
										<span className="p-0 font-light text-sm uppercase ">
											{`${t(translations.billing.refund)} ${t(
												translations.billing.tax
											)}`}
										</span>
									</div>
								</div>
								<div className="flex flex-col items-end">
									<span className="p-0 font-semibold text-sm ">{`-${CurrencyHelper.formatPrice(
										Math.abs(refundDetail.tax || 0)
									)}`}</span>
								</div>
							</div>
						</>
					) : undefined}
				</div>
				{editing && (
					<div className="flex gap-2 px-1">
						{!billDetail.extraAmount || billDetail.extraAmount === 0 ? (
							<div
								onClick={() => {
									setShowAddExtra(true);
								}}
								className="text-xs w-[120px] justify-center cursor-pointer text-black bg-[#99FFB8] flex gap-1 items-center my-1 border border-CHECKED_IN rounded-md p-1 active:animate-pop"
							>
								{<Icon size={0.8} path={mdiPlus} />}
								{t(translations.checkout.addExtra)}
							</div>
						) : (
							<div
								onClick={() => {
									updateBillDetail(
										{
											...billDetail,
											extraAmount: undefined,
											note: undefined,
										},
										index
									);
								}}
								className="text-xs  w-[120px] justify-center cursor-pointer text-error bg-white flex gap-1 items-center my-1 border rounded-md p-1 active:animate-pop"
							>
								{<Icon size={0.8} path={mdiClose} />}
								{`${t(translations.remove)} ${t(
									translations.checkout.extraAmount
								)}`}
							</div>
						)}
						<div
							onClick={() => {
								setShowChangeStaff(true);
							}}
							className="text-xs justify-center cursor-pointer bg-white flex gap-1 items-center my-1 border rounded-md p-2 active:animate-pop"
						>
							{<Icon size={0.8} path={mdiAccountConvert} />}
							Change Staff
						</div>
						{showAddExtra && (
							<ModalNumPad
								onCancel={() => {
									setShowAddExtra(false);
								}}
								unit="Usd"
								isMoney
								title={t(translations.checkout.addExtra)}
								showNumpad={showNumpad}
								disabled={_.isEmpty(reason)}
								key={`${props.billDetail.id}`}
								onConfirm={(value) => {
									updateBillDetail(
										{
											...billDetail,
											note: reason,
											extraAmount: parseFloat(value),
										},
										index
									);
									setShowAddExtra(false);
								}}
							>
								<div className="p-4">
									<TextArea
										id="reason"
										onChange={(e) => setReason(e.target.value)}
										onFocus={() => setShowNumpad(false)}
										autoFocus
										label={t(translations.checkout.addExtraReason)}
									/>
									<div className="flex justify-end mt-4">
										<Button
											id="enter_amount"
											disabled={_.isEmpty(reason)}
											onClick={() => {
												if (!_.isEmpty(reason)) {
													setShowNumpad(true);
												}
											}}
											className="text-white"
											small
											title={t(translations.checkout.enterAmount)}
										/>
									</div>
								</div>
							</ModalNumPad>
						)}
						{showChangeStaff && (
							<SelectStaffModal
								visible={showChangeStaff}
								onCancel={() => {
									setShowChangeStaff(false);
								}}
								onSelectStaff={(staff) => {
									updateBillDetail(
										{ ...billDetail, stylistId: staff.id, stylist: staff },
										index
									);
									setShowChangeStaff(false);
								}}
								serviceId={billDetail.itemId}
								selectedStaff={billDetail.stylist}
							/>
						)}
						{showServicePicker && (
							<ServicePickerModal
								visible={showServicePicker}
								onCancel={() => setShowServicePicker(false)}
								onSelectService={(service) => {
									updateBillDetail(
										{
											...billDetail,
											item: service,
											itemId: service.id,
											amount: service.price,
											price: service.price,
										},
										index
									);
									setShowServicePicker(false);
								}}
							/>
						)}
					</div>
				)}
				{refundPartial && (
					<div className="flex gap-2 px-1">
						{!refundDetail ? (
							<div
								onClick={() => {
									setShowAddModal(true);
								}}
								className="text-xs w-[120px] justify-center cursor-pointer text-black bg-[#99FFB8] flex gap-1 items-center my-1 border border-CHECKED_IN rounded-md p-1 active:animate-pop"
							>
								{<Icon size={0.8} path={mdiCashRefund} />}
								{t(translations.billing.refund)}
							</div>
						) : (
							<div
								onClick={() => {
									removeRefundDetail(billDetail.id!);
								}}
								className="text-xs  w-[120px] justify-center cursor-pointer text-error bg-white flex gap-1 items-center my-1 border rounded-md p-1 active:animate-pop"
							>
								{<Icon size={0.8} path={mdiClose} />}
								{`${t(translations.remove)}`}
							</div>
						)}
					</div>
				)}
				{showAddModal && (
					<RefundPartialModal
						onCancel={() => setShowAddModal(false)}
						billDetail={billDetail}
					/>
				)}
			</li>
		</>
	);
};

const ListDetails = () => {
	const { billDetails, refundDetails, editing, addNewBillDetail } =
		useBillDetailContext();
	const getRefundByDetail = (detail: Partial<IBillingDetail>) => {
		const refundDetail = !_.isEmpty(refundDetails)
			? refundDetails?.find((e) => e.billDetailId === detail.id)
			: undefined;
		return refundDetail;
	};
	const [showChangeStaff, setShowChangeStaff] = useState(false);
	const [showServicePicker, setShowServicePicker] = useState(false);
	const [newBillDetail, setNewBillDetail] = useState<Partial<IBillingDetail>>();
	return (
		<>
			{editing && (
				<>
					<div className="py-2 flex float-right">
						<Button
							// className="pl-8 mt-2 cursor-pointer"
							onClick={() => setShowServicePicker(true)}
							iconBefore={<Icon path={mdiPlus} size={0.8} />}
							title="Add Service"
							small
						/>
					</div>
					{showChangeStaff && (
						<SelectStaffModal
							visible={showChangeStaff}
							onCancel={() => {
								setShowChangeStaff(false);
								setNewBillDetail(undefined);
							}}
							onSelectStaff={(staff) => {
								newBillDetail &&
									addNewBillDetail({
										...newBillDetail,
										stylistId: staff.id,
										stylist: staff,
									});
								setNewBillDetail(undefined);
								setShowChangeStaff(false);
							}}
							serviceId={newBillDetail?.itemId}
						/>
					)}
					{showServicePicker && (
						<ServicePickerModal
							visible={showServicePicker}
							onCancel={() => setShowServicePicker(false)}
							onSelectService={(service) => {
								setShowServicePicker(false);
								setNewBillDetail({
									...newBillDetail,
									item: service,
									itemId: service.id,
									amount: service.price,
									price: service.price,
									discount: 0,
									promotionDiscount: 0,
									quantity: 1,
									serviceId: service.id,
								});
								setShowChangeStaff(true);
							}}
						/>
					)}
				</>
			)}
			<ul className="rounded-lg  text-gray-900 list-none mt-0 ">
				{(billDetails || []).map((x, index) => {
					return (
						<Item
							billDetail={x}
							index={index}
							refundDetail={getRefundByDetail(x)}
						/>
					);
				})}
			</ul>
		</>
	);
};
const ListGiftCardDetails = () => {
	const { bill } = useBillDetailContext();
	return (
		<ul className="rounded-lg  text-gray-900 list-none  mt-2 ">
			{bill?.billDetails
				.filter((x) => x.giftCardId)
				.map((x, index) => {
					return <GiftCardDetail giftCard={x.giftCard} index={index} />;
				})}
		</ul>
	);
};

export const Details = () => {
	const { bill } = useBillDetailContext();
	return (
		<div className="col-span-3 overflow-hidden pb-16 pl-2  ">
			<ListDetails />
			<ListGiftCardDetails />
		</div>
	);
};
