import clsxm from "clsxs/clsxm";
import { Tag } from "components/atoms/Tag";
import { ColorHelper } from "helpers";
import I18n from "i18n-js";
import React from "react";
import { translations, _t } from "utils";
export const StatusTag = (props: { status: number; className?: string }) => {
  return (
    <Tag
      label={I18n.t(_t(translations.bookingStatus[`status_${props.status}`]))}
      className={clsxm(`bg-${ColorHelper.getNameColorFromStatus(props.status)}`, props.className)}
    />
  );
};
