import { ReduxHelper } from "helpers";
import { IHolidayModel } from "models";
import { IAddHolidayRequest, IGetAllHolidayRequest } from "models/RequestModels";
import { IPaginateResponse } from "models/ResponseModels";

const prefix = "HOLIDAY";

const addHoliday = ReduxHelper.generateActions<IAddHolidayRequest>(`${prefix}/ADD_HOLIDAY`);
const getAllHoliday = ReduxHelper.generateActions<IGetAllHolidayRequest, IPaginateResponse<IHolidayModel[]>>(
  `${prefix}/GET_ALL_HOLIDAY`
);
const clearHoliday = ReduxHelper.generateLocalAction(`${prefix}/CLEAR_HOLIDAY`);
const selectHoliday = ReduxHelper.generateLocalAction<IHolidayModel>(`${prefix}/SELECT_HOLIDAY`);
const getHolidayById = ReduxHelper.generateActions<string, IHolidayModel>(`${prefix}/GET_HOLIDAY_BY_ID`);
const updateHoliday = ReduxHelper.generateActions<Partial<IHolidayModel>>(`${prefix}/UPDATE_HOLIDAY`);
const deleteHoliday = ReduxHelper.generateActions<string>(`${prefix}/DELETE_HOLIDAY`);

export { addHoliday, prefix, getAllHoliday, clearHoliday, selectHoliday, getHolidayById, updateHoliday, deleteHoliday };
