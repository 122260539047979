/* eslint-disable react-hooks/exhaustive-deps */
import clsxm from 'clsxs/clsxm';
import { Description, Button } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { GenerateHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import { t } from 'i18n-js';
import _ from 'lodash';
import { useCheckOutContext } from 'pages/CheckOut/context';
import { useEffect, useRef, useState } from 'react';
import { translations } from 'utils';
import { PaymentType } from 'utils/Consts';

export const OtherModal = (props: { onCancel: () => void }) => {
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const paymentMethods = useAppSelector(
		(state) => state.PaymentMethodReducer.paymentMethods
	);
	const { calculateAmountNeedToPay, addPayment } = useCheckOutContext();
	const numpadRef = useRef<INumPadRef>();
	const [selectedId, setSelectedId] = useState<string>('');
	const [referenceCode, setReferenceCode] = useState('');

	const onUpdateText = (text: string) => {
		if (numpadRef) {
			numpadRef.current?.setText(text);
		}
	};

	const onClear = () => {
		onUpdateText('');
	};

	const onAddPayment = (transactionReferenceNumber?: string) => {
		if (_.isEmpty(selectedId)) {
			return;
		}
		addPayment({
			paymentType: PaymentType.OTHER,
			amount: calculateAmountNeedToPay(),
			transactionReferenceNumber:
				transactionReferenceNumber ||
				GenerateHelper.generateFourDigitsNumber().toString(),
			appName: paymentMethods.find((x) => x.id === selectedId)?.name,
			paymentMethodId: selectedId,
		});
		props.onCancel();
	};
	useEffect(() => {
		if (!branch?.paymentReferenceCodeRequiring) {
			onAddPayment();
		}
	}, [selectedId]);

	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			showNumpad={branch?.paymentReferenceCodeRequiring}
			title={t(translations.checkout.chooseAppToPay)}
			onConfirm={(x) => {
				onAddPayment(x);
			}}
			onChange={(x) => setReferenceCode(x)}
		>
			<div className="flex flex-col h-full justify-between">
				<div className="flex gap-8 items-center mt-10 mx-4 ">
					{paymentMethods
						.filter((y) => y.status === 1)
						.map((x) => {
							return (
								<div
									onClick={() => setSelectedId(x.id)}
									className={clsxm(
										'flex flex-col items-center justify-center border border-primary rounded-lg p-3 w-32 cursor-pointer active:animate-pop duration-500 ease-in',
										selectedId === x.id ? 'bg-PROCESSING text-white' : ''
									)}
								>
									<h5>{x.name}</h5>
								</div>
							);
						})}
				</div>
				{branch?.paymentReferenceCodeRequiring && (
					<div className="flex h-fit flex-1 items-end p-4">
						<div className="flex w-full items-center justify-between ">
							<div>
								<Description title={t(translations.checkout.otherReference)} value={referenceCode} />
							</div>

							<div className="flex gap-2">
								<Button
									onClick={() => onClear()}
									small
									title={t(translations.clear)}
									className="btn-outline btn-error"
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</ModalNumPad>
	);
};
