import { DetailPageLayout } from 'components/molecules';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { I18n, _t, translations, t } from 'utils';
import BasicInfo from './components/BasicInfo';
import Employment from './components/Employment';
import PayrollSetup from './components/PayrollSetup';
import { WorkingTime } from './components/WorkingTime';
import Roles from './components/Roles';
import {
	IStaffDetailForm,
	StaffDetailProvider,
	useStaffDetailContext,
} from './context';
import { SelectServices } from './components/SelectServices';
import { PRIMARY_COLOR } from 'utils/Colors';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { useEffect, useState } from 'react';
import { WeekDays } from 'utils/Consts';
import { useAppSelector } from 'helpers/hookHelpers';
import { useDispatch } from 'react-redux';
import { AppConfigActions } from 'redux/actions';
import { Button, Icon } from 'components/atoms';
import clsxm from 'clsxs/clsxm';
import { mdiPlay } from '@mdi/js';
export const StaffDetail = () => {
	const { id } = useParams() as { id: string };
	return (
		<StaffDetailProvider staffId={id}>
			<Page />
		</StaffDetailProvider>
	);
};

const Page = () => {
	const { isAdd, form, submitForm, loading, staff, onDeleteStaff } =
		useStaffDetailContext();
	const [stepIndex, setStepIndex] = useState(0);
	const [run, setRun] = useState(false);
	const [tab, setTab] = useState((staff?.incomeType || 1) - 1);
	const dispatch = useDispatch();
	const tourGuide = useAppSelector((state) => state.AppConfigReducer.tourGuide);
	const openedScreen = _.isEmpty(tourGuide) ? false : tourGuide.StaffDetail;

	useEffect(() => {
		if (!openedScreen) {
			setRun(true);
			dispatch(
				AppConfigActions.usedTourGuide.request({
					key: 'StaffDetail',
					used: true,
				})
			);
		}
	}, []);

	const handleJoyrideCallback = (data: CallBackProps) => {
		const { action, index, status, type } = data;

		if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
			// Need to set our running state to false, so we can restart if we click start again.

			setRun(false);
			setStepIndex(0);
		} else if (
			([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
		) {
			const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
			if (
				index === 6 ||
				(tab !== 1 && index === 11) ||
				(tab !== 2 && index === 14)
			) {
				setRun(false);
				setStepIndex(nextStepIndex);
				setTimeout(() => {
					setRun(true);
				}, 400);
			} else if (
				(index === 7 || index === 12 || index === 15) &&
				action === ACTIONS.PREV
			) {
				setRun(false);
				setStepIndex(5);

				setTimeout(() => {
					setRun(true);
				}, 400);
			} else {
				// Update state to advance the tour
				setStepIndex(nextStepIndex);
			}
		}
	};
	const onChangeTab = (i: number) => {
		if (stepIndex === 6 || stepIndex === 11 || stepIndex === 14) {
			setRun(false);
			setStepIndex(stepIndex + 1);
		} else {
			setRun(run);
			setStepIndex(stepIndex);
		}
	};
	return (
		<DetailPageLayout<IStaffDetailForm>
			title={I18n.t(
				_t(
					isAdd
						? translations.staffDetail.addStaff
						: translations.staffDetail.editStaff
				)
			)}
			form={form}
			onSave={form?.handleSubmit(submitForm)}
			loading={loading}
			showDelete={!isAdd}
			// extraRightHeader={
			// 	<div className="flex align-middle my-auto gap-4">
			// 		<Button
			// 			className={clsxm(
			// 				'mr-16 btn-choose-booking-time bg-white btn-outline btn-primary capitalize font-medium'
			// 			)}
			// 			small
			// 			title={'Start Tour'}
			// 			onClick={() => setRun(true)}
			// 			iconAfter={<Icon path={mdiPlay} />}
			// 		/>
			// 	</div>
			// }
			onDelete={onDeleteStaff}
		>
			{(isAdd || !_.isEmpty(staff)) && (
				<>
					<div className="flex flex-col gap-4 px-14 ">
						<BasicInfo />
						<Employment />
						<Roles />
						<PayrollSetup onChangeTab={(i) => onChangeTab(i)} />
						<SelectServices />
						<WorkingTime isLoading={loading} />
						<WorkingTime isLoading={loading} online />
					</div>
				</>
			)}
		</DetailPageLayout>
	);
};
