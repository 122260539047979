import { Button, ButtonGroup, Description, Select } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { CurrencyHelper, TimeHelper } from 'helpers';
import { priceFixed } from 'helpers/currencyHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import I18n, { t } from 'i18n-js';
import _ from 'lodash';
import { IEmployee } from 'models';
import { ITip } from 'models/ITip';
import moment from 'moment';
import { useCheckOutContext } from 'pages/CheckOut/context';
import React, { useRef, useState } from 'react';
import { adjustTip, welcome } from 'services/CloverApiService';
import { translations, _t } from 'utils';
import { PaymentType, TipType } from 'utils/Consts';
import { calculateCardCharge } from './CardModal';

interface ITipMethod {
	id: TipType;
	label: string;
}

const TipMethods: ITipMethod[] = [
	{
		id: TipType.AUTO,
		label: I18n.t(_t(translations.checkout.autoTips)),
	},
	{
		id: TipType.SPLIT_EVEN,
		label: I18n.t(_t(translations.checkout.splitEven)),
	},
	{
		id: TipType.MANUALLY,
		label: I18n.t(_t(translations.checkout.manually)),
	},
];

export const TipModal = (props: { onCancel: () => void }) => {
	const {
		totalAmountBillDetails,
		bill,
		setTip,
		setTipType,
		tip,
		childrenBills = [],
		billDetails = [],
		payments,
		setPayments,
		done,
		setTipAfterCheckout,
		calculateAmountNeedToPay,
	} = useCheckOutContext();
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const [type, setType] = useState(TipMethods[0]);
	const [tipValue, setTipValue] = useState(0);
	const numpadRef = useRef<INumPadRef>();
	const [selectedStylistId, setSelectedStylistId] = useState<string>();
	const childBillDetails = _.flatMapDeep(
		childrenBills.map((x) => x.billDetails!)
	);
	const allBillDetails = billDetails.concat(childBillDetails);
	const uniqStylists = _.uniqBy(
		allBillDetails.filter((x) => _.isEmpty(x.giftCardId)).map((x) => x.stylist),
		(s) => s?.id
	);
	const stylistIds =
		allBillDetails
			.filter((x) => _.isEmpty(x.giftCardId))
			.map((x) => x.stylistId) || [];
	const averageTip = CurrencyHelper.priceFixed(
		(tipValue || 0) /
			(type.id === TipType.AUTO ? stylistIds.length : _.uniq(stylistIds).length)
	);
	const manualList = uniqStylists.filter(
		(x) =>
			!_.includes(
				tip?.tips?.map((t) => t.employeeId),
				x?.id
			)
	);
	const getTipByEmployee = (stylistId: string, isLast: boolean): number => {
		const bookingSameStylist = allBillDetails.filter(
			(x) => x.stylistId === stylistId
		);
		const totalServiceSameStylist = _.sumBy(
			bookingSameStylist,
			(x) => (x?.price || 0) + (x?.extraAmount || 0)
		);
		const tipAutoPercent = CurrencyHelper.priceFixed(
			(totalServiceSameStylist / totalAmountBillDetails()) * 100
		);
		const tipAuto = CurrencyHelper.priceFixed(
			(tipAutoPercent * tipValue) / 100
		);
		if (type.id === TipType.SPLIT_EVEN) {
			return isLast
				? CurrencyHelper.priceFixed(
						tipValue - averageTip * (_.uniq(stylistIds).length - 1)
				  )
				: averageTip;
		}

		if (type.id === TipType.AUTO) {
			return isLast
				? tipValue -
						_.sumBy(_.uniq(stylistIds.filter((s) => s !== stylistId)), (x) =>
							getTipByEmployee(`${x}`, false)
						)
				: tipAuto;
		}
		return 0;
	};

	const handleChangePayments = async () => {
		let newPayments = _.clone(payments);
		const creditIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.CREDIT_CARD
		);
		const appIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.APP
		);
		const cashIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.CASH
		);
		if (creditIndex > -1 && newPayments) {
			const oldCardCharge = newPayments[creditIndex].cardCharge || 0;
			const newAmount =
				(newPayments[creditIndex].amount || 0) - oldCardCharge + tipValue;

			newPayments[creditIndex].amount = priceFixed(
				newAmount + calculateCardCharge(`${newAmount}`, branch)
			);
			newPayments[creditIndex].final = true;
			newPayments[creditIndex].cardCharge = priceFixed(
				calculateCardCharge(`${newAmount}`, branch)
			);
			if (newPayments[creditIndex].isClover) {
				const tipAdjust =
					branch?.cardChargeFlatRate !== undefined &&
					branch?.cardChargeFlatRate! !== 0
						? tipValue
						: tipValue + (tipValue * (branch?.cardChargePercent || 0)) / 100;
				const responseAdjust = await adjustTip(
					`${newPayments[creditIndex].transactionReferenceNumber}`,
					tipAdjust * 100
				);
				welcome();
			}
			setPayments(newPayments);
			return;
		}
		if (appIndex > -1 && newPayments) {
			newPayments[appIndex].amount = priceFixed(
				(newPayments[appIndex].amount || 0) + tipValue
			);
			return;
		}
		if (cashIndex > -1 && newPayments) {
			newPayments[cashIndex].amount = priceFixed(
				(newPayments[cashIndex].amount || 0) + tipValue
			);
			return;
		}
	};
	const onConfirm = () => {
		if (type.id === TipType.MANUALLY) {
			const newTips = tip?.tips
				? tip.tips.concat([
						{
							employeeId: `${selectedStylistId}`,
							tipAmount: tipValue,
							date: moment.utc().toDate(),
						},
				  ])
				: [
						{
							employeeId: `${selectedStylistId}`,
							tipAmount: tipValue,
							date: moment.utc().toDate(),
						},
				  ];
			const newTip: ITip = {
				...tip,
				totalTip: (tip?.totalTip || 0) + tipValue,
				tips: newTips,
			};
			setTip(newTip);
			if (done) {
				setTipAfterCheckout(newTip);
			}
		} else {
			const newTip: ITip = {
				billId: bill?.id,
				date: TimeHelper.fromTimeZoneStringToUtc(moment.utc().toISOString()),
				totalTip: tipValue,
			};
			setTip(newTip);
			if (done) {
				setTipAfterCheckout(newTip);
			}
		}
		if (calculateAmountNeedToPay() === 0) {
			handleChangePayments();
		}
	};
	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			isMoney
			onChange={(v) => setTipValue(parseFloat(v) || 0)}
			showNumpad
			unit="Usd"
			title={t(translations.checkout.tip)}
			disabled={type.id === TipType.MANUALLY && !selectedStylistId}
			onConfirm={(x) => {
				onConfirm();
				props.onCancel();
				setTipType(type.id);
			}}
		>
			<div className="flex flex-col gap-2 h-full flex-1 p-4">
				<h5 className="font-bold">{t(translations.checkout.tipMethod)}</h5>
				<ButtonGroup<ITipMethod>
					buttons={TipMethods}
					buttonClassNames={[
						'auto-tip flex-1',
						'split-even flex-1',
						'manually flex-1',
					]}
					activeClassName={'flex-1'}
					small
					value={type}
					onChange={(t) => setType(t)}
					valueField={'id'}
					titleField="label"
				/>
				<div id="list-tip-for">
					{type.id !== TipType.MANUALLY &&
						uniqStylists.map((stylist, index) => {
							return (
								<Description
									title={`${t(translations.checkout.tipFor)} ${
										stylist?.firstName || ''
									} ${stylist?.lastName || ''}`}
									value={CurrencyHelper.formatPrice(
										getTipByEmployee(
											`${stylist?.id}`,
											index === uniqStylists.length - 1
										)
									)}
								/>
							);
						})}
				</div>

				{type.id === TipType.MANUALLY && (
					<Select<IEmployee>
						options={manualList as IEmployee[]}
						keyField={'id'}
						fullWidth
						valueId={selectedStylistId}
						onChange={(v) => setSelectedStylistId(v.id)}
						className="w-full"
						titleField="firstName"
						placeHolder={t(translations.mainBooking.selectStylist)}
					/>
				)}
				<div className="flex flex-1 items-end justify-end">
					<Button
						id="clear"
						onClick={() => {
							if (numpadRef) {
								numpadRef.current?.setText('');
							}
						}}
						small
						title={t(translations.clear)}
						className="btn-outline btn-error"
					/>
				</div>
			</div>
		</ModalNumPad>
	);
};
