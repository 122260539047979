import { LoadingProvider } from 'contexts/LoadingContext';
import _ from 'lodash';
import qs from 'qs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CloverActions } from 'redux/actions';

export const CloverIntegration = () => {
	const { shopId, branchId } = useParams<{
		shopId: string;
		branchId: string;
	}>();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const queries = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	}) as {
		merchant_id?: string;
		code?: string;
	};
	useEffect(() => {
		if (!_.isEmpty(queries.code)) {
			dispatch(
				CloverActions.setCloverConfig.request({
					merchantId: `${queries.merchant_id}`,
					code: `${queries.code}`,
				})
			);
			setTimeout(() => {
				window.location.replace(
					`https://${shopId}.${process.env.REACT_APP_PRODUCTION_URL}/branches-management-detail/edit/${branchId}/?merchantId=${queries.merchant_id}&code=${queries.code}`
				);
			});
			//
		}
	});
	return (
		<LoadingProvider loading={true}>
			<div className="w-screen h-screen bg-primary" />
		</LoadingProvider>
	);
};
