import _ from 'lodash';
import {
	IBooking,
	IBookingDetail,
	IEmployee,
	IHolidayDetail,
	IHolidayModel,
	IService,
} from 'models';
import moment, { Moment } from 'moment';
import { Event } from 'react-big-calendar';
import configureStore from 'redux/configuration/configureStore';
import { BookingStatus } from 'utils/Consts';
import { getInitialWorkingTime, toTimeZone } from './timeHelper';

export enum EventType {
	Booking,
	Holiday,
	Break,
}
export interface IEventCalendar
	extends Event,
		Partial<IBookingDetail>,
		Partial<IHolidayDetail> {
	resource?: IEmployee;
	resourceId: string;
	description: string;
	services?: IService[];
	status?: number;
	ids?: string[];
	type: number;
	booking?: IBooking;
	supplyPrice?: number;
	realEndAt?: Moment;
}

// function getTimeBooking(booking: IBooking) : {start: Date; end:Date} {
//   const start = !_.isEmpty(booking.startTime)
// }

export const convertBreakToEvent = (
	employees: IEmployee[] = [],
	date: Moment
) => {
	let events: IEventCalendar[] = [];
	for (const employee of employees) {
		const today = date.get('day');
		const breakingTime = _.find(
			employee.employeeWorkingTimes,
			(x) => x.days?.includes(`${today}`) && x.breakingTimeStartAt
		) as any;
		if (breakingTime) {
			const event: IEventCalendar = {
				resourceId: employee.id,
				type: EventType.Break,
				start: getInitialWorkingTime(
					breakingTime.breakingTimeStartAt,
					date
				).toDate(),
				end: getInitialWorkingTime(
					breakingTime.breakingTimeEndAt,
					date
				).toDate(),
				description: 'Breaking Time',
				id: `${employee.id}-${breakingTime}-${moment().format('dd/mm/yyyy')}`,
			};
			events.push(event);
		}
	}
	return events;
};

export function convertHolidayToEvent(holidays: IHolidayModel[] = []) {
	let events: IEventCalendar[] = [];
	for (const holiday of holidays) {
		holiday.holidayDetails.forEach((holidayDetail) => {
			if (!_.isEmpty(holidayDetail.employeeId)) {
				const event: IEventCalendar = {
					...holidayDetail,
					resourceId: holidayDetail.employeeId!,
					description: holiday.holidayName!,
					type: EventType.Holiday,
					start: toTimeZone(holidayDetail.startTime).toDate(),
					end: toTimeZone(holidayDetail.endTime).toDate(),
				};
				events.push(event);
			}
		});
	}
	return events;
}

export function convertBookingToEvent(bookings: IBooking[]): IEventCalendar[] {
	let events: IEventCalendar[] = [];
	for (let i = 0; i < bookings.length; i++) {
		const booking = bookings[i];
		const bookingEvents = [];
		if (booking.status === BookingStatus.CANCELED) {
			continue;
		}
		const bookingDuration = _.sumBy(
			booking.bookingDetails,
			(x) => x.item?.duration || 0
		);
		const realBookingDuration = moment(booking.bookingDetails[0].endAt).diff(
			booking.bookingDetails[0].startAtExpected,
			'minute'
		);
		for (let j = 0; j < booking.bookingDetails.length; j++) {
			const bookingDetail = booking.bookingDetails[j];
			const duplicate = events.find(
				(x) =>
					x.booking?.id !== booking.id &&
					x.startAtExpected === bookingDetail.startAtExpected &&
					x.endAtExpected === bookingDetail.endAtExpected &&
					x.stylistId === bookingDetail.stylistId &&
					x.booking?.customerId === booking.customerId &&
					x.booking?.status !== 7
			);
			if (duplicate) {
				continue;
			}
			const lastBookingDetail = booking.bookingDetails[j - 1];
			const lastEvent = _.find(
				bookingEvents,
				(x) => x.id === lastBookingDetail?.id
			);
			let startAt = toTimeZone(
				bookingDetail.startAt || bookingDetail.startAtExpected
			);
			let endAt = toTimeZone(
				bookingDetail.endAt || bookingDetail.endAtExpected
			);
			if (booking.status >= BookingStatus.CHECKED_IN) {
				if (booking.status === BookingStatus.DONE) {
					if (bookingDetail.endAt && !bookingDetail.startAt) {
						const duration =
							((bookingDetail.item?.duration || 0) / bookingDuration) *
							realBookingDuration;

						if (j === 0 || !lastEvent) {
							endAt = toTimeZone(bookingDetail.startAtExpected).add(
								duration,
								'minute'
							);
						} else {
							startAt = toTimeZone(lastEvent?.end || bookingDetail.endAt);
							endAt = toTimeZone(lastEvent?.end || bookingDetail.endAt).add(
								duration,
								'minute'
							);
						}
					}
				}
			}
			const event: IEventCalendar = {
				...bookingDetail,
				id: `${bookingDetail.id}`,
				title: `${booking.customer.firstName} ${booking.customer.lastName}`,
				realEndAt: endAt,
				start: startAt.toDate(),
				end: endAt.toDate(),
				description: bookingDetail.item?.name!,
				resourceId: _.isEmpty(bookingDetail.stylistId)
					? 'Anyone'
					: bookingDetail.stylistId!,
				resource: bookingDetail.stylist!,
				services: [bookingDetail.item!],
				status: booking.status,
				type: EventType.Booking,
				booking: booking,
				discounterType: bookingDetail.discounterType,
			};
			bookingEvents.push(event);
			events.push(event);
		}
	}
	return events;
}
