import clsxm from 'clsxs/clsxm';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

interface ITabViewProps {
	tabs: string[] | React.ReactNode[];
	tabPanels: React.ReactNode[];
	className?: string;
	onChangeTab?: (tabIndex: number) => void;
	defaultTab?: number;
}
export const TabView = (props: ITabViewProps) => {
	const [tabIndex, setTabIndex] = useState(0);

	useEffect(() => {
		if (props.defaultTab) {
			setTabIndex(props.defaultTab);
		}
	}, [props.defaultTab]);

	return (
		<Tabs
			selectedIndex={tabIndex}
			onSelect={(index) => {
				setTabIndex(index);
				props.onChangeTab && props.onChangeTab(index);
			}}
			className="flex flex-col h-full"
		>
			<TabList
				className={clsxm(
					'tabs tabs-boxed rounded-none bg-[#f4f3f8] border-b-2 border-b-base-200 my-2',
					props.className
				)}
			>
				{props.tabs.map((x, index) => {
					return (
						<Tab
							className={clsxm(
								` tab tab-lg px-3 tab-${index}`,
								index === tabIndex && 'main-gradient-background tab-active',
								index !== 0 && 'mx-1'
							)}
						>
							{x}
						</Tab>
					);
				})}
			</TabList>
			{props.tabPanels.map((x, index) => {
				return (
					<TabPanel
						className={clsxm(
							'px-2 w-full overflow-hidden',
							index === tabIndex ? 'flex-1' : ''
						)}
					>
						{x}
					</TabPanel>
				);
			})}
		</Tabs>
	);
};
