import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ITheme } from 'models';
import { ThemeActions } from 'redux/actions';

interface IThemeReducer {
	themes: ITheme[];
}

const initialState: IThemeReducer = {
	themes: [],
};

const getAllThemeSuccess = (
	state: IThemeReducer,
	action: PayloadAction<ITheme[]>
) => {
	state.themes = action.payload;
};

const themeReducer = createReducer(initialState, (builder) => {
	builder.addCase(ThemeActions.getAllThemes.success, getAllThemeSuccess);
});

export default themeReducer;
