/* eslint-disable react-hooks/exhaustive-deps */
import { mdiClose, mdiPrinter } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Card, Container, Icon, Select } from 'components/atoms';
import Table, {
	EColumnType,
	IColumnProps,
	ITableRef,
} from 'components/atoms/Table';
import { DateRangePicker } from 'components/molecules/DateRangePicker';
import { TDateRange } from 'components/molecules/ModalCalendar';
import { LoadingProvider } from 'contexts/LoadingContext';
import { AlertHelper, CurrencyHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import {
	createESCPayroll,
	createESCPayrollAll,
	print,
} from 'helpers/printHelper';
import _ from 'lodash';
import { IPayrollSummary, IPayrollSummaryDetail } from 'models';
import { IPayrollRequest } from 'models/RequestModels';
import moment from 'moment';
import { Divider } from 'pages/FinancialReport/components/Divider';
import { ReportFieldValue } from 'pages/FinancialReport/components/ReportFieldValue';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReportApiService } from 'services';
import { t, translations } from 'utils';
import { DateRangeType, DATE_RANGE_TYPES } from 'utils/Consts';

export const PayrollSummaryReport = () => {
	const tableRef = useRef<ITableRef>(null);
	const [report, setReport] = useState<IPayrollSummary>();
	const [isLoading, setIsLoading] = useState<boolean>();
	const [isShowDateRange, setIsShowDateRange] = useState<boolean>(); //workaround until change daterange props
	const [selectedEmployee, setSelectedEmployee] =
		useState<IPayrollSummaryDetail>();
	const [selectedDateRangeType, setSelectedDateRangeType] = useState<
		typeof DATE_RANGE_TYPES[0]
	>(DATE_RANGE_TYPES[0]);
	const [dateFilter, setDateFilter] = useState<TDateRange>({
		fromDate: moment().startOf('date'),
		toDate: moment().startOf('date'),
	});
	const staffs = useAppSelector((state) => state.EmployeeReducer.employees);

	// useEffect(() => {
	// 	getPayrollSummary({ pageNumber: 1, pageSize: 10 });
	// }, []);

	useEffect(() => {
		getPayrollSummary({
			pageSize: 10,
			pageNumber: report?.details.pageNumber || 1,
			fromDate: dateFilter.fromDate!.format('YYYY-MM-DD'),
			toDate: dateFilter.toDate!.format('YYYY-MM-DD'),
		});
	}, [dateFilter]);

	useEffect(() => {
		setIsShowDateRange(false);
		let timeOut = setTimeout(() => {
			switch (selectedDateRangeType.value) {
				case DateRangeType.TODAY:
					setDateFilter({ fromDate: moment(), toDate: moment() });
					break;
				case DateRangeType.YESTERDAY:
					setDateFilter({
						fromDate: moment().add(-1, 'day'),
						toDate: moment().add(-1, 'day'),
					});
					break;
				case DateRangeType.WEEKLY:
					setDateFilter({
						fromDate: moment().startOf('week'),
						toDate: moment().endOf('week'),
					});
					setIsShowDateRange(true);
					break;
				case DateRangeType.SEMI_MONTH_1st:
					setDateFilter({
						fromDate: moment().startOf('month'),
						toDate: moment().startOf('month').add(14, 'day'),
					});
					setIsShowDateRange(true);
					break;
				case DateRangeType.SEMI_MONTH_2nd:
					setDateFilter({
						fromDate: moment().startOf('month').add(15, 'day'),
						toDate: moment().endOf('month'),
					});
					setIsShowDateRange(true);
					break;
				case DateRangeType.MONTHLY:
					setDateFilter({
						fromDate: moment().startOf('month'),
						toDate: moment().endOf('month'),
					});
					setIsShowDateRange(true);
					break;
				default:
					setIsShowDateRange(true);
					break;
			}
			clearTimeout(timeOut);
		}, 50);
	}, [selectedDateRangeType]);

	const getPayrollSummary = useCallback(async (request: IPayrollRequest) => {
		setIsLoading(true);
		try {
			const result = await ReportApiService.getPayrollSummary(request);
			if (result.succeeded && !_.isEmpty(result.data)) {
				setReport(result.data!);
			} else {
				AlertHelper.showError(result);
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	const employeeCol: IColumnProps<IPayrollSummaryDetail> = {
		header: t(translations.employee),
		key: 'employeeName',
	};
	const salaryCol: IColumnProps<IPayrollSummaryDetail> = {
		header: t(translations.payrollReport.salary),
		key: 'salary',
		type: EColumnType.MONEY,
		// renderCell: (data) => CurrencyHelper.formatBalance(data.salary),
	};
	const totalServiceCol: IColumnProps<IPayrollSummaryDetail> = {
		header: `${t(translations.payrollReport.totalService)}(${t(
			translations.currency
		)})`,
		key: 'serviceIncome',
		type: EColumnType.MONEY,
		// renderCell: (data) => (
		// 	<p className="text-right">
		// 		{CurrencyHelper.formatBalance(
		// 			(data.supplyFee || 0) + (data.income || 0)
		// 		)}
		// 	</p>
		// ),
	};
	const supplyFeeCol: IColumnProps<IPayrollSummaryDetail> = {
		header: `${t(translations.payrollDetailReport.supplyFee)}(${t(
			translations.currency
		)})`,
		key: 'supplyFee',
		type: EColumnType.MONEY,
	};
	const revenueCol: IColumnProps<IPayrollSummaryDetail> = {
		header: `${t(translations.payrollReport.realIncome)}(${t(
			translations.currency
		)})`,
		key: 'income',
		type: EColumnType.MONEY,
		renderCell: (data) => (
			<p className="text-right font-bold">{`${CurrencyHelper.formatPrice(
				data.serviceIncome - (data.supplyFee || 0),
				false
			)}`}</p>
		),
		headerClassName: 'font-bold',
	};
	const refundCol: IColumnProps<IPayrollSummaryDetail> = {
		header: `${t(translations.payrollDetailReport.refund)}(${t(
			translations.currency
		)})`,
		key: 'refund',
		type: EColumnType.MONEY,
		// renderCell: (data) => CurrencyHelper.formatBalance(data.commission),
	};
	const commisionCol: IColumnProps<IPayrollSummaryDetail> = {
		header: `${t(translations.payrollReport.commission)}(${t(
			translations.currency
		)})`,
		key: 'commission',
		type: EColumnType.MONEY,
		// renderCell: (data) => CurrencyHelper.formatBalance(data.commission),
	};
	const commisionPercentCol: IColumnProps<IPayrollSummaryDetail> = {
		header: `${t(translations.payrollReport.commission)}(%)`,
		key: 'commission',
		type: EColumnType.MONEY,
		renderCell: (data) => {
			const commission = _.find(
				staffs,
				(x) => x.id === data.employeeId
			)?.servicesCommissionPercent;
			return <p className="text-right">{`${commission}%`}</p>;
		},
	};
	const tip: IColumnProps<IPayrollSummaryDetail> = {
		header: `${t(translations.payrollDetailReport.tips)}(${t(
			translations.currency
		)})`,
		key: 'tip',
		type: EColumnType.MONEY,
		// renderCell: (data) => CurrencyHelper.formatBalance(data.commission),
	};
	const salaryPay: IColumnProps<IPayrollSummaryDetail> = {
		header: `${t(translations.payrollDetailReport.totalSalary)}(${t(
			translations.currency
		)})`,
		key: 'salaryPay',
		type: EColumnType.MONEY,
		cellClassName: 'font-bold',
		headerClassName: 'font-bold',
		// renderCell: (data) => CurrencyHelper.formatBalance(data.commission),
	};
	const actionCol: IColumnProps<IPayrollSummaryDetail> = {
		header: t(translations.action),
		key: 'employeeId',
		renderHeader: (
			<p className="m-0 flex items-center justify-center">
				{t(translations.action)}
			</p>
		),
		renderCell: (item) => (
			<div className="flex items-center justify-center">
				<Button
					small
					title={t(translations.print)}
					onClick={() => {
						onPrintClick(item);
					}}
					iconBefore={<Icon path={mdiPrinter} />}
					ghost
					className="text-primary"
				/>
			</div>
		),
	};

	const onPrintClick = async (payroll: IPayrollSummaryDetail) => {
		// this.setState({ printType: PrintType.ONE });
		const period = `${dateFilter.fromDate?.format(
			'MM/DD/YYYY'
		)} - ${dateFilter.toDate?.format('MM/DD/YYYY')}`;

		const payrollESCCommands = createESCPayroll(payroll, period);
		await print(payrollESCCommands, undefined);
		// if (printReport) {
		//     alert('123')
		// }
	};

	const onPrintAll = async (report: IPayrollSummary) => {
		// this.setState({ payroll: dataPayroll, printType: PrintType.ALL });
		const period = `${dateFilter.fromDate?.format(
			'MM/DD/YYYY'
		)} - ${dateFilter.toDate?.format('MM/DD/YYYY')}`;
		const payrollAllESCCommands = createESCPayrollAll(
			report.details.data,
			period
		);
		// eslint-disable-next-line no-restricted-globals
		await print(payrollAllESCCommands, undefined, true);
	};

	return (
		// <Container className="block h-full">
		<LoadingProvider loading={isLoading} className="p-4 flex-1 block h-full">
			<div
				className={clsxm(
					'h-full flex flex-col',
					selectedEmployee ? 'gap-4' : ''
				)}
			>
				<div className="grid grid-cols-4 gap-4 mb-4">
					<Select<typeof DATE_RANGE_TYPES[0]>
						options={DATE_RANGE_TYPES}
						keyField="id"
						titleField="title"
						fullWidth
						onChange={setSelectedDateRangeType}
						value={selectedDateRangeType}
					/>
					<div>
						{isShowDateRange && (
							<DateRangePicker
								onSelectDateRange={setDateFilter}
								format={'MMM Do, YYYY'}
								disabled={
									isLoading ||
									selectedDateRangeType.value !== DateRangeType.DATE_RANGE
								}
								selectedDateRange={dateFilter}
							/>
						)}
					</div>
				</div>
				<div
					className={clsxm(
						'flex-1 overflow-auto',
						selectedEmployee ? 'grid grid-cols-5 gap-4' : ''
					)}
				>
					<Table
						data={report?.details.data || []}
						ref={tableRef}
						columns={[
							employeeCol,
							salaryCol,
							totalServiceCol,
							supplyFeeCol,
							revenueCol,
							refundCol,
							commisionCol,
							commisionPercentCol,
							tip,
							salaryPay,
							actionCol,
						]}
						noDefaultPadding
						showSearch
						pagination={{
							page: report?.details.pageNumber || 0,
							totalItem: report?.details.totalRecords || 0,
							totalPage: report?.details.totalPages || 0,
							onPageChange: (page, searchText) => {
								getPayrollSummary({
									fromDate: dateFilter.fromDate?.format('YYYY-MM-DD'),
									toDate: dateFilter.toDate?.format('YYYY-MM-DD'),
									pageNumber: page,
									pageSize: report?.details.pageSize || 0,
									searchText: searchText,
								});
							},
						}}
						onSearchChange={(text) =>
							getPayrollSummary({
								fromDate: dateFilter.fromDate?.format('YYYY-MM-DD'),
								toDate: dateFilter.toDate?.format('YYYY-MM-DD'),
								pageNumber: 1,
								pageSize: report?.details.pageSize || 0,
								searchText: text,
							})
						}
						onClickRow={setSelectedEmployee}
						// className={'h-full'}
						containerClassName={selectedEmployee ? 'col-span-4' : ''}
						renderHeader={
							<Button
								disabled={!report}
								onClick={() => onPrintAll(report!)}
								iconBefore={<Icon path={mdiPrinter} />}
								small
								ghost
								white
							>
								{t(translations.payrollReport.printAll)}
							</Button>
						}
					/>
					{selectedEmployee && (
						<Card className="col-span-1 flex flex-col items-center h-full overflow-y-auto relative">
							{/* <div className="overflow-y-auto"> */}
							<div
								className="absolute top-2 right-2 cursor-pointer"
								onClick={() => setSelectedEmployee(undefined)}
							>
								<Icon path={mdiClose} />
							</div>
							<label className="label">
								<p className="label-text text-xl font-bold my-0">
									{t(translations.payrollReport.payrollPreview)}
								</p>
							</label>
							<Divider className="w-full h-1" />
							<div className="w-full text-left">
								<label className="label p-0">
									<p className="label-text text-sm my-0">
										{t(translations.payrollReport.name)}
										<span className="label-text text-sm font-bold my-0">
											{selectedEmployee.employeeName || ''}
										</span>
									</p>
								</label>
								<label className="label p-0">
									<p className="label-text text-sm my-0">
										{t(translations.phone)}
										{': '}
										<span className="label-text text-sm font-bold my-0">
											{selectedEmployee.phone || ''}
										</span>
									</p>
								</label>
								<label className="label p-0">
									<p className="label-text text-sm my-0">
										{t(translations.email)}
										{': '}
										<span className="label-text text-sm font-bold my-0">
											{selectedEmployee.email || ''}
										</span>
									</p>
								</label>
								<label className="label p-0">
									<p className="label-text text-sm my-0">
										{t(translations.payrollReport.period)}
										<span className="label-text text-sm font-bold my-0">
											{`${dateFilter.fromDate?.format(
												'MMM Do, YYYY'
											)} - ${dateFilter.toDate?.format('MMM Do, YYYY')}`}
										</span>
									</p>
								</label>
							</div>
							<div className="w-full">
								<Divider className="w-full h-1" />
								<ReportFieldValue
									title={t(translations.payrollReport.salary)}
									value={CurrencyHelper.formatPrice(selectedEmployee.salary)}
									bold
									big
								/>
								<Divider className="w-full h-1" />
								<ReportFieldValue
									title={t(translations.payrollReport.revenue)}
									value={CurrencyHelper.formatPrice(selectedEmployee.income)}
									bold
									big
								/>
								<ReportFieldValue
									title={t(translations.payrollReport.serviceRevenue)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.serviceIncome
									)}
								/>
								<ReportFieldValue
									title={t(translations.payrollReport.productRevenue)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.productIncome
									)}
								/>
								<ReportFieldValue
									title={t(translations.payrollReport.giftCardRevenue)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.giftCardIncome
									)}
								/>
								<Divider className="w-full h-1" />
								<ReportFieldValue
									title={t(translations.payrollReport.commission)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.commission
									)}
									bold
									big
								/>
								<ReportFieldValue
									title={t(translations.serviceCommission)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.serviceCommission
									)}
								/>
								<ReportFieldValue
									title={t(translations.productCommission)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.productCommission
									)}
								/>
								<ReportFieldValue
									title={t(translations.payrollReport.giftCardCommission)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.giftCardCommission
									)}
								/>
								<Divider className="w-full h-1" />
								<ReportFieldValue
									title={t(translations.payrollReport.tips)}
									value={CurrencyHelper.formatPrice(selectedEmployee.tip)}
									bold
									big
								/>
								<ReportFieldValue
									title={t(translations.payrollReport.cardCharge)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.cardCharge
									)}
									bold
									big
								/>
								<ReportFieldValue
									title={t(translations.payrollReport.tipsCardCharge)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.tipCardCharge
									)}
									bold
									big
								/>
								<Divider className="w-full h-1" />
								<ReportFieldValue
									title={t(translations.payrollReport.totalSalary)}
									value={CurrencyHelper.formatPrice(selectedEmployee.salaryPay)}
									bold
									big
								/>
								<ReportFieldValue
									title={t(translations.payrollReport.cashIncome)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.salaryPayByCash
									)}
									bold
									big
								/>
								<ReportFieldValue
									title={t(translations.payrollReport.checkIncome)}
									value={CurrencyHelper.formatPrice(
										selectedEmployee.salaryPayByCheck
									)}
									bold
									big
								/>
							</div>
							<Divider className="w-full h-1" />
							<label className="label">
								<p className="label-text text-lg my-0">
									{t(translations.thank)}
								</p>
							</label>
							{/* </div> */}
						</Card>
					)}
				</div>
			</div>
		</LoadingProvider>
		// </Container>
	);
};
