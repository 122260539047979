import { EPeriodType } from 'models';
import { ITimeBlock } from 'models/ITimeBlock';
import moment, { Moment } from 'moment';
import momentTz from 'moment-timezone';

import configureStore from 'redux/configuration/configureStore';

export type EFormatType =
	| 'date'
	| 'time'
	| 'datetime'
	| 'longdate'
	| 'week_date_time';

export const generateDateRangeFromPeriod = (period: EPeriodType) => {
	switch (period) {
		case EPeriodType.DAY:
			return { fromDate: moment(), toDate: moment() };
		case EPeriodType.YESTERDAY:
			return {
				fromDate: moment().add(-1, 'day'),
				toDate: moment().add(-1, 'day'),
			};
		case EPeriodType.WEEK:
			return {
				fromDate: moment().add(-7, 'day'),
				toDate: moment(),
			};

		case EPeriodType.SEMI_MONTH:
			return {
				fromDate: moment().add(-15, 'day'),
				toDate: moment(),
			};
		case EPeriodType.SEMI_YEAR:
			return {
				fromDate: moment().add(-6, 'month'),
				toDate: moment(),
			};

		case EPeriodType.MONTH:
			return {
				fromDate: moment().add(-1, 'month'),
				toDate: moment(),
			};
		case EPeriodType.YEAR:
			return {
				fromDate: moment().startOf('year'),
				toDate: moment(),
			};
		case EPeriodType.QUARTER:
			return {
				fromDate: moment().add(-1, 'month'),
				toDate: moment(),
			};
		default:
			return undefined;
	}
};

export function convertDurationToStringDetail(duration: number) {
	const hours = Math.floor(duration / 60);
	const minutes = duration - hours * 60;
	return `${hours > 0 ? `${hours}h` : ''} ${
		minutes > 0 ? `${minutes}min` : ''
	}`;
}

export function convertDurationToNumber(duration: string): number {
	const hours = moment(duration, 'HH:mm').hours();
	const minutes = moment(duration, 'HH:mm').minutes();
	return hours * 60 + minutes;
}

export function convertDurationToString(duration: number) {
	const hours = Math.floor(duration / 60);
	const minutes = duration - hours * 60;
	return `${hours}:${minutes}`;
}

export function generateTimeBlockId(time: string) {
	return `timeBlock${time}`;
}
export function getTimesFromBlock(timeBlockId?: string) {
	if (!timeBlockId) {
		return undefined;
	}
	return timeBlockId.split('timeBlock')[1];
}

export function generateTimeBlocks(timeBlocks: ITimeBlock[]) {
	return timeBlocks.map((timeBlock) => {
		const timeString = moment(timeBlock.time).format('YYYY-MM-DDTHH:mm');
		const timeBlockConverted = {
			id: generateTimeBlockId(timeString),
			label: timeString,
		};
		return timeBlockConverted;
	});
}

export const getInitialWorkingTime = (
	time: string,
	date = moment(),
	pickerFix: boolean = false,
	timeZone?: string
) => {
	// const splited = time.split('T');
	// const spitedTime = splited[1];
	// const dateString = splited[0];
	// if (dateString === '2023-10-17' || dateString === '2023-10-18') {
	// 	if (dateString.includes('8')) {
	// 		date.add(1, 'day');
	// 	}
	// 	const niceTime = toTimeZone(`${date.format('YYYY-MM-DD')}T${spitedTime}`);
	// 	return pickerFix ? moment(niceTime.format('YYYY-MM-DDTHH:mm')) : niceTime;
	// }
	return pickerFix
		? moment(toTimeZone(time, timeZone).format('YYYY-MM-DDTHH:mm'))
		: toTimeZone(time, timeZone);
};

export const toTimeZone = (date?: Date | string, timeZone?: string): moment.Moment => {
	return momentTz
		.utc(date)
		.tz(
			timeZone || configureStore().store.getState().BranchReducer.currentBranch
				?.localTimeZone || momentTz.tz.guess()
		);
};

export const fromTimeZoneStringToUtc = (
	dateTime: string,
	format = 'YYYY-MM-DDTHH:mm'
): string => {
	return momentTz
		.tz(
			dateTime,
			configureStore().store.getState().BranchReducer.currentBranch
				?.localTimeZone || momentTz.tz.guess()
		)
		.utc()
		.format(format);
};

// export const formatDateByBranch = (date: Moment) : string => {
// 	const currentBranch = configureStore().store.getState().BranchReducer.currentBranch
// 	const countryCode = currentBranch?.address?.countryCode
// 	if (countryCode === 'US') {
// 		return date.format('Y')
// 	}
// }

export const convertWorkingTime = (
	startTime: string,
	endTime: string,
	date = momentTz()
) => {
	const isNextDay = endTime.split('T')[0].includes('02');
	const startTimeString = startTime.split('T')[1];
	const endTimeString = endTime.split('T')[1];
	const startTimeConverted = moment(
		`${date.format('YYYY-MM-DD')}T${startTimeString}`
	);
	const endTimeDate = isNextDay ? date.add(1, 'd') : date;
	const endTimeConverted = moment(
		`${endTimeDate.format('YYYY-MM-DD')}T${endTimeString}`
	);
	return {
		startTime: startTimeConverted,
		endTime: endTimeConverted,
	};
};

export const generateTimeFormat = (type: EFormatType) => {
	const currentBranch =
		configureStore().store.getState().BranchReducer.currentBranch;
	const countryCode = currentBranch?.address?.countryCode;
	if (countryCode === 'US') {
		switch (type) {
			case 'date':
				return 'MM/DD/YYYY';
			case 'datetime':
				return 'MM/DD/YYYY HH:mmA';
			case 'time':
				return 'HH:mmA';
			case 'longdate':
				return 'MMM Do, YYYY';
			case 'week_date_time':
				return 'ddd hh:mmA';
			default:
				return 'MM/DD/YYYY HH:mmA';
		}
	} else {
		switch (type) {
			case 'date':
				return 'DD/MM/YYYY';
			case 'datetime':
				return 'DD/MM/YYYY HH:mm';
			case 'time':
				return 'HH:mm';
			case 'longdate':
				return 'DD MMMM, YYYY';
			case 'week_date_time':
				return 'ddd HH:mm';
			default:
				return 'DD/MM/YYYY HH:mm';
		}
	}
};
