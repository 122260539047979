/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from 'components/atoms';
// import { CountBookingPerHourResponseDetail, IBookingHourSummary } from 'models';
import { useDashboardContext } from 'pages/Dashboard/context';
import React, { useEffect, useState } from 'react';
// import branch from 'redux/reducers/branch';
import { AdminDashBoardApiService } from 'services';
import { Bar } from 'react-chartjs-2';
import { TimeHelper } from 'helpers';
import moment from 'moment';
import _ from 'lodash';
const borderRadius = Number.MAX_VALUE;
const borderRadiusAllCorners = {
	topLeft: borderRadius,
	topRight: borderRadius,
	bottomLeft: 0,
	bottomRight: 0,
};
export const BookingByHour = () => {
	const { timeRange, branch } = useDashboardContext();
	const [data, setData] = useState<
		{
			totalBookingPerHour: number;
			hour: string;
			numberHour: number;
		}[]
	>();
	const fetchData = async () => {
		const response = await AdminDashBoardApiService.getHourBookingReport({
			fromDate: timeRange.fromDate.format('YYYY-MM-DD'),
			toDate: timeRange.toDate.format('YYYY-MM-DD'),
			branchId: branch,
		});
		if (response.succeeded && response.data) {
			const hours = response.data.countBookingPerHourResponseDetails.map(
				(x) => {
					const date = TimeHelper.toTimeZone(
						`2022-01-07T${x.hour < 10 ? '0' + x.hour : x.hour}:00`
					);
					const timeZoneHour = date.format('hh:A');
					return {
						...x,
						hour: timeZoneHour,
						numberHour: parseInt(date.format('HH')),
					};
				}
			);
			const missingHour = _.range(0, 22).filter(
				(x) => !_.find(hours, (h) => h.numberHour === x)
			);
			const fullHours = hours.concat(
				missingHour.map((x) => {
					const date = moment(`2022-01-07T${x < 10 ? '0' + x : x}:00`);
					const timeZoneHour = date.format('hh:A');
					return {
						totalBookingPerHour: 0,
						hour: timeZoneHour,
						numberHour: x,
					};
				})
			);

			setData(_.sortBy(fullHours, (x) => x.numberHour));
		}
	};
	useEffect(() => {
		fetchData();
	}, [timeRange, branch]);
	return (
		<Card title="Appointment Times" className="col-span-2">
			<Bar
				options={{
					scales: {
						x: {
							stacked: true,
							grid: {
								display: false,
							},
						},
						y: {
							stacked: true,
							display: false,
							grid: {
								display: false,
							},
						},
					},

					plugins: {
						datalabels: {
							display: false,
						},
						title: {
							display: false,
						},
						legend: {
							display: false,
						},
					},
				}}
				data={{
					labels: data
						?.filter((e) => e.totalBookingPerHour > 0)
						.map((x) => x.hour),
					datasets: [
						{
							label: 'value',
							data: data
								?.filter((e) => e.totalBookingPerHour > 0)
								.map((x) => x.totalBookingPerHour),
							backgroundColor: '#323faf',
							borderRadius: borderRadiusAllCorners,
							barPercentage: 0.4,
							borderSkipped: false,
						},
						{
							label: 'max',
							data: data
								?.filter((e) => e.totalBookingPerHour > 0)
								.map(
									(x) =>
										(_.maxBy(data, (x) => x.totalBookingPerHour)
											?.totalBookingPerHour || 0) - x.totalBookingPerHour
								),
							backgroundColor: '#f4f3f8',
							barPercentage: 0.4,
							borderRadius: borderRadiusAllCorners,
							borderSkipped: false,
						},
					],
				}}
			/>
		</Card>
	);
};
