import React, { Component } from 'react';
import ListBooking from './ListBooking';
import { TabView } from 'components/molecules/Tab';
import ListGiftCard from './ListGiftCard';

class Sales extends Component {
	render() {
		return (
			<div className="h-full overflow-hidden">
				<TabView
					tabs={['Appointments', 'Gift Card']}
					tabPanels={[<ListBooking />, <ListGiftCard />]}
				/>
			</div>
		);
	}
}

export default Sales;
