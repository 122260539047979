import clsxm from 'clsxs/clsxm';
import React from 'react';

interface IProps {
	className?: string;
}
export const Divider = ({ className }: IProps) => (
	<div
		className={clsxm(
			'border-dashed border-b-[1px] border-base-200 my-4',
			className
		)}
	/>
);
