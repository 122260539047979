import { mdiCheck, mdiClose } from '@mdi/js';
import {
	Button,
	ConfirmModal,
	Description,
	Icon,
	Input,
} from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { CurrencyHelper } from 'helpers';
import { showAlert, showError } from 'helpers/alertHelper';
import _ from 'lodash';
import { IGiftCard } from 'models';
import { useCheckOutContext } from 'pages/CheckOut/context';
import React, { useRef, useState } from 'react';
import GiftCardApiService from 'services/GiftCardApiService';
import { t, translations } from 'utils';
import { PaymentType } from 'utils/Consts';

export const GiftCardModal = (props: { onCancel: () => void }) => {
	const { calculateAmountNeedToPay, addPayment } = useCheckOutContext();
	const [code, setCode] = useState('');
	const [giftCard, setGiftCard] = useState<IGiftCard>();
	const [confirmActiveModal, setConfirmActiveModal] = useState(false);
	const numpadRef = useRef<INumPadRef>();
	const onUpdateText = (text: string) => {
		if (numpadRef) {
			numpadRef.current?.setText(text);
		}
	};
	const onExact = () => {
		onUpdateText(`${_.min([calculateAmountNeedToPay(), giftCard?.balance])}`);
	};
	const onClear = () => {
		onUpdateText('');
	};
	const onCheckGiftCard = async () => {
		const response = await GiftCardApiService.getGiftCardByCode(`${code}`);
		if (response.succeeded && response.data) {
			setGiftCard(response.data);
			if (response.data.status === 0) {
				setConfirmActiveModal(true);
			}
		} else {
			showAlert(t(translations.checkout.giftCardNotFound), 'error');
		}
	};
	const onActiveGiftCard = async () => {
		debugger;
		const response = await GiftCardApiService.activeGiftCard({
			id: `${giftCard?.id}`,
		});
		if (response.succeeded && response.data) {
			setConfirmActiveModal(false);
			showAlert('Gift card has been activated', 'success');
		} else {
			setCode('');
			setGiftCard(undefined);
			setConfirmActiveModal(false);
			showError(response);
		}
	};
	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			isMoney
			disabled={_.isEmpty(giftCard)}
			showNumpad={!_.isEmpty(giftCard)}
			unit="Usd"
			title={t(translations.checkout.giftCard)}
			onConfirm={(x) => {
				addPayment({
					paymentType: PaymentType.GIFT_CARD,
					giftCode: giftCard?.giftCode,
					amount: parseFloat(x),
				});
				props.onCancel();
			}}
			onChange={(x) => {
				const number = _.toNumber(x);
				if (number > calculateAmountNeedToPay()) {
					numpadRef.current?.setText(`${calculateAmountNeedToPay()}`);
				}
				if (number > (giftCard?.balance || 0)) {
					numpadRef.current?.setText(`${giftCard?.balance || 0}`);
				}
			}}
		>
			<div className="flex flex-col justify-between h-full flex-1  p-4">
				<div className="flex gap-2 items-end">
					<Input
						label={t(translations.checkout.giftCardCode)}
						value={code}
						onChange={(e) => setCode(e.target.value)}
						placeholder={t(translations.checkout.scanQrOrSwipe)}
						autoFocus
						renderAfter={
							<div className="flex">
								<Button
									onClick={() => {
										setCode('');
										setGiftCard(undefined);
									}}
									title={t(translations.clear)}
									className="text-error h-[45px] min-h-[45px]"
									ghost
									small
									iconBefore={<Icon path={mdiClose} />}
								/>
							</div>
						}
					/>
					<Button
						onClick={() => onCheckGiftCard()}
						title={t(translations.checkout.checkCoupon)}
						primary={!_.isEmpty(code)}
						disabled={_.isEmpty(code)}
						small
						className=" h-[45px] min-h-[45px]"
						iconBefore={<Icon path={mdiCheck} />}
					/>
				</div>
				{!_.isEmpty(giftCard) && (
					<div className="flex w-full items-end justify-between gap-4">
						<div className="flex flex-col">
							<Description
								title={t(translations.checkout.balance)}
								value={`${CurrencyHelper.formatPrice(giftCard?.balance)}`}
							/>
							<Description
								title={t(translations.checkout.amountNeedToPay)}
								value={`${CurrencyHelper.formatPrice(
									calculateAmountNeedToPay()
								)}`}
							/>
						</div>
						<div className="flex gap-2">
							<Button
								onClick={() => onExact()}
								small
								title={t(translations.checkout.exact)}
								className="btn-outline btn-primary"
							/>
							<Button
								onClick={() => onClear()}
								small
								title={t(translations.clear)}
								className="btn-outline btn-error"
							/>
						</div>
					</div>
				)}
				<ConfirmModal
					title="Warning"
					type="warning"
					visible={confirmActiveModal}
					subTitle={
						'Gift card has not been activated. Do you want to activate it?'
					}
					onPositiveClick={() => onActiveGiftCard()}
					onNegativeClick={() => {
						setConfirmActiveModal(false);
						setCode('');
						setGiftCard(undefined);
					}}
				/>
			</div>
		</ModalNumPad>
	);
};
