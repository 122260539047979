import { createReducer } from "@reduxjs/toolkit";
import { CustomerActions } from "../actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { ICustomer } from "models";

export interface ICustomerReducer {
  customers?: ICustomer[];
  selectedCustomer?: Partial<ICustomer>;
}

const initialState: ICustomerReducer = {
  customers: [],
};

const getAllCustomersSuccess = (state: ICustomerReducer, action: PayloadAction<ICustomer[] | null>) => {
  state.customers = action.payload!;
};
function selectedCustomer(
  state: ICustomerReducer,
  action: PayloadAction<ICustomer>
) {
  state.selectedCustomer = action.payload;
}
function getCustomerByIdSuccess(
  state: ICustomerReducer,
  action: PayloadAction<ICustomer>
) {
  state.selectedCustomer = action.payload;
}
function removeCurrentCustomer(state: ICustomerReducer) {
  state.selectedCustomer = undefined;
}
const CustomerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CustomerActions.getAllCustomers.success, getAllCustomersSuccess)
    .addCase(CustomerActions.selectCustomer.request, selectedCustomer)
    .addCase(CustomerActions.getCustomerById.success, getCustomerByIdSuccess)
    .addCase(CustomerActions.removeCurrentCustomer.request, removeCurrentCustomer);
});
export default CustomerReducer;
