import { ReduxHelper } from 'helpers';
import { IExpenseType } from 'models';
import { IPaginateRequest } from 'models/RequestModels';

export const prefix = 'EXPENSE_TYPE';

const getAllExpenseTypes = ReduxHelper.generateActions<
	IPaginateRequest,
	IExpenseType[]
>(`${prefix}/GET_ALL_EXPENSE_TYPES`);

export { getAllExpenseTypes };
