import { mdiCommentAlertOutline, mdiSelectionEllipseRemove } from '@mdi/js';
import clsxm from 'clsxs/clsxm';
import { Button, Icon, TextArea } from 'components/atoms';
import { useBookingHook } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import { ListCategories } from 'pages/Booking/components/ListCategories';
import { ListStaff } from 'pages/Booking/components/ListStaff';
import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { BookingActions } from 'redux/actions';

import { t, translations } from 'utils';

import { ListServices } from './components/ListServices';

const Footer = () => {
	const dispatch = useDispatch();
	const { currentIndex, bookingDetails, changeBookingDetail } =
		useBookingHook();
	const currentBookingDetail =
		currentIndex !== undefined ? bookingDetails[currentIndex] : undefined;
	return (
		<div className="w-full h-16 row-span-1  bg-white rounded-r-lg px-4 flex justify-end items-center gap-2 auto-cols-max overflow-x-auto pt-0 overflow-y-hidden ">
			<div className="flex justify-center items-center gap-4">
				{currentIndex !== undefined &&
					currentBookingDetail &&
					currentBookingDetail.stylistId && (
						<Button
							id="request-staff"
							small
							onClick={() => {
								changeBookingDetail(
									{
										...bookingDetails[currentIndex],
										isStylistRequested:
											!bookingDetails[currentIndex].isStylistRequested,
									},
									currentIndex
								);
							}}
							className={clsxm(
								'min-h-[38px] h-[38px] text-xs ',
								bookingDetails[currentIndex].isStylistRequested
									? 'btn-warning'
									: 'btn-outline'
							)}
							title={
								bookingDetails[currentIndex].isStylistRequested
									? 'Staff Requested'
									: 'Request Staff'
							}
							iconBefore={<Icon path={mdiCommentAlertOutline} />}
						/>
					)}
				{currentIndex !== undefined && (
					<Button
						id="unselect-booking-detail"
						small
						onClick={() => {
							dispatch(
								BookingActions.unselectBookingDetail.request(currentIndex)
							);
						}}
						className={clsxm('min-h-[38px] h-[38px] text-xs btn-outline')}
						title={'Unselected'}
						iconBefore={<Icon path={mdiSelectionEllipseRemove} />}
					/>
				)}
			</div>
			{/* <ServiceTimeModal /> */}
		</div>
	);
};

const Note = () => {
	const { setNote } = useBookingHook();
	const note = useAppSelector((state) => state.BookingReducer.note);
	return (
		<div className="w-full h-32 row-span-1  bg-white rounded-r-lg px-4 gap-2 auto-cols-max overflow-x-auto py-1 overflow-y-hidden ">
			<h4 className="mt-1">{'Note'}</h4>
			<div>
				<TextArea
					defaultValue={note}
					onBlur={(e) => setNote(e.target.value)}
					full
				/>
			</div>
		</div>
	);
};

export const MainBooking = () => {
	const categories = useAppSelector((state) =>
		state.CategoryReducer.categories.filter((c) => c.status === 1)
	);
	const [selectedCategory, setSelectedCategory] = useState('all');

	return (
		<div className="col-span-3 gap-2 grid grid-rows-[repeat(11,minmax(0,1fr))] overflow-hidden">
			<ListCategories onSelect={(x) => setSelectedCategory(x)} />
			<div className="row-[span_9_/_span_9] grid grid-cols-8 gap-2">
				<div className="col-span-5 h-full flex flex-col rounded-r-lg gap-2 overflow-hidden ">
					<ListServices selectedCategory={selectedCategory} />
					<Note />
				</div>
				<div className="col-span-3 h-full flex flex-col w-full rounded-lg overflow-hidden gap-2 ">
					<ListStaff />
					<Footer />
				</div>
			</div>
			{/* <Footer /> */}
		</div>
	);
};
