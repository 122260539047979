import React, { Component } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { AuthRoute } from "routers/routes";

interface IRouteComponentProps extends RouteComponentProps {}

export default class AuthenticationRouter extends Component<IRouteComponentProps> {
  render() {
    return (
      <Switch>
        {AuthRoute.map((route) => {
          return <Route exact path={route.path} component={route.component} key={route.key} />;
        })}
      </Switch>
    );
  }
}
