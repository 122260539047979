import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ICloseOnlineBooking } from 'models';
import { CloseOnlineBookingActions } from 'redux/actions';

interface ICloseOnlineBookingState {
	currentClosedOnlineBooking?: ICloseOnlineBooking;
}

const initialState: ICloseOnlineBookingState = {};

const selectCloseOnlineBooking = (
	state: ICloseOnlineBookingState,
	action: PayloadAction<ICloseOnlineBooking | undefined>
) => {
	state.currentClosedOnlineBooking = action.payload;
};

const reducer = createReducer(initialState, (builder) => {
	builder.addCase(
		CloseOnlineBookingActions.selectCloseOnlineBooking.request,
		selectCloseOnlineBooking
	);
});

export default reducer;
