import { Card, Input, Select, Switch } from 'components/atoms';
import { I18n, _t, translations } from 'utils';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IPromotion, IPromotionType } from 'models/IPromotion';
import { useFormContext } from 'react-hook-form';
import '../index.css';
import { BannerUpload } from './UploadBanner';
import { DateFormInput } from 'components/molecules/DateFormInput';

const DiscountAmount = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<IPromotion>();

	const usePercentage = watch('usePercentage');

	return (
		<>
			{usePercentage ? (
				<>
					<Input
						label={I18n.t(_t(translations.promotionDetail.discountAmount))}
						placeholder={I18n.t(
							_t(translations.promotionDetail.placeholderDiscountAmount)
						)}
						error={errors.discountPercentage?.message}
						renderAfter={
							<Switch
								label={I18n.t(_t(translations.promotionDetail.usePercentage))}
								value={usePercentage}
								onChange={() => {
									setValue('usePercentage', !usePercentage);
									setValue('discountPercentage', 0);
									setValue('discountAmount', 0);
								}}
								primary
								fullWidth
								className="switch-after"
							/>
						}
						type="number"
						alignRight
						{...register('discountPercentage', {
							required: I18n.t(
								_t(
									translations.promotionDetail.messageRequiredDiscountPercentage
								)
							),
							min: {
								value: 0,
								message: I18n.t(_t(translations.validate.lessThanZero)),
							},
							max: {
								value: 100,
								message: I18n.t(
									_t(translations.validate.greaterThanHundredPercent)
								),
							},
						})}
					/>
					<Input
						label={I18n.t(
							_t(translations.promotionDetail.maximumDiscountAmount)
						)}
						placeholder={I18n.t(
							_t(translations.promotionDetail.placeholderMaximumDiscountAmount)
						)}
						error={errors.maximumDiscountAmount?.message}
						renderAfter={<>$</>}
						type="number"
						alignRight
						{...register('maximumDiscountAmount', {
							min: {
								value: 0,
								message: I18n.t(_t(translations.validate.lessThanZero)),
							},
						})}
					/>
				</>
			) : (
				<>
					<Input
						label={I18n.t(_t(translations.promotionDetail.discountAmount))}
						placeholder={I18n.t(
							_t(translations.promotionDetail.placeholderDiscountAmount)
						)}
						error={errors.discountAmount?.message}
						renderAfter={
							<Switch
								label={I18n.t(_t(translations.promotionDetail.usePercentage))}
								value={usePercentage}
								onChange={() => {
									setValue('usePercentage', !usePercentage);
									setValue('discountPercentage', 0);
									setValue('discountAmount', 0);
								}}
								primary
								fullWidth
								className="switch-after"
							/>
						}
						type="number"
						alignRight
						{...register('discountAmount', {
							required: I18n.t(
								_t(translations.promotionDetail.messageRequiredDiscountAmount)
							),
							min: {
								value: 0,
								message: I18n.t(_t(translations.validate.lessThanZero)),
							},
						})}
					/>
				</>
			)}
		</>
	);
};
const Type = [
	{
		id: 0,
		name: I18n.t(_t(translations.promotionDetail.noLimits)),
	},
	{
		id: 1,
		name: I18n.t(_t(translations.promotionDetail.nTimesOnly)),
	},
	{
		id: 2,
		name: I18n.t(_t(translations.promotionDetail.nTimesPerUser)),
	},
];
const Limitation = () => {
	const {
		formState: { errors },
		watch,
		setValue,
		register,
	} = useFormContext<IPromotion>();

	const limitationType = watch('limitationType');

	return (
		<div className="grid grid-cols-4 gap-x-4">
			<Select<{ id: number; name: string }>
				options={Type}
				titleField="name"
				keyField="id"
				label={I18n.t(_t(translations.promotionDetail.discountLimitation))}
				onChange={(value) => {
					setValue('limitationType', value.id);
				}}
				valueId={limitationType}
				fullWidth
			/>
			{limitationType !== 0 && (
				<Input
					label={I18n.t(_t(translations.promotionDetail.nTimes))}
					error={errors.limitationTimes?.message}
					type="number"
					alignRight
					{...register('limitationTimes', {
						required: I18n.t(
							_t(translations.promotionDetail.messageRequiredLimitationTimes)
						),
						min: {
							value: 0,
							message: I18n.t(_t(translations.validate.lessThanZero)),
						},
					})}
				/>
			)}
		</div>
	);
};

interface IPromotionInfoProps {
	discountTypes: IPromotionType[];
	currentPromotion?: IPromotion;
	description?: string;
	setDescription: (value: string) => void;
	setNewBanner: (value: File) => void;
}

export const PromotionInfo = (props: IPromotionInfoProps) => {
	const reactQuillRef = useRef(null) as any;
	const [valueContent, setValueContent] = useState<string>(
		props.currentPromotion?.description || ''
	);
	const {
		formState: { errors },
		watch,
		setValue,
		register,
		clearErrors,
		control,
	} = useFormContext<IPromotion>();

	const discountType = watch('promotionTypeId');
	const isEnabled = watch('isEnabled');
	const requiresCouponCode = watch('requiresCouponCode');
	const isCumulative = watch('isCumulative');
	const bannerUrl = watch('bannerUrl');

	useEffect(() => {
		register('promotionTypeId', {
			required: I18n.t(
				_t(translations.promotionDetail.messageRequiredDiscountType)
			),
		});
	}, [register]);

	useEffect(() => {
		props.setDescription(valueContent);
	}, [valueContent]);

	return (
		<Card className={'p-4 rounded-lg bg-base-100 shadow-md mb-28'}>
			<div className="grid gap-y-8">
				<div className="font-medium">
					<div className="label-text text-md">Banner</div>
					<div className="text-sm font-normal">
						Click to upload new banner. Recommend resolution: 1920 x 1080
					</div>
				</div>
				<div className="flex justify-center">
					<BannerUpload
						source={bannerUrl}
						onChange={(file) => {
							props.setNewBanner(file);
						}}
					/>
				</div>
				<Input
					label={I18n.t(_t(translations.promotionDetail.promotionName))}
					placeholder={I18n.t(_t(translations.promotionDetail.placeholderName))}
					{...register('name', {
						required: I18n.t(
							_t(translations.promotionDetail.messageRequiredName)
						),
					})}
					error={errors.name?.message}
				/>

				<div>
					<label className="label font-medium ">
						<span className="label-text text-md">
							{I18n.t(_t(translations.description))}
						</span>
					</label>
					<ReactQuill
						ref={reactQuillRef}
						style={{ marginBottom: '24px' }}
						theme="snow"
						value={valueContent}
						onChange={setValueContent}
					/>
				</div>

				<div className="grid grid-cols-4 gap-x-4">
					<Select<IPromotionType>
						options={props.discountTypes}
						titleField="name"
						keyField="id"
						label={I18n.t(_t(translations.promotionDetail.discountType))}
						title="Select Discount Type"
						fullWidth
						size="small"
						valueId={discountType}
						onChange={(value) => {
							clearErrors('promotionTypeId');
							setValue('promotionTypeId', value.id);
						}}
						error={errors.promotionTypeId?.message}
					/>
					<DiscountAmount />
				</div>
				<div className="grid grid-cols-4 gap-x-4">
					<DateFormInput
						error={errors.startDateUtc?.message}
						label="Start date"
						rules={{
							required: I18n.t(
								_t(translations.promotionDetail.messageRequiredDatePicker)
							),
						}}
						placeholder="Choose start date"
						control={control as any}
						name={'startDateUtc'}
					/>
					<DateFormInput
						error={errors.endDateUtc?.message}
						label="End date"
						rules={{ required: false }}
						placeholder="Choose end date"
						control={control as any}
						name={'endDateUtc'}
					/>
				</div>
				<Limitation />
				<div className="grid grid-cols-4">
					<Switch
						label={I18n.t(_t(translations.promotionDetail.requiresCoupon))}
						value={requiresCouponCode}
						onChange={(value) => {
							setValue('requiresCouponCode', value);
						}}
						primary
					/>
					<Switch
						label={I18n.t(_t(translations.promotionDetail.cumulativeWithOther))}
						value={isCumulative}
						onChange={(value) => {
							setValue('isCumulative', value);
						}}
						primary
					/>
				</div>
				<Switch
					label={I18n.t(_t(translations.active))}
					value={isEnabled}
					onChange={(value) => {
						setValue('isEnabled', value);
					}}
					className="grid grid-cols-4"
					primary
				/>
			</div>
		</Card>
	);
};
