import { IAdjustCloseOutPeriod, ICloseOutPeriod } from 'models';
import { IPaginateRequest } from 'models/RequestModels';
import { IPaginateResponse } from 'models/ResponseModels';
import { BaseApiService } from './BaseApiService';

export const prefix = '/CloseOutPeriod';
class CloseOutApiService extends BaseApiService {
	public getAllCloseOutPeriod = (request: IPaginateRequest) =>
		this.get<IPaginateResponse<ICloseOutPeriod[]>>(
			`${prefix}/get-all-close-out-periods`,
			{
				params: request,
			}
		);
	public addCloseOutPeriod = (request: Partial<ICloseOutPeriod>) =>
		this.post<Partial<ICloseOutPeriod>, ICloseOutPeriod>(
			`${prefix}/add-close-out-period`,
			request
		);
	public getLastCloseOut = () =>
		this.get<ICloseOutPeriod>(
			`${prefix}/get-last-close-out`
		);
    public adjustCloseOutDate = (request: IAdjustCloseOutPeriod) =>
		this.put<IAdjustCloseOutPeriod, IAdjustCloseOutPeriod>(
			`${prefix}/adjust-close-out-period`, request
		);
}

export default new CloseOutApiService(true);
