import clsxm from 'clsxs/clsxm';
import { Input } from 'components/atoms/Input';
import React, { useEffect, useRef, useState } from 'react';
import { mdiAccount, mdiLock, mdiEye, mdiEyeOff, mdiPin } from '@mdi/js';
import { Icon } from 'components/atoms/Icon';
import { Button } from 'components/atoms/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ILoginByPinRequest, ILoginRequest } from 'models/RequestModels';
import { GenerateHelper, isPos } from 'helpers';
import { Switch } from 'components/atoms/Switch';
import { useDispatch } from 'react-redux';
import { AuthenticationActions } from 'redux/actions';
import { electron } from 'ipc';
import I18n from 'i18n-js';
import { translations, _t } from 'utils';

import { useAppSelector } from 'helpers/hookHelpers';
import { PinCode } from 'components/molecules/PinCode';
import { LoadingProvider } from 'contexts/LoadingContext';
import JSEncrypt from 'jsencrypt';
import { useHistory } from 'react-router-dom';
const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDh98tkn38BECET3a8PVkhOZ2/t
96YS7FBgqI8WGkztuemSK/6qfiPLjQbM5/4ZbckbJVHjirU+yp2ozxG0s7FxS+VI
+b7loP+DUPzojDoKCoQ/lJmMDizfK2FXq4UWmNg7pZ5mH8uhlmivI9aOXizhcpen
zyWi8p+SjdSRh4as5QIDAQAB
-----END PUBLIC KEY-----`;
const POSLogin = () => {
	const dispatch = useDispatch();

	const shop = useAppSelector((state) => state.ShopReducer.shop);
	const action = useAppSelector(
		(state) => state.ReduxActionReducer['AUTHENTICATION']
	);
	const onComplete = (value: string) => {
		const key = new JSEncrypt({ default_key_size: '1024' });
		key.setPublicKey(PUBLIC_KEY);
		const encryptedValue = key.encrypt(value);
		const data: ILoginByPinRequest = {
			pinCode: `${encryptedValue}`,
			shopId: `${shop?.id}`,
			rememberMe: true,
			deviceId: GenerateHelper.uuid(),
		};
		dispatch(AuthenticationActions.authenticateWithPin.request(data));
	};
	return (
		<LoadingProvider
			loading={action === AuthenticationActions.authenticateWithPin.requestName}
			className="card-body"
		>
			<h2 className="mt-0 mb-1">Enter your PIN CODE to login</h2>
			<PinCode onComplete={(value) => onComplete(value)} />
		</LoadingProvider>
	);
};

const WebLogin = () => {
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<ILoginRequest>({
		defaultValues: {
			deviceId: GenerateHelper.uuid(),
			rememberMe: true,
		},
	});
	const dispatch = useDispatch();
	const ownerLogin = isPos();
	const onSubmit: SubmitHandler<ILoginRequest> = (data) => {
		if (ownerLogin) {
			const ownerData = Object.assign(data);
			dispatch(AuthenticationActions.ownerAuthenticate.request(ownerData));
		} else {
			dispatch(AuthenticationActions.authenticate.request(data));
		}
	};
	const [rememberMe, setRememberMe] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	useEffect(() => {
		register('rememberMe');
	}, [register]);
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="card-body">
				<h2 className="mt-0">Login to manage your business.</h2>
				{!ownerLogin && (
					<Input
						{...register('userNameOrEmail', {
							required: 'Please input your Username/Email',
						})}
						error={
							errors.userNameOrEmail && `${errors.userNameOrEmail.message}`
						}
						placeholder="Username"
						renderBefore={<Icon size={0.8} path={mdiAccount} />}
					/>
				)}
				<Input
					placeholder="Password"
					type={showPassword ? 'text' : 'password'}
					{...register('password', {
						required: 'Please input your password',
					})}
					error={errors.password && `${errors.password.message}`}
					renderAfter={
						<div onClick={() => setShowPassword(!showPassword)}>
							<Icon
								className={clsxm('cursor-pointer')}
								size={0.8}
								path={showPassword ? mdiEye : mdiEyeOff}
							/>
						</div>
					}
					renderBefore={<Icon size={0.8} path={mdiLock} />}
				/>
				<div className="flex w-full justify-end">
					<Switch
						primary
						className="w-full flex justify-end"
						onChange={(value) => {
							setRememberMe(value);
							setValue('rememberMe', value);
						}}
						label={'Remember Me'}
						value={getValues('rememberMe')}
					/>
				</div>

				<div className="card-actions justify-end mt-4">
					<Button type="submit" primary small fullWidth title="Login" />
				</div>
			</div>
		</form>
	);
};

export const Login = () => {
	const [useUser, setUseUser] = useState(!isPos());
	const history = useHistory();
	return (
		<>
			<div className={clsxm('flex flex-col items-center w-full ')}>
				<img
					alt=""
					className={clsxm('w-48 h-48')}
					src="./logo-square.png"
				></img>
				<h4 className={clsxm('mt-[-30px]')}>
					Instant Nail Booking & Management
				</h4>
				<div className="rounded-lg bg-base-100 shadow-lg w-1/4 min-w-96 gap-3 mt-6">
					{!useUser ? <POSLogin /> : <WebLogin />}
				</div>
				<div className="flex justify-between">
					{isPos() && (
						<Button
							small
							title={
								!useUser ? 'Use User/Password to Login' : 'Use Pin To Login'
							}
							className="mt-2 text-FINISHED"
							ghost
							onClick={() => {
								setUseUser(!useUser);
							}}
						/>
					)}
				</div>
				<Button
					onClick={() => {
						window.location.href = `https://partners.${process.env.REACT_APP_PRODUCTION_URL}`;
					}}
					small
					title="Login to other shop"
					className="text-primary mt-2"
					ghost
				/>
				{isPos() && (
					<div className="grid grid-cols-2 gap-4">
						<Button
							small
							className="btn-outline"
							onClick={() => electron.ipcRenderer.minimize()}
							title={I18n.t(_t(translations.profileMenu.minimize))}
						/>
						<Button
							small
							className="btn-outline btn-error"
							onClick={() => electron.ipcRenderer.quit()}
							title={I18n.t(_t(translations.profileMenu.quit))}
						/>
					</div>
				)}
			</div>
			<footer className="footer footer-center p-4  text-base-content">
				<div>
					<p>Copyright © 2022 - SCSSolutions.io</p>
				</div>
			</footer>
		</>
	);
};
