import { ICustomer } from 'models';
import { IGetRequest } from 'models/RequestModels';
import { BaseApiService } from 'services/BaseApiService';

class CustomerApiService extends BaseApiService {
	public getCustomers = (query?: IGetRequest) =>
		this.get<ICustomer[]>(`/Customer/get-all-customers`, {
			params: query,
		});
	public getCustomerByEmail = (email: string) =>
		this.get<ICustomer[]>(`/Customer/get-customer-by-email?email=${email}`);
	public getCustomerByPhone = (phone: string) =>
		this.get<ICustomer>(`/Customer/get-customer-by-phone?phoneNumber=${phone}`);
	public addCustomer = (addCustomerRequest: Partial<ICustomer>) =>
		this.post<Partial<ICustomer>, ICustomer>(
			`/Customer/create-customer`,
			addCustomerRequest
		);
	public editCustomer = (editCustomerRequest: Partial<ICustomer>) =>
		this.put<Partial<ICustomer>, ICustomer>(
			`/Customer/update-customer`,
			editCustomerRequest
		);
	public editBookingOnlinePermission = (body: {
		id: string;
		disableBookingOnline: boolean;
	}) =>
		this.put<
			{
				id: string;
				disableBookingOnline: boolean;
			},
			boolean
		>(`/Customer/update-customer-online-booking-permission`, body);
	public getCustomerById = (id: string) =>
		this.get<ICustomer>(`/Customer/get-customer/${id}`);
	public deleteCustomer = (id: string) =>
		this.delete<string>(`/Customer/delete-customer/${id}`);
}

export default new CustomerApiService();
