import { mdiDelete, mdiPlus } from '@mdi/js';
import {
	Button,
	Card,
	ConfirmModal,
	IColumnProps,
	Icon,
	Modal,
} from 'components/atoms';
import Table from 'components/atoms/Table';
import { AlertHelper } from 'helpers';
import { isEmpty } from 'lodash';
import { IErrorResponse, IItemCategory } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PromotionApiService from 'services/PromotionApiService';
import { I18n, _t, translations } from 'utils';
import { Header } from './AppliedToServices';

export const CategoriesModal = (props: {
	onSave: (selectedRows: IItemCategory[]) => void;
	selectedRows: IItemCategory[];
}) => {
	const [selectedRows, setSelectedRows] = useState<IItemCategory[]>(
		props.selectedRows
	);

	const checkIsSelect = (data: IItemCategory) => {
		let index = selectedRows.findIndex((x) => x.id === data.id);

		if (index !== -1) {
			return true;
		} else {
			return false;
		}
	};
	const handleClickRow = (data: IItemCategory) => {
		if (!checkIsSelect(data)) {
			setSelectedRows([...selectedRows, data!]);
		} else {
			const newData = selectedRows.filter((e) => e.id !== data!.id);
			setSelectedRows(newData);
		}
	};
	const columns = useMemo<IColumnProps<IItemCategory>[]>(() => {
		return [
			{
				header: '',
				key: 'id',
				renderCell: (data) => {
					return (
						<input
							type="checkbox"
							checked={checkIsSelect(data!)}
							className="checkbox checkbox-primary"
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedRows([...selectedRows, data!]);
								} else {
									const newData = selectedRows.filter((e) => e.id !== data!.id);
									setSelectedRows(newData);
								}
							}}
						/>
					);
				},
			},
			{
				header: I18n.t(_t(translations.servicesDetail.serviceName)),
				key: 'name',
			},
		];
	}, [selectedRows]);

	useEffect(() => {
		setSelectedRows(props.selectedRows);
	}, [props.selectedRows]);

	return (
		<>
			<Table<IItemCategory>
				columns={columns}
				queryUrl={'/ItemCategory/get-all-item-categories'}
				showSearch
				renderHeader={<Header onSave={() => props.onSave(selectedRows)} />}
				selectedRows={selectedRows}
				keySelected={'id'}
				onClickRow={(data) => {
					handleClickRow(data);
				}}
			/>
		</>
	);
};

export const PromotionCategoriesTable = (props: {
	data?: IItemCategory[];
	setShowModalDelete: (value: boolean) => void;
	setCategoryToDelete: (value: IItemCategory) => void;
	setShowModal: (value: boolean) => void;
}) => {
	const columns = useMemo<IColumnProps<IItemCategory>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.servicesDetail.serviceName)),
				key: 'name',
			},
			{
				header: '',
				key: 'id',
				renderCell: (data) => {
					return (
						<div className="flex justify-end gap-x-4">
							<Button
								className="btn btn-outline text-ERROR border-0"
								small
								onClick={() => {
									props.setCategoryToDelete(data!);
									props.setShowModalDelete(true);
								}}
								iconBefore={<Icon path={mdiDelete} />}
							/>
						</div>
					);
				},
			},
		];
	}, []);

	return (
		<>
			<Table<IItemCategory>
				columns={columns}
				data={props.data}
				renderHeader={
					<Button
						title={I18n.t(_t(translations.addNew))}
						small
						onClick={(e) => {
							props.setShowModal(true);
						}}
						iconBefore={<Icon path={mdiPlus} />}
					/>
				}
				className="max-h-[calc(100vh-29rem)]"
				noPaging
			/>
		</>
	);
};

interface IAppliedToServices {
	isAdd: boolean;
	categories: IItemCategory[];
	setCategories: (value: IItemCategory[]) => void;
}
export const AppliedToCategories = (props: IAppliedToServices) => {
	const { id } = useParams() as { id: string };
	const [showModal, setShowModal] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [categoryToDelete, setCategoryToDelete] = useState<IItemCategory>();
	const [promotionCategories, setPromotionCategories] = useState<
		IItemCategory[]
	>([]);

	const onSave = (selectedRows: IItemCategory[]) => {
		props.isAdd
			? setPromotionCategories(selectedRows)
			: handleAdd(selectedRows);
		setShowModal(false);
	};
	const onDelete = async (value: IItemCategory) => {
		const newData = promotionCategories.filter((e) => e.id !== value.id);
		if (props.isAdd) {
			setPromotionCategories(newData);
		} else {
			try {
				const result = await PromotionApiService.removePromotionItemCategory({
					promotionId: id,
					itemCategoryIds: [value.id],
				});
				if (result.succeeded) {
					AlertHelper.showSuccess(
						I18n.t(_t(translations.promotionDetail.messageDeletedCategory))
					);
					fetchPromotionCategories();
				} else {
					const error = result as IErrorResponse;
					AlertHelper.showError(error);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};
	const handleAdd = async (items: IItemCategory[]) => {
		try {
			const categoryKeys = items.map((item) => item.id);
			const result = await PromotionApiService.updatePromotionItemCategory({
				promotionId: id,
				itemCategoryIds: categoryKeys,
			});
			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.promotionDetail.messageAddedCategory))
				);
				fetchPromotionCategories();
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchPromotionCategories = async () => {
		try {
			const result = await PromotionApiService.getPromotionItemCategory({
				promotionId: id,
			});
			if (result.succeeded) {
				const categories = result.data?.map((element) => element.itemCategory);
				setPromotionCategories(categories || []);
			}
		} catch (error) {}
	};

	useEffect(() => {
		if (!props.isAdd) {
			fetchPromotionCategories();
		} else {
			if (!isEmpty(props.categories)) setPromotionCategories(props.categories);
		}
	}, []);

	useEffect(() => {
		props.setCategories(promotionCategories);
	}, [promotionCategories]);

	return (
		<Card>
			<PromotionCategoriesTable
				data={promotionCategories}
				setCategoryToDelete={(value) => setCategoryToDelete(value)}
				setShowModalDelete={(value) => setShowModalDelete(value)}
				setShowModal={(value) => setShowModal(value)}
			/>
			<Modal
				title={I18n.t(_t(translations.promotionDetail.modalTitle))}
				className="w-1/2"
				isOpen={showModal}
				onCancel={() => setShowModal(false)}
				content={
					<CategoriesModal
						onSave={(selectedRows) => onSave(selectedRows)}
						selectedRows={promotionCategories}
					/>
				}
			/>
			<ConfirmModal
				visible={showModalDelete}
				title={I18n.t(_t(translations.delete))}
				subTitle={I18n.t(
					_t(translations.promotionDetail.deleteCategoryConfirmContent)
				)}
				onPositiveClick={() => {
					onDelete(categoryToDelete!);
					setShowModalDelete(false);
				}}
				onNegativeClick={() => setShowModalDelete(false)}
				type="error"
			/>
		</Card>
	);
};
