import { ReduxHelper } from 'helpers';
import { IEmployee, IPoolStaff } from 'models';
import { IPoolStaffsRequest } from 'models/RequestModels';

export const prefix = 'TODAY';

const socketAddPoolStaffs = ReduxHelper.generateLocalAction<IPoolStaff>(
	`${prefix}/SOCKET_ADD_POOL_STAFFS`
);
const socketRemovePoolStaffs = ReduxHelper.generateLocalAction<IPoolStaff>(
	`${prefix}/SOCKET_REMOVE_POOL_STAFFS`
);
const getPoolStaffs = ReduxHelper.generateActions<
	IPoolStaffsRequest,
	IPoolStaff[]
>(`${prefix}/GET_POOL_STAFFS`);

export { getPoolStaffs, socketAddPoolStaffs, socketRemovePoolStaffs };
