import {
	mdiDelete,
	mdiFolderOpenOutline,
	mdiPencilOutline,
	mdiPlus,
} from '@mdi/js';
import {
	Button,
	Card,
	ConfirmModal,
	Container,
	IColumnProps,
	Icon,
	Modal,
} from 'components/atoms';
import Table, { EColumnType } from 'components/atoms/Table';
import { PickService } from 'components/molecules/PickService';
import { AlertHelper, CurrencyHelper, TimeHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import { isEmpty } from 'lodash';
import { IApiResponse, IErrorResponse, IService } from 'models';
import { IPromotionItem } from 'models/IPromotion';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PromotionApiService from 'services/PromotionApiService';
import { I18n, _t, translations } from 'utils';

export const Header = (props: { onSave: () => void }) => {
	return (
		<Button
			title={I18n.t(_t(translations.save))}
			small
			onClick={() => {
				props.onSave();
			}}
		/>
	);
};

export const ServicesModal = (props: {
	onSave: (selectedRows: IService[]) => void;
	selectedRows: IService[];
}) => {
	const [selectedRows, setSelectedRows] = useState<IService[]>(
		props.selectedRows
	);

	const checkIsSelect = (data: IService) => {
		let index = selectedRows.findIndex((x) => x.id === data.id);

		if (index !== -1) {
			return true;
		} else {
			return false;
		}
	};
	const handleClickRow = (data: IService) => {
		if (!checkIsSelect(data)) {
			setSelectedRows([...selectedRows, data!]);
		} else {
			const newData = selectedRows.filter((e) => e.id !== data!.id);
			setSelectedRows(newData);
		}
	};
	const columns = useMemo<IColumnProps<IService>[]>(() => {
		return [
			{
				header: '',
				key: 'id',
				renderCell: (data) => {
					return (
						<input
							type="checkbox"
							checked={checkIsSelect(data!)}
							className="checkbox checkbox-primary"
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedRows([...selectedRows, data!]);
								} else {
									const newData = selectedRows.filter((e) => e.id !== data!.id);
									setSelectedRows(newData);
								}
							}}
						/>
					);
				},
			},
			{
				header: I18n.t(_t(translations.servicesDetail.serviceName)),
				key: 'name',
			},
			{
				header: I18n.t(_t(translations.servicesDetail.duration)),
				key: 'duration',
				type: EColumnType.NUMBER,
			},
			{
				header: I18n.t(_t(translations.billing.price)),
				key: 'price',
				type: EColumnType.NUMBER,
			},
		];
	}, [selectedRows]);
	useEffect(() => {
		setSelectedRows(props.selectedRows);
	}, [props.selectedRows]);

	return (
		<>
			<Table<IService>
				columns={columns}
				queryUrl={'/Service/get-all-services'}
				showSearch
				renderHeader={<Header onSave={() => props.onSave(selectedRows)} />}
				selectedRows={selectedRows}
				keySelected={'id'}
				onClickRow={(data) => {
					handleClickRow(data);
				}}
			/>
		</>
	);
};

export const PromotionServiceContent = (props: {
	data: IService[];
	setShowModalDelete: (value: boolean) => void;
	setServiceToDelete: (value: IService) => void;
	setShowModal: (value: boolean) => void;
}) => {
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	const isEmptyCategory = (categoryId: string) => {
		let index = props.data.findIndex(
			(service) => service.itemCategoryId === categoryId
		);
		return index === -1 ? true : false;
	};

	return (
		<>
			<Container>
				<div className="flex w-full justify-end">
					<Button
						small
						title={I18n.t(_t(translations.edit))}
						iconBefore={<Icon path={mdiPencilOutline} />}
						onClick={() => {
							props.setShowModal(true);
						}}
					/>
				</div>
				{isEmpty(props.data) ? (
					<div className="w-full flex justify-center">
						<div>
							<Icon size={3} path={mdiFolderOpenOutline} className="mx-auto" />
							<h3 className="my-0">No data available</h3>
						</div>
					</div>
				) : (
					<ul className="list-none p-0">
						{categories.map((category) => {
							return isEmptyCategory(category.id) ? null : (
								<li className="border-1 shadow-md my-4 w-full rounded-md px-0">
									<div className="bg-base-200 h-12 text-lg font-semibold p-2 rounded-t-md flex">
										{category.name}
									</div>
									<ul className="p-0 pb-2">
										{props.data
											.filter(
												(service) => service.itemCategoryId === category.id
											)
											.map((x, index) => {
												return (
													<div key={index}>
														{index !== 0 && <div className="divider my-0" />}
														<li className="px-12 flex">
															<div className="ml-2 flex basis-11/12">
																<span className="basis-1/2">{x.name}</span>
																<span className="basis-1/4">
																	{TimeHelper.convertDurationToStringDetail(
																		x.duration
																	)}
																</span>
																<span className="basis-1/4">
																	{CurrencyHelper.formatPrice(x.price)}
																</span>
															</div>
															<Button
																className="btn btn-outline text-ERROR border-0 basis-1/12"
																small
																onClick={() => {
																	props.setServiceToDelete(x);
																	props.setShowModalDelete(true);
																}}
																iconBefore={<Icon path={mdiDelete} />}
															/>
														</li>
													</div>
												);
											})}
									</ul>
								</li>
							);
						})}
					</ul>
				)}
			</Container>
		</>
	);
};

interface IAppliedToServices {
	isAdd: boolean;
	services: IService[];
	setServices: (value: IService[]) => void;
}
export const AppliedToServices = (props: IAppliedToServices) => {
	const { id } = useParams() as { id: string };
	const [showModal, setShowModal] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [serviceToDelete, setServiceToDelete] = useState<IService>();
	const [promotionServices, setPromotionServices] = useState<IService[]>([]);

	const onSave = (selectedRows: IService[]) => {
		props.isAdd ? setPromotionServices(selectedRows) : handleAdd(selectedRows);
		setShowModal(false);
	};
	const onDelete = async (value: IService) => {
		const newData = promotionServices.filter((e) => e.id !== value.id);
		if (props.isAdd) {
			setPromotionServices(newData);
		} else {
			try {
				const result = await PromotionApiService.removePromotionItem({
					promotionId: id,
					itemIds: [value.id],
				});
				if (result.succeeded) {
					AlertHelper.showSuccess(
						I18n.t(_t(translations.promotionDetail.messageDeletedItem))
					);
					fetchPromotionItem();
				} else {
					const error = result as IErrorResponse;
					AlertHelper.showError(error);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};
	const handleAdd = async (items: IService[]) => {
		try {
			const serviceKeys = items.map((item) => item.id);
			const result = await PromotionApiService.updatePromotionItem({
				promotionId: id,
				itemIds: serviceKeys,
			});
			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.promotionDetail.messageAddedItem))
				);
				fetchPromotionItem();
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchPromotionItem = async () => {
		try {
			const result = (await PromotionApiService.getPromotionItem({
				promotionId: id,
			})) as IApiResponse<IPromotionItem[]>;
			if (result.succeeded) {
				const items = result.data?.map((element) => element.item);
				setPromotionServices(items || []);
			}
		} catch (error) {}
	};

	useEffect(() => {
		if (!props.isAdd) {
			fetchPromotionItem();
		} else {
			if (!isEmpty(props.services)) setPromotionServices(props.services);
		}
	}, []);

	useEffect(() => {
		props.setServices(promotionServices);
	}, [promotionServices]);

	return (
		<Card>
			<PromotionServiceContent
				data={promotionServices}
				setServiceToDelete={(value) => setServiceToDelete(value)}
				setShowModalDelete={(value) => setShowModalDelete(value)}
				setShowModal={(value) => setShowModal(value)}
			/>
			<PickService
				title={I18n.t(_t(translations.promotionDetail.modalTitle))}
				visible={showModal}
				selected={promotionServices}
				onSave={(data) => onSave(data)}
				onCancel={() => setShowModal(false)}
			/>
			<ConfirmModal
				visible={showModalDelete}
				title={I18n.t(_t(translations.delete))}
				subTitle={I18n.t(_t(translations.promotionDetail.deleteConfirmContent))}
				onPositiveClick={() => {
					onDelete(serviceToDelete!);
					setShowModalDelete(false);
				}}
				onNegativeClick={() => setShowModalDelete(false)}
				type="error"
			/>
		</Card>
	);
};
