import { ReduxHelper } from 'helpers';
import { IEmployee, IService } from 'models';
import {
	IAddEmployeeRequest,
	IEditEmployeeRequest,
} from 'models/RequestModels';
export const prefix = 'EMPLOYEE';

const getAllEmployees = ReduxHelper.generateActions<
	string | undefined,
	IEmployee[]
>(`${prefix}/GET_ALL_EMPLOYEES`);
const selectEmployee = ReduxHelper.generateLocalAction<IEmployee>(
	`${prefix}/SELECT_CURRENT_EMPLOYEE`
);
const removeCurrentEmployee = ReduxHelper.generateLocalAction(
	`${prefix}/REMOVE_CURRENT_EMPLOYEE`
);
const getEmployeeById = ReduxHelper.generateActions<string, IEmployee>(
	`${prefix}/GET_EMPLOYEE_BY_ID`
);
const addEmployee = ReduxHelper.generateActions<IAddEmployeeRequest, IEmployee>(
	`${prefix}/ADD_EMPLOYEE`
);
const editEmployee = ReduxHelper.generateActions<
	IEditEmployeeRequest,
	IEmployee
>(`${prefix}/EDIT_EMPLOYEE`);

const deleteEmployee = ReduxHelper.generateActions<string, string>(
	`${prefix}/DELETE_EMPLOYEE`
);
const getEmployeesByBranch = ReduxHelper.generateActions<void, IEmployee[]>(
	`${prefix}/GET_EMPLOYEE_BY_BRANCH`
);
const getEmployeesByService = ReduxHelper.generateActions<string, IEmployee[]>(
	`${prefix}/GET_EMPLOYEE_BY_SERVICE`
);

export {
	getAllEmployees,
	selectEmployee,
	removeCurrentEmployee,
	addEmployee,
	editEmployee,
	deleteEmployee,
	getEmployeeById,
	getEmployeesByBranch,
	getEmployeesByService,
};
