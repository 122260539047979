import { Button, ButtonGroup, Description, Icon } from 'components/atoms';
import { ModalNumPad } from 'components/molecules/ModalNumPad';
import { INumPadRef } from 'components/molecules/ModalNumPad/NumPad';
import { BookingHelper, CurrencyHelper } from 'helpers';
import { calculateTotalAmountBillDetails } from 'helpers/bookingHelper';
import { useAppSelector } from 'helpers/hookHelpers';
import I18n from 'i18n-js';
import { clone, isEmpty, sumBy, uniqBy } from 'lodash';
import { IBillingDetail } from 'models';
import React, { useEffect, useRef, useState } from 'react';
import { t, translations, _t } from 'utils';
import { DiscounterType } from 'utils/Consts';
import { useBillDetailContext } from '../context';

interface ISharingMethod {
	id: DiscounterType;
	label: string;
}

const RefundSharingTypes: ISharingMethod[] = [
	{
		id: DiscounterType.BOTH,
		label: I18n.t(_t(translations.checkout.both)),
	},
	{
		id: DiscounterType.OWNER_ONLY,
		label: I18n.t(_t(translations.checkout.ownerOnly)),
	},
	{
		id: DiscounterType.EMPLOYEE_ONLY,
		label: I18n.t(_t(translations.checkout.employeeOnly)),
	},
];

export const RefundPartialModal = (props: {
	onCancel: () => void;
	billDetail: Partial<IBillingDetail>;
}) => {
	const { refund, refundDetails, setRefund, setRefundDetails, setRefunding } =
		useBillDetailContext();
	const numpadRef = useRef<INumPadRef>();
	const [sharingType, setSharingType] = useState(RefundSharingTypes[0]);
	const [refundValue, setRefundValue] = useState(0);
	const taxPercent = useAppSelector(
		(state) => state.BranchReducer.currentBranch?.taxPercent
	);
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const onUpdateText = (text: string) => {
		if (numpadRef) {
			numpadRef.current?.setText(text);
		}
	};
	useEffect(() => {
		if (refund?.refundSharingType) {
			setSharingType(RefundSharingTypes[refund.refundSharingType]);
		}
	}, [refund]);
	const onExact = () => {
		onUpdateText(`${getMaxAmount()}`);
	};

	const getOwnerRefund = () => {
		let ownerSharingAmount;
		switch (sharingType.id) {
			case DiscounterType.BOTH:
				ownerSharingAmount = refundValue / 2;
				break;
			case DiscounterType.EMPLOYEE_ONLY:
				ownerSharingAmount = 0;
				break;
			case DiscounterType.OWNER_ONLY:
				ownerSharingAmount = refundValue;
				break;
			default:
				ownerSharingAmount = 0;
				break;
		}
		return ownerSharingAmount;
	};

	const getMaxAmount = () => {
		const maxAmount =
			(branch?.discountBeforeTax
				? BookingHelper.calculateBookingDetailAmount({
						item: props.billDetail.item,
						discount: props.billDetail.discount || 0,
						promotionDiscount: props.billDetail.promotionDiscount || 0,
				  }) < 0
					? 0
					: BookingHelper.calculateBookingDetailAmount({
							item: props.billDetail.item,
							discount: props.billDetail.discount || 0,
							promotionDiscount: props.billDetail.promotionDiscount || 0,
					  })
				: props.billDetail.amount || 0) + (props.billDetail.extraAmount || 0);
		return maxAmount + (maxAmount * (branch?.taxPercent || 0)) / 100;
	};

	const onConfirm = () => {
		setRefund({
			refundSharingType: sharingType.id,
		});
		const originalAmount = CurrencyHelper.priceFixed(
			(refundValue / ((taxPercent || 0) + 100)) * 100
		);
		const tax = CurrencyHelper.priceFixed(refundValue - originalAmount);
		const newRefundDetail = {
			...props.billDetail,
			id: undefined,
			billDetailId: props.billDetail.id,
			amount: -originalAmount,
			tax: -tax,
		};
		let details = clone(refundDetails) || [];
		details.push(newRefundDetail);
		setRefundDetails(details);
		setRefunding(true);
	};
	return (
		<ModalNumPad
			ref={(x) => {
				if (x) {
					numpadRef.current = x;
				}
			}}
			{...props}
			isMoney
			onChange={(x) => {
				const maxValue = getMaxAmount();
				if (parseFloat(x) > maxValue) {
					setRefundValue(maxValue);
					onUpdateText(`${maxValue}`);
				} else {
					setRefundValue(parseFloat(x) || 0);
				}
			}}
			showNumpad
			unit={'Usd'}
			title={'Refund This Service'}
			onConfirm={(x) => {
				onConfirm();
				props.onCancel();
			}}
		>
			<div className="flex flex-col gap-2 h-full flex-1 p-4">
				<h5 className="font-bold">
					Sharing Method
					<span className="ml-4 font-normal">
						(It will apply for all refund in this bill)
					</span>
				</h5>
				<ButtonGroup<ISharingMethod>
					buttons={RefundSharingTypes}
					buttonClassNames={['flex-1', 'flex-1', 'flex-1']}
					activeClassName={'flex-1'}
					small
					value={sharingType}
					onChange={(t) => {
						setSharingType(t);
					}}
					valueField={'id'}
					titleField="label"
				/>
				<Description
					title={`Refund by Owner`}
					value={CurrencyHelper.formatPrice(getOwnerRefund())}
				/>
				<Description
					title={`Refund by ${props.billDetail.stylist?.firstName || ''} ${
						props.billDetail.stylist?.lastName || ''
					}`}
					value={CurrencyHelper.formatPrice(refundValue - getOwnerRefund())}
				/>
				<div className="flex flex-1 gap-2 items-end">
					<div className="flex w-full items-center justify-between">
						<div>
							<Description
								title={t(translations.billing.maximumRefundAmount)}
								value={`${CurrencyHelper.formatPrice(getMaxAmount())}`}
							/>
						</div>
						<div className="flex gap-2">
							<Button
								onClick={() => onExact()}
								small
								title={t(translations.checkout.exact)}
								className="btn-outline btn-primary"
							/>
							<Button
								onClick={() => {
									if (numpadRef) {
										numpadRef.current?.setText('');
									}
								}}
								small
								title={t(translations.clear)}
								className="btn-outline btn-error"
							/>
						</div>
					</div>
				</div>
			</div>
		</ModalNumPad>
	);
};
