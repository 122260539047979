import clsxm from 'clsxs/clsxm';
import _ from 'lodash';
import React from 'react';

interface IAvatarProps {
	name?: string;
	source?: string;
	color?: string;
	small?: boolean;
	className?: string;
	start?: boolean;
	fullHeight?: boolean;
	containerClassName?: string;
}

export const Avatar = (props: IAvatarProps) => {
	if (_.isEmpty(props.source)) {
		return (
			<div
				className={clsxm(
					'avatar placeholder w-full items-center inline-flex',
					!props.start && 'justify-center',
					props.fullHeight && 'h-full',
					props.containerClassName
				)}
			>
				<div
					style={{ backgroundColor: props.color && props.color }}
					className={clsxm(
						'text-neutral-content rounded-full w-24 bg-base-300',
						props.small && 'w-8',
						props.className
					)}
				>
					<div
						className={clsxm(
							'text-[#000] font-semibold',
							props.small && 'text-xs text-[10px]'
						)}
					>
						{`${props.name}`.toLocaleUpperCase()}
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="avatar flex justify-center">
			<div
				className={clsxm(
					'w-24 rounded-full',
					props.small && 'w-8',
					props.className
				)}
			>
				<img alt="" src={props.source} className="m-0" />
			</div>
		</div>
	);
};
