import React, { FC, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { DetailRoute, getSubRoutes, HomeRoute } from './routes';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import PrivateRoute from 'routers/ProtectedRoute';
import { BranchActions } from 'redux/actions';
import _ from 'lodash';
import ContentAdmin from 'routers/ContentAmin';
import { TabRoute } from 'routers/TabRoute';
import { AdminHeader } from './Header';
import { Booking } from 'pages/Booking';
import { Modal } from 'components/atoms/Modal';
import { useAppSelector } from 'helpers/hookHelpers';
import { Icon } from 'components/atoms/Icon';
import { mdiChevronRight } from '@mdi/js';
import { useDispatch } from 'react-redux';
import clsxm from 'clsxs/clsxm';
import 'moment/locale/vi';
import moment from 'moment';

interface IRouteComponentProps
	extends RouteComponentProps,
		ReturnType<typeof mapStateToProps> {}

class AdminRouter extends React.Component<IRouteComponentProps> {
	state = {
		collapsed: false,
		reload: false,
		showSelectBranch: false,
	};

	componentDidUpdate(prev: IRouteComponentProps) {
		if (!_.isEmpty(this.props.currentBranch)) {
			if (this.props.currentBranch.address?.countryCode === 'VN') {
				moment.locale('vi');
			} else {
				moment.locale('en');
			}
			if (
				!_.isEmpty(prev.currentBranch) &&
				prev.currentBranch?.id !== this.props.currentBranch?.id
			) {
				this.setState({ reload: true }, () => {
					setTimeout(() => {
						this.setState({ reload: false });
					}, 500);
				});
				// if (
				// 	this.props.branchAction?.includes(
				// 		BranchActions.getBranchById.successName
				// 	)
				// ) {
				// 	this.setState({ reload: true }, () => {
				// 		setTimeout(() => {
				// 			this.setState({ reload: false });
				// 		}, 500);
				// 	});
				// }
			}
		}
	}
	onCollapse = (collapsed: boolean) => {
		this.setState({ collapsed });
	};
	toggle = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	render() {
		if (this.checkIsLoading()) {
			return this.renderLoading();
		}
		return (
			<div className="w-full h-full overflow-hidden overflow-y-auto  ">
				{!this.state.reload && !_.isEmpty(this.props.currentBranch) ? (
					<ContentAdmin user={this.props.user!} />
				) : null}
				<AdminHeader
					onSelectChangeBranch={() => this.setState({ showSelectBranch: true })}
				/>
				{/* {this.state.showSelectBranch && (
					<BranchModal
						onCancel={() => this.setState({ showSelectBranch: false })}
					/>
				)} */}
			</div>
		);
	}

	renderLoading() {
		return (
			<div className="loading-body">
				<div className="sk-cube-grid">
					<div className="sk-cube sk-cube1" />
					<div className="sk-cube sk-cube2" />
					<div className="sk-cube sk-cube3" />
					<div className="sk-cube sk-cube4" />
					<div className="sk-cube sk-cube5" />
					<div className="sk-cube sk-cube6" />
					<div className="sk-cube sk-cube7" />
					<div className="sk-cube sk-cube8" />
					<div className="sk-cube sk-cube9" />
				</div>
			</div>
		);
	}
	checkIsMobile() {
		return true;
		// return window.innerWidth <= 1441;
	}
	checkIsLoading() {
		if (_.isEmpty(this.props.currentBranch)) {
			return true;
		}
		return false;
	}
}

const mapStateToProps = (state: RootState) => ({
	loading: state.AppConfigReducer.showLoading,
	user: state.UserReducer.user,
	employee: state.UserReducer.employee,
	currentBranch: state.BranchReducer.currentBranch,
	branchAction: state.ReduxActionReducer['BRANCH'],
});
const Admin = connect(mapStateToProps)(AdminRouter);

const AdminRoute: FC<{ isAuthenticated: boolean; userRoles?: string[] }> = ({
	isAuthenticated = false,
}) => {
	return (
		<div className="h-full overflow-hidden overflow-y-auto">
			{DetailRoute.map((route) => (
				<PrivateRoute
					isAuthenticated={isAuthenticated}
					exact
					path={route.path}
					component={route.component}
				/>
			))}
			<PrivateRoute
				isAuthenticated={isAuthenticated}
				exact
				path={['/booking', '/booking/new', '/booking/:id']}
				component={Booking}
			/>
			<PrivateRoute
				isAuthenticated={isAuthenticated}
				exact
				path={HomeRoute.concat(getSubRoutes()).map((route) => route.path!)}
				component={Admin}
			/>
		</div>
	);
};

export default AdminRoute;
