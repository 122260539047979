import { useAppSelector } from 'helpers/hookHelpers';
import moment, { Moment } from 'moment';
import React, { createContext, FC, useContext, useState } from 'react';
import { DATE_RANGE_TYPES, PERIOD_TYPES } from 'utils/Consts';

interface IDashboardContext {
	period: typeof PERIOD_TYPES[0];
	timeRange: {
		fromDate: Moment;
		toDate: Moment;
	};
	setPeriod?: (period: typeof PERIOD_TYPES[0]) => void;
	setTimeRange: (timeRange: { fromDate: Moment; toDate: Moment }) => void;
	setBranch?: (id: string) => void;
	branch?: string;
}
const DashBoardContext = createContext<IDashboardContext>({
	period: PERIOD_TYPES[0],
	timeRange: {
		fromDate: moment(),
		toDate: moment(),
	},
	setTimeRange: () => {},
});
export const useDashboardContext = () => {
	return useContext(DashBoardContext);
};

export const DashboardProvider: React.FC<{
	children?: React.ReactNode;
}> = ({ children }) => {
	const { currentBranch } = useAppSelector((state) => state.BranchReducer);
	const [timeRange, setTimeRange] = useState({
		fromDate: moment(),
		toDate: moment(),
	});
	const [period, setPeriod] = useState(PERIOD_TYPES[0]);
	const [branch, setBranch] = useState(currentBranch?.id);
	const value = React.useMemo(
		() => ({
			period,
			setPeriod,
			branch,
			setBranch,
			timeRange,
			setTimeRange,
		}),
		[period, branch, timeRange]
	);
	return (
		<DashBoardContext.Provider value={value}>
			{children}
		</DashBoardContext.Provider>
	);
};
