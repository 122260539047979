import { ReduxHelper } from 'helpers';
import { ITheme } from 'models';
import { IPaginateRequest } from 'models/RequestModels';

const prefix = 'THEME';

const getAllThemes = ReduxHelper.generateActions<IPaginateRequest, ITheme[]>(
	`${prefix}/GET_ALL_THEME`
);

export { getAllThemes };
