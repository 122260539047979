import { TabView } from 'components/molecules/Tab';
import React from 'react';
import { ListEmail } from './ListEmail';
import { ListTemplateEmail } from './ListTemplateEmail';

export const EmailBlast = () => {
	return (
		<TabView
			tabs={['Email', 'Template']}
			tabPanels={[<ListEmail />, <ListTemplateEmail />]}
		/>
	);
};
