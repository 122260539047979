import { PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse, IBooking, ICustomer, IErrorResponse } from "models";
import { IGetBookingsRequest } from "models/RequestModels";
import moment from "moment";
import { put, takeEvery, call, all } from "redux-saga/effects";
import { SearchActions } from "redux/actions";
import BookingApiService from "services/BookingApiService";
import CustomerApiService from "services/CustomerApiService";
import { BookingStatus } from "utils/Consts";

function* searchByText(action: PayloadAction<string>) {
  try {
    const customerQuery = {
      searchText: action.payload,
      pageSize: 10,
    };
    const bookingQuery: IGetBookingsRequest = {
      fromDate: moment().subtract(1, "hour").toDate(),
      toDate: moment().add(1, "month").toDate(),
      searchText: action.payload,
      pageSize: 10,
      bookingStatus: `${BookingStatus.PENDING},${BookingStatus.FINISHED}`,
    };
    const [resultCustomers, resultBookings]: [IApiResponse<ICustomer[]>, IApiResponse<IBooking[]>] = yield all([
      call(CustomerApiService.getCustomers, customerQuery),
      call(BookingApiService.getBookingByBranch, bookingQuery),
    ]);
    if (resultCustomers.succeeded && resultBookings.succeeded) {
      yield put(
        SearchActions.searchByText.success({
          customers: resultCustomers.data!,
          bookings: resultBookings.data!,
        })
      );
    } else {
      const error = resultCustomers as IErrorResponse;
      yield put(SearchActions.searchByText.failed(error));
    }
  } catch (error) {
    yield put(SearchActions.searchByText.failed(error));
  }
}

export function* SearchWatcher() {
  yield takeEvery(SearchActions.searchByText.requestName, searchByText);
}
