import { mdiPlus } from '@mdi/js';
import { Button, ConfirmModal, Icon } from 'components/atoms';
import Table, { IColumnProps, ITableRef } from 'components/atoms/Table';
import { AlertHelper } from 'helpers';
import { IErrorResponse } from 'models';
import { ITemplate } from 'models/ITemplate';
import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TemplateApiService from 'services/TemplateApiService';
import { translations, t } from 'utils';
import { ButtonsAction } from './ListEmail';

export const ListTemplateEmail = () => {
	const [showModal, setShowModal] = useState(false);
	const [templateId, setTemplateId] = useState('');
	const tableRef = useRef<ITableRef>();
	const history = useHistory();

	const onDeleteTemplate = async () => {
		try {
			const result = await TemplateApiService.deleteTemplate(templateId);
			if (result.succeeded) {
				AlertHelper.showSuccess(
					t(translations.templateDetail.messageDeleteSuccess)
				);
				tableRef.current?.refresh();
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {}
	};

	const columns = useMemo<IColumnProps<ITemplate>[]>(() => {
		return [
			{
				header: t(translations.template.subject),
				key: 'subject',
			},
			{
				header: '',
				key: 'subject',
				renderCell: () => <div className="flex flex-1 w-40" />,
			},
			{
				header: t(translations.action),
				key: 'id',
				renderCell: (data) => {
					return (
						<ButtonsAction
							onEdit={() => history.push(`template/edit/${data.id}`)}
							onDelete={() => {
								setTemplateId(data.id);
								setShowModal(true);
							}}
						/>
					);
				},
			},
		];
	}, []);
	return (
		<>
			<Table<ITemplate>
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				queryUrl="/EmailTemplate/get-all-emailtemplates"
				showSearch
				columns={columns}
				renderHeader={
					<Button
						small
						primary
						title={t(translations.addNew)}
						iconBefore={<Icon path={mdiPlus} />}
						onClick={() => history.push('template/add-new')}
					/>
				}
				// className="max-h-[calc(100vh-27.3rem)]"
			/>
			<ConfirmModal
				visible={showModal}
				title={t(translations.delete)}
				subTitle={t(translations.templateDetail.deleteConfirmContent)}
				onPositiveClick={() => {
					onDeleteTemplate();
					setShowModal(false);
				}}
				onNegativeClick={() => setShowModal(false)}
				type="error"
			/>
		</>
	);
};
