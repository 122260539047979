import _ from 'lodash';
import { IApiResponse, IErrorResponse } from 'models';
import { ICurrency } from 'models/ICurrency';
import { put, takeEvery, call } from 'redux-saga/effects';
import { CurrencyActions } from 'redux/actions';
import CurrencyApiService from 'services/CurrencyApiService';

function* getAllCurrency() {
	try {
		const result: IApiResponse<ICurrency[]> = yield call(
			CurrencyApiService.getAllCurrency
		);
		if (result.succeeded) {
			yield put(CurrencyActions.getAllCurrency.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			yield put(CurrencyActions.getAllCurrency.failed(errorResult));
		}
	} catch (error) {
		yield put(CurrencyActions.getAllCurrency.failed(error));
	}
}

export function* CurrencyWatcher() {
	yield takeEvery(CurrencyActions.getAllCurrency.requestName, getAllCurrency);
}
