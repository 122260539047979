import { ReduxHelper } from 'helpers';
import { ICurrency } from 'models/ICurrency';

export const prefix = 'CURRENCY';

const getAllCurrency = ReduxHelper.generateActions<void, ICurrency[]>(
	`${prefix}/GET_ALL_CURRENCY`
);

export { getAllCurrency };
